import React, { useEffect, useState, useMemo } from 'react'
import {
  PageHeader,
  Pagination,
  SelectField,
  FlexContainer,
  Filter,
  ActionButton,
  TableMessageRow
} from 'components'
import {
  Row,
  Col,
  Table,
  Modal
} from 'react-bootstrap'
import {
  useGetAuditTables,
  useGetLogs,
  TLogsParams,
  TLogsInfo
} from 'repositories/logs'
import {
  formatDateTimeToView,
  removeTimeFromDateTime
} from 'utils/helpers'
import { AiOutlineEye } from 'react-icons/ai'
import { Redirect } from 'react-router-dom'
import useFilters from 'hooks/useFilters'
import { useAuth } from 'hooks/useAuth'
import usePagination from 'hooks/usePagination'
import { FilterButton, ModalText } from './styles'

const actionOptions = [
  {
    label: 'INSERIU',
    value: 'INSERIDO'
  },
  {
    label: 'ATUALIZOU',
    value: 'ATUALIZADO'
  },
  {
    label: 'DELETOU',
    value: 'DELETADO'
  }
]

const modalAllFalse: boolean[] = [false, false, false, false, false, false, false, false, false, false]

type TPaginationInfoLog = {
  numberOfRecordsByPage: number
  pageNumber: number
  totalPages: number
  totalRecords:number
}

const VisualizarLogs = () => {
  const [logs, setLogs] = useState<TLogsInfo[]>([])
  const pagination = usePagination()
  const [tableOptions, setTableOptions] = useState<TSelectOption[]>([])
  const [chosenTable, setChosenTable] = useState<number>(-1)
  const [showDetailsModal, setShowDetailsModal] = useState<boolean[]>(modalAllFalse)
  const [paginationInfo, setPaginationInfo] = useState<TPaginationInfoLog>()
  const [showTable, setShowTable] = useState<boolean>(false)

  const handleHideDetailsModal = () => setShowDetailsModal(modalAllFalse)
  const handleShowDetailsModal = (id: number) => {
    const _modal = showDetailsModal.map((modal, index) => {
      if(id === index) return true
      return modal
    })
    setShowDetailsModal(_modal)
  }

  const getAuditTables = useGetAuditTables()
  const getLogs = useGetLogs()
  const filters = useFilters()
  const { userPermissions } = useAuth()

  const permissions = useMemo(() => userPermissions?.auditoria, [userPermissions])

  useEffect(() => {
    if(!getLogs.logs) return
    setLogs(getLogs.logs)
  }, [getLogs.logs])

  useEffect(() => {
    getAuditTables.get()
  }, [])

  useEffect(() => {
    if(chosenTable === -1) return
    filterTables()
  }, [filters.items])


  useEffect(() => {
    if(!getAuditTables.tabelas) return
    const _tableOptions = getAuditTables.tabelas.map(tabela => {
      return {
        id: tabela.id,
        value: String(tabela.id),
        label: tabela.tableName
      }
    })

    setTableOptions(_tableOptions)
  }, [getAuditTables.tabelas])

  useEffect(() => {
    filters.stopLoading()
    pagination.stopLoading()

    if (!getLogs.paginationInfo) return
    if(!getLogs.logs) return


    setPaginationInfo(getLogs.paginationInfo)
    pagination.setOptions(getLogs.paginationInfo.pageNumber, getLogs.paginationInfo.totalPages)

  }, [getLogs.paginationInfo])

  const onChangeTable = (e: TInputEvent) => {
    setChosenTable(parseInt(e.target.value))
    setLogs([])
    setShowTable(false)
  }

  const filterTables = (pageIndex = 0) => {
    setShowTable(true)
    setLogs([])
    const _params: TLogsParams = {
      paginate: {
        pageNumber: pageIndex,
        numberOfRecordsByPage: 10
      },
      filter: {
        ...filters.getObjectifiedFilterItems(),
      },
      auditTableId: chosenTable
    }
    getLogs.get(_params)
  }

  const formatActions = (action: string) => {
    switch(action){
      case 'INSERIDO': return 'INSERIU'
      case 'ATUALIZADO': return 'ATUALIZOU'
      case 'DELETADO': return 'DELETOU'
      default: return 'ERRO'
    }
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <PageHeader title='Auditoria' />
      <Row>
        <Col md={4}>
          <SelectField
            required
            label='Tabela'
            name= 'SelectTable'
            options={tableOptions}
            value={chosenTable}
            onChange={onChangeTable}
          />
        </Col>
        <Col md={3}>
          <FilterButton
            isLoading={getLogs.isLoading}
            onClick={() => filterTables(0)}
            disabled={!(chosenTable >= 0)}
          >
            Filtrar
          </FilterButton>
        </Col>
      </Row>
      { showTable && <>
        <Row>
          <Col>
            <Table responsive striped bordered>
              <thead>
                <tr>
                  <th>
                    <FlexContainer>
                      <span>ID</span>
                      <Filter
                        field='registerId'
                        specialType='number'
                        filters={filters}
                      />
                    </FlexContainer>
                  </th>
                  <th>
                    <FlexContainer>
                      <span>Data / Hora</span>
                      <Filter
                        label={[
                          'Data Início',
                          'Data Fim'
                        ]}
                        field='startDate|endDate'
                        filters={filters}
                      />
                    </FlexContainer>
                  </th>
                  <th>
                    <FlexContainer>
                      <span>User</span>
                      <Filter
                        field='userName'
                        filters={filters}
                      />
                    </FlexContainer>
                  </th>
                  <th>
                    <FlexContainer>
                      <span>Ação</span>
                      <Filter
                        field='action'
                        options={actionOptions}
                        filters={filters}
                      />
                    </FlexContainer>
                  </th>
                  <th>
                    <FlexContainer>
                      <span>
                      Detalhes
                      </span>
                    </FlexContainer>
                  </th>
                </tr>
              </thead>
              <tbody>
                {logs.length === 0 &&
                <TableMessageRow colSpan={5}>
                  Não foram encontrados registros para serem listados
                </TableMessageRow>}
                {logs?.map((log, index) => {
                  return(
                    <tr key={log.id}>
                      <td>{log.id}</td>
                      <td>{log.date}</td>
                      <td>{log.userName}</td>
                      <td>{formatActions(log.action)}</td>
                      <td>
                        <ActionButton
                          title='Mostrar detalhes'
                          onClick={() => handleShowDetailsModal(index)}
                        >
                          <AiOutlineEye />
                        </ActionButton>
                        <Modal
                          size='lg'
                          show={showDetailsModal[index]}
                          onHide={handleHideDetailsModal}
                        >
                          <Modal.Header closeButton>
                            <PageHeader title='Modificações' />
                          </Modal.Header>
                          <Modal.Body>
                            <ModalText>{log.changeDetails}</ModalText>
                          </Modal.Body>
                        </Modal>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Pagination
              currentPageIndex={paginationInfo?.pageNumber}
              totalPages={paginationInfo?.totalPages}
              request={filterTables}
              goTo={pagination.goTo}
              goToPage={pagination.goToPage}
              loader={pagination.loader}
            />
          </Col>
        </Row>
      </>}
    </>
  )
}

export default VisualizarLogs
import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useProjetos from 'repositories/useProjetos'
import useInformacoes from 'repositories/useInformacoes'
import useIntegradores from 'repositories/useIntegradores'

import {
  Col,
  Form,
  Modal
} from 'react-bootstrap'

import { downloadBase64File, formatCpfCnpj, formatDateEnUs } from 'utils/helpers'
import { useToasts } from 'react-toast-notifications'
import {
  PrimaryButton,
  SecondaryButton,
  TextField,
  SearchField,
  ButtonsContainer
} from 'components'

import {
  ModalInfo,
  CardTermoScr
} from './styles'

const schema = Yup.object().shape({
  banco: Yup.string().required('Banco é obrigatório'),
  agencia: Yup
    .number()
    .min(1, 'Agência não pode ser menor que zero')
    .max(999999, 'Agência não pode ser maior que 999999')
    .required('Agência é obrigatório'),
  numeroConta: Yup.number()
    .min(1, 'Conta Corrente/Poupança não pode ser 0')
    .required('Conta Corrente/Poupança é obrigatório'),
  integrador: Yup.string()
    .when('isRequiredIntegrador', {
      is: false,
      then: Yup.string().required('Integrador é obrigatório')
    })
})

const initialValues = {
  bancoId: '',
  banco: '',
  agencia: '',
  numeroConta: '',
  integrador: '',
  integradorId: '',
  isAcceptedScr: false,
  isRequiredIntegrador: false,
}

const messageError = 'Houve um erro ao enviar o arquivo SCR a api SAFRA, download do arquivo realizado, por favor realizar o envio na aba documentos'
const toastOptions = {
  appearance: 'warning',
  autoDismiss: false,
}

const ModalConfirmationProposal = props => {
  const {
    isModalOpen,
    setIsOpen,
    installmentId,
    proposal,
    integratorBankInfo
  } = props
  const [isSaving, setIsSaving] = useState(false)
  const [bancosOptions, setBancosOptions] = useState([])
  const [integradoresOptions, setIntegradoresOptions] = useState([])
  const [hasIntegrator, setHastIntegrator] = useState(false)

  const history = useHistory()
  const { id } = useParams()
  const repository = useProjetos()
  const { getBancos } = useInformacoes()
  const { getIntegradoresByCompanyName } = useIntegradores()
  const { addToast } = useToasts()
  const integradorRef = useRef(null)
  const bancoRef = useRef(null)

  useEffect(() => {
    formik.setFieldValue('isRequiredIntegrador', proposal?.isRequiredIntegrador)
  }, [isModalOpen])


  const handleClose = () => {
    setIsOpen(false)
    if(hasIntegrator) return
    formik.resetForm()
  }

  const onSubmit = async () => {
    setIsSaving(true)
    const { values } = formik
    const formattedCurrentDate = formatDateEnUs(new Date)
    const data = {
      installmentId,
      bankId: values.bancoId,
      agencyNumber: values.agencia,
      accountNumber: values.numeroConta,
      integrator: values.integradorId,
      scrSendingDateTime: formattedCurrentDate
    }

    const response = await repository.postProposta(data)
    setIsSaving(false)
    if (response.fileScr) {
      downloadBase64File('SCR', response.fileScr)
      addToast(messageError, toastOptions)
    }
    if (!response.error) history.push(`/projetos/detalhes/${id}`)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })

  const hasError = name => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = name => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  })

  const onSearchIntegrador = term => {
    if (term.length < 3) return

    const requestIntegradores = async () => {
      const params = {
        companyName: term
      }
      const integradores = await getIntegradoresByCompanyName(params)
      if (!integradores) return

      const _integradoresOptions = integradores.integrators.map(integrador => {
        const formattedCnpj = formatCpfCnpj(integrador.cnpj)
        return {
          label: `${integrador.companyName} - CNPJ: ${formattedCnpj}`,
          value: integrador.id,
          ...integrador
        }
      })

      setIntegradoresOptions(_integradoresOptions)
    }

    requestIntegradores()
  }

  const onChangeIntegrador = ([integrador]) => {
    if(!integrador) {
      formik.resetForm()
      return
    }
    const value = integrador?.value ?? ''
    const label = integrador?.label ?? ''
    formik.setFieldValue('integrador', label, true)
    formik.setFieldValue('integradorId', value, true)
  }

  const onBlurIntegrador = () => {
    formik.setFieldTouched('integrador', true)
    if (!formik.values.integradorId) integradorRef.current?.clear()
  }

  const onSearchBanco = term => {
    if (term.length < 3) return

    const requestBancos = async () => {
      const bancos = await getBancos(term)
      if (!bancos) return
      const separator = ' - '
      const _bancosOptions = bancos.map(banco => ({
        label: banco.name + separator + banco.code,
        value: banco.id
      }))

      setBancosOptions(_bancosOptions)
    }

    requestBancos()
  }

  const onChangeBanco = ([banco]) => {
    const value = banco?.value ?? ''
    const label = banco?.label ?? ''
    formik.setFieldValue('banco', label, true)
    formik.setFieldValue('bancoId', value, true)
  }

  const onBlurBanco = () => {
    formik.setFieldTouched('banco', true)
    if (!formik.values.banco) bancoRef.current?.clear()
  }

  return (
    <Modal
      show={isModalOpen}
      centered
      size='lg'
      backdrop='static'
      onHide={handleClose}
    >
      <Modal.Header>
        Confirmar escolha da proposta
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Col>
              <ModalInfo>
                Para continuarmos com o processo de crédito para o seu projeto,
                precisaremos dos dados bancários da sua conta principal.
              </ModalInfo>
              <ModalInfo>
                Esta conta será usada como referência para o seu perfil dentro desse projeto.
              </ModalInfo>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col>
              <SearchField
                required
                label='Nome do banco'
                elementRef={bancoRef}
                options={bancosOptions}
                {...getCommonFieldProps('banco')}
                onSearch={onSearchBanco}
                onChange={onChangeBanco}
                onBlur={onBlurBanco}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col md={6}>
              <TextField
                required
                type='number'
                label='Agência'
                maxLength={6}
                {...getCommonFieldProps('agencia')}
              />
            </Col>
            <Col md={6}>
              <TextField
                required
                type='number'
                label='Conta Corrente/Poupança'
                {...getCommonFieldProps('numeroConta')}
              />
            </Col>
          </Form.Row>

          {!formik.values.isRequiredIntegrador && (
            <Form.Row>
              <Col>
                <SearchField
                  required={!proposal?.integrator}
                  label='Integrador'
                  elementRef={integradorRef}
                  options={integradoresOptions}
                  {...getCommonFieldProps('integrador')}
                  onSearch={onSearchIntegrador}
                  onChange={onChangeIntegrador}
                  onBlur={onBlurIntegrador}
                />
              </Col>
            </Form.Row>
          )}
          <Form.Row>
            <Col>
              <CardTermoScr>
                <span>
                  Eu, <strong>{proposal?.clientName}</strong> CPF nº
                  <strong>{proposal?.clientCpf}</strong>, autorizo a Safra
                  Financeira a consultar os débitos e responsabilidades decorrentes
                  de operações com características de crédito e as informações e os
                  registros de medidas judiciais que em meu nome constem ou venham
                  a constar do Sistema de Informações de Crédito (SCR), gerido pelo
                  Banco Central do Brasil - BACEN, ou dos sistemas que venham a
                  complementá-lo ou a substituí-lo.
                </span>
                <Form.Group controlId='isAcceptedScr'>
                  <Form.Check
                    type='checkbox'
                    checked={formik.values.isAcceptedScr}
                    onChange={formik.handleChange}
                    label='Li e autorizo'
                  />
                </Form.Group>
              </CardTermoScr>
            </Col>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonsContainer compact>
            <SecondaryButton
              onClick={handleClose}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              type='submit'
              isLoading={isSaving}
              disabled={!formik.values.isAcceptedScr}
            >
              Confirmar
            </PrimaryButton>
          </ButtonsContainer>
        </Modal.Footer>
      </Form>
    </Modal>
  )

}

export default ModalConfirmationProposal
import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const QUERY_BILLINGS = gql`
    query findBillingByFilter(
        $paginate: Paginate!,
        $findBillingCommand: FindBillingByFilterCommand
    ) {
        findBillingByFilter (
            paginate: $paginate,
            findBillingCommand: $findBillingCommand
        ) {
            ${commonResponse}
            paginationInfo{
                numberOfRecordsByPage
                pageNumber
                totalRecords
                totalPages
            }
            billingSuccess{
                unitConsumption
                powerPlantName
                clientName
                month
                dueDate
                fileSent
                fileReturned
                paid
                payday
                copernicoBillValue
                cemigBillValue
            }
        }
}
`

export const REPORT_BILLINGS = gql`
    query billingReport (
        $findBillingCommand: FindBillingByFilterCommand
    ) {
        billingReport(
            findBillingCommand: $findBillingCommand
        ){
            ${commonResponse}
            requestIdentification {
                id
              }
        }
    }       
`
export const QUERY_BILLING_REPORT_LIST = gql`
query findBillingReportByFilter(
    $paginate: Paginate!,
    $findBillingReportCommand: FindBillingReportCommand
){
    findBillingReportByFilter(
        paginate: $paginate,
        findBillingReportCommand: $findBillingReportCommand,
    ){
        ${commonResponse}
        paginationInfo{
            numberOfRecordsByPage
            pageNumber
            totalRecords
            totalPages
        }
        billingReportSuccess {
            id
            userName
            link
            status
            errorDetail
            createdDate
            updatedDate
          }
    }
}
`
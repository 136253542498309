import React from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { Form, Col } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import useAutenticacao from 'repositories/useAutenticacao'
import {
  UnsignedContainer,
  Box,
  TextField,
  PrimaryButton,
  Logo,
  InfoText,
  CenteredContainer,
  Link
} from 'components'
import { Footer } from '../styles'

const successMessage = 'Em instantes você receberá um e-mail com instruções para recuperar sua senha!'

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Insira um e-mail válido')
    .required('Insira seu e-mail')
})

type TRecuperacaoSenha = {
  email: string
}

const initialValues: TRecuperacaoSenha = {
  email: ''
}

const RecuperacaoSenha = () => {
  const history = useHistory()
  const repository = useAutenticacao()
  const { addToast } = useToasts()

  const onSubmit = async () => {
    const { email } = formik.values
    const response = await repository.postEsqueceuSenha(email)
    if (!response) return

    addToast(successMessage, {
      appearance: 'success',
      autoDismiss: true,
    })
    history.push('/')
  }

  const formik = useFormik<TRecuperacaoSenha>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit
  })

  const hasError = (name: keyof TRecuperacaoSenha) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TRecuperacaoSenha) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  return (
    <UnsignedContainer>
      <Box width={500}>
        <CenteredContainer>
          <Logo>Copérnico Energia Renovável</Logo>

          <InfoText isCentered>
            Esqueceu sua senha? É fácil recuperar
          </InfoText>
          <InfoText isCentered>
            <strong>Digite seu e-mail que iremos enviar as instruções</strong>
          </InfoText>
        </CenteredContainer>

        <Form onSubmit={formik.handleSubmit}>
          <Form.Row>
            <Col>
              <TextField
                label='E-mail'
                autoFocus
                {...getCommonFieldProps('email')}
              />
            </Col>
          </Form.Row>

          <CenteredContainer>
            <PrimaryButton
              block
              type='submit'
              isLoading={repository.isLoading}
            >
              Enviar
            </PrimaryButton>
            <Link to='/entrar'>Entrar na plataforma</Link>
          </CenteredContainer>

          <Footer>
            <Link to='/'>Termos de Uso | Política de Privacidade</Link>
            <InfoText>Copyright © Copérnico. 2021</InfoText>
          </Footer>
        </Form>
      </Box>
    </UnsignedContainer>
  )
}

export default RecuperacaoSenha
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Col, Row, Table } from 'react-bootstrap'
import { AiFillPlusSquare, AiOutlineEye } from 'react-icons/ai'

import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import { useAuth } from 'hooks/useAuth'
import { BiCheck, BiPencil, BiX } from 'react-icons/bi'
import {
  useGetDistribuidoras,
  useCreateUpdateDistribuidora
} from 'repositories/distribuidoras'
import {
  Filter,
  FlexContainer,
  PageHeader,
  PrimaryButton,
  TableMessageRow,
  Loader,
  ActionButton,
  Pagination
} from 'components'
import { formatCpfCnpj, formatDecimalNumberToPercentage } from 'utils/helpers'
import { initialPaginationParams, ufOptions } from 'utils/constants'

const tableMessageRowColSpan = 6

const initialParams: TListaDistribuidorasParams = {
  findPowerCompanyByFilterCommand: {},
  ...initialPaginationParams
}

const DistribuidorasLista = () => {
  const [distribuidoras, setDistribuidora] = useState<TDistribuidoraInfo[]>([])
  const [paginationInfo, setPaginationInfo] = useState<TPageProperties>()

  const history = useHistory()
  const filters = useFilters()
  const pagination = usePagination()
  const getDistribuidoras = useGetDistribuidoras()
  const updateDistribuidora = useCreateUpdateDistribuidora()
  const { userPermissions } = useAuth()

  const permissions = useMemo(() => userPermissions?.distribuidoras, [userPermissions])

  useEffect(() => {
    getDistribuidoras.get(initialParams)
  }, [])

  useEffect(() => {
    requestDistribuidoras()
  }, [filters.items])

  useEffect(() => {
    if (!getDistribuidoras.paginationInfo) return

    setDistribuidora(getDistribuidoras.distribuidoras!)

    const { paginationInfo } = getDistribuidoras
    setPaginationInfo({
      pageNumber: paginationInfo.pageNumber,
      totalPages: paginationInfo.totalPages
    })
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)

    filters.stopLoading()
    pagination.stopLoading()
  }, [getDistribuidoras.paginationInfo])

  const requestDistribuidoras = (pageIndex = 0, triggerLoading = false) => {
    const _params = {
      findPowerCompanyByFilterCommand: {
        ...filters.getObjectifiedFilterItems()
      },
      paginate: {
        pageNumber: pageIndex,
        numberOfRecordsByPage: pagination.itemsPerPage
      }
    }

    getDistribuidoras.get(_params, triggerLoading)
  }

  useEffect(() => {
    if (updateDistribuidora.data) {
      requestDistribuidoras(getDistribuidoras.paginationInfo?.pageNumber)
    }
  }, [updateDistribuidora.data])

  const onClickGoToForm = () => {
    history.push('/distribuidoras/novo')
  }

  const onClickVisualize = (id: number) => () => {
    history.push(`/distribuidoras/visualizar/${id}`)
  }

  const onClickEdit = (id: number) => () => {
    history.push(`/distribuidoras/editar/${id}`)
  }

  const onClickToggleStatus = (distribuidora: TDistribuidoraInfo) => () => {
    const _distribuidora: TDistribuidoraInfo = {
      ...distribuidora,
      isActive: !distribuidora.isActive
    }

    updateDistribuidora.send(_distribuidora)
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Distribuidoras'>
            {permissions?.add && (
              <PrimaryButton onClick={onClickGoToForm} iconPosition='right'>
                <span>Adicionar Distribuidora</span>
                <AiFillPlusSquare />
              </PrimaryButton>
            )}
          </PageHeader>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>Nome Fantasia</span>
                    <Filter
                      field='name'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>CNPJ</span>
                    <Filter
                      field='cnpj'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>% Desconto</span>
                    <Filter
                      field='discountPercentage'
                      specialType='number'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Cidade</span>
                    <Filter
                      field='address.city'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Estado</span>
                    <Filter
                      field='address.uf'
                      options={ufOptions}
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {getDistribuidoras.isLoading ? (
                <TableMessageRow colSpan={tableMessageRowColSpan}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!distribuidoras.length && (
                    <TableMessageRow colSpan={tableMessageRowColSpan}>
                      Não foram encontradas distribuidoras para serem listadas
                    </TableMessageRow>
                  )}
                </>
              )}
              {distribuidoras.map(distribuidora => {
                const formattedCnpj = formatCpfCnpj(distribuidora.cnpj)
                const formattedDiscountPercentage = formatDecimalNumberToPercentage(
                  distribuidora.discountPercentage
                )
                const statusButtonTitle = distribuidora.isActive ? 'Inativar' : 'Ativar'

                return (
                  <tr key={distribuidora.id}>
                    <td>{distribuidora.name}</td>
                    <td>{formattedCnpj}</td>
                    <td>{formattedDiscountPercentage}</td>
                    <td>{distribuidora.address?.city}</td>
                    <td>{distribuidora.address?.state}</td>
                    <td>
                      <ActionButton
                        title='Visualizar'
                        onClick={onClickVisualize(distribuidora.id!)}
                      >
                        <AiOutlineEye />
                      </ActionButton>
                      {permissions?.edit && (
                        <ActionButton
                          title='Editar'
                          onClick={onClickEdit(distribuidora.id!)}
                        >
                          <BiPencil />
                        </ActionButton>
                      )}
                      {permissions?.inactivate && (
                        <ActionButton
                          title={statusButtonTitle}
                          onClick={onClickToggleStatus(distribuidora)}
                        >
                          {distribuidora.isActive ? <BiX /> : <BiCheck />}
                        </ActionButton>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          <Pagination
            currentPageIndex={paginationInfo?.pageNumber}
            totalPages={paginationInfo?.totalPages}
            request={requestDistribuidoras}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}

export default DistribuidorasLista
import React, { useState } from 'react'
import styled from 'styled-components'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import {
  Modal
} from 'react-bootstrap'

import {
  ButtonsContainer,
  SecondaryButton,
  ActionButton
} from 'components'

import headerNfeImage from 'assets/images/headerNfe.png'

const Image = styled.img`
  width: 100%;
`

type TProps = {
  title?: string
}

const HelpInfoModal = (props: TProps) => {
  const [showModal, setShowModal] = useState(false)
  const {
    title = 'Informações'
  } = props

  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  return (
    <>
      <Modal
        size='lg'
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header>
          {title}
        </Modal.Header>
        <Modal.Body>
          <Image src={headerNfeImage} />
        </Modal.Body>
        <Modal.Footer>
          <ButtonsContainer compact>
            <SecondaryButton onClick={closeModal}>
              Fechar
            </SecondaryButton>
          </ButtonsContainer>
        </Modal.Footer>
      </Modal>
      <ActionButton
        title='Informações sobre a nota fiscal'
        onClick={openModal}
        type='button'
      >
        <AiOutlineExclamationCircle />
      </ActionButton>
    </>
  )
}

export default HelpInfoModal
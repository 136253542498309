import styled from 'styled-components'
import { IconSizes } from 'utils/constants'

const getIconSize = (size?: IconSizes) => {
  switch (size) {
    case IconSizes.SMALL:
      return '15px'
    case IconSizes.MEDIUM:
      return '25px'
    case IconSizes.LARGE:
    default:
      return '35px'
  }
}

type TIconButtonProps = {
  color?: string
  size?: IconSizes
}

export const IconButton = styled.button<TIconButtonProps>`
  padding: 0;
  border: none;
  background-color: transparent;
  color: ${p => p.color
    ? p.color
    : p.theme.colors.DARK_GRAY
};

  svg {
    width: ${p => getIconSize(p.size)};
    height: ${p => getIconSize(p.size)};
  }
`
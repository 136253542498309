import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { Form, Col } from 'react-bootstrap'

import { useLogin } from 'repositories/autenticacao'
import { useAuth } from 'hooks/useAuth'
import {
  UnsignedContainer,
  Box,
  TextField,
  PrimaryButton,
  Logo,
  InfoText,
  CenteredContainer,
  Link
} from 'components'
import { Footer } from '../styles'

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Insira um e-mail válido')
    .required('Insira seu e-mail'),
  senha: yup.string().required('Insira sua senha')
})

type TEntrar = {
  email: string
  senha: string
}

const initialValues: TEntrar = {
  email: '',
  senha: ''
}

const Entrar = () => {
  const history = useHistory()
  const login = useLogin()
  const auth = useAuth()

  useEffect(() => {
    if (login.isError || !login.userInfo || !login.token) return

    auth.saveUserData({
      name: login.userInfo.name,
      token: login.token,
      role: login.userInfo.roleName,
      permissions: login.userInfo.permissions,
      id: login.userInfo.id
    })
    history.push('/')
  }, [login.userInfo])

  const signIn = () => {
    const { email, senha } = formik.values

    login.send(email, senha)
  }

  const formik = useFormik<TEntrar>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: signIn
  })

  const hasError = (name: keyof TEntrar) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TEntrar) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  return (
    <UnsignedContainer>
      <Box width={500}>
        <CenteredContainer>
          <Logo>Copérnico Energia Renovável</Logo>

          <InfoText isCentered>
            Olá. Seja bem-vindo à plataforma da Copérnico
          </InfoText>
          <InfoText isCentered>
            <strong>Utilize os campos abaixo para acessar</strong>
          </InfoText>
        </CenteredContainer>

        <Form onSubmit={formik.handleSubmit}>
          <Form.Row>
            <Col>
              <TextField
                label='E-mail'
                autoFocus
                {...getCommonFieldProps('email')}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col>
              <TextField
                label='Senha'
                type='password'
                {...getCommonFieldProps('senha')}
              />
            </Col>
          </Form.Row>

          <CenteredContainer>
            <PrimaryButton
              block
              type='submit'
              isLoading={login.isLoading}
            >
              Entrar
            </PrimaryButton>
            <Link to='/recuperacao-senha'>Esqueci minha senha</Link>
          </CenteredContainer>

          <Footer>
            <Link to='/'>Termos de Uso | Política de Privacidade</Link>
            <InfoText>Copyright © Copérnico. 2021</InfoText>
          </Footer>
        </Form>
      </Box>
    </UnsignedContainer>
  )
}

export default Entrar

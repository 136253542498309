import styled from 'styled-components'
import { Spinner, Dropdown, Form } from 'react-bootstrap'

type TButtonProps = {
  active?: boolean
}

export const Button = styled.button<TButtonProps>`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  color: ${
  p => p.active
    ? p.theme.colors.ORANGE
    : p.theme.colors.DARK_GRAY
};

  svg {
    width: 15px;
    height: 15px;
  }

  &::after {
    content: unset;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.ORANGE};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 3px;
`

export const CustomSpinner = styled(Spinner)`
  width: 17px;
  height: 17px;
`

export const DropdownContainer = styled(Dropdown.Menu)`
  padding: 10px;
  min-width: 190px;
`

export const CustomFormGroup = styled(Form.Group)`
  margin-bottom: 5px;
`
import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { cnpj as cnpjValidation } from 'cpf-cnpj-validator'
import { getStatusBancarioString } from 'utils/helpers'
import { bankStatusOptions } from 'utils/constants'

import {
  Row,
  Col,
  Table
} from 'react-bootstrap'
import { BiPencil } from 'react-icons/bi'
import { AiFillPlusSquare, AiOutlineEye } from 'react-icons/ai'
import { RiMailSendLine } from 'react-icons/ri'

import useIntegradores from 'repositories/useIntegradores'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import ConfirmationEmailModal from './Components/ConfirmationEmailModal'
import { useAuth } from 'hooks/useAuth'

import {
  Loader,
  PageHeader,
  PrimaryButton,
  ActionButton,
  Pagination,
  Filter,
  FlexContainer,
  InfoText,
  TableMessageRow
} from 'components'
import { formatResponseToOptions } from 'utils/helpers'


const IntegradoresLista = () => {
  const [integradores, setIntegradores] = useState<TIntegradorRequest[]>([])
  const [statusOptions, setStatusOptions] = useState<TSelectOption[]>([])
  const [situacoesOptions, setSituacoesOptions] = useState<TSelectOption[]>([])
  const [integradorId, setIntegradorId] = useState(0)
  const [showConfirmationEmailModal, setShowConfirmationEmailModal] = useState(false)

  const repository = useIntegradores()
  const history = useHistory()
  const pagination = usePagination()
  const filters = useFilters()
  const { userPermissions } = useAuth()

  const permissions = useMemo(() => userPermissions?.integradores, [userPermissions])

  const openConfirmationEmailModal = () => setShowConfirmationEmailModal(true)
  const closeConfirmationEmailModal = () => setShowConfirmationEmailModal(false)

  const setIntegradorIdEnvioEmail = (id: number) => () => {
    setIntegradorId(id)
    openConfirmationEmailModal()
  }

  useEffect(() => {
    requestIntegradores()
  }, [filters.items])

  useEffect(() => {
    const requestStatus = async () => {
      const response = await repository.getStatus()
      if (!response) return
      const status = formatResponseToOptions(response.integratorStatus)
      setStatusOptions(status)
    }

    const requestSituacoes = async () => {
      const response = await repository.getSituacoes()
      if (!response) return
      const situacoes = formatResponseToOptions(response.integratorSituation)
      setSituacoesOptions(situacoes)
    }

    requestStatus()
    requestSituacoes()
  }, [])

  const requestIntegradores = async (pageIndex?: number) => {
    const triggerLoading = pageIndex === undefined && !filters.items.length

    const _filters = filters.getObjectifiedFilterItems()
    const params = {
      pageSize: pagination.itemsPerPage,
      page: pageIndex ?? 0,
      ..._filters
    }

    const integradores = await repository.getIntegradores(params, triggerLoading)
    pagination.stopLoading()
    filters.stopLoading()
    if (!integradores) return

    setIntegradores(integradores.integrators)
    pagination.setOptions(integradores.number, integradores.totalPages)
  }

  const onClickGoToForm = () => {
    history.push('/integradores/novo')
  }

  const onClickVisualize = (id: number) => () => {
    history.push(`/integradores/visualizar/${id}`)
  }

  const onClickEdit = (id: number) => () => {
    history.push(`/integradores/editar/${id}`)
  }



  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Integradores'>
            {permissions?.add && (
              <PrimaryButton
                iconPosition='right'
                onClick={onClickGoToForm}
              >
                <span>Adicionar Integrador</span>
                <AiFillPlusSquare />
              </PrimaryButton>
            )}
          </PageHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <InfoText>
            Você poderá visualizar seus integradores aqui.
          </InfoText>
        </Col>
      </Row>
      <Loader isLoading={repository.isLoading} />
      {!repository.isLoading && (
        <>
          <Row>
            <Col>
              <Table responsive striped bordered>
                <thead>
                  <tr>
                    <th>
                      <FlexContainer>
                        <span>CNPJ</span>
                        <Filter
                          field='cnpj'
                          filters={filters}
                        />
                      </FlexContainer>
                    </th>
                    <th>
                      <FlexContainer>
                        <span>Razão Social</span>
                        <Filter
                          field='companyName'
                          filters={filters}
                        />
                      </FlexContainer>
                    </th>
                    <th>
                      <FlexContainer>
                        <span>Responsável</span>
                        <Filter
                          field='responsibleFullName'
                          filters={filters}
                        />
                      </FlexContainer>
                    </th>
                    <th>
                      <FlexContainer>
                        <span>Responsável Técnico</span>
                        <Filter
                          field='technicalResponsibleFullName'
                          filters={filters}
                        />
                      </FlexContainer>
                    </th>
                    <th>
                      <FlexContainer>
                        <span>Status</span>
                        <Filter
                          field='statusId'
                          filters={filters}
                          options={statusOptions}
                        />
                      </FlexContainer>
                    </th>
                    <th>
                      <FlexContainer>
                        <span>Status Bancário</span>
                        <Filter
                          field='statusBankIsBlocked'
                          filters={filters}
                          options={bankStatusOptions}
                        />
                      </FlexContainer>
                    </th>
                    <th>
                      <FlexContainer>
                        <span>Situação</span>
                        <Filter
                          field='situationId'
                          filters={filters}
                          options={situacoesOptions}
                        />
                      </FlexContainer>
                    </th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {repository.isLoading ? (
                    <TableMessageRow colSpan={8}>
                      <Loader />
                    </TableMessageRow>
                  ) : (
                    <>
                      {!integradores?.length && (
                        <TableMessageRow colSpan={8}>
                          Nenhum integrador encontrado
                        </TableMessageRow>
                      )}
                      {integradores.map(integrador => {
                        const formattedCnpj = cnpjValidation.format(integrador.cnpj)
                        return (
                          <tr key={integrador.id}>
                            <td>{formattedCnpj}</td>
                            <td>{integrador.companyName}</td>
                            <td>{integrador.responsible.fullName}</td>
                            <td>{integrador.technicalManager.fullName}</td>
                            <td>{integrador.status.description}</td>
                            <td>{getStatusBancarioString(integrador.statusBankIsBlocked)}</td>
                            <td>{integrador.situation.description}</td>
                            <td>
                              <ActionButton
                                title='Visualizar'
                                onClick={onClickVisualize(integrador.id)}
                              >
                                <AiOutlineEye />
                              </ActionButton>
                              {permissions?.edit && (
                                <ActionButton
                                  title='Editar'
                                  onClick={onClickEdit(integrador.id)}
                                >
                                  <BiPencil />
                                </ActionButton>
                              )}
                              {!integrador.hasUser && (
                                <ActionButton onClick={setIntegradorIdEnvioEmail(integrador.id)}>
                                  <RiMailSendLine />
                                </ActionButton>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Pagination
                currentPageIndex={pagination.currentPageIndex!}
                totalPages={pagination.totalPages!}
                request={requestIntegradores}
                goTo={pagination.goTo}
                goToPage={pagination.goToPage}
                loader={pagination.loader}
              />
            </Col>
          </Row>
          <ConfirmationEmailModal
            closeModal={closeConfirmationEmailModal}
            modalIsOpen={showConfirmationEmailModal}
            id={integradorId}
            reloadIntegradores={requestIntegradores}
          />
        </>
      )}
    </>
  )
}

export default IntegradoresLista
import React, { useMemo, useEffect } from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { useToasts } from 'react-toast-notifications'


import { useAuth } from 'hooks/useAuth'
import {
  useRegisterOrUpdateParametrization,
  TRegisterOrUpdateParametrizationParams,
  useGetParametrizations,
  TParametroAdesao
} from 'repositories/parametrosAdesao'

import { Container } from '../styles'
import { Col, Form } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import {
  FormLabel,
  TextField,
  ButtonsContainer,
  PrimaryButton
} from 'components'

const validationSchema = yup.object().shape({
  diasLeituraConta: yup.number()
    .required('O campo "Dias de antecedência" é obrigatório')
    .min(0, 'Insira uma quantidade de dias maior ou igual a zero')
})

type TParametrosAdesaoForm = {
  diasLeituraConta: string
}

const initialValues = {
  diasLeituraConta: '0'
}

const ParametrosAdesao = () => {
  const { userPermissions } = useAuth()
  const { addToast } = useToasts()
  const registerParametrization = useRegisterOrUpdateParametrization()
  const getParametrizations = useGetParametrizations()
  const permissions = useMemo(() => userPermissions?.parametros, [userPermissions])


  useEffect(() => {
    getParametrizations.get()
  }, [])

  useEffect(() => {
    if(!registerParametrization.data) return
    if(registerParametrization.isError) {
      addToast(
        'Algum erro ocorreu, seus parâmetros não foram salvos!',
        { appearance: 'error', autoDismiss: true }
      )
      return
    }
    addToast(
      'Parâmetros atualizados com sucesso!',
      { appearance: 'success', autoDismiss: true }
    )
  }, [registerParametrization.data])

  useEffect(() => {
    if(!getParametrizations.parametros) return
    populateForm()
  }, [getParametrizations.parametros])

  const findParameterNumeric = (parameterName: string, parametros: TParametroAdesao[]) => {
    const parameter = parametros.find( param => param.name === parameterName)
    return String(parameter?.valueNumeric)
  }

  const populateForm = () => {
    if(!getParametrizations.parametros) return
    const formattedParams: TParametrosAdesaoForm = {
      diasLeituraConta: findParameterNumeric('DAYSBEFOREREADING', getParametrizations.parametros)
    }
    formik.setValues(formattedParams)
  }

  const onSubmit = () => {
    const params: TRegisterOrUpdateParametrizationParams = {
      parametrizations: [
        {
          id: 1,
          name: 'DAYSBEFOREREADING',
          valueChar: null,
          valueNumeric: parseInt(formik.values.diasLeituraConta)
        }
      ]
    }
    registerParametrization.send(params)
  }


  const formik = useFormik<TParametrosAdesaoForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit
  })

  const hasError = (name: keyof TParametrosAdesaoForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TParametrosAdesaoForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />


  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <FormLabel
            underLined
            column
            required
            md={9}
          >
            Dias de antecedência de leitura da conta de energia
          </FormLabel>

          <Col md={3}>
            <TextField
              type='number'
              appendLabel='dias'
              disabled={!permissions?.edit}
              min={0}
              max={100}
              {...getCommonFieldProps('diasLeituraConta')}
            />
          </Col>
        </Form.Row>
        {permissions?.edit && (
          <ButtonsContainer>
            <PrimaryButton type='submit' isLoading={registerParametrization.isLoading}>
              Salvar Alterações
            </PrimaryButton>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
}

export default ParametrosAdesao
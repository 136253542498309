import styled from 'styled-components'

type TProps = {
  isCentered: boolean
}

export const Container = styled.header<TProps>`
  display: flex;
  justify-content: ${p => p.isCentered ? 'center' : 'space-between'};
  flex-wrap: wrap;
  margin-bottom: 20px;

  div {
    display: flex;
    gap: 10px;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.ORANGE};
  font-weight: 700;
`
import { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'

import { QUERY_BILLINGS, QUERY_BILLING_REPORT_LIST, REPORT_BILLINGS } from './faturasGql'

type TBillingResponse = {
  findBillingByFilter: TCommonResponse & TPaginationInfo &{
    billingSuccess: TBillingInfo[]
  }}
type TBillingReportsListResponse = {
  findBillingReportByFilter: TCommonResponse & TPaginationInfo &{
    billingReportSuccess: TBillingReportsListInfo[]
  }}

type TBillingReportResponse = {
  billingReport: TCommonResponse & {
    requestIdentification: {
      id: string
    }
  }}

export type TBillingInfo = {
  unitConsumption: string
  powerPlantName: string
  clientName: string
  month: string
  dueDate: string
  fileSent: boolean
  fileReturned: boolean
  paid: boolean
  payday: string
  copernicoBillValue: number
  cemigBillValue: number
}
export type TBillingReportsListInfo = {
  id: number
  userName: string
  link: string
  status: 'SUCCESS' | 'DELETED' | 'ERROR' | 'PROCESSING'
  errorDetail?: string
  createdDate: string
  updatedDate: string
}

export type TBillingParams = {
  findBillingCommand: TFindBillingByFilter
  paginate: TPaginationParams
}
export type TBillingReportsListParams = {
  findBillingReportCommand: TFindBillingReportsListByFilter
  paginate: TPaginationParams
}

export type TBillingReportParams = {
  findBillingCommand: TFindBillingByFilter
}

export type TFindBillingByFilter = {
  unitConsumption?: string
  powerPlantName?: string
  clientName?: string
  monthStart?: string
  monthEnd?: string
  dueDateStart?: string
  dueDateEnd?: string
  copernicoTotalStart?: number
  copernicoTotalEnd?: number
  cemigTotalStart?: number
  cemigTotalEnd?: number
  fileSent?: boolean
  fileReturned?: boolean
  paid?: boolean
  payday?: string
}
export type TFindBillingReportsListByFilter = {
  username?: string
  link?: string
  status?: string
  errorDetail?: string
  createdDate?: string
  updatedDate?: string
}

export const useGetBillings = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestBillings, { loading, data }] = useLazyQuery<TBillingResponse>(QUERY_BILLINGS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findBillingByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TBillingParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestBillings({
      variables: params
    })
  }

  return {
    isLoading: loading && isLoading,
    billings: data?.findBillingByFilter.billingSuccess,
    paginationInfo: data?.findBillingByFilter.paginationInfo,
    get
  }
}

export const useGetBillingsReport = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestBillingsReport, { loading, data }] = useLazyQuery<TBillingReportResponse>(REPORT_BILLINGS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.billingReport.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TBillingReportParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestBillingsReport({
      variables: params
    })
  }

  return {
    isLoading: loading && isLoading,
    requestIdentification: data?.billingReport.requestIdentification.id,
    commonResponse: data?.billingReport.commonResponse,
    get
  }
}

export const useGetBillingsReportsList = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestBillingReportsList, { loading, data }] = useLazyQuery<TBillingReportsListResponse>(QUERY_BILLING_REPORT_LIST, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findBillingReportByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TBillingReportsListParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestBillingReportsList({
      variables: params
    })
  }

  return {
    isLoading: loading && isLoading,
    billingReportList: data?.findBillingReportByFilter.billingReportSuccess,
    paginationInfo: data?.findBillingReportByFilter.paginationInfo,
    get
  }
}
import ApolloClient from 'apollo-boost'
import { storageKey } from './axios'

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
  request: operation => {
    const getToken = () => {
      const storageUserData = localStorage.getItem(storageKey!)
      if (!storageUserData) return ''

      return JSON.parse(storageUserData)
    }

    const { token } = getToken()

    operation.setContext({
      headers: {
        Authorization: token
      }
    })
  }
})

export default client

import styled from 'styled-components'
import { ModalBody as ModalBodyBS } from 'react-bootstrap'

export const Container = styled.div`
  form > div {
    margin-bottom: 10px;
  }
`

export const ModalBody = styled(ModalBodyBS)`
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 2rem;
`
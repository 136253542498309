import * as Yup from 'yup'

export const initialValues: TContaBancariaForm = {
  bancoId: '',
  banco: '',
  agencia: '',
  contaBancaria: '',
  contaPrincipal: 'false'
}

export const schema = Yup.object().shape({
  banco: Yup.string().required('Banco é obrigatório'),
  agencia: Yup
    .number()
    .typeError('Agência precisa ser um número')
    .min(1, 'Número da agência não pode ser 0')
    .max(999999999999999, 'O número da agência precisa ser menor que 15 dígitos')
    .required('Número da agência é obrigatória'),
  contaBancaria: Yup
    .number()
    .typeError('Conta precisa ser um número')
    .min(1, 'Número da conta não pode ser 0')
    .max(999999999999999, 'O número da conta precisa ser menor que 15 dígitos')
    .required('Número da conta é obrigatória'),
  contaPrincipal: Yup.boolean(),
})
import { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'

import { QUERY_PIPEFY_ERRORS } from './pipefyGql'

type TFindCardErrorsResponse = {
  findCardErrors: TCommonResponse & TPaginationInfo &{
    cardsErrorSuccess: TFindCardErrorsInfo[]
  }}

export type TFindCardErrorsInfo = {
  cardId: number
  clientName: string
  errorDescription: string
  createDate: string
}

export type TFindCardErrorsParams = {
  findCardErrorCommand: TFindCardErrors
  paginate: TPaginationParams
}
export type TFindCardErrors = {
  cardId?: number
  clientName?: string
  createDateStart?: string
  createDateEnd?: string
}

export const useGetPipefyErrors = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestPipefyErrors, { loading, data }] = useLazyQuery<TFindCardErrorsResponse>(QUERY_PIPEFY_ERRORS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findCardErrors.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TFindCardErrorsParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestPipefyErrors({
      variables: params
    })
  }

  return {
    isLoading: loading && isLoading,
    errors: data?.findCardErrors.cardsErrorSuccess,
    paginationInfo: data?.findCardErrors.paginationInfo,
    get
  }
}
import styled from 'styled-components'

const barColors = [
  '#FD4A1D',
  '#FE961D',
  '#FBCC2A',
  '#535353',
  '#888888',
  '#D3D3D3',
]

export const Container = styled.div`
  display: flex;
  margin-top: 10px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const YAxis = styled.ul`
  list-style: none;
  margin: 0;
  height: 239px;
  display: flex;
  flex-direction: column;
  width: 60px;
  align-items: flex-end;
  
  li {
    display: block;
    width: 7px;
    height: 40px;
    border-top: 1px solid ${({ theme }) => theme.colors.DARK_GRAY};;
    text-align: right;
    color: ${({ theme }) => theme.colors.DARK_GRAY};

    span {
      display: block;
      width: 60px;
      margin: -12px 0 0 -70px;
    }
  }
`

export const XAxis = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-around;
  color: ${({ theme }) => theme.colors.DARK_GRAY};

  li {
    height: 7px;
    border-right: 1px solid ${({ theme }) => theme.colors.DARK_GRAY};
    text-align: center;
    flex: 1;

    &:last-child {
      border: none;
    }
  }
`

export const Bars = styled.ul`
  width: calc(100% - 100px);
  height: 200px;
  list-style: none;
  margin: 0;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.DARK_GRAY};;
  border-top: none;
  border-right: none;
  display: flex;

  li {
    flex: 1;
    height: 200px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

type TBarProps = {
  completion: number
  index: number
}

export const Bar = styled.span<TBarProps>`
  display: block;
  width: 100%;
  height: ${p => p.completion}%;
  background-color: ${p => barColors[p.index]};
  list-style: none;
  margin: 0;
`
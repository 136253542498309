import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const QUERY_CEMIG_ERRORS = gql`
    query findEnergyBillQueueError(
        $paginate: Paginate,
        $findEnergyBillQueueErrorCommand:  FindEnergyBillQueueErrorCommand
    ) {
        findEnergyBillQueueError (
            paginate: $paginate,
            findEnergyBillQueueErrorCommand: $findEnergyBillQueueErrorCommand
        ) {
            ${commonResponse}
            paginationInfo{
                numberOfRecordsByPage
                pageNumber
                totalRecords
                totalPages
            }
            energyBillErrorSuccess{
                id
                cpfOrCnpj
                unitConsumption
                error
                recordedIn
            }
        }
}
`

import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams, useLocation, Redirect } from 'react-router-dom'
import { useFormik } from 'formik'
import { useToasts } from 'react-toast-notifications'

import { Row, Col, Form, Table } from 'react-bootstrap'

import { FaTimes } from 'react-icons/fa'
import { useAuth } from 'hooks/useAuth'
import {
  GoBackButton,
  PageHeader,
  ButtonsContainer,
  PrimaryButton,
  FormSubTitle,
  ActionButton,
  ConfirmButton
} from 'components'

import {
  useGetUsinas,
  useGetCondominio,
  useSaveCondominio,
} from 'repositories/condominios'

import {
  initialValues,
  schema
} from './validations'
import { confirmationMessage, Variant } from 'utils/constants'
import TextInput from 'components/Bootstrap/TextInput'
import MaskedInput from 'components/Bootstrap/MaskedInput'
import SelectInput from 'components/Bootstrap/SelectInput'

import ContasBancarias from './Components/ContasBancarias'
import ModalUsina from './Components/ModalUsina'
import { ButtonsGroup } from './style'

const initialUsinasParams = {
  findPowerPlantByFilterCommand: {
    isActive: 'true',
    available: true,
  },
  paginate: {
    numberOfRecordsByPage: 1000,
    pageNumber: 0,
  }
}

const toastCreateSucessMessage = 'Condomínio adicionado com sucesso!'
const toastUpdateSucessMessage = 'Condomínio editado com sucesso!'
const toastEmptyAccountsMessage = 'Necessário incluir pelo menos uma conta bancária!'
const toastSemContaPrincipalMessage = 'Adicione pelo menos uma conta bancária como principal'

const CondominioForm = () => {

  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { addToast } = useToasts()
  const location = useLocation()
  const getUsinas = useGetUsinas()
  const createUpdateCondominio = useSaveCondominio()
  const getCondominio = useGetCondominio()
  const { userData, userPermissions, hasFormPermission } = useAuth()
  const [showUsinaModal, setshowUsinaModal] = useState(false)

  const permission = useMemo(() => {
    const permission = userPermissions?.condominios
    return hasFormPermission(permission!, location.pathname)
  }, [userPermissions])

  const onSubmit = () => {
    if(!formik.values.accounts.length) {
      addToast(
        toastEmptyAccountsMessage,
        { appearance: 'error', autoDismiss: true }
      )
      return
    }

    const isContaPrincipalSet = formik.values.accounts.some(conta => conta.isMainAccount)

    if (!isContaPrincipalSet) {
      addToast(
        toastSemContaPrincipalMessage,
        { appearance: 'error', autoDismiss: true }
      )
      return
    }
    const { values } = formik

    const formattedPowerPlants = values.powerPlants.map(plant => (plant.id))
    const formattedAccounts = values.accounts.map(account => ({
      id: account.id,
      bank: account.bank,
      account: account.account,
      accountDigit: account.accountDigit,
      agency: account.agency,
      agencyDigit: account.agencyDigit,
      isMainAccount: account.isMainAccount
    }))

    const data = {
      id: Number(id) ?? null,
      name: values.name,
      cnpj: values.cnpj,
      isActive: values.isActive,
      accounts: formattedAccounts,
      powerPlants: formattedPowerPlants
    }

    createUpdateCondominio.save(data)
  }

  const formik = useFormik<TCondominioResponse>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })

  useEffect(() => {
    if (id) getCondominio.get(id)
  }, [id])

  useEffect(() => {
    if (userPermissions?.usinas?.add) getUsinas.get(initialUsinasParams)
  }, [])

  useEffect(() => {
    if (getCondominio.condominio) {
      formik.setValues(getCondominio.condominio)
    }
  }, [getCondominio.condominio])

  useEffect(() => {
    const { data } = createUpdateCondominio
    if (data && !data.isError) {
      history.push('/condominios')
      addToast(
        id ? toastUpdateSucessMessage : toastCreateSucessMessage,
        { appearance: 'success', autoDismiss: true }
      )
    }
  }, [createUpdateCondominio.data])

  const onClickGoToPage = (page: string) => () => history.push(page)

  const hasError = (name: keyof TCondominioResponse) => (
    formik.touched[name] && formik.errors[name]
  )

  const getErrorProps = (name: keyof TCondominioResponse) => ({
    error: { isError: hasError(name), message: formik.errors[name] }
  })

  const onChangePlant = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!event) return
    formik.setFieldValue('plantId', Number(event.target.value))
  }

  const onClickAddUsina = () => {
    const { values } = formik
    if (!getUsinas.usinas || !values.plantId) return
    const [plant] = getUsinas.usinas.filter(usina => usina.id === values.plantId)
    const plantAlreadyExist = values.powerPlants.filter(plant =>
      plant.id === values.plantId
    )
    if(plantAlreadyExist.length) return
    const data = [...values.powerPlants, plant]
    formik.setFieldValue('powerPlants', data)
    formik.setFieldValue('plantId', '')
  }

  const onClickRemoveUsina = (indexArray: number) => () => {
    const data = formik.values.powerPlants.filter((_, index) => (
      index !== indexArray
    ))
    formik.setFieldValue('powerPlants', data)
  }

  const customSetFieldValue = (
    name: keyof TCondominioResponse,
    value: TAccount[]
  ) => {
    formik.setFieldValue(name, value)
  }

  const { isVisualizando, headerTitle } = useMemo(() => {
    const url = location.pathname.split('/')[2]
    const isVisualizando = url === 'visualizar'
    return {
      isVisualizando,
      headerTitle: (
        isVisualizando ? 'Visualizar' : (id ? 'Editar' : 'Adicionar')
      )
    }
  }, [location.pathname, id])

  if (userData && !permission) return <Redirect to='/acesso-negado' />

  const onClickOpenUsinaModal = () => setshowUsinaModal(true)
  const handleCloseUsinaModal = () => setshowUsinaModal(false)

  const addNovaUsina = (usina: TPlant) => {
    const { values } = formik
    const usinas = [...values.powerPlants, usina]
    formik.setFieldValue('powerPlants', usinas)
    formik.setFieldValue('plantId', '')
    getUsinas.get(initialUsinasParams)
  }

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={!isVisualizando}
            route='/condominios'
            message={confirmationMessage}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <PageHeader title={`${headerTitle} Condomínio`} />
        </Col>
      </Row>
      <Form.Row>
        <Col md={3}>
          <MaskedInput
            required
            label='CNPJ'
            mask='99.999.999/9999-99'
            handleChange={formik.handleChange}
            disabled={isVisualizando}
            {...formik.getFieldProps('cnpj')}
            {...getErrorProps('cnpj')}
          />
        </Col>
        <Col md={9}>
          <TextInput
            required
            label='Nome do Condomínio'
            handleChange={formik.handleChange}
            disabled={isVisualizando}
            {...formik.getFieldProps('name')}
            {...getErrorProps('name')}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <FormSubTitle>Dados Bancários</FormSubTitle>
        </Col>
      </Form.Row>
      <ContasBancarias
        values={formik.values}
        customSetFieldValue={customSetFieldValue}
        isDisabled={isVisualizando}
      />

      <Form.Row>
        <Col>
          <FormSubTitle>Associar Usinas</FormSubTitle>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          Selecione as usinas que deseja associar
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={8}>
          <SelectInput
            {...formik.getFieldProps('usinaId')}
            handleChange={formik.handleChange}
            items={getUsinas.usinas}
            value={formik.values.plantId}
            properties={{ labelId: 'id', labelName: 'name' }}
            disabled={isVisualizando || !userPermissions?.usinas?.view}
            onChange={onChangePlant}
          />
        </Col>
        <Col md={4}>
          <ButtonsGroup>
            <PrimaryButton
              type='button'
              disabled={isVisualizando || !userPermissions?.usinas?.view}
              onClick={onClickAddUsina}
            >
              Incluir
            </PrimaryButton>
            <PrimaryButton
              outline
              type='button'
              disabled={isVisualizando || !userPermissions?.usinas?.add}
              onClick={onClickOpenUsinaModal}
              title={!userPermissions?.usinas?.add
                ? 'Você não tem permissão de cadastro de usina'
                : ''
              }
            >
              Cadastrar Usina
            </PrimaryButton>
          </ButtonsGroup>
        </Col>
      </Form.Row>

      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>Usinas Associadas</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {!formik.values.powerPlants.length && (
                <tr>
                  <td colSpan={2}>Nenhuma usina associada</td>
                </tr>
              )}
              {formik.values.powerPlants.map((plant, index) => (
                <tr key={index}>
                  <td>{plant?.name}</td>
                  <td>
                    <ActionButton
                      title='Desassociar'
                      onClick={onClickRemoveUsina(index)}
                      disabled={isVisualizando}
                    >
                      <FaTimes />
                    </ActionButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <ButtonsContainer>
        <ConfirmButton
          active={!isVisualizando}
          actionFn={onClickGoToPage('/condominios')}
          message={confirmationMessage}
          variant={Variant.SECONDARY}
        >
          <span>{isVisualizando ? 'Voltar' : 'Cancelar' }</span>
        </ConfirmButton>
        {!isVisualizando && (
          <PrimaryButton
            onClick={formik.handleSubmit}
            isLoading={createUpdateCondominio.loading}
          >
            <span>Salvar</span>
          </PrimaryButton>
        )}
      </ButtonsContainer>
      {userPermissions?.usinas?.add && (
        <ModalUsina
          usinasVinculadas={formik.values.powerPlants}
          addNovaUsina={addNovaUsina}
          closeModal={handleCloseUsinaModal}
          modalIsOpen={showUsinaModal}
        />
      )}
    </>
  )
}

export default CondominioForm
import React from 'react'
import { Link } from 'react-router-dom'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { Container } from './styles'

export type TMenuItemsProps = {
  route: string
  title: string
  isActive?: boolean
  icon?: React.ReactNode
  isMultiple?: boolean
  isOpen?: boolean
  closeSidebar(): void
  action?(): void
}

const MenuItem = (props: TMenuItemsProps) => {
  const {
    route,
    title,
    isActive = false,
    icon,
    isMultiple = false,
    isOpen = false,
    closeSidebar,
    action
  } = props

  const _route = isMultiple ? '#' : route

  const onClick = () => {
    closeSidebar()
    if (action) action()
  }

  return (
    <Container isActive={isActive}>
      <Link onClick={onClick} to={_route}>
        <i>{icon}</i>
        <span>{title}</span>
        <ArrowIcon isMultiple={isMultiple} isOpen={isOpen} />
      </Link>
    </Container>
  )
}

type TArrowIconProps = {
  isMultiple: boolean
  isOpen: boolean
}

const ArrowIcon = (props: TArrowIconProps) => {
  const { isMultiple, isOpen } = props

  if (!isMultiple) return null

  return <>{isOpen ? <FaAngleUp /> : <FaAngleDown />}</>
}

export default MenuItem

import * as Yup from 'yup'
import { removeMaskGuides } from 'utils/helpers'

export const initialValues: TDadosTecnicosRequest = {
  id: '',
  codigoPedido: '',
  potenciaProjeto: '',
  performance: '',
  tempoInstalacao: '',
  voltagem: '',
  valorInstalacao: '',
  potenciaEstimada: '',
  valorEstimado: '',
  valorEstimadoOrigem: '',
  totalEquipamento: '0',
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  estado: '',
  cidade: '',
  cidadeId: '',
  nomeCompleto: '',
  email: '',
  celular: '',
  crea: '',
  cargo: '',
  modulos: [],
  inversores: [],
}

export const schema = Yup.object().shape({
  codigoPedido: Yup
    .string()
    .required('Código do pedido é obrigatório'),
  potenciaProjeto: Yup
    .number()
    .min(1, 'Potência deve ser maior do que zero'),
  performance: Yup
    .number()
    .min(1, 'Desempenho deve ser maior do que zero'),
  tempoInstalacao: Yup
    .number()
    .min(1, 'Tempo de instalação deve ser maior do que zero'),
  voltagem: Yup
    .number()
    .min(1, 'Tensão deve ser maior do que zero'),
  potenciaEstimada: Yup
    .string()
    .required('Potência estimado é obrigatório')
    .test(
      'is-incorrect',
      'Potência estimada deve ser maior do que zero',
      value => (parseFloat(value!) > 0)
    ),
  valorEstimado: Yup
    .string()
    .required('Valor estimado é obrigatório')
    .test(
      'is-incorrect',
      'Valor estimado deve ser maior do que zero',
      value => (parseFloat(value!) !== 0)
    ),
  valorInstalacao: Yup
    .string()
    .required('Valor da instalação é obrigatório')
    .test(
      'is-incorrect',
      'Valor da instalação deve ser maior do que zero',
      value => (parseFloat(value!) >= 0)
    ),
  totalEquipamento: Yup
    .string()
    .required('Valor do equipamento é obrigatório')
    .test(
      'is-incorrect',
      'Valor da instalação deve ser maior do que zero',
      value => (parseFloat(value!) > 0)
    ),
  cep: Yup
    .string()
    .required('CEP é obrigatório')
    .test(
      '',
      'CEP não está com o formato válido',
      value => {
        const cleanCep = removeMaskGuides(String(value))
        const cepInputIsEmpty = cleanCep.length === 0
        return cepInputIsEmpty || cleanCep.length === 8
      }
    ),
  endereco: Yup.string().required('Logradouro é obrigatório'),
  numero: Yup
    .string()
    .max(5, 'Não pode ser maior que 5 dígitos')
    .required('Número é obrigatório'),
  bairro: Yup.string().required('Bairro é obrigatório'),
  cidade: Yup.string().required('Cidade é obrigatório'),
  estado: Yup.string().required('Estado é obrigatório'),
  email: Yup
    .string()
    .email('Email está inválido'),
  celular: Yup
    .string()
    .test(
      '',
      'Insira um celular válido',
      value => {
        const zeroedPhone = '(00) 00000-0000'
        const isPhoneZeroed = value === zeroedPhone

        return !isPhoneZeroed
      }
    ),
  crea: Yup
    .string()
    .test(
      '',
      'Crea não está com o formato válido',
      value => {
        const zeroedCrea = '000000000-0'
        const isCreaZeroed = value === zeroedCrea

        return !isCreaZeroed
      }
    )
})

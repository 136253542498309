import * as Yup from 'yup'

export const initialValues: TModule = {
  brand: '',
  model: '',
  amount: '',
  power: '',
  warrantyTerm: '',
  equipmentValue: ''
}

export const schema = Yup.object().shape({
  brand: Yup.string().required('Marca é obrigatório'),
  model: Yup.string().required('Modelo é obrigatório'),
  amount: Yup
    .number()
    .min(1, 'Quantidade deve ser maior do que zero')
    .max(999, 'Quantidade deve ser menor ou igual a 999')
    .required('Quantidade é obrigatório'),
  power: Yup
    .number()
    .min(1, 'Potência deve ser maior do que zero')
    .required('Potência é obrigatório'),
  warrantyTerm: Yup
    .number()
    .min(1, 'Garantia deve ser maior do que zero')
    .required('Garantia é obrigatório'),
  equipmentValue: Yup
    .string()
    .test(
      '',
      'Valor deve ser maior do que zero',
      value => (parseFloat(value!) > 0)
    ).required('Valor é obrigatório'),
})
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

type TOverlayProps = {
  isOpen: boolean
}

export const Overlay = styled.div<TOverlayProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: ${p => p.isOpen ? 'block' : 'none'};
  z-index: 1;
`

export const Main = styled.main`
  background-color: ${({ theme }) => theme.colors.WHITE};
  flex: 1;
`

export const Content = styled.article`
  padding: 40px;
  padding-left: 290px;
  height: 100vh;
  overflow-y: auto;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
    margin-left: 100px;
    width: calc(100vw - 100px);
    padding-left: 40px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    margin-left: 0;
    height: calc(100vh - 60px);
    width: 100vw;
  }
`
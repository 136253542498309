import React, { useEffect, useState, useMemo } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

import { FaRegUserCircle, FaPlug } from 'react-icons/fa'
import { BiMap } from 'react-icons/bi'
import { BsLightningFill } from 'react-icons/bs'
import { GiTwoCoins } from 'react-icons/gi'

import { useAuth } from 'hooks/useAuth'
import useProjetos from 'repositories/useProjetos'
import {
  formatDateTimeToView,
  formatDecimalNumber,
  formatCurrency,
} from 'utils/helpers'
import {
  Loader,
  InfoText,
  GoBackButton,
  PageHeader,
  ButtonsContainer,
  SecondaryButton,
  PrimaryButton
} from 'components'
import CancelationModal from '../Components/CancelationModal'
import Timeline from './Components/Timeline'
import {
  ActionMenu,
  ActionLink,
  StatusProgressBar,
  Box,
  BoxTitle,
  ClienteInfoContainer,
  ProjetoInfoContainer,
  ProjetoExtraInfoContainer,
  ExclamationIcon,
} from './styles'

const iconSize = 25

enum ActionMenuItems {
  CONJUNTO_DE_PROPOSTAS = 'conjuntoDePropostas',
  DOCUMENTOS = 'documentos',
  DADOS_TECNICOS = 'dodosTecnicos',
  CREDITO = 'credito',
  NOTA_FISCAL = 'notaFiscal',
  DOCUMENTOS_DE_FORMALIZACAO = 'documentosDeFormalizacao',
  DETALHES_FORMULARIO = 'detalhesFormulario',
}

const getActionMenuStatus = (
  status: number,
  item: ActionMenuItems,
  role: string | undefined,
  //mudança paliativa para resolver uma emergência em produção
  travaPasso2:boolean
) => {
  const getEnabledItems = () => {
    const levelOne = [ActionMenuItems.DADOS_TECNICOS, ActionMenuItems.DETALHES_FORMULARIO]
    const levelTwo = [...levelOne, ActionMenuItems.CONJUNTO_DE_PROPOSTAS]
    const levelThree = [...levelTwo, ActionMenuItems.DOCUMENTOS]
    const levelFour = [...levelThree, ActionMenuItems.CREDITO]
    const levelFive = (role === 'Administrador') ? [...levelFour, ActionMenuItems.NOTA_FISCAL] : [...levelFour]
    const levelSix = [...levelFive, ActionMenuItems.DOCUMENTOS_DE_FORMALIZACAO]

    switch (status) {
      case 14.29: return levelOne
      //mudança paliativa para resolver uma emergência em produção
      case 28.58: {
        if(travaPasso2)
          return levelOne
        return levelTwo
      }
      case 42.87: return levelThree
      case 57.16: return levelFour
      case 71.44: return levelFive
      case 85.72:
      case 100: return levelSix
      default: return []
    }
  }

  const enabledItems = getEnabledItems()

  if (status >= levelThreeDocumentos && ((role === 'Administrador') || item !== ActionMenuItems.NOTA_FISCAL)) return ''
  return enabledItems.includes(item) ? '' : 'disabled'
}

const levelThreeDocumentos = 42.87

const statusProjetoCriado = 1

const statusAguardandoEnvioDocumentos = 3


const Detalhes = () => {
  const [detalhes, setDetalhes] = useState<TProjetosDetalhesResponse>()
  const [documentosPendentes, setDocumentosPendentes] = useState<TDocumentosPendentesInfo>()
  const [showCancelationModal, setShowCancelationModal] = useState(false)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const repository = useProjetos()
  const { userPermissions, userData } = useAuth()
  const permissions = useMemo(() => userPermissions?.projetos, [userPermissions])
  const [hasEmail, setHasEmail] = useState(false)
  const repositoryProjetos = useProjetos()
  const [projeto, setProjeto] = useState()



  const openCancelationModal = () => setShowCancelationModal(true)
  const closeCancelationModal = () => setShowCancelationModal(false)

  useEffect(() => {
    const formatDetalhes = (details: TProjetosDetalhesResponse): TProjetosDetalhesResponse => {
      const formattedDataHoraCriacao = formatDateTimeToView(details.data_hora_criacao)
      const formattedStatus = formatDecimalNumber(details.status.percentage)
      const formattedPotencia = formatDecimalNumber(details.potencia)
      const formattedValorPrevisto = formatCurrency(details.valor_previsto)


      return {
        ...details,
        formattedDataHoraCriacao,
        formattedStatus,
        formattedPotencia,
        formattedValorPrevisto,
      }
    }

    const requestDetalhes = async () => {
      const _detalhes = await repository.getDetalhes(id)
      if (!_detalhes) return

      const formattedDetalhes = formatDetalhes(_detalhes)
      const detalhes = {
        ...formattedDetalhes,
      }
      setDetalhes(detalhes)
    }

    requestDetalhes()
    requestProjeto()
  }, [id])

  const requestProjeto = async () => {
    const triggerLoading = false
    const projeto = await repositoryProjetos.getProjeto(id, triggerLoading)
    if (projeto) setProjeto(projeto)
  }

  useEffect(() => {
    if (!detalhes) return
    const requestDocumentosPendentes = async () => {
      const pendencias = await repository.getDocumentosPendentes(id)
      if(!pendencias) return

      const formattedPendencias = {
        documentos: Boolean(pendencias.pendingInformationDocument),
        documentosFormalizacao: Boolean(pendencias.pendingFormalizationDocument),
        notasFiscais: Boolean(pendencias.pendingInvoiceDocument),
      }
      setDocumentosPendentes(formattedPendencias)
    }

    if (detalhes.status.id >= statusAguardandoEnvioDocumentos) requestDocumentosPendentes()

    if (detalhes.cliente.email === null) {
      setHasEmail(false)
    } else {
      setHasEmail(true)
    }
  }, [detalhes])

  const onClickGoToPage = (
    status: number,
    item: ActionMenuItems,
    page: string,
    //mudança paliativa para resolver uma emergência em produção
    travaPasso2:boolean
  ) => () => {
    const role = userData?.role
    const isDisabled = Boolean(getActionMenuStatus(status, item, role, travaPasso2))
    const isDisabledItem = status >= levelThreeDocumentos && ((role === 'Administrador') || item !== ActionMenuItems.NOTA_FISCAL) ? false : isDisabled

    if (!isDisabledItem) history.push(`/projetos/${page}/${id}`, { fromDetalhes: true })
  }

  const onClickGoToEditPage = () => history.push(`/projetos/editar/${id}`)

  const onClickEdit = (id: string, projeto: any) => () => {
    history.push({
      pathname: `/projetos/editar/${id}`,
      state: { data: projeto }
    })
  }

  const onClickGoToReplicatePage = () => history.push(`/projetos/replicar/${id}`)

  const canBeEdited = detalhes?.status.id === statusProjetoCriado

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton route='/projetos' />
        </Col>
      </Row>

      <Loader isLoading={repository.isLoading} />

      {detalhes && !repository.isLoading && (
        <>
          <Row>
            <Col>
              <PageHeader
                title={`Projeto "${detalhes.nome}"`}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                Através do menu de ações você pode enviar as informações
                necessárias para o andamento do projeto.
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <ActionMenu>
                <li className={getActionMenuStatus(detalhes.status.percentage, ActionMenuItems.DETALHES_FORMULARIO, userData?.role, detalhes.proposalReadyToSend)}>
                  <ActionLink onClick={onClickGoToPage(detalhes.status.percentage, ActionMenuItems.DETALHES_FORMULARIO, 'detalhes-formulario', detalhes.proposalReadyToSend)}>Detalhes do Formulário</ActionLink>
                </li>

                <li className={getActionMenuStatus(detalhes.status.percentage, ActionMenuItems.DADOS_TECNICOS, userData?.role, detalhes.proposalReadyToSend)}>
                  <ActionLink onClick={onClickGoToPage(detalhes.status.percentage, ActionMenuItems.DADOS_TECNICOS, 'dados-tecnicos', detalhes.proposalReadyToSend)}>Dados Técnicos</ActionLink>
                </li>

                <li className={getActionMenuStatus(detalhes.status.percentage, ActionMenuItems.CONJUNTO_DE_PROPOSTAS, userData?.role, detalhes.proposalReadyToSend)}>
                  <ActionLink onClick={onClickGoToPage(detalhes.status.percentage, ActionMenuItems.CONJUNTO_DE_PROPOSTAS, 'propostas', detalhes.proposalReadyToSend)}>Conjunto de propostas</ActionLink>
                </li>

                <li className={getActionMenuStatus(detalhes.status.percentage, ActionMenuItems.DOCUMENTOS, userData?.role, detalhes.proposalReadyToSend)}>
                  <ActionLink hasPendencia={documentosPendentes?.documentos} onClick={onClickGoToPage(detalhes.status.percentage, ActionMenuItems.DOCUMENTOS, 'documentos', detalhes.proposalReadyToSend)}>
                    <span>Documentos</span>
                    {documentosPendentes?.documentos && (<ExclamationIcon />)}
                  </ActionLink>
                </li>

                <li className={getActionMenuStatus(detalhes.status.percentage, ActionMenuItems.CREDITO, userData?.role, detalhes.proposalReadyToSend)}>
                  <ActionLink onClick={onClickGoToPage(detalhes.status.percentage, ActionMenuItems.CREDITO, 'credito', detalhes.proposalReadyToSend)}>Crédito</ActionLink>
                </li>

                <li className={getActionMenuStatus(detalhes.status.percentage, ActionMenuItems.NOTA_FISCAL, userData?.role, detalhes.proposalReadyToSend)}>
                  <ActionLink hasPendencia={documentosPendentes?.notasFiscais} onClick={onClickGoToPage(detalhes.status.percentage, ActionMenuItems.NOTA_FISCAL, 'notas-fiscais', detalhes.proposalReadyToSend)}>
                    <span>Notas Fiscais</span>
                    {documentosPendentes?.notasFiscais && (<ExclamationIcon />)}
                  </ActionLink>
                </li>

                <li className={getActionMenuStatus(detalhes.status.percentage, ActionMenuItems.DOCUMENTOS_DE_FORMALIZACAO, userData?.role, detalhes.proposalReadyToSend)}>
                  <ActionLink hasPendencia={documentosPendentes?.documentosFormalizacao} onClick={onClickGoToPage(detalhes.status.percentage, ActionMenuItems.DOCUMENTOS_DE_FORMALIZACAO, 'documentos-formalizacao', detalhes.proposalReadyToSend)}>
                    <span>Documentos de formalização</span>
                    {documentosPendentes?.documentosFormalizacao && (<ExclamationIcon />)}
                  </ActionLink>
                </li>
              </ActionMenu>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                <span>Status do projeto </span>
                <strong>{detalhes.formattedStatus}% completo.</strong>
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <StatusProgressBar completion={detalhes.status.percentage}>
                <span />
              </StatusProgressBar>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <Box>
                <BoxTitle spaced>
                  <FaPlug size={iconSize} />
                  <h3>Dados do Projeto</h3>
                </BoxTitle>

                <ProjetoInfoContainer>
                  <li>
                    <GiTwoCoins size={iconSize} />
                    <h4>Valor previsto</h4>
                    <span>{detalhes.formattedValorPrevisto}</span>
                  </li>

                  <li>
                    <BiMap size={iconSize} />
                    <h4>Localização</h4>
                    <span>{detalhes.cidade} - {detalhes.estado}</span>
                  </li>

                  <li>
                    <BsLightningFill size={iconSize} />
                    <h4>Potência</h4>
                    <span>{detalhes.formattedPotencia}</span>
                    <span>kWp</span>
                  </li>
                </ProjetoInfoContainer>

                <ProjetoExtraInfoContainer>
                  <strong>Criado em</strong>
                  <span>{detalhes.formattedDataHoraCriacao}</span>
                </ProjetoExtraInfoContainer>
              </Box>
              <Box>
                <BoxTitle spaced>
                  <FaRegUserCircle size={iconSize} />
                  <h3>Dados do Cliente</h3>
                </BoxTitle>

                <ClienteInfoContainer>
                  <div>
                    <dt>Nome</dt>
                    <dd>{detalhes.cliente.nome}</dd>
                  </div>

                  {hasEmail && (
                    <>
                      <div>
                        <dt>E-mail</dt>
                        <dd>{detalhes.cliente.email}</dd>
                      </div>
                    </>
                  )}

                  <div>
                    <dt>Telefone</dt>
                    <dd>{detalhes.cliente.telefone}</dd>
                  </div>
                </ClienteInfoContainer>
              </Box>
            </Col>
            <Col md={5}>
              <Timeline
                id={id}
                percentageStatus={detalhes.status.percentage}
              />
            </Col>
          </Row>

          <ButtonsContainer>
            {(permissions?.edit && canBeEdited) && (
              <PrimaryButton onClick={onClickEdit(id, projeto)}>
                Editar Projeto
              </PrimaryButton>
            )}

            {(permissions?.edit && detalhes.canBeCanceled) && (
              <>
                <SecondaryButton onClick={openCancelationModal}>
                  Cancelar Projeto
                </SecondaryButton>
                <CancelationModal
                  closeModal={closeCancelationModal}
                  modalIsOpen={showCancelationModal}
                  id={id}
                />
              </>
            )}
            {(permissions?.replicate && detalhes.canBeReplicated) && (
              <>
                <PrimaryButton
                  onClick={onClickGoToReplicatePage}
                >
                  Replicar Projeto
                </PrimaryButton>
              </>
            )}
          </ButtonsContainer>
        </>
      )}
    </>
  )
}

export default Detalhes
import { useState } from 'react'
import useRest from 'hooks/useRest'

const useParametros = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getParametros = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/parametrization'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response.parametrizations as TParametrosRequest
  }

  const putParametros = async (data: TParametrosBody, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/parametrization/${data.id}`
    const response = await rest.put(url, data)
    if (triggerLoading) setIsLoading(false)
    if(!response) return false
    return response
  }

  return {
    isLoading,
    getParametros,
    putParametros,
  }
}

export default useParametros
import { useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'
import { USINAS, USINA_MUTATION, USINA_QUERY } from './usinasGqls'

type TCreateUpdateUsinaResponse = {
  registerOrUpdatePowerPlant: TCommonResponse & {
    powerPlantSuccess: {
      id: number
      name: string
    }
  }
}

export const useCreateUpdateUsina = () => {
  const errorHandler = useErrorHandler()

  const [
    createUpdateUsina,
    { data, loading }
  ] = useMutation<TCreateUpdateUsinaResponse>(USINA_MUTATION, {
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.registerOrUpdatePowerPlant.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (usina: TUsinaInfoBody) => {
    const __accounts = usina.accounts?.map(conta => {
      return {
        account: conta.account,
        accountDigit: conta.accountDigit,
        agency: conta.agency,
        agencyDigit: conta.agencyDigit,
        bank: conta.bank,
        id: conta.id,
        isMainAccount: conta.isMainAccount
      }
    })
    const _usina = {
      ...usina,
      accounts: __accounts,
      powerPlantAddress: {
        ...usina.powerPlantAddress,
      },
    }
    createUpdateUsina({
      variables: {
        registerPowerPlantCommand: _usina
      }
    })
  }

  return {
    send,
    data,
    isError: data?.registerOrUpdatePowerPlant.commonResponse.isError,
    description: data?.registerOrUpdatePowerPlant.commonResponse.description,
    isLoading: loading
  }
}

type TUsinasResponse = {
  findPowerPlantByFilter: TComonResponse & TPaginationInfo & {
    powerPlantSuccess: TUsinaInfo[]
  }
}

export const useGetUsinas = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestUsinas, { loading, data }] = useLazyQuery<TUsinasResponse>(USINAS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findPowerPlantByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TListaUsinasParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestUsinas({
      variables: params
    })
  }

  return {
    isLoading: loading && isLoading,
    usinas: data?.findPowerPlantByFilter.powerPlantSuccess,
    paginationInfo: data?.findPowerPlantByFilter.paginationInfo,
    get
  }
}

type TUsinaResponse = {
  findPowerPlantById: TComonResponse & {
    powerPlantSuccess: TUsinaInfo
  }
}

export const useGetUsina = () => {
  const errorHandler = useErrorHandler()

  const [requestUsina, { data }] = useLazyQuery<TUsinaResponse>(USINA_QUERY, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.findPowerPlantById.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: string) => {
    requestUsina({
      variables: { id }
    })
  }

  return {
    usina: data?.findPowerPlantById.powerPlantSuccess,
    get
  }
}

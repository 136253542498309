import React from 'react'
import { Modal } from 'react-bootstrap'
import { ButtonsContainer, InfoText, PrimaryButton, SecondaryButton } from 'components'
import useIntegradores from 'repositories/useIntegradores'
import { useToasts } from 'react-toast-notifications'

const successMessage = 'Email enviado com sucesso.'

type TProps = {
  id: number
  modalIsOpen: boolean
  closeModal(): void
  reloadIntegradores?(): void
}

const ConfirmationEmailModal = (props: TProps) => {
  const {
    id,
    modalIsOpen,
    closeModal,
    reloadIntegradores
  } = props
  const { isLoading, getEmailIntegradorNovoUsuario } = useIntegradores()
  const { addToast } = useToasts()

  const onClickSendEmailNovoUsuario = async () => {
    const response = await getEmailIntegradorNovoUsuario(id)
    if (!response || response.error) return

    addToast(successMessage, {
      appearance: 'success',
      autoDismiss: true,
    })
    if (reloadIntegradores) reloadIntegradores()
    closeModal()
  }

  return (
    <>
      <Modal
        show={modalIsOpen}
        onHide={closeModal}
      >
        <Modal.Body>
          <InfoText>
            Deseja mesmo enviar um email requisitando um usuário para esse integrador ?
          </InfoText>
        </Modal.Body>

        <Modal.Footer>
          <ButtonsContainer compact>
            <SecondaryButton onClick={closeModal}>
              Não
            </SecondaryButton>

            <PrimaryButton
              type='button'
              onClick={onClickSendEmailNovoUsuario}
              isLoading={isLoading}
            >
              Sim, enviar
            </PrimaryButton>
          </ButtonsContainer>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmationEmailModal
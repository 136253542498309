import { useEffect } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'

import {
  QUERY_CONDOMINIO,
  QUERY_CONDOMINIOS,
  QUERY_CONDOMINIOS_USINAS,
  QUERY_USINAS,
  MUTATION_CONDOMINIO
} from 'repositories/condominiosGql'

type TUsina = {
  id: string
  name: string
  isActive: boolean
}

type TUsinasList = {
  powerPlantSuccess: TUsina[]
}

type TListUsinas = {
  findPowerPlantByFilter: TCommonResponse & TPaginationInfo & TUsinasList
}

type TVariableUsinasParams = {
  findPowerPlantByFilterCommand: {
    id?: number,
    isActive?: string,
    available?: boolean,
  },
  paginate: {
    pageNumber: number,
    numberOfRecordsByPage: number,
  }
}

export const useGetUsinas = () => {
  const errorHandler = useErrorHandler()

  const [
    requestUsinas,
    { loading, data }
  ] = useLazyQuery<TListUsinas>(QUERY_USINAS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  const get = (params: TVariableUsinasParams) => {
    requestUsinas({
      variables: params
    })
  }

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.findPowerPlantByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  return {
    loading,
    usinas: data?.findPowerPlantByFilter?.powerPlantSuccess,
    get
  }
}

type TVariableCondominioParams = {
  findCondominiumByFilterCommand?: {
    id?: number,
    name?: string,
  },
  paginate: {
    pageNumber: number,
    numberOfRecordsByPage: number,
  }
}

type TGetCondominios = {
  findCondominiumByFilter: TCommonResponse & TPaginationInfo & {
    condominiumSuccess: TCondominioResponse[]
  }
}

export const useGetCondominios = () => {
  const errorHandler = useErrorHandler()

  const [
    requestCondominios,
    { data, loading }
  ] = useLazyQuery<TGetCondominios>(QUERY_CONDOMINIOS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.findCondominiumByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TVariableCondominioParams) => {
    requestCondominios({
      variables: params
    })
  }

  return {
    loading,
    condominios: data?.findCondominiumByFilter.condominiumSuccess,
    paginationInfo: data?.findCondominiumByFilter.paginationInfo,
    get
  }
}

type TGetCondominio = {
  findCondominiumById: TCommonResponse & {
    condominiumSuccess: TCondominioResponse
  }
}

export const useGetCondominio = () => {
  const errorHandler = useErrorHandler()

  const [
    requestCondominio,
    { data }
  ] = useLazyQuery<TGetCondominio>(QUERY_CONDOMINIO, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.findCondominiumById.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: string) => {
    requestCondominio({
      variables: { id }
    })
  }

  return {
    condominio: data?.findCondominiumById.condominiumSuccess,
    get
  }
}


type TSaveCondominio = {
  registerOrUpdateCondominium: TCommonResponse
}


export const useSaveCondominio = () => {
  const errorHandler = useErrorHandler()

  const [
    saveCondominio,
    { loading, data }
  ] = useMutation<TSaveCondominio>(MUTATION_CONDOMINIO, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.registerOrUpdateCondominium.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const save = (body: TCondominioRequest) => {
    saveCondominio({
      variables: {
        registerCondominium: { ...body }
      }
    })
  }

  return {
    loading,
    data: data?.registerOrUpdateCondominium.commonResponse,
    save
  }
}

export const useGetCondominiosUsinas = () => {
  const errorHandler = useErrorHandler()

  const [
    requestCondominios,
    { data }
  ] = useLazyQuery<TGetCondominios>(QUERY_CONDOMINIOS_USINAS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  useEffect(() => {
    if (!data) return

    const { isError, description } = data?.findCondominiumByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TVariableCondominioParams) => {
    requestCondominios({
      variables: params
    })
  }

  return {
    condominios: data?.findCondominiumByFilter.condominiumSuccess,
    get
  }
}
import { useState } from 'react'

const useFilters = (): TFiltersHook => {
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState<TFilter[]>([])

  const getTerm = (field: string) => {
    const filter = filters.find(item => item.field === field)
    return filter?.term ?? ''
  }

  const add = (filtersToAdd: TFilter[]) => {
    setIsLoading(true)
    const _filters = [...filters, ...filtersToAdd]
    setFilters(_filters)
  }

  const remove = (fields: string[]) => {
    setIsLoading(true)
    const [field1, field2] = fields
    const filtersWithoutFirst = filters.filter(item => (
      field1 !== item.field
    ))
    const filtersWithoutSecond = filtersWithoutFirst.filter(item => (
      field2 !== item.field
    ))
    setFilters(filtersWithoutSecond)
  }

  const stopLoading = () => setIsLoading(false)

  const getObjectifiedFilterItems = () => {
    const getBooleanString = (term: string) => {
      try {
        return JSON.parse(term)
      } catch(_) {
        return term
      }
    }

    const multiLevelItems = filters.filter(
      item => item.field.split('.').length > 1
    )

    const objectifyMultiLevelItems = multiLevelItems.reduce((acc, curr) => {
      const [father, son] = curr.field.split('.')
      const fathersAcc = father in acc
        ? acc[father]
        : {}
      const term = getBooleanString(curr.term)

      return {
        ...acc,
        [father]: {
          ...fathersAcc,
          [son]: term
        }
      }
    }, {} as TMultiLevelObject )

    const singleLevelItems = filters.filter(
      item => item.field.split('.').length === 1
    )

    const objectifySingleLevelItems = singleLevelItems.reduce((acc, curr) => ({
      ...acc, [curr.field]: getBooleanString(curr.term)
    }), {})

    return {
      ...objectifyMultiLevelItems,
      ...objectifySingleLevelItems
    }
  }

  return {
    items: filters,
    getTerm,
    add,
    remove,
    isLoading,
    stopLoading,
    getObjectifiedFilterItems
  }
}

export default useFilters
import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const QUERY_INVOICING_ERRORS = gql`
    query findGenerateInvoiceLog (
        $paginate: Paginate,
        $findGenerateInvoiceLogCommand:  FindGenerateInvoiceLogCommand
    ) {
        findGenerateInvoiceLog (
            paginate: $paginate,
            findGenerateInvoiceLogCommand: $findGenerateInvoiceLogCommand
        ) {
            ${commonResponse}
            paginationInfo{
                numberOfRecordsByPage
                pageNumber
                totalRecords
                totalPages
            }
            generateInvoiceLogSuccess {
                id
                cpfOrCnpj
                status 
                referenceMonth
                description
                updatedIn
                unitConsumption
            }
        }
}
`

import styled from 'styled-components'

const lightGray = '#f5f5f5'

export const CardTermoScr = styled.div`
    font-size: 15px;
    text-align: justify;
    margin-top: 5px;
    padding: 10px 15px;
    background-color: ${lightGray};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    display: block;

    div {
        font-weight: 700;
        margin-top: 5px;
    }
`

export const ModalInfo = styled.span`
    opacity: 0.68;
    font-size: 16px;
    line-height: 2;
`
import useErrorHandler from 'hooks/useErrorHandler'
import { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import { QUERY_INVOICING_ERRORS } from './errosFaturamentoGql'
import useRest from './../hooks/useRest'

type TFindGenerateInvoiceLogResponse = {
  findGenerateInvoiceLog: TCommonResponse &
  TPaginationInfo & {
    generateInvoiceLogSuccess: TFindGenerateInvoiceLogInfo[]
  }
}

export type TFindGenerateInvoiceLogInfo = {
  id: number
  cpfOrCnpj: string
  status: 'ERROR' | 'SUCCESS'
  referenceMonth: string
  description: string
  updatedIn: string
  unitConsumption: string
}

export type TFindGenerateInvoiceLogParams = {
  findGenerateInvoiceLogCommand: TFindGenerateInvoiceLog
  paginate: TPaginationParams
}
export type TFindGenerateInvoiceLog = {
  id?: number
  cpfOrCnpj?: string
  status?: 'ERROR' | 'SUCCESS'
  referenceMonth?: string
  description?: string
  updatedIn?: string
  unitConsumption?: string
}

export const useGetInvoicingLogs = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestInvoicingLogs, { loading, data }] =
    useLazyQuery<TFindGenerateInvoiceLogResponse>(QUERY_INVOICING_ERRORS, {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error),
    })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findGenerateInvoiceLog.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (
    params: TFindGenerateInvoiceLogParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    requestInvoicingLogs({
      variables: params,
    })
  }

  return {
    isLoading: loading && isLoading,
    errors: data?.findGenerateInvoiceLog.generateInvoiceLogSuccess,
    paginationInfo: data?.findGenerateInvoiceLog.paginationInfo,
    get,
  }
}
import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Row, Col, Table } from 'react-bootstrap'
import { BiPencil, BiCheck, BiX } from 'react-icons/bi'
import { AiFillPlusSquare, AiOutlineEye } from 'react-icons/ai'

import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import { useGetUsinas, useCreateUpdateUsina } from 'repositories/usinas'
import {
  PageHeader,
  PrimaryButton,
  Pagination,
  ActionButton,
  FlexContainer,
  Filter,
  Loader,
  TableMessageRow
} from 'components'
import { initialPaginationParams, estadosOptions } from 'utils/constants'
import { formatDecimalNumber, formatDateToView } from 'utils/helpers'
import { useAuth } from 'hooks/useAuth'


const tableMessageRowColSpan = 8

const initialParams: TListaUsinasParams = {
  findPowerPlantByFilterCommand: {},
  ...initialPaginationParams
}

const UsinasLista = () => {
  const [usinas, setUsinas] = useState<TUsinaInfo[]>([])
  const [paginationInfo, setPaginationInfo] = useState<TPageProperties>()

  const history = useHistory()
  const pagination = usePagination()
  const getUsinas = useGetUsinas()
  const updateUsina = useCreateUpdateUsina()
  const filters = useFilters()
  const { userPermissions } = useAuth()

  const permissions = useMemo(() => userPermissions?.usinas, [userPermissions])

  useEffect(() => {
    getUsinas.get(initialParams)
  }, [])

  useEffect(() => {
    requestUsinas()
  }, [filters.items])

  useEffect(() => {
    if (!getUsinas.paginationInfo) return

    setUsinas(getUsinas.usinas!)

    const _paginationInfo = getUsinas.paginationInfo
    setPaginationInfo({
      pageNumber: _paginationInfo.pageNumber,
      totalPages: _paginationInfo.totalPages
    })
    pagination.setOptions(_paginationInfo.pageNumber, _paginationInfo.totalPages)

    filters.stopLoading()
    pagination.stopLoading()
  }, [getUsinas.paginationInfo])

  const requestUsinas = (pageIndex = 0, triggerLoading = false) => {
    const _params = {
      findPowerPlantByFilterCommand: {
        ...filters.getObjectifiedFilterItems()
      },
      paginate: {
        pageNumber: pageIndex,
        numberOfRecordsByPage: pagination.itemsPerPage
      }
    }

    getUsinas.get(_params, triggerLoading)
  }

  useEffect(() => {
    if (updateUsina.data) {
      requestUsinas(getUsinas.paginationInfo?.pageNumber)
    }
  }, [updateUsina.data])

  const onClickGoToForm = () => {
    history.push('/usinas/novo')
  }

  const onClickVisualize = (id: number) => () => {
    history.push(`/usinas/visualizar/${id}`)
  }

  const onClickEdit = (id: number) => () => {
    history.push(`/usinas/editar/${id}`)
  }

  const onClickToogleUsinaStatus = (usina: TUsinaInfo) => () => {
    const _usina: TUsinaInfoBody = {
      powerPlantAddress: {
        city: usina.powerPlantAddress.city,
        id: usina.powerPlantAddress.id,
        ruralName: usina.powerPlantAddress.ruralName,
        state: usina.powerPlantAddress.state
      },
      consumerUnit: usina.consumerUnit,
      id: usina.id,
      name: usina.name,
      operationStartDate: usina.operationStartDate,
      power: usina.power,
      powerPlantProductionReadingDay: usina.powerPlantProductionReadingDay,
      powerCompanyReadingDay: usina.powerCompanyReadingDay,
      powerCompany: usina.powerCompany.id!,
      isActive: !usina.isActive,
      accounts: usina.accounts
    }

    updateUsina.send(_usina)
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Usinas'>
            {permissions?.add && (
              <PrimaryButton onClick={onClickGoToForm} iconPosition='right'>
                <span>Adicionar Usina</span>
                <AiFillPlusSquare />
              </PrimaryButton>
            )}
          </PageHeader>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>Nome</span>
                    <Filter
                      field='name'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Unidade Consumidora</span>
                    <Filter
                      specialType='number'
                      field='consumerUnit'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Cidade</span>
                    <Filter
                      field='powerPlantAddress.city'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Estado</span>
                    <Filter
                      field='powerPlantAddress.state'
                      options={estadosOptions}
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Potência (KWp)</span>
                    <Filter
                      field='power'
                      specialType='number'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Início da Operação</span>
                    <Filter
                      label={[
                        'Data Início',
                        'Data Fim'
                      ]}
                      field='operationStartDateStart|operationStartDateEnd'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {getUsinas.isLoading ? (
                <TableMessageRow colSpan={tableMessageRowColSpan}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!usinas?.length && (
                    <TableMessageRow colSpan={tableMessageRowColSpan}>
                      Não foram encontradas usinas para serem listadas
                    </TableMessageRow>
                  )}
                </>
              )}
              {usinas?.map(usina => {
                const unidadeConsumidora = formatDecimalNumber(usina.consumerUnit)
                const potencia = formatDecimalNumber(usina.power)
                const dataInicioOperacao = formatDateToView(usina.operationStartDate)
                const statusButtonTitle = usina.isActive ? 'Inativar' : 'Ativar'

                return (
                  <tr key={usina.id}>
                    <td>{usina.name}</td>
                    <td>{unidadeConsumidora}</td>
                    <td>{usina.powerPlantAddress.city}</td>
                    <td>{usina.powerPlantAddress.state}</td>
                    <td>{potencia}</td>
                    <td>{dataInicioOperacao}</td>
                    <td>
                      <ActionButton
                        title='Visualizar'
                        onClick={onClickVisualize(usina.id!)}
                      >
                        <AiOutlineEye />
                      </ActionButton>
                      {permissions?.edit && usina.isActive && (
                        <ActionButton
                          title='Editar'
                          onClick={onClickEdit(usina.id!)}
                        >
                          <BiPencil />
                        </ActionButton>
                      )}
                      {permissions?.inactivate && (
                        <ActionButton
                          title={statusButtonTitle}
                          onClick={onClickToogleUsinaStatus(usina)}
                        >
                          {usina.isActive ? <BiX /> : <BiCheck />}
                        </ActionButton>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          <Pagination
            currentPageIndex={paginationInfo?.pageNumber}
            totalPages={paginationInfo?.totalPages}
            request={requestUsinas}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}

export default UsinasLista

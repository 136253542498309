import styled from 'styled-components'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Spinner } from 'react-bootstrap'

export const List = styled.ul`
  display: flex;
  flex-flow: wrap;
  list-style: none;
  margin-top: 10px;
`

export const ListItem = styled.li`
  position: relative;
  width: 200px;
  border: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 20px;
  margin: 0 20px 20px 0;
  text-align: center;

  @media screen and (max-width: 532px) {
    width: 100%;
  }

  svg {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
    width: 100%;
  }

  svg:nth-child(2) {
    position: absolute;
    top: 11px;
    left: 35px;
    height: 26px;
  }


  h4, strong, > span {
    display: block;
    font-size: 13px;
    line-height: 1.4;
    text-transform: uppercase;
  }

  h4, strong {
    font-weight: 700;
  }

  h4 {
    height: auto;
  }
`

export const ExclamationIcon = styled(AiOutlineExclamationCircle)`
  color: ${({ theme }) => theme.colors.ORANGE};
`

type TCheckIconProps = {
  isAprovado: boolean
}

export const CheckIcon = styled(IoIosCheckmarkCircleOutline) <TCheckIconProps>`
  color: ${p => p.isAprovado
    ? p.theme.colors.LIGHT_GREEN
    : p.theme.colors.MEDIUM_GRAY};
`

export const SendDocumento = styled.label`
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.ORANGE};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 6px 12px;
  cursor: pointer;
`

export const ViewDocumento = styled.label`
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: #51d624;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 6px 12px;
  cursor: pointer;
`

export const InvisibleFileInput = styled.input`
  display: none;
`

export const Loader = styled(Spinner)`
  width: 20px;
  height: 20px;
`

export const TextInfo = styled.p`
  display: flex;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  margin-bottom: 5px;
  gap: 5px;

  strong {
    margin: 0 5px;
  }
`
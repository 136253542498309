import { useState, useEffect } from 'react'
import { useMutation, useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'
import {
  QUERY_CLIENTES,
  MUTATION_CLIENTE,
  QUERY_CLIENTE,
  QUERY_ENERGY_BILL,
  QUERY_VALIDATE_INVITATION_CODE,
  MUTATION_REGISTER_CLIENTE_USER,
  QUERY_MEUS_DADOS,
  QUERY_MEUS_DADOS_USINAS_CONDOMINIOS,
  MUTATION_DELETE_ANEXOS
} from './clientesGqls'

type TCreateUpdateClienteResponse = {
  registerOrUpdateClient: TComonResponse
}

export const useCreateUpdateCliente = () => {
  const errorHandler = useErrorHandler()

  const [
    createUpdateCliente,
    { data, loading }
  ] = useMutation<TCreateUpdateClienteResponse>(MUTATION_CLIENTE, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.registerOrUpdateClient.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (cliente: Partial<TClienteInfoBody>) => {
    const _cliente = {
      ...cliente,
      clientAddress: {
        ...cliente.clientAddress,
      },
    }

    createUpdateCliente({
      variables: {
        registerClientCommand: _cliente
      }
    })
  }

  return {
    send,
    data,
    isError: data?.registerOrUpdateClient.commonResponse.isError,
    isLoading: loading
  }
}

type TClientesResponse = {
  findClientsByFilter: TComonResponse & TPaginationInfo & {
    clientSuccess: TClienteInfo[]
  }
}

export const useGetClientes = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestClientes, { loading, data }] = useLazyQuery<TClientesResponse>(QUERY_CLIENTES, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findClientsByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TListaClientesParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestClientes({
      variables: params
    })
  }

  return {
    isLoading: loading && isLoading,
    clientes: data?.findClientsByFilter.clientSuccess,
    paginationInfo: data?.findClientsByFilter.paginationInfo,
    get
  }
}

type TClienteResponse = {
  findClientById: TComonResponse & {
    clientSuccess: TClienteInfo
  }
}

export const useGetCliente = () => {
  const errorHandler = useErrorHandler()

  const [requestCliente, { data }] = useLazyQuery<TClienteResponse>(QUERY_CLIENTE, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.findClientById.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: string) => {
    requestCliente({
      variables: { id }
    })
  }

  return {
    cliente: data?.findClientById.clientSuccess,
    get
  }
}

type TContaEnergiaResponse = {
  getEnergyBillLinkById: TComonResponse & {
    publicLinkEnergyBill: string
  }
}

export const useGetContaEnergia = () => {
  const errorHandler = useErrorHandler()

  const [requestConta, { data, loading }] = useLazyQuery<TContaEnergiaResponse>(QUERY_ENERGY_BILL, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.getEnergyBillLinkById.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: number) => {
    requestConta({
      variables: { id }
    })
  }

  return {
    loading,
    conta: data?.getEnergyBillLinkById.publicLinkEnergyBill,
    get
  }
}

type TValidacaoCondigoConviteResponse = {
  getStatusInvitationToken: TComonResponse & {
    success: {
      name: string
      email: string
    }
  }
}

export const useGetValidacaoCodigoConvite = () => {
  const errorHandler = useErrorHandler()

  const [requestValidacao, { data, loading }] = useLazyQuery<TValidacaoCondigoConviteResponse>(
    QUERY_VALIDATE_INVITATION_CODE,
    {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error)
    }
  )

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.getStatusInvitationToken.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (token: string) => {
    requestValidacao({
      variables: { authToken: token }
    })
  }

  return {
    loading,
    info: data?.getStatusInvitationToken.success,
    mensagem: data?.getStatusInvitationToken.commonResponse.description,
    get
  }
}

type TClienteUserBody = {
  name: string
  email: string
  passwordHash: string
  authToken: string
}

type TCreateClienteUserResponse = {
  registerNewUserFromClient: TComonResponse
}

export const useCreateClienteUser = () => {
  const errorHandler = useErrorHandler()

  const [
    createClienteUser,
    { data, loading }
  ] = useMutation<TCreateClienteUserResponse>(MUTATION_REGISTER_CLIENTE_USER, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.registerNewUserFromClient.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (_data: TClienteUserBody) => {
    createClienteUser({
      variables: {
        registerUserFromClientCommand: _data
      }
    })
  }

  return {
    send,
    data,
    isError: data?.registerNewUserFromClient.commonResponse.isError,
    isLoading: loading
  }
}

type TMeusDadosResponse = {
  findMyDataRegisterById: TComonResponse & {
    myDataRegister: TMeusDadosInfo
  }
}

type TMeusDadosUsinasCondominioResponse = {
  findMyDataRegisterById: TComonResponse & {
    myDataRegister: TMeusDadosUsinasCondominio
  }
}

export const useGetMeusDados = () => {
  const errorHandler = useErrorHandler()

  const [requestMeusDados, { data }] = useLazyQuery<TMeusDadosResponse>(
    QUERY_MEUS_DADOS,
    {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error),
    }
  )

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.findMyDataRegisterById.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: number) => {
    requestMeusDados({
      variables: { id },
    })
  }

  return {
    meusDados: data?.findMyDataRegisterById.myDataRegister,
    get,
  }
}

export const useGetMeusDadosUsinasCondominio = () => {
  const errorHandler = useErrorHandler()

  const [requestMeusDados, { data }] = useLazyQuery<TMeusDadosUsinasCondominioResponse>(
    QUERY_MEUS_DADOS_USINAS_CONDOMINIOS,
    {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error),
    }
  )

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.findMyDataRegisterById.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: number) => {
    requestMeusDados({
      variables: { id },
    })
  }

  return {
    meusDadosUsinasCondominio: data?.findMyDataRegisterById.myDataRegister,
    get,
  }
}

export const useCreateUpdateMeusDadosCliente = () => {
  const errorHandler = useErrorHandler()

  const [
    createUpdateCliente,
    { data, loading }
  ] = useMutation<TCreateUpdateClienteResponse>(MUTATION_CLIENTE, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.registerOrUpdateClient.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (cliente: TMeusDadosUpdate) => {
    const _cliente = {
      ...cliente,
      clientAddress: {
        ...cliente.clientAddress,
      },
    }

    createUpdateCliente({
      variables: {
        registerClientCommand: _cliente
      }
    })
  }

  return {
    send,
    data,
    isError: data?.registerOrUpdateClient.commonResponse.isError,
    isLoading: loading
  }
}


type TDeleteAnexoResponse = {
  deleteEnergyBillByListId: TComonResponse
}

export const useDeleteAnexos = () => {
  const errorHandler = useErrorHandler()

  const [deleteAnexos, { data }] = useMutation<TDeleteAnexoResponse>(
    MUTATION_DELETE_ANEXOS,
    {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error)
    }
  )

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.deleteEnergyBillByListId.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const _delete = (ids: number[]) => {
    deleteAnexos({
      variables: { listId: ids }
    })
  }

  return {
    data: data?.deleteEnergyBillByListId.commonResponse,
    _delete
  }
}
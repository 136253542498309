import React from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'

import {
  ActionButton
} from 'components'

const HelpInfoTooltip = () => {
  return (
    <OverlayTrigger
      key='top'
      placement='top'
      overlay={
        <Tooltip id='info-tooltip'>
          As notas fiscais de serviço não possuem um
          layout unico, o mesmo possui variações conforme o município do prestador.
        </Tooltip>
      }
    >
      <ActionButton type='button'>
        <AiOutlineExclamationCircle />
      </ActionButton>
    </OverlayTrigger>
  )
}

export default HelpInfoTooltip
import styled from 'styled-components'

export const Container = styled.header`
  padding: 18px 36px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  display: none;
  justify-content: space-between;

  div {
    display: flex;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    display: flex;
  }
`

export const MenuButton = styled.button`
  display: inline;
  color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
  margin-right: 18px;
  border: none;

  svg {
    width: 20px;
    height: 20px;
  }
`

export const Title = styled.h2`
  display: inline;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
  letter-spacing: 1px;

  span {
    font-weight: 300;
    margin-right: 5px;
  }
`

export const SearchInput = styled.div`
  height: 20px;
  color: ${({ theme }) => theme.colors.MEDIUM_GRAY};

  svg {
    width: 20px;
    height: 20px;
    margin-right: 18px;
  }

  input {
    width: 200px;
    background-color: transparent;
    border: none;
    letter-spacing: 1px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
    opacity: 0.5;

    &:focus {
      outline: none;
    }
  }
`
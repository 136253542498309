import React from 'react'
import { Form } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import {
  FormLabel,
  FlexContainer
} from 'components'

type TProps = {
  required?: boolean
  mask: string | (string | RegExp)[]
  name: string
  label?: string
  placeholder?: string
  emptyLabel?: string
  description?: string
  isInvalid?: boolean
  errorMessage?: string
  value?: string
  onChange(e: TInputEvent): void
  disabled?: boolean
  helpIcon?: React.ReactNode
}

const MaskedTextField = (props: TProps) => {
  const {
    required,
    label,
    name,
    isInvalid,
    errorMessage,
    description,
    disabled = false,
    helpIcon,
    ...rest
  } = props

  return (
    <Form.Group>
      <FlexContainer>
        <FormLabel required={required}>
          {label}
        </FormLabel>
        {helpIcon}
      </FlexContainer>

      <InputMask
        id={name}
        className={`form-control ${isInvalid && 'is-invalid'}`}
        disabled={disabled}
        {...rest}
        autoComplete='disabled'
      />

      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>

      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default MaskedTextField

import useRest from 'hooks/useRest'
import { useState } from 'react'

const useInvoiceCalculate = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const postCalculateInvoice = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/energy-bill/invoice/calculate'
    const response = await rest.post(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response.requestIdentificationId as string
  }

  return { isLoading, postCalculateInvoice }
}

export default useInvoiceCalculate

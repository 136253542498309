import React, { useEffect, useMemo } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useGetEndereco } from 'repositories/general'
import {
  RadioField,
  TextField,
  MaskedTextField,
  SelectField,
  FormTitle,
  FormDivider,
  ButtonsContainer,
  PrimaryButton
} from 'components'
import { removeMaskGuides } from 'utils/helpers'
import {
  PessoaTipo,
  tipoEnderecoOptions,
  TiposEndereco,
  tipoPessoaOptions
} from 'utils/constants'
import { useGetMeusDados, useCreateUpdateMeusDadosCliente } from 'repositories/clientes'
import { useToasts } from 'react-toast-notifications'
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator'
import { useAuth } from 'hooks/useAuth'
import { Redirect } from 'react-router'

const toastUpdateSucessMessage = 'Dados editados com sucesso!'

const validationSchema = yup.object().shape({
  cpf: yup.string().when('tipoPessoa', {
    is: 'F',
    then: yup.string()
      .required('O campo "CPF" é obrigatório')
      .test(
        '',
        'O CPF informado não é válido',
        value => cpfValidator.isValid(value!)
      ),
    otherwise: yup.string()
  }),
  razaoSocial: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string().required('O campo "Razão Social" é obrigatório'),
    otherwise: yup.string()
  }),
  cnpj: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string()
      .required('O campo "CNPJ" é obrigatório')
      .test(
        '',
        'O CNPJ informado não é válido',
        value => cnpjValidator.isValid(value!)
      ),
    otherwise: yup.string()
  }),
  cep: yup.string()
    .required('O campo "CEP" é obrigatório')
    .test(
      '',
      'Insira um CEP válido',
      value => {
        const zeroedCEP = '00000-000'
        const isCEPZeroed = value === zeroedCEP

        const cleanCEP = removeMaskGuides(String(value))
        const isCEPIncomplete = cleanCEP.length !== 8

        return (!isCEPIncomplete && !isCEPZeroed)
      }
    ),
  cidade: yup.string().required('O campo "Cidade" é obrigatório'),
  estado: yup.string().required('O campo "Estado" é obrigatório'),
  bairro: yup.string().required('O campo "Bairro" é obrigatório'),
  tipoEndereco: yup.string().required('O campo "Tipo de Endereço" é obrigatório'),
  logradouro: yup.string().required('O campo "Logradouro" é obrigatório'),
  numero: yup.number()
    .required('O campo "Número" é obrigatório')
    .moreThan(0, 'Insira um número válido')
    .typeError('Insira um número válido'),
  contato: yup.string().required('O campo "Contato" é obrigatório'),
  telefone: yup.string()
    .required('O campo "Telefone" é obrigatório')
    .test(
      '',
      'Insira um telefone válido',
      value => {
        const zeroedPhone = '(00) 00000-0000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = cleanPhone.length < 10

        return (!isPhoneIncomplete && !isPhoneZeroed)
      }
    )
    .max(15, 'Insira um telefone válido'),
  email: yup.string()
    .required('O campo "E-mail" é obrigatório')
    .email('Insira um e-mail válido')
})

type TMeusDadosForm = {
  id: string
  tipoPessoa: PessoaTipo
  nome: string
  cpf: string
  razaoSocial: string
  cnpj: string
  cep: string
  cidade: string
  estado: string
  bairro: string
  tipoEndereco: TiposEndereco
  logradouro: string
  numero: string
  complemento: string
  contato: string
  telefone: string
  email: string
  mediaAnualConsumo: string
  representanteComercial: string
  empresaRepresentacaoComercial: string
}

const initialValues: TMeusDadosForm = {
  id: '',
  tipoPessoa: PessoaTipo.FISICA,
  nome: '',
  cpf: '',
  razaoSocial: '',
  cnpj: '',
  cep: '',
  cidade: '',
  estado: '',
  bairro: '',
  tipoEndereco: TiposEndereco.RUA,
  logradouro: '',
  numero: '',
  complemento: '',
  contato: '',
  telefone: '',
  email: '',
  mediaAnualConsumo: '0',
  representanteComercial: '',
  empresaRepresentacaoComercial: ''
}

const Cadastro = () => {
  const getEndereco = useGetEndereco()
  const getMeusDados = useGetMeusDados()
  const updateUsuario = useCreateUpdateMeusDadosCliente()
  const { addToast } = useToasts()
  const { userData, userPermissions } = useAuth()
  const userId = userData?.id
  const permissions = useMemo(() => userPermissions?.meusDados, [userPermissions])

  const userHasPermissionsEditMyData = useMemo(() => (
    userData?.permissions.find(
      permission => permission === 'MY_DATA_EDIT'
    )
  ), [userData])

  useEffect(() => {
    if (userId) getMeusDados.get(userId)
  }, [userId])

  useEffect(() => {
    if (!getMeusDados.meusDados) return

    const { meusDados } = getMeusDados
    const _meusDados: TMeusDadosForm = {
      id: String(meusDados.id),
      tipoPessoa: meusDados.typePerson,
      nome: meusDados.fullName ?? '',
      cpf: meusDados.cpf ?? '',
      razaoSocial: meusDados.companyName ?? '',
      cnpj: meusDados.cnpj ?? '',
      cep: meusDados.clientAddress.zipCode,
      cidade: meusDados.clientAddress.city,
      estado: String(meusDados.clientAddress.uf),
      bairro: meusDados.clientAddress.neighborhood,
      tipoEndereco: meusDados.clientAddress.addressType as TiposEndereco,
      logradouro: meusDados.clientAddress.street,
      numero: meusDados.clientAddress.number,
      complemento: meusDados.clientAddress.complement,
      contato: meusDados.contactName,
      telefone: meusDados.telephone,
      email: meusDados.email,
      mediaAnualConsumo: `${meusDados.kwh}`,
      representanteComercial: meusDados.tradeRepresentative ?? '',
      empresaRepresentacaoComercial: meusDados.commercialRepresentationCompany ?? '',
    }

    formik.setValues(_meusDados, true)
  }, [getMeusDados.meusDados])

  const onSubmit = () => {
    const { values } = formik

    const meusDadosBody: TMeusDadosUpdate = {
      id: Number(values.id) ?? null,
      typePerson: values.tipoPessoa as PessoaTipo,
      fullName: values.nome ?? null,
      cpf: values.cpf ?? null,
      companyName: values.razaoSocial ?? null,
      cnpj: values.cnpj ?? null,
      telephone: values.telefone,
      email: values.email,
      contactName: values.contato,
      kwh: Number(values.mediaAnualConsumo),
      tradeRepresentative: values.representanteComercial,
      commercialRepresentationCompany: values.empresaRepresentacaoComercial,
      clientAddress: {
        zipCode: values.cep,
        street: values.logradouro,
        number: String(values.numero),
        complement: values.complemento,
        neighborhood: values.bairro,
        addressType: values.tipoEndereco,
        city: values.cidade,
        uf: values.estado
      },
    }

    updateUsuario.send(meusDadosBody)
  }

  const formik = useFormik<TMeusDadosForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit
  })

  useEffect(() => {
    if (!getEndereco.endereco) return
    const { localidade, uf, bairro, logradouro } = getEndereco.endereco

    formik.setFieldValue('cidade', localidade, true)
    formik.setFieldValue('estado', uf, true)
    formik.setFieldValue('bairro', bairro, true)
    formik.setFieldValue('logradouro', logradouro, true)
  }, [getEndereco.endereco])

  useEffect(() => {
    if (!updateUsuario.data || updateUsuario.isError) return

    addToast(
      toastUpdateSucessMessage,
      { appearance: 'success', autoDismiss: true }
    )
  }, [updateUsuario.data])

  const hasError = (name: keyof TMeusDadosForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TMeusDadosForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const onChangeCEP = (e: TInputEvent) => {
    const { value: cep } = e.target
    formik.handleChange(e)

    const cepNumbers = cep.replace(/_/g, '').replace(/-/g, '')
    if (cepNumbers.length === 8) {
      getEndereco.get(cep)
      return
    }

    formik.setFieldValue('cidade', '')
    formik.setFieldValue('estado', '')
    formik.setFieldValue('bairro', '')
    formik.setFieldValue('logradouro', '')
  }

  const customRadioOnChange = (field: keyof TMeusDadosForm, value: string) => {
    formik.setFieldValue(field, value, true)
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Row>
        <Col>
          <RadioField
            label='Tipo Pessoa'
            options={tipoPessoaOptions}
            required
            disabled={!userHasPermissionsEditMyData}
            {...getCommonFieldProps('tipoPessoa')}
            onChange={customRadioOnChange}
          />
        </Col>
      </Form.Row>

      {formik.values.tipoPessoa === 'F' ? (
        <Form.Row>
          <Col md={8}>
            <TextField
              label='Nome'
              required
              disabled={!userHasPermissionsEditMyData}
              {...getCommonFieldProps('nome')}
            />
          </Col>

          <Col md={4}>
            <MaskedTextField
              label='CPF'
              mask='999.999.999-99'
              placeholder='000.000.000-00'
              disabled={!userHasPermissionsEditMyData}
              required
              {...getCommonFieldProps('cpf')}
            />
          </Col>
        </Form.Row>
      ) : (
        <Form.Row>
          <Col md={8}>
            <TextField
              label='Razão Social'
              disabled={!userHasPermissionsEditMyData}
              required
              {...getCommonFieldProps('razaoSocial')}
            />
          </Col>

          <Col md={4}>
            <MaskedTextField
              label='CNPJ'
              mask='99.999.999/9999-99'
              placeholder='00.000.000/0000-00'
              disabled={!userHasPermissionsEditMyData}
              required
              {...getCommonFieldProps('cnpj')}
            />
          </Col>
        </Form.Row>
      )}

      <Form.Row>
        <Col md={2}>
          <MaskedTextField
            label='CEP'
            mask='99999-999'
            placeholder='00000-000'
            disabled={!userHasPermissionsEditMyData}
            required
            {...getCommonFieldProps('cep')}
            onChange={onChangeCEP}
          />
        </Col>

        <Col md={5}>
          <TextField
            label='Cidade'
            required
            disabled
            {...getCommonFieldProps('cidade')}
          />
        </Col>

        <Col md={5}>
          <TextField
            label='Estado'
            disabled
            required
            {...getCommonFieldProps('estado')}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={3}>
          <SelectField
            label='Tipo de Endereço'
            options={tipoEnderecoOptions}
            disabled={!userHasPermissionsEditMyData}
            {...getCommonFieldProps('tipoEndereco')}
          />
        </Col>

        <Col md={7}>
          <TextField
            label='Logradouro'
            disabled={!userHasPermissionsEditMyData}
            required
            {...getCommonFieldProps('logradouro')}
          />
        </Col>

        <Col md={2}>
          <TextField
            label='Número'
            type='number'
            min={0}
            disabled={!userHasPermissionsEditMyData}
            required
            {...getCommonFieldProps('numero')}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={6}>
          <TextField
            label='Bairro'
            disabled={!userHasPermissionsEditMyData}
            required
            {...getCommonFieldProps('bairro')}
          />
        </Col>

        <Col md={6}>
          <TextField
            label='Complemento'
            disabled={!userHasPermissionsEditMyData}
            {...getCommonFieldProps('complemento')}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <TextField
            label='Contato'
            disabled={!userHasPermissionsEditMyData}
            required
            {...getCommonFieldProps('contato')}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={4}>
          <MaskedTextField
            label='Telefone'
            mask='(99) 99999-9999'
            placeholder='(00) 00000-0000'
            disabled={!userHasPermissionsEditMyData}
            required
            {...getCommonFieldProps('telefone')}
          />
        </Col>

        <Col md={8}>
          <TextField
            label='E-mail'
            type='email'
            disabled={!userHasPermissionsEditMyData}
            required
            {...getCommonFieldProps('email')}
          />
        </Col>
      </Form.Row>

      <FormDivider />

      <Row>
        <Col>
          <FormTitle>Dados Técnicos</FormTitle>
        </Col>
      </Row>

      <Form.Row>
        <Col md={12}>
          <TextField
            label='Média Anual de Consumo (kWh)'
            type='number'
            min={0}
            required
            disabled
            {...getCommonFieldProps('mediaAnualConsumo')}
          />
        </Col>
      </Form.Row>

      <FormDivider />

      <Row>
        <Col>
          <FormTitle>Representação Comercial</FormTitle>
        </Col>
      </Row>

      <Form.Row>
        <Col md={6}>
          <TextField
            label='Representante Comercial'
            disabled
            required
            {...getCommonFieldProps('representanteComercial')}
          />
        </Col>

        <Col md={6}>
          <TextField
            label='Empresa de Representação Comercial'
            disabled
            required
            {...getCommonFieldProps('empresaRepresentacaoComercial')}
          />
        </Col>
      </Form.Row>

      <ButtonsContainer>
        <PrimaryButton
          type='submit'
          isLoading={false}
          disabled={!userHasPermissionsEditMyData}
        >
          Salvar
        </PrimaryButton>
      </ButtonsContainer>
    </Form>
  )
}

export default Cadastro
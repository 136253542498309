import React, { useEffect, useMemo, useState } from 'react'
import { useAuth } from 'hooks/useAuth'
import { useLogout } from 'repositories/autenticacao'
import {
  Container,
  Picture,
  Info,
  ArrowIconDown,
  ArrowIconUp
} from './styles'
import MenuItem from '../MenuItem'
import { PermissionsName } from '..'
import { Menu } from '../styles'
import profilePicture from 'assets/images/user.png'

type TProps = {
  closeSidebar(): void
}

const getShortUserName = (name: string) => {
  const nameArray = name.split(' ')
  const firstIndex = 0
  const lastIndex = nameArray.length - 1

  const firstName = nameArray[firstIndex]
  const lastName = nameArray[lastIndex]
  const shortUserName = nameArray.length === 1
    ? firstName
    : `${firstName} ${lastName}`

  return shortUserName
}

const Profile = ({ closeSidebar }: TProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { userData, removeUserData } = useAuth()
  const logout = useLogout()

  const userHasPermissionsMyData = useMemo(() => (
    userData?.permissions.find(
      permission => permission === `${PermissionsName.MEUS_DADOS}_VIEW`
    )
  ), [userData])

  useEffect(() => {
    if (logout.data) removeUserData()
  }, [logout.data])

  const onClickToggleOpen = () => setIsOpen(!isOpen)

  const logoutAction = () => logout.send()

  const shortUserName = userData ? getShortUserName(userData.name) : ''

  const ArrowIcon = () => (isOpen ? <ArrowIconUp /> : <ArrowIconDown />)
  return (

    <Container>
      <div>
        <Picture src={profilePicture} />
        <Info onClick={onClickToggleOpen} className='profileMenu'>
          <div>
            <h3>{shortUserName}</h3>
            <ArrowIcon />
          </div>
          <span>{userData?.role}</span>
        </Info>
      </div>
      {isOpen && (
        <Menu>
          {userHasPermissionsMyData && (
            <MenuItem
              route='/clientes/meus-dados'
              title='Meus Dados'
              closeSidebar={closeSidebar}
            />
          )}
          <MenuItem
            route=''
            title='Sair'
            closeSidebar={closeSidebar}
            action={logoutAction}
          />
        </Menu>
      )}
    </Container>
  )
}

export default Profile

import React from 'react'
import { Modal, Table } from 'react-bootstrap'
import { ModalBody } from '../styles'
import { formatCpfCnpj } from 'utils/helpers'
import { PrimaryButton } from 'components'

type TProps = {
  selectedCondominio?: TCondominioResponse
  showModal: boolean
  handleCloseModal(): void
}

const ClientesModal = (props: TProps) => {
  const { selectedCondominio, showModal, handleCloseModal } = props
  return (
    <Modal show={showModal} size='lg' onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Clientes Alocados ao Condomínio &quot;{selectedCondominio?.name}&quot;</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th>Nome / Razão Social</th>
              <th>CPF / CNPJ</th>
            </tr>
          </thead>

          <tbody>
            {selectedCondominio?.allocatedClients.map(cliente => {
              const cpfCnpj = cliente.cpf || cliente.cnpj
              const formattedCpfCnpj = cpfCnpj && formatCpfCnpj(cpfCnpj)

              return (
                <tr key={cpfCnpj}>
                  <td>{cliente.fullName || cliente.companyName}</td>
                  <td>{formattedCpfCnpj}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ModalBody>
      <Modal.Footer>
        <PrimaryButton onClick={handleCloseModal}>
            Fechar
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ClientesModal
import React, { useRef, useMemo } from 'react'
import { useFormik } from 'formik'

import {
  Col,
  Form
} from 'react-bootstrap'

import {
  Container,
  Content,
  EmptyAccounts,
  AccountForm,
  AccountsContent,
  CustomButton
} from './styles'
import DropdownSelectBank from 'components/Bootstrap/DropdownSelectBank'
import TextInput from 'components/Bootstrap/TextInput'

import { initialValues, schema } from './validation'
import { PrimaryButton } from 'components'
import SelectInput from 'components/Bootstrap/SelectInput'
import { TiTimes } from 'react-icons/ti'
import { Typeahead } from 'react-bootstrap-typeahead'
import MaskedInput from 'components/Bootstrap/MaskedInput'

const accountDefaultArray = [
  { value: true, description: 'Sim' },
  { value: false, description: 'Não' }
]

type TProps = {
  values: TCondominioResponse,
  customSetFieldValue(
    field: keyof TCondominioResponse,
    value: TAccount[]
  ): void,
  isDisabled?: boolean
}

type TOption = {
  id: string,
  name: string
}

const ContasBancarias = (props: TProps) => {
  const {
    values,
    customSetFieldValue,
    isDisabled = false
  } = props
  const bankRef = useRef<Typeahead<string>>(null)

  const handleSubmit = () => {
    const conta = {
      ...formik.values,
      isMainAccount: JSON.parse(formik.values.isMainAccount)
    }
    const data = [...values.accounts, conta]
    customSetFieldValue('accounts', data)

    const isContaPrincipalSet = data.some(conta => conta.isMainAccount)
    const resetedValues = {
      ...initialValues,
      isMainAccount: isContaPrincipalSet ? String(false) : ''
    }
    formik.setValues(resetedValues, false)
    bankRef.current?.clear()
  }

  const isContaPrincipalSet = useMemo(() => (
    values.accounts.some(conta => conta.isMainAccount)
  ), [values.accounts])

  const _initialValues = useMemo(() => ({
    ...initialValues,
    isMainAccount: isContaPrincipalSet ? 'false' : ''
  }), [initialValues, isContaPrincipalSet])

  const formik = useFormik<TAccount>({
    initialValues: _initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: handleSubmit,
  })

  const onClickRemoveAccount = (indexArray: number) => () => {
    const data = values.accounts.filter((_, index) => (
      index !== indexArray
    ))
    customSetFieldValue('accounts', data)
  }

  const onChangeTypeahead = (
    name: keyof TAccount
  ) => ([option]: TOption[]) => {
    formik.setFieldValue(name, option?.name, true)
  }

  const onBlurTypeahead = (name: keyof TAccount) => () => {
    formik.setFieldTouched(name, true)
    if (!formik.values.bank) bankRef.current?.clear()
  }

  const hasError = (name: keyof TAccount) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getErrorProps = (name: keyof TAccount) => ({
    error: { isError: hasError(name), message: formik.errors[name] }
  })

  return (
    <Container>
      <AccountForm md={7}>
        <form onSubmit={formik.handleSubmit}>
          <Form.Row>
            <Col md={8}>
              <DropdownSelectBank
                label='Nome do banco'
                disabled={isDisabled}
                {...formik.getFieldProps('bank')}
                id='bank'
                onChange={onChangeTypeahead('bank')}
                onBlur={onBlurTypeahead('bank')}
                required
                elementRef={bankRef}
                {...getErrorProps('bank')}
              />
            </Col>

            <Col md={4}>
              <SelectInput
                {...formik.getFieldProps('isMainAccount')}
                required
                label='Principal ?'
                handleChange={formik.handleChange}
                items={accountDefaultArray}
                properties={{ labelId: 'value', labelName: 'description' }}
                disabled={isDisabled || isContaPrincipalSet}
                {...getErrorProps('isMainAccount')}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col md={7}>
              <TextInput
                required
                type='number'
                label='Agência'
                {...formik.getFieldProps('agency')}
                handleChange={formik.handleChange}
                disabled={isDisabled}
                {...getErrorProps('agency')}
              />
            </Col>

            <Col md={5}>
              <MaskedInput
                required
                mask='*'
                label='Dígito Agência'
                handleChange={formik.handleChange}
                disabled={isDisabled}
                {...formik.getFieldProps('agencyDigit')}
                {...getErrorProps('agencyDigit')}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col md={7}>
              <TextInput
                required
                type='number'
                label='Conta Corrente/Poupança'
                {...formik.getFieldProps('account')}
                handleChange={formik.handleChange}
                disabled={isDisabled}
                {...getErrorProps('account')}
              />
            </Col>

            <Col md={5}>
              <MaskedInput
                required
                mask='*'
                label='Dígito da Conta'
                handleChange={formik.handleChange}
                disabled={isDisabled}
                {...formik.getFieldProps('accountDigit')}
                {...getErrorProps('accountDigit')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <PrimaryButton
                type='submit'
                disabled={isDisabled}
              >
                Incluir
              </PrimaryButton>
            </Col>
          </Form.Row>
        </form>
      </AccountForm>
      <AccountsContent md={5}>
        {!values.accounts.length && (
          <EmptyAccounts>
            Nenhuma conta bancária cadastrada
          </EmptyAccounts>
        )}
        {values.accounts.map((account, index) => (
          <Content key={index}>
            <Form.Row>
              <Col md={5}>
                <strong>Banco </strong><br />{account.bank}
              </Col>
              <Col md={3}>
                <strong>Agência </strong><br />{account.agency}
              </Col>
              <Col md={4}>
                <strong>Dígito </strong><br />{account.agencyDigit}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={5}>
                <strong>Conta </strong><br />{account.account}
              </Col>
              <Col md={4}>
                <strong>Digito </strong><br />{account.accountDigit}
              </Col>
              <Col md={3}>
                <strong>Principal? </strong><br />{account.isMainAccount ? 'Sim' : 'Não'}
              </Col>
            </Form.Row>
            {!isDisabled && (
              <CustomButton
                className='mr-1 text-danger'
                title='Excluir'
                onClick={onClickRemoveAccount(index)}
              >
                <TiTimes />
              </CustomButton>
            )}
          </Content>
        ))}
      </AccountsContent>
    </Container>
  )
}

export default ContasBancarias
import React, { useState, useEffect, useMemo } from 'react'
import {
  PageHeader,
  TextField,
  MaskedTextField,
  PrimaryButton,
  ButtonsContainer
} from 'components'
import { FormTitle } from './styles'
import ContasBancarias from './Components/ContasBancarias'
import { useToasts } from 'react-toast-notifications'
import { Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { useFormik } from 'formik'
import { TEmpresaForm, validationSchema, initialValues } from './validation'
import { Form, Col, Row } from 'react-bootstrap'
import useEmpresa, { TEmpresaParametros } from 'repositories/useEmpresa'

type TDadosEmpresa = {
  contas: TAccountInformation[]
  form: TEmpresaForm
}


const EditEmpresa = () => {
  const [contasBancarias, setContasBancarias] = useState<TAccountInformation[]>([])
  const [dados, setDados] = useState<TDadosEmpresa>()

  const { addToast } = useToasts()
  const { userData, userPermissions, hasFormPermission } = useAuth()

  const permissions = useMemo(() => userPermissions?.empresa, [userPermissions])
  const empresa = useEmpresa()

  const updateContasBancarias = (values: TAccountInformation[]) => {
    setContasBancarias(values)
  }

  const isVisualizando = () => {
    if(!permissions) return true
    if(!permissions.edit) return true
    return false
  }

  const handleSubmit = () => {
    if(!hasMainAccount(contasBancarias)) {
      addToast(
        'É necessário uma Conta Principal para alterar a Empresa.',
        { appearance: 'error', autoDismiss: true }
      )
      return
    }

    const putData = async (data:TEmpresaParametros) => {
      const response = await empresa.putEmpresa(data)
      if(!response) return
      addToast(
        'Empresa atualizada com sucesso',
        { appearance: 'success', autoDismiss: true }
      )
    }
    const dados: TEmpresaParametros = {
      corporateName: formik.values.razaoSocial,
      fantasyName: formik.values.nomeFantasia,
      cnpj: formik.values.cnpj,
      foundationYear: formik.values.anoFundacao,
      bankAccountInformations: contasBancarias
    }
    putData(dados)
  }

  useEffect(() => {
    const getDados = async() => {
      const response = await empresa.getEmpresa()
      if(!response) return
      const _dados = {
        contas: response.data.bankAccountInformations,
        form: {
          razaoSocial: response.data.corporateName,
          nomeFantasia: response.data.fantasyName,
          cnpj: response.data.cnpj,
          anoFundacao: response.data.foundationYear
        }
      }
      setDados(_dados)
    }
    getDados()
  }, [])

  useEffect(() => {
    if(!dados) return
    formik.setValues(dados.form)
    setContasBancarias(dados.contas)
  }, [dados])

  const hasMainAccount = (accounts: TAccountInformation[] ) => {
    if( accounts.filter( e => e.masterAccount === true).length > 0)
      return true
    return false
  }

  const formik = useFormik<TEmpresaForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: handleSubmit
  })

  const hasError = (name: keyof TEmpresaForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TEmpresaForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title={'Empresa'} />
        </Col>
      </Row>
      <Form onSubmit={formik.handleSubmit} id='formEmpresa'>
        <Form.Row>
          <Col md={8}>
            <TextField
              label='Razão Social'
              required
              disabled={isVisualizando()}
              {...getCommonFieldProps('razaoSocial')}
            />
          </Col>
          <Col md={4}>
            <MaskedTextField
              label='CNPJ'
              mask='99.999.999/9999-99'
              required
              disabled={isVisualizando()}
              {...getCommonFieldProps('cnpj')}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={8}>
            <TextField
              label='Nome Fantasia'
              disabled={isVisualizando()}
              {...getCommonFieldProps('nomeFantasia')}
            />
          </Col>
          <Col md={4}>
            <TextField
              required
              disabled={isVisualizando()}
              label='Ano de Fundação'
              maxLength={4}
              {...getCommonFieldProps('anoFundacao')}
            />
          </Col>

        </Form.Row>
      </Form>
      <Col>
        <FormTitle>Contas Bancárias</FormTitle>
      </Col>
      <ContasBancarias
        contasBancarias={contasBancarias}
        updateContasBancarias={updateContasBancarias}
        isVisualizando={isVisualizando()}
      />
      <ButtonsContainer compact>
        <PrimaryButton
          type='submit'
          isLoading={empresa.isLoading}
          form='formEmpresa'
          disabled={isVisualizando()}
        >
          <span>Salvar</span>
        </PrimaryButton>
      </ButtonsContainer>
    </>
  )
}

export default EditEmpresa
import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const PARAMETRIZATION_MUTATION = gql`
    mutation registerOrUpdateParametrization ($parametrizationInput: RegisterParametrizationCommand!) {
        registerOrUpdateParametrization (parametrizationInput: $parametrizationInput) {
            ${commonResponse}
        }
  }
`

export const PARAMETRIZATION_QUERY = gql`
    query findAllParametrization{
        findAllParametrization{
            ${commonResponse}
            parametrizationSuccess{
                id
                name
                valueChar
                valueNumeric
            }
        }
    }
`
import useErrorHandler from 'hooks/useErrorHandler'
import { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import { QUERY_CEMIG_ERRORS } from './errosCemigQpl'

type TFindEnergyBillQueueErrorsResponse = {
  findEnergyBillQueueError: TCommonResponse &
  TPaginationInfo & {
    energyBillErrorSuccess: TFindEnergyBillQueueErrorInfo[]
  }
}

export type TFindEnergyBillQueueErrorInfo = {
  id: number
  cpfOrCnpj: string
  unitConsumption: string
  error: string
  recordedIn: string
}

export type TFindEnergyBillQueueErrorParams = {
  findEnergyBillQueueErrorCommand: TFindEnergyBillQueueError
  paginate: TPaginationParams
}
export type TFindEnergyBillQueueError = {
  id?: number
  cpfOrCnpj?: string
  unitConsumption?: string
  error?: string
  recordedIn?: string
}

export const useGetCemigErrors = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestCemigErrors, { loading, data }] =
    useLazyQuery<TFindEnergyBillQueueErrorsResponse>(QUERY_CEMIG_ERRORS, {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error),
    })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } =
      data.findEnergyBillQueueError.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (
    params: TFindEnergyBillQueueErrorParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    requestCemigErrors({
      variables: params,
    })
  }

  return {
    isLoading: loading && isLoading,
    errors: data?.findEnergyBillQueueError.energyBillErrorSuccess,
    paginationInfo: data?.findEnergyBillQueueError.paginationInfo,
    get,
  }
}

import React, { useState, useMemo, useEffect } from 'react'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

import useProjetos from 'repositories/useProjetos'
import { Loader, InfoText, GoBackButton, PageHeader } from 'components'
import Documento from './Components/Documento'
import { ExclamationIcon, List, TextInfo } from './style'

const Documentos = () => {
  const [nomeProjeto, setNomeProjeto] = useState('')
  const [isCasado, setIsCasado] = useState(false)
  const [documentos, setDocumentos] = useState<TDocumentoRequest[]>([])
  const [detalhes, setDetalhes] = useState<TProjetosDetalhesResponse>()
  const [hasEmail, setHasEmail] = useState(false)

  const { id } = useParams<{ id: string }>()
  const location = useLocation<{ fromDetalhes: boolean }>()
  const repository = useProjetos()

  useEffect(() => {
    if (id) requestDocumentos()
    if (id) requestDetalhes()
  }, [id])

  useEffect(() => {
    if (detalhes) {
      if (detalhes.cliente.email === null) {
        setHasEmail(false)
      } else {
        setHasEmail(true)
      }
    }
  }, [detalhes])

  const requestDocumentos = async () => {
    const response = await repository.getDocumentos(id)
    if (!response) return

    setNomeProjeto(response.projectName)
    setIsCasado(response.isMarried)
    setDocumentos(response.documents)
  }
  
  const requestDetalhes = async () => {    
    const _detalhes = await repository.getDetalhes(id)
    if (!_detalhes) return

    setDetalhes(_detalhes)
  }

  const goBackRoute = `/projetos/detalhes/${id}`

  const sentDocumentos = useMemo(() => (
    documentos.reduce((acc, curr) => (
      curr.status > 1 ? acc + 1 : acc
    ), 0)
  ), [documentos])

  const completion = `${sentDocumentos}/${documentos.length}`

  if (!location.state) return <Redirect to={goBackRoute} />

  const TextDisplay = () => {
    if (isCasado) {
      if (hasEmail) {
        return (
          <Row>
              <Col>
                <TextInfo>
                  <ExclamationIcon size={24} />
                  <span>
                    Atenção! o CPF, RG do cliente e cônjuge somente será enviado ao
                    banco safra, após todos serem anexados.
                  </span>
                </TextInfo>
              </Col>
            </Row>
        )
      } else return ( <></>)
    } else return ( <></>)
  }

  return (
    <>
      <Row>
        <Col>
          <GoBackButton route={goBackRoute} />
        </Col>
      </Row>

      <Loader isLoading={repository.isLoading} />

      {Boolean(documentos.length) && !repository.isLoading && (
        <>
          <Row>
            <Col>
              <PageHeader title='Documentos' />
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                Precisamos que envie alguns documentos necessários para efetuarmos
                a análise de crédito.
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                <strong>Dica:</strong>

                <span>
                  Você pode enviar os documentos parcialmente. Não é necessário enviar
                  todos de uma vez só.
                </span>
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText isPartOfGroup>
                <strong>Projeto:</strong>
                <span>{nomeProjeto}</span>
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                <strong>Documentos entregues:</strong>
                <span>{completion}</span>
              </InfoText>
            </Col>
          </Row>

          <TextDisplay />

          <List>
            {documentos.map(documento => (
              <Documento
                arquivo={documento.arquivo}
                key={documento.id}
                projetoId={id}
                isCasado={isCasado}
                id={documento.id}
                requestDocumentos={requestDocumentos}
                tipoDescricao={documento.tipo_descricao}
                status={documento.status}
                dataHoraEnvio={documento.data_hora_envio}
                dataHoraParecer={documento.data_hora_parecer}
                comentarioParecer={documento.comentario_parecer}
              />
            ))}
          </List>
        </>
      )}
    </>
  )
}

export default Documentos
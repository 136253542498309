import Moment from 'moment'
import { ActivationStatus, PessoaTipo } from './constants'

export const formatDateTimeToView = (date: any) => (
  Moment(date).format('DD/MM/YYYY hh:mm a')
)

export const formatDateToServer = (date: string) => (
  Moment(date).format('YYYY-MM-DD')
)

export const removeTimeFromDateTime = (date: string) => (
  date.substring(0, 10)
)

export const formatDecimalNumber = (number: any) => {
  const formatter = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'decimal',
      maximumFractionDigits: 2
    }
  )

  return formatter.format(number)
}

export const formatContractStatus = (status: string) => {
  switch (status){
    case 'NEW': return 'NOVO'
    case 'WAITING_CONNECTION': return 'AGUARDANDO CONEXÃO'
    case 'CONNECTED': return 'CONECTADO'
    case 'CANCELED': return 'CANCELADO'
    default: return 'ERRO'
  }
}


export const formatContractType = (type: string) => {
  switch (type){
    case 'COMMERCIAL': return 'COMERCIAL'
    case 'RESIDENTIAL': return 'RESIDENCIAL'
    case 'INDUSTRIAL': return 'INDUSTRIAL'
    case 'RURAL': return 'RURAL'
    default: return 'ERRO'
  }
}


export const formatDecimalNumberToPercentage = (number: any) => (
  `${formatDecimalNumber(number)} %`
)

export const formatBooleanToSimNao = (bool: boolean) => {
  if(bool) return 'SIM'
  return 'NÃO'
}


export const formatCurrency = (number: any) => {
  const formatter = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL'
    }
  )

  return formatter.format(number)
}

export const formatDateToView = (date: any) => {
  if (!date) return ''
  return Moment(date).format('DD/MM/YYYY')
}

export const getCurrentDate = () => Moment().format('YYYY-MM-DD')

export const getDiffDaysBetweenDates = (start: any, end: any) => {
  const startDate = Moment(start, 'YYYY-MM-DD')
  const endDate = Moment(end, 'YYYY-MM-DD')
  return Moment.duration(endDate.diff(startDate)).asDays()
}

export const fileToBase64 = async (file: Blob) => {
  const base64 = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const [, data] = String(base64).split(',')

  return data
}

export const downloadBase64File = async (
  fileName: string,
  base64string: string,
  fileFormat = 'application/pdf'
) => {
  await new Promise<void>((resolve, reject) => {
    try {
      const linkSource = `data:${fileFormat};base64,${base64string}`

      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export const formatCurrencyToServer = (number: any) => {
  if (!number) return '0'

  if (number.indexOf(','))
    return number.replace(/\./g, '').replace(',', '.')

  return number
}

export const getApiErroMessage = (code: number) => {
  switch (code) {
    case -1: return 'Você não tem permissão para acessar essa tela/funcionalidade'
    case -1000: return 'Algo inesperado aconteceu'
    case -1001: return 'Você deve estar autenticado para realizar esta operação'
    case -1002: return 'Você não tem permissão para realizar esta operação'
    case -1003: return 'A validação dos dados da requisição falhou'
    case -1006: return 'Dados inconsistentes na API Safra'
    case -1007: return 'Não foi possível executar a operação na API Safra'
    case -1008: return 'Serviço na API Safra indisponível no momento / Implantação em andamento'
    case -1009: return 'Erro interno na API Safra financiamento'
    case -1010: return 'Erro ao realizar a comunicação com a API Safra'
    case -2000: return 'E-mail ou senha inválidos'
    case -2001: return 'Este usuário está inativo'
    case -2002: return 'Token inválido'
    case -2003: return 'Usuário não encontrado'
    case -18002: return 'Ocorreu um erro ao enviar o documento, por favor verifique o arquivo e tente novamente'
    case -19001: return 'O Arquivo solicitado não foi encontrado, documento depende do envio da(s) nota(s) fiscais'
    case -21002: return 'O Valor da(s) nota(s) fiscais não pode ser diferente do projeto'
    case -21007: return 'Houve um erro ao tentar reenviar a nota fiscal para api SAFRA, por favor tente novamente mais tarde'
    case -23001: return 'Já existe um CNPJ cadastrado para outro integrador'
    case -23002: return 'Não foi possível enviar o email, tente novamente mais tarde'
    case -23010: return 'E-mail já registrado para outro integrador'
    case -23011: return 'E-mail institucional já registrado para outro integrador'
    case -23012: return 'Não é possível avaliar um integrador com esta situação'
    case -23015: return 'Não é possível avaliar um integrador para esta situação'
    case -23016: return 'Não é possível desativar um integrador com projetos associados em andamento'
    case -23017: return 'Não é possível reprovar um integrador com projetos associados em andamento'
    case -23018: return 'Já existe um usuário cadastrado com esse e-mail'
    case -32009: return 'Não é possível enviar uma proposta com um integrador pendente de aprovação'
    case -2004: return 'Token expirado'
    case -2005: return 'Este CPF/CNPJ já está cadastrado'
    case -2006: return 'Este e-mail já está cadastrado'
    case -3001: return 'Não foi possível salvar o FAQ'
    case -3002: return 'FAQ não encontrado'
    case -3003: return 'FAQ não encontrado para ser atualizado.'
    case -3004: return 'FAQ não encontrado para ser excluído'
    case -3005: return 'Tamanho do campo excedido'
    case -4000: return 'Não foi possível salvar o usuário pois a permissão de provedor de serviços não existe no banco de dados.'
    case -13: return 'Este e-mail já está cadastrado.'
    case -22: return 'Este perfil já está cadastrado'
    case -24: return 'Perfil \'Administrador\' não pode ser alterado'
    case 400: return 'Condomínio já cadastrado com esse CNPJ'
    case -32011: return 'Apenas projetos recusados pelo Banco Safra podem ser replicados.'
    case -32004: return 'Esse projeto ainda não possui uma proposta'
    case -32005: return 'Projeto não pode ser cancelado no estado atual'
    case -32006: return 'Falha ao cancelar proposta com o banco safra'
    case -18003: return 'Proposta ainda não foi enviada ao banco safra'
    case -18006: return 'Proposta não pode ser cancelada no estado atual'
    default: return 'Algo de errado aconteceu. Por favor, tente novamente mais tarde!'
  }
}

export const formatDateEnUs = (date: Moment.MomentInput) => (
  Moment(date).format('YYYY-MM-DD hh:mm')
)

export const getTipoPessoaByFirstLetter = (firstLetter: PessoaTipo) => (
  firstLetter === 'F' ? 'Física' : 'Jurídica'
)

export const getYesNoFromBoolean = (value: boolean) => (
  value ? 'Sim' : 'Não'
)

export const removeMaskGuides = (value: string) => (
  value
    .replace(/_/g, '')
    .replace(/-/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/ /g, '')
    .replace(/[^\d]+/g, '')
)

export const removeSpecialCharacters = (value: any) => {
  return value.replace(/[^a-zA-Z0-9]/g, '')
}

export const formatCpfCnpj = (number?: string) => {
  if (!number) return ''

  const [regext, replacement] = number.length === 11
    ? [/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4']
    : [/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5']

  return number.replace(regext, replacement)
}

export const addDaysInDate = (days: string) => (
  Moment(new Date()).add(days, 'days').format('YYYY-MM-DD')
)

export const getStatusLabel = (value: string) => {
  switch(value){
    case ActivationStatus.ACTIVATED: return 'Ativo'
    case ActivationStatus.DEACTIVATED: return 'Inativo'
    case ActivationStatus.WAITING: return 'Aguardando Ativação'
  }
}

export const downloadUrl = (url: string, name: string): void => {
  const link = document.createElement('a')

  link.download = name
  link.href = url
  link.target = '_BLANK'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getStatusBancarioString = (status: boolean) => {
  if(status) return 'Bloqueado'
  return 'Desbloqueado'
}

export const getStatusBancarioBool = (status: string) => {
  if(status === 'true') return true
  return false
}

export const formatResponseToOptions = (items: TSelectResponse[]) => {
  return items.map(item => ({
    label: item.description!,
    value: String(item.id)!
  }))
}

export const sleep = (ms: number = 0) => new Promise( resolve => setTimeout(resolve, ms))
import React, { useState, useEffect, useMemo } from 'react'
import { Redirect, useParams, useLocation } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

import FormPF from './PF/index'
import FormPJ from './PJ/index'
import useProjetos from 'repositories/useProjetos'


type TValidatePersonType = {
  personType: 'PF' | 'PJ' | '',
  hasPassedThruUseEffect: boolean
}

const validadePersonTypeMock: TValidatePersonType = {
  personType: '',
  hasPassedThruUseEffect: false
}

const ViewProject = (props: any) => {
  const { userData, userPermissions, hasFormPermission } = useAuth()
  const { id } = useParams<{ id: string }>()
  const repositoryProjetos = useProjetos()
  const location = useLocation()

  const [projeto, setProjeto] = useState<any>({})

  const [tipoPessoa, setTipoPessoa] = useState('')
  const [validatePersonType, setValidatePersonType] = useState<TValidatePersonType>(validadePersonTypeMock)

  const permission = useMemo(() => {
    const permission = userPermissions?.projetos
    return hasFormPermission(permission!, location.pathname)
  }, [userPermissions])



  useEffect(() => {
    const requestProjeto = async () => {
      const triggerLoading = false
      const _projeto = await repositoryProjetos.getProjeto(id, triggerLoading)
      if (_projeto) {
        if (_projeto.client === null) {
          setTipoPessoa('PJ')
        } else if (_projeto.clientpj === null || _projeto.clientPJ === null) {
          setTipoPessoa('PF')
        }
        setProjeto(_projeto)
      }
    }

    if (id) requestProjeto()
  }, [id])

  useEffect(() => {
    if (projeto !== null && projeto !== undefined){
      if (projeto.client !== null) {
        setValidatePersonType({
          personType: 'PF',
          hasPassedThruUseEffect: true
        })
      } else {
        setValidatePersonType({
          personType: 'PJ',
          hasPassedThruUseEffect: true
        })
      }
    } else if (tipoPessoa !== null || tipoPessoa !== undefined) {
      if (tipoPessoa === 'PF'){
        setValidatePersonType({
          personType: 'PF',
          hasPassedThruUseEffect: true
        })
      } else {
        setValidatePersonType({
          personType: 'PJ',
          hasPassedThruUseEffect: true
        })
      }
    }
  }, [projeto, tipoPessoa])

  const renderForms = () => {
    if(validatePersonType.hasPassedThruUseEffect) {
      if (tipoPessoa === 'PJ') {
        return <FormPJ />

      } else if (tipoPessoa === 'PF') { {
        return <FormPF />
      }
      }
    }
  }

  return (
    <>
      {renderForms()}
    </>
  )
}

export default ViewProject

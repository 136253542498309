import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useAuth } from 'hooks/useAuth'
import {
  useGetContractById,
  useGetAttachmentLink,
  useSaveContract,
  TSaveContractParams,
  TUpdateContractParams,
  useUpdateContract
} from 'repositories/contratos'
import { useGetUsinas } from 'repositories/usinas'
import useInformacoes from 'repositories/useInformacoes'
import { useParams, Redirect, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { useFormik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import {
  GoBackButton,
  FormDivider,
  PageHeader,
  ActionButton,
  SearchField,
  PrimaryButton,
  SelectField,
  TextField,
  MaskedCurrencyField,
  MaskedTextField,
  NumberField,
  ConfirmButton,
  ButtonsContainer
} from 'components'
import { Row, Col, Form } from 'react-bootstrap'
import {
  Container,
  CustomCol,
  Label,
  SubTitle,
  CustomColButton
} from './styles'
import {
  formatContractStatus,
  formatDateToView,
  formatCpfCnpj,
  formatCurrencyToServer,
  removeMaskGuides
} from 'utils/helpers'
import {
  contractTypeOptions,
  contractSubclassOptions,
  confirmationMessage,
  Variant
} from 'utils/constants'
import { AiOutlineDownload } from 'react-icons/ai'
import { TypeaheadModel, AsyncTypeahead } from 'react-bootstrap-typeahead'

const paginateParams: TPaginationParams = {
  pageNumber: 0,
  numberOfRecordsByPage: 20
}

export type TContratoForm = {
  type: string
  subclass: string
  averageConsumption: string
  unitConsumption: string
  discount: string
  averageCost: string
  finalCost: string
  readingDate: string
  street: string
  number: string
  complement: string
  city: string
  state: string
  neighborhood: string
  zipCode: string
}

export type TLinkUsinaForm = {
  usina: string
  idUsina: number
}

const initialValues: TContratoForm = {
  type: '',
  subclass: '',
  averageConsumption: '',
  unitConsumption: '',
  discount: '',
  averageCost: '',
  finalCost: '',
  street: '',
  number: '',
  complement: '',
  city: '',
  state: '',
  neighborhood: '',
  zipCode: '',
  readingDate: '',
}

const initialValuesUsina: TLinkUsinaForm = {
  usina: '',
  idUsina: -1
}

const validationSchemaUsina = yup.object().shape({
  usina: yup.string().required('É necessário escolher uma Usina antes de fazer o vínculo'),
  idUsina: yup.number().required('É necessário escolher uma Usina antes de fazer o vínculo')
})

const validationSchema = yup.object().shape({
  unitConsumption: yup.number()
    .min(0, 'Insira um número maior ou igual a zero')
    .max(999999999999999999999999999999999999999999999999999999999999,
      'Insira um número com menos de 60 dígitos')
    .required('O campo "Unidade Consumidora" é obrigatório'),
  averageConsumption: yup.number()
    .min(0, 'Insira um número maior ou igual a zero')
    .max(999999999999999, 'Insira um número com menos de 15 dígitos')
    .required('O campo "Consumo Médio" é obrigatório'),
  averageCost: yup.string()
    .test(
      '',
      'Custo médio não pode ser menor que zero',
      value => {
        if(parseFloat(value!) >= 0) return true
        if(value === undefined) return true
        return false
      }
    ),
  finalCost: yup.string()
    .test(
      '',
      'Custo final não pode ser menor que zero',
      value => {
        if(parseFloat(value!) >= 0) return true
        if(value === undefined) return true
        return false
      }
    ),
  discount: yup.string()
    .test(
      'is-incorrect',
      'Desconto deve ser maior do que zero',
      value => (parseFloat(value!) > 0)
    )
    .test(
      'is-incorrect',
      'Insira um número menor ou igual a 100%',
      value => (parseFloat(value!) <= 100)
    )
    .required('O campo "Desconto" é obrigatório'),
  readingDate: yup.date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .max(new Date, 'Data posterior a atual não é permitido'),
  zipCode: yup
    .string()
    .required('CEP é obrigatório')
    .test(
      '',
      'CEP não é válido',
      value => {
        const zeroedCep = '00000-000'
        const isCepZeroed = value === zeroedCep

        const cleanCep = removeMaskGuides(String(value))
        const isCepIncomplete = cleanCep.length < 8

        return (!isCepIncomplete && !isCepZeroed)
      }
    ),
  street: yup.string().required('Logradouro é obrigatório')
    .max(100, 'Insira um logradouro com menos de 100 dígitos'),
  number: yup.string()
    .max(5, 'Não pode ser maior que 5 dígitos')
    .required('Número é obrigatório'),
  neighborhood: yup.string().max(100, 'Insira um bairro com menos de 100 dígitos'),
  city: yup.string().required('Cidade é obrigatório'),
  state: yup.string().required('Estado é obrigatório'),
  complement: yup.string().max(100, 'Insira um complemento com menos de 100 dígitos'),
})

const FormContrato = () => {
  const [contrato, setContrato] = useState<TClienteContrato | null>()
  const [estadoOptions, setEstadoOptions] = useState<TSelectOption[]>([])
  const [cidadeOptions, setCidadeOptions] = useState<TypeaheadModel[]>([])
  const [nomeRazaoSocial, setNomeRazaoSocial] = useState<string>('')
  const [formattedCpfCnpj, setFormattedCpfCnpj] = useState<string>('')
  const [usinaOptions, setUsinaOptions] = useState<TypeaheadModel[]>([])
  const cidadeRef = useRef<AsyncTypeahead<string>>(null)
  const usinaRef = useRef<AsyncTypeahead<string>>(null)

  const { userPermissions } = useAuth()
  const history = useHistory()
  const getContrato = useGetContractById()
  const getAttachmentLink = useGetAttachmentLink()
  const getUsinas = useGetUsinas()
  const { getEstados, getCidades } = useInformacoes()
  const saveContract = useSaveContract()
  const updateContract = useUpdateContract()
  const { addToast } = useToasts()

  const permissions = useMemo(() => userPermissions?.contrato, [userPermissions])
  const { id } = useParams<{ id: string}>()

  useEffect(() => {
    const requestEstados = async () => {
      const estados = await getEstados()
      if (!estados) return
      const _estadoOptions = estados.map(estado => ({
        label: estado.name,
        value: estado.name,
        id: estado.id
      }))
      setEstadoOptions(_estadoOptions)
    }
    requestEstados()
  }, [])

  useEffect(() => {
    if(!estadoOptions) return
    getContrato.get(Number(id))
  }, [estadoOptions])

  useEffect(() => {
    if(!contrato) return
    const _nomeRazaoSocial = contrato.client.fullName ?? contrato.client.companyName
    setNomeRazaoSocial(_nomeRazaoSocial)
    const _cpfCnpj = contrato.client.cpf ?? contrato.client.cnpj
    setFormattedCpfCnpj(formatCpfCnpj(_cpfCnpj))
  }, [contrato])

  useEffect(() => {
    if(!getContrato.contrato) return
    setContrato(getContrato.contrato)
    const formattedContract = {
      type: getContrato.contrato?.type,
      subclass: getContrato.contrato.subclass,
      unitConsumption: getContrato.contrato.unitConsumption,
      street: getContrato.contrato?.contractAddress?.street ?
        String(getContrato.contrato?.contractAddress?.street) : '',
      number: getContrato.contrato?.contractAddress?.number ?
        String(getContrato.contrato?.contractAddress?.number) : '',
      complement: getContrato.contrato?.contractAddress?.complement ?
        String(getContrato.contrato?.contractAddress?.complement) : '',
      city: getContrato.contrato?.contractAddress?.city ?
        String(getContrato.contrato?.contractAddress?.city) : '',
      state: getContrato.contrato?.contractAddress?.state ?
        String(getContrato.contrato?.contractAddress?.state) : '',
      neighborhood: getContrato.contrato?.contractAddress?.neighborhood ?
        String(getContrato.contrato?.contractAddress?.neighborhood) : '',
      zipCode: String(getContrato.contrato?.contractAddress?.zipCode),
      finalCost: String(getContrato.contrato?.finalCost),
      averageCost: String(getContrato.contrato?.averageCost),
      averageConsumption: String(getContrato.contrato?.averageConsumption),
      discount: String(getContrato.contrato?.discount),
      readingDate: getContrato.contrato?.readingDate ? getContrato.contrato?.readingDate : ''
    }
    const usinaValues = {
      usina: getContrato.contrato.powerPlant?.name ?
        String(getContrato.contrato.powerPlant?.name) : '',
      idUsina: getContrato.contrato.powerPlant?.id ?
        Number(getContrato.contrato.powerPlant?.id) : -1
    }
    formikUsina.setValues(usinaValues)
    formik.setValues(formattedContract)
  }, [getContrato.contrato])

  const onClickGetLink = (id: number) => {
    getAttachmentLink.get(id)
  }

  useEffect(() => {
    if(!getAttachmentLink.link) return
    window.open(getAttachmentLink.link)
  }, [getAttachmentLink.link])

  useEffect(() => {
    if(!getUsinas.usinas)return
    const mappedUsinasOptions = getUsinas.usinas.map(usina => {
      return {
        label: usina.name,
        value: usina.id
      }
    })

    setUsinaOptions(mappedUsinasOptions)
  }, [getUsinas.usinas])


  useEffect(() => {
    if(saveContract.response?.isError || formikUsina.values.usina === '') return
    addToast(
      'Usina vinculada com sucesso!',
      { appearance: 'success', autoDismiss: true }
    )
    getContrato.get(Number(id))
  }, [saveContract.response])

  const isVisualizando = useMemo(() => {
    const urlAction = location.pathname.split('/')[2]
    return urlAction === 'visualizar'
  }, [location.pathname])

  const handleSubmitUsina = () => {
    const params: TSaveContractParams = {
      contractId: Number(id),
      powerPlantId: Number(formikUsina.values.idUsina)
    }

    saveContract.send(params)
  }

  const handleSubmit = () => {
    const params: TUpdateContractParams = {
      updateContractCommand: {
        ...formik.values,
        averageCost: formatCurrencyToServer(formik.values.averageCost),
        finalCost: formatCurrencyToServer(formik.values.finalCost),
        discount: Number(formik.values.discount),
        id: parseFloat(id),
        contractHash: String(contrato?.contractHash),
        sellerName: String(contrato?.sellerName)
      }
    }
    updateContract.send(params)
  }

  const formik = useFormik<TContratoForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    if(!updateContract.response) return
    if(updateContract.response?.isError) return
    addToast(
      'Contrato salvo com sucesso!',
      { appearance: 'success', autoDismiss: true }
    )
    getContrato.get(Number(id))
  }, [updateContract.response])

  const formikUsina = useFormik<TLinkUsinaForm>({
    initialValues: initialValuesUsina,
    validationSchema: validationSchemaUsina,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: handleSubmitUsina
  })

  const hasError = (name: keyof TContratoForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const hasErrorUsina = (name: keyof TLinkUsinaForm) => {
    return formikUsina.touched[name] && formikUsina.errors[name]
  }

  const getCommonFieldProps = (name: keyof TContratoForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const getCommonFieldPropsUsina = (name: keyof TLinkUsinaForm) => ({
    isInvalid: Boolean(hasErrorUsina(name)),
    errorMessage: formikUsina.errors[name],
    name,
    value: formikUsina.values[name],
    onBlur: formikUsina.handleBlur,
    onChange: formikUsina.handleChange
  })

  const onSearchUsina = (term: string) => {
    if (term.length < 3 || getUsinas.isLoading) return

    const params: TListaUsinasParams = {
      findPowerPlantByFilterCommand: {
        name: term,
        isActive: true
      },
      paginate: {
        ...paginateParams
      }
    }
    getUsinas.get(params)
  }

  const onChangeUsina = ([usina]: TSelectOption[]) => {
    const idValue = usina?.value ?? -1
    const usinaLabel = usina?.label ?? ''
    formikUsina.setFieldValue('idUsina', idValue, true)
    formikUsina.setFieldValue('usina', usinaLabel, true)
  }

  const onBlurUsina = () => {
    formik.setFieldTouched('usina', true)
    if (!formikUsina.values.usina) usinaRef.current?.clear()
  }

  const onChangeEstado = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('state', value, true)
    formik.setFieldValue('city', '')
    if (cidadeRef.current) cidadeRef.current.clear()
  }

  const onChangeCidade = ([cidade]: TSelectOption[]) => {
    const value = cidade?.value ?? ''
    formik.setFieldValue('city', value, true)
  }

  const onSearchCidade = (term: string) => {
    if (!formik.values.state || term.length < 3) return

    const requestCidades = async () => {
      const _estado = estadoOptions.find(estado => (
        String(estado.value) === formik.values.state
      ))
      const params = {
        search: term,
        stateId: String(_estado!.id)
      }
      const cidades = await getCidades(params)
      if (!cidades) return

      const _cidadeOptions = cidades.map(cidade => ({
        label: cidade.description,
        value: cidade.description
      }))

      setCidadeOptions(_cidadeOptions)
    }

    requestCidades()
  }

  const onBlurCidade = () => {
    formik.setFieldTouched('city', true)
    if (!formik.values.city) cidadeRef.current?.clear()
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  const onClickGoToLista = () => history.push('/contratos')

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            route='/contratos'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PageHeader title={`Contrato ${contrato?.id}`} />
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            <SubTitle>Dados Gerais</SubTitle>
          </Col>
        </Row>
        <Row>
          <CustomCol md={6}>
            <Label>Número do Contrato:</Label> {contrato?.contractHash}
          </CustomCol>
          <CustomCol md={6}>
            <Label>Status:</Label> {formatContractStatus(String(contrato?.status))}
          </CustomCol>
        </Row>
        <Row>
          <CustomCol md={6}>
            <Label>Cliente:</Label> {nomeRazaoSocial}
          </CustomCol>
          <CustomCol md={6}>
            <Label>CPF/CNPJ:</Label> {formattedCpfCnpj}
          </CustomCol>
        </Row>
        <Form onSubmit={formikUsina.handleSubmit}>
          <Row>
            <CustomCol md={6}>
              <SearchField
                required
                disabled={contrato?.status !== 'NEW' || !permissions?.bond || isVisualizando}
                label='Usina'
                options={usinaOptions}
                elementRef={usinaRef}
                {...getCommonFieldPropsUsina('usina')}
                onSearch={onSearchUsina}
                onBlur={onBlurUsina}
                onChange={onChangeUsina}
                value={String(formikUsina.values.usina)}
              />
            </CustomCol>
            <CustomColButton md={6}>
              <PrimaryButton
                type='submit'
                isLoading={saveContract.isLoading}
                disabled={contrato?.status !== 'NEW' || !permissions?.bond || isVisualizando}
              >
                <span>Vincular Usina</span>
              </PrimaryButton>
            </CustomColButton>
          </Row>
        </Form>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <CustomCol md={6}>
              <SelectField
                label = 'Tipo do Contrato'
                options={contractTypeOptions}
                disabled={isVisualizando}
                required
                {...getCommonFieldProps('type')}
              />
            </CustomCol>
            <CustomCol md={6}>
              <SelectField
                label = 'Subclasse'
                disabled={isVisualizando}
                options={contractSubclassOptions}
                required
                {...getCommonFieldProps('subclass')}
              />
            </CustomCol>
          </Row>
          <Row>
            <CustomCol md={6}>
              <NumberField
                label='Média de Consumo (kWh)'
                min={0}
                decimalScale={2}
                disabled={isVisualizando}
                {...getCommonFieldProps('averageConsumption')}
              />
            </CustomCol>
            <CustomCol md={6}>
              <NumberField
                label='Unidade Consumidora'
                disabled={isVisualizando}
                required
                {...getCommonFieldProps('unitConsumption')}
              />
            </CustomCol>
          </Row>
          <Row>
            <CustomCol md={6}>
              <MaskedCurrencyField
                label='Custo Médio'
                disabled={isVisualizando}
                {...getCommonFieldProps('averageCost')}
              />
            </CustomCol>
            <CustomCol md={6}>
              <MaskedCurrencyField
                label='Custo Final'
                disabled={isVisualizando}
                {...getCommonFieldProps('finalCost')}
              />
            </CustomCol>
          </Row>
          <Row>
            <CustomCol md={6}>
              <NumberField
                label= 'Desconto (%)'
                disabled={isVisualizando}
                required
                {...getCommonFieldProps('discount')}
              />
            </CustomCol>
            <CustomCol md={6}>
              <TextField
                label='Data de leitura'
                disabled={isVisualizando}
                type='date'
                {...getCommonFieldProps('readingDate')}
              />
            </CustomCol>
          </Row>
          <FormDivider />

          <Row>
            <Col>
              <SubTitle>Endereço</SubTitle>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <MaskedTextField
                label='CEP'
                disabled={isVisualizando}
                mask='99999-999'
                required
                {...getCommonFieldProps('zipCode')}
                value={formik.values.zipCode}
              />
            </Col>

            <Col md={5}>
              <TextField
                label='Logradouro'
                disabled={isVisualizando}
                required
                {...getCommonFieldProps('street')}
              />
            </Col>

            <Col md={4}>
              <TextField
                label='Número'
                disabled={isVisualizando}
                required
                type='string'
                {...getCommonFieldProps('number')}
              />
            </Col>
          </Row>

          <Row>
            <Col md={5}>
              <TextField
                label='Bairro'
                disabled={isVisualizando}
                {...getCommonFieldProps('neighborhood')}
              />
            </Col>

            <Col md={3}>
              <SelectField
                required
                label='Estado'
                disabled={isVisualizando}
                options={estadoOptions}
                {...getCommonFieldProps('state')}
                onChange={onChangeEstado}
              />
            </Col>

            <Col md={4}>
              <SearchField
                required
                label='Cidade'
                disabled={isVisualizando}
                elementRef={cidadeRef}
                options={cidadeOptions}
                {...getCommonFieldProps('city')}
                onSearch={onSearchCidade}
                onChange={onChangeCidade}
                onBlur={onBlurCidade}
                value={formik.values.city}
              />
            </Col>
          </Row>

          <Row>
            <Col md={5}>
              <TextField
                label='Complemento'
                disabled={isVisualizando}
                {...getCommonFieldProps('complement')}
              />
            </Col>
          </Row>
          <FormDivider />

          <Row>
            <Col>
              <SubTitle>Documentos</SubTitle>
            </Col>
          </Row>
          {contrato?.attachments.map(documento => {
            return(
              <Row key={documento.id}>
                <CustomCol md={6}>
                  <Label>Nome:</Label> {documento.fileName}
                </CustomCol>
                <CustomCol md={4}>
                  <Label>Adicionado em:</Label> {formatDateToView(documento.createdDate)}
                </CustomCol>
                <CustomCol md={2}>
                  <ActionButton
                    title='Visualizar Documento'
                    onClick={() => onClickGetLink(documento.id)}
                  >
                    <AiOutlineDownload />
                  </ActionButton>
                </CustomCol>
              </Row>
            )
          })}
          {!contrato?.attachments.length && (
            <Row>
              <CustomCol> Não foram encontrados documentos anexados ao contrato. </CustomCol>
            </Row>
          )}

          <FormDivider />
          <ButtonsContainer>
            <ConfirmButton
              active={!isVisualizando}
              actionFn={onClickGoToLista}
              message={confirmationMessage}
              variant={Variant.SECONDARY}
            >
              <span>{isVisualizando ? 'Voltar' : 'Cancelar' }</span>
            </ConfirmButton>
            {!isVisualizando && (
              <PrimaryButton type='submit' isLoading={updateContract.isLoading}>
                <span>Salvar</span>
              </PrimaryButton>
            )}
          </ButtonsContainer>
        </Form>
      </Container>
    </>
  )
}

export default FormContrato
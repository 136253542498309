import React from 'react'
import { Form } from 'react-bootstrap'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { FormLabel } from 'components'

type TProps = {
  required?: boolean
  name: string
  label?: string
  placeholder?: string
  emptyLabel?: string
  description?: string
  isInvalid?: boolean
  errorMessage?: string
  value?: string | number
  onChange(e: TInputEvent): void,
  disabled?: boolean,
  readOnly?: boolean,
  allowNegative?: boolean,
  prefix?: string,
  suffix?: string,
  max?: number,
  min?: number,
  decimalScale?: number
}

const NumberField = (props: TProps) => {
  const {
    required,
    label,
    name,
    isInvalid,
    errorMessage,
    description,
    disabled = false,
    allowNegative	= false,
    prefix = '',
    max,
    min,
    suffix = '',
    decimalScale = 0,
    ...rest
  } = props

  const validLimitValue = ({ value }: NumberFormatProps) => {
    const number = Number(value)

    if(min && max){
      return number >= min && number <= max
    }
    if(min) return number <= min
    if(max) return number <= max

    return true
  }

  return (
    <Form.Group>
      <FormLabel required={required}>
        {label}
      </FormLabel>

      <NumberFormat
        id={name}
        className={`form-control ${isInvalid && 'is-invalid'}`}
        autoComplete='off'
        displayType='input'
        type='text'
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        thousandSeparator={false}
        allowNegative={allowNegative}
        isAllowed={validLimitValue}
        decimalScale={decimalScale}
        {...rest}
      />

      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>

      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default NumberField

import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'

import useInformacoes from 'repositories/useInformacoes'
import { FormLabel } from 'components'
import { debounce } from 'debounce'

const DropdownSelectBank = props => {
  const {
    field,
    label,
    value,
    placeholder = '',
    maxLength = 1,
    error = {},
    description = '',
    required = false,
    elementRef,
    ...rest
  } = props
  const [options, setOptions] = useState([])
  const respository = useInformacoes()

  const handlesSearch = async search => {
    const bancos = await respository.getBancos(search)
    if (bancos) setOptions(bancos)
  }

  return (
    <Form.Group controlId={field}>
      <FormLabel required={required}>{label}</FormLabel>
      <Typeahead
        name={field}
        placeholder={placeholder}
        minLength={maxLength}
        isInvalid={Boolean(error.isError)}
        className={error.isError ? 'is-invalid' : ''}
        values={value}
        isLoading={respository.isLoading}
        emptyLabel='Nenhum banco encontrado'
        labelKey='name'
        onInputChange={debounce(handlesSearch, 500)}
        options={options}
        useCache={false}
        ref={elementRef}
        inputProps={{
          autoComplete: 'disabled'
        }}
        {...rest}
      />
      <Form.Control.Feedback type='invalid'>
        {error.message}
      </Form.Control.Feedback>
      {description && (
        <Form.Text className='text-muted'>{description}</Form.Text>
      )}
    </Form.Group>
  )
}

export default DropdownSelectBank

import axios from 'services/axios'
import { AxiosError } from 'axios'

import useErrorHandler, { ApiErrorMessages } from 'hooks/useErrorHandler'

const useRest = () => {
  const {
    handleApiErrorDesambiguation,
    handleApiErrorUnavailable
  } = useErrorHandler()

  const get = async (
    url: string,
    params: Object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const _params = {
        params
      }
      const response = await axios.get(url, _params)
      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      const { status, code } = _error.response?.data
      if(_error.response?.data.message === ApiErrorMessages.SERVICE_UNAVAILABLE) {
        handleApiErrorUnavailable(_error.response?.data.message)
        return
      }

      if (status) {
        handleApiErrorDesambiguation(status)
        return
      }

      if (code) handleApiErrorDesambiguation(code)

    }
  }

  const post = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const response = await axios.post(url, body)
      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      const errorCode = Number(_error.response?.data.code)
      handleApiErrorDesambiguation(errorCode)
    }
  }

  const put = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const response = await axios.put(url, body)
      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      const errorCode = Number(_error.response?.data.code)
      handleApiErrorDesambiguation(errorCode)
    }
  }

  return { get, post, put }
}

export default useRest
import React from 'react'
import { Container, Logo, Title } from './styles'

const Home = () => {
  return (
    <Container>
      <Logo>Copérnico Energia Renovável</Logo>
      <Title>Seja bem-vindo a Copérnico</Title>
    </Container>
  )
}

export default Home
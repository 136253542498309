import React from 'react'
import { GrDocumentText } from 'react-icons/gr'

import useProjetos from 'repositories/useProjetos'
import {
  ListItem,
  CheckIcon,
  ExclamationIcon,
  SendDocumento,
  InvisibleFileInput,
  Loader,
  ViewDocumento
} from '../style'
import { StatusDocumento } from 'utils/constants'
import { fileToBase64, formatDateTimeToView } from 'utils/helpers'
import { Status } from 'pages/MinhasFaturas/styles'
import { title } from 'process'

const idsDocumentosConjugeAndProponente = [1, 10, 11]

const Documento = (props: TDocumento) => {
  const {
    arquivo,
    projetoId,
    isCasado,
    id,
    requestDocumentos,
    tipoDescricao: descricao,
    status,
    dataHoraEnvio,
    dataHoraParecer,
    comentarioParecer
  } = props

  const repository = useProjetos()

  const isDocumentoSent = (
    status > StatusDocumento.NAO_ENVIADO && status !== StatusDocumento.NEGADO_PELO_BANCO
  )

  const showExclamationIcon = isCasado && idsDocumentosConjugeAndProponente.includes(id)

  const getDocument = async () => {
    const response = await repository.getDocumentoById(projetoId, id.toString())
    if (!response) return
    return response
  }

  const handleViewClick = async () => {
    const document = await getDocument()
    openFile(document)
  }

  const openFile = async (_document: any) => {
    const base64 = _document.documents.arquivo
    const blob = base64ToBlob(base64, 'application/pdf')
    const url = URL.createObjectURL(blob)
    const pdfWindow = window.open('')
    pdfWindow?.document.write(`<title>${props?.tipoDescricao}</title><iframe src="${url}" width="100%" height="100%" style="border: none;"></iframe>`)

    function base64ToBlob(base64: any, type = 'application/octet-stream') {
      const binStr = atob(base64)
      const len = binStr.length
      const arr = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      return new Blob([arr], { type })
    }
  }

  const viewDocument = () => {
    if (status > StatusDocumento.NAO_ENVIADO) {
      return (
        <>
          <ViewDocumento onClick={handleViewClick}>
            <span>Visualizar Documento</span>
          </ViewDocumento>
        </>
      )
    }
  }

  return (
    <ListItem>
      <div>
        <GrDocumentText />
        {showExclamationIcon && (
          <ExclamationIcon />
        )}
      </div>
      <h4>{descricao}</h4>
      <hr />
      {isDocumentoSent ? (
        <SentDocumento
          status={status}
          dataHoraEnvio={dataHoraEnvio}
          projetoId={projetoId}
          id={id}
          statusSafra={status}
          requestDocumentos={requestDocumentos}
        />
      ) : (
        <>
          {status === StatusDocumento.NEGADO_PELO_BANCO && (
            <DeniedDocumento
              dataHoraParecer={dataHoraParecer}
              comentarioParecer={comentarioParecer}
              projetoId={projetoId}
              id={id}
              statusSafra={status}
              requestDocumentos={requestDocumentos}
            />
          )}
          <ToBeSentDocumento
            projetoId={projetoId}
            id={id}
            statusSafra={status}
            requestDocumentos={requestDocumentos}
          />
        </>
      )}

      {viewDocument()}

    </ListItem>
  )
}

const DeniedDocumento = (props: TDeniedDocumento) => {
  const { dataHoraParecer, comentarioParecer } = props

  const formattedDateTime = formatDateTimeToView(dataHoraParecer)


  return (
    <>
      <strong>Recusado em</strong>
      <span>{formattedDateTime}</span>
      {comentarioParecer && (
        <>
          <strong>Parecer</strong>
          <span>{comentarioParecer}</span>
        </>
      )}
      <ExclamationIcon />
    </>
  )
}

const SentDocumento = (props: TSentDocumento) => {
  const { status, dataHoraEnvio, projetoId, id, statusSafra, requestDocumentos } = props

  const formattedDateTime = formatDateTimeToView(dataHoraEnvio)
  const isAprovado = status === StatusDocumento.APROVADO_PELO_BANCO

  const repository = useProjetos()

  const onChangeUploadFile = async (event: any) => {
    if (!event) return

    const rawFile = event.target.files[0]

    const fileContent = await fileToBase64(rawFile)
    const file = rawFile.name

    const data = {
      documentList: { id },
      file,
      fileContent
    }

    await repository.postDocumento(projetoId, data)
    requestDocumentos()
  }

  const aceptedFileTypes = 'pdf, jpg, jpeg, png'
  const fileInputId = `fileInput${id}`
  let sendButton = <></>

  let buttonText = ''

  if (statusSafra === StatusDocumento.AGUARDANDO_APROVACAO_BANCO) {
    buttonText = 'Reenviar Documento'
  } else if (statusSafra === StatusDocumento.SALVO_MAS_NAO_ENVIADO) {
    buttonText = 'Enviar Próximo Documento'
  } else {
    buttonText = 'Enviar Documento'
  }

  if (statusSafra !== StatusDocumento.APROVADO_PELO_BANCO) {
    sendButton = <><SendDocumento htmlFor={fileInputId}>{repository.isLoading ? (<Loader animation='border' variant='light' />) : (<span>{buttonText}</span>)}</SendDocumento><InvisibleFileInput type='file' id={fileInputId} onChange={onChangeUploadFile} accept={aceptedFileTypes} disabled={repository.isLoading} /></>
  }

  return (
    <>
      <strong>
        {status === StatusDocumento.SALVO_MAS_NAO_ENVIADO
          ? 'Anexado em'
          : 'Enviado em'
        }
      </strong>
      <span>{formattedDateTime}</span>
      <CheckIcon isAprovado={isAprovado} />
      {sendButton}
    </>
  )
}

const ToBeSentDocumento = (props: TToBeSentDocumento) => {
  const { projetoId, id, statusSafra, requestDocumentos } = props

  const repository = useProjetos()

  const onChangeUploadFile = async (event: any) => {
    if (!event) return

    const rawFile = event.target.files[0]

    const fileContent = await fileToBase64(rawFile)
    const file = rawFile.name

    const data = {
      documentList: { id },
      file,
      fileContent
    }

    await repository.postDocumento(projetoId, data)
    requestDocumentos()
  }

  let buttonText = ''

  if (statusSafra === StatusDocumento.AGUARDANDO_APROVACAO_BANCO) {
    buttonText = 'Enviar Documento'
  } else if (statusSafra === StatusDocumento.SALVO_MAS_NAO_ENVIADO) {
    buttonText = 'Enviar Próximo Documento'
  } else {
    buttonText = 'Enviar Documento'
  }

  const aceptedFileTypes = 'pdf, jpg, jpeg, png'
  const fileInputId = `fileInput${id}`



  return (
    <>
      <SendDocumento htmlFor={fileInputId}>
        {repository.isLoading ? (
          <Loader animation='border' variant='light' />
        ) : (
          <span>
            {buttonText}
          </span>
        )}
      </SendDocumento>

      <InvisibleFileInput
        type='file'
        id={fileInputId}
        onChange={onChangeUploadFile}
        accept={aceptedFileTypes}
        disabled={repository.isLoading}
      />
    </>
  )
}

export default Documento
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { BiX } from 'react-icons/bi'

import { InfoText, PageHeader, PrimaryButton, SecondaryButton } from 'components'
import { CustomModalBody, CloseButton } from './styles'


type TProps = {
  isOpen: boolean,
  closeModal(): void
}

const ModalPurchaseProfile = (props: TProps) => {
  const { isOpen, closeModal } = props

  const history = useHistory()

  const handleCloseModal = () => closeModal()

  const onClickGoFormPF = () => history.push({
    pathname: '/projetos/novo/pf',
    state: { personType: 'PF' }
  })
  const onClickGoFormPJ = () => history.push({
    pathname: '/projetos/novo/pj',
    state: { personType: 'PJ' }
  })

  return (
    <Modal
      show={isOpen}
      centered
      onHide={handleCloseModal}
    >
      <CustomModalBody>
        <CloseButton onClick={handleCloseModal}>
          <BiX />
        </CloseButton>
        <PageHeader title='Criar Projeto' isCentered />
        <InfoText isCentered>
          <strong>Qual o perfil de compra?</strong>
        </InfoText>
        <InfoText isCentered>
            Selecione um perfil para prosseguir com o cadastro do projeto
        </InfoText>
        <PrimaryButton
          onClick={onClickGoFormPF}
          block
        >
            Pessoa Física
        </PrimaryButton>
        <InfoText isCentered isPartOfGroup>ou</InfoText>
        <SecondaryButton
          onClick={onClickGoFormPJ}
          block
        >
            Pessoa Jurídica
        </SecondaryButton>
      </CustomModalBody>
    </Modal>
  )

}

export default ModalPurchaseProfile
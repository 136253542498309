import * as Yup from 'yup'
import { regexCelular, senhaRegex } from 'utils/constants'


export const initialValues: TUsuarioRequest = {
  name: '',
  email: '',
  passwordHash: '',
  role: '',
  telephone: '',
  status: 'ACTIVATED'
}

export const schema = Yup.object().shape({
  id: Yup.number().nullable(),
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup
    .string()
    .email('Email não está valido')
    .required('Email é obrigatório'),
  passwordHash: Yup
    .string()
    .when('id', {
      is: ((value: number) => !value),
      then: Yup.string().required('Senha é obrigatório')
    }).matches(
      senhaRegex, { excludeEmptyString: true
        , message: 'A senha precisa ter pelo menos 8 caracteres, sendo um caractere especial, letras e números.' },
    ),
  passwordHashConfirmation: Yup.string()
    .when('id', {
      is: ((value: number) => !value),
      then: Yup.string().required('Confirmação da senha é obrigatória')
    })
    .oneOf(
      [Yup.ref('passwordHash')],
      'As senhas estão diferentes'
    ),
  role: Yup.string().required('Perfil é obrigatório'),
  telephone: Yup
    .string()
    .matches(regexCelular, 'Celular não é valido')
    .required('Celular é obrigatório'),
  status: Yup.string().required('Status é obrigatório')
})

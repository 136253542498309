import React from 'react'
import { Container, Title } from './styles'

type TProps = {
  title?: string
  isCentered?: boolean
  children?: React.ReactNode
}

const PageHeader = (props: TProps) => {
  const { title = '', isCentered = false, children } = props
  return (
    <Container isCentered={isCentered}>
      <Title>{title}</Title>
      <div>{children}</div>
    </Container>
  )
}

export default PageHeader

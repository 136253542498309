import * as yup from 'yup'
import {
  cnpj as cnpjValidation
} from 'cpf-cnpj-validator'
import { removeMaskGuides } from 'utils/helpers'

export type TEmpresaForm = {
  razaoSocial: string
  nomeFantasia: string
  cnpj: string
  anoFundacao: string
}

export const initialValues: TEmpresaForm = {
  razaoSocial: '',
  nomeFantasia: '',
  cnpj: '',
  anoFundacao: ''
}

const anoAtual = new Date().getFullYear()

export const validationSchema = yup.object().shape({
  razaoSocial: yup.string()
    .required('Razão Social é um campo obrigatório.')
    .max(200, 'Insira uma Razão Social com menos de 200 dígitos'),
  nomeFantasia: yup.string()
    .max(200, 'Insira um nome com menos de 200 dígitos'),
  cnpj: yup.string()
    .required('O campo "CNPJ" é obrigatório')
    .test(
      '',
      'CNPJ não está válido',
      value => {
        const cleanCnpj = removeMaskGuides(String(value))
        const cnpjInputIsEmpty = cleanCnpj.length === 0
        return cnpjInputIsEmpty || cnpjValidation.isValid(cleanCnpj!)
      }
    ),
  anoFundacao: yup.number()
    .max(anoAtual, 'Ano de Fundação não pode ser posterior ao atual')
    .required('Ano é obrigatório'),
})

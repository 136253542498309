import { ModalBody } from 'react-bootstrap'
import styled from 'styled-components'

export const CustomModalBody = styled(ModalBody)`
  padding: 30px;
`

export const CloseButton = styled.button`
  position: fixed;
  top: 35px;
  right: 35px;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.WHITE};

  &:hover {
    color: ${({ theme }) => theme.colors.WHITE};
  }

  svg {
    width: 32px;
    height: 32px;
  }
`
import styled from 'styled-components'

export const ListContainer = styled.ul`
  display: flex;
  list-style: none;
`

type TItemProps = {
  isActive: boolean
}

export const Item = styled.li<TItemProps>`
  padding: 0 20px;
  border-right: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY};

  &:last-child {
    border-right: none;
    padding-right: 0;
  }

  a {
    color: ${p => p.isActive ? p.theme.colors.DARK_YELLOW : p.theme.colors.DARK_GRAY};
    text-decoration: ${p => p.isActive ? 'underline' : 'unset'};
    font-weight: 700;
  }
`
import React, { useState, useEffect } from 'react'
import { GrDocumentText } from 'react-icons/gr'

import {
  ListItem,
  CheckIcon,
  ExclamationIcon,
  SendDocumento,
  InvisibleFileInput,
  Loader,
  ViewDocumento
} from '../style'
import {
  fileToBase64,
  downloadBase64File,
  formatDateTimeToView
} from 'utils/helpers'
import { StatusDocumento } from 'utils/constants'
import useProjetos from 'repositories/useProjetos'
import { PrimaryButton } from 'components'

const Documento = (props: TDocumento) => {
  const {
    arquivo,
    projetoId,
    id,
    tipoId,
    requestDocumentos,
    tipoDescricao: descricao,
    status,
    dataHoraEnvio,
    dataHoraParecer,
    comentarioParecer
  } = props


  const repository = useProjetos()

  const isDocumentoSent = (
    status > StatusDocumento.NAO_ENVIADO && status !== StatusDocumento.NEGADO_PELO_BANCO
  )

  const getDocument = async () => {
    const response = await repository.getDocumentoById(projetoId, id.toString())
    if (!response) return
    return response
  }

  const handleViewClick = async () => {
    const document = await getDocument()
    openFile(document)
  }

  const openFile = async (_document: any) => {
    const base64 = _document.documents.arquivo
    const blob = base64ToBlob(base64, 'application/pdf')
    const url = URL.createObjectURL(blob)
    const pdfWindow = window.open('')
    pdfWindow?.document.write(`<title>${props?.tipoDescricao}</title><iframe src="${url}" width="100%" height="100%" style="border: none;"></iframe>`)

    function base64ToBlob(base64: any, type = 'application/octet-stream') {
      const binStr = atob(base64)
      const len = binStr.length
      const arr = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      return new Blob([arr], { type })
    }
  }

  const viewDocument = () => {
    if (status > StatusDocumento.NAO_ENVIADO) {
      return (
        <>
          <ViewDocumento onClick={handleViewClick}>
            <span>Visualizar Documento</span>
          </ViewDocumento>
        </>
      )
    }
  }

  return (
    <ListItem>
      <GrDocumentText />
      <h4>{descricao}</h4>
      <hr />
      {isDocumentoSent ? (
        <SentDocumento
          status={status}
          dataHoraEnvio={dataHoraEnvio}
          projetoId={projetoId}
          tipoId={tipoId}
          id={id}
          requestDocumentos={requestDocumentos}
        />
      ) : (
        <>
          {status === StatusDocumento.NEGADO_PELO_BANCO && (
            <DeniedDocumento
              dataHoraParecer={dataHoraParecer}
              comentarioParecer={comentarioParecer}
              projetoId={projetoId}
              id={id}
              tipoId={tipoId}
              statusSafra={status}
              requestDocumentos={requestDocumentos}
            />
          )}
          <ToBeSentDocumento
            projetoId={projetoId}
            id={id}
            tipoId={tipoId}
            statusSafra={status}
            requestDocumentos={requestDocumentos}
          />
        </>
      )}
      {viewDocument()}

    </ListItem>
  )
}

const DeniedDocumento = (props: TDeniedDocumento) => {
  const { dataHoraParecer, comentarioParecer } = props

  const formattedDateTime = formatDateTimeToView(dataHoraParecer)

  return (
    <>
      <strong>Recusado em</strong>
      <span>{formattedDateTime}</span>
      {comentarioParecer && (
        <>
          <strong>Parecer</strong>
          <span>{comentarioParecer}</span>
        </>
      )}
      <ExclamationIcon />
    </>
  )
}

const SentDocumento = (props: TSentDocumento) => {
  const { status, dataHoraEnvio, projetoId, id, tipoId, requestDocumentos } = props

  useEffect(() => {
    console.log('DOCUMENTO SENT', props)
  }, [])


  const formattedDateTime = formatDateTimeToView(dataHoraEnvio)
  const isAprovado = status === StatusDocumento.APROVADO_PELO_BANCO

  const [isUploading, setIsUploading] = useState(false)

  const repository = useProjetos()

  const onChangeUploadFile = async (event: any) => {
    if (!event) return

    setIsUploading(true)
    const rawFile = event.target.files[0]

    const fileContent = await fileToBase64(rawFile)
    const file = rawFile.name

    const data = {
      documentList: { id },
      file,
      fileContent
    }

    await repository.postDocumentoFormalizacao(projetoId, data, false)
    setIsUploading(false)
    requestDocumentos()
  }

  const onClickDownloadFile = async () => {
    const documento = await repository.getDocumentoFormalizacao(projetoId, String(tipoId))
    if (!documento.arquivo) return
    downloadBase64File(documento.nomeArquivo, documento.arquivo)
  }

  const aceptedFileTypes = 'pdf, jpg, jpeg, png'
  const fileInputId = `fileInput${id}`

  let sendButton = <></>

  let buttonText = ''

  if (status === StatusDocumento.AGUARDANDO_APROVACAO_BANCO) {
    buttonText = 'Reenviar Documento'
  } else if (status === StatusDocumento.SALVO_MAS_NAO_ENVIADO) {
    buttonText = 'Enviar Próximo Documento'
  } else {
    buttonText = 'Enviar Documento'
  }

  if (status !== StatusDocumento.APROVADO_PELO_BANCO) {
    sendButton = <><PrimaryButton onClick={onClickDownloadFile} isLoading={repository.isLoading} block>Baixar documento para assinar</PrimaryButton><SendDocumento htmlFor={fileInputId}>{isUploading ? (<Loader animation='border' variant='secondary' />) : (<span>{buttonText}</span>)}</SendDocumento><InvisibleFileInput type='file' id={fileInputId} onChange={onChangeUploadFile} accept={aceptedFileTypes} disabled={isUploading} /></>
  }

  return (
    <>
      <strong>
        {status === StatusDocumento.SALVO_MAS_NAO_ENVIADO
          ? 'Anexado em'
          : 'Enviado em'
        }
      </strong>
      <span>{formattedDateTime}</span>
      <CheckIcon isAprovado={isAprovado} />
      {sendButton}
    </>
  )
}

const ToBeSentDocumento = (props: TToBeSentDocumento) => {
  const { projetoId, id, tipoId, statusSafra, requestDocumentos } = props

  const [isUploading, setIsUploading] = useState(false)

  const repository = useProjetos()

  const onChangeUploadFile = async (event: any) => {
    if (!event) return

    setIsUploading(true)
    const rawFile = event.target.files[0]

    const fileContent = await fileToBase64(rawFile)
    const file = rawFile.name

    const data = {
      documentList: { id },
      file,
      fileContent
    }

    await repository.postDocumentoFormalizacao(projetoId, data, false)
    setIsUploading(false)
    requestDocumentos()
  }

  const onClickDownloadFile = async () => {
    const documento = await repository.getDocumentoFormalizacao(projetoId, String(tipoId))
    if (!documento.arquivo) return
    downloadBase64File(documento.nomeArquivo, documento.arquivo)
  }

  const aceptedFileTypes = 'pdf, jpg, jpeg, png'
  const fileInputId = `fileInput${id}`

  let buttonText = ''

  if (statusSafra === StatusDocumento.AGUARDANDO_APROVACAO_BANCO) {
    buttonText = 'Reenviar Documento'
  } else if (statusSafra === StatusDocumento.SALVO_MAS_NAO_ENVIADO) {
    buttonText = 'Enviar Próximo Documento'
  } else {
    buttonText = 'Enviar Documento'
  }

  return (
    <>
      <PrimaryButton
        onClick={onClickDownloadFile}
        isLoading={repository.isLoading}
        block
      >
        Baixar documento para assinar
      </PrimaryButton>

      <SendDocumento htmlFor={fileInputId}>
        {isUploading ? (
          <Loader animation='border' variant='secondary' />
        ) : (
          <span>
            {/* {statusSafra === StatusDocumento.NEGADO_PELO_BANCO
              ? 'Reenviar Documento Assinado'
              : 'Enviar Documento Assinado'} */}
            {buttonText}
          </span>
        )}
      </SendDocumento>

      <InvisibleFileInput
        type='file'
        id={fileInputId}
        onChange={onChangeUploadFile}
        accept={aceptedFileTypes}
        disabled={isUploading}
      />
    </>
  )
}

export default Documento
import React from 'react'
import { PageHeader } from 'components'
import { Tabs, Tab } from 'react-bootstrap'
import ParametrosFinanciamento from './Financiamento'
import ParametrosAdesao from './Adesão'
import { CustomTabs, CustomTab } from './styles'

const Parametros = () => {
  return(<>
    <PageHeader title='Parâmetros' />
    <CustomTabs defaultActiveKey='financiamento'>
      <CustomTab eventKey='financiamento' title='Financiamento'>
        <ParametrosFinanciamento />
      </CustomTab>
      <CustomTab eventKey='adesao' title='Adesão'>
        <ParametrosAdesao />
      </CustomTab>
    </CustomTabs>
  </>
  )
}

export default Parametros
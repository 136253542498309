import { useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'
import { ALIQUOT_MUTATION, ALIQUOT_QUERY } from './aliquotasGql'
import { TAliquot } from 'pages/Aliquotas/validation'

type TUpdateAliquotResponse = {
  updateAliquot: TCommonResponse & {
    aliquotResult: {
      id: number
      typeClass: string
      monthValidity: string
      te: number
      tusd: number
      pisCofins: number
      icms: number
    }
  }
}

type TGetAliquotResponse = {
  getAliquot: TCommonResponse & {
    aliquot: {
      id: number
      typeClass: string
      monthValidity: string
      te: number
      tusd: number
      pisCofins: number
      icms: number
    }
  }
}

export const useUpdateAliquot = () => {
  const errorHandler = useErrorHandler()

  const [
    updateAliquot,
    { data, loading }
  ] = useMutation<TUpdateAliquotResponse>(ALIQUOT_MUTATION, {
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.updateAliquot.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (aliquota: TAliquot) => {

    const _aliquot = aliquota

    updateAliquot({
      variables: {
        updateAliquotCommand: { aliquot: { ..._aliquot } }
      }
    })
  }

  return {
    send,
    data,
    isError: data?.updateAliquot.commonResponse.isError,
    description: data?.updateAliquot.commonResponse.description,
    isLoading: loading
  }
}

export const useGetAliquot = () => {
  const errorHandler = useErrorHandler()
  const [isLoading, setIsLoading] = useState(false)

  const [requestAliquot, { data }] = useLazyQuery<TGetAliquotResponse>(ALIQUOT_QUERY, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.getAliquot.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: number) => {
    setIsLoading(true)
    requestAliquot({
      variables: { getAliquotCommand: { id } }
    })
  }

  return {
    aliquot: data?.getAliquot.aliquot,
    get,
    isLoading
  }
}
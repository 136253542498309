import React, { useState, useEffect } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { Container, Main, Content, Overlay } from './styles'

type TProps = {
  children: React.ReactNode
}

const Layout = ({ children }: TProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  useEffect(() => {
    const closeSidebar = () => {
      if (window.innerWidth > 500) setIsSidebarOpen(false)
    }

    window.addEventListener('resize', closeSidebar)
  }, [])

  const openSidebar = () => setIsSidebarOpen(true)

  const closeSidebar = () => setIsSidebarOpen(false)

  return (
    <Container>
      <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      <Overlay isOpen={isSidebarOpen} onClick={closeSidebar} />
      <Main>
        <Header openSidebar={openSidebar} />
        <Content>{children}</Content>
      </Main>
    </Container>
  )
}


export default Layout

import { useEffect } from 'react'
import { useMutation, useLazyQuery, useQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'

import {
  QUERY_FUNCIONALIDADES,
  QUERY_PROFILE,
  QUERY_PROFILES,
  MUTATION_CREATE_PROFILE,
  MUTATION_UPDATE_PROFILE
} from 'repositories/perfisGql'

type TPaginationInfo = {
  paginationInfo: {
    numberOfRecordsByPage: number,
    pageNumber: number,
    totalRecords: number,
    totalPages: number,
  }
}

type TFuncionalidadesResultResponse = {
  listFunctionalitiesResult: TPermissionList[]
}

type TFuncionalidadesResponse = {
  listFunctionalities: TCommonResponse & TFuncionalidadesResultResponse
}

export const useGetFuncionalidades = () => {
  const errorHandler = useErrorHandler()

  const { loading, data } = useQuery<TFuncionalidadesResponse>(QUERY_FUNCIONALIDADES, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.listFunctionalities.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(Number(description))
  }, [data])

  return {
    loading,
    data: data?.listFunctionalities
  }
}

type TProfilesResultResponse = {
  profileList: TPerfisResponse[]
}

type TProfilesResponse = {
  listProfiles: TCommonResponse & TPaginationInfo & TProfilesResultResponse
}

type TVariableParams = {
  id?: string,
  name?: string,
  status?: string,
  paginate: {
    pageNumber: number,
    numberOfRecordsByPage: number,
  }
}

export const useGetProfiles = () => {
  const errorHandler = useErrorHandler()

  const [
    requestProfiles,
    { loading, data }
  ] = useLazyQuery<TProfilesResponse>(QUERY_PROFILES, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  const get = (params: TVariableParams) => {
    requestProfiles({
      variables: {
        listProfilesCommand: params
      },
    })
  }

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.listProfiles.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(Number(description))
  }, [data])

  return {
    get,
    loading,
    perfis: data?.listProfiles.profileList,
    paginationInfo: data?.listProfiles.paginationInfo
  }
}

type TProfileResultResponse = {
  profile: TPerfisResponse
}

type TProfileResponse = {
  getProfile: TCommonResponse & TProfileResultResponse & {
    usersAddedToThisProfile: boolean
  }
}

export const useGetProfile = () => {
  const errorHandler = useErrorHandler()

  const [
    requestProfile,
    { loading, data }
  ] = useLazyQuery<TProfileResponse>(QUERY_PROFILE, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  const get = (profileId: string) => {
    requestProfile({
      variables: {
        getProfileCommand: {
          id: profileId
        }
      },
    })
  }

  useEffect(() => {
    if (!data) return
    const { isError, description } = data?.getProfile.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(Number(description))
  }, [data])

  return {
    get,
    loading,
    perfilJaUtilizado: data?.getProfile.usersAddedToThisProfile,
    data: data?.getProfile.profile
  }
}

type TRegisterNewProfile = {
  registerNewProfile: TCommonResponse
}

export const usePostProfile = () => {
  const errorHandler = useErrorHandler()

  const [
    registerProfile,
    { loading, data }
  ] = useMutation<TRegisterNewProfile>(MUTATION_CREATE_PROFILE, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  const save = (body: TPerfisPostOrUpdate) => {
    registerProfile({
      variables: {
        registerProfileCommand: { ...body }
      }
    })
  }

  useEffect(() => {
    if (!data) return
    const { isError, statusCode } = data.registerNewProfile.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(Number(statusCode))
  }, [data])

  return {
    loading,
    response: data?.registerNewProfile.commonResponse,
    save
  }
}

type TUpdateProfile = {
  updateProfile: TCommonResponse
}

export const useUpdateProfile = () => {
  const errorHandler = useErrorHandler()

  const [
    updateProfile,
    { loading, data }
  ] = useMutation<TUpdateProfile>(MUTATION_UPDATE_PROFILE, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  const update = (body: TPerfisPostOrUpdate) => {
    updateProfile({
      variables: {
        updateProfileCommand: { ...body }
      }
    })
  }

  useEffect(() => {
    if (!data) return
    const { isError, statusCode } = data.updateProfile.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(statusCode)
  }, [data])

  return {
    loading,
    response: data?.updateProfile.commonResponse,
    update
  }
}
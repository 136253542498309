import React, { useEffect, useState } from 'react'
import useProjetos from 'repositories/useProjetos'
import { formatDateTimeToView } from 'utils/helpers'
import {
  Container,
  TimelineDigitalFormalizationContainer,
  Entry,
  Body,
  Date
} from './styles'
import { Loader } from 'components'
import {
  Box,
  BoxTitle
} from '../../styles'

type TProps = {
  id: string
}

const TimelineDigitalFormalization = (props: TProps) => {
  const { id } = props
  const [digitalFormalizationTimelineEntries, setDigitalFormalizationTimelineEntries] = useState<TProjetoDigitalFormalizationTimelineRequest[]>([])
  const repository = useProjetos()

  useEffect(() => {

    const requestTimeline = async () => {
      const digitalFormalizationTimeline = await repository.getDigitalFormalizationTimeline(id)
      if (!digitalFormalizationTimeline) return
      setDigitalFormalizationTimelineEntries(digitalFormalizationTimeline)
    }

    requestTimeline()
  }, [id])

  return (
    <Container>
      <Box height={624}>
        <BoxTitle>
          <h3>Linha do tempo da formalização digital</h3>
        </BoxTitle>
        {repository.isLoading ? (
          <Loader />
        ) : (
          <>
            {digitalFormalizationTimelineEntries.length > 0? (
              <TimelineDigitalFormalizationContainer>
                {digitalFormalizationTimelineEntries.map(digitalFormalizationTimelineEntry => {
                  const formattedDataEvento = formatDateTimeToView(digitalFormalizationTimelineEntry.dataFim).replace('Invalid date','')

                  return (
                    <Entry key={digitalFormalizationTimelineEntry.id}>
                      <Date>
                        <p>{formattedDataEvento}</p>
                      </Date>
                      <Body>
                        <p>{digitalFormalizationTimelineEntry.descricao}</p>
                      </Body>
                    </Entry>
                  )
                })}
              </TimelineDigitalFormalizationContainer>
            ) : (
              <span>Formalização Digital ainda não iniciada!</span>
            )}
          </>
        )}
      </Box>
    </Container>
  )
}

export default TimelineDigitalFormalization
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useHistory, useParams, useLocation, Redirect } from 'react-router-dom'
import { useFormik } from 'formik'
import { AsyncTypeahead, TypeaheadModel } from 'react-bootstrap-typeahead'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'
import {
  initialValues,
  schema
} from 'pages/Integradores/Formulario/validation'

import {
  Row,
  Col,
  Form
} from 'react-bootstrap'

import useIntegradores from 'repositories/useIntegradores'
import useParametros from 'repositories/useParametros'

import ContasBancarias from './Components/ContasBancarias'
import {
  PageHeader,
  FormTitle,
  FormSubTitle,
  ButtonsContainer,
  PrimaryButton,
  GoBackButton,
  ConfirmButton,
  TextField,
  MaskedTextField,
  SelectField,
  SearchField
} from 'components'
import { formatResponseToOptions, getDiffDaysBetweenDates, getStatusBancarioBool } from 'utils/helpers'
import { confirmationMessage, estadosApiSafraOptions, Variant, bankStatusOptions } from 'utils/constants'
import useInformacoes from 'repositories/useInformacoes'
import { useAuth } from 'hooks/useAuth'

const toastCreateSucessMessage = 'Integrador adicionado com sucesso'
const toastUpdateSucessMessage = 'Integrador editado com sucesso!'
const toastMessageEmptyContasBancarias = 'Não é possível incluir ou atualizar um integrador sem conta(s) bancária(s)'

enum Status {
  ATIVO = 1,
  INATIVO = 2
}

const IntegradorForm = () => {
  const [contasBancarias, setContasBancarias] = useState<TAccountInformation[]>([])
  const [cidadeOptions, setCidadeOptions] = useState<TypeaheadModel[]>([])
  const [statusOptions, setStatusOptions] = useState<TSelectOption[]>([])
  const [situacoesOptions, setSituacoesOptions] = useState<TSelectOption[]>([])

  const { addToast } = useToasts()
  const repository = useIntegradores()
  const { getParametros } = useParametros()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const { getCidades } = useInformacoes()
  const cidadeRef = useRef<AsyncTypeahead<string>>(null)
  const { userData, userPermissions, hasFormPermission } = useAuth()

  const { permissions, permissionForm } = useMemo(() => {
    const permission = userPermissions?.integradores
    return {
      permissions: permission,
      permissionForm: hasFormPermission(permission!, location.pathname)
    }
  }, [userPermissions])

  useEffect(() => {
    const requestStatus = async () => {
      const response = await repository.getStatus()
      if (!response) return
      const status = formatResponseToOptions(response.integratorStatus)
      setStatusOptions(status)
    }

    const requestSituacoes = async () => {
      const response = await repository.getSituacoes()
      if (!response) return

      const situacoes = formatResponseToOptions(response.integratorSituation)
      setSituacoesOptions(situacoes)
    }

    const requestParametros = async () => {
      const response = await getParametros()
      if (response) {
        formik.setFieldValue('validade', response.validityOfIntegrators)
      }
    }

    const requestIntegrador = async () => {
      const response = await repository.getIntegrador(id)
      if (!response) return

      const integrador: TIntegradorForm = {
        id: String(response.id),
        cnpj: response.cnpj,
        razaoSocial: response.companyName,
        nomeFantasia: response.fantasyName,
        inscricaoMunicipal: response.municipalRegistration,
        numeroProjetosExecutados: String(response.executedProjectsNumber),
        potenciaInstalacao: String(response.installatedPower),
        email: response.institucionalEmail,
        telefone: response.phone,
        dataFundacao: response.foundationDate,
        dataEfetivacao: response.effectiveDate,
        dataCriacao: String(response.createdDate),
        temUsuario: String(response.hasUser),
        criarUsuario: '',
        status: String(response.status.id),
        statusBancario: String(response.statusBankIsBlocked),
        situacao: String(response.situation.id),
        validade: String(response.validity),
        vigencia: '',
        cep: response.address.zipCode,
        endereco: response.address.street,
        numero: response.address.number,
        bairro: response.address.neighborhood,
        complemento: response.address.complement,
        estado: String(response.address.state.id),
        cidadeId: String(response.address.city.id),
        cidade: String(response.address.city.description),
        responsavelNome: response.responsible.fullName,
        responsavelEmail: response.responsible.email,
        responsavelCelular: response.responsible.celular,
        tecnicoNome: response.technicalManager.fullName,
        tecnicoEmail: response.technicalManager.email,
        tecnicoCelular: response.technicalManager.celular,
        tecnicoCrea: String(response.technicalManager.crea),
        tecnicoCargo: String(response.technicalManager.jobPosition),
      }
      formik.setValues(integrador)
      setContasBancarias(response.bankAccountInformations)
      validateVigenciaStatusIntegrador(integrador.dataEfetivacao)
    }

    requestStatus()
    requestSituacoes()
    validateVigenciaStatusIntegrador(formik.values.dataEfetivacao)

    if (id) {
      requestIntegrador()
      return
    }
    requestParametros()
  }, [id])

  const onSubmit = async () => {
    if (!contasBancarias.length) {
      addToast(
        toastMessageEmptyContasBancarias,
        { appearance: 'error', autoDismiss: true }
      )
      return
    }

    const { values } = formik
    const data = {
      id: Number(values.id),
      cnpj: values.cnpj,
      companyName: values.razaoSocial,
      fantasyName: values.nomeFantasia,
      municipalRegistration: values.inscricaoMunicipal,
      executedProjectsNumber: Number(values.numeroProjetosExecutados),
      installatedPower: Number(values.potenciaInstalacao),
      institucionalEmail: values.email,
      phone: values.telefone,
      createUser: Boolean(values.criarUsuario),
      foundationDate: values.dataFundacao,
      effectiveDate: values.dataEfetivacao,
      validity: Number(values.validade),
      status: {
        id: Number(values.status)
      },
      statusBankIsBlocked: getStatusBancarioBool(values.statusBancario),
      situation: {
        id: Number(values.situacao)
      },
      address: {
        zipCode: values.cep,
        street: values.endereco,
        number: values.numero,
        complement: values.complemento,
        neighborhood: values.bairro,
        city: {
          id: Number(values.cidadeId)
        },
        state: {
          id: Number(values.estado)
        }
      },
      responsible: {
        fullName: values.responsavelNome,
        email: values.responsavelEmail,
        celular: values.responsavelCelular,
      },
      technicalManager: {
        fullName: values.tecnicoNome,
        email: values.tecnicoEmail,
        celular: values.tecnicoCelular,
        crea: String(values.tecnicoCrea),
        jobPosition: String(values.tecnicoCargo),
      },
      bankAccountInformations: contasBancarias,
    }
    const response = id
      ? await repository.putIntegrador(data)
      : await repository.postIntegrador(data)
    if (response.error) return

    const _toastCreateSuccessMessage = !id && response.code === 22005
      ? `${toastCreateSucessMessage} e e-mail para criação do usuário enviado com sucesso!`
      : `${toastCreateSucessMessage}, porem e-mail para criação do usuário não foi enviado!`

    history.push('/integradores')
    addToast(
      id ? toastUpdateSucessMessage : _toastCreateSuccessMessage,
      { appearance: 'success', autoDismiss: true }
    )
  }

  const formik = useFormik<TIntegradorForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })

  const hasError = (name: keyof TIntegradorForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TIntegradorForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const updateContasBancarias = (values: TAccountInformation[]) => {
    setContasBancarias(values)
  }

  const onChangeEstado = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('estado', value, true)
    formik.setFieldValue('cidade', '')
    formik.setFieldValue('cidadeId', '')
    if (cidadeRef.current) cidadeRef.current.clear()
  }

  const onSearchCidade = (term: string) => {
    if (!formik.values.estado || term.length < 3) return

    const requestCidades = async () => {
      const params = {
        search: term,
        stateId: String(formik.values.estado)
      }
      const cidades = await getCidades(params)
      if (!cidades) return

      const _cidadeOptions = cidades.map(cidade => ({
        label: cidade.description,
        value: cidade.id
      }))

      setCidadeOptions(_cidadeOptions)
    }

    requestCidades()
  }

  const onChangeCidade = ([cidade]: TSelectOption[]) => {
    const value = cidade?.value ?? ''
    const label = cidade?.label ?? ''
    formik.setFieldValue('cidadeId', value, true)
    formik.setFieldValue('cidade', label, true)
  }

  const onChangeStatusBancario = (e: TInputEvent) => {
    formik.setFieldValue('statusBancario', e.target.value)
  }

  const onBlurCidade = () => {
    formik.setFieldTouched('cidade', true)
    if (!formik.values.cidade) cidadeRef.current?.clear()
  }

  const onClickGoBackToList = () => history.push('/integradores')

  const { isVisualizando, headerTitle } = useMemo(() => {
    const url = location.pathname.split('/')[2]
    const isVisualizando = url === 'visualizar'
    return {
      isVisualizando,
      headerTitle: (
        isVisualizando ? 'Visualizar' : (id ? 'Editar' : 'Adicionar')
      )
    }
  }, [location.pathname, id])

  const validateVigenciaStatusIntegrador = (dataEfetivacao: string) => {
    if (!dataEfetivacao) return

    const { validade } = formik.values
    const endVigencia = moment(dataEfetivacao).add(validade, 'days')
    const diff = getDiffDaysBetweenDates(new Date, endVigencia)
    const status = diff < 0 ? Status.INATIVO : Status.ATIVO
    const vigencia = diff < 0
      ? `Expirou em ${endVigencia.format('DD/MM/YYYY')}`
      : `${endVigencia.format('DD/MM/YYYY')} (${Math.round(diff)}) dias restantes`
    formik.setFieldValue('vigencia', vigencia)
    formik.setFieldValue('status', status)
  }

  const onBlurDataEfetivacao = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldTouched('dataEfetivacao', true)
    validateVigenciaStatusIntegrador(value)
  }

  if (userData && !permissionForm) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={!isVisualizando}
            route='/integradores'
            message={confirmationMessage}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <PageHeader title={`${headerTitle} Integrador`} />
        </Col>
      </Row>

      <Form.Row>
        <Col>
          <FormTitle>Dados do Integrador</FormTitle>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={2}>
          <MaskedTextField
            required
            label='CNPJ'
            mask='99.999.999/9999-99'
            disabled={isVisualizando}
            {...getCommonFieldProps('cnpj')}
          />
        </Col>
        <Col md={4}>
          <TextField
            required
            label='Razão Social'
            disabled={isVisualizando}
            {...getCommonFieldProps('razaoSocial')}
          />
        </Col>
        <Col md={6}>
          <TextField
            label='Nome Fantasia'
            disabled={isVisualizando}
            {...getCommonFieldProps('nomeFantasia')}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={3}>
          <TextField
            required
            label='Inscrição Municipal'
            disabled={isVisualizando}
            {...getCommonFieldProps('inscricaoMunicipal')}
          />
        </Col>
        <Col md={3}>
          <TextField
            required
            type='date'
            label='Data de Fundação'
            disabled={isVisualizando}
            {...getCommonFieldProps('dataFundacao')}
          />
        </Col>
        <Col md={3}>
          <TextField
            required
            type='number'
            label='Número de Projetos'
            disabled={isVisualizando}
            {...getCommonFieldProps('numeroProjetosExecutados')}
          />
        </Col>
        <Col md={3}>
          <TextField
            required
            type='number'
            label='Potência instalada'
            disabled={isVisualizando}
            {...getCommonFieldProps('potenciaInstalacao')}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={6}>
          <TextField
            required
            type='email'
            label='Email'
            disabled={isVisualizando}
            {...getCommonFieldProps('email')}
          />
        </Col>
        <Col md={6}>
          <MaskedTextField
            required
            label='Telefone'
            mask='(99) 9999-9999'
            disabled={isVisualizando}
            {...getCommonFieldProps('telefone')}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <FormSubTitle>Dados da Vigência</FormSubTitle>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={4}>
          <TextField
            required
            type='date'
            label='Inicio de vigência'
            disabled={isVisualizando || !permissions?.inactivate}
            {...getCommonFieldProps('dataEfetivacao')}
            onBlur={onBlurDataEfetivacao}
          />
        </Col>
        <Col md={8}>
          <TextField
            disabled={true}
            label='Data de expiração da vigência'
            {...getCommonFieldProps('vigencia')}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={4}>
          <SelectField
            required
            label='Status'
            options={statusOptions}
            disabled={true}
            {...getCommonFieldProps('status')}
          />
        </Col>
        <Col md={4}>
          <SelectField
            required
            label='Status Bancário'
            options={bankStatusOptions}
            disabled={isVisualizando || !permissions?.approval}
            {...getCommonFieldProps('statusBancario')}
            onChange={onChangeStatusBancario}
          />
        </Col>
        <Col md={4}>
          <SelectField
            required
            label='Situação do integrador'
            options={situacoesOptions}
            disabled={isVisualizando || !permissions?.approval}
            {...getCommonFieldProps('situacao')}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <FormSubTitle>Endereço</FormSubTitle>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={3}>
          <MaskedTextField
            required
            disabled={isVisualizando}
            label='CEP'
            mask='99999-999'
            {...getCommonFieldProps('cep')}
          />
        </Col>

        <Col md={5}>
          <TextField
            required
            disabled={isVisualizando}
            label='Logradouro'
            {...getCommonFieldProps('endereco')}
          />
        </Col>

        <Col md={4}>
          <TextField
            required
            disabled={isVisualizando}
            type='string'
            label='Número'
            {...getCommonFieldProps('numero')}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={5}>
          <TextField
            required
            disabled={isVisualizando}
            label='Bairro'
            {...getCommonFieldProps('bairro')}
          />
        </Col>

        <Col md={3}>
          <SelectField
            required
            label='Estado'
            disabled={isVisualizando}
            options={estadosApiSafraOptions}
            {...getCommonFieldProps('estado')}
            onChange={onChangeEstado}
          />
        </Col>

        <Col md={4}>
          <SearchField
            required
            label='Cidade'
            disabled={isVisualizando}
            elementRef={cidadeRef}
            options={cidadeOptions}
            {...getCommonFieldProps('cidade')}
            onSearch={onSearchCidade}
            onChange={onChangeCidade}
            onBlur={onBlurCidade}
            description='Para pesquisar uma cidade, selecione um "Estado" antes'
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={5}>
          <TextField
            label='Complemento'
            disabled={isVisualizando}
            {...getCommonFieldProps('complemento')}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <FormSubTitle>Responsável</FormSubTitle>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={4}>
          <TextField
            required
            label='Nome do Responsável'
            disabled={isVisualizando}
            {...getCommonFieldProps('responsavelNome')}
          />
        </Col>
        <Col md={5}>
          <TextField
            required
            type='email'
            label='Email'
            disabled={isVisualizando}
            {...getCommonFieldProps('responsavelEmail')}
          />
        </Col>
        <Col md={3}>
          <MaskedTextField
            required
            label='Celular'
            mask='(99) 99999-9999'
            disabled={isVisualizando}
            {...getCommonFieldProps('responsavelCelular')}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <FormSubTitle>Responsável Técnico</FormSubTitle>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={4}>
          <TextField
            required
            label='Responsável Técnico'
            disabled={isVisualizando}
            {...getCommonFieldProps('tecnicoNome')}
          />
        </Col>
        <Col md={5}>
          <TextField
            required
            type='email'
            label='Email'
            disabled={isVisualizando}
            {...getCommonFieldProps('tecnicoEmail')}
          />
        </Col>
        <Col md={3}>
          <MaskedTextField
            required
            label='Celular'
            mask='(99) 99999-9999'
            disabled={isVisualizando}
            {...getCommonFieldProps('tecnicoCelular')}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={4}>
          <MaskedTextField
            required
            label='Crea'
            mask='999999999-9'
            disabled={isVisualizando}
            {...getCommonFieldProps('tecnicoCrea')}
          />
        </Col>
        <Col md={8}>
          <TextField
            required
            label='Cargo'
            disabled={isVisualizando}
            {...getCommonFieldProps('tecnicoCargo')}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <FormSubTitle>Dados Bancários</FormSubTitle>
        </Col>
      </Form.Row>
      <ContasBancarias
        contasBancarias={contasBancarias}
        updateContasBancarias={updateContasBancarias}
        isVisualizando={isVisualizando}
      />
      <ButtonsContainer>
        <ConfirmButton
          active={!isVisualizando}
          actionFn={onClickGoBackToList}
          message={confirmationMessage}
          variant={Variant.SECONDARY}
        >
          <span>{isVisualizando ? 'Voltar' : 'Cancelar'}</span>
        </ConfirmButton>
        {!isVisualizando && (
          <PrimaryButton
            type='button'
            isLoading={repository.isLoading}
            onClick={formik.handleSubmit}
          >
            <span>Salvar</span>
          </PrimaryButton>
        )}
      </ButtonsContainer>
    </>
  )
}

export default IntegradorForm
import { gql } from 'apollo-boost'
import { commonResponse, paginationInfo } from './constants'

export const CONTRACTS_QUERY = gql`
  query findContract (
    $findContractByFilterCommand: FindContractByFilterCommand,
    $paginate: Paginate
    ) {
    findContractByFilter (
        findContractByFilterCommand: $findContractByFilterCommand
        paginate: $paginate
    ) {
      ${commonResponse}
      ${paginationInfo}
      contractSuccess {
        id
        contractHash    
        contractPipefyId
        sellerName
        unitConsumption
        averageConsumption
        averageCost
        finalCost
        discount
        readingDate
        category
        type
        subclass
        status
        client {
            id
            fullName
            cpf
            companyName
            cnpj
        }
        powerPlant {
            id
            name
            isActive
            power
            operationStartDate
            consumerUnit
            powerCompanyReadingDay
            powerPlantProductionReadingDay
            powerCompany {
                id
                name
            }
            powerPlantAddress {
                id
                ruralName
                city
                state
            }
        }
        attachments {
          id
          fileName
          createdDate
        }
      }
    }
  }
`

export const CONTRACT_BY_ID_QUERY = gql`
  query findContractById ($id : Long!) {
    findContractById (id : $id) {
      ${commonResponse}
      contractSuccess {
        id
        contractHash    
        contractPipefyId
        sellerName
        unitConsumption
        averageConsumption
        averageCost
        finalCost
        discount
        readingDate
        category
        type
        subclass
        status
        contractAddress {
          id
          zipCode
          street
          number
          complement
          neighborhood
          addressType
          city
          state
        }
        client {
          id
          fullName
          cpf
          companyName
          cnpj
        }
        powerPlant {
            id
            name
            isActive
            power
            operationStartDate
            consumerUnit
            powerCompanyReadingDay
            powerPlantProductionReadingDay
            powerCompany {
                id
                name
            }
            powerPlantAddress {
                id
                ruralName
                city
                state
            }
        }
        attachments {
          id
          fileName
          createdDate
        }
      }
    }
  }
`

export const ATTACHMENT_DOWNLOAD_LINK = gql`
  query getAttachmentDownloadLink ($id : Long!) {
    getAttachmentDownloadLink (id : $id) {
      ${commonResponse}
      publicLinkEnergyBill
    }
  }
`

export const SAVE_CONTRACT = gql`
  mutation saveContracts ($registerContractCommand: RegisterContractCommand){
    saveContracts(registerContractCommand: $registerContractCommand){
      ${commonResponse}
    }
  }
`

export const UPDATE_CONTRACT_STATUS = gql`
  mutation updateContractStatus ($id : Long!, $newContractStatus: String){
    updateContractStatus(id: $id, newContractStatus: $newContractStatus){
      ${commonResponse}
    }
  }
`

export const UPDATE_CONTRACT = gql`
  mutation saveOrUpdateContracts ($updateContractCommand: UpdateContractCommand){
    saveOrUpdateContracts(updateContractCommand: $updateContractCommand){
      ${commonResponse}
    }
  }
`
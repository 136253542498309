import styled from 'styled-components'
import { ModalTitle as ModalTitleBS } from 'react-bootstrap'

export const ModalTitle = styled(ModalTitleBS)`
  color: ${p => p.theme.colors.ORANGE};
  font-weight: 700;
  font-size: 1.2rem;
`

export const ModalContent = styled.div`
  box-shadow: 0px 0px 15px 4px rgb(2 0 0 / 30%);
`
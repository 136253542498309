import React from 'react'
import { Form } from 'react-bootstrap'
import { FormLabel } from 'components'

const TextInput = props => {
  const {
    field,
    label = '',
    type = 'text',
    placeholder = '',
    value,
    handleChange,
    error = {},
    description = '',
    ...rest
  } = props
  return (
    <Form.Group controlId={field}>
      <FormLabel required={rest.required}>{label}</FormLabel>
      <Form.Control
        type={type}
        name={field}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        isInvalid={Boolean(error.isError)}
        autoComplete='off'
        {...rest}
      />
      <Form.Control.Feedback type='invalid'>
        {error.message}
      </Form.Control.Feedback>
      {description && <Form.Text className='text-muted'>{description}</Form.Text>}
    </Form.Group>
  )
}

export default TextInput
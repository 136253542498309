import { useState } from 'react'
import useRest from 'hooks/useRest'

type TCidadesParams = {
  stateId: string
  search: string
}

type TNacionalidadeParams = Omit<TCidadesParams, 'stateId'>

type TNacionalidadeRequest = {
  description: string
  id: string
}

const useInformacoes = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getEstados = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/location/states'
    const params = { pageSize: 27 }
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as TUfRequest[]
    return response.states as TUfRequest[]
  }

  const getCidades = async (params: TCidadesParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/location/cities'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as TCidadesRequest[]
    return response.citys as TCidadesRequest[]
  }

  const getBancos = async (search: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/banks'
    const params = { search }
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as TBancosResponse[]
    return response.banks as TBancosResponse[]
  }

  const getTiposEndereco = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/location/address-types'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response.addressTypes
  }

  const getEscolaridades = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/personal/schooling-types'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response.schoolingTypes
  }

  const getEstadosCivis = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/personal/marital-status-types'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response.maritalStatuss
  }

  const getNacionalidades = async (params: TNacionalidadeParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/location/nationalities'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as TNacionalidadeRequest[]
    return response.nationalitys as TNacionalidadeRequest[]
  }

  const getTiposDocumentoIdentificacao = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/document/identification-document-types'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response.identificationDocumentTypes
  }

  const getNaturezasProfissao = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/occupation/profession-natures'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response.professionNatures
  }

  const getPatrimonios = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/patrimonies'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response.patrimonys
  }

  const getProfissoes = async (idSetorOcupacional: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/occupation/professions'
    const params = {
      professionNatureId: idSetorOcupacional
    }
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response.professions
  }

  const getCargos = async (params: TCargosParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/occupation/occupation-roles'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response.occupationRoles
  }

  const getCompanySize = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/PJ/companySize'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response
  }

  const getActivityBranch = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/information/PJ/activityBranch'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response
  }

  const getEconomicActivity = async (activityBranch: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/information/PJ/economicActivity/${activityBranch}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response
  }

  return {
    isLoading,
    getEstados,
    getCidades,
    getBancos,
    getTiposEndereco,
    getEscolaridades,
    getEstadosCivis,
    getNacionalidades,
    getTiposDocumentoIdentificacao,
    getNaturezasProfissao,
    getPatrimonios,
    getProfissoes,
    getCargos,
    getCompanySize,
    getActivityBranch,
    getEconomicActivity
  }
}

export default useInformacoes
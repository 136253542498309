import React, { useState, useMemo, useEffect } from 'react'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

import useProjetos from 'repositories/useProjetos'
import { Loader, InfoText, GoBackButton, PageHeader, TextField } from 'components'
import Documento from './Components/Documento'
import TimelineDigitalFormalization from '../Detalhes/Components/TimelineDigitalFormalization'
import { List } from './style'


const DocumentosFormalizacao = () => {
  const [projetoNome, setProjetoNome] = useState('')
  const [documentos, setDocumentos] = useState<TDocumentoFormalizacaoRequest[]>([])
  const { id } = useParams<{ id: string }>()
  const location = useLocation<{ fromDetalhes: boolean }>()
  const [projeto, setProjeto] = useState<TProjetoBody>()
  const repository = useProjetos()
  const [projetoLink, setProjetoLink] = useState('')

  useEffect(() => {
    if (id) requestDocumentos() 
    requestProjeto()
    requestLinkFormalizacao()
  }, [id])

  
  const requestProjeto = async () => {
    const triggerLoading = false
    const projeto = await repository.getProjeto(id, triggerLoading)
    setProjeto(projeto)
  }

  const requestDocumentos = async () => {
    const response = await repository.getDocumentosFomalizacao(id)
    if (!response) return
    setProjetoNome(response.projectName)
    setDocumentos(response.documents)
  }

  const requestLinkFormalizacao = async () => {
    const projeto = await repository.getProjeto(id, false)
    if (projeto!.client !== null) {
      const response = await repository.getDigitalFormalizationLink(id)
      if (!response) return
      setProjetoLink(response.url)
    }
  }

  const goBackRoute = `/projetos/detalhes/${id}`

  const sentDocumentos = useMemo(() => (
    documentos.reduce((acc, curr) => (
      curr.status > 1 ? acc + 1 : acc
    ), 0)
  ), [documentos])

  const completion = `${sentDocumentos}/${documentos.length}`

  if (!location.state) return <Redirect to={goBackRoute} />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton route={goBackRoute} />
        </Col>
      </Row>

      <Loader isLoading={repository.isLoading} />

      {Boolean(documentos.length) && !repository.isLoading && projeto!.client !== null && (
        <>
          <Row>
            <Col>
              <PageHeader title='Documentos de Formalização' />
            </Col>
          </Row>
          <Row>
            <Col>
              <InfoText>
                Precisamos que envie alguns documentos necessários para efetuarmos
                a formalização do projeto.
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                <strong>Dica:</strong>

                <span>
                  Você pode enviar os documentos parcialmente. Não é necessário
                  enviar todos de uma vez só.
                </span>
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText isPartOfGroup>
                <strong>Projeto:</strong>
                <span>{projetoNome}</span>
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                <strong>Documentos entregues:</strong>
                <span>{completion}</span>
              </InfoText>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TimelineDigitalFormalization
                id={id}
              />
            </Col>
          </Row>
          <Row>
          <Col md={12}>
                <TextField
                  disabled={true}
                  label='Url de Formalização'
                  name='Url de Formalização'
                  defaultValue={projetoLink}
                />
              </Col>
          </Row>
          <Row>
            <Col>
              <h3>Envio Manual dos documentos de formalização</h3>
            </Col>
          </Row>
          <List>
            {documentos.map((documento, index) => {
              if (index > 1) return null
              return (
                <Documento
                  arquivo={documento.arquivo}
                  key={documento.id}
                  projetoId={id}
                  id={documento.id}
                  requestDocumentos={requestDocumentos}
                  tipoId={documento.tipo_id}
                  tipoDescricao={documento.tipo_descricao}
                  status={documento.status}
                  dataHoraEnvio={documento.data_hora_envio}
                  dataHoraParecer={documento.data_hora_parecer}
                  comentarioParecer={documento.comentario_parecer}
                />
              )
            })}
          </List>

          <List>
            {documentos.map((documento, index) => {
              if (index < 2) return null
              return (
                <Documento
                  arquivo={documento.arquivo}
                  key={documento.id}
                  projetoId={id}
                  id={documento.id}
                  requestDocumentos={requestDocumentos}
                  tipoId={documento.tipo_id}
                  tipoDescricao={documento.tipo_descricao}
                  status={documento.status}
                  dataHoraEnvio={documento.data_hora_envio}
                  dataHoraParecer={documento.data_hora_parecer}
                  comentarioParecer={documento.comentario_parecer}
                />
              )
            })}
          </List>
        </>
      )}
      {Boolean(documentos.length) && !repository.isLoading && projeto!.client === null && (
        <>
          <Row>
            <Col>
              <PageHeader title='Documentos de Formalização' />
            </Col>
          </Row>
          <Row>
            <Col>
              <InfoText>
                Precisamos que envie alguns documentos necessários para efetuarmos
                a formalização do projeto.
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                <strong>Dica:</strong>

                <span>
                  Você pode enviar os documentos parcialmente. Não é necessário
                  enviar todos de uma vez só.
                </span>
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText isPartOfGroup>
                <strong>Projeto:</strong>
                <span>{projetoNome}</span>
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                <strong>Documentos entregues:</strong>
                <span>{completion}</span>
              </InfoText>
            </Col>
          </Row>
          <List>
            {documentos.map((documento, index) => {
              if (index > 1) return null
              return (
                <Documento
                  arquivo={documento.arquivo}
                  key={documento.id}
                  projetoId={id}
                  id={documento.id}
                  requestDocumentos={requestDocumentos}
                  tipoId={documento.tipo_id}
                  tipoDescricao={documento.tipo_descricao}
                  status={documento.status}
                  dataHoraEnvio={documento.data_hora_envio}
                  dataHoraParecer={documento.data_hora_parecer}
                  comentarioParecer={documento.comentario_parecer}
                />
              )
            })}
          </List>

          <List>
            {documentos.map((documento, index) => {
              if (index < 2) return null
              return (
                <Documento
                  arquivo={documento.arquivo}
                  key={documento.id}
                  projetoId={id}
                  id={documento.id}
                  requestDocumentos={requestDocumentos}
                  tipoId={documento.tipo_id}
                  tipoDescricao={documento.tipo_descricao}
                  status={documento.status}
                  dataHoraEnvio={documento.data_hora_envio}
                  dataHoraParecer={documento.data_hora_parecer}
                  comentarioParecer={documento.comentario_parecer}
                />
              )
            })}
          </List>
        </>
      )}
    </>
  )
}

export default DocumentosFormalizacao
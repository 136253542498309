import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { useFormik } from 'formik'
import { initialValues, schema } from './validation'
import cepPromise from 'cep-promise'
import { Form, Col, Tab } from 'react-bootstrap'
import { AsyncTypeahead, TypeaheadModel } from 'react-bootstrap-typeahead'
import {
  CustomTabs,
} from './styles'

import {
  Loader,
  GoBackButton,
  PageHeader,
  FormSubTitle,
  ButtonsContainer,
  PrimaryButton,
  ConfirmButton,
  FormDivider,
  TextField,
  MaskedTextField,
  MaskedCurrencyField,
  SelectField,
  SearchField
} from 'components'

import {
  formatCurrency,
  formatCurrencyToServer,
  formatDecimalNumber
} from 'utils/helpers'
import useInformacoes from 'repositories/useInformacoes'
import useProjetos from 'repositories/useProjetos'
import Modules from './Components/Modules'
import Inverters from './Components/Inverters'

import { confirmationMessage, estadosApiSafraOptions, Variant } from 'utils/constants'
import { useAuth } from 'hooks/useAuth'

const errorMessage = 'Necessário incluir pelo menos um módulo e um inversor'

const formatModules = (modules: TModule[]) => {
  const formattedData = modules.map(module => ({
    ...module,
    equipmentValue: formatCurrencyToServer(String(module.equipmentValue))
  }))
  return formattedData
}

const formatInverters = (inverters: TInverter[]) => {
  const formattedData = inverters.map(inverter => ({
    ...inverter,
    equipmentValue: formatCurrencyToServer(String(inverter.equipmentValue))
  }))
  return formattedData
}

const DadosTecnicos = () => {
  const [isDisableItems, setIsDisableItems] = useState(false)
  const [cidadeOptions, setCidadeOptions] = useState<TypeaheadModel[]>([])

  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation<{ fromDetalhes: boolean }>()
  const repositoryProjetos = useProjetos()
  const { getCidades } = useInformacoes()
  const { addToast } = useToasts()
  const cidadeRef = useRef<AsyncTypeahead<string>>(null)
  const { userPermissions } = useAuth()
  useMemo(() => {
    const permissions = userPermissions?.projetos
    if (!permissions?.edit) setIsDisableItems(true)
  }, [userPermissions])

  const onSubmit = async () => {
    const { values } = formik
    if (!values.modulos?.length || !values.inversores?.length) {
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }
    const modules = formatModules(values.modulos ?? [])
    const inverters = formatInverters(values.inversores ?? [])
    const data = {
      requestCode: values.codigoPedido,
      projectPowerKwp: values.potenciaProjeto,
      performanceRatio: values.performance,
      installationTime: values.tempoInstalacao,
      connectionVoltage: values.voltagem,
      estimatedPowerOfProject: formatCurrencyToServer(values.potenciaEstimada),
      estimatedValueOfProject: formatCurrencyToServer(values.valorEstimado),
      installationValue: formatCurrencyToServer(values.valorInstalacao),
      totalEquipmentValue: formatCurrencyToServer(values.totalEquipamento),
      installationAddress: {
        zipCode: values.cep,
        street: values.endereco,
        number: values.numero,
        complement: values.complemento,
        neighborhood: values.bairro,
        city: {
          id: values.cidadeId,
        },
        state: {
          id: values.estado
        },
      },
      technicalManager: {
        fullName: values.nomeCompleto,
        email: values.email,
        celular: values.celular,
        crea: values.crea,
        jobPosition: values.cargo,
      },
      modules,
      inverters,
    }
    await repositoryProjetos.postDadosTecnicos(id, data)
    history.push(`/projetos/detalhes/${id}`)
  }

  const formik = useFormik<TDadosTecnicosRequest>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })

  const hasError = (name: keyof TDadosTecnicosForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (
    name: keyof TDadosTecnicosForm
  ) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  })

  const onClickGoToDetalhes = () => history.push(`/projetos/detalhes/${id}`)

  useEffect(() => {
    if(formik.values.cep.replaceAll('_', '').replace('-', '').length >= 8) {
      cepPromise(formik.values.cep).then((data: any) => {
        if (data) {
          if(data.neighborhood !== '') formik.setFieldValue('bairro', data.neighborhood)
          if(data.street !== '') formik.setFieldValue('endereco', data.street)
        }
      })
    }
  }, [formik.values.cep])

  useEffect(() => {
    const requestDadosTecnicos = async () => {
      const _dados = await repositoryProjetos.getDadosTecnicos(id)
      if (!_dados) return

      if(_dados.data) setIsDisableItems(true)

      const dados = !_dados.data ? _dados.sourceData : _dados.data
      if(!dados) return
      const modulos = formatModules(dados.modules ?? [])
      const inversores = formatInverters(dados.inverters ?? [])

      const data = {
        id: String(dados.id) ?? '',
        codigoPedido: dados.requestCode,
        potenciaProjeto: dados.projectPowerKwp ? dados.projectPowerKwp : '',
        performance: dados.performanceRatio ? dados.performanceRatio : '',
        tempoInstalacao: dados.installationTime ? dados.installationTime : '',
        voltagem: dados.connectionVoltage ? dados.installationTime : '',
        potenciaEstimada: '',
        valorEstimado: '',
        valorEstimadoOrigem: '',
        valorInstalacao: dados.installationValue.toString(),
        totalEquipamento: dados.totalEquipmentValue.toString(),
        cep: dados.installationAddress.zipCode,
        endereco: dados.installationAddress.street,
        numero: dados.installationAddress.number,
        complemento: dados.installationAddress.complement,
        bairro: dados.installationAddress.neighborhood,
        estado: String(dados.installationAddress.state.id),
        cidade: dados.installationAddress.city.description ?? '',
        cidadeId: String(dados.installationAddress.city.id),
        nomeCompleto: dados.technicalManager.fullName,
        email: dados.technicalManager.email,
        celular: dados.technicalManager.celular,
        crea: dados.technicalManager.crea,
        cargo: dados.technicalManager.jobPosition,
        modulos,
        inversores,
      }
      formik.setValues(data)
    }

    const requestEstimatedValues = async () => {
      const project = await repositoryProjetos.getEstimatedValuesByProjectId(id)
      await requestDadosTecnicos()
      if (!project) return

      formik.setFieldValue(
        'potenciaEstimada',
        formatDecimalNumber(project.estimatedPowerOfProject)
      )
      formik.setFieldValue(
        'valorEstimado',
        formatDecimalNumber(project.estimatedValueOfProject)
      )
      formik.setFieldValue(
        'valorEstimadoOrigem',
        formatDecimalNumber(project.estimatedValueOfProject)
      )
    }
    requestEstimatedValues()
  }, [])

  const onChangeEstado = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('estado', value, true)
    formik.setFieldValue('cidadeId', '')
    if (cidadeRef.current) cidadeRef.current.clear()
  }

  const onSearchCidade = (term: string) => {
    if (!formik.values.estado || term.length < 3) return

    const requestCidades = async () => {
      const params = {
        search: term,
        stateId: String(formik.values.estado)
      }
      const cidades = await getCidades(params)
      if (!cidades) return

      const _cidadeOptions = cidades.map(cidade => ({
        label: cidade.description,
        value: String(cidade.id)
      }))

      setCidadeOptions(_cidadeOptions)
    }

    requestCidades()
  }

  const onChangeCidade = ([cidade]: TSelectOption[]) => {
    const value = cidade
    formik.setFieldValue('cidadeId', value.value, true)
    formik.setFieldValue('cidade', value.label, true)
  }

  const onBlurCidade = () => {
    formik.setFieldTouched('cidadeId', true)
    if (!formik.values.cidadeId) cidadeRef.current?.clear()
  }

  useEffect(() => {
    const { values } = formik
    const formattedInstallationValue = formatCurrencyToServer(values.valorInstalacao)
    const formattedTotalEquipmentValue = formatCurrencyToServer(values.totalEquipamento)
    const sumValueProject = formatDecimalNumber(
      Number(formattedInstallationValue) +
      Number(formattedTotalEquipmentValue)
    )
    formik.setFieldValue('valorEstimado', sumValueProject)

    if (sumValueProject === '0') formik.setFieldValue('valorEstimado', values.valorEstimadoOrigem)
  }, [formik.values.valorInstalacao, formik.values.totalEquipamento])

  if (!location.state) return <Redirect to={`/projetos/detalhes/${id}`} />

  return (
    <>
      <GoBackButton
        showConfirmation={!isDisableItems}
        route={`/projetos/detalhes/${id}`}
        message={confirmationMessage}
      />
      <Form.Row>
        <Col>
          <PageHeader title='Dados Técnicos' />
        </Col>
      </Form.Row>
      <Loader isLoading={repositoryProjetos.isLoading} />
      {!repositoryProjetos.isLoading && (
        <form onSubmit={formik.handleSubmit}>
          <Form.Row>
            <Col md={3}>
              <TextField
                required
                label='Código do pedido'
                disabled={isDisableItems}
                {...getCommonFieldProps('codigoPedido')}
                description='Digite o código do pedido gerado na plataforma de SGF'
              />
            </Col>
            <Col md={3}>
              <TextField
                type='number'
                label='Potência do projeto (kWp)'
                disabled={isDisableItems}
                {...getCommonFieldProps('potenciaProjeto')}
              />
            </Col>
            <Col md={2}>
              <TextField
                type='number'
                label='Performance ratio'
                disabled={isDisableItems}
                {...getCommonFieldProps('performance')}
              />
            </Col>
            <Col md={2}>
              <TextField
                type='number'
                label='Tempo de Instalação'
                disabled={isDisableItems}
                {...getCommonFieldProps('tempoInstalacao')}
              />
            </Col>
            <Col md={2}>
              <TextField
                type='number'
                label='Tensão de Conexão'
                disabled={isDisableItems}
                {...getCommonFieldProps('voltagem')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FormSubTitle>Endereço de Instalação</FormSubTitle>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={2}>
              <MaskedTextField
                required
                label='CEP'
                mask='99999-999'
                disabled={isDisableItems}
                {...getCommonFieldProps('cep')}
              />
            </Col>
            <Col md={4}>
              <TextField
                required
                label='Logradouro'
                disabled={isDisableItems}
                {...getCommonFieldProps('endereco')}
              />
            </Col>
            <Col md={2}>
              <TextField
                type='string'
                required
                label='Número'
                disabled={isDisableItems}
                {...getCommonFieldProps('numero')}
              />
            </Col>
            <Col md={4}>
              <TextField
                required
                label='Bairro'
                disabled={isDisableItems}
                {...getCommonFieldProps('bairro')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={3}>
              <SelectField
                required
                label='Estado'
                options={estadosApiSafraOptions}
                disabled={isDisableItems}
                {...getCommonFieldProps('estado')}
                onChange={onChangeEstado}
              />
            </Col>
            <Col md={3}>
              <SearchField
                required
                label='Cidade'
                disabled={isDisableItems}
                elementRef={cidadeRef}
                options={cidadeOptions}
                {...getCommonFieldProps('cidadeId')}
                onSearch={onSearchCidade}
                onChange={onChangeCidade}
                onBlur={onBlurCidade}
                value={formik.values.cidade}
                description='Para pesquisar uma cidade, selecione um "Estado" antes'
              />
            </Col>
            <Col md={6}>
              <TextField
                label='Complemento'
                disabled={isDisableItems}
                {...getCommonFieldProps('complemento')}
                description='Digite se houver algum complemento ao endereço, ex: Apto 410 ou Casa B'
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FormSubTitle>Responsável Técnico</FormSubTitle>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={6}>
              <TextField
                label='Nome'
                disabled={isDisableItems}
                {...getCommonFieldProps('nomeCompleto')}
              />
            </Col>
            <Col md={6}>
              <TextField
                label='Email'
                disabled={isDisableItems}
                {...getCommonFieldProps('email')}
              />
            </Col>
            <Col md={4}>
              <MaskedTextField
                mask='999999999-9'
                label='Crea'
                disabled={isDisableItems}
                {...getCommonFieldProps('crea')}
              />
            </Col>
            <Col md={3}>
              <MaskedTextField
                label='Celular'
                mask='(99) 99999-9999'
                disabled={isDisableItems}
                {...getCommonFieldProps('celular')}
              />
            </Col>
            <Col md={5}>
              <TextField
                label='Cargo'
                disabled={isDisableItems}
                {...getCommonFieldProps('cargo')}
              />
            </Col>
          </Form.Row>
          <FormDivider />
          <Form.Row>
            <Col md={3}>
              <MaskedCurrencyField
                required
                label='Valor do serviço'
                disabled={isDisableItems}
                {...getCommonFieldProps('valorInstalacao')}
              />
            </Col>
            <Col md={3}>
              <MaskedCurrencyField
                required
                readOnly={true}
                disabled={isDisableItems}
                label='Valor do equipamento'
                {...getCommonFieldProps('totalEquipamento')}
              />
            </Col>
            <Col md={3}>
              <MaskedCurrencyField
                required
                readOnly={true}
                disabled={isDisableItems}
                label='Valor estimado'
                {...getCommonFieldProps('valorEstimado')}
              />
            </Col>
            <Col md={3}>
              <MaskedCurrencyField
                label='Potência estimada'
                required
                disabled={isDisableItems}
                {...getCommonFieldProps('potenciaEstimada')}
              />
            </Col>
          </Form.Row>
          <CustomTabs defaultActiveKey='modules'>
            <Tab eventKey='modules' title='Módulos'>
              <Modules
                values={formik.values}
                setFieldValue={formik.setFieldValue}
                isDisableItems={isDisableItems}
              />
            </Tab>
            <Tab eventKey='inverters' title='Inversores'>
              <Inverters
                values={formik.values}
                setFieldValue={formik.setFieldValue}
                isDisableItems={isDisableItems}
              />
            </Tab>
          </CustomTabs>
          <ButtonsContainer>
            <ConfirmButton
              active={!isDisableItems}
              actionFn={onClickGoToDetalhes}
              message={confirmationMessage}
              variant={Variant.SECONDARY}
            >
              {isDisableItems ? 'Voltar' : 'Cancelar'}
            </ConfirmButton>
            <PrimaryButton
              type='submit'
              disabled={isDisableItems}
            >
              <span>Salvar</span>
            </PrimaryButton>
          </ButtonsContainer>
        </form>
      )}
    </>
  )
}

export default DadosTecnicos
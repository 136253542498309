import React, { useEffect, useMemo, useState } from 'react'
import {
  FlexContainer,
  PageHeader,
  Pagination,
  TableMessageRow,
} from 'components'
import { Col, Row, Table } from 'react-bootstrap'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import {
  TFindEnergyBillQueueErrorInfo,
  TFindEnergyBillQueueErrorParams,
  useGetCemigErrors,
} from 'repositories/errosCemig'
import { initialPaginationParams } from 'utils/constants'
import { formatDateToView } from 'utils/helpers'
import Filter from './../../components/Table/Filter/index'
import { Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { CustomTable } from './styles'

const initialParams: TFindEnergyBillQueueErrorParams = {
  findEnergyBillQueueErrorCommand: {},
  ...initialPaginationParams,
}

const ErrosCemigLista = () => {
  const pagination = usePagination()
  const { userPermissions } = useAuth()
  const filters = useFilters()
  const getCemigErrors = useGetCemigErrors()
  const [paginationInfo, setPaginationInfo] = useState<TPageProperties>()
  const [cemigErrors, setCemigErrors] = useState<
    TFindEnergyBillQueueErrorInfo[]
  >([])

  useEffect(() => {
    getCemigErrors.get(initialParams)
  }, [])

  useEffect(() => {
    if (!getCemigErrors.errors) return
    setCemigErrors(getCemigErrors.errors)
  }, [getCemigErrors.errors])

  useEffect(() => {
    requestCemigErrors()
  }, [filters.items])

  useEffect(() => {
    pagination.stopLoading()
    filters.stopLoading()

    if (!getCemigErrors.paginationInfo) return
    if (!getCemigErrors.errors) return

    setCemigErrors(getCemigErrors.errors)

    const { paginationInfo } = getCemigErrors
    setPaginationInfo({
      pageNumber: paginationInfo.pageNumber,
      totalPages: paginationInfo.totalPages,
    })
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)
  }, [getCemigErrors.paginationInfo])

  const requestCemigErrors = (pageIndex = 0, triggerLoading = false) => {
    const _params: TFindEnergyBillQueueErrorParams = {
      findEnergyBillQueueErrorCommand: {
        ...filters.getObjectifiedFilterItems(),
      },
      paginate: {
        pageNumber: pageIndex,
        numberOfRecordsByPage: 10,
      },
    }
    getCemigErrors.get(_params, triggerLoading)
  }

  const permissions = useMemo(() => userPermissions?.cemig, [userPermissions])

  if (userPermissions && !permissions?.view)
    return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Erros Cemig' />
          <CustomTable responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>CPF/CNPJ</span>
                    <Filter field='cpfOrCnpj' filters={filters} />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Unidade consumidora</span>
                    <Filter field='unitConsumption' filters={filters} />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Data</span>
                    <Filter
                      label={['Data Início', 'Data Fim']}
                      field='updatedDateStart|updatedDateEnd'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Erro</span>
                    <Filter field='error' filters={filters} />
                  </FlexContainer>
                </th>
              </tr>
            </thead>
            <tbody>
              {cemigErrors.length === 0 && (
                <TableMessageRow colSpan={7}>
                  Não foram encontrados erros para serem listados
                </TableMessageRow>
              )}
              {cemigErrors &&
                cemigErrors.map(error => (
                  <>
                    <tr key={error.id}>
                      <td>{error.cpfOrCnpj}</td>
                      <td>{error.unitConsumption}</td>
                      <td>{formatDateToView(error.recordedIn)}</td>
                      <td>{error.error}</td>
                    </tr>
                  </>
                ))}
            </tbody>
          </CustomTable>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination
            currentPageIndex={paginationInfo?.pageNumber}
            totalPages={paginationInfo?.totalPages}
            request={requestCemigErrors}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}
export default ErrosCemigLista

import { formatCurrencyToServer } from 'utils/helpers'

export const basicSpouseInfo = (values: any, isRequiredEnderecoComercial: boolean, isRequiredEnderecoConjuge: boolean) => {
    const dados: TProjetoBodyReduced = {
        estimatedPowerOfProject: formatCurrencyToServer(values.potenciaProjeto),
        estimatedValueOfProject: formatCurrencyToServer(values.valorProjeto),
        projectName: values.nomeProjeto,
        projectPurpose: {
          id: Number(values.finalidadeProjeto)
        },
        typeFinancing: {
          id: Number(values.tipoFinanciamento)
        },
        state: {
          id: Number(values.estadoProjeto)
        },
        city: {
          id: Number(values.cidadeProjetoId)
        },
        integrator: {
          id: Number(values.integradorId)
        },
        client: {
          fullName: values.nomeCompleto,
          email: values.email,
          celular: values.celular,
          birthDate: values.dataNascimento,
          address: {
            zipCode: values.cep,
            street: values.endereco,
            number: String(values.numero),
            complement: values.complemento,
            neighborhood: values.bairro,
            addressType: {
              id: Number(values.tipoResidencia)
            },
            city: {
              id: Number(values.cidadeId)
            },
            state: {
              id: Number(values.estado)
            }
          },
          occupationInformation: {
            professionNature: {
              id: Number(values.ocupacao)
            },
            profession: {
              id: Number(values.profissao)
            },
            occupationRole: {
              id: Number(values.cargo)
            },
            companyInformation: {
              cnpj: values.comercialCnpj,
              companyName: values.comercialNome,
              admissionDate: values.comercialAdmissao,
              comercialPhone: values.comercialTelefone,
              phoneExtension: values.comercialTelefoneRamal,
              address: (
                isRequiredEnderecoComercial
                  ? {
                    zipCode: values.comercialCep,
                    street: values.comercialEndereco,
                    number: String(values.comercialNumero),
                    complement: values.comercialComplemento,
                    neighborhood: values.comercialBairro,
                    addressType: {
                      id: 2,
                      description: 'COMERCIAL'
                    },
                    city: {
                      id: Number(values.comercialCidadeId),
                    },
                    state: {
                      id: Number(values.comercialEstado),
                    }
                  }
                  : null
              ),
            },
          },
          personalInformation: {
            nationality: {
              id: Number(values.nacionalidadeId)
            },
            maritalStatus: {
              id: Number(values.estadoCivil)
            },
            scholarity: {
              id: Number(values.escolaridade)
            },
            hometown: {
              id: Number(values.cidadeNaturalidadeId)
            },
            homestate: {
              id: Number(values.estadoNaturalidade)
            },
            sex: values.sexo,
            mothersName: values.nomeMae,
            cpf: values.cpf
          },
          financialInformation: {
            monthlyIncome: formatCurrencyToServer(values.rendaMensal),
            patrimony: {
              id: Number(values.patrimonio)
            }
          },
          identificationDocument: {
            documentNumber: values.documento,
            documentEmissionDate: values.dataEmissao,
            identificationDocumentType: {
              id: Number(values.tipoDocumento)
            },
            documentEmmiterInstitution: {
              id: Number(values.orgaoEmissor)
            },
            uf: {
              id: Number(values.estadoOrgaoEmissor)
            }
          },
          spouse: {
            fullName: values.conjugeNome,
            cpf: values.conjugeCpf
          }
        }
      }
  
      return dados
  }

export const completeSpouseInfo = (values: any, isRequiredEnderecoComercial: boolean, isRequiredEnderecoConjuge: boolean) => {
    const dados: TProjetoBody = {
        estimatedPowerOfProject: formatCurrencyToServer(values.potenciaProjeto),
        estimatedValueOfProject: formatCurrencyToServer(values.valorProjeto),
        projectName: values.nomeProjeto,
        projectPurpose: {
          id: Number(values.finalidadeProjeto)
        },
        typeFinancing: {
          id: Number(values.tipoFinanciamento)
        },
        state: {
          id: Number(values.estadoProjeto)
        },
        city: {
          id: Number(values.cidadeProjetoId)
        },
        integrator: {
          id: Number(values.integradorId)
        },
        client: {
          fullName: values.nomeCompleto,
          email: values.email,
          celular: values.celular,
          birthDate: values.dataNascimento,
          address: {
            zipCode: values.cep,
            street: values.endereco,
            number: String(values.numero),
            complement: values.complemento,
            neighborhood: values.bairro,
            addressType: {
              id: Number(values.tipoResidencia)
            },
            city: {
              id: Number(values.cidadeId)
            },
            state: {
              id: Number(values.estado)
            }
          },
          occupationInformation: {
            professionNature: {
              id: Number(values.ocupacao)
            },
            profession: {
              id: Number(values.profissao)
            },
            occupationRole: {
              id: Number(values.cargo)
            },
            companyInformation: {
              cnpj: values.comercialCnpj,
              companyName: values.comercialNome,
              admissionDate: values.comercialAdmissao,
              comercialPhone: values.comercialTelefone,
              phoneExtension: values.comercialTelefoneRamal,
              address: (
                isRequiredEnderecoComercial
                  ? {
                    zipCode: values.comercialCep,
                    street: values.comercialEndereco,
                    number: String(values.comercialNumero),
                    complement: values.comercialComplemento,
                    neighborhood: values.comercialBairro,
                    addressType: {
                      id: 2,
                      description: 'COMERCIAL'
                    },
                    city: {
                      id: Number(values.comercialCidadeId),
                    },
                    state: {
                      id: Number(values.comercialEstado),
                    }
                  }
                  : null
              ),
            },
          },
          personalInformation: {
            nationality: {
              id: Number(values.nacionalidadeId)
            },
            maritalStatus: {
              id: Number(values.estadoCivil)
            },
            scholarity: {
              id: Number(values.escolaridade)
            },
            hometown: {
              id: Number(values.cidadeNaturalidadeId)
            },
            homestate: {
              id: Number(values.estadoNaturalidade)
            },
            sex: values.sexo,
            mothersName: values.nomeMae,
            cpf: values.cpf
          },
          financialInformation: {
            monthlyIncome: formatCurrencyToServer(values.rendaMensal),
            patrimony: {
              id: Number(values.patrimonio)
            }
          },
          identificationDocument: {
            documentNumber: values.documento,
            documentEmissionDate: values.dataEmissao,
            identificationDocumentType: {
              id: Number(values.tipoDocumento)
            },
            documentEmmiterInstitution: {
              id: Number(values.orgaoEmissor)
            },
            uf: {
              id: Number(values.estadoOrgaoEmissor)
            }
          },
          spouse: {
            fullName: values.conjugeNome,
            cpf: values.conjugeCpf,
            comercialPhone: values.conjugeComercialTelefone,
            phoneExtension: Number(values.conjugeComercialTelefoneRamal),
            address: (
              isRequiredEnderecoConjuge
                ? {
                  zipCode: values.conjugeComercialCep,
                  street: values.conjugeComercialEndereco,
                  number: String(values.conjugeComercialNumero),
                  complement: values.conjugeComercialComplemento,
                  neighborhood: values.conjugeComercialBairro,
                  city: {
                    id: Number(values.conjugeComercialCidadeId),
                  },
                  state: {
                    id: Number(values.conjugeComercialEstado),
                  }
                }
                : null
            ),
            financialInformation: {
              monthlyIncome: formatCurrencyToServer(values.conjugeRendaMensal)
            },
            occupationInformation: {
              professionNature: {
                id: Number(values.conjugeOcupacao)
              },
              profession: {
                id: Number(values.conjugeProfissao)
              },
              occupationRole: {
                id: Number(values.conjugeCargo)
              },
              companyInformation: {
                cnpj: values.conjugeComercialCnpj,
                companyName: values.conjugeComercialNome,
                admissionDate: values.conjugeComercialAdmissao
              }
            },
          }
        }
      }
  
      return dados
  }
import * as Yup from 'yup'
import { cnpj as cnpjValidation } from 'cpf-cnpj-validator'

export const initialValues: TCondominioResponse = {
  name: '',
  cnpj: '',
  isActive: true,
  accounts: [],
  powerPlants: [],
  allocatedClients: []
}

export const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  plantId: Yup.number(),
  cnpj: Yup.string()
    .required('CNPJ é obrigatório')
    .test(
      'is-incorrect',
      'CNPJ não está válido',
      (value?: string) => (
        Boolean(cnpjValidation.isValid(String(value)))
      )
    ),
})
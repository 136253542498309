import styled from 'styled-components'
import { Table } from 'react-bootstrap'
import { FlexContainer } from 'components'

type TStatusProgressBarProps = {
  completion: number
  color: string
}

export const StatusProgressBar = styled.div<TStatusProgressBarProps>`
  left: 0;
  bottom: 0;
  width: ${p => `${p.completion ?? 0}%`};
  height: 3px;
  background-color: ${p => p.color};
`

export const Container = styled.div`
  height: 80vh;
`

export const CustomTable = styled(Table)`
  td {
    vertical-align: middle;
  }

  span {
    width: max-content;
    display: inline;
  }

`

export const CustomFlexContainer = styled(FlexContainer)`
  flex-wrap: nowrap;
  width: fit-content;

  span {
    padding-right: 10px;
  }
`

export const FlexStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FlexButtonContainer = styled(FlexContainer)`
  flex-wrap: nowrap;
  width: max-content;
`

export const MaxContentRow = styled.div`
  width: max-content;
`
import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const QUERY_AUDIT_TABLES = gql`
    query findAllAuditTables{
        findAllAuditTables{
            ${commonResponse}
            auditTablesSuccess{
                id
                tableName
            }
        }
    }
`

export const QUERY_LOGS = gql`
    query findAuditByFilter(
        $auditTableId : Int!,
        $paginate: Paginate,
        $filter: AuditFilter
    ) {
        findAuditByFilter (
            auditTableId: $auditTableId,
            paginate: $paginate,
            filter: $filter
        ) {
            ${commonResponse}
            paginationInfo{
                numberOfRecordsByPage
                pageNumber
                totalRecords
                totalPages
            }
            auditSuccess{
                id
                date
                userName
                action
                changeDetails
            }
        }
}
`
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams, useLocation, Redirect } from 'react-router-dom'
import { useFormik } from 'formik'

import { useToasts } from 'react-toast-notifications'
import { Form, Row, Col } from 'react-bootstrap'

import {
  GoBackButton,
  PageHeader,
  ButtonsContainer,
  FormSubTitle,
  ConfirmButton,
  TextField,
  SelectField
} from 'components'

import {
  useGetFuncionalidades,
  useGetProfile,
  usePostProfile,
  useUpdateProfile
} from 'repositories/perfis'

import {
  initialValues,
  schema
} from './validations'
import Permissoes from './Components/Permissoes'

import { confirmationMessage, statusOptions, Variant } from 'utils/constants'
import { useAuth } from 'hooks/useAuth'

const toastCreatedMessage = 'Perfil adicionado com sucesso!'
const toastUpdatedMessage = 'Perfil editado com sucesso!'
const toastEmptyPermissoesMessage = 'Necessário selecionar pelo menos uma permissão para o perfil'
const messagePerfilEmUso = 'Esse perfil está associado a um ou mais usuários e todos usuários serão afetados com esta mudança. Deseja prosseguir?'

const PerfisFormulario = () => {
  const [funcionalidades, setFuncionalidades] = useState<TPermissionList[]>([])

  const history = useHistory()
  const { addToast } = useToasts()
  const { id } = useParams<{ id: string }>()
  const getProfile = useGetProfile()
  const getFuncionalidades = useGetFuncionalidades()
  const postProfile = usePostProfile()
  const updateProfile = useUpdateProfile()
  const location = useLocation()
  const { userData, userPermissions, hasFormPermission } = useAuth()

  const { permissions, permissionForm } = useMemo(() => {
    const permission = userPermissions?.perfis
    return {
      permissions: permission,
      permissionForm: hasFormPermission(permission!, location.pathname)
    }
  }, [userPermissions])

  useEffect(() => {
    if (!getFuncionalidades.data) return
    const { data } = getFuncionalidades
    const requestFuncionalidades = () => {
      const formatFuncionalidades = (
        funcionalidadesResult: TPermissionList[],
        selectedPermissions?: TPermissionList
      ): TPermissionList[] => {
        return funcionalidadesResult.map(funcionalidades => {
          const permissions = funcionalidades.permissions.map(permission => {
            const isChecked = selectedPermissions?.permissions.some(
              checkedPermission => permission.id === checkedPermission.id
            )
            return {
              ...permission,
              checked: Boolean(isChecked)
            }
          })
          return {
            ...funcionalidades,
            permissions
          }
        })
      }
      const _funcionalidades = formatFuncionalidades(
        data.listFunctionalitiesResult,
        getProfile.data
      )
      if (getProfile.data) formik.setValues(getProfile.data)
      setFuncionalidades(_funcionalidades)
    }
    requestFuncionalidades()
  }, [getFuncionalidades.data, getProfile.data])

  useEffect(() => {
    if (id) getProfile.get(id)
  }, [id])

  const onSubmit = () => {
    const filteredCheckedFuncionalidades = funcionalidades.filter(funcionalidade => (
      funcionalidade.permissions.some(permission => permission.checked)
    ))

    if (!filteredCheckedFuncionalidades.length) {
      addToast(toastEmptyPermissoesMessage, {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    const _funcionalidades = filteredCheckedFuncionalidades.map(funcionalidade => {
      const filteredPermissions = funcionalidade.permissions.filter(permission => (
        permission.checked
      ))
      const _permissions = filteredPermissions.map(
        permission => ({ id: permission.id })
      )
      return {
        functionality: funcionalidade.id,
        permissions: _permissions
      }
    })
    const data = {
      name: formik.values.name,
      status: formik.values.status,
      functionalities: _funcionalidades
    }

    if (id) {
      updateProfile.update({ id, ...data })
      return
    }

    postProfile.save(data)
  }

  const redirectToList = (successMessage: string) => {
    addToast(successMessage, {
      appearance: 'success',
      autoDismiss: true,
    })
    history.push('/perfis')
  }

  useEffect(() => {
    if (!postProfile.response || postProfile.response.isError) return
    redirectToList(toastCreatedMessage)
  }, [postProfile.response])

  useEffect(() => {
    if (!updateProfile.response || updateProfile.response.isError) return
    redirectToList(toastUpdatedMessage)
  }, [updateProfile.response])

  const formik = useFormik<TPerfisRequest>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })

  const updatePermissions = (
    funcionalidadeId: number,
    permissions: TPermission[]
  ) => {
    const _funcionalidades = funcionalidades.map(funcionalidade => (
      funcionalidade.id === funcionalidadeId ? { ...funcionalidade, permissions } : funcionalidade
    ))
    setFuncionalidades(_funcionalidades)
  }

  const updateAllPermissionsByFuncionalidade = (funcionalidadeId: number, checked: boolean) => {
    const _funcionalidades = funcionalidades.map(funcionalidade => {
      if (funcionalidade.id === funcionalidadeId) {
        const permissions = funcionalidade.permissions.map(
          permission => ({ ...permission, checked })
        )
        return { ...funcionalidade, permissions }
      }

      return funcionalidade
    })
    setFuncionalidades(_funcionalidades)
  }

  const onClickGoToPage = (page: string) => () => history.push(page)

  const hasError = (name: keyof TPerfisRequest) => (
    formik.touched[name] && formik.errors[name]
  )

  const getCommonFieldProps = (name: keyof TPerfisRequest) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const { isVisualizando, headerTitle } = useMemo(() => {
    const url = location.pathname.split('/')[2]
    const isVisualizando = url === 'visualizar'
    return {
      isVisualizando,
      headerTitle: (
        isVisualizando ? 'Visualizar' : (id ? 'Editar' : 'Adicionar')
      )
    }
  }, [location.pathname, id])

  if (userData && !permissionForm) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={!isVisualizando}
            route='/perfis'
            message={confirmationMessage}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <PageHeader title={`${headerTitle} Perfil de Acesso`} />
        </Col>
      </Row>
      <Form>
        <Form.Row>
          <Col md={8}>
            <TextField
              label='Nome'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('name')}
            />
          </Col>
          <Col md={4}>
            <SelectField
              required
              label='Status'
              options={statusOptions}
              disabled={isVisualizando || !permissions?.inactivate}
              {...formik.getFieldProps('status')}
              isInvalid={Boolean(hasError('status'))}
              errorMessage={formik.errors.status}
            />
          </Col>
        </Form.Row>
        <Row>
          <Col>
            <FormSubTitle>Permissões</FormSubTitle>
          </Col>
        </Row>
        {funcionalidades.map((items, index) => (
          <Form.Row key={index}>
            <Col>
              <Permissoes
                data={items}
                updatePermissions={updatePermissions}
                updateAllPermissionsByFuncionalidade={updateAllPermissionsByFuncionalidade}
                disabled={isVisualizando}
              />
            </Col>
          </Form.Row>
        ))}

        <ButtonsContainer>
          <ConfirmButton
            active={!isVisualizando}
            actionFn={onClickGoToPage('/perfis')}
            message={confirmationMessage}
            variant={Variant.SECONDARY}
          >
            <span>{isVisualizando ? 'Voltar' : 'Cancelar'}</span>
          </ConfirmButton>
          {!isVisualizando && (
            <ConfirmButton
              active={Boolean(getProfile.perfilJaUtilizado)}
              actionFn={formik.handleSubmit}
              message={messagePerfilEmUso}
              variant={Variant.PRIMARY}
              isLoading={postProfile.loading || updateProfile.loading}
            >
              Salvar
            </ConfirmButton>
          )}
        </ButtonsContainer>
      </Form>
    </>
  )
}

export default PerfisFormulario
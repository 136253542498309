import * as Yup from 'yup'

export const initialValues: TAccount = {
  bank: '',
  account: '',
  accountDigit: '',
  agency: '',
  agencyDigit: '',
  isMainAccount: ''
}

export const schema = Yup.object().shape({
  bank: Yup.string().required('Banco é obrigatório'),
  account: Yup.number()
    .positive('Informe um número maior do que 0')
    .required('Número da agência é obrigatória'),
  accountDigit: Yup
    .string()
    .required('Digito da agência é obrigatória'),
  agency: Yup
    .number()
    .positive('Informe um número maior do que 0')
    .required('Número da conta é obrigatória'),
  agencyDigit: Yup
    .string()
    .required('Digito da conta é obrigatória'),
  isMainAccount: Yup.boolean()
    .required('Informe se a conta é principal ou não')
})
import React, { useEffect, useState } from 'react'
import useProjetos from 'repositories/useProjetos'
import { formatDateTimeToView } from 'utils/helpers'
import {
  Container,
  TimelineContainer,
  Entry,
  Body,
  Date
} from './styles'
import { Loader } from 'components'
import {
  Box,
  BoxTitle
} from '../../styles'

type TProps = {
  id: string
  percentageStatus: number
}

const percentageStatusDocumentos = 42.87

const Timeline = (props: TProps) => {
  const { id, percentageStatus } = props
  const [timelineEntries, setTimelineEntries] = useState<TProjetoTimelineRequest[]>([])
  const repository = useProjetos()

  useEffect(() => {

    const requestTimeline = async () => {
      const timeline = await repository.getTimeline(id)
      if (!timeline) return

      setTimelineEntries(timeline)
    }

    if (percentageStatus >= percentageStatusDocumentos) requestTimeline()
  }, [id])

  return (
    <Container>
      <Box height={424}>
        <BoxTitle>
          <h3>Linha do tempo</h3>
        </BoxTitle>
        {repository.isLoading ? (
          <Loader />
        ) : (
          <>
            {timelineEntries.length ? (
              <TimelineContainer>
                {timelineEntries.map(timelineEntry => {
                  const formattedDataEvento = formatDateTimeToView(timelineEntry.dataEvento)

                  return (
                    <Entry key={timelineEntry.id}>
                      <Date>
                        <p>{formattedDataEvento}</p>
                      </Date>
                      <Body>
                        <p>{timelineEntry.descricao}</p>
                      </Body>
                    </Entry>
                  )
                })}
              </TimelineContainer>
            ) : (
              <span>Esse projeto ainda não possui uma proposta!</span>
            )}
          </>
        )}
      </Box>
    </Container>
  )
}

export default Timeline
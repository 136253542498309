import React from 'react'
import { Form } from 'react-bootstrap'
import { CustomFormLabel } from './styles'

type TOption = {
  label: string
  value: number | string
}

type TProps = {
  required?: boolean
  name: string
  label?: string
  options: TOption[]
  value: number | string
  description?: string
  isInvalid: boolean
  errorMessage?: string
  onChange(field: any, value: string | number): void
  disabled?: boolean
}

const RadioField = (props: TProps) => {
  const {
    name,
    required,
    label,
    errorMessage,
    description,
    options,
    value,
    onChange,
    disabled = false,
    ...rest
  } = props

  const onChangeRadio = (value: string | number) => () => {
    onChange(name, value)
  }

  return (
    <Form.Group>
      <CustomFormLabel required={required}>
        {label}
      </CustomFormLabel>

      {options.map(option => {
        const isChecked = value === option.value
        return (
          <Form.Check
            key={option.value}
            inline
            label={option.label}
            type='radio'
            checked={isChecked}
            onChange={onChangeRadio(option.value)}
            disabled={disabled}
            {...rest}
          />
        )
      })}

      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>

      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default RadioField

import styled from 'styled-components'
import { Form } from 'react-bootstrap'

export const ButtonsGroup = styled(Form.Row)`
  display: flex;
  align-item: baseline;
  gap: 10px;
  margin-top: 7px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
    padding: 0px 5px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    margin-top: 0px;
  }
`
import { cnpj as cnpjValidation } from 'cpf-cnpj-validator'
import { removeMaskGuides } from 'utils/helpers'
import * as Yup from 'yup'

export const initialValues: TNotaFiscalForm = {
  nfeId: '',
  enviarDuasNotas: '',
  nfeDataHoraEnvio: '',
  nfeStatusEnvio: '',
  nfeNomeArquivo: '',
  nfeConteudo: '',
  nfeDanfe: '',
  nfeChaveDeAcesso: '',
  nfeCnpj: '',
  nfeAnoFundacao: '',
  nfeRazaoSocial: '',
  nfeNomeFantasia: '',
  nfeValor: '',
  nfeTipo: 'NFE',
  nfeBanco: '',
  nfeBancoId: '',
  nfeAgencia: '',
  nfeContaBancaria: '',
  nfseId: '',
  nfseDataHoraEnvio: '',
  nfseStatusEnvio: '',
  nfseNomeArquivo: '',
  nfseConteudo: '',
  nfseDanfe: '',
  nfseChaveDeAcesso: '',
  nfseCnpj: '',
  nfseAnoFundacao: '',
  nfseRazaoSocial: '',
  nfseNomeFantasia: '',
  nfseValor: '',
  nfseTipo: 'NFES',
  nfseBanco: '',
  nfseBancoId: '',
  nfseAgencia: '',
  nfseContaBancaria: '',
  nfseNumero: '',
  nfse2Id: '',
  nfse2DataHoraEnvio: '',
  nfse2StatusEnvio: '',
  nfse2NomeArquivo: '',
  nfse2Conteudo: '',
  nfse2Danfe: '',
  nfse2ChaveDeAcesso: '',
  nfse2Cnpj: '',
  nfse2AnoFundacao: '',
  nfse2RazaoSocial: '',
  nfse2NomeFantasia: '',
  nfse2Valor: '',
  nfse2Tipo: 'NFE',
  nfse2Banco: '',
  nfse2BancoId: '',
  nfse2Agencia: '',
  nfse2ContaBancaria: '',
  nfse2Numero: '',
  nfse3Id: '',
  nfse3DataHoraEnvio: '',
  nfse3StatusEnvio: '',
  nfse3NomeArquivo: '',
  nfse3Conteudo: '',
  nfse3Danfe: '',
  nfse3ChaveDeAcesso: '',
  nfse3Cnpj: '',
  nfse3AnoFundacao: '',
  nfse3RazaoSocial: '',
  nfse3NomeFantasia: '',
  nfse3Valor: '',
  nfse3Tipo: 'NFE',
  nfse3Banco: '',
  nfse3BancoId: '',
  nfse3Agencia: '',
  nfse3ContaBancaria: '',
  nfse3Numero: '',
}

const anoAtual = new Date().getFullYear()

export const schema = Yup.object().shape({
  enviarDuasNotas: Yup.boolean(),
  nfeNomeArquivo: Yup.string().required('Nota Fiscal é obrigatório'),
  nfeDanfe: Yup.string().required('Danfe é obrigatório'),
  nfeChaveDeAcesso: Yup
    .string()
    .test(
      '',
      'Insira uma chave de acesso válida',
      value => {
        const zeroedChaveAcesso = '00000000000000000000000000000000000000000000'
        const isChaveAcessoZeroed = value === zeroedChaveAcesso

        const cleanChaveAcesso = removeMaskGuides(String(value))
        const isChaveAcessoIncomplete = cleanChaveAcesso.length < 44

        return (!isChaveAcessoIncomplete && !isChaveAcessoZeroed)
      }
    )
    .required('Chave de Acesso é obrigatório'),
  nfeCnpj: Yup
    .string()
    .required('CNPJ é obrigatório')
    .test(
      '',
      'CNPJ não está válido',
      value => (cnpjValidation.isValid(value!))
    ),
  nfeAnoFundacao: Yup
    .number()
    .max(anoAtual, 'Ano de Fundação não pode ser posterior ao atual')
    .required('Ano é obrigatório'),
  nfeRazaoSocial: Yup.string().required('Razão Social é obrigatório'),
  nfeValor: Yup
    .string()
    .required('Valor é obrigatório')
    .test(
      '',
      'Valor não pode ser 0,00',
      value => (parseFloat(value!) !== 0)
    ),
  nfeBanco: Yup.string().required('Banco é obrigatório'),
  nfeAgencia: Yup
    .number()
    .min(1, 'Agência não pode ser menor que 1')
    .max(9999, 'Agência não pode ser maior que 9999')
    .required('Agência é obrigatório'),
  nfeContaBancaria: Yup
    .number()
    .min(1, 'Conta Bancaria não pode ser menor que 1')
    .required('Conta Bancaria é obrigatório'),
  nfseNomeArquivo: Yup
    .string()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup.string().required('Nota Fiscal é obrigatório')
    }),
  nfseDanfe: Yup
    .string()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup.string().required('Danfe é obrigatório')
    }),
  nfseChaveDeAcesso: Yup
    .string()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup.string().required('Chave de acesso é obrigatório')
    }),
  nfseCnpj: Yup
    .string()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup
        .string()
        .required('CNPJ é obrigatório')
        .test(
          '',
          'CNPJ não está válido',
          value => (cnpjValidation.isValid(value!))
        )
    }),
  nfseAnoFundacao: Yup
    .number()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup
        .number()
        .max(anoAtual, 'Ano de Fundação não pode ser posterior ao atual')
        .required('Ano de Fundação é obrigatório')
    }),
  nfseRazaoSocial: Yup
    .string()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup.string().required('Razão Social é obrigatório')
    }),
  nfseValor: Yup
    .string()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup
        .string()
        .required('Valor é obrigatório')
        .test(
          '',
          'Valor não pode ser 0,00',
          value => (parseFloat(value!) !== 0)
        )
    }),
  nfseBanco: Yup
    .string()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup
        .string()
        .required('Banco é obrigatório')
    }),
  nfseAgencia: Yup
    .number()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup
        .number()
        .min(1, 'Agência não pode ser menor que 1')
        .max(9999, 'Agência não pode ser maior que 9999')
        .required('Agência é obrigatório')
    })
  ,
  nfseContaBancaria: Yup
    .number()
    .when('enviarDuasNotas', {
      is: true,
      then: Yup
        .number()
        .min(1, 'Conta Bancaria não pode ser menor que 1')
        .required('Conta Bancaria é obrigatório'),
    })
})
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { v4 as genId } from 'uuid'
import { BiErrorCircle } from 'react-icons/bi'
import { MdDashboard, MdHome, MdAttachMoney } from 'react-icons/md'
import { IoGitNetworkSharp } from 'react-icons/io5'
import { HiDocumentReport } from 'react-icons/hi'
import { BsBuilding, BsFillFileEarmarkTextFill } from 'react-icons/bs'
import {
  FaIndustry,
  FaUserCircle,
  FaUserTie,
  FaProjectDiagram,
  FaUserFriends,
  FaMapMarkedAlt,
  FaBarcode
} from 'react-icons/fa'
import {
  RiFolderKeyholeLine,
  RiEqualizerLine
} from 'react-icons/ri'
import { AiOutlinePrinter } from 'react-icons/ai'
import { Container, Logo, Menu, InnerContainer } from './styles'
import Profile from './Profile'
import MenuItem from './MenuItem'

import { useAuth } from 'hooks/useAuth'

export enum PermissionsName {
  PROJETOS = 'PROJECT',
  RELATORIOS_PROJETOS = 'REPORT',
  INTEGRADORES = 'INTEGRATORS',
  CLIENTES = 'CLIENT',
  CONDOMINIOS = 'CONDOMINIUM',
  USINAS = 'FACTORY',
  DISTRIBUIDORAS = 'POWER_COMPANY',
  FATURAS = 'BILLING',
  USUARIOS = 'USERS',
  PERFIS = 'PROFILES',
  PARAMETROS = 'PARAMETRIZATION',
  MEUS_DADOS = 'MY_DATA',
  ALIQUOTA = 'ALIQUOT',
  CONTRATOS = 'CONTRACT',
  AUDITORIA = 'AUDIT',
  PIPEFY = 'PIPEFY_ERROR',
  CEMIG= 'QUEUE_SEARCH_ENERGY_BILL',
  ERROS_FATURAMENTO= 'GENERATE_INVOICE_LOG',
  EMPRESA = 'COMPANY'
}

const accessFreeMenuItems = [
  {
    path: '/',
    icon: <MdHome />,
    title: 'Início',
    isActive: false,
  },
  {
    path: '/dashboard',
    icon: <MdDashboard />,
    title: 'Dashboard',
    isActive: false,
  },
]

const accessControledMenuItems = [
  {
    path: '/projetos',
    icon: <FaProjectDiagram />,
    title: 'Financiamentos',
    isActive: false,
    permissionName: PermissionsName.PROJETOS
  },
  {
    path: '/relatorios-projetos',
    icon: <AiOutlinePrinter />,
    title: 'Relatórios',
    isActive: false,
    permissionName: PermissionsName.RELATORIOS_PROJETOS
  },
  {
    path: '/integradores',
    icon: <FaUserFriends />,
    title: 'Integradores',
    isActive: false,
    permissionName: PermissionsName.INTEGRADORES
  },
  {
    path: '/clientes',
    icon: <FaUserTie />,
    title: 'Clientes',
    isActive: false,
    permissionName: PermissionsName.CLIENTES
  },
  {
    path: '/contratos',
    icon: <BsFillFileEarmarkTextFill />,
    title: 'Contratos',
    isActive: false,
    permissionName: PermissionsName.CONTRATOS
  },
  {
    path: '/faturamento',
    icon: <FaBarcode />,
    title: 'Faturamento',
    isActive: false,
    permissionName: PermissionsName.FATURAS
  },
  {
    path: '/condominios',
    icon: <FaMapMarkedAlt />,
    title: 'Condomínios',
    isActive: false,
    permissionName: PermissionsName.CONDOMINIOS
  },
  {
    path: '/usinas',
    icon: <FaIndustry />,
    title: 'Usinas',
    isActive: false,
    permissionName: PermissionsName.USINAS
  },
  {
    path: '/usuarios',
    icon: <FaUserCircle />,
    title: 'Usuários',
    isActive: false,
    permissionName: PermissionsName.USUARIOS
  },
  {
    path: '/perfis',
    icon: <RiFolderKeyholeLine />,
    title: 'Perfis de Acesso',
    isActive: false,
    permissionName: PermissionsName.PERFIS
  },
  {
    path: '/empresa',
    icon: <BsBuilding />,
    title: 'Empresa',
    isActive: false,
    permissionName: PermissionsName.EMPRESA
  },
  {
    path: '/parametros',
    icon: <RiEqualizerLine />,
    title: 'Parâmetros',
    isActive: false,
    permissionName: PermissionsName.PARAMETROS
  },
  {
    path: '/aliquotas',
    icon: <MdAttachMoney />,
    title: 'Alíquotas',
    isActive: false,
    permissionName: PermissionsName.ALIQUOTA
  },
  {
    path: '/pipefy/listar-erros',
    icon: <BiErrorCircle />,
    title: 'Erros Pipefy',
    isActive: false,
    permissionName: PermissionsName.PIPEFY
  },
  {
    path: '/erros-cemig',
    icon: <BiErrorCircle />,
    title: 'Erros CEMIG',
    isActive: false,
    permissionName: PermissionsName.CEMIG
  },
  {
    path: '/erros-faturamento',
    icon: <BiErrorCircle />,
    title: 'Erros Faturamento',
    isActive: false,
    permissionName: PermissionsName.ERROS_FATURAMENTO
  },
  {
    path: '/auditoria',
    icon: <HiDocumentReport />,
    title: 'Auditoria',
    isActive: false,
    permissionName: PermissionsName.AUDITORIA
  },
]

type TProps = {
  isOpen: boolean
  closeSidebar(): void
}

const Sidebar = (props: TProps) => {
  const { isOpen, closeSidebar } = props
  const { pathname } = useLocation()
  const { userData } = useAuth()

  const userAllowedMenuItems = useMemo(() => {
    const filteredMenuItems = accessControledMenuItems.filter(item => (
      userData?.permissions?.some(permission => (
        permission === `${item.permissionName}_VIEW`
      ))
    ))
    return [
      ...filteredMenuItems
    ]
  }, [userData])

  const mainPath = `/${pathname.split('/')[1]}`

  const updatedMenuItems = useMemo(() => (
    userAllowedMenuItems.map(item => (
      mainPath === item.path
        ? { ...item, isActive: true }
        : item
    ))
  ), [mainPath])

  return (
    <Container isOpen={isOpen}>
      <InnerContainer>
        <Logo>Copérnico</Logo>
        <Profile closeSidebar={closeSidebar} />
        <Menu>
          {updatedMenuItems.map(menuItem => {
            const key = genId()
            return (
              <MenuItem
                key={key}
                icon={menuItem.icon}
                route={menuItem.path}
                title={menuItem.title}
                isActive={menuItem.isActive}
                closeSidebar={closeSidebar}
              />
            )
          })}
        </Menu>
      </InnerContainer>
    </Container>
  )
}

export default Sidebar

import { useState, useEffect } from 'react'
import { useMutation, useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'
import {
  MUTATION_DISTRIBUIDORA,
  QUERY_DISTRIBUIDORA,
  QUERY_DISTRIBUIDORAS,
  QUERY_DISTRIBUIDORAS_SELECT
} from './distribuidorasGqls'

type TDistribuidorasResponse = {
  findPowerCompanyByFilter: TComonResponse & TPaginationInfo & {
    powerCompanySuccess: TDistribuidoraInfo[]
  }
}

export const useGetDistribuidoras = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [
    requestDistribuidoras,
    { loading, data }
  ] = useLazyQuery<TDistribuidorasResponse>(
    QUERY_DISTRIBUIDORAS, {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error)
    }
  )

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findPowerCompanyByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TListaDistribuidorasParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestDistribuidoras({
      variables: params
    })
  }

  return {
    isLoading: loading && isLoading,
    distribuidoras: data?.findPowerCompanyByFilter.powerCompanySuccess,
    paginationInfo: data?.findPowerCompanyByFilter.paginationInfo,
    get
  }
}

type TDistribuidorasSelectResponse = {
  listPowerCompanies: TComonResponse & {
    powerCompanySuccess: TDistribuidoraInfo[]
  }
}

export const useGetDistribuidorasSelect = () => {
  const errorHandler = useErrorHandler()

  const [
    requestDistribuidoras,
    { data }
  ] = useLazyQuery<TDistribuidorasSelectResponse>(
    QUERY_DISTRIBUIDORAS_SELECT, {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error)
    }
  )

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.listPowerCompanies.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TListaDistribuidorasParams) => {
    requestDistribuidoras({
      variables: params
    })
  }

  return {
    distribuidoras: data?.listPowerCompanies.powerCompanySuccess,
    get
  }
}

type TCreateUpdateDistribuidoraResponse = {
  registerOrUpdatePowerCompany: TComonResponse
}

export const useCreateUpdateDistribuidora = () => {
  const errorHandler = useErrorHandler()

  const [
    createUpdateDistribuidora,
    { data, loading }
  ] = useMutation<TCreateUpdateDistribuidoraResponse>(MUTATION_DISTRIBUIDORA, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.registerOrUpdatePowerCompany.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (distribuidora: TDistribuidoraInfo) => {
    const _distribuidora = {
      ...distribuidora,
      address: {
        ...distribuidora.address,
        __typename: undefined
      },
      __typename: undefined
    }

    createUpdateDistribuidora({
      variables: {
        registerPowerCompanyCommand: _distribuidora
      }
    })
  }

  return {
    send,
    data,
    isError: data?.registerOrUpdatePowerCompany.commonResponse.isError,
    isLoading: loading
  }
}

type TDistribuidoraResponse = {
  findPowerCompanyById: TComonResponse & {
    powerCompanySuccess: TDistribuidoraInfo
  }
}

export const useGetDistribuidora = () => {
  const errorHandler = useErrorHandler()

  const [requestDistribuidora, { data }] = useLazyQuery<TDistribuidoraResponse>(
    QUERY_DISTRIBUIDORA, {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error)
    }
  )

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.findPowerCompanyById.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: string) => {
    requestDistribuidora({
      variables: { id }
    })
  }

  return {
    distribuidora: data?.findPowerCompanyById.powerCompanySuccess,
    get
  }
}
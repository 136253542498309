import React, { useMemo } from 'react'
import {
  Card,
  Row,
  Col,
  Form
} from 'react-bootstrap'

import { Container } from './styles'

type TProps = {
  data: TPermissionList,
  updatePermissions(id: number, permissions: TPermission[]): void,
  updateAllPermissionsByFuncionalidade(
    funcionalidadeId: number,
    checked: boolean
  ): void,
  disabled: boolean
}

const Permissoes = (props: TProps) => {
  const {
    data,
    updatePermissions,
    updateAllPermissionsByFuncionalidade,
    disabled
  } = props
  const onChangeCheckAllPermission = () => {
    updateAllPermissionsByFuncionalidade(data.id, !isAllPermissionsChecked)
  }

  const onChangeTogglePermission = (index: number) => () => {
    const permissions = data.permissions.map((permission, _index) => (
      index === _index ? { ...permission, checked: !permission.checked } : permission
    ))
    updatePermissions(data.id, permissions)
  }

  const isAllPermissionsChecked = useMemo(() => (
    !data.permissions.some(permission => !permission.checked)
  ), [data.permissions])

  return (
    <Container>
      <Card.Header>
        <Form.Check
          name='funcionality'
          label={data.name}
          checked={isAllPermissionsChecked}
          onChange={onChangeCheckAllPermission}
          disabled={disabled}
        />
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <Row>
            {data.permissions.map((permission, index) => (
              <Col
                key={permission.id}
                md={6}
              >
                <Form.Check
                  name='permissions'
                  label={permission.permissionType.name}
                  checked={permission.checked}
                  onChange={onChangeTogglePermission(index)}
                  disabled={disabled}
                />
              </Col>
            ))}
          </Row>
        </Card.Text>
      </Card.Body>
    </Container>
  )
}

export default Permissoes
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams, useLocation, Redirect } from 'react-router-dom'
import { useFormik } from 'formik'

import { useToasts } from 'react-toast-notifications'
import { Row, Col, Form } from 'react-bootstrap'

import {
  GoBackButton,
  PageHeader,
  ButtonsContainer,
  PrimaryButton,
  ConfirmButton
} from 'components'

import {
  useGetProfiles,
  useGetUsuario,
  usePostUsuario,
  useUpdateUsuario
} from 'repositories/usuarios'

import {
  initialValues,
  schema
} from './validations'
import TextInput from 'components/Bootstrap/TextInput'
import MaskedInput from 'components/Bootstrap/MaskedInput'
import SelectInput from 'components/Bootstrap/SelectInput'
import {
  ActivationStatus,
  confirmationMessage,
  statusOptions as statusOptionsConstant,
  Variant
} from 'utils/constants'
import { useAuth } from 'hooks/useAuth'

const initialParamsProfile = {
  status: ActivationStatus.ACTIVATED,
  paginate: {
    numberOfRecordsByPage: 1000,
    pageNumber: 0,
  }
}

const toastCreatedMessage = 'Usuário adicionado com sucesso!'
const toastUpdatedMessage = 'Usuário editado com sucesso!'

type TStatusOptions = {
  label: string
  value: string
}

const awaitingStatus:TStatusOptions[] = [{
  label: 'Ativo',
  value: ActivationStatus.ACTIVATED
},
{
  label: 'Inativo',
  value: ActivationStatus.DEACTIVATED
},
{
  label: 'Aguardando Ativação',
  value: ActivationStatus.WAITING
},
]



const UsuariosFormulario = () => {
  const [statusOptions, setStatusOptions] = useState<TStatusOptions[]>(statusOptionsConstant)
  const [isWaitingStatus, setIsWaitingStatus] = useState<boolean>(false)
  const history = useHistory()
  const { addToast } = useToasts()
  const { id } = useParams<{ id: string }>()
  const location = useLocation<{ toView: boolean }>()
  const getUsuario = useGetUsuario()
  const getPerfis = useGetProfiles(initialParamsProfile)
  const postUsuario = usePostUsuario()
  const updateUsuario = useUpdateUsuario()
  const { userData, userPermissions, hasFormPermission } = useAuth()


  const { permissions, permissionForm } = useMemo(() => {
    const permission = userPermissions?.usuarios
    return {
      permissions: permission,
      permissionForm: hasFormPermission(permission!, location.pathname)
    }
  }, [userPermissions])

  const onSubmit = () => {
    const { values } = formik
    const user = {
      name: values.name,
      email: values.email,
      passwordHash: values.passwordHash,
      role: Number(values.role),
      telephone: values.telephone,
      status: values.status,
    }
    if (id) {
      updateUsuario.update({ id: Number(id), ...user })
      return
    }

    postUsuario.save(user)
  }

  const redirectToList = (successMessage: string) => {
    addToast(successMessage, {
      appearance: 'success',
      autoDismiss: true,
    })
    history.push('/usuarios')
  }

  useEffect(() => {
    if (!postUsuario.response || postUsuario.response.isError) return
    redirectToList(toastCreatedMessage)
  }, [postUsuario.response])

  useEffect(() => {
    if (!updateUsuario.response || updateUsuario.response.isError) return
    redirectToList(toastUpdatedMessage)
  }, [updateUsuario.response])

  const formik = useFormik<TUsuarioRequest>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })


  useEffect(() => {
    if (!id || !getUsuario.usuario) return
    const { usuario } = getUsuario
    const data = { ...usuario, role: usuario.role.id }
    if (!usuario) return
    formik.setValues(data)
    if(!(usuario.status === ActivationStatus.WAITING)) return
    setStatusOptions(awaitingStatus)
    setIsWaitingStatus(true)
  }, [id, getUsuario.usuario])

  useEffect(() => {
    if (id) getUsuario.get(id)
  }, [id])


  const onClickGoToPage = (page: string) => () => history.push(page)

  const hasError = (name: keyof TUsuarioRequest) => (
    formik.touched[name] && formik.errors[name]
  )

  const getErrorProps = (name: keyof TUsuarioRequest) => ({
    error: { isError: hasError(name), message: formik.errors[name] }
  })

  const { isVisualizando, headerTitle } = useMemo(() => {
    const url = location.pathname.split('/')[2]
    const isVisualizando = url === 'visualizar'
    return {
      isVisualizando,
      headerTitle: (
        isVisualizando ? 'Visualizar' : (id ? 'Editar' : 'Adicionar')
      )
    }
  }, [location.pathname, id])

  if (userData && !permissionForm) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={!isVisualizando}
            route='/usuarios'
            message={confirmationMessage}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <PageHeader title={`${headerTitle} Usuário`} />
        </Col>
      </Row>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Col md={6}>
            <TextInput
              required
              label='Nome'
              handleChange={formik.handleChange}
              disabled={isVisualizando}
              {...formik.getFieldProps('name')}
              {...getErrorProps('name')}
            />
          </Col>
          <Col md={3}>
            <SelectInput
              required
              label='Status'
              handleChange={formik.handleChange}
              items={statusOptions}
              disabled={isVisualizando || !permissions?.inactivate || isWaitingStatus}
              properties={{ labelId: 'value', labelName: 'label' }}
              {...formik.getFieldProps('status')}
              {...getErrorProps('status')}
            />
          </Col>
          <Col md={3}>
            <MaskedInput
              required
              label='Celular'
              mask='(99) 99999-9999'
              disabled={isVisualizando}
              handleChange={formik.handleChange}
              {...formik.getFieldProps('telephone')}
              {...getErrorProps('telephone')}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <TextInput
              required
              label='Email'
              handleChange={formik.handleChange}
              disabled={isVisualizando}
              {...formik.getFieldProps('email')}
              {...getErrorProps('email')}
            />
          </Col>
          <Col md={6}>
            <SelectInput
              required
              label='Perfil'
              handleChange={formik.handleChange}
              items={getPerfis.perfis}
              disabled={isVisualizando}
              properties={{ labelId: 'id', labelName: 'name' }}
              {...formik.getFieldProps('role')}
              {...getErrorProps('role')}
            />
          </Col>
        </Form.Row>
        {!id && (
          <Form.Row>
            <Col md={6}>
              <TextInput
                required={!formik.values.id}
                type='password'
                label='Senha'
                handleChange={formik.handleChange}
                disabled={isVisualizando}
                {...formik.getFieldProps('passwordHash')}
                {...getErrorProps('passwordHash')}
              />
            </Col>
            <Col md={6}>
              <TextInput
                required={!formik.values.id}
                type='password'
                label='Confirmação de Senha'
                handleChange={formik.handleChange}
                disabled={isVisualizando}
                {...formik.getFieldProps('passwordHashConfirmation')}
                {...getErrorProps('passwordHashConfirmation')}
              />
            </Col>
          </Form.Row>
        )}
        <ButtonsContainer>
          <ConfirmButton
            active={!isVisualizando}
            actionFn={onClickGoToPage('/usuarios')}
            message={confirmationMessage}
            variant={Variant.SECONDARY}
          >
            <span>{isVisualizando ? 'Voltar' : 'Cancelar'}</span>
          </ConfirmButton>
          {!isVisualizando && (
            <PrimaryButton
              type='submit'
              isLoading={postUsuario.loading || updateUsuario.loading}
            >
              <span>Salvar</span>
            </PrimaryButton>
          )}
        </ButtonsContainer>
      </Form>
    </>
  )
}

export default UsuariosFormulario

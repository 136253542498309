import React from 'react'
import { Form } from 'react-bootstrap'
import { FormLabel } from 'components'
import {
  AsyncTypeahead,
  TypeaheadModel
} from 'react-bootstrap-typeahead'

type TProps = {
  required?: boolean
  name: string
  label?: string
  value: string | undefined
  placeholder?: string
  emptyLabel?: string
  promptText?: string
  description?: string
  options: TypeaheadModel[]
  isInvalid?: boolean
  errorMessage?: string
  disabled?: boolean
  onSearch(term: string): void
  elementRef: any
  onChange(selected: TypeaheadModel[]): void
  onBlur(): void
}

const SearchField = (props: TProps) => {
  const {
    required,
    label,
    name,
    errorMessage,
    description,
    disabled = false,
    elementRef,
    isInvalid,
    value,
    emptyLabel = 'Nenhum item encontrado',
    promptText = 'Digite para pesquisar',
    ...rest
  } = props

  return (
    <Form.Group>
      <FormLabel required={required}>
        {label}
      </FormLabel>

      <AsyncTypeahead
        id={name}
        disabled={disabled}
        className={isInvalid ? 'is-invalid' : ''}
        delay={500}
        useCache={false}
        isLoading={false}
        ref={elementRef}
        selected={value ? [value] : []}
        emptyLabel={emptyLabel}
        promptText={promptText}
        inputProps={{
          className: isInvalid ? 'is-invalid' : '',
          autoComplete: 'disabled'
        }}
        {...rest}
      />

      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>

      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default SearchField

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useHistory, Redirect, useParams, useLocation } from 'react-router-dom'

import cepPromise from 'cep-promise'
import { useFormik } from 'formik'
import { Row, Col, Form } from 'react-bootstrap'
import { AsyncTypeahead, TypeaheadModel } from 'react-bootstrap-typeahead'
import { useToasts } from 'react-toast-notifications'

import { useAuth } from 'hooks/useAuth'
import useProjetos from 'repositories/useProjetos'
import useInformacoes from 'repositories/useInformacoes'
import useIntegradores from 'repositories/useIntegradores'
import {
  PageHeader,
  InfoText,
  FormTitle,
  FormSubTitle,
  FormDivider,
  ButtonsContainer,
  PrimaryButton,
  GoBackButton,
  ConfirmButton,
  TextField,
  SelectField,
  MaskedTextField,
  MaskedCurrencyField,
  SearchField
} from 'components'

import {
  formatCpfCnpj,
  formatResponseToOptions
} from 'utils/helpers'

import {
  tipoFinanciamentoOptions,
  finalidadeProjetoOptions,
  estadosApiSafraOptions,
  confirmationMessage,
  Variant
} from 'utils/constants'
import {
  initialValues,
  schema,
  estadoCivilCasado,
  ocupacaoAssalariado,
  nacionalidadeBrasileira
} from './validation'

import { dataOneGuarantor, dataTwoGuarantor, noGuarantors, populateForms } from './formdata'

enum CommonFieldTypes {
  SOCIO = 'socio',
  SOCIO_CONJUGE = 'socioConjuge',
  AVALISTA = 'avalista',
  AVALISTA_CONJUGE = 'avalistaConjuge',
  AVALISTA2 = 'avalista2',
  AVALISTA2_CONJUGE = 'avalista2Conjuge'
}

const toastCreateSucessMessage = 'Projeto adicionado com sucesso!'
const toastUpdateSucessMessage = 'Projeto editado com sucesso!'

const valuesOcupacaoAssalariado = ['0', '2', '4']

const tipoDocumentoRg = '1'

const formMaxLength = 30

const CreateProject = () => {
  const [escolaridadesOptions, setEscolaridadesOptions] = useState<TSelectOption[]>([])
  const [estadoCivilOptions, setEstadoCivilOptions] = useState<TSelectOption[]>([])
  const [nacionalidadesOptions, setNacionalidadesOptions] = useState<TSelectOption[]>([])
  const [tiposResidenciaOptions, setTiposResidenciaOptions] = useState<TSelectOption[]>([])
  const [ocupacoesOptions, setOcupacoesOptions] = useState<TSelectOption[]>([])
  const [profissoesOptions, setProfissoesOptions] = useState<TSelectOption[]>([])
  const [cargosOptions, setCargoOptions] = useState<TSelectOption[]>([])
  const [profissoesConjugeOptions, setProfissoesConjugeOptions] = useState<TSelectOption[]>([])
  const [cargosConjugeOptions, setCargoConjugeOptions] = useState<TSelectOption[]>([])
  const [patrimonioOptions, setPatrimonioOptions] = useState<TSelectOption[]>([])
  const [cidadeOptions, setCidadeOptions] = useState<TypeaheadModel[]>([])
  const [integradoresOptions, setIntegradoresOptions] = useState<TypeaheadModel[]>([])
  const [isReplicate, setIsReplicate] = useState<boolean>()

  const [profissoesSocioConjugeOptions, setProfissoesSocioConjugeOptions] = useState<TSelectOption[]>([])
  const [cargosSocioConjugeOptions, setCargosSocioConjugeOptions] = useState<TSelectOption[]>([])

  const [profissoesAvalistaConjugeOptions, setProfissoesAvalistaConjugeOptions] = useState<TSelectOption[]>([])
  const [cargosAvalistaConjugeOptions, setCargosAvalistaConjugeOptions] = useState<TSelectOption[]>([])

  const [profissoesAvalista2ConjugeOptions, setProfissoesAvalista2ConjugeOptions] = useState<TSelectOption[]>([])
  const [cargosAvalista2ConjugeOptions, setCargosAvalista2ConjugeOptions] = useState<TSelectOption[]>([])

  const [porteOptions, setPorteOptions] = useState<TSelectOption[]>([])
  const [ramoOptions, setRamoOptions] = useState<TSelectOption[]>([])
  const [atividadeOptions, setAtividadeOptions] = useState<TSelectOption[]>([])

  const [showFieldsDocumentos, setShowFieldsDocumentos] = useState(false)
  const [isDisabledIntegradorField, setIsDisabledIntegradorField] = useState(false)
  const [isEnderecamentoComercialDisabled, setIsDisabledEnderecamentoComercialField] = useState(false)
  const [isEnderecamentoConjugeDisabled, setIsDisabledEnderecamentoConjugeField] = useState(false)

  const [socioAdmConjugeObrigatorio, setSocioAdmConjugeObrigatorio] = useState(false)
  const [avalistaConjugeObrigatorio, setAvalistaConjugeObrigatorio] = useState(false)
  const [avalista2ConjugeObrigatorio, setAvalista2ConjugeObrigatorio] = useState(false)

  const [segundoAvalista, setSegundoAvalista] = useState(false)
  const [requiredAvalista, setRequiredAvalista] = useState(false)

  const [isRequiredConjuge1, setIsRequiredConjuge1] = useState(false)
  const [isRequiredConjuge2, setIsRequiredConjuge2] = useState(false)
  const [isRequiredConjuge3, setIsRequiredConjuge3] = useState(false)

  const [isRequiredEnderecoConjuge1, setIsRequiredEnderecoConjuge1] = useState(false)
  const [isRequiredEnderecoConjuge2, setIsRequiredEnderecoConjuge2] = useState(false)
  const [isRequiredEnderecoConjuge3, setIsRequiredEnderecoConjuge3] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { addToast } = useToasts()
  const history = useHistory()
  const location = useLocation()
  const repositoryProjetos = useProjetos()
  const repositoryInformacoes = useInformacoes()
  const { getIntegradoresByCompanyName } = useIntegradores()
  const cidadeProjetoRef = useRef<AsyncTypeahead<string>>(null)
  const cidadeRef = useRef<AsyncTypeahead<string>>(null)
  const cidadeNaturalidadeRef = useRef<AsyncTypeahead<string>>(null)
  const integradorRef = useRef<AsyncTypeahead<string>>(null)
  const conjugeCidadeRef = useRef<AsyncTypeahead<string>>(null)
  const comercialCidadeRef = useRef<AsyncTypeahead<string>>(null)
  const nacionalidadeRef = useRef<AsyncTypeahead<string>>(null)
  const socioAdmCidadeRef = useRef<AsyncTypeahead<string>>(null)
  const socioAdmConjugeCidadeRef = useRef<AsyncTypeahead<string>>(null)
  const avalistaCidadeRef = useRef<AsyncTypeahead<string>>(null)
  const avalistaConjugeCidadeRef = useRef<AsyncTypeahead<string>>(null)
  const avalista2CidadeRef = useRef<AsyncTypeahead<string>>(null)
  const avalista2ConjugeCidadeRef = useRef<AsyncTypeahead<string>>(null)
  const atividadeEconomicaRef = useRef<AsyncTypeahead<string>>(null)
  const { userData, userPermissions, hasFormPermission } = useAuth()

  const permission = useMemo(() => {
    const permission = userPermissions?.projetos
    return hasFormPermission(permission!, location.pathname)
  }, [userPermissions])

  useEffect(() => {
    const populateForm = (projeto: TProjetoRequestPJ) => {
      getAtividadesOptions(projeto.clientpj.activityBranch.idRamoAtividade.toString())
      const data = populateForms(projeto)
      formik.setValues(data)
    }

    const requestProjeto = async () => {
      const triggerLoading = false
      const projeto = await repositoryProjetos.getProjeto(id, triggerLoading)
      if (projeto.clientpj.guarantors.length > 1) {
        onChangeSegundoAvalista()
      }
      if (projeto) populateForm(projeto)
    }

    if (id) requestProjeto()
  }, [id])

  const onSubmit = async () => {
    const { values } = formik

    let dados

    if (segundoAvalista) {
      dados = dataTwoGuarantor(values, isRequiredConjuge1, isRequiredConjuge2, isRequiredConjuge3)
    } else if (values.avalistaNome === '') {
      dados = noGuarantors(values, isRequiredConjuge1)
    } else {
      dados = dataOneGuarantor(values, isRequiredConjuge1, isRequiredConjuge2)
    }

    if (segundoAvalista && values.avalistaNome === '' && values.avalista2Nome === '') {
      addToast('Preencha todos os campos para continuar', { appearance: 'error', autoDismiss: true })
      return
    }

    const _dados = {
      sourceProjectId: id ? parseInt(id) : null,
      ...dados
    }

    const response = (id && !isReplicate)
      ? await repositoryProjetos.putProjeto(id, dados)
      : await repositoryProjetos.postProjeto(_dados)
    if (!response) return

    history.push('/projetos')
    addToast(
      getToastMessage(),
      { appearance: 'success', autoDismiss: true }
    )
  }

  const formik = useFormik<TProjetoFormPJ>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })

  useEffect(() => {
    setIsReplicate(location.pathname.includes('replicar'))
  }, [location])

  useEffect(() => {
    if (formik.values.socioAdmEstadoCivil === estadoCivilCasado) {
      setSocioAdmConjugeObrigatorio(true)
    } else {
      setSocioAdmConjugeObrigatorio(false)
    }
    if (formik.values.avalistaEstadoCivil === estadoCivilCasado) {
      setAvalistaConjugeObrigatorio(true)
    } else {
      setAvalistaConjugeObrigatorio(false)
    }
    if (formik.values.avalista2EstadoCivil === estadoCivilCasado) {
      setAvalista2ConjugeObrigatorio(true)
    } else {
      setAvalista2ConjugeObrigatorio(false)
    }
  }, [formik.values.socioAdmEstadoCivil, formik.values.avalistaEstadoCivil, formik.values.avalista2EstadoCivil])

  useEffect(() => {
    const getInformacoes = async () => {
      const [
        tiposResidencia,
        escolaridade,
        estadoCivil,
        ocupacoes,
        patrimonio
      ] = await Promise.all([
        repositoryInformacoes.getTiposEndereco(),
        repositoryInformacoes.getEscolaridades(),
        repositoryInformacoes.getEstadosCivis(),
        repositoryInformacoes.getNaturezasProfissao(),
        repositoryInformacoes.getPatrimonios(),
      ])
      const _tiposResidencia = formatResponseToOptions(tiposResidencia)
      setTiposResidenciaOptions(_tiposResidencia)
      const _escolaridade = formatResponseToOptions(escolaridade)
      setEscolaridadesOptions(_escolaridade)
      const _estadoCivil = formatResponseToOptions(estadoCivil)
      setEstadoCivilOptions(_estadoCivil)
      const _ocupacoes = formatResponseToOptions(ocupacoes)
      setOcupacoesOptions(_ocupacoes)
      const _patrimonio = formatResponseToOptions(patrimonio)
      setPatrimonioOptions(_patrimonio)
    }
    getInformacoes()
    getParams()
    getPortesOptions()
    getRamoAtividadeOptions()
  }, [])

  const {
    isSocioAdmConjugeAssalariado,
    isAvalistaConjugeAssalariado,
    isAvalista2ConjugeAssalariado,
  }: any = useMemo(() => {
    const { socioAdmEstadoCivil, avalistaEstadoCivil, avalista2EstadoCivil } = formik.values
    const { socioAdmConjugeOcupacao, avalistaConjugeOcupacao, avalista2ConjugeOcupacao } = formik.values

    const isSocioAdmConjugeAssalariado = (
      socioAdmEstadoCivil === estadoCivilCasado &&
      ocupacaoAssalariado.indexOf(socioAdmConjugeOcupacao) > 0
    )

    const isAvalistaConjugeAssalariado = (
      avalistaEstadoCivil === estadoCivilCasado &&
      ocupacaoAssalariado.indexOf(avalistaConjugeOcupacao) > 0
    )

    const isAvalista2ConjugeAssalariado = (
      avalista2EstadoCivil === estadoCivilCasado &&
      ocupacaoAssalariado.indexOf(avalista2ConjugeOcupacao ? avalista2ConjugeOcupacao : '0') > 0
    )

    return {
      isSocioAdmConjugeAssalariado,
      isAvalistaConjugeAssalariado,
      isAvalista2ConjugeAssalariado
    }
  }, [
    formik.values.socioAdmEstadoCivil,
    formik.values.avalistaEstadoCivil,
    formik.values.avalista2EstadoCivil,
    formik.values.socioAdmConjugeOcupacao,
    formik.values.avalistaConjugeOcupacao,
    formik.values.avalista2ConjugeOcupacao,
  ])

  const hasError = (name: keyof TProjetoFormPJ) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TProjetoFormPJ) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  })

  const onChangeTipoDocumento = (e: TInputEvent) => {
    const { value } = e.target

    formik.setFieldValue('tipoDocumento', value)
    if (value === tipoDocumentoRg) {
      setShowFieldsDocumentos(true)
      return
    }

    setShowFieldsDocumentos(false)
    formik.setFieldValue('orgaoEmissor', '')
    formik.setFieldValue('estadoOrgaoEmissor', '')
  }

  const populateProfissoesField = async (
    type: CommonFieldTypes,
    ocupacaoId?: string,
    clearFields = false
  ) => {
    const profissoes = ocupacaoId
      ? await repositoryInformacoes.getProfissoes(ocupacaoId)
      : []

    const formattedPorfissoes = formatResponseToOptions(profissoes)

    switch (type) {
      case CommonFieldTypes.SOCIO_CONJUGE:
        setProfissoesSocioConjugeOptions(formattedPorfissoes)
        if (!clearFields) return
        formik.setFieldValue('socioAdmConjugeProfissao', '')
        formik.setFieldValue('socioAdmConjugeCargo', '')
        break
      case CommonFieldTypes.AVALISTA_CONJUGE:
        setProfissoesAvalistaConjugeOptions(formattedPorfissoes)
        if (!clearFields) return
        formik.setFieldValue('socioAvalistaProfissao', '')
        formik.setFieldValue('socioAvalistaCargo', '')
        break
      case CommonFieldTypes.AVALISTA2_CONJUGE:
        setProfissoesAvalista2ConjugeOptions(formattedPorfissoes)
        if (!clearFields) return
        formik.setFieldValue('socioAvalista2Profissao', '')
        formik.setFieldValue('socioAvalista2Cargo', '')
        break
    }
  }

  const onChangeOcupacao = (e: TInputEvent) => {
    const { value, id } = e.target
    formik.setFieldValue(id, value, true)
    let type
    let clearFields = false

    switch (id){
      case 'socioAdmConjugeOcupacao':
        type = CommonFieldTypes.SOCIO_CONJUGE
        clearFields = true
        populateProfissoesField(type, value, clearFields)
        break
      case 'avalistaConjugeOcupacao':
        type = CommonFieldTypes.AVALISTA_CONJUGE
        clearFields = true
        populateProfissoesField(type, value, clearFields)
        break
      case 'avalista2ConjugeOcupacao':
        type = CommonFieldTypes.AVALISTA2_CONJUGE
        clearFields = true
        populateProfissoesField(type, value, clearFields)
        break
    }
  }

  const getParams = async () => {
    const respActivityBranch = repositoryInformacoes.getActivityBranch()

  }

  const populateCargoField = async (
    type: CommonFieldTypes | string,
    params: TCargosParams,
    clearField = false
  ) => {
    const cargos = await repositoryInformacoes.getCargos(params)
    if (!cargos) return

    const formattedCargos = formatResponseToOptions(cargos)

    switch (type) {
      case CommonFieldTypes.SOCIO_CONJUGE:
        setCargosSocioConjugeOptions(formattedCargos)
        if (clearField) formik.setFieldValue('socioAdmConjugeCargo', '')
        return
      case CommonFieldTypes.AVALISTA_CONJUGE:
        setCargosAvalistaConjugeOptions(formattedCargos)
        if (clearField) formik.setFieldValue('avalistaConjugeCargo', '')
        return
      case CommonFieldTypes.AVALISTA2_CONJUGE:
        setCargosAvalista2ConjugeOptions(formattedCargos)
        if (clearField) formik.setFieldValue('avalista2ConjugeCargo', '')

    }
  }

  const onChangeProfissao = (e: TInputEvent) => {
    const { value, id } = e.target
    formik.setFieldValue(id, value, true)
    let type = ''
    let formikValue = ''

    switch (id) {
      case 'socioAdmConjugeProfissao':
        type = CommonFieldTypes.SOCIO_CONJUGE
        const fieldOcupacao1 = id === 'socioAdmConjugeProfissao' ? 'socioAdmConjugeOcupacao' : 'socioAdmConjugeOcupacao'
        formikValue = formik.values[fieldOcupacao1]
        break
      case 'avalistaConjugeProfissao':
        type = CommonFieldTypes.AVALISTA_CONJUGE
        const fieldOcupacao2 = id === 'avalistaConjugeProfissao' ? 'avalistaConjugeOcupacao' : 'avalistaConjugeOcupacao'
        formikValue = formik.values[fieldOcupacao2]
        break
      case 'avalista2ConjugeProfissao':
        type = CommonFieldTypes.AVALISTA2_CONJUGE
        const fieldOcupacao3 = id === 'avalista2ConjugeProfissao' ? 'avalista2ConjugeOcupacao' : 'avalista2ConjugeOcupacao'
        formikValue = formik.values[fieldOcupacao3] as string
        break
    }

    const params: TCargosParams = {
      professionId: value,
      professionNatureId: formikValue
    }

    const clearField = true
    populateCargoField(type, params, clearField)
  }

  const onChangeCargo = (e: TInputEvent) => {
    const { value, id } = e.target

    switch (id) {
      case 'socioAdmConjugeCargo':
        formik.setFieldValue('socioAdmConjugeCargo', value)
        break
      case 'avalistaConjugeCargo':
        formik.setFieldValue('avalistaConjugeCargo', value)
        break
      case 'avalista2ConjugeCargo':
        formik.setFieldValue('avalista2ConjugeCargo', value)
        break
    }
  }

  const onChangeSocioEstadoCivil = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('socioAdmEstadoCivil', value, true)

    if (value === estadoCivilCasado) return

    formik.setFieldValue('socioAdmConjugeNome', '')
    formik.setFieldValue('socioAdmConjugeCpf', '')
    formik.setFieldValue('socioAdmConjugeOcupacao', '')
    formik.setFieldValue('socioAdmConjugeProfissao', '')
    formik.setFieldValue('socioAdmConjugeCargo', '')
    formik.setFieldValue('socioAdmConjugeComercialNome', '')
    formik.setFieldValue('socioAdmConjugeComercialCnpj', '')
    formik.setFieldValue('socioAdmConjugeRendaMensal', '')
    formik.setFieldValue('socioAdmConjugeComercialAdmissao', '')
    formik.setFieldValue('socioAdmEnderecamentoConjugePessoal', '')
    formik.setFieldValue('socioAdmConjugeCep', '')
    formik.setFieldValue('socioAdmConjugeEndereco', '')
    formik.setFieldValue('socioAdmConjugeNumero', '')
    formik.setFieldValue('socioAdmConjugeBairro', '')
    formik.setFieldValue('socioAdmConjugeEstado', '')
    formik.setFieldValue('socioAdmConjugeCidade', '')
    formik.setFieldValue('socioAdmConjugeComplemento', '')
    formik.setFieldValue('socioAdmConjugeComercialTelefone', '')
    formik.setFieldValue('socioAdmConjugeComercialTelefoneRamal', '')
  }

  const onChangeAvalistaEstadoCivil = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('avalistaEstadoCivil', value, true)

    if (value === estadoCivilCasado) return

    formik.setFieldValue('avalistaConjugeNome', '')
    formik.setFieldValue('avalistaConjugeCpf', '')
    formik.setFieldValue('avalistaConjugeOcupacao', '')
    formik.setFieldValue('avalistaConjugeProfissao', '')
    formik.setFieldValue('avalistaConjugeCargo', '')
    formik.setFieldValue('avalistaConjugeComercialNome', '')
    formik.setFieldValue('avalistaConjugeComercialCnpj', '')
    formik.setFieldValue('avalistaConjugeRendaMensal', '')
    formik.setFieldValue('avalistaConjugeComercialAdmissao', '')
    formik.setFieldValue('avalistaEnderecamentoConjugePessoal', '')
    formik.setFieldValue('avalistaConjugeCep', '')
    formik.setFieldValue('avalistaConjugeEndereco', '')
    formik.setFieldValue('avalistaConjugeNumero', '')
    formik.setFieldValue('avalistaConjugeBairro', '')
    formik.setFieldValue('avalistaConjugeEstado', '')
    formik.setFieldValue('avalistaConjugeCidade', '')
    formik.setFieldValue('avalistaConjugeComplemento', '')
    formik.setFieldValue('avalistaConjugeComercialTelefone', '')
    formik.setFieldValue('avalistaConjugeComercialTelefoneRamal', '')
  }

  const onChangeAvalista2EstadoCivil = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('avalista2EstadoCivil', value, true)

    if (value === estadoCivilCasado) return

    formik.setFieldValue('avalista2ConjugeNome', '')
    formik.setFieldValue('avalista2ConjugeCpf', '')
    formik.setFieldValue('avalista2ConjugeOcupacao', '')
    formik.setFieldValue('avalista2ConjugeProfissao', '')
    formik.setFieldValue('avalista2ConjugeCargo', '')
    formik.setFieldValue('avalista2ConjugeComercialNome', '')
    formik.setFieldValue('avalista2ConjugeComercialCnpj', '')
    formik.setFieldValue('avalista2ConjugeRendaMensal', '')
    formik.setFieldValue('avalista2ConjugeComercialAdmissao', '')
    formik.setFieldValue('avalista2EnderecamentoConjugePessoal', '')
    formik.setFieldValue('avalista2ConjugeCep', '')
    formik.setFieldValue('avalista2ConjugeEndereco', '')
    formik.setFieldValue('avalista2ConjugeNumero', '')
    formik.setFieldValue('avalista2ConjugeBairro', '')
    formik.setFieldValue('avalista2ConjugeEstado', '')
    formik.setFieldValue('avalista2ConjugeCidade', '')
    formik.setFieldValue('avalista2ConjugeComplemento', '')
    formik.setFieldValue('avalista2ConjugeComercialTelefone', '')
    formik.setFieldValue('avalista2ConjugeComercialTelefoneRamal', '')
  }

  const onChangeAvalista2 = () => {
    if (segundoAvalista === true) return

    formik.setFieldValue('avalista2Nome', '')
    formik.setFieldValue('avalista2Cpf', '')
    formik.setFieldValue('avalista2Email', '')
    formik.setFieldValue('avalista2Telefone', '')
    formik.setFieldValue('avalista2EstadoCivil', '')
    formik.setFieldValue('cargosAvalista2ConjugeOptions', '')
    formik.setFieldValue('avalista2Endereco', '')
    formik.setFieldValue('avalista2Numero', '')
    formik.setFieldValue('avalista2Bairro', '')
    formik.setFieldValue('avalista2Estado', '')
    formik.setFieldValue('avalista2CidadeRef', '')
    formik.setFieldValue('avalista2CidadeId', '')
    formik.setFieldValue('avalista2Complemento', '')
    formik.setFieldValue('avalista2ComercialTelefone', '')
    formik.setFieldValue('avalista2ComercialTelefoneRamal', '')
    formik.setFieldValue('avalista2ComercialCelular', '')
    formik.setFieldValue('avalista2RendaMensal', '')
    formik.setFieldValue('avalista2Patrimonio', '')
    formik.setFieldValue('avalista2ConjugeNome', '')
    formik.setFieldValue('avalista2ConjugeCpf', '')
    formik.setFieldValue('avalista2ConjugeOcupacao', '')
    formik.setFieldValue('avalista2ConjugeProfissao', '')
    formik.setFieldValue('avalista2ConjugeCargo', '')
    formik.setFieldValue('avalista2ConjugeComercialNome', '')
    formik.setFieldValue('avalista2ConjugeComercialCnpj', '')
    formik.setFieldValue('avalista2ConjugeRendaMensal', '')
    formik.setFieldValue('avalista2ConjugeComercialAdmissao', '')
    formik.setFieldValue('avalista2EnderecamentoConjugePessoal', '')
    formik.setFieldValue('avalista2ConjugeCep', '')
    formik.setFieldValue('avalista2ConjugeEndereco', '')
    formik.setFieldValue('avalista2ConjugeNumero', '')
    formik.setFieldValue('avalista2ConjugeBairro', '')
    formik.setFieldValue('avalista2ConjugeEstado', '')
    formik.setFieldValue('avalista2ConjugeCidade', '')
    formik.setFieldValue('avalista2ConjugeCidadeId', '')
    formik.setFieldValue('avalista2ConjugeComplemento', '')
    formik.setFieldValue('avalista2ConjugeComercialTelefone', '')
    formik.setFieldValue('avalista2ConjugeComercialTelefoneRamal', '')
  }

  const onChangeEstado = (e: TInputEvent) => {
    const { value, id } = e.target
    switch (id) {
      case 'estadoProjeto':
        formik.setFieldValue('estadoProjeto', value, true)
        formik.setFieldValue('cidadeProjeto', '')
        if (cidadeProjetoRef.current) cidadeProjetoRef.current.clear()
        break
      case 'estado':
        formik.setFieldValue('estado', value, true)
        formik.setFieldValue('cidade', '')
        if (cidadeRef.current) cidadeRef.current.clear()
        break
      case 'estadoNaturalidade':
        formik.setFieldValue('estadoNaturalidade', value, true)
        formik.setFieldValue('cidadeNaturalidade', '')
        if (cidadeNaturalidadeRef.current) cidadeNaturalidadeRef.current.clear()
        break
      case 'conjugeComercialEstado':
        formik.setFieldValue('conjugeComercialEstado', value, true)
        formik.setFieldValue('conjugeComercialCidade', '')
        if (conjugeCidadeRef.current) conjugeCidadeRef.current.clear()
        break
      case 'socioAdmEstado':
        formik.setFieldValue('socioAdmEstado', value, true)
        formik.setFieldValue('socioAdmCidade', '')
        if (socioAdmCidadeRef.current) socioAdmCidadeRef.current.clear()
        break
      case 'socioAdmConjugeEstado':
        formik.setFieldValue('socioAdmConjugeEstado', value, true)
        formik.setFieldValue('socioAdmConjugeCidade', '')
        if (socioAdmConjugeCidadeRef.current) socioAdmConjugeCidadeRef.current.clear()
        break
      case 'avalistaEstado':
        formik.setFieldValue('avalistaEstado', value, true)
        formik.setFieldValue('avalistaCidade', '')
        if (avalistaCidadeRef.current) avalistaCidadeRef.current.clear()
        break
      case 'avalistaConjugeEstado':
        formik.setFieldValue('avalistaConjugeEstado', value, true)
        formik.setFieldValue('avalistaConjugeCidade', '')
        if (avalistaConjugeCidadeRef.current) avalistaConjugeCidadeRef.current.clear()
        break
      case 'avalista2Estado':
        formik.setFieldValue('avalista2Estado', value, true)
        formik.setFieldValue('avalista2Cidade', '')
        if (avalista2CidadeRef.current) avalista2CidadeRef.current.clear()
        break
      case 'avalista2ConjugeEstado':
        formik.setFieldValue('avalista2ConjugeEstado', value, true)
        formik.setFieldValue('avalista2ConjugeCidade', '')
        if (avalista2ConjugeCidadeRef.current) avalista2ConjugeCidadeRef.current.clear()
        break
    }
  }

  const onSearchCidade = (name: keyof TProjetoFormPJ) => (term: string) => {
    if (term.length < 3) return
    const requestCidades = async (estado: string) => {
      const params = {
        search: term,
        stateId: String(estado)
      }
      const cidades = await repositoryInformacoes.getCidades(params)
      if (!cidades) return

      const _cidadeOptions = cidades.map(cidade => ({
        label: cidade.description,
        value: String(cidade.id)
      }))

      setCidadeOptions(_cidadeOptions)
    }

    const { values } = formik

    switch (name) {
      case 'cidadeProjeto':
        if (values.estadoProjeto) requestCidades(values.estadoProjeto)
        break
      case 'cidade':
        if (values.estado) requestCidades(values.estado)
        break
      case 'socioAdmCidade':
        if (values.socioAdmEstado) requestCidades(values.socioAdmEstado)
        break
      case 'socioAdmConjugeCidade':
        if (values.socioAdmConjugeEstado) requestCidades(values.socioAdmConjugeEstado)
        break
      case 'avalistaCidade':
        if (values.avalistaEstado) requestCidades(values.avalistaEstado)
        break
      case 'avalistaConjugeCidade':
        if (values.avalistaConjugeEstado) requestCidades(values.avalistaConjugeEstado)
        break
      case 'avalista2Cidade':
        if (values.avalista2Estado) requestCidades(values.avalista2Estado)
        break
      case 'avalista2ConjugeCidade':
        if (values.avalista2ConjugeEstado) requestCidades(values.avalista2ConjugeEstado)
        break
    }
  }

  const onChangeCidade = (name: keyof TProjetoFormPJ) => ([cidade]: TSelectOption[]) => {
    const value = cidade?.value ?? ''
    const label = cidade?.label ?? ''
    switch (name) {
      case 'cidadeProjeto':
        formik.setFieldValue('cidadeProjeto', label, true)
        formik.setFieldValue('cidadeProjetoId', value, true)
        break
      case 'cidade':
        formik.setFieldValue('cidade', label, true)
        formik.setFieldValue('cidadeId', value, true)
        break
      case 'socioAdmCidade':
        formik.setFieldValue('socioAdmCidade', label, true)
        formik.setFieldValue('socioAdmCidadeId', value, true)
        break
      case 'socioAdmConjugeCidade':
        formik.setFieldValue('socioAdmConjugeCidade', label, true)
        formik.setFieldValue('socioAdmConjugeCidadeId', value, true)
        break
      case 'avalistaCidade':
        formik.setFieldValue('avalistaCidade', label, true)
        formik.setFieldValue('avalistaCidadeId', value, true)
        break
      case 'avalistaConjugeCidade':
        formik.setFieldValue('avalistaConjugeCidade', label, true)
        formik.setFieldValue('avalistaConjugeCidadeId', value, true)
        break
      case 'avalista2Cidade':
        formik.setFieldValue('avalista2Cidade', label, true)
        formik.setFieldValue('avalista2CidadeId', value, true)
        break
      case 'avalista2ConjugeCidade':
        formik.setFieldValue('avalista2ConjugeCidade', label, true)
        formik.setFieldValue('avalista2ConjugeCidadeId', value, true)
        break
    }
  }

  const onBlurCidade = (name: keyof TProjetoFormPJ) => () => {
    const getRefByName = () => {
      switch (name) {
        case 'cidadeProjeto':
          return cidadeProjetoRef
        case 'cidade':
          return cidadeRef
        case 'socioAdmCidade':
          return socioAdmCidadeRef
        case 'socioAdmConjugeCidade':
          return socioAdmConjugeCidadeRef
        case 'avalistaCidade':
          return avalistaCidadeRef
        case 'avalistaConjugeCidade':
          return avalistaConjugeCidadeRef
        case 'avalista2Cidade':
          return avalista2CidadeRef
        case 'avalista2ConjugeCidade':
          return avalista2ConjugeCidadeRef
      }
    }
    const ref = getRefByName()
    formik.setFieldTouched(name, true)
    if (!formik.values[name]) ref?.current?.clear()
  }

  const onSearchIntegrador = (term: string) => {
    if (term.length < 3) return

    const requestIntegradores = async () => {
      const params = {
        companyName: term
      }
      const response = await getIntegradoresByCompanyName(params)
      if (!response) return

      const _integradoresOptions = response.integrators.map(integrador => {
        const formattedCnpj = formatCpfCnpj(integrador.cnpj)
        return {
          label: `${integrador.companyName} - CNPJ: ${formattedCnpj}`,
          value: integrador.id
        }
      })

      setIntegradoresOptions(_integradoresOptions)
    }

    requestIntegradores()
  }

  const onChangeIntegrador = ([integrador]: TSelectOption[]) => {
    const value = integrador?.value ?? ''
    const label = integrador?.label ?? ''
    formik.setFieldValue('integrador', label, true)
    formik.setFieldValue('integradorId', value, true)
  }

  const onBlurIntegrador = () => {
    formik.setFieldTouched('integradorId', true)
    if (!formik.values.integradorId) integradorRef.current?.clear()
  }

  const onSearchNacionalidade = (term: string) => {
    if (term.length < 3) return

    const requestNacionalidades = async () => {
      const params = {
        search: term,
      }
      const nacionalidades = await repositoryInformacoes.getNacionalidades(params)
      if (!nacionalidades) return

      const _nacionalidadesOptions = nacionalidades.map(nacionalidade => ({
        label: nacionalidade.description,
        value: nacionalidade.id
      }))

      setNacionalidadesOptions(_nacionalidadesOptions)
    }

    requestNacionalidades()
  }

  const onChangeNacionalidade = ([nacionalidade]: TSelectOption[]) => {
    const cleanNaturalidadeEstadoCidade = () => {
      formik.setFieldValue('estadoNaturalidade', '')
      formik.setFieldValue('cidadeNaturalidade', '')
      formik.setFieldValue('cidadeNaturalidadeId', '')
      if (cidadeNaturalidadeRef.current) {
        cidadeNaturalidadeRef.current.clear()
      }
    }

    const value = nacionalidade?.value ?? ''
    const label = nacionalidade?.label ?? ''
    formik.setFieldValue('nacionalidade', label, true)
    formik.setFieldValue('nacionalidadeId', value, true)
    if (String(value) !== nacionalidadeBrasileira) cleanNaturalidadeEstadoCidade()
  }

  const onBlurNacionalidade = () => {
    formik.setFieldTouched('nacionalidade', true)
    if (!formik.values.nacionalidadeId) nacionalidadeRef.current?.clear()
  }

  const isRequiredCidadeUfNaturalidade = useMemo(() => (
    String(formik.values.nacionalidadeId) === nacionalidadeBrasileira
  ), [formik.values.nacionalidadeId])

  const onClickBackToList = () => history.push('/projetos')

  const onChangePorte = (e: TInputEvent) => {
    const { value, id } = e.target
    formik.setFieldValue('porte', value, true)
  }

  const onChangeRamo = (e: TInputEvent) => {
    const { value, id } = e.target
    let newValue = 0
    newValue = Number(value) - 1

    if (newValue >= 0) {
      const selectLabel = (ramoOptions[Number(newValue)].value)
      getAtividadesOptions(selectLabel)
      formik.setFieldValue('ramo', value, true)
    } else {
      formik.setFieldValue('ramo', value, true)
      setAtividadeOptions([])
    }
  }

  const getPortesOptions = async () => {
    const response = await repositoryInformacoes.getCompanySize()
    if (!response) return
    const _portesOptions = response.companySize.map((porte: any) => ({
      label: `${porte.descricao} - R$ ${porte.valorInicial} a R$ ${porte.valorFinal}`,
      value: porte.idPorteEmpresa,
    }))
    setPorteOptions(_portesOptions)
  }

  const getRamoAtividadeOptions = async () => {
    const response = await repositoryInformacoes.getActivityBranch()
    if (!response) return
    const _ramoAtividadeOptions = response.activityBranch.map((ramoAtividade: any) => ({
      label: ramoAtividade.descricao,
      value: ramoAtividade.idRamoAtividade,
    }))
    setRamoOptions(_ramoAtividadeOptions)
  }

  const onSearchAtividade = (name: keyof TProjetoFormPJ) => (term: string) => {}

  const onBlurAtividade = (name: keyof TProjetoFormPJ) => () => {
    const getRefByName = () => {
      switch (name) {
        case 'atividadeEconomica':
          return atividadeEconomicaRef
      }
    }
    const ref = getRefByName()
    formik.setFieldTouched(name, true)
    if (!formik.values[name]) ref?.current?.clear()
  }

  const onChangeAtividade = (name: keyof TProjetoFormPJ) => ([atividade]: TSelectOption[]) => {
    const value = atividade?.value ?? ''
    const label = atividade?.label ?? ''
    formik.setFieldValue('atividadeEconomica', label, true)
    formik.setFieldValue('atividadeEconomicaId', value, true)
  }

  const getAtividadesOptions = async (ramo: string) => {
    const response = await repositoryInformacoes.getEconomicActivity(ramo)
    if (!response) return
    const _atividadesOptions = response.economicActivity.map((atividade: any) => ({
      label: atividade.descricao,
      value: atividade.id,
    }))
    setAtividadeOptions(_atividadesOptions)
  }

  useEffect(() => {
    const requestCurrentIntegrador = async () => {
      const response = await getIntegradoresByCompanyName({ pageSize: 1 })
      if (!response) return

      const { currentUserIntegrator } = response
      if (currentUserIntegrator.id) {
        const formattedIntegradorNome = `${currentUserIntegrator.companyName} - CNPJ: ${currentUserIntegrator.cnpj}`
        formik.setFieldValue('integrador', formattedIntegradorNome)
        formik.setFieldValue('integradorId', currentUserIntegrator.id)
        setIsDisabledIntegradorField(true)
      }
    }

    requestCurrentIntegrador()
  }, [])

  useEffect(() => {
    if(formik.values.cep.replaceAll('_', '').replace('-', '').length >= 8) {
      cepPromise(formik.values.cep).then((data: any) => {
        if (data) {
          formik.setFieldValue('bairro', data.neighborhood)
          formik.setFieldValue('endereco', data.street)
        }
      })
    }
  }, [formik.values.cep])

  useEffect(() => {
    if(formik.values.socioAdmCep.replaceAll('_', '').replace('-', '').length >= 8) {
      cepPromise(formik.values.socioAdmCep).then((data: any) => {
        if (data) {
          formik.setFieldValue('socioAdmBairro', data.neighborhood)
          formik.setFieldValue('socioAdmEndereco', data.street)
        }
      })
    }
  }, [formik.values.socioAdmCep])

  useEffect(() => {
    if(formik.values.avalistaCep.replaceAll('_', '').replace('-', '').length >= 8) {
      cepPromise(formik.values.avalistaCep).then((data: any) => {
        if (data) {
          formik.setFieldValue('avalistaBairro', data.neighborhood)
          formik.setFieldValue('avalistaEndereco', data.street)
        }
      })
    }
  }, [formik.values.avalistaCep])

  useEffect(() => {
    if(formik.values.socioAdmConjugeCep.replaceAll('_', '').replace('-', '').length >= 8) {
      cepPromise(formik.values.socioAdmConjugeCep).then((data: any) => {
        if (data) {
          formik.setFieldValue('socioAdmConjugeBairro', data.neighborhood)
          formik.setFieldValue('socioAdmConjugeEndereco', data.street)
        }
      })
    }
  }, [formik.values.socioAdmConjugeCep])

  useEffect(() => {
    if(formik.values.avalistaConjugeCep.replaceAll('_', '').replace('-', '').length >= 8) {
      cepPromise(formik.values.avalistaConjugeCep).then((data: any) => {
        if (data) {
          formik.setFieldValue('avalistaConjugeBairro', data.neighborhood)
          formik.setFieldValue('avalistaConjugeEndereco', data.street)
        }
      })
    }
  }, [formik.values.avalistaConjugeCep])

  useEffect(() => {
    if (formik.values.avalista2Cep !== undefined) {
      if(formik.values.avalista2Cep.replaceAll('_', '').replace('-', '').length >= 8) {
        cepPromise(formik.values.avalista2Cep).then((data: any) => {
          if (data) {
            formik.setFieldValue('avalista2Bairro', data.neighborhood)
            formik.setFieldValue('avalista2Endereco', data.street)
          }
        })
      }
    }
  }, [formik.values.avalista2Cep])

  useEffect(() => {
    if(formik.values.avalista2ConjugeCep !== undefined){
      if(formik.values.avalista2ConjugeCep.replaceAll('_', '').replace('-', '').length >= 8) {
        cepPromise(formik.values.avalista2ConjugeCep).then((data: any) => {
          if (data) {
            formik.setFieldValue('avalista2ConjugeBairro', data.neighborhood)
            formik.setFieldValue('avalista2ConjugeEndereco', data.street)
          }
        })
      }
    }
  }, [formik.values.avalista2ConjugeCep])

  useEffect(() => {
    const validateFilled = () => {
      if (segundoAvalista) {
        setRequiredAvalista(true)
      } else if (formik.values.avalistaNome) {
        if (formik.values.avalistaNome.length > 0) {
          setRequiredAvalista(true)
        } else {
          setRequiredAvalista(false)
        }
      } else {
        setRequiredAvalista(false)
      }
    }
    validateFilled()
  }, [formik.values.avalistaNome, segundoAvalista])

  useEffect(() => {
    const validateFilled = () => {
      if (formik.values.socioAdmConjugeRendaMensal) {
        if (formik.values.socioAdmConjugeRendaMensal.length > 3) {
          setIsRequiredConjuge1(true)
        } else {
          setIsRequiredConjuge1(false)
        }
      } else {
        setIsRequiredConjuge1(false)
      }

      if (formik.values.avalistaConjugeRendaMensal) {
        if (formik.values.avalistaConjugeRendaMensal.length > 3) {
          setIsRequiredConjuge2(true)
        } else {
          setIsRequiredConjuge2(false)
        }
      } else {
        setIsRequiredConjuge2(false)
      }

      if (formik.values.avalista2ConjugeRendaMensal) {
        if (formik.values.avalista2ConjugeRendaMensal.length > 3) {
          setIsRequiredConjuge3(true)
        } else {
          setIsRequiredConjuge3(false)
        }
      } else {
        setIsRequiredConjuge3(false)
      }
    }
    validateFilled()
  }, [formik.values.socioAdmConjugeRendaMensal,
    formik.values.avalistaConjugeRendaMensal,
    formik.values.avalista2ConjugeRendaMensal,
    isRequiredConjuge1,
    isRequiredConjuge2,
    isRequiredConjuge3])

  useEffect(() => {
    if (valuesOcupacaoAssalariado.indexOf(String(formik.values.socioAdmConjugeOcupacao)) >= 0){
      setIsRequiredEnderecoConjuge1(true)
    } else {
      setIsRequiredEnderecoConjuge1(false)
    }

    if (valuesOcupacaoAssalariado.indexOf(String(formik.values.avalistaConjugeOcupacao)) >= 0){
      setIsRequiredEnderecoConjuge2(true)
    } else {
      setIsRequiredEnderecoConjuge2(false)
    }

    if (valuesOcupacaoAssalariado.indexOf(String(formik.values.avalista2ConjugeOcupacao)) >= 0){
      setIsRequiredEnderecoConjuge3(true)
    } else {
      setIsRequiredEnderecoConjuge3(false)
    }
  }, [
    formik.values.socioAdmConjugeOcupacao,
    formik.values.avalistaConjugeOcupacao,
    formik.values.avalista2ConjugeOcupacao,
  ])

  const onChangeSegundoAvalista = () => {
    setSegundoAvalista(!segundoAvalista)
    onChangeAvalista2()
  }

  const { isRequiredSocioConjuge, isRequiredAvalistaConjuge, isRequiredAvalista2Conjuge } = useMemo(() => {
    const { socioAdmEstadoCivil, avalistaEstadoCivil, avalista2EstadoCivil } = formik.values
    const isRequiredSocioConjuge = socioAdmEstadoCivil === estadoCivilCasado
    const isRequiredAvalistaConjuge = avalistaEstadoCivil === estadoCivilCasado
    const isRequiredAvalista2Conjuge = avalista2EstadoCivil === estadoCivilCasado

    return {
      isRequiredSocioConjuge,
      isRequiredAvalistaConjuge,
      isRequiredAvalista2Conjuge
    }
  }, [ formik.values.socioAdmEstadoCivil, formik.values.avalistaEstadoCivil, formik.values.avalista2EstadoCivil ])

  const isVisualizando = useMemo(() => {
    const urlAction = location.pathname.split('/')[2]
    return urlAction === 'visualizar'
  }, [location.pathname])

  const headerTitleAction = useMemo(() => {
    if (isVisualizando) return 'Visualizar'
    if (isReplicate) return 'Replicar'

    return id ? 'Editar' : 'Adicionar'
  }, [isVisualizando, id, isReplicate])

  const getConfirmationMessage = () => {
    if(isReplicate) return 'Ao prosseguir, este projeto será replicado. Deseja continuar?'
    return 'Ao prosseguir, suas alterações serão salvas. Deseja continuar?'
  }

  const getToastMessage = () => {
    if(isReplicate) return 'Projeto replicado com sucesso!'
    if(id) return 'Projeto editado com sucesso!'
    return 'Projeto criado com sucesso!'
  }

  if (userData && !permission) return <Redirect to='/acesso-negado' />

  const renderSegundoAvalistaConjuge = () => {
    if(segundoAvalista && formik.values.avalista2EstadoCivil === estadoCivilCasado) {
      return (
        <>
          <Row>
            <Col>
              <FormSubTitle>Informações do Cônjuge</FormSubTitle>
            </Col>
          </Row>

          <Form.Row>
            <Col md={6}>
              <TextField
                required={isRequiredAvalista2Conjuge}
                label='Nome do Cônjuge'
                {...getCommonFieldProps('avalista2ConjugeNome')}
              />
            </Col>
            <Col md={6}>
              <MaskedTextField
                required={isRequiredAvalista2Conjuge}
                label='CPF'
                mask='999.999.999-99'
                {...getCommonFieldProps('avalista2ConjugeCpf')}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col>
              <SelectField
                required={isRequiredConjuge3}
                label='Ocupação'
                options={ocupacoesOptions}
                {...getCommonFieldProps('avalista2ConjugeOcupacao')}
                onChange={onChangeOcupacao}
              />
            </Col>
            <Col md={6}>
              <SelectField
                required={isRequiredConjuge3}
                label='Profissão'
                options={profissoesAvalista2ConjugeOptions}
                {...getCommonFieldProps('avalista2ConjugeProfissao')}
                onChange={onChangeProfissao}
              />
            </Col>
          </Form.Row>

          <Form.Row>

            <Col md={6}>
              <SelectField
                required={isRequiredConjuge3}
                label='Cargo / Atividade'
                options={cargosAvalista2ConjugeOptions}
                {...getCommonFieldProps('avalista2ConjugeCargo')}
                onChange={onChangeCargo}
              />
            </Col>
            <Col md={6}>
              <TextField
                required={isAvalista2ConjugeAssalariado}
                label='Nome da Empresa'
                {...getCommonFieldProps('avalista2ConjugeComercialNome')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={4}>
              <MaskedTextField
                required={isAvalista2ConjugeAssalariado}
                label='CNPJ'
                mask='99.999.999/9999-99'
                {...getCommonFieldProps('avalista2ConjugeComercialCnpj')}
              />
            </Col>
            <Col md={4}>
              <MaskedCurrencyField
                required={isRequiredConjuge3}
                label='Renda mensal'
                description='Ao preencher, os demais campos do cônjuge serão obrigatórios'
                {...getCommonFieldProps('avalista2ConjugeRendaMensal')}
              />
            </Col>
            <Col md={4}>
              <TextField
                type='date'
                label='Data admissão'
                required={isAvalista2ConjugeAssalariado}
                {...getCommonFieldProps('avalista2ConjugeComercialAdmissao')}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col md={3}>
              <MaskedTextField
                label='CEP'
                mask='99999-999'
                required={isRequiredEnderecoConjuge3}
                {...getCommonFieldProps('avalista2ConjugeCep')}
              />
            </Col>

            <Col md={5}>
              <TextField
                label='Logradouro'
                required={isRequiredEnderecoConjuge3}
                {...getCommonFieldProps('avalista2ConjugeEndereco')}
              />
            </Col>

            <Col md={4}>
              <TextField
                type='string'
                label='Número'
                maxLength={5}
                required={isRequiredEnderecoConjuge3}
                {...getCommonFieldProps('avalista2ConjugeNumero')}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col md={5}>
              <TextField
                label='Bairro'
                required={isRequiredEnderecoConjuge3}
                {...getCommonFieldProps('avalista2ConjugeBairro')}
              />
            </Col>

            <Col md={3}>
              <SelectField
                label='Estado'
                options={estadosApiSafraOptions}
                required={isRequiredEnderecoConjuge3}
                {...getCommonFieldProps('avalista2ConjugeEstado')}
                onChange={onChangeEstado}
              />
            </Col>

            <Col md={4}>
              <SearchField
                label='Cidade'
                elementRef={conjugeCidadeRef}
                options={cidadeOptions}
                required={isRequiredEnderecoConjuge3}
                {...getCommonFieldProps('avalista2ConjugeCidade')}
                onSearch={onSearchCidade('avalista2ConjugeCidade')}
                onChange={onChangeCidade('avalista2ConjugeCidade')}
                onBlur={onBlurCidade('avalista2ConjugeCidade')}
                description='Para pesquisar uma cidade, selecione um "Estado" antes'
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col md={5}>
              <TextField
                label='Complemento'
                maxLength={formMaxLength}
                {...getCommonFieldProps('avalista2ConjugeComplemento')}
              />
            </Col>
            <Col md={3}>
              <MaskedTextField
                required={isRequiredConjuge3}
                label='Telefone'
                mask='(99) 99999-9999'
                {...getCommonFieldProps('avalista2ConjugeComercialTelefone')}
              />
            </Col>
            <Col md={4}>
              <TextField
                type='number'
                label='Ramal'
                {...getCommonFieldProps('avalista2ConjugeComercialTelefoneRamal')}
              />
            </Col>
          </Form.Row>
          <FormDivider />
        </>
      )
    }
  }

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={true}
            route='/projetos'
            message={confirmationMessage}
          />
        </Col>
      </Row>

      <PageHeader title={`${headerTitleAction} Projeto`} />

      <Row>
        <Col>
          <InfoText>
            Para prosseguir com o processo de compra do sistema fotovoltaico você deve fornecer algumas informações a respeito
            do cliente ou de sua empresa.
          </InfoText>
        </Col>
      </Row>

      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <FormTitle>Dados do Projeto</FormTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={6}>
            <SelectField
              required
              label='Finalidade do Projeto'
              options={finalidadeProjetoOptions}
              {...getCommonFieldProps('finalidadeProjeto')}
            />
          </Col>

          <Col md={6}>
            <SelectField
              required
              label='Tipo de Financiamento'
              options={tipoFinanciamentoOptions}
              {...getCommonFieldProps('tipoFinanciamento')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Nome do projeto'
              required
              {...getCommonFieldProps('nomeProjeto')}
            />
          </Col>

          <Col md={6}>
            <SearchField
              label='Integrador'
              disabled={isDisabledIntegradorField}
              elementRef={integradorRef}
              options={integradoresOptions}
              {...getCommonFieldProps('integrador')}
              onSearch={onSearchIntegrador}
              onChange={onChangeIntegrador}
              onBlur={onBlurIntegrador}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={3}>
            <MaskedCurrencyField
              required
              label='Potência estimada'
              {...getCommonFieldProps('potenciaProjeto')}
            />
          </Col>

          <Col md={3}>
            <MaskedCurrencyField
              required
              label='Valor estimado'
              {...getCommonFieldProps('valorProjeto')}
            />
          </Col>

          <Col md={2}>
            <SelectField
              required
              label='Estado'
              options={estadosApiSafraOptions}
              {...getCommonFieldProps('estadoProjeto')}
              onChange={onChangeEstado}
            />
          </Col>

          <Col md={4}>
            <SearchField
              required
              label='Cidade'
              elementRef={cidadeProjetoRef}
              options={cidadeOptions}
              {...getCommonFieldProps('cidadeProjeto')}
              onSearch={onSearchCidade('cidadeProjeto')}
              onChange={onChangeCidade('cidadeProjeto')}
              onBlur={onBlurCidade('cidadeProjeto')}
              description='Para pesquisar uma cidade, selecione um "Estado" antes'
            />
          </Col>
        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormTitle>Pessoa Jurídica</FormTitle>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormSubTitle>Informações básicas</FormSubTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Razão Social'
              required
              {...getCommonFieldProps('razaoSocial')}
            />
          </Col>

          <Col md={6}>
            <TextField
              label='Nome Fantasia'
              required
              {...getCommonFieldProps('nomeFantasia')}
            />

          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <MaskedTextField
              required
              label='CNPJ'
              mask='99.999.999/9999-99'
              {...getCommonFieldProps('cnpj')}
            />
          </Col>

          <Col md={6}>
            <TextField
              type='date'
              label='Data de Constituição'
              required
              {...getCommonFieldProps('dataConstituicao')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <MaskedCurrencyField
              required
              label='Valor de Faturamento Anual Médio'
              {...getCommonFieldProps('valorFaturamentoAnualMedio')}
            />
          </Col>

          <Col md={6}>
            <MaskedCurrencyField
              required
              label='Patrimonio Liquido'
              {...getCommonFieldProps('patrimonioLiquido')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Nome do Grupo Economico'
              required
              {...getCommonFieldProps('nomeGrupoEconomico')}
            />
          </Col>

          <Col md={6}>
            <SearchField
              required
              label='Nacionalidade'
              elementRef={nacionalidadeRef}
              options={nacionalidadesOptions}
              {...getCommonFieldProps('nacionalidade')}
              onSearch={onSearchNacionalidade}
              onChange={onChangeNacionalidade}
              onBlur={onBlurNacionalidade}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={4}>
            <SelectField
              required
              label='Porte da Empresa'
              options={porteOptions}
              {...getCommonFieldProps('porte')}
              onChange={onChangePorte}
            />
          </Col>

          <Col md={4}>
            <SelectField
              required
              label='Ramo de Atividade'
              options={ramoOptions}
              {...getCommonFieldProps('ramo')}
              onChange={onChangeRamo}
            />
          </Col>

          <Col md={4}>
            <SearchField
              required
              label='Atividade Econômica'
              elementRef={atividadeEconomicaRef}
              options={atividadeOptions}
              {...getCommonFieldProps('atividadeEconomica')}
              onSearch={onSearchAtividade('atividadeEconomica')}
              onChange={onChangeAtividade('atividadeEconomica')}
              onBlur={onBlurAtividade('atividadeEconomica')}
              description='Para pesquisar uma atividade, selecione um "Ramo de Atividade" antes'
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <MaskedTextField
              required
              label='Celular Comercial'
              mask='(99) 99999-9999'
              {...getCommonFieldProps('celularComercial')}
            />
          </Col>
          <Col md={6}>
            <MaskedTextField
              required
              label='Telefone Comercial'
              mask='(99) 99999-9999'
              {...getCommonFieldProps('telefoneComercial')}
            />
          </Col>
        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormSubTitle>Endereço</FormSubTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={3}>
            <MaskedTextField
              label='CEP'
              mask='99999-999'
              required
              {...getCommonFieldProps('cep')}
            />
          </Col>

          <Col md={5}>
            <TextField
              label='Logradouro'
              required
              {...getCommonFieldProps('endereco')}
            />
          </Col>

          <Col md={4}>
            <TextField
              required
              type='string'
              label='Número'
              maxLength={5}
              {...getCommonFieldProps('numero')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={5}>
            <TextField
              required
              label='Bairro'
              {...getCommonFieldProps('bairro')}
            />
          </Col>

          <Col md={3}>
            <SelectField
              required
              label='Estado'
              options={estadosApiSafraOptions}
              {...getCommonFieldProps('estado')}
              onChange={onChangeEstado}
            />
          </Col>

          <Col md={4}>
            <SearchField
              required
              label='Cidade'
              elementRef={cidadeRef}
              options={cidadeOptions}
              {...getCommonFieldProps('cidade')}
              onSearch={onSearchCidade('cidade')}
              onChange={onChangeCidade('cidade')}
              onBlur={onBlurCidade('cidade')}
              description='Para pesquisar uma cidade, selecione um "Estado" antes'
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={12}>
            <TextField
              label='Complemento'
              maxLength={formMaxLength}
              {...getCommonFieldProps('complemento')}
            />
          </Col>
        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormSubTitle>Sócio Administrador (Principal Avalista)</FormSubTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              required
              label='Nome'
              {...getCommonFieldProps('socioAdmNome')}
            />
          </Col>
          <Col md={6}>
            <MaskedTextField
              required
              label='CPF'
              mask='999.999.999-99'
              {...getCommonFieldProps('socioAdmCpf')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='E-mail'
              required
              {...getCommonFieldProps('socioAdmEmail')}
            />
          </Col>
          <Col md={3}>
            <MaskedTextField
              required
              label='Telefone'
              mask='(99) 99999-9999'
              {...getCommonFieldProps('socioAdmTelefone')}
            />
          </Col>
          <Col md={3}>
            <SelectField
              required
              label='Estado Civil'
              options={estadoCivilOptions}
              {...getCommonFieldProps('socioAdmEstadoCivil')}
              onChange={onChangeSocioEstadoCivil}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={3}>
            <MaskedTextField
              label='CEP'
              mask='99999-999'
              required
              {...getCommonFieldProps('socioAdmCep')}
            />
          </Col>

          <Col md={5}>
            <TextField
              label='Logradouro'
              required
              {...getCommonFieldProps('socioAdmEndereco')}
            />
          </Col>

          <Col md={4}>
            <TextField
              required
              type='string'
              label='Número'
              maxLength={5}
              {...getCommonFieldProps('socioAdmNumero')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={5}>
            <TextField
              required
              label='Bairro'
              {...getCommonFieldProps('socioAdmBairro')}
            />
          </Col>

          <Col md={3}>
            <SelectField
              required
              label='Estado'
              options={estadosApiSafraOptions}
              {...getCommonFieldProps('socioAdmEstado')}
              onChange={onChangeEstado}
            />
          </Col>

          <Col md={4}>
            <SearchField
              required
              label='Cidade'
              elementRef={socioAdmCidadeRef}
              options={cidadeOptions}
              {...getCommonFieldProps('socioAdmCidade')}
              onSearch={onSearchCidade('socioAdmCidade')}
              onChange={onChangeCidade('socioAdmCidade')}
              onBlur={onBlurCidade('socioAdmCidade')}
              description='Para pesquisar uma cidade, selecione um "Estado" antes'
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={12}>
            <TextField
              label='Complemento'
              maxLength={formMaxLength}
              {...getCommonFieldProps('socioAdmComplemento')}
            />
          </Col>
        </Form.Row>

        {formik.values.socioAdmEstadoCivil === estadoCivilCasado && (
          <>
            <FormDivider />

            <Row>
              <Col>
                <FormSubTitle>Informações do Cônjuge</FormSubTitle>
              </Col>
            </Row>

            <Form.Row>
              <Col md={6}>
                <TextField
                  required={isRequiredSocioConjuge}
                  label='Nome do Cônjuge'
                  {...getCommonFieldProps('socioAdmConjugeNome')}
                />
              </Col>
              <Col md={6}>
                <MaskedTextField
                  required={isRequiredSocioConjuge}
                  label='CPF'
                  mask='999.999.999-99'
                  {...getCommonFieldProps('socioAdmConjugeCpf')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col>
                <SelectField
                  required={isRequiredConjuge1}
                  label='Ocupação'
                  options={ocupacoesOptions}
                  {...getCommonFieldProps('socioAdmConjugeOcupacao')}
                  onChange={onChangeOcupacao}
                />
              </Col>
              <Col md={6}>
                <SelectField
                  required={isRequiredConjuge1}
                  label='Profissão'
                  options={profissoesSocioConjugeOptions}
                  {...getCommonFieldProps('socioAdmConjugeProfissao')}
                  onChange={onChangeProfissao}
                />
              </Col>
            </Form.Row>

            <Form.Row>

              <Col md={6}>
                <SelectField
                  required={isRequiredConjuge1}
                  label='Cargo / Atividade'
                  options={cargosSocioConjugeOptions}
                  {...getCommonFieldProps('socioAdmConjugeCargo')}
                  onChange={onChangeCargo}
                />
              </Col>
              <Col md={6}>
                <TextField
                  required={isSocioAdmConjugeAssalariado}
                  label='Nome da Empresa'
                  {...getCommonFieldProps('socioAdmConjugeComercialNome')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={4}>
                <MaskedTextField
                  required={isSocioAdmConjugeAssalariado}
                  label='CNPJ'
                  mask='99.999.999/9999-99'
                  {...getCommonFieldProps('socioAdmConjugeComercialCnpj')}
                />
              </Col>
              <Col md={4}>
                <MaskedCurrencyField
                  required={isRequiredConjuge1}
                  label='Renda mensal'
                  description='Ao preencher, os demais campos do cônjuge serão obrigatórios'
                  {...getCommonFieldProps('socioAdmConjugeRendaMensal')}
                />
              </Col>
              <Col md={4}>
                <TextField
                  type='date'
                  label='Data admissão'
                  required={isSocioAdmConjugeAssalariado}
                  {...getCommonFieldProps('socioAdmConjugeComercialAdmissao')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={3}>
                <MaskedTextField
                  label='CEP'
                  mask='99999-999'
                  required={isRequiredEnderecoConjuge1}
                  {...getCommonFieldProps('socioAdmConjugeCep')}
                />
              </Col>

              <Col md={5}>
                <TextField
                  label='Logradouro'
                  required={isRequiredEnderecoConjuge1}
                  {...getCommonFieldProps('socioAdmConjugeEndereco')}
                />
              </Col>

              <Col md={4}>
                <TextField
                  type='string'
                  label='Número'
                  maxLength={5}
                  required={isRequiredEnderecoConjuge1}
                  {...getCommonFieldProps('socioAdmConjugeNumero')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={5}>
                <TextField
                  label='Bairro'
                  required={isRequiredEnderecoConjuge1}
                  {...getCommonFieldProps('socioAdmConjugeBairro')}
                />
              </Col>

              <Col md={3}>
                <SelectField
                  label='Estado'
                  options={estadosApiSafraOptions}
                  required={isRequiredEnderecoConjuge1}
                  {...getCommonFieldProps('socioAdmConjugeEstado')}
                  onChange={onChangeEstado}
                />
              </Col>

              <Col md={4}>
                <SearchField
                  label='Cidade'
                  elementRef={socioAdmConjugeCidadeRef}
                  options={cidadeOptions}
                  required={isRequiredEnderecoConjuge1}
                  {...getCommonFieldProps('socioAdmConjugeCidade')}
                  onSearch={onSearchCidade('socioAdmConjugeCidade')}
                  onChange={onChangeCidade('socioAdmConjugeCidade')}
                  onBlur={onBlurCidade('socioAdmConjugeCidade')}
                  description='Para pesquisar uma cidade, selecione um "Estado" antes'
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={5}>
                <TextField
                  label='Complemento'
                  maxLength={formMaxLength}
                  {...getCommonFieldProps('socioAdmConjugeComplemento')}
                />
              </Col>
              <Col md={3}>
                <MaskedTextField
                  required={isRequiredConjuge1}
                  label='Telefone'
                  mask='(99) 99999-9999'
                  {...getCommonFieldProps('socioAdmConjugeComercialTelefone')}
                />
              </Col>
              <Col md={4}>
                <TextField
                  type='number'
                  label='Ramal'
                  {...getCommonFieldProps('socioAdmConjugeComercialTelefoneRamal')}
                />
              </Col>
            </Form.Row>
          </>
        )}

        <FormDivider />

        <Row>
          <Col>
            <FormSubTitle>Avalista</FormSubTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Nome'
              required={requiredAvalista}
              {...getCommonFieldProps('avalistaNome')}
            />
          </Col>
          <Col md={6}>
            <MaskedTextField
              label='CPF'
              required={requiredAvalista}
              mask='999.999.999-99'
              {...getCommonFieldProps('avalistaCpf')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              required={requiredAvalista}
              label='E-mail'
              {...getCommonFieldProps('avalistaEmail')}
            />
          </Col>
          <Col md={3}>
            <MaskedTextField
              required={requiredAvalista}
              label='Telefone'
              mask='(99) 99999-9999'
              {...getCommonFieldProps('avalistaTelefone')}
            />
          </Col>
          <Col md={3}>
            <SelectField
              required={requiredAvalista}
              label='Estado Civil'
              options={estadoCivilOptions}
              {...getCommonFieldProps('avalistaEstadoCivil')}
              onChange={onChangeAvalistaEstadoCivil}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={3}>
            <MaskedTextField
              required={requiredAvalista}
              label='CEP'
              mask='99999-999'
              {...getCommonFieldProps('avalistaCep')}
            />
          </Col>

          <Col md={5}>
            <TextField
              required={requiredAvalista}
              label='Logradouro'
              {...getCommonFieldProps('avalistaEndereco')}
            />
          </Col>

          <Col md={4}>
            <TextField
              required={requiredAvalista}
              type='string'
              label='Número'
              maxLength={5}
              {...getCommonFieldProps('avalistaNumero')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={5}>
            <TextField
              required={requiredAvalista}
              label='Bairro'
              {...getCommonFieldProps('avalistaBairro')}
            />
          </Col>

          <Col md={3}>
            <SelectField
              required={requiredAvalista}
              label='Estado'
              options={estadosApiSafraOptions}
              {...getCommonFieldProps('avalistaEstado')}
              onChange={onChangeEstado}
            />
          </Col>

          <Col md={4}>
            <SearchField
              required={requiredAvalista}
              label='Cidade'
              elementRef={avalistaCidadeRef}
              options={cidadeOptions}
              {...getCommonFieldProps('avalistaCidade')}
              onSearch={onSearchCidade('avalistaCidade')}
              onChange={onChangeCidade('avalistaCidade')}
              onBlur={onBlurCidade('avalistaCidade')}
              description='Para pesquisar uma cidade, selecione um "Estado" antes'
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={12}>
            <TextField
              label='Complemento'
              maxLength={formMaxLength}
              {...getCommonFieldProps('avalistaComplemento')}
            />
          </Col>
        </Form.Row>

        {formik.values.avalistaEstadoCivil === estadoCivilCasado && (
          <>
            <FormDivider />

            <Row>
              <Col>
                <FormSubTitle>Informações do Cônjuge</FormSubTitle>
              </Col>
            </Row>

            <Form.Row>
              <Col md={6}>
                <TextField
                  required={isRequiredAvalistaConjuge}
                  label='Nome do Cônjuge'
                  {...getCommonFieldProps('avalistaConjugeNome')}
                />
              </Col>
              <Col md={6}>
                <MaskedTextField
                  required={isRequiredAvalistaConjuge}
                  label='CPF'
                  mask='999.999.999-99'
                  {...getCommonFieldProps('avalistaConjugeCpf')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col>
                <SelectField
                  required={isRequiredConjuge2}
                  label='Ocupação'
                  options={ocupacoesOptions}
                  {...getCommonFieldProps('avalistaConjugeOcupacao')}
                  onChange={onChangeOcupacao}
                />
              </Col>
              <Col md={6}>
                <SelectField
                  required={isRequiredConjuge2}
                  label='Profissão'
                  options={profissoesAvalistaConjugeOptions}
                  {...getCommonFieldProps('avalistaConjugeProfissao')}
                  onChange={onChangeProfissao}
                />
              </Col>
            </Form.Row>

            <Form.Row>

              <Col md={6}>
                <SelectField
                  required={isRequiredConjuge2}
                  label='Cargo / Atividade'
                  options={cargosAvalistaConjugeOptions}
                  {...getCommonFieldProps('avalistaConjugeCargo')}
                  onChange={onChangeCargo}
                />
              </Col>
              <Col md={6}>
                <TextField
                  required={isAvalistaConjugeAssalariado}
                  label='Nome da Empresa'
                  {...getCommonFieldProps('avalistaConjugeComercialNome')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={4}>
                <MaskedTextField
                  required={isAvalistaConjugeAssalariado}
                  label='CNPJ'
                  mask='99.999.999/9999-99'
                  {...getCommonFieldProps('avalistaConjugeComercialCnpj')}
                />
              </Col>
              <Col md={4}>
                <MaskedCurrencyField
                  required={isRequiredConjuge2}
                  label='Renda mensal'
                  description='Ao preencher, os demais campos do cônjuge serão obrigatórios'
                  {...getCommonFieldProps('avalistaConjugeRendaMensal')}
                />
              </Col>
              <Col md={4}>
                <TextField
                  type='date'
                  label='Data admissão'
                  required={isAvalistaConjugeAssalariado}
                  {...getCommonFieldProps('avalistaConjugeComercialAdmissao')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={3}>
                <MaskedTextField
                  label='CEP'
                  mask='99999-999'
                  required={isRequiredEnderecoConjuge2}
                  {...getCommonFieldProps('avalistaConjugeCep')}
                />
              </Col>

              <Col md={5}>
                <TextField
                  label='Logradouro'
                  required={isRequiredEnderecoConjuge2}
                  {...getCommonFieldProps('avalistaConjugeEndereco')}
                />
              </Col>

              <Col md={4}>
                <TextField
                  type='string'
                  label='Número'
                  maxLength={5}
                  required={isRequiredEnderecoConjuge2}
                  {...getCommonFieldProps('avalistaConjugeNumero')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={5}>
                <TextField
                  label='Bairro'
                  required={isRequiredEnderecoConjuge2}
                  {...getCommonFieldProps('avalistaConjugeBairro')}
                />
              </Col>

              <Col md={3}>
                <SelectField
                  label='Estado'
                  options={estadosApiSafraOptions}
                  required={isRequiredEnderecoConjuge2}
                  {...getCommonFieldProps('avalistaConjugeEstado')}
                  onChange={onChangeEstado}
                />
              </Col>

              <Col md={4}>
                <SearchField
                  label='Cidade'
                  elementRef={conjugeCidadeRef}
                  options={cidadeOptions}
                  required={isRequiredEnderecoConjuge2}
                  {...getCommonFieldProps('avalistaConjugeCidade')}
                  onSearch={onSearchCidade('avalistaConjugeCidade')}
                  onChange={onChangeCidade('avalistaConjugeCidade')}
                  onBlur={onBlurCidade('avalistaConjugeCidade')}
                  description='Para pesquisar uma cidade, selecione um "Estado" antes'
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={5}>
                <TextField
                  label='Complemento'
                  maxLength={formMaxLength}
                  {...getCommonFieldProps('avalistaConjugeComplemento')}
                />
              </Col>
              <Col md={3}>
                <MaskedTextField
                  required={isRequiredConjuge2}
                  label='Telefone'
                  mask='(99) 99999-9999'
                  {...getCommonFieldProps('avalistaConjugeComercialTelefone')}
                />
              </Col>
              <Col md={4}>
                <TextField
                  type='number'
                  label='Ramal'
                  {...getCommonFieldProps('avalistaConjugeComercialTelefoneRamal')}
                />
              </Col>
            </Form.Row>
          </>
        )}

        <FormDivider />

        {segundoAvalista === true && (
          <>
            <Row>
              <Col>
                <FormSubTitle>Segundo Avalista</FormSubTitle>
              </Col>
            </Row>

            <Form.Row>
              <Col md={6}>
                <TextField
                  label='Nome'
                  required={segundoAvalista}
                  {...getCommonFieldProps('avalista2Nome')}
                />
              </Col>
              <Col md={6}>
                <MaskedTextField
                  label='CPF'
                  required={segundoAvalista}
                  mask='999.999.999-99'
                  {...getCommonFieldProps('avalista2Cpf')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={6}>
                <TextField
                  label='E-mail'
                  required={segundoAvalista}
                  {...getCommonFieldProps('avalista2Email')}
                />
              </Col>
              <Col md={3}>
                <MaskedTextField
                  label='Telefone'
                  required={segundoAvalista}
                  mask='(99) 99999-9999'
                  {...getCommonFieldProps('avalista2Telefone')}
                />
              </Col>
              <Col md={3}>
                <SelectField
                  label='Estado Civil'
                  required={segundoAvalista}
                  options={estadoCivilOptions}
                  {...getCommonFieldProps('avalista2EstadoCivil')}
                  onChange={onChangeAvalista2EstadoCivil}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={3}>
                <MaskedTextField
                  label='CEP'
                  required={segundoAvalista}
                  mask='99999-999'
                  {...getCommonFieldProps('avalista2Cep')}
                />
              </Col>

              <Col md={5}>
                <TextField
                  label='Logradouro'
                  required={segundoAvalista}
                  {...getCommonFieldProps('avalista2Endereco')}
                />
              </Col>

              <Col md={4}>
                <TextField
                  type='string'
                  label='Número'
                  required={segundoAvalista}
                  maxLength={5}
                  {...getCommonFieldProps('avalista2Numero')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={5}>
                <TextField
                  label='Bairro'
                  required={segundoAvalista}
                  {...getCommonFieldProps('avalista2Bairro')}
                />
              </Col>

              <Col md={3}>
                <SelectField
                  label='Estado'
                  required={segundoAvalista}
                  options={estadosApiSafraOptions}
                  {...getCommonFieldProps('avalista2Estado')}
                  onChange={onChangeEstado}
                />
              </Col>

              <Col md={4}>
                <SearchField
                  label='Cidade'
                  required={segundoAvalista}
                  elementRef={avalista2CidadeRef}
                  options={cidadeOptions}
                  {...getCommonFieldProps('avalista2Cidade')}
                  onSearch={onSearchCidade('avalista2Cidade')}
                  onChange={onChangeCidade('avalista2Cidade')}
                  onBlur={onBlurCidade('avalista2Cidade')}
                  description='Para pesquisar uma cidade, selecione um "Estado" antes'
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={12}>
                <TextField
                  label='Complemento'
                  maxLength={formMaxLength}
                  {...getCommonFieldProps('avalista2Complemento')}
                />
              </Col>
            </Form.Row>

            <FormDivider />
          </>
        )}

        {renderSegundoAvalistaConjuge()}

        <Form.Row>
          <Col>
            <Form.Group controlId='segundoAvalista'>
              <Form.Check
                type='switch'
                label='Desejo adicionar um segundo Avalista'
                onChange={onChangeSegundoAvalista}
                checked={segundoAvalista}
              />
            </Form.Group>
          </Col>
        </Form.Row>

        <ButtonsContainer>
          <ConfirmButton
            active={true}
            actionFn={onClickBackToList}
            message={confirmationMessage}
            variant={Variant.SECONDARY}
          >
            Cancelar
          </ConfirmButton>
          <ConfirmButton
            active={true}
            actionFn={formik.handleSubmit}
            message={getConfirmationMessage()}
            variant={Variant.PRIMARY}
            isLoading={repositoryProjetos.isLoading}
          >
            {isReplicate ? 'Replicar' : 'Salvar'}
          </ConfirmButton>
        </ButtonsContainer>
      </Form>
    </>
  )
}

export default CreateProject

import { formatDateToServer, removeTimeFromDateTime } from 'utils/helpers'

export const populateForms = (projeto: any) => {
  const nullSafeString = (value?: string | number) => (value ? String(value) : '')

  const { client } = projeto
  const {
    address,
    personalInformation,
    identificationDocument,
    occupationInformation,
    financialInformation
  } = client

  let data: TProjetoForm

  data = {
    tipoFinanciamento: nullSafeString(projeto.typeFinancing.id),
    finalidadeProjeto: nullSafeString(projeto.projectPurpose.id),
    nomeProjeto: nullSafeString(projeto.projectName),
    potenciaProjeto: nullSafeString(projeto.estimatedPowerOfProject),
    valorProjeto: nullSafeString(projeto.estimatedValueOfProject),
    estadoProjeto: nullSafeString(projeto.state.id),
    cidadeProjeto: nullSafeString(projeto.city.description),
    cidadeProjetoId: nullSafeString(projeto.city.id),
    integrador: projeto.integrator
      ? nullSafeString(`${projeto.integrator?.companyName} - ${projeto.integrator?.cnpj}`)
      : '',
    integradorId: nullSafeString(projeto.integrator?.id),
    nomeCompleto: nullSafeString(client.fullName),
    dataNascimento: nullSafeString(formatDateToServer(client.birthDate)),
    email: nullSafeString(client.email),
    celular: nullSafeString(client.celular),
    cep: nullSafeString(address.zipCode),
    endereco: nullSafeString(address.street),
    numero: nullSafeString(address.number),
    bairro: nullSafeString(address.neighborhood),
    complemento: nullSafeString(address.complement),
    cidade: nullSafeString(address.city.description),
    cidadeId: nullSafeString(address.city.id),
    estado: nullSafeString(address.state.id),
    tipoResidencia: nullSafeString(address.addressType.id),
    nacionalidade: nullSafeString(personalInformation.nationality.description),
    nacionalidadeId: nullSafeString(personalInformation.nationality.id),
    cidadeNaturalidade: nullSafeString(personalInformation.hometown?.description),
    cidadeNaturalidadeId: nullSafeString(personalInformation.hometown?.id),
    estadoNaturalidade: nullSafeString(personalInformation.homestate?.id),
    escolaridade: nullSafeString(personalInformation.scholarity.id),
    sexo: nullSafeString(personalInformation.sex),
    estadoCivil: nullSafeString(personalInformation.maritalStatus.id),
    cpf: nullSafeString(personalInformation.cpf),
    nomeMae: nullSafeString(personalInformation.mothersName),
    tipoDocumento: nullSafeString(identificationDocument.identificationDocumentType.id),
    documento: nullSafeString(identificationDocument.documentNumber),
    orgaoEmissor: nullSafeString(identificationDocument.documentEmmiterInstitution?.id),
    estadoOrgaoEmissor: nullSafeString(identificationDocument.uf?.id),
    dataEmissao: nullSafeString(formatDateToServer(identificationDocument.documentEmissionDate)),
    ocupacao: nullSafeString(occupationInformation.professionNature.id),
    profissao: nullSafeString(occupationInformation.profession.id),
    cargo: nullSafeString(occupationInformation.occupationRole.id),
    rendaMensal: nullSafeString(financialInformation.monthlyIncome),
    patrimonio: nullSafeString(financialInformation.patrimony.id),
    comercialCnpj: nullSafeString(client.occupationInformation.companyInformation.cnpj),
    comercialNome: nullSafeString(client.occupationInformation.companyInformation.companyName),
    comercialAdmissao: removeTimeFromDateTime(nullSafeString(client.occupationInformation.companyInformation.admissionDate)),
    comercialTelefone: nullSafeString(client.occupationInformation.companyInformation.comercialPhone),
    comercialTelefoneRamal: nullSafeString(client.occupationInformation.companyInformation.phoneExtension),
    comercialEnderecoObrigatorio: 'false',
    comercialCep: nullSafeString(client.occupationInformation.companyInformation.address?.zipCode),
    comercialEndereco: nullSafeString(client.occupationInformation.companyInformation.address?.street),
    comercialNumero: nullSafeString(client.occupationInformation.companyInformation.address?.number),
    comercialComplemento: nullSafeString(client.occupationInformation.companyInformation.address?.complement),
    comercialBairro: nullSafeString(client.occupationInformation.companyInformation.address?.neighborhood),
    comercialCidade: nullSafeString(client.occupationInformation.companyInformation.address?.city?.description),
    comercialCidadeId: nullSafeString(client.occupationInformation.companyInformation.address?.city?.id),
    comercialEstado: nullSafeString(client.occupationInformation.companyInformation.address?.state?.id),
    conjugeNome: nullSafeString(client.spouse?.fullName),
    conjugeCpf: nullSafeString(client.spouse?.cpf),
    conjugeComercialCnpj: nullSafeString(client.spouse?.occupationInformation?.companyInformation?.cnpj),
    conjugeComercialNome: nullSafeString(client.spouse?.occupationInformation?.companyInformation?.companyName),
    conjugeComercialAdmissao: removeTimeFromDateTime(nullSafeString(client.spouse?.occupationInformation?.companyInformation?.admissionDate)),
    conjugeComercialTelefone: nullSafeString(client.spouse?.comercialPhone),
    conjugeComercialTelefoneRamal: nullSafeString(client.spouse?.phoneExtension),
    conjugeEnderecoObrigatorio: 'false',
    conjugeComercialCep: nullSafeString(client.spouse?.address?.zipCode),
    conjugeComercialEndereco: nullSafeString(client.spouse?.address?.street),
    conjugeComercialNumero: nullSafeString(client.spouse?.address?.number),
    conjugeComercialComplemento: nullSafeString(client.spouse?.address?.complement),
    conjugeComercialBairro: nullSafeString(client.spouse?.address?.neighborhood),
    conjugeComercialCidade: nullSafeString(client.spouse?.address?.city?.description),
    conjugeComercialCidadeId: nullSafeString(client.spouse?.address?.city?.id),
    conjugeComercialEstado: nullSafeString(client.spouse?.address?.state?.id),
    conjugeRendaMensal: nullSafeString(client.spouse?.financialInformation?.monthlyIncome),
    conjugeOcupacao: nullSafeString(client.spouse?.occupationInformation?.professionNature?.id),
    conjugeProfissao: nullSafeString(client.spouse?.occupationInformation?.profession?.id),
    conjugeCargo: nullSafeString(client.spouse?.occupationInformation?.occupationRole?.id),
    enderecamentoPessoal: nullSafeString(client.address?.enderecamentoPessoal),
    enderecamentoConjugePessoal: nullSafeString(client.address?.enderecamentoConjugePessoal),
  }



  return data
}
import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const QUERY_VALIDATE_INVITATION_CODE = gql`
  query getStatusInvitationIntegratorToken ($authToken: String!) {
    getStatusInvitationIntegratorToken (authToken: $authToken) {
      ${commonResponse}
      success {
        name
        email
        integratorId
      }
    }
  }
`

export const MUTATION_REGISTER_INTEGRADOR_USER = gql`
  mutation registerNewUserFromIntegrator (
    $registerNewUserFromIntegratorCommand: RegisterUserFromIntegratorCommandInput!
  ) {
    registerNewUserFromIntegrator (
      registerNewUserFromIntegratorCommand: $registerNewUserFromIntegratorCommand
    ) {
      ${commonResponse}
    }
  }
`
import React from 'react'
import GlobalStyles from './style'
import Routes from 'routes'
import Providers from 'providers'

const App = () => (
  <Providers>
    <Routes />
    <GlobalStyles />
  </Providers>
)

export default App

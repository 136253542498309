import styled from 'styled-components'

type TBoxProps = {
  width?: number
  height?: number
  showShadow?: boolean
  compact?: boolean
  showOpacity?: boolean
}

export const Box = styled.div<TBoxProps>`
  width: 100%;
  max-width: ${p => `${p.width}px` ?? 'auto'};
  height: ${p => `${p.height}px` ?? 'auto'};
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${p => p.compact ? '10px 15px' : '30px'};
  border: ${p => p.showShadow ? 'none' : `1px solid ${p.theme.colors.LIGHT_GRAY}`};
  box-shadow: ${p => p.showShadow ? '3px 3px 6px 0 rgba(0, 0, 0, 0.25)' : 'none'};
  opacity: ${p => p.showOpacity ? 0.3 : 1};
`
import styled from 'styled-components'
import logo from 'assets/images/logoHorizontal.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  opacity: 0.6;
`

export const Logo = styled.h1`
  background: url(${logo}) no-repeat;
  background-size: contain;
  width: 400px;
  height: 100px;
  text-indent: -9999px;
`

export const Title = styled.h3`
  font-size: 22px;
  margin: 10px 0 20px 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
`
import { useState, useEffect } from 'react'
import { useQuery, useMutation, useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'

import {
  QUERY_USUARIOS,
  QUERY_USUARIO,
  QUERY_PERFIS,
  MUTATION_SAVE_USUARIO,
  MUTATION_UPDATE_USUARIO
} from 'repositories/usuariosGql'

type TListUsers = {
  usersList: TUsuarioResponse[]
}

type TUsersList = {
  listUsers: TCommonResponse & TPaginationInfo & TListUsers
}

export const useGetUsuarios = () => {
  const errorHandler = useErrorHandler()

  const [
    requestUsuarios,
    { loading, data }
  ] = useLazyQuery<TUsersList>(QUERY_USUARIOS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  const get = (params: TVariableParams) => {
    requestUsuarios({
      variables: {
        listUsersCommand: params
      },
    })
  }

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.listUsers.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(Number(description))
  }, [data])

  return {
    get,
    loading,
    usuarios: data?.listUsers.usersList,
    paginationInfo: data?.listUsers.paginationInfo
  }
}

type TProfileResponse = {
  getUser: TCommonResponse & {
    user: TUsuarioResponse
  }
}

export const useGetUsuario = () => {
  const errorHandler = useErrorHandler()

  const [
    requestUsuario,
    { loading, data }
  ] = useLazyQuery<TProfileResponse>(QUERY_USUARIO, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  const get = (userId: string) => {
    requestUsuario({
      variables: {
        getUserCommand: {
          id: userId
        }
      },
    })
  }

  useEffect(() => {
    if (!data) return
    const { isError, description } = data?.getUser.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(Number(description))
  }, [data])

  return {
    get,
    loading,
    usuario: data?.getUser.user
  }
}


type TProfile = {
  id: string,
  name: string,
  status: string,
}

type TProfileList = {
  profileList: TProfile[]
}

type TListProfiles = {
  listProfiles: TCommonResponse & TPaginationInfo & TProfileList
}

type TVariableParams = {
  id?: string,
  name?: string,
  status?: string,
  paginate: {
    pageNumber: number,
    numberOfRecordsByPage: number,
  }
}

export const useGetProfiles = (initialParams: TVariableParams) => {
  const [params, setParams] = useState<TVariableParams>(initialParams)
  const errorHandler = useErrorHandler()

  const { loading, data } = useQuery<TListProfiles>(QUERY_PERFIS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
    variables: {
      listProfilesCommand: {
        ...params
      }
    },
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.listProfiles.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(Number(description))
  }, [data])

  const perfisOptions = data?.listProfiles.profileList.map(profile => ({
    label: profile.name,
    value: profile.id
  }))

  const defineParams = (_params: TVariableParams) => setParams(_params)

  return {
    defineParams,
    loading,
    perfis: data?.listProfiles.profileList,
    perfisOptions
  }
}

type TSaveUsuario = {
  registerNewUser: TCommonResponse
}


export const usePostUsuario = () => {
  const errorHandler = useErrorHandler()

  const [
    saveUser,
    { loading, data }
  ] = useMutation<TSaveUsuario>(MUTATION_SAVE_USUARIO, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  const save = (body: TUsuarioRequest) => {
    saveUser({
      variables: {
        registerUserCommand: { user: { ...body } }
      }
    })
  }

  useEffect(() => {
    if (!data) return
    const { isError, statusCode } = data.registerNewUser.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(statusCode)
  }, [data])

  return {
    loading,
    response: data?.registerNewUser.commonResponse,
    save
  }
}

type TUpdateUsuario = {
  updateUser: TCommonResponse
}

export const useUpdateUsuario = () => {
  const errorHandler = useErrorHandler()

  const [
    updateUsuario,
    { loading, data }
  ] = useMutation<TUpdateUsuario>(MUTATION_UPDATE_USUARIO, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error),
  })

  const update = (body: TUsuarioRequest) => {
    updateUsuario({
      variables: {
        updateUserCommand: { user: { ...body } }
      }
    })
  }

  useEffect(() => {
    if (!data) return
    const { isError, statusCode } = data.updateUser.commonResponse
    if (isError) errorHandler.handleApiErrorDesambiguation(statusCode)
  }, [data])

  return {
    loading,
    response: data?.updateUser.commonResponse,
    update
  }
}
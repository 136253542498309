import { gql } from 'apollo-boost'
import { commonResponse, paginationInfo } from './constants'

export const QUERY_USINAS = gql`
  query findPowerPlantByFilter (
      $findPowerPlantByFilterCommand: FindPowerPlantByFilterCommand, 
      $paginate: Paginate
    ) {
    findPowerPlantByFilter (
      findPowerPlantByFilterCommand: $findPowerPlantByFilterCommand, 
      paginate: $paginate
      ) {
      ${commonResponse}
      ${paginationInfo}
      powerPlantSuccess { id, name }
    }
  }
`

export const QUERY_CONDOMINIOS = gql`
  query findCondominiumByFilter (
      $findCondominiumByFilterCommand: FindCondominiumByFilterCommand, 
      $paginate: Paginate
    ) {
      findCondominiumByFilter (
      findCondominiumByFilterCommand: $findCondominiumByFilterCommand, 
      paginate: $paginate
      ) {
      ${commonResponse}
      ${paginationInfo}
      condominiumSuccess { 
        id
        name
        cnpj
        isActive
        accounts {
          id
          bank
          agency
          agencyDigit
          account
          accountDigit
          isMainAccount
        }
        powerPlants {
          id
          name
        }
        allocatedClients {
          fullName
          cpf
          companyName
          cnpj
        }
      }
    }
  }
`

export const QUERY_CONDOMINIOS_USINAS = gql`
  query findCondominiumByFilter (
      $findCondominiumByFilterCommand: FindCondominiumByFilterCommand, 
      $paginate: Paginate
    ) {
      findCondominiumByFilter (
        findCondominiumByFilterCommand: $findCondominiumByFilterCommand, 
        paginate: $paginate
      ) {
      ${commonResponse}
      condominiumSuccess { 
        id
        name
        powerPlants {
          id
          name
          remainingPercentToContract
        }
      }
    }
  }
`

export const QUERY_CONDOMINIO = gql`
  query findCondominiumById ($id: Long!) {
    findCondominiumById (id: $id) {
      ${commonResponse}
      condominiumSuccess { 
        id
        name
        cnpj
        isActive
        accounts {
          id
          bank
          agency
          agencyDigit
          account
          accountDigit
          isMainAccount
        }
        powerPlants {
          id
          name
        }
      }
    }
  }
`

export const MUTATION_CONDOMINIO = gql`
    mutation registerOrUpdateCondominium (
      $registerCondominium: RegisterCondominiumCommand
    ) {
      registerOrUpdateCondominium (registerCondominium: $registerCondominium) {
        ${commonResponse}
      }
    }
`
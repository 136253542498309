import styled from 'styled-components'

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  font-weight: 700;
  text-decoration: underline;

  :hover {
    color: ${({ theme }) => theme.colors.DARK_GRAY};
  }
`
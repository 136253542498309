import styled from 'styled-components'
import { Col } from 'react-bootstrap'

export const Container = styled.div`
    padding: 5px 25px 10px 25px;
    width: 1100px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: ${({ theme }) => theme.borderRadius};
    border-width: 0.5px;
    border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 25px;
    @media (max-width: 1400px) {
        width: auto;
    }
`

export const CustomCol = styled(Col)`
    font-size: 18px;
    padding-bottom: 7.5px;
`
export const CustomColButton = styled(Col)`
    font-size: 18px;
    align-self: center;
    padding-top: 7.5px;
`

export const Label = styled.b`
     font-size: 20px;
`

export const SubTitle = styled.h3`
  font-size: 24px;
  margin: 10px 0 20px 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.ORANGE};
`
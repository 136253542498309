import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom'
import { useFormik } from 'formik'
import { Form, Col, Row, Modal } from 'react-bootstrap'
import { TypeaheadModel, AsyncTypeahead } from 'react-bootstrap-typeahead'
import * as yup from 'yup'
import { useToasts } from 'react-toast-notifications'

import { useCreateUpdateUsina, useGetUsina } from 'repositories/usinas'
import ContasBancarias from 'components/ContasBancarias'
import { useGetDistribuidorasSelect } from 'repositories/distribuidoras'
import useInformacoes from 'repositories/useInformacoes'
import { useAuth } from 'hooks/useAuth'
import {
  FormTitle,
  PageHeader,
  TextField,
  SearchField,
  SelectField,
  FormDivider,
  ButtonsContainer,
  GoBackButton,
  ConfirmButton,
  PrimaryButton,
  SecondaryButton,
  NumberField
} from 'components'
import { Variant, confirmationMessage } from 'utils/constants'
import { formatDateToServer, removeTimeFromDateTime, sleep } from 'utils/helpers'
import { ApiErrorMessages } from 'hooks/useErrorHandler'

const distribuidorasParams: TListaDistribuidorasParams = {
  findPowerCompanyByFilterCommand: {},
  paginate: {
    pageNumber: 0,
    numberOfRecordsByPage: 1000
  }
}

const toastCreateSucessMessage = 'Usina adicionada com sucesso!'
const toastUpdateSucessMessage = 'Usina editada com sucesso!'

const validationSchema = yup.object().shape({
  nome: yup.string()
    .required('O campo "Nome" é obrigatório')
    .max(100, 'Insira um nome com menos de 100 dígitos'),
  unidadeConsumidora: yup.number()
    .min(0, 'Insira um número maior ou igual a zero')
    .max(99999999999, 'Insira um número com menos de 11 dígitos')
    .required('O campo "Unidade Consumidora" é obrigatório'),
  dataInicioOperacao: yup.date()
    .required('O campo "Data Início Operação" é obrigatório'),
  potencia: yup.number()
    .min(0, 'Insira um número maior ou igual a zero')
    .max(999999999999999, 'Insira um número com menos de 15 dígitos')
    .required('O campo "Potência" é obrigatório'),
  nomeImovelRural: yup.string()
    .required('O campo "Nome do Imóvel Rural" é obrigatório')
    .max(100, 'Insira um nome com menos de 100 dígitos'),
  cidade: yup.string()
    .required('O campo "Cidade" é obrigatório')
    .max(100, 'Insira uma cidade com menos de 100 dígitos'),
  estado: yup.string().required('O campo "Estado" é obrigatório'),
  distribuidoraId: yup.string()
    .required('O campo "Nome da Distribuidora" é obrigatório'),
  diaLeitura: yup.number()
    .positive('Insira um dia do mês válido')
    .integer('Insira um dia do mês válido')
    .max(31, 'Insira um dia do mês válido')
    .required('O campo "Dia de Leitura" é obrigatório'),
  diaProducao: yup.number()
    .positive('Insira um dia do mês válido')
    .integer('Insira um dia do mês válido')
    .max(31, 'Insira um dia do mês válido')
    .required('O campo "Dia de Produção" é obrigatório')
})

type TUsinaForm = {
  nome: string
  isAtiva: string
  unidadeConsumidora: string
  dataInicioOperacao: string
  potencia: string
  nomeImovelRural: string
  cidade: string
  estado: string
  distribuidoraId: string
  diaLeitura: string
  diaProducao: string
}

const initialValues: TUsinaForm = {
  nome: '',
  isAtiva: 'true',
  unidadeConsumidora: '',
  dataInicioOperacao: '',
  potencia: '',
  nomeImovelRural: '',
  cidade: '',
  estado: '',
  distribuidoraId: '',
  diaLeitura: '',
  diaProducao: ''
}

const UsinasFormulario = () => {
  const [contasBancarias, setContasBancarias] = useState<TAccount[]>([])
  const [estadoOptions, setEstadoOptions] = useState<TSelectOption[]>([])
  const [cidadeOptions, setCidadeOptions] = useState<TypeaheadModel[]>([])
  const [distribuidoraOptions, setDistribuidoraOptions] = useState<TSelectOption[]>([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isAtiva, setIsAtiva] = useState(true)

  const { id } = useParams<{ id: string}>()
  const location = useLocation()
  const history = useHistory()
  const { addToast } = useToasts()
  const createUpdateUsina = useCreateUpdateUsina()
  const getUsina = useGetUsina()
  const getDistribuidoras = useGetDistribuidorasSelect()
  const { getEstados, getCidades } = useInformacoes()
  const cidadeRef = useRef<AsyncTypeahead<string>>(null)
  const { userData, userPermissions, hasFormPermission } = useAuth()
  const inputTagRef = useRef<HTMLInputElement>(null)

  const permission = useMemo(() => {
    const _permission = userPermissions?.usinas
    return hasFormPermission(_permission!, location.pathname)
  }, [userPermissions])

  useEffect(() => {
    const requestEstados = async () => {
      const estados = await getEstados()
      if (!estados) return

      const _estadoOptions = estados.map(estado => ({
        label: estado.name,
        value: estado.name,
        id: estado.id
      }))

      setEstadoOptions(_estadoOptions)
    }

    requestEstados()
    getDistribuidoras.get(distribuidorasParams)
  }, [])

  useEffect(() => {
    if (id) getUsina.get(id)
  }, [id])

  useEffect(() => {
    if(!isAtiva) history.push(`/usinas/visualizar/${id}`)
  }, [isAtiva])

  useEffect(() => {
    if (!getUsina.usina) return

    const { usina } = getUsina
    const { powerPlantAddress } = usina
    const _usina: TUsinaForm = {
      nome: usina.name,
      isAtiva: String(usina.isActive),
      unidadeConsumidora: String(usina.consumerUnit),
      dataInicioOperacao: removeTimeFromDateTime(usina.operationStartDate),
      potencia: String(usina.power),
      nomeImovelRural: powerPlantAddress.ruralName,
      cidade: powerPlantAddress.city,
      estado: powerPlantAddress.state,
      distribuidoraId: String(usina.powerCompany.id),
      diaLeitura: String(usina.powerCompanyReadingDay),
      diaProducao: String(usina.powerPlantProductionReadingDay),
    }
    setIsAtiva(usina.isActive)
    if(usina.accounts) setContasBancarias(usina.accounts)
    formik.setValues(_usina, true)
  }, [getUsina.usina])

  const focusInputTagAlreadyUsed = async () => {
    if((createUpdateUsina.description === ApiErrorMessages.USINA_TAG)) {
      await sleep(500)
      inputTagRef.current?.focus()
    }
  }

  useEffect(() => {
    if (createUpdateUsina.data) handleHideConfirmationModal()

    if (!createUpdateUsina.data || createUpdateUsina.isError){
      focusInputTagAlreadyUsed()
      return
    }

    history.push('/usinas')
    addToast(
      id ? toastUpdateSucessMessage : toastCreateSucessMessage,
      { appearance: 'success', autoDismiss: true }
    )
  }, [createUpdateUsina.data])

  useEffect(() => {
    const populateDistribuidoras = () => {
      if (!getDistribuidoras.distribuidoras) return
      const _distribuidoraOptions = getDistribuidoras.distribuidoras.map(distribuidora => ({
        label: distribuidora.name,
        value: String(distribuidora.id)
      }))
      setDistribuidoraOptions(_distribuidoraOptions)
    }
    populateDistribuidoras()
  }, [getDistribuidoras.distribuidoras])

  const handleSubmit = () => {
    if(!hasMainAccount(contasBancarias)){
      addToast(
        'É necessário uma Conta Principal para cadastrar uma Usina.',
        { appearance: 'error', autoDismiss: true }
      )
      return
    }
    const { values } = formik
    const usinaBody: TUsinaInfoBody = {
      id: Number(id) ?? null,
      name: values.nome,
      isActive: JSON.parse(values.isAtiva),
      power: Number(values.potencia),
      operationStartDate: formatDateToServer(values.dataInicioOperacao),
      consumerUnit: Number(values.unidadeConsumidora),
      powerCompany: Number(values.distribuidoraId),
      powerCompanyReadingDay: Number(values.diaLeitura),
      powerPlantProductionReadingDay: Number(values.diaProducao),
      powerPlantAddress: {
        ruralName: values.nomeImovelRural,
        city: values.cidade,
        state: values.estado
      },
      accounts: contasBancarias
    }

    createUpdateUsina.send(usinaBody)
  }

  const hasMainAccount = (accounts: TAccount[] ) => {
    if( accounts.filter( e => JSON.parse(e.isMainAccount) === true).length > 0)
      return true
    return false
  }

  const updateContasBancarias = (values: TAccount[]) => {
    setContasBancarias(values)
  }
  const handleShowConfirmationModal = () => setShowConfirmationModal(true)
  const handleHideConfirmationModal = () => setShowConfirmationModal(false)

  const formik = useFormik<TUsinaForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: id ? handleSubmit : handleShowConfirmationModal
  })

  const hasError = (name: keyof TUsinaForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TUsinaForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const onClickGoToLista = () => history.push('/usinas')

  const onChangeEstado = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('estado', value, true)
    formik.setFieldValue('cidade', '')
    if (cidadeRef.current) cidadeRef.current.clear()
  }

  const onSearchCidade = (term: string) => {
    if (!formik.values.estado || term.length < 3) return

    const requestCidades = async () => {
      const _estado = estadoOptions.find(estado => (
        estado.value === formik.values.estado
      ))

      const params = {
        search: term,
        stateId: String(_estado!.id)
      }
      const cidades = await getCidades(params)
      if (!cidades) return

      const _cidadeOptions = cidades.map(cidade => ({
        label: cidade.description,
        value: cidade.description
      }))

      setCidadeOptions(_cidadeOptions)
    }

    requestCidades()
  }

  const onChangeCidade = ([cidade]: TSelectOption[]) => {
    const value = cidade?.value ?? ''
    formik.setFieldValue('cidade', value, true)
  }

  const onBlurCidade = () => {
    formik.setFieldTouched('cidade', true)
    if (!formik.values.cidade) cidadeRef.current?.clear()
  }

  const isVisualizando = useMemo(() => {
    const urlAction = location.pathname.split('/')[2]
    return urlAction === 'visualizar'
  }, [location.pathname])

  const headerTitleAction = useMemo(() => {
    if (isVisualizando) return 'Visualizar'

    return id ? 'Editar' : 'Adicionar'
  }, [isVisualizando, id])

  const tagConfirmationMessage = `
    Você inseriu "${formik.values.unidadeConsumidora}" como sua Unidade Consumidora. Essa informação não poderá ser 
    alterada depois de salva. Tem certeza que deseja continuar?
  `
  if (userData && !permission) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={!isVisualizando}
            route='/usinas'
            message={confirmationMessage}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PageHeader title={`${headerTitleAction} Usina`} />
        </Col>
      </Row>

      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Col md={8}>
            <TextField
              label='Nome'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('nome')}
            />
          </Col>

          <Col md={4}>
            <NumberField
              label='Unidade Consumidora'
              required
              disabled={Boolean(id)}
              {...getCommonFieldProps('unidadeConsumidora')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Início da Operação'
              type='date'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('dataInicioOperacao')}
            />
          </Col>

          <Col md={6}>
            <NumberField
              required
              min={0}
              decimalScale={2}
              label='Potência (KWp)'
              disabled={isVisualizando}
              {...getCommonFieldProps('potencia')}
            />
          </Col>

        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormTitle>Localização</FormTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={5}>
            <TextField
              required
              label='Nome do Imóvel Rural'
              disabled={isVisualizando}
              {...getCommonFieldProps('nomeImovelRural')}
            />
          </Col>

          <Col md={2}>
            <SelectField
              required
              label='Estado'
              options={estadoOptions}
              disabled={isVisualizando}
              {...getCommonFieldProps('estado')}
              onChange={onChangeEstado}
            />
          </Col>

          <Col md={5}>
            <SearchField
              required
              label='Cidade'
              elementRef={cidadeRef}
              options={cidadeOptions}
              disabled={isVisualizando}
              {...getCommonFieldProps('cidade')}
              onSearch={onSearchCidade}
              onChange={onChangeCidade}
              onBlur={onBlurCidade}
              value={formik.values.cidade}
            />
          </Col>
        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormTitle>Distribuidora</FormTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={4}>
            <SelectField
              required
              label='Empresa'
              disabled={isVisualizando}
              options={distribuidoraOptions}
              {...getCommonFieldProps('distribuidoraId')}
            />
          </Col>

          <Col md={4}>
            <NumberField
              required
              max={31}
              label='Dia de Leitura'
              disabled={isVisualizando}
              {...getCommonFieldProps('diaLeitura')}
            />
          </Col>

          <Col md={4}>
            <NumberField
              required
              max={31}
              label='Dia de Produção'
              disabled={isVisualizando}
              {...getCommonFieldProps('diaProducao')}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <FormTitle>Dados Bancários</FormTitle>
          </Col>
        </Form.Row>
        <ContasBancarias
          accounts={contasBancarias}
          updateContasBancarias={updateContasBancarias}
          isDisabled={isVisualizando}
        />

        <ButtonsContainer>
          <ConfirmButton
            active={!isVisualizando}
            actionFn={onClickGoToLista}
            message={confirmationMessage}
            variant={Variant.SECONDARY}
          >
            {isVisualizando ? 'Voltar' : 'Cancelar' }
          </ConfirmButton>

          {!isVisualizando && (
            <PrimaryButton type='submit' isLoading={createUpdateUsina.isLoading}>
              <span>Salvar</span>
            </PrimaryButton>
          )}

          <Modal
            size='sm'
            show={showConfirmationModal}
            onHide={handleHideConfirmationModal}
          >
            <Modal.Body>
              {tagConfirmationMessage}
            </Modal.Body>

            <Modal.Footer>
              <ButtonsContainer compact>
                <SecondaryButton onClick={handleHideConfirmationModal}>
                  Não
                </SecondaryButton>

                <PrimaryButton
                  onClick={handleSubmit}
                  isLoading={createUpdateUsina.isLoading}
                >
                  Sim
                </PrimaryButton>
              </ButtonsContainer>
            </Modal.Footer>
          </Modal>
        </ButtonsContainer>
      </Form>
    </>
  )
}

export default UsinasFormulario

import React, { useState, useMemo } from 'react'
import {
  PageHeader,
  PageOptions
} from 'components'
import Cadastro from './Cadastro'
import CondominiosUsinas from './CondominiosUsinas'

enum PageOptionsLabels {
  CADASTRO = 'Cadastro',
  CONDOMINIOS_USINAS = 'Condomínios / Usinas'
}

const initialPageOptions: TPageOption[] = [
  {
    label: PageOptionsLabels.CADASTRO,
    isActive: true,
  },
  {
    label: PageOptionsLabels.CONDOMINIOS_USINAS,
    isActive: false,
  },
]

const MeusDados = () => {
  const [pageOptions, setPageOptions] = useState<TPageOption[]>(initialPageOptions)

  const chooseOption = (index: number) => {
    const _pageOptions = pageOptions.map((option, _index) => ({
      ...option,
      isActive: _index === index
    }))
    setPageOptions(_pageOptions)
  }

  const chosenOption = useMemo(() => (
    pageOptions.find(option => option.isActive)?.label
  ), [pageOptions])

  return (
    <>
      <PageHeader title='Meus Dados'>
        <PageOptions options={pageOptions} chooseOption={chooseOption} />
      </PageHeader>

      {chosenOption === PageOptionsLabels.CADASTRO
        ? <Cadastro />
        : <CondominiosUsinas />
      }
    </>
  )
}

export default MeusDados
import { gql } from 'apollo-boost'
import { commonResponse, paginationInfo } from './constants'

export const QUERY_FUNCIONALIDADES = gql`
  query getFuncionalidades {
    listFunctionalities {
      ${commonResponse}
      listFunctionalitiesResult {
        id,
        name,
        order,
        permissions {
          id
          name
          permissionType {
            id
            name
          }
        },
      }
    }
  }
`

export const MUTATION_CREATE_PROFILE = gql`
    mutation registerNewProfile ($registerProfileCommand: RegisterProfileCommand) {
      registerNewProfile (registerProfileCommand: $registerProfileCommand) {
        ${commonResponse}
      }
    }
`

export const QUERY_PROFILES = gql`
query listProfiles ($listProfilesCommand: ListProfilesCommand) {
  listProfiles (listProfilesCommand: $listProfilesCommand) {
    ${commonResponse}
    ${paginationInfo}
    profileList {
      id,
      name,
      status
    }
  }
}
`

export const QUERY_PROFILE = gql`
  query getProfile ($getProfileCommand: GetProfileCommand) {
    getProfile (getProfileCommand: $getProfileCommand) {
      ${commonResponse}
      usersAddedToThisProfile
      profile {
        id
        name
        status
        permissions {
            id
            permissionType {
                id
            }
        }
      }
    }
  }
`

export const MUTATION_UPDATE_PROFILE = gql`
    mutation updateProfile ($updateProfileCommand: UpdateProfileCommand) {
      updateProfile (updateProfileCommand: $updateProfileCommand) {
        ${commonResponse}
      }
    }
`
import { useToasts, Options } from 'react-toast-notifications'
import { useAuth } from 'hooks/useAuth'
import { getApiErroMessage } from 'utils/helpers'

enum ApiErrorCodes {
  INVALID_TOKEN = 401
}

export enum ApiErrorMessages {
  TIMEOUT = 'Request timed out',
  INVALID_TOKEN = 'Auth token is not recognized',
  INVALID_CREDENTIALS = 'Invalid email or password',
  USINA_TAG = 'Tag already used',
  DUPLICATE_EMAIL = 'Client is already registered for this email',
  DUPLICATE_CPF = 'Client is already registered for this CPF',
  DUPLICATE_CNPJ = 'Client is already registered for this CNPJ',
  DUPLICATE_CEMIG_LOGIN = 'Client is already registered for this login CEMIG',
  ZIP_CODE_NOT_FOUND = 'ZipCode not found',
  CONDO_DUPLICATE_CNPJ = 'CNPJ is already registered',
  EXPIRED_TOKEN = 'Expired token',
  INVALID_USER_TOKEN = 'Invalid token',
  INACTIVE_USER = 'User not activated',
  INVALID_USER_INTEGRATOR_IS_PENDING = 'Integrator associated with this user is pending, expired or inactive.',
  POWER_COMPANY_HAS_POWER_PLANT = 'Power company has power plants assigned',
  SERVICE_UNAVAILABLE = '503 Service Unavailable from POST https://api.safrafinanceira.com.br/apl-api-captura-scd/api/v1/Token'
}

type TGraphQlErrors = {
  graphQLErrors: {
    extensions: TComonResponse
  }[]
}

const toastErrorConfig = {
  appearance: 'error',
  autoDismiss: true,
} as Options

const useErrorHandler = () => {
  const { addToast } = useToasts()
  const { removeUserData, isSignedIn } = useAuth()

  const getFirstErrorCommonResponse = (error: TGraphQlErrors) => {
    const emptyDescription = { description: '' }
    if (!error.graphQLErrors.length) return emptyDescription

    return (
      error.graphQLErrors[0].extensions
        ? error.graphQLErrors[0].extensions.commonResponse
        : emptyDescription
    )
  }

  const handleApiError = (code: number) => {
    const message = getApiErroMessage(code)
    addToast(message, toastErrorConfig)
  }

  const handleGenericError = () => {
    const message =
      'Algo de errado aconteceu. Por favor, tente novamente mais tarde!'
    addToast(message, toastErrorConfig)
  }

  const handleInvalidTokenError = () => {
    const message = 'Sua sessão foi encerrada. Por favor, faça login novamente!'
    addToast(message, toastErrorConfig)
  }

  const handleTimeoutError = () => {
    const message = 'Esta requisição excedeu o tempo de espera do sistema, favor tentar mais tarde ou diminuir o escopo da análise.'
    addToast(
      message,
      { appearance: 'error',
        autoDismiss: false, })
  }

  const handleApolloErrorDesambiguation = (error: any) => {
    const { description } = getFirstErrorCommonResponse(error as TGraphQlErrors)
    if (description === ApiErrorMessages.INVALID_TOKEN) {
      removeUserData()
      handleInvalidTokenError()
      return
    }

    if(description === ApiErrorMessages.TIMEOUT){
      handleTimeoutError()
      return
    }

    handleGenericError()
  }

  const handleApiErrorDesambiguation = (code: number) => {
    if (code === ApiErrorCodes.INVALID_TOKEN && isSignedIn) {
      removeUserData()
      handleInvalidTokenError()
      return
    }

    handleApiError(code)
  }

  const handleApiErrorUnavailable = (message: string) => {
    if (message === ApiErrorMessages.SERVICE_UNAVAILABLE && isSignedIn) {
      handleBusinessLogicError(message as ApiErrorMessages)
    }
  }

  const handleBusinessLogicError = (message: string) => {
    const getMessage = (message: ApiErrorMessages) => {
      switch (message) {
        case ApiErrorMessages.USINA_TAG:
          return 'A tag informada já está sendo utilizada em outra usina'
        case ApiErrorMessages.INVALID_CREDENTIALS:
          return 'E-mail e/ou senha inválidos'
        case ApiErrorMessages.DUPLICATE_EMAIL:
          return 'Já existe um cliente registrado com o e-mail informado'
        case ApiErrorMessages.DUPLICATE_CPF:
          return 'Já existe um cliente registrado com o CPF informado'
        case ApiErrorMessages.DUPLICATE_CNPJ:
          return 'Já existe um cliente registrado com o CNPJ informado'
        case ApiErrorMessages.DUPLICATE_CEMIG_LOGIN:
          return 'Já existe um cliente registrado com o esse mesmo login na CEMIG'
        case ApiErrorMessages.ZIP_CODE_NOT_FOUND:
          return 'CEP não encontrado'
        case ApiErrorMessages.CONDO_DUPLICATE_CNPJ:
          return 'Já existe um condomínio registrado com o CNPJ informado'
        case ApiErrorMessages.EXPIRED_TOKEN:
          return 'Esse link expirou!'
        case ApiErrorMessages.INVALID_USER_TOKEN:
          return 'Esse token está inválido!'
        case ApiErrorMessages.INACTIVE_USER:
          return 'O usuário está desativado. Entre em contato com a Copérnico'
        case ApiErrorMessages.INVALID_USER_INTEGRATOR_IS_PENDING:
          return 'Integrador associado a este usuário está pendente, expirado ou inativo'
        case ApiErrorMessages.POWER_COMPANY_HAS_POWER_PLANT:
          return 'A distribuidora está vinculada a uma ou mais usinas. Antes de desativá-la, remova os vínculos!'
        case ApiErrorMessages.SERVICE_UNAVAILABLE:
          return 'Integração com API do Safra está Offline. Necessário entrar em contato com suporte Safra'
        default:
          return 'Algo de errado aconteceu. Por favor, tente novamente mais tarde!'
      }
    }

    const _message = getMessage(message as ApiErrorMessages)

    addToast(_message, toastErrorConfig)
  }

  return {
    handleApiError,
    handleGenericError,
    handleApolloErrorDesambiguation,
    handleApiErrorDesambiguation,
    handleBusinessLogicError,
    handleApiErrorUnavailable
  }
}

export default useErrorHandler

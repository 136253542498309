import React, { useState, useEffect, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import useProjetos from 'repositories/useProjetos'

import FormPF from './PF/index'
import FormPJ from './PJ/index'


const CreateProject = (props: any) => {
  const { userData, userPermissions, hasFormPermission } = useAuth()
  const [isPJ, setIsPJ] = useState<boolean>()
  const [projeto, setProjeto] = useState<TProjetoRequest | TProjetoRequestPJ>()
  const { id } = useParams<{ id: string }>()
  const repositoryProjetos = useProjetos()

  useEffect(() => {
    const requestProjeto = async () => {
      const triggerLoading = false
      const _projeto = await repositoryProjetos.getProjeto(id, triggerLoading)
      if(!_projeto) return
      setProjeto(_projeto)
    }
    if(!id) return
    requestProjeto()
  }, [id])

  useEffect(() => {
    if(!projeto) return
    if(!projeto.clientpj)
      setIsPJ(false)
    else
      setIsPJ(true)
  }, [projeto])

  const permission = useMemo(() => {
    const permission = userPermissions?.projetos
    return hasFormPermission(permission!, location.pathname)
  }, [userPermissions])


  if (userData && !permission) return <Redirect to='/acesso-negado' />
  return (
    <>
      {(isPJ !== null && isPJ === true) && (
        <FormPJ />
      )}
      {(isPJ !== null && isPJ === false) && (
        <FormPF />
      )}
    </>
  )
}

export default CreateProject

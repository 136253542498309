import { useState } from 'react'
import useRest from 'hooks/useRest'

type TListaIntegradoresParams = {
  pageSize?: number
  page?: number
  search?: string
  companyName?: string
}

type TIntegradorInfo = {
  id: string
  cnpj: string
  companyName: string
}

type TIntegradorSearchParams = {
  integrators: TIntegradorInfo[]
  currentUserIntegrator: {
    id: number
    companyName: string
    cnpj: string
  }
}

const useIntegradores = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getIntegradores = async (
    params: TListaIntegradoresParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/integrator'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response
  }

  const getIntegrador = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/integrator/${id}/details`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TIntegradorRequest
    return response.data as TIntegradorRequest
  }

  const postIntegrador = async (data: TIntegradorRequest, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/integrator'
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  const putIntegrador = async (data: TIntegradorRequest, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/integrator/${data.id}`
    const response = await rest.put(url, data)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  const getIntegradoresByCompanyName = async (
    params: TListaIntegradoresParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/integrator/project-add-integrator'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TIntegradorSearchParams
    return response as TIntegradorSearchParams
  }

  const getEmailIntegradorNovoUsuario = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/integrator/create-user-for-integrator/${id}`
    const response = await rest.put(url)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  const getStatus = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/integrator-status'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response
  }

  const getSituacoes = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/integrator-situation'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response
  }

  return {
    isLoading,
    getIntegradores,
    getIntegrador,
    postIntegrador,
    putIntegrador,
    getIntegradoresByCompanyName,
    getStatus,
    getSituacoes,
    getEmailIntegradorNovoUsuario
  }
}

export default useIntegradores
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom'
import { useFormik } from 'formik'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useToasts } from 'react-toast-notifications'
import {
  Row,
  Col,
  Form
} from 'react-bootstrap'

import { Container, CustomFormContainer } from './styles'

import { useAuth } from 'hooks/useAuth'
import useProjetos, { TNotaFiscalEmpresa, TNotaFiscalIntegrador } from 'repositories/useProjetos'
import useInformacoes from 'repositories/useInformacoes'

import { schema, initialValues } from './validation'
import {
  Loader,
  InfoText,
  GoBackButton,
  PageHeader,
  ButtonsContainer,
  ConfirmButton,
  PrimaryButton,
  FormSubTitle,
  TextField,
  MaskedTextField,
  MaskedCurrencyField,
  FileField,
  SearchField,
  FormDivider,
  Box
} from 'components'

import { confirmationMessage, StatusDocumento, Variant } from 'utils/constants'
import { formatCurrency, formatCurrencyToServer, formatDateTimeToView } from 'utils/helpers'
import ConfirmationModal from './Components/ConfirmationModal'
import HelpInfoModal from './Components/HelpInfoModal'
import HelpInfoTooltip from './Components/HelpInfoTooltip'

const toastCreateSucessMessage = 'Notas fiscais e favorecidos foram criados com sucesso!'
const toastUpdateSucessMessage = 'Notas fiscais enviadas com sucesso!'

const agencyMaxLength = 4

const NotaFiscal = () => {
  const [nfUnificada, setNfUnificada] = useState<TNotaFiscalUnificadaInfo>()
  const [bancosOptions, setBancosOptions] = useState<TSelectOption[]>([])
  const [isVisualizando, setIsVisualizando] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [quantidadeNotas, setQuantidadeNotas] = useState(1)

  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation<{ fromDetalhes: boolean }>()
  const repository = useProjetos()
  const { getBancos } = useInformacoes()
  const { addToast } = useToasts()

  const nfeBancoRef = useRef<AsyncTypeahead<string>>(null)
  const nfseBancoRef = useRef<AsyncTypeahead<string>>(null)
  const { userPermissions } = useAuth()

  useMemo(() => {
    if (!userPermissions?.projetos?.edit) setIsVisualizando(true)
  }, [userPermissions])

  useEffect(() => {
    const requestNotasFiscais = async () => {
      const notas = await repository.getNotasFiscais(id)
      if (!notas.empresa) return
      fillCompanyData(notas.empresa)
      if(!notas.integrador) return
      fillIntegratorData(notas.integrador)
      if (!notas.result.length) return

      const { notaFiscalUnificada, result } = notas
      const _notaFiscalUnificada = {
        id: notaFiscalUnificada.id,
        status: notaFiscalUnificada.status,
        dataHoraEnvio: formatDateTimeToView(notaFiscalUnificada.data_hora_envio),
        descricaoEnvio: notaFiscalUnificada.tipo_descricao,
        dataHoraParecer: formatDateTimeToView(notaFiscalUnificada.data_hora_parecer),
        descricaoParecer: notaFiscalUnificada.comentario_parecer,
        reenviarNf: Boolean(notaFiscalUnificada.status === StatusDocumento.NEGADO_PELO_BANCO)
      }
      setNfUnificada(_notaFiscalUnificada)

      const [nfe, nfse, nfse2, nfse3] = result
      const data = {
        nfeId: String(nfe.id),
        enviarDuasNotas: notas.result.length > 1 ? 'true' : '',
        nfeNomeArquivo: (!_notaFiscalUnificada.reenviarNf ? nfe.notaFiscal?.arquivo : ''),
        nfeConteudo: '',
        nfeDataHoraEnvio: formatDateTimeToView(nfe.notaFiscal?.data_hora_envio ?? ''),
        nfeStatusEnvio: (!_notaFiscalUnificada.reenviarNf ? nfe.notaFiscal?.tipo_descricao : ''),
        nfeDanfe: nfe.danfe,
        nfeChaveDeAcesso: nfe.chaveDeAcesso,
        nfeCnpj: nfe.cnpj,
        nfeAnoFundacao: nfe.anoFundacao,
        nfeRazaoSocial: nfe.razaoSocial,
        nfeNomeFantasia: nfe.nomeFantasia,
        nfeValor: formatCurrency(nfe.valor),
        nfeTipo: 'NFE',
        nfeBanco: nfe.banco?.name,
        nfeBancoId: String(nfe.banco?.id),
        nfeAgencia: String(nfe.agencia),
        nfeContaBancaria: String(nfe.contaBancaria),
        nfseId: String(nfse?.id),
        nfseNomeArquivo: (!_notaFiscalUnificada.reenviarNf ? nfse?.notaFiscal?.arquivo : ''),
        nfseConteudo: '',
        nfseDataHoraEnvio: formatDateTimeToView(nfse?.notaFiscal?.data_hora_envio ?? ''),
        nfseStatusEnvio: (
          !_notaFiscalUnificada.reenviarNf
            ? (nfse?.notaFiscal?.tipo_descricao ?? '')
            : ''
        ),
        nfseDanfe: nfse?.danfe ?? '',
        nfseChaveDeAcesso: nfse?.chaveDeAcesso ?? '',
        nfseCnpj: nfse?.cnpj ?? '',
        nfseAnoFundacao: nfse?.anoFundacao ?? '',
        nfseRazaoSocial: nfse?.razaoSocial ?? '',
        nfseNomeFantasia: nfse?.nomeFantasia ?? '',
        nfseValor: formatCurrency(nfse?.valor ?? '0'),
        nfseTipo: 'NFES',
        nfseBanco: nfse?.banco?.name ?? '',
        nfseBancoId: String(nfse?.banco?.id ?? ''),
        nfseAgencia: String(nfse?.agencia ?? ''),
        nfseContaBancaria: String(nfse?.contaBancaria ?? ''),

        nfse2Id: String(nfse2?.id),
        nfse2NomeArquivo: (!_notaFiscalUnificada.reenviarNf ? nfse2?.notaFiscal?.arquivo : ''),
        nfse2Conteudo: '',
        nfse2DataHoraEnvio: formatDateTimeToView(nfse2?.notaFiscal?.data_hora_envio ?? ''),
        nfse2StatusEnvio: (
          !_notaFiscalUnificada.reenviarNf
            ? (nfse2?.notaFiscal?.tipo_descricao ?? '')
            : ''
        ),
        nfse2Danfe: nfse2?.danfe ?? '',
        nfse2ChaveDeAcesso: nfse2?.chaveDeAcesso ?? '',
        nfse2Cnpj: nfse2?.cnpj ?? '',
        nfse2AnoFundacao: nfse2?.anoFundacao ?? '',
        nfse2RazaoSocial: nfse2?.razaoSocial ?? '',
        nfse2NomeFantasia: nfse2?.nomeFantasia ?? '',
        nfse2Valor: formatCurrency(nfse2?.valor ?? '0'),
        nfse2Tipo: 'NFES',
        nfse2Banco: nfse2?.banco?.name ?? '',
        nfse2BancoId: String(nfse2?.banco?.id ?? ''),
        nfse2Agencia: String(nfse2?.agencia ?? ''),
        nfse2ContaBancaria: String(nfse2?.contaBancaria ?? ''),

        nfse3Id: String(nfse3?.id),
        nfse3NomeArquivo: (!_notaFiscalUnificada.reenviarNf ? nfse3?.notaFiscal?.arquivo : ''),
        nfse3Conteudo: '',
        nfse3DataHoraEnvio: formatDateTimeToView(nfse3?.notaFiscal?.data_hora_envio ?? ''),
        nfse3StatusEnvio: (
          !_notaFiscalUnificada.reenviarNf
            ? (nfse3?.notaFiscal?.tipo_descricao ?? '')
            : ''
        ),
        nfse3Danfe: nfse3?.danfe ?? '',
        nfse3ChaveDeAcesso: nfse3?.chaveDeAcesso ?? '',
        nfse3Cnpj: nfse3?.cnpj ?? '',
        nfse3AnoFundacao: nfse3?.anoFundacao ?? '',
        nfse3RazaoSocial: nfse3?.razaoSocial ?? '',
        nfse3NomeFantasia: nfse3?.nomeFantasia ?? '',
        nfse3Valor: formatCurrency(nfse3?.valor ?? '0'),
        nfse3Tipo: 'NFES',
        nfse3Banco: nfse3?.banco?.name ?? '',
        nfse3BancoId: String(nfse3?.banco?.id ?? ''),
        nfse3Agencia: String(nfse3?.agencia ?? ''),
        nfse3ContaBancaria: String(nfse3?.contaBancaria ?? ''),
      } as TNotaFiscalForm

      if (notas.result.length) setIsVisualizando(true)
      formik.setValues(data)
      setQuantidadeNotas(notas.result.length)
    }

    if (id) requestNotasFiscais()
  }, [id])

  const fillCompanyData = (empresa: TNotaFiscalEmpresa) => {
    formik.setFieldValue('nfeCnpj', empresa.cnpj)
    formik.setFieldValue('nfeAnoFundacao', empresa.foundationYear)
    formik.setFieldValue('nfeRazaoSocial', empresa.corporateName)
    formik.setFieldValue('nfeNomeFantasia', empresa.fantasyName)
    formik.setFieldValue('nfeBanco', empresa.bankName)
    formik.setFieldValue('nfeBancoId', empresa.bankId)
    formik.setFieldValue('nfeAgencia', empresa.agencyNumber)
    formik.setFieldValue('nfeContaBancaria', empresa.accountNumber)
  }

  const fillIntegratorData = (integrador: TNotaFiscalIntegrador) => {
    formik.setFieldValue('nfseCnpj', integrador.cnpj)
    formik.setFieldValue('nfseAnoFundacao', integrador.foundationYear)
    formik.setFieldValue('nfseRazaoSocial', integrador.companyName)
    formik.setFieldValue('nfseNomeFantasia', integrador.fantasyName)

    formik.setFieldValue('nfseBanco', integrador.bankName)
    formik.setFieldValue('nfseBancoId', integrador.bankId)
    formik.setFieldValue('nfseAgencia', integrador.agencyNumber)
    formik.setFieldValue('nfseContaBancaria', integrador.accountNumber)

    formik.setFieldValue('nfse2Banco', integrador.bankName)
    formik.setFieldValue('nfse2BancoId', integrador.bankId)
    formik.setFieldValue('nfse2Agencia', integrador.agencyNumber)
    formik.setFieldValue('nfse2ContaBancaria', integrador.accountNumber)

    formik.setFieldValue('nfse3Banco', integrador.bankName)
    formik.setFieldValue('nfse3BancoId', integrador.bankId)
    formik.setFieldValue('nfse3Agencia', integrador.agencyNumber)
    formik.setFieldValue('nfse3ContaBancaria', integrador.accountNumber)

  }

  const openConfirmationModal = () => setShowConfirmationModal(true)
  const closeConfirmationModal = () => setShowConfirmationModal(false)

  const onSubmit = async () => {
    if (nfUnificada?.reenviarNf) {
      await sendNotasFiscais()
      return
    }

    openConfirmationModal()
  }

  const sendNotasFiscais = async () => {
    const { values } = formik
    const _notas = [
      {
        id: Number(values.nfeId),
        notaFiscal: {
          file: values.nfeNomeArquivo,
          fileContent: values.nfeConteudo
        },
        danfe: values.nfeDanfe,
        chaveDeAcesso: values.nfeChaveDeAcesso,
        cnpj: values.nfeCnpj,
        anoFundacao: values.nfeAnoFundacao,
        razaoSocial: values.nfeRazaoSocial,
        nomeFantasia: values.nfeNomeFantasia,
        valor: formatCurrencyToServer(values.nfeValor),
        type: values.nfeTipo,
        banco: values.nfeBancoId,
        agencia: values.nfeAgencia,
        contaBancaria: values.nfeContaBancaria,
      },
      {
        id: Number(values.nfseId),
        notaFiscal: {
          file: values.nfseNomeArquivo,
          fileContent: values.nfseConteudo
        },
        danfe: values.nfseDanfe,
        chaveDeAcesso: values.nfseChaveDeAcesso,
        cnpj: values.nfseCnpj,
        anoFundacao: values.nfseAnoFundacao,
        razaoSocial: values.nfseRazaoSocial,
        nomeFantasia: values.nfseNomeFantasia,
        valor: formatCurrencyToServer(values.nfseValor),
        type: values.nfseTipo,
        banco: values.nfseBancoId,
        agencia: values.nfseAgencia,
        contaBancaria: values.nfseContaBancaria,
      },
      {
        id: Number(values.nfse2Id),
        notaFiscal: {
          file: values.nfse2NomeArquivo,
          fileContent: values.nfse2Conteudo
        },
        danfe: values.nfse2Danfe,
        chaveDeAcesso: values.nfse2ChaveDeAcesso,
        cnpj: values.nfseCnpj,
        anoFundacao: values.nfseAnoFundacao,
        razaoSocial: values.nfseRazaoSocial,
        nomeFantasia: values.nfseNomeFantasia,
        valor: formatCurrencyToServer(values.nfse2Valor),
        type: values.nfseTipo,
        banco: values.nfse2BancoId,
        agencia: values.nfse2Agencia,
        contaBancaria: values.nfse2ContaBancaria,
      },
      {
        id: Number(values.nfse3Id),
        notaFiscal: {
          file: values.nfse3NomeArquivo,
          fileContent: values.nfse3Conteudo
        },
        danfe: values.nfse3Danfe,
        chaveDeAcesso: values.nfse3ChaveDeAcesso,
        cnpj: values.nfseCnpj,
        anoFundacao: values.nfseAnoFundacao,
        razaoSocial: values.nfseRazaoSocial,
        nomeFantasia: values.nfseNomeFantasia,
        valor: formatCurrencyToServer(values.nfse3Valor),
        type: values.nfse3Tipo,
        banco: values.nfse3BancoId,
        agencia: values.nfse3Agencia,
        contaBancaria: values.nfse3ContaBancaria,
      }
    ]

    let notas: any

    switch (quantidadeNotas) {
      case 1:
        notas = [_notas[0]]
        break
      case 2:
        notas = [_notas[0], _notas[1]]
        break
      case 3:
        notas = [_notas[0], _notas[1], _notas[2]]
        break
      case 4:
        notas = [_notas[0], _notas[1], _notas[2], _notas[3]]
        break
    }

    const response = nfUnificada?.reenviarNf
      ? await repository.putNotasFiscais(id, notas)
      : await repository.postNotasFiscais(id, notas)

    if (!response || response?.error) return

    addToast(
      nfUnificada?.reenviarNf ? toastUpdateSucessMessage : toastCreateSucessMessage,
      { appearance: 'success', autoDismiss: true }
    )
    history.push(goBackRoute)
  }

  const formik = useFormik<TNotaFiscalForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })

  const hasError = (name: keyof TNotaFiscalForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TNotaFiscalForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  })

  const setFileNfe = (name: string, content: string) => {
    formik.setFieldValue('nfeNomeArquivo', name, true)
    formik.setFieldValue('nfeConteudo', content, true)
  }

  const setFileNfse = (name: string, content: string) => {
    formik.setFieldValue('nfseNomeArquivo', name, true)
    formik.setFieldValue('nfseConteudo', content, true)
  }

  const setFileNfse2 = (name: string, content: string) => {
    formik.setFieldValue('nfse2NomeArquivo', name, true)
    formik.setFieldValue('nfse2Conteudo', content, true)
  }

  const setFileNfse3 = (name: string, content: string) => {
    formik.setFieldValue('nfse3NomeArquivo', name, true)
    formik.setFieldValue('nfse3Conteudo', content, true)
  }

  const onSearchBanco = (term: string) => {
    if (term.length < 3) return
    const requestBanco = async () => {
      const bancos = await getBancos(term)
      const separator = ' - '
      if (!bancos) return
      const _bancosOptions = bancos.map(banco => ({
        label: banco.name + separator + banco.code,
        value: String(banco.id)
      }))

      setBancosOptions(_bancosOptions)
    }

    requestBanco()
  }

  const onChangeBanco = (name: keyof TNotaFiscalForm) => ([banco]: TSelectOption[]) => {
    const value = banco?.value ?? ''
    const label = banco?.label ?? ''
    switch (name) {
      case 'nfeBanco':
        formik.setFieldValue('nfeBanco', label, true)
        formik.setFieldValue('nfeBancoId', value, true)
        break
      case 'nfseBanco':
        formik.setFieldValue('nfseBanco', label, true)
        formik.setFieldValue('nfseBancoId', value, true)
        break
      case 'nfse2Banco':
        formik.setFieldValue('nfse2Banco', label, true)
        formik.setFieldValue('nfse2BancoId', value, true)
        break
      case 'nfse3Banco':
        formik.setFieldValue('nfse3Banco', label, true)
        formik.setFieldValue('nfse3BancoId', value, true)
        break
    }
  }

  const onBlurBanco = (name: keyof TNotaFiscalForm) => () => {
    const getRefByName = () => {
      switch (name) {
        case 'nfeBanco':
          return nfeBancoRef
        case 'nfseBanco':
          return nfseBancoRef
        case 'nfse2Banco':
          return nfseBancoRef
        case 'nfse3Banco':
          return nfseBancoRef
      }
    }
    const ref = getRefByName()
    formik.setFieldTouched(name, true)
    if (!formik.values[name]) ref?.current?.clear()
  }

  const goBackRoute = `/projetos/detalhes/${id}`

  const onClickBackToDetail = () => history.push(goBackRoute)

  if (!location.state) return <Redirect to={goBackRoute} />

  const isEditable = !isVisualizando || nfUnificada?.reenviarNf

  const onChangeEnviarMultiplasNotas = (e: TInputEvent) => {
    const { value } = e.target
    setQuantidadeNotas(parseInt(value))
  }

  return (
    <Container>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={isEditable}
            route={goBackRoute}
            message={confirmationMessage}
          />
        </Col>
      </Row>

      <Loader isLoading={repository.isLoading} />
      {!repository.isLoading && (
        <>
          <Row>
            <Col>
              <PageHeader title='Notas Fiscais' />
            </Col>
          </Row>

          <Row>
            <Col>
              <InfoText>
                Esta seção é reservada para o envio de nota fiscal. Você poderá fazer o envio
                <strong>de até quatro notas fiscais.</strong>
              </InfoText>
            </Col>
          </Row>
          <Row>
            <Col>
              <InfoText>
                Selecione a opção abaixo para definir a
                <strong>quantidade de notas fiscais.</strong>
              </InfoText>
            </Col>
          </Row>

          <Form.Row>
            <Col>
              <Form.Check
                type='radio'
                id='enviarMultiplasNotas1'
                label='1 Nota Fiscal'
                name='quantNotas'
                value={1}
                defaultChecked
                disabled={isVisualizando}
                checked={quantidadeNotas === 1}
                onChange={onChangeEnviarMultiplasNotas}
              />
              <Form.Check
                type='radio'
                id='enviarMultiplasNotas2'
                label='2 Notas Fiscais'
                name='quantNotas'
                value={2}
                disabled={isVisualizando}
                checked={quantidadeNotas === 2}
                onChange={onChangeEnviarMultiplasNotas}
              />
              <Form.Check
                type='radio'
                id='enviarMultiplasNotas3'
                label='3 Notas Fiscais'
                name='quantNotas'
                value={3}
                disabled={isVisualizando}
                checked={quantidadeNotas === 3}
                onChange={onChangeEnviarMultiplasNotas}
              />
              <Form.Check
                type='radio'
                id='enviarMultiplasNotas4'
                label='4 Notas Fiscais'
                name='quantNotas'
                value={4}
                disabled={isVisualizando}
                checked={quantidadeNotas === 4}
                onChange={onChangeEnviarMultiplasNotas}
              />
            </Col>
          </Form.Row>
          <br />

          {isVisualizando && (
            <>
              <Row>
                <Col>
                  <InfoText isPartOfGroup>
                    <strong>Enviado em:</strong>
                    <span>{nfUnificada?.dataHoraEnvio}</span>
                  </InfoText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InfoText>
                    <strong>Status:</strong>
                    <span>{nfUnificada?.descricaoEnvio}</span>
                  </InfoText>
                </Col>
              </Row>

              {nfUnificada?.reenviarNf && (
                <>
                  <Row>
                    <Col>
                      <InfoText isPartOfGroup>
                        <strong>Recusado em:</strong>
                        <span>{nfUnificada?.dataHoraParecer}</span>
                      </InfoText>
                    </Col>
                  </Row>
                  {nfUnificada?.descricaoParecer && (
                    <Row>
                      <Col>
                        <InfoText isPartOfGroup>
                          <strong>Parecer sobre a recusa:</strong>
                          <span>{nfUnificada?.descricaoParecer}</span>
                        </InfoText>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <InfoText isPartOfGroup>
                        <strong>
                          Houve a recusa dos documentos, por favor para prosseguir
                          com o processo, realize o envio novamente.
                        </strong>
                      </InfoText>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

          <Form onSubmit={formik.handleSubmit}>
            <Box compact>
              <Form.Row>
                <Col>
                  <FormSubTitle>Nota Fiscal #1</FormSubTitle>
                </Col>
              </Form.Row>

              <CustomFormContainer>
                <Col md={6}>
                  <Form.Row>
                    <Col>
                      <FileField
                        disabled={isVisualizando && !nfUnificada?.reenviarNf}
                        label='Arquivo (.pdf)'
                        setFile={setFileNfe}
                        required
                        {...getCommonFieldProps('nfeNomeArquivo')}
                        fileName={formik.values.nfeNomeArquivo}
                        inCloud={formik.values.nfeStatusEnvio}
                        accept='application/pdf'
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={8}>
                      <TextField
                        required
                        disabled={isVisualizando}
                        label='Danfe'
                        {...getCommonFieldProps('nfeDanfe')}
                        maxLength={15}
                        helpIcon={<HelpInfoModal />}
                      />
                    </Col>
                    <Col md={4}>
                      <MaskedCurrencyField
                        required
                        disabled={isVisualizando}
                        label='Valor'
                        {...getCommonFieldProps('nfeValor')}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <MaskedTextField
                        required
                        label='Chave de Acesso'
                        disabled={isVisualizando}
                        mask='99999999999999999999999999999999999999999999'
                        {...getCommonFieldProps('nfeChaveDeAcesso')}
                        helpIcon={<HelpInfoModal />}
                      />
                    </Col>
                  </Form.Row>
                </Col>
                <Col md={6}>
                  <Form.Row>
                    <Col md={6}>
                      <MaskedTextField
                        required
                        label='CNPJ'
                        //disabled
                        disabled={isVisualizando}
                        mask='99.999.999/9999-99'
                        {...getCommonFieldProps('nfeCnpj')}
                      />
                    </Col>
                    <Col md={6}>
                      <TextField
                        required
                        //disabled
                        disabled={isVisualizando}
                        label='Ano de Fundação'
                        maxLength={4}
                        {...getCommonFieldProps('nfeAnoFundacao')}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <TextField
                        required
                        //disabled
                        disabled={isVisualizando}
                        label='Razão Social'
                        {...getCommonFieldProps('nfeRazaoSocial')}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <TextField
                        label='Nome Fantasia'
                        disabled={isVisualizando}
                        //disabled
                        {...getCommonFieldProps('nfeNomeFantasia')}
                      />
                    </Col>
                  </Form.Row>
                </Col>
              </CustomFormContainer>

              <FormDivider />

              <Form.Row>
                <Col md={6}>
                  <SearchField
                    required
                    //disabled
                    disabled={isVisualizando}
                    label='Banco'
                    elementRef={nfeBancoRef}
                    options={bancosOptions}
                    {...getCommonFieldProps('nfeBanco')}
                    onSearch={onSearchBanco}
                    onChange={onChangeBanco('nfeBanco')}
                    onBlur={onBlurBanco('nfeBanco')}

                    value={formik.values.nfeBanco}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    required
                    //disabled
                    disabled={isVisualizando}
                    type='number'
                    label='Agência'
                    maxLength={agencyMaxLength}
                    {...getCommonFieldProps('nfeAgencia')}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    required
                    //disabled
                    disabled={isVisualizando}
                    type='number'
                    label='Conta Bancária'
                    {...getCommonFieldProps('nfeContaBancaria')}
                  />
                </Col>
              </Form.Row>
            </Box>

            {(quantidadeNotas >= 2) && (
              <Box compact>
                <Form.Row>
                  <Col>
                    <FormSubTitle>Nota Fiscal #2</FormSubTitle>
                  </Col>
                </Form.Row>

                <CustomFormContainer>
                  <Col md={6}>
                    <Form.Row>
                      <Col>
                        <FileField
                          required
                          disabled={isVisualizando && !nfUnificada?.reenviarNf}
                          label='Arquivo (.pdf)'
                          setFile={setFileNfse}
                          {...getCommonFieldProps('nfseNomeArquivo')}
                          fileName={formik.values.nfseNomeArquivo}
                          inCloud={formik.values.nfseStatusEnvio}
                          accept='application/pdf'
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col md={8}>
                        <TextField
                          required
                          disabled={isVisualizando}
                          label='Danfe'
                          {...getCommonFieldProps('nfseDanfe')}
                          maxLength={15}
                          helpIcon={<HelpInfoTooltip />}
                        />
                      </Col>
                      <Col md={4}>
                        <MaskedCurrencyField
                          required
                          disabled={isVisualizando}
                          label='Valor'
                          {...getCommonFieldProps('nfseValor')}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <TextField
                          required
                          disabled={isVisualizando}
                          label='Chave de Acesso'
                          {...getCommonFieldProps('nfseChaveDeAcesso')}
                          helpIcon={<HelpInfoTooltip />}
                        />
                      </Col>
                    </Form.Row>
                  </Col>
                  <Col md={6}>
                    <Form.Row>
                      <Col md={6}>
                        <MaskedTextField
                          required
                          label='CNPJ'
                          disabled={isVisualizando}
                          //disabled
                          mask='99.999.999/9999-99'
                          {...getCommonFieldProps('nfseCnpj')}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          required
                          disabled={isVisualizando}
                          //disabled
                          label='Ano de Fundação'
                          maxLength={4}
                          {...getCommonFieldProps('nfseAnoFundacao')}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <TextField
                          required
                          //disabled
                          disabled={isVisualizando}
                          label='Razão Social'
                          {...getCommonFieldProps('nfseRazaoSocial')}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <TextField
                          label='Nome Fantasia'
                          //disabled
                          disabled={isVisualizando}
                          {...getCommonFieldProps('nfseNomeFantasia')}
                        />
                      </Col>
                    </Form.Row>
                  </Col>
                </CustomFormContainer>

                <FormDivider />

                <Form.Row>
                  <Col md={6}>
                    <SearchField
                      required
                      //disabled
                      disabled={isVisualizando}
                      label='Banco'
                      elementRef={nfseBancoRef}
                      options={bancosOptions}
                      {...getCommonFieldProps('nfseBanco')}
                      onSearch={onSearchBanco}
                      onChange={onChangeBanco('nfseBanco')}
                      onBlur={onBlurBanco('nfseBanco')}
                      value={formik.values.nfseBanco}
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      required
                      //disabled
                      disabled={isVisualizando}
                      type='number'
                      label='Agência'
                      maxLength={agencyMaxLength}
                      {...getCommonFieldProps('nfseAgencia')}

                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      required
                      //disabled
                      disabled={isVisualizando}
                      type='number'
                      label='Conta Bancária'
                      {...getCommonFieldProps('nfseContaBancaria')}
                    />
                  </Col>
                </Form.Row>
              </Box>
            )}

            {(quantidadeNotas >= 3) && (
              <Box compact>
                <Form.Row>
                  <Col>
                    <FormSubTitle>Nota Fiscal #3</FormSubTitle>
                  </Col>
                </Form.Row>

                <CustomFormContainer>
                  <Col md={6}>
                    <Form.Row>
                      <Col>
                        <FileField
                          required
                          disabled={isVisualizando && !nfUnificada?.reenviarNf}
                          label='Arquivo (.pdf)'
                          setFile={setFileNfse2}
                          {...getCommonFieldProps('nfse2NomeArquivo')}
                          fileName={formik.values.nfse2NomeArquivo}
                          inCloud={formik.values.nfse2StatusEnvio}
                          accept='application/pdf'
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col md={8}>
                        <TextField
                          required
                          disabled={isVisualizando}
                          label='Danfe'
                          {...getCommonFieldProps('nfse2Danfe')}
                          maxLength={15}
                          helpIcon={<HelpInfoTooltip />}
                        />
                      </Col>
                      <Col md={4}>
                        <MaskedCurrencyField
                          required
                          disabled={isVisualizando}
                          label='Valor'
                          {...getCommonFieldProps('nfse2Valor')}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <TextField
                          required
                          disabled={isVisualizando}
                          label='Chave de Acesso'
                          {...getCommonFieldProps('nfse2ChaveDeAcesso')}
                          helpIcon={<HelpInfoTooltip />}
                        />
                      </Col>
                    </Form.Row>
                  </Col>
                  <Col md={6}>
                    <Form.Row>
                      <Col md={6}>
                        <MaskedTextField
                          required
                          label='CNPJ'
                          //disabled
                          disabled={isVisualizando}
                          mask='99.999.999/9999-99'
                          {...getCommonFieldProps('nfseCnpj')}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          required
                          //disabled
                          disabled={isVisualizando}
                          label='Ano de Fundação'
                          maxLength={4}
                          {...getCommonFieldProps('nfseAnoFundacao')}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <TextField
                          required
                          //disabled
                          disabled={isVisualizando}
                          label='Razão Social'
                          {...getCommonFieldProps('nfseRazaoSocial')}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <TextField
                          label='Nome Fantasia'
                          //disabled
                          disabled={isVisualizando}
                          {...getCommonFieldProps('nfseNomeFantasia')}
                        />
                      </Col>
                    </Form.Row>
                  </Col>
                </CustomFormContainer>

                <FormDivider />

                <Form.Row>
                  <Col md={6}>
                    <SearchField
                      required
                      //disabled
                      disabled={isVisualizando}
                      label='Banco'
                      elementRef={nfseBancoRef}
                      options={bancosOptions}
                      {...getCommonFieldProps('nfse2Banco')}
                      onSearch={onSearchBanco}
                      onChange={onChangeBanco('nfse2Banco')}
                      onBlur={onBlurBanco('nfse2Banco')}
                      value={formik.values.nfse2Banco}
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      required
                      //disabled
                      disabled={isVisualizando}
                      type='number'
                      label='Agência'
                      maxLength={agencyMaxLength}
                      {...getCommonFieldProps('nfse2Agencia')}

                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      required
                      //disabled
                      disabled={isVisualizando}
                      type='number'
                      label='Conta Bancária'
                      {...getCommonFieldProps('nfse2ContaBancaria')}
                    />
                  </Col>
                </Form.Row>
              </Box>
            )}

            {(quantidadeNotas >= 4) && (
              <Box compact>
                <Form.Row>
                  <Col>
                    <FormSubTitle>Nota Fiscal #4</FormSubTitle>
                  </Col>
                </Form.Row>

                <CustomFormContainer>
                  <Col md={6}>
                    <Form.Row>
                      <Col>
                        <FileField
                          required
                          disabled={isVisualizando && !nfUnificada?.reenviarNf}
                          label='Arquivo (.pdf)'
                          setFile={setFileNfse3}
                          {...getCommonFieldProps('nfse3NomeArquivo')}
                          fileName={formik.values.nfse3NomeArquivo}
                          inCloud={formik.values.nfse3StatusEnvio}
                          accept='application/pdf'
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col md={8}>
                        <TextField
                          required
                          disabled={isVisualizando}
                          label='Danfe'
                          {...getCommonFieldProps('nfse3Danfe')}
                          maxLength={15}
                          helpIcon={<HelpInfoTooltip />}
                        />
                      </Col>
                      <Col md={4}>
                        <MaskedCurrencyField
                          required
                          disabled={isVisualizando}
                          label='Valor'
                          {...getCommonFieldProps('nfse3Valor')}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <TextField
                          required
                          disabled={isVisualizando}
                          label='Chave de Acesso'
                          {...getCommonFieldProps('nfse3ChaveDeAcesso')}
                          helpIcon={<HelpInfoTooltip />}
                        />
                      </Col>
                    </Form.Row>
                  </Col>
                  <Col md={6}>
                    <Form.Row>
                      <Col md={6}>
                        <MaskedTextField
                          required
                          label='CNPJ'
                          //disabled
                          disabled={isVisualizando}
                          mask='99.999.999/9999-99'
                          {...getCommonFieldProps('nfseCnpj')}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          required
                          //disabled
                          disabled={isVisualizando}
                          label='Ano de Fundação'
                          maxLength={4}
                          {...getCommonFieldProps('nfseAnoFundacao')}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <TextField
                          required
                          //disabled
                          disabled={isVisualizando}
                          label='Razão Social'
                          {...getCommonFieldProps('nfseRazaoSocial')}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <TextField
                          label='Nome Fantasia'
                          //disabled
                          disabled={isVisualizando}
                          {...getCommonFieldProps('nfseNomeFantasia')}
                        />
                      </Col>
                    </Form.Row>
                  </Col>
                </CustomFormContainer>

                <FormDivider />

                <Form.Row>
                  <Col md={6}>
                    <SearchField
                      required
                      //disabled
                      disabled={isVisualizando}
                      label='Banco'
                      elementRef={nfseBancoRef}
                      options={bancosOptions}
                      {...getCommonFieldProps('nfse3Banco')}
                      onSearch={onSearchBanco}
                      onChange={onChangeBanco('nfse3Banco')}
                      onBlur={onBlurBanco('nfse3Banco')}
                      value={formik.values.nfse3Banco}
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      required
                      //disabled
                      disabled={isVisualizando}
                      type='number'
                      label='Agência'
                      maxLength={agencyMaxLength}
                      {...getCommonFieldProps('nfse3Agencia')}

                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      required
                      //disabled
                      disabled={isVisualizando}
                      type='number'
                      label='Conta Bancária'
                      {...getCommonFieldProps('nfse3ContaBancaria')}
                    />
                  </Col>
                </Form.Row>
              </Box>
            )}

            <ButtonsContainer>
              <ConfirmButton
                active={isEditable}
                actionFn={onClickBackToDetail}
                message={confirmationMessage}
                variant={Variant.SECONDARY}
              >
                <span>
                  {isEditable
                    ? 'Cancelar'
                    : 'Voltar'
                  }
                </span>
              </ConfirmButton>
              {isEditable && (
                <PrimaryButton
                  type='submit'
                >
                  <span>
                    {
                      nfUnificada?.reenviarNf
                        ? 'Reenviar Notas'
                        : 'Enviar Notas'
                    }
                  </span>
                </PrimaryButton>
              )}
            </ButtonsContainer>
          </Form>
        </>
      )}
      <ConfirmationModal
        values={formik.values}
        confirmFn={sendNotasFiscais}
        closeModal={closeConfirmationModal}
        modalIsOpen={showConfirmationModal}
      />

    </Container>
  )
}

export default NotaFiscal

import React, { createContext, useContext, useState, useEffect } from 'react'
import restApi, { storageKey } from 'services/axios'


type TUserData = {
  name: string
  token: string
  role: string
  id: number
  permissions: string[]
}

type TCommonFuncionalidades = {
  view?: boolean
  add?: boolean
  edit?: boolean
  form?: boolean
}

type TPermissions = {
  projetos?: TCommonFuncionalidades & {
    cancel?: boolean
    users?: boolean
    replicate?: boolean
  }
  integradores?: TCommonFuncionalidades & {
    inactivate?: boolean
    approval?: boolean
    suggested_view?: boolean
  }
  usuarios?: TCommonFuncionalidades & {
    inactivate?: boolean
  }
  perfis?: TCommonFuncionalidades & {
    inactivate?: boolean
  }
  clientes?: TCommonFuncionalidades & {
    inactivate?: boolean
  }
  usinas?: TCommonFuncionalidades & {
    inactivate?: boolean
  }
  condominios?: TCommonFuncionalidades & {
    inactivate?: boolean
  }
  distribuidoras?: TCommonFuncionalidades & {
    inactivate?: boolean
  }
  faturas?: {
    view?: boolean
  }
  parametros?: {
    view?: boolean
    edit?: boolean
  }
  relatorios?: {
    view?: boolean
  }
  meusDados? : {
    view?: boolean
    edit?: boolean
  }
  alicota? : {
    view?: boolean
    edit?: boolean
  }
  contrato? : {
    view?: boolean
    status?: boolean
    bond?: boolean
  }
  auditoria? : {
    view?: boolean
  }
  pipefy? : {
    view?: boolean
  }
  empresa?: {
    view?: boolean
    edit?: boolean
  }
  cemig?:{
    view?: boolean
    edit?: boolean
  }
  errosFaturamento?:{
    view?: boolean
  }
}

type TPermissionTypes = TCommonFuncionalidades & {
  cancel?: boolean
  approval?: boolean
  inactivate?: boolean
  replicate?: boolean
}

type TUseAuth = {
  isSignedIn: boolean
  userData?: TUserData
  userPermissions?: TPermissions
  hasToken(): boolean
  saveUserData(user: TUserData): void
  removeUserData(): void
  hasFormPermission(permission: TPermissionTypes, url?: string): boolean
}

const AuthContext = createContext<TUseAuth>({} as TUseAuth)

const getPermissionsBussinesName = (permissionName: string): string => {
  switch (permissionName) {
    case 'project': return 'projetos'
    //mudança paliativa para resolver uma emergência em produção
    case 'projectviewall': return 'projetos'
    case 'integrators': return 'integradores'
    case 'report': return 'relatorios'
    case 'users': return 'usuarios'
    case 'profiles': return 'perfis'
    case 'parametrization': return 'parametros'
    case 'client': return 'clientes'
    case 'factory': return 'usinas'
    case 'condominium': return 'condominios'
    case 'powercompany': return 'distribuidoras'
    case 'bill': return 'faturas'
    case 'billing': return 'faturas'
    case 'mydata': return 'meusDados'
    case 'aliquot': return 'alicota'
    case 'contract': return 'contrato'
    case 'audit': return 'auditoria'
    case 'pipefyerror': return 'pipefy'
    case 'company': return 'empresa'
    case 'queuesearchenergybill': return 'cemig'
    case 'generateinvoicelog': return 'errosFaturamento'
    default: return ''
  }
}

type TProps = {
  children: React.ReactNode
}

const AuthProvider = ({ children }: TProps) => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [userData, setUserData] = useState<TUserData>()
  const [userPermissions, setUserPermissions] = useState<TPermissions>()

  const getObjectifiedPermissions = (permissions: string[]): TPermissions => {
    const _permissions = permissions?.reduce((acc, curr) => {
      const arrayPermission = curr.toLowerCase().split('_')
      const lastIndex = arrayPermission.length - 1
      const action = arrayPermission[lastIndex]
      const permission = arrayPermission.join('').replace(action, '')
      const _permission = getPermissionsBussinesName(permission)
      const permissionsAcc = _permission in acc
        ? acc[_permission]
        : {}

      return {
        ...acc,
        [_permission]: {
          ...permissionsAcc,
          [action]: true
        }
      }
    }, {} as TMultiLevelObject)
    return _permissions
  }

  useEffect(() => {
    const storageUserData = localStorage.getItem(storageKey)
    if (!storageUserData) return

    const parsedUserData = JSON.parse(storageUserData)
    const permissions = getObjectifiedPermissions(parsedUserData.permissions)
    setUserData(parsedUserData)
    setUserPermissions(permissions)
    setIsSignedIn(true)
  }, [])

  const saveUserData = (user: TUserData) => {
    localStorage.setItem(storageKey, JSON.stringify(user))
    const permissions = getObjectifiedPermissions(user.permissions)
    setUserData(user)
    setUserPermissions(permissions)
    setIsSignedIn(true)
    setToken(user.token)
  }

  const removeUserData = () => {
    localStorage.removeItem(storageKey)
    setUserData(undefined)
    setUserPermissions(undefined)
    setIsSignedIn(false)
  }

  const setToken = (token: string) => {
    restApi.defaults.headers.Authorization = `Bearer ${token}`
  }

  const hasToken = () => Boolean(localStorage.getItem(storageKey))

  const hasFormPermission = (permission: TPermissionTypes, url: string) => {
    if (!url) return false

    const [, , urlAction] = url.split('/')
    switch (urlAction) {
      case 'novo':
        return Boolean(permission?.add)
      case 'editar':
        return Boolean(permission?.edit)
      case 'replicar':
        return Boolean(permission?.replicate)
      case 'visualizar':
        return Boolean(permission?.view)
      default: return false
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        userData,
        userPermissions,
        hasToken,
        saveUserData,
        removeUserData,
        hasFormPermission
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): TUseAuth => {
  const context = useContext(AuthContext)

  return context
}

export { useAuth, AuthProvider }

import styled from 'styled-components'
import { Tabs, Table } from 'react-bootstrap'

const borderTabs = '#dee2e6 #dee2e6 #f5f5f5'

export const Container = styled.div`
  .icon-right span {
    padding-right: 70px;
  }
`

export const ContainerInvertersAndModules = styled.div`
  padding: 5px 10px;
`

export const CustomTabs = styled(Tabs)`
  margin-top: 10px;

  .nav-link {
    font-weight: 700;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
    background-color: transparent!important;
  
    &.active {
      border-color: ${borderTabs};
    }
  }

  > div div {
    padding: 10px;
  }

  .tab-content {
    margin-bottom: 15px;
  }
`

export const EmptyRows = styled.td`
  text-align: center;
`

export const CustomTable = styled(Table)`
  margin-top: 2rem;
  margin-bottom: 0;
`
import styled from 'styled-components'
import { Button, Spinner } from 'react-bootstrap'

type TPrimaryButtonProps = {
  iconPosition?: 'right' | 'left'
  inLine?: boolean
  outline?: boolean
}

export const CustomButton = styled(Button)<TPrimaryButtonProps>`
  background-color: ${p => p.outline
    ? p.theme.colors.WHITE
    : p.theme.colors.ORANGE
} !important;
  color: ${p => p.outline ? p.theme.colors.ORANGE : p.theme.colors.WHITE};
  border: solid 1px ${({ theme }) => theme.colors.ORANGE} !important;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  margin-top: ${p => p.inLine ? '32px' : 'unset'};

  :hover, :focus {
    background-color: ${({ theme }) => theme.colors.ORANGE};
    color: ${p => p.outline ? p.theme.colors.ORANGE : p.theme.colors.WHITE};
  }
  
  :disabled {
    color: ${p => p.outline ? p.theme.colors.ORANGE : p.theme.colors.WHITE};
  }

  svg {
    margin-left: ${p => p.iconPosition === 'right' ? '10px' : '0'};
    margin-right: ${p => p.iconPosition === 'left' ? '10px' : '0'};
    width: 22px;
    height: auto;
  }
`

export const CustomSpinner = styled(Spinner)`
  width: 17px;
  height: 17px;
`
import * as Yup from 'yup'
import { cnpj as cnpjValidation } from 'cpf-cnpj-validator'

export const initialValues: TContaBancariaForm = {
  bancoId: '',
  banco: '',
  agencia: '',
  contaBancaria: '',
  contaPrincipal: 'false',
  favorecido: '',
  cnpj: ''
}

export const schema = Yup.object().shape({
  banco: Yup.string().required('Banco é obrigatório'),
  agencia: Yup
    .number()
    .min(1, 'Número da agência não pode ser 0')
    .required('Número da agência é obrigatória'),
  contaBancaria: Yup
    .number()
    .min(1, 'Número da conta não pode ser 0')
    .required('Número da conta é obrigatória'),
  contaPrincipal: Yup.boolean(),
  favorecido: Yup.string().required('Favorecido é obrigatório'),
  cnpj: Yup.string().required('CNPJ é obrigatório')
    .test(
      'is-incorrect',
      'CNPJ não está válido',
      (value?: string) => {
        return Boolean(cnpjValidation.isValid(String(value)))
      }
    ),
})
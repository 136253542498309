import styled from 'styled-components'
import { Pagination, Spinner } from 'react-bootstrap'

export const Container = styled.div`
  margin-top: 20px;
  
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    display: flex;
    justify-content: center;
  }
`

export const CustomPagination = styled(Pagination)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .page-item.active {
    .page-link {
      background-color: ${({ theme }) => theme.colors.RED};
      border: none;
    }
  }

  .page-link {
    width: 30px;
    height: 30px;
    border-radius: 15px !important;
    padding: 0;
    line-height: 30px;
    text-align: center;
    border-color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
    background-color: transparent;
    color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
    margin-left: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      background-color: ${({ theme }) => theme.colors.RED};
      border: none;
      color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`

export const CustomSpinner = styled(Spinner)`
  width: 17px;
  height: 17px;
`
import { createGlobalStyle } from 'styled-components'
import { theme } from 'styles'
import neogroteskRegular from 'fonts/NeogroteskPro-Regular.woff'
import neogroteskRegular2 from 'fonts/NeogroteskPro-Regular.woff2'

export default createGlobalStyle`

  @font-face {
      font-family: 'Neogrotesk Pro';
      src: url(${neogroteskRegular2}) format('woff2'),
          url(${neogroteskRegular}) format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
  }

  body {
    font-family: 'Neogrotesk Pro', sans-serif;
    -webkit-font-smoothing: antialiased !important;
    color: ${theme.colors.DARK_GRAY};
    overflow-y: hidden;
  }

  .modal-backdrop {
      width: 100%;
  }

  .tab-content {
    .tab-pane {
      border: 1px solid #dee2e6;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-color: transparent #dee2e6 #dee2e6;
    }
  }

  table.table td, .form-control {
    font-weight: lighter;
  }

  table.table td, table.table th, .form-control {
    color: ${theme.colors.DARK_GRAY} !important;
  }

  .react-toast-notifications__container {
    z-index: 1200 !important;
  }
`

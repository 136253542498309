import React, { useEffect, useState, useMemo } from 'react'
import { useParams, Redirect, useLocation } from 'react-router-dom'
import { Col, Row, Alert } from 'react-bootstrap'
import { BsFileEarmarkText } from 'react-icons/bs'

import { useAuth } from 'hooks/useAuth'
import useProjetos from 'repositories/useProjetos'
import {
  formatCurrency,
  formatDateToView,
  formatDecimalNumber,
  getDiffDaysBetweenDates
} from 'utils/helpers'
import ModalConfirmationProposal from './Components/ModalConfirmationProposal'

import { Column, Item, Divider } from './styles'

import {
  Loader,
  GoBackButton,
  PageHeader,
  InfoText,
  Box,
  PrimaryButton
} from 'components'

const Propostas = () => {
  const [showModal, setShowModal] = useState(false)
  const [proposal, setProposal] = useState()
  const [integratorBankInfo, setIntegratorBankInfo] = useState()
  const [totalInstallments, setTotalInstallments] = useState(0)
  const [installmentId, setInstallmentId] = useState('')
  const [isDisableButtonChoseProposal, setIsDisableButtonChoseProposal] = useState(false)

  const { id } = useParams()
  const location = useLocation()
  const repository = useProjetos()
  const { userPermissions } = useAuth()
  const permissions = useMemo(() => userPermissions?.projetos, [userPermissions])

  useEffect(() => {
    const formatProposals = proposals => {
      const installments = proposals.installments.map(item => {
        return {
          id: item.id,
          prazo: item.prazo,
          propostaSelecionada: (proposals.idPropostaSafra ? item.isChosen : true),
          primeiroVencimento: formatDateToView(item.primeiroVencimento),
          taxaCet: formatDecimalNumber(item.taxaCet),
          taxaCetAno: 0,
          taxaJuros: formatDecimalNumber(item.taxaJuros),
          ultimoVencimento: formatDateToView(item.ultimoVencimento),
          valor: formatCurrency(item.valor),
          carencia: getDiffDaysBetweenDates(proposals.dataSimulacao, item.primeiroVencimento),
          formatedValueOfProject: formatCurrency(proposals.estimatedValueOfProject),
        }
      })

      return {
        ...proposals,
        installments
      }
    }

    const requestProposals = async () => {
      const response = await repository.getPropostasAndIntegrador(id)
      const { proposals, integrator, integratorInfo } = response
      if (!proposals?.length) return

      const firstProposal = proposals[0]

      setIsDisableButtonChoseProposal(Boolean(firstProposal?.idPropostaSafra))
      setTotalInstallments(firstProposal.installments.length)
      const _formattedProposals = formatProposals(firstProposal)
      const formattedProposals = {
        ..._formattedProposals,
        isRequiredIntegrador: Boolean(integrator)
      }
      setProposal(formattedProposals)

      if(!integratorInfo) return
      setIntegratorBankInfo(integratorInfo)
    }

    if (id) requestProposals()
  }, [id])

  const onClickShowModal = _id => () => {
    setShowModal(true)
    setInstallmentId(_id)
  }

  if (!location.state) return <Redirect to={`/projetos/detalhes/${id}`} />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton route={`/projetos/detalhes/${id}`} />
        </Col>
      </Row>

      <PageHeader title='Propostas de Financiamento' />

      <Row>
        <Col>
          <InfoText>
            Você pode selecionar apenas uma das consultas de cada conjunto
          </InfoText>
        </Col>
      </Row>

      <Loader isLoading={repository.isLoading} />

      <Row>
        <Col>
          <InfoText>
            <strong>Número de conjuntos:</strong>
            <span>{totalInstallments}</span>
          </InfoText>
        </Col>
      </Row>

      {!repository.isLoading && (
        <>
          {!proposal && (
            <Row>
              <Col>
                <Alert variant='danger'>
                  Não foram encontradas propostas para esse projeto
                </Alert>
              </Col>
            </Row>
          )}

          {proposal?.installments.map(item => (
            <>
              <Box showOpacity={!item.propostaSelecionada} key={item.id}>
                <Row>
                  <Column md={3}>
                    <Item>
                      <h4>Valor das parcelas</h4>
                      <span>{item.valor} por mês</span>
                    </Item>
                    <Item>
                      <h4>Valor financiado</h4>
                      <span>{item.formatedValueOfProject}</span>
                    </Item>
                  </Column>
                  <Column md={3}>
                    <Item>
                      <h4>Valor financiado</h4>
                      <BsFileEarmarkText size={32} />
                      <span>Sem garantia</span>
                    </Item>
                  </Column>
                  <Column md={3}>
                    <Item>
                      <h4>Taxa de juros</h4>
                      <span>{item.taxaJuros}% ao mês</span>
                    </Item>
                    <Item>
                      <h4>Valor de entrada</h4>
                      <span>R$ 0,00</span>
                    </Item>
                  </Column>
                  <Column md={3}>
                    <Item>
                      <h4>Quantidade de parcelas</h4>
                      <span>{item.prazo} parcelas</span>
                    </Item>
                    <Item>
                      <h4>Tempo de carência</h4>
                      <span>{item.carencia} dias</span>
                    </Item>
                  </Column>
                </Row>
              </Box>
              <Divider>
                <div>
                  <hr />
                </div>
                <div>
                  <PrimaryButton
                    disabled={isDisableButtonChoseProposal || !permissions.edit}
                    onClick={onClickShowModal(item.id)}
                  >
                    Selecionar Consulta
                  </PrimaryButton>
                </div>
              </Divider>
            </>
          ))}
          <ModalConfirmationProposal
            setIsOpen={setShowModal}
            isModalOpen={showModal}
            installmentId={installmentId}
            proposal={proposal}
            integratorBankInfo={integratorBankInfo}
          />
        </>
      )}
    </>
  )
}

export default Propostas
import { Tabs, Tab } from 'react-bootstrap'
import styled from 'styled-components'

export const CustomTabs = styled(Tabs)`
    .nav-link.active {
        color: #F28F28;
        font-weight: bold;
    }

    a {
        color: #F28F28;
        font-weight: bold;
    }
`

export const CustomTab = styled(Tab)`
    
`
export const Container = styled.div`
    padding: 20px;
`
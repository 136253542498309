import React from 'react'
import { Route as RouterRoute, Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import AuthLayout from 'components/Layout'

type TProps = {
  children: React.ReactNode
}

const DefaultLayout = ({ children }: TProps) => <>{children}</>

const Route = (props: any) => {
  const { component: Component, isPrivate = false, ...remainingProps } = props

  const { isSignedIn, hasToken } = useAuth()

  if (!isSignedIn && !hasToken() && isPrivate) return <Redirect to='/entrar' />

  const Layout = isSignedIn && isPrivate ? AuthLayout : DefaultLayout

  return (
    <RouterRoute
      {...remainingProps}
      render={routeProps => (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      )}
    />
  )
}

export default Route

import React, { useEffect, useMemo } from 'react'
import {
  PageHeader,
  NumberField,
  PrimaryButton,
  RadioField,
  SelectField,
} from 'components'
import { initialValues, validationSchema, TAliquot } from './validation'
import { Form, Col, Row } from 'react-bootstrap'
import { monthOptions } from 'utils/constants'
import { useUpdateAliquot, useGetAliquot } from 'repositories/aliquotas'
import { Container, PercentageContainer } from './styles'
import { useToasts } from 'react-toast-notifications'

import { useFormik } from 'formik'
import { useAuth } from 'hooks/useAuth'
import { Redirect } from 'react-router-dom'



export const classTypeOptions = [
  {
    label: 'Residêncial',
    value: 1
  },
  {
    label: 'Comercial',
    value: 2
  },
  {
    label: 'Industrial',
    value: 3
  },
  {
    label: 'Rural',
    value: 4
  },
]



const Aliquotas = () => {
  const updateAliquot = useUpdateAliquot()
  const getAliquot = useGetAliquot()
  const { userPermissions } = useAuth()
  const { addToast } = useToasts()

  const permissions = useMemo(() => userPermissions?.alicota, [userPermissions])

  const getType = (id: number) => {
    switch(id){
      case 1: return 'RESIDENTIAL'
      case 2: return 'COMMERCIAL'
      case 3: return 'INDUSTRIAL'
      case 4: return 'RURAL'
      default: return 'ERRO'
    }
  }

  useEffect(() => {
    getAliquot.get(formik.values.id)
  }, [])

  useEffect(() => {
    if(!getAliquot.aliquot) return
    formik.setFieldValue('monthValidity', getAliquot.aliquot.monthValidity, true)
    formik.setFieldValue('te', getAliquot.aliquot.te, true)
    formik.setFieldValue('tusd', getAliquot.aliquot.tusd, true)
    formik.setFieldValue('icms', getAliquot.aliquot.icms, true)
    formik.setFieldValue('pisCofins', getAliquot.aliquot.pisCofins, true)
  }, [getAliquot.aliquot])


  useEffect(() => {
    if(!updateAliquot.data) return
    if(updateAliquot.isError) {
      addToast(
        'Algum erro ocorreu, suas alíquotas não foram salvas!',
        { appearance: 'error', autoDismiss: true }
      )
      return
    }
    addToast(
      'Alíquotas salvas com sucesso!',
      { appearance: 'success', autoDismiss: true }
    )
  }, [updateAliquot.data])

  const onSubmit = () => {
    const aliquot: TAliquot = {
      id: formik.values.id,
      monthValidity: formik.values.monthValidity,
      te: formik.values.te,
      icms: formik.values.icms,
      pisCofins: formik.values.pisCofins,
      tusd: formik.values.tusd,
      typeClass: formik.values.typeClass
    }
    updateAliquot.send(aliquot)
  }

  const formik = useFormik<TAliquot>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit
  })

  const hasError = (name: keyof TAliquot) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TAliquot) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const customRadioOnChange = (field: keyof TAliquot, value: number) => {
    getAliquot.get(value)
    formik.resetForm()
    formik.setFieldValue(field, value, true)
    formik.setFieldValue('typeClass', getType(value), true)
  }

  const onChangeMonth = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('monthValidity', value, true)
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return(<>
    <Row>
      <Col>
        <PageHeader title='Alíquotas' />
      </Col>
    </Row>
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <RadioField
            label='Tipo da Classe'
            options={classTypeOptions}
            required
            {...getCommonFieldProps('id')}
            onChange= {customRadioOnChange}
          />
        </Row>
        <Row>
          <SelectField
            required
            label='Mês'
            options={monthOptions}
            disabled={!permissions?.edit}
            {...getCommonFieldProps('monthValidity')}
            onChange={onChangeMonth}
          />
        </Row>
        <Row>
          <NumberField
            required
            decimalScale={5}
            max={100}
            label='PIS/COFINS'
            disabled={!permissions?.edit}
            {...getCommonFieldProps('pisCofins')}
          />
          <PercentageContainer>%</PercentageContainer>
        </Row>
        <Row>
          <NumberField
            required
            decimalScale={5}
            max={100}
            label='ICMS'
            disabled={!permissions?.edit}
            {...getCommonFieldProps('icms')}
          />
          <PercentageContainer>%</PercentageContainer>
        </Row>
        <Row>
          <NumberField
            required
            decimalScale={5}
            max={1}
            label='TE'
            disabled={!permissions?.edit}
            {...getCommonFieldProps('te')}
          />
        </Row>
        <Row>
          <NumberField
            required
            decimalScale={5}
            max={1}
            label='TUSD'
            disabled={!permissions?.edit}
            {...getCommonFieldProps('tusd')}
          />
        </Row>
        <Row>
          <PrimaryButton type='submit' isLoading={(getAliquot.isLoading) || (updateAliquot.isLoading)} disabled={!permissions?.edit}>
            <span>Salvar</span>
          </PrimaryButton>
        </Row>
      </Form>
    </Container>
  </>
  )
}

export default Aliquotas
import { gql } from 'apollo-boost'
import { commonResponse, paginationInfo } from './constants'

export const USINAS = gql`
  query findPowerPlantByFilter (
    $findPowerPlantByFilterCommand: FindPowerPlantByFilterCommand,
    $paginate: Paginate
  ) {
    findPowerPlantByFilter(
      findPowerPlantByFilterCommand: $findPowerPlantByFilterCommand
      paginate: $paginate
    ) {
      ${commonResponse}
      ${paginationInfo}
      powerPlantSuccess {
        id
        name
        isActive
        power
        operationStartDate
        consumerUnit
        powerCompanyReadingDay
        powerPlantProductionReadingDay
        powerCompany {
          id
          name
        }
        powerPlantAddress {
          id
          ruralName
          city
          state
        }
        accounts {
          id
          bank
          agency
          agencyDigit
          account
          accountDigit
          isMainAccount
        }
      }
    }
  }
`

export const USINA_MUTATION = gql`
  mutation savePowerPlant ($registerPowerPlantCommand: RegisterPowerPlantCommand) {
    registerOrUpdatePowerPlant (registerPowerPlantCommand: $registerPowerPlantCommand) {
      ${commonResponse}
      powerPlantSuccess {
        id,
        name
      }
    }
  }
`

export const USINA_QUERY = gql`
  query findPowerPlantById ($id: Long!) {
    findPowerPlantById(id: $id) {
      ${commonResponse}
      powerPlantSuccess {
        id
        name
        isActive
        power
        operationStartDate
        consumerUnit
        powerCompanyReadingDay
        powerPlantProductionReadingDay
        powerCompany {
          id
          name
        }
        powerPlantAddress {
          id
          ruralName
          city
          state
        }
        accounts {
          id
          bank
          agency
          agencyDigit
          account
          accountDigit
          isMainAccount
        }
      }
    }
  }
`

import React from 'react'
import { Modal } from 'react-bootstrap'
import { ButtonsContainer, PrimaryButton, SecondaryButton } from 'components'
import TextInput from 'components/Bootstrap/TextInput'
import useProjetos from 'repositories/useProjetos'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Text
} from './styles'

const sucessMessage = 'Proposta cancelada com sucesso'

type TProps = {
  isLoading?: boolean
  id: string
  modalIsOpen: boolean
  closeModal(): void
  reloadProjects?(): void
}

const validationSchema = Yup.object().shape({
  reason: Yup.string().required('O motivo é obrigatório')
})

const initialValues = {
  reason: ''
}

const CancelationModal = (props: TProps) => {
  const {
    modalIsOpen,
    closeModal,
    isLoading = false,
    id,
    reloadProjects
  } = props
  const repository = useProjetos()

  const { addToast } = useToasts()
  const history = useHistory()

  const onSubmit = async () => {
    const { values } = formik
    const data = {
      reason: values.reason,
      id
    }
    const response = await repository.postCancelamentoProjeto(data)
    closeModal()
    if (!response) return

    addToast(sucessMessage, {
      appearance: 'success',
      autoDismiss: true,
    })
    if (reloadProjects) {
      reloadProjects()
      return
    }

    history.push('/projetos')
  }

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: true,
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <>
      <Modal
        show={modalIsOpen}
        onHide={closeModal}
      >
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <span>Digite o motivo do cancelamento</span>
            <TextInput
              field='reason'
              value={formik.values.reason}
              handleChange={formik.handleChange}
              error={{
                isError: Boolean(formik.errors.reason),
                message: formik.errors.reason
              }}
            />

          </Modal.Body>

          <Modal.Footer>
            <Text>Deseja realmente cancelar o projeto?</Text>
            <ButtonsContainer compact>
              <SecondaryButton onClick={closeModal}>
                Não
              </SecondaryButton>

              <PrimaryButton onClick={formik.handleSubmit} isLoading={isLoading}>
                Sim
              </PrimaryButton>
            </ButtonsContainer>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default CancelationModal
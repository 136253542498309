import styled from 'styled-components'

export const Container = styled.div`
    padding: 5px 0 10px 25px;
    width: 500px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: ${({ theme }) => theme.borderRadius};
    border-width: 0.5px;
    border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.25);
    @media (max-width: 700px) {
        width: auto;
    }
`

export const PercentageContainer = styled.p`
    align-self: center;
    padding-left: 5px;
    padding-top: 32.5px;
`
import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {
  ButtonsContainer,
  FlexContainer,
  InfoText,
  PrimaryButton,
  SecondaryButton,
  FormSubTitle
} from 'components'
import { ModalBody } from '../styles'

type TProps = {
  values: TNotaFiscalForm
  modalIsOpen: boolean
  closeModal(): void
  confirmFn(): void
}

const ConfirmationModal = (props: TProps) => {
  const {
    values,
    modalIsOpen,
    closeModal,
    confirmFn
  } = props

  const onClickConfirmActionFn = () => {
    confirmFn()
    closeModal()
  }

  return (
    <>
      <Modal
        size='lg'
        show={modalIsOpen}
        onHide={closeModal}
        backdrop='static'
      >
        <Modal.Header>
          Confirmação dos dados
        </Modal.Header>
        <ModalBody>
          <Row>
            <Col>
              <FormSubTitle>
                <strong>Nota Fiscal de Produto</strong>
              </FormSubTitle>
            </Col>
          </Row>
          <FlexContainer>
            <InfoText isPartOfGroup>
                Número da Danfe
            </InfoText>
            <InfoText isPartOfGroup>
              <strong>{values.nfeDanfe}</strong>
            </InfoText>
          </FlexContainer>
          <FlexContainer>
            <InfoText isPartOfGroup>
                Chave de Acesso
            </InfoText>
            <InfoText isPartOfGroup>
              <strong>{values.nfeChaveDeAcesso}</strong>
            </InfoText>
          </FlexContainer>
          <FlexContainer>
            <InfoText isPartOfGroup>
                Valor
            </InfoText>
            <InfoText isPartOfGroup>
              <strong>{values.nfeValor}</strong>
            </InfoText>
          </FlexContainer>
          <FlexContainer>
            <InfoText isPartOfGroup>
                CNPJ
            </InfoText>
            <InfoText isPartOfGroup>
              <strong>{values.nfeCnpj}</strong>
            </InfoText>
          </FlexContainer>
          <FlexContainer>
            <InfoText isPartOfGroup>
                Razão Social
            </InfoText>
            <InfoText isPartOfGroup>
              <strong>{values.nfeRazaoSocial}</strong>
            </InfoText>
          </FlexContainer>
          <FlexContainer>
            <InfoText isPartOfGroup>
                Nome Fantasia
            </InfoText>
            <InfoText isPartOfGroup>
              <strong>{values.nfeNomeFantasia}</strong>
            </InfoText>
          </FlexContainer>
          <FlexContainer>
            <InfoText isPartOfGroup>
                Banco
            </InfoText>
            <InfoText isPartOfGroup>
              <strong>{values.nfeBanco}</strong>
            </InfoText>
          </FlexContainer>
          <FlexContainer>
            <InfoText isPartOfGroup>
                Agência / Conta bancaria
            </InfoText>
            <InfoText isPartOfGroup>
              <strong>{values.nfeAgencia} / {values.nfeContaBancaria}</strong>
            </InfoText>
          </FlexContainer>

          {values.enviarDuasNotas && (
            <>
              <hr />
              <Row>
                <Col>
                  <FormSubTitle>
                    <strong>Nota Fiscal de Serviço</strong>
                  </FormSubTitle>
                </Col>
              </Row>
              <FlexContainer>
                <InfoText isPartOfGroup>
                  Número da nota
                </InfoText>
                <InfoText isPartOfGroup>
                  <strong>{values.nfseDanfe}</strong>
                </InfoText>
              </FlexContainer>
              <FlexContainer>
                <InfoText isPartOfGroup>
                  Chave de Acesso
                </InfoText>
                <InfoText isPartOfGroup>
                  <strong>{values.nfseChaveDeAcesso}</strong>
                </InfoText>
              </FlexContainer>
              <FlexContainer>
                <InfoText isPartOfGroup>
                  Valor
                </InfoText>
                <InfoText isPartOfGroup>
                  <strong>{values.nfseValor}</strong>
                </InfoText>
              </FlexContainer>
              <FlexContainer>
                <InfoText isPartOfGroup>
                  CNPJ
                </InfoText>
                <InfoText isPartOfGroup>
                  <strong>{values.nfseCnpj}</strong>
                </InfoText>
              </FlexContainer>
              <FlexContainer>
                <InfoText isPartOfGroup>
                  Razão Social
                </InfoText>
                <InfoText isPartOfGroup>
                  <strong>{values.nfseRazaoSocial}</strong>
                </InfoText>
              </FlexContainer>
              <FlexContainer>
                <InfoText isPartOfGroup>
                  Nome Fantasia
                </InfoText>
                <InfoText isPartOfGroup>
                  <strong>{values.nfseNomeFantasia}</strong>
                </InfoText>
              </FlexContainer>
              <FlexContainer>
                <InfoText isPartOfGroup>
                  Banco
                </InfoText>
                <InfoText isPartOfGroup>
                  <strong>{values.nfseBanco}</strong>
                </InfoText>
              </FlexContainer>
              <FlexContainer>
                <InfoText isPartOfGroup>
                  Agência / Conta bancaria
                </InfoText>
                <InfoText isPartOfGroup>
                  <strong>{values.nfseAgencia} / {values.nfseContaBancaria}</strong>
                </InfoText>
              </FlexContainer>
            </>
          )}
        </ModalBody>

        <Modal.Footer>
          <span>Ao enviar não será possível editar os valores.</span>
          <ButtonsContainer compact>
            <SecondaryButton onClick={closeModal}>
              Não
            </SecondaryButton>

            <PrimaryButton
              type='button'
              onClick={onClickConfirmActionFn}
            >
              Sim, enviar
            </PrimaryButton>
          </ButtonsContainer>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmationModal
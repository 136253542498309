import styled from 'styled-components'

export const Container = styled.div`
  > div {
    max-height: 423px;
  }
`

export const TimelineContainer = styled.div`
  width:100%;
  max-width:800px;
  padding: 70px 20px;
  position: relative;
  box-shadow:  0.5rem 0.5rem 2rem 0 rgba(black, 0.2);

  &:before {
    content: '';
    position: absolute;
    top: 50px;
    left:calc(33% + 15px);
    bottom: 50px;
    width: 2.5px;
    background: ${({ theme }) => theme.colors.ORANGE};
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  } 
`

export const Entry = styled.div`
  clear: both;
  text-align: left;
  position: relative;
  font-size: 13px;
`

export const Date = styled.text `
    margin-bottom: 5px;
    float: left;
    width: 33%;
    padding-right: 15px;
    text-align: right;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      border: 4px solid ${({ theme }) => theme.colors.ORANGE};
      background-color: ${({ theme }) => theme.colors.ORANGE};
      border-radius: 100%;
      top: 15%;
      right: -13.7px;
      z-index: 99;
    }
`

export const Body = styled.div`
    display: flex;
    position: flex-end;
    width: 66%;
    padding-left: 30px;
    
    p {
      line-height: 19px;
      &:first-child {
        margin-top: 0;
        font-weight: 400;
      }
    }
`
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'

import { TableMessageRow } from 'components'
import {
  formatDecimalNumberToPercentage,
  formatDateToView
} from 'utils/helpers'
import { useGetMeusDadosUsinasCondominio } from 'repositories/clientes'
import { useAuth } from 'hooks/useAuth'

const tableMessageRowColSpan = 4
const CondominiosUsinas = () => {
  const [associacoes, setAssociacoes] = useState<TMeusDadosUsinasCondominio>()
  const { userData } = useAuth()
  const id = userData?.id
  const getMeusDadosUsinasCondominio = useGetMeusDadosUsinasCondominio()

  useEffect(() => {
    if (id) getMeusDadosUsinasCondominio.get(id)
  }, [id])

  useEffect(() => {
    if(!getMeusDadosUsinasCondominio.meusDadosUsinasCondominio) return

    setAssociacoes(getMeusDadosUsinasCondominio.meusDadosUsinasCondominio)
  }, [getMeusDadosUsinasCondominio.meusDadosUsinasCondominio])

  return (
    <Table responsive striped bordered>
      <thead>
        <tr>
          <th>Adicionado em</th>
          <th>Condomínio</th>
          <th>Usina</th>
          <th>% Contratado de Consumo</th>
        </tr>
      </thead>
      <tbody>
        {!associacoes?.clientPowerPlants.length ? (
          <TableMessageRow colSpan={tableMessageRowColSpan}>
            Sem usinas e condomínios para mostrar aqui
          </TableMessageRow>
        ) : (
          associacoes?.clientPowerPlants.map(associacao => {
            const porcentagem = formatDecimalNumberToPercentage(
              associacao.contractedPercentage
            )

            const formattedData = formatDateToView(associacao.createdDate)
            return (
              <tr key={`${associacao.id}`}>
                <td>{formattedData}</td>
                <td>{associacao.powerPlant.condominium.name}</td>
                <td>{associacao.powerPlant.name}</td>
                <td>{porcentagem}</td>
              </tr>
            )
          })
        )}
      </tbody>
    </Table>
  )
}

export default CondominiosUsinas
import React, { useState, useRef, useMemo } from 'react'
import { useFormik } from 'formik'
import { TiTimes } from 'react-icons/ti'
import { Typeahead } from 'react-bootstrap-typeahead'

import {
  Col,
  Form
} from 'react-bootstrap'

import {
  Container,
  Content,
  EmptyAccounts,
  AccountForm,
  AccountsContent,
  CustomButton
} from './styles'

import { initialValues, schema } from './validation'
import {
  PrimaryButton,
  TextField,
  MaskedTextField,
  SelectField,
  SearchField
} from 'components'
import useInformacoes from 'repositories/useInformacoes'

const contaPrincipalOptions = [
  {
    value: 'true',
    label: 'Sim'
  },
  {
    value: 'false',
    label: 'Não'
  }
]

type TProps = {
  contasBancarias: TAccountInformation[],
  updateContasBancarias(values: TAccountInformation[]): void,
  isVisualizando?: boolean
}

const ContasBancarias = (props: TProps) => {
  const {
    contasBancarias,
    updateContasBancarias,
    isVisualizando = false
  } = props
  const [bancosOptions, setBancosOptions] = useState<TSelectOption[]>([])
  const bancoRef = useRef<Typeahead<string>>(null)
  const repositoryInformacoes = useInformacoes()

  const onSubmit = () => {
    const data = formik.values
    const novaConta = {
      agency: {
        agencyNumber: data.agencia,
        bank: {
          id: data.bancoId,
          name: data.banco
        }
      },
      accountNumber: data.contaBancaria,
      masterAccount: JSON.parse(data.contaPrincipal),
      favored: data.favorecido,
      cnpj: data.cnpj
    }
    const contas = [...contasBancarias, novaConta]
    updateContasBancarias(contas)
    formik.resetForm()
    bancoRef.current?.clear()
  }

  const formik = useFormik<TContaBancariaForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })

  const onClickRemoveAccount = (indexArray: number) => () => {
    const data = contasBancarias.filter((_, index) => (
      index !== indexArray
    ))
    updateContasBancarias(data)
  }

  const onSearchBanco = (term: string) => {
    if (term.length < 3) return

    const requestBancos = async () => {
      const bancos = await repositoryInformacoes.getBancos(term)
      if (!bancos) return
      const separator = ' - '
      const _bancosOptions = bancos.map(banco => ({
        label: banco.name + separator + banco.code,
        value: String(banco.id)
      }))

      setBancosOptions(_bancosOptions)
    }

    requestBancos()
  }

  const onChangeBanco = ([banco]: TSelectOption[]) => {
    const value = banco?.value ?? ''
    const label = banco?.label ?? ''
    formik.setFieldValue('banco', label, true)
    formik.setFieldValue('bancoId', value, true)
  }

  const onBlurBanco = () => {
    formik.setFieldTouched('banco', true)
    if (!formik.values.banco) bancoRef.current?.clear()
  }

  const hasError = (name: keyof TContaBancariaForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TContaBancariaForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const isContaPrincipalSet = useMemo(() => (
    contasBancarias.some(conta => conta.masterAccount)
  ), [contasBancarias])

  return (
    <Container>
      <Form.Row>
        <AccountForm md={7}>
          <form onSubmit={formik.handleSubmit}>
            <Form.Row>
              <Col md={8}>
                <SearchField
                  required
                  label='Banco'
                  elementRef={bancoRef}
                  options={bancosOptions}
                  disabled={isVisualizando}
                  {...getCommonFieldProps('banco')}
                  onSearch={onSearchBanco}
                  onChange={onChangeBanco}
                  onBlur={onBlurBanco}
                  value={formik.values.banco}
                />
              </Col>
              <Col md={4}>
                <TextField
                  required
                  label='Agência'
                  disabled={isVisualizando}
                  {...getCommonFieldProps('agencia')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={4}>
                <SelectField
                  label='Conta Principal ?'
                  options={contaPrincipalOptions}
                  disabled={isVisualizando || isContaPrincipalSet}
                  {...getCommonFieldProps('contaPrincipal')}
                />
              </Col>
              <Col md={8}>
                <TextField
                  required
                  label='Número da Conta'
                  disabled={isVisualizando}
                  {...getCommonFieldProps('contaBancaria')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={7}>
                <TextField
                  required
                  label='Favorecido'
                  disabled={isVisualizando}
                  {...getCommonFieldProps('favorecido')}
                />
              </Col>
              <Col md={5}>
                <MaskedTextField
                  required
                  label='CNPJ'
                  mask='99.999.999/9999-99'
                  disabled={isVisualizando}
                  {...getCommonFieldProps('cnpj')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <PrimaryButton
                  type='submit'
                  disabled={isVisualizando}
                >
                  Incluir
                </PrimaryButton>
              </Col>
            </Form.Row>
          </form>
        </AccountForm>
        <AccountsContent md={5}>
          {!contasBancarias.length && (
            <EmptyAccounts>
              Necessário incluir pelo menos uma conta bancária
            </EmptyAccounts>
          )}
          {contasBancarias.map((bank, index) => (
            <Content key={index}>
              <Form.Row>
                <Col md={5}>
                  <strong>Banco </strong><br />{bank.agency.bank.name}
                </Col>
                <Col md={3}>
                  <strong>Agência </strong><br />{bank.agency.agencyNumber}
                </Col>
                <Col md={4}>
                  <strong>Conta </strong><br />{bank.accountNumber}
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md={5}>
                  <strong>Favorecido </strong><br />{bank.favored}
                </Col>
                <Col md={4}>
                  <strong>CNPJ </strong><br />{bank.cnpj}
                </Col>
                <Col md={3}>
                  <strong>Principal? </strong><br />{bank.masterAccount ? 'Sim' : 'Não'}
                </Col>
              </Form.Row>
              {!isVisualizando && (
                <CustomButton
                  className='mr-1 text-danger'
                  title='Excluir'
                  onClick={onClickRemoveAccount(index)}
                >
                  <TiTimes />
                </CustomButton>
              )}
            </Content>
          ))}
        </AccountsContent>
      </Form.Row>
    </Container>
  )
}

export default ContasBancarias
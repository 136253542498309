import { gql } from 'apollo-boost'
import { commonResponse, paginationInfo } from './constants'

export const QUERY_DISTRIBUIDORAS = gql`
  query findPowerCompanyByFilter (
    $findPowerCompanyByFilterCommand: FindPowerCompanyByFilterCommand, 
    $paginate: Paginate
  ) {
    findPowerCompanyByFilter (
      findPowerCompanyByFilterCommand: $findPowerCompanyByFilterCommand, 
      paginate: $paginate
    ) {
      ${commonResponse}
      ${paginationInfo}
      powerCompanySuccess {
        id
        name
        companyName
        cnpj
        isActive
        discountPercentage
        address {
          zipCode
          street
          number
          complement
          neighborhood
          city
          state
          uf
        }
      }
    }
  }
`

export const QUERY_DISTRIBUIDORA = gql`
  query findPowerCompanyById ($id: Long!) {
    findPowerCompanyById (id: $id) {
      ${commonResponse}
      powerCompanySuccess {
        id
        name
        companyName
        cnpj
        isActive
        discountPercentage
        address {
          zipCode
          street
          number
          complement
          neighborhood
          city
          state
        }
      }
    }
  }
`

export const MUTATION_DISTRIBUIDORA = gql`
  mutation registerOrUpdatePowerCompany (
    $registerPowerCompanyCommand: RegisterPowerCompanyCommand
  ) {
    registerOrUpdatePowerCompany (registerPowerCompanyCommand: $registerPowerCompanyCommand) {
      ${commonResponse}
    }
  }
`

export const QUERY_DISTRIBUIDORAS_SELECT = gql`
  query listPowerCompanies {
    listPowerCompanies {
      ${commonResponse}
      powerCompanySuccess {
        id
        name
      }
    }
  }
`
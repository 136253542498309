import styled from 'styled-components'
import { Card, Col } from 'react-bootstrap'
import { ActionButton } from 'components'

const border = '1px solid #ced4da'
const breakPoint = '767px'

export const Container = styled.div`
  padding: 0 5px 0 10px;
  border: ${border};
  border-radius: 4px;
  box-shadow: -1px 2px 4px 0 rgb(0 0 0 / 16%);
  margin-bottom: 15px;
`

export const Content = styled(Card)`
    font-size: 14px;
    background-color: transparent;
    border-radius: 4px;
    padding: 10px 10px;
    margin-top: 5px;

    strong {
      font-size: 13px;
    }
`

export const EmptyAccounts = styled.span`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.RED};
`

export const AccountForm = styled(Col)`
  @media screen and (min-width: ${breakPoint}) {
    padding: 10px 10px 5px 0;
    border-right: ${border};
  }
`

export const AccountsContent = styled(Col)`
  overflow-x: auto;
  height: 240px;
`

export const CustomButton = styled(ActionButton)`
  position: absolute;
  top: 0;
  right: 0;
`
import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import {
  AiOutlineEye,
  AiFillPlusSquare,
  AiOutlineCloseCircle,
  AiOutlineCheck
} from 'react-icons/ai'
import { BiPencil } from 'react-icons/bi'
import useProjetos from 'repositories/useProjetos'
import useFilters from 'hooks/useFilters'
import usePagination from 'hooks/usePagination'
import { useAuth } from 'hooks/useAuth'
import { useToasts } from 'react-toast-notifications'
import ModalPurchaseProfile from './Components/ModalPurchaseProfile'
import CancelationModal from '../Components/CancelationModal'

import {
  ActionButton,
  Loader,
  InfoText,
  PageHeader,
  PrimaryButton,
  TableMessageRow,
  Pagination,
  Filter
} from 'components'
import { formatDateTimeToView, formatCurrency } from 'utils/helpers'
import { theme } from 'styles'
import {
  StatusProgressBar,
  CustomTable,
  CustomFlexContainer,
  FlexStatusContainer,
  MaxContentRow,
  FlexButtonContainer,
  Container
} from './styles'
import {
  statusProjetosOptions,
  tipoClienteOptions,
  statusSafraProjetosOptions
} from 'utils/constants'

type TProject = {
  canBeCanceled: boolean
  statusSafra?: string
  id: string
  projectName: string
  createdDate: string
  estimatedValueOfProject: string
  proposalSafraId: string
  projectStatus: {
    id: number
    percentage: number
    description: string
  }
  client: {
    fullName: string
  }
  clientPJ: {
    corporateName: string
  }
  seller: {
    name: string
  }
  integrador: {
    companyName: string
  }
  proposalReadyToSend?: boolean
  state?: {
    id: number
    name: string
    uf: string
  }
}

const getProjetoStatusPercentageColor = (percentage: number) => {
  if (percentage < 30) return theme.colors.RED
  if (percentage === 100) return theme.colors.LIGHT_GREEN
  return theme.colors.ORANGE
}

const ListProject = () => {
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false)
  const { addToast } = useToasts()
  const [showCancelationModal, setShowCancelationModal] = useState(false)
  const [projetos, setProjetos] = useState<TProject[]>()
  const [projectToBeCanceledId, setProjectTobeCanceledId] = useState('')

  const repository = useProjetos()
  const history = useHistory()
  const filters = useFilters()
  const pagination = usePagination()
  const { userPermissions } = useAuth()
  const permissions = useMemo(() => userPermissions?.projetos, [userPermissions])

  const onClickShowModal = () => setShowCreateProjectModal(true)
  const openCancelationModal = () => setShowCancelationModal(true)
  const closeCancelationModal = () => setShowCancelationModal(false)

  const setProjectToBeCanceled = (id: string) => () => {
    setProjectTobeCanceledId(id)
    openCancelationModal()
  }

  useEffect(() => {
    if (filters.items) requestProjetos()
  }, [filters.items])

  const aprovaProjetoSafra = async (id: string) => {
    //mudança paliativa para resolver uma emergência em produção
    const data = {
      installmentId: id,
      bankId: null,
      agencyNumber: null,
      accountNumber: null,
      integrator: null,
      scrSendingDateTime: null
    }

    const response = await repository.postProposta(data)
    if(!response){
      alert('Erro na aprovação com o Safra')
      return
    }
    addToast( 'Proposta enviada com sucesso!', {
      appearance: 'success',
      autoDismiss: true
    })
    requestProjetos()
  }

  const requestProjetos = async (pageIndex?: number) => {
    const formatProjetos = (listaProjetos: TProject[]) => {
      return listaProjetos.map(projeto => {
        const formattedCriadoEm = formatDateTimeToView(projeto.createdDate)
        const formattedValorPrevisto = formatCurrency(projeto.estimatedValueOfProject)

        return {
          ...projeto,
          createdDate: formattedCriadoEm,
          estimatedValueOfProject: formattedValorPrevisto
        }
      })
    }

    const triggerLoading = pageIndex === undefined

    const _filters = filters.getObjectifiedFilterItems()
    const params = {
      pageSize: 12,
      page: pageIndex ?? 0,
      ..._filters
    }

    const _projetos = await repository.getProjetos(params, triggerLoading)
    pagination.stopLoading()
    filters.stopLoading()
    if (!_projetos) return

    const formattedProjetos = formatProjetos(_projetos.projects)
    setProjetos(formattedProjetos)
    pagination.setOptions(_projetos.number, _projetos.totalPages)
  }

  //mudança paliativa para resolver uma emergência em produção
  const printProposal = (ready:boolean|undefined, status:number) => {
    if(status === 3) return 'Aprovado'
    if(!ready) return 'Não está pronto'
    return 'Pronto para aprovação'
  }

  const handleCloseCreateProjectModal = () => setShowCreateProjectModal(false)

  const onClickGoToDetails = (id: string) => () => {
    history.push(`/projetos/detalhes/${id}`)
  }

  const onClickEdit = (id: string, projeto: any) => () => {
    history.push({
      pathname: `/projetos/editar/${id}`,
      state: { data: projeto }
    })
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <Container>
      <Row>
        <Col>
          <PageHeader title='Financiamentos'>
            {permissions?.add && (
              <PrimaryButton onClick={onClickShowModal} iconPosition='right'>
                <span>Adicionar Financiamento</span>
                <AiFillPlusSquare />
              </PrimaryButton>
            )}
            <ModalPurchaseProfile
              isOpen={showCreateProjectModal}
              closeModal={handleCloseCreateProjectModal}
            />
          </PageHeader>
        </Col>
      </Row>

      <Row>
        <Col>
          <InfoText>
            Você poderá visualizar seus financiamentos aqui.
          </InfoText>
        </Col>
      </Row>

      <Row>
        <Col>
          <CustomTable striped bordered>
            <thead>
              <tr>
                <th>Ações</th>
                <th>
                  <CustomFlexContainer>
                    <span>Código</span>
                    <Filter
                      field='id'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Proposta Safra</span>
                    <Filter
                      field='proposalId'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Financiamento</span>
                    <Filter
                      field='projectName'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Cliente</span>
                    <Filter
                      field='client'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Local</span>
                    <Filter
                      field='regional'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Tipo</span>
                    <Filter
                      field='tipoCliente'
                      filters={filters}
                      options={tipoClienteOptions}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Vendedor</span>
                    <Filter
                      field='seller'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Integrador</span>
                    <Filter
                      field='integrador'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Valor previsto</span>
                    <Filter
                      specialType='currency'
                      field='projectValue'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Status Safra</span>
                    <Filter
                      field='statusSafra'
                      filters={filters}
                      options={statusSafraProjetosOptions}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Cadastrado em</span>
                    <Filter
                      label={[
                        'Data Inicial',
                        'Data Final'
                      ]}
                      field='createDateStart|createDateEnd'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
              </tr>
            </thead>
            <tbody>
              {repository.isLoading ? (
                <TableMessageRow colSpan={14}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!projetos?.length && (
                    <TableMessageRow colSpan={14}>
                      Não foram encontrados projetos para serem listados
                    </TableMessageRow>
                  )}
                  {projetos?.map(projeto => {
                    const percentageColor = getProjetoStatusPercentageColor(
                      projeto.projectStatus.percentage
                    )
                    let projetoClientName
                    let tipoCliente
                    if (projeto.client !== null) {
                      projetoClientName = projeto.client.fullName
                      tipoCliente = 'PF'
                    } else {
                      projetoClientName = projeto.clientPJ.corporateName
                      tipoCliente = 'PJ'
                    }

                    const canBeEdited = projeto.projectStatus.id === 1
                    return (
                      <tr key={projeto.id}>
                        <td>
                          <FlexButtonContainer>
                            <ActionButton onClick={onClickGoToDetails(projeto.id)}>
                              <AiOutlineEye />
                            </ActionButton>
                            {(canBeEdited && permissions?.edit) && (
                              <ActionButton
                                title='Editar'
                                onClick={onClickEdit(projeto.id, projeto)}
                              >
                                <BiPencil />
                              </ActionButton>
                            )}
                            {(projeto.canBeCanceled && permissions?.cancel) && (
                              <ActionButton onClick={setProjectToBeCanceled(projeto.id)}>
                                <AiOutlineCloseCircle />
                              </ActionButton>
                            )}
                            {(projeto.proposalReadyToSend) && permissions?.users && (
                              <ActionButton onClick={() => {aprovaProjetoSafra(projeto.id)}}>
                                <AiOutlineCheck />
                              </ActionButton>)}
                          </FlexButtonContainer>
                        </td>
                        <td><MaxContentRow>{projeto.id}</MaxContentRow></td>
                        <td><MaxContentRow>{projeto.proposalSafraId}</MaxContentRow></td>
                        <td><MaxContentRow>{projeto.projectName}</MaxContentRow></td>
                        <td><MaxContentRow>{projetoClientName}</MaxContentRow></td>
                        <td><MaxContentRow>{projeto.state?.name}</MaxContentRow></td>
                        <td><MaxContentRow>{tipoCliente}</MaxContentRow></td>
                        <td><MaxContentRow>{projeto.seller.name}</MaxContentRow></td>
                        <td><MaxContentRow>{projeto.integrador ? projeto.integrador.companyName : '-'}</MaxContentRow></td>
                        <td><MaxContentRow>{projeto.estimatedValueOfProject}</MaxContentRow></td>
                        <td><MaxContentRow>{projeto.statusSafra}</MaxContentRow></td>
                        <td><MaxContentRow>{projeto.createdDate}</MaxContentRow></td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </CustomTable>
        </Col>
      </Row>

      <Row>
        <Col>
          <Pagination
            currentPageIndex={pagination.currentPageIndex!}
            totalPages={pagination.totalPages!}
            request={requestProjetos}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
      <CancelationModal
        closeModal={closeCancelationModal}
        modalIsOpen={showCancelationModal}
        id={projectToBeCanceledId}
        reloadProjects={requestProjetos}
      />
    </Container>
  )
}

export default ListProject

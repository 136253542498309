import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const QUERY_PIPEFY_ERRORS = gql`
    query findCardErrors(
        $paginate: Paginate,
        $findCardErrorCommand: FindCardErrorCommand
    ) {
        findCardErrors (
            paginate: $paginate,
            findCardErrorCommand: $findCardErrorCommand
        ) {
            ${commonResponse}
            paginationInfo{
                numberOfRecordsByPage
                pageNumber
                totalRecords
                totalPages
            }
            cardsErrorSuccess{
                cardId
                clientName
                errorDescription
                createDate
            }
        }
}
`
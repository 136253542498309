import styled from 'styled-components'

export const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    height: 675px;
    max-height: 675px;
  }
`

export const BoxTitle = styled.div`
  margin-bottom: 7.5px;
  display: flex;
  gap: 10px;

  svg {
    width: 25px;
    height: 25px;
    color: ${({ theme }) => theme.colors.ORANGE};
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.ORANGE};
  }
`

export const InfoContainer = styled.dl`
  color: ${({ theme }) => theme.colors.DARK_GRAY};

  div {
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY};
    padding: 10px;
  }

  div:last-child {
    border-bottom: none;
  }

  dt {
    width: 150px;
    float: left;
    color: ${({ theme }) => theme.colors.DARK_GRAY};
  }

  dd {
    flex: 1;
  }
`

export const ActionButton = styled.button`
  display: inline-flex;
  background: none;
  border: none;
  cursor: ${p => p.disabled ? 'unset' : 'pointer'};
  color: blue;
  margin: 0 5px;
  
  svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.BLACK};
  }
`
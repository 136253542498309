import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  ButtonsContainer,
  PrimaryButton,
  SecondaryButton,
  FormTitle,
  TextField,
  SearchField,
  SelectField,
  FormDivider,
  PageHeader,
  NumberField,
} from 'components'
import { Form, Col, Row, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import { Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { ModalBody } from './styles'
import { formatDateToServer } from 'utils/helpers'
import { useCreateUpdateUsina } from 'repositories/usinas'
import { TypeaheadModel, AsyncTypeahead } from 'react-bootstrap-typeahead'
import useInformacoes from 'repositories/useInformacoes'
import { useGetDistribuidorasSelect } from 'repositories/distribuidoras'
import { confirmationMessage } from 'utils/constants'
import ModalConfirmation from 'components/ModalConfirmation'
import { ApiErrorMessages } from 'hooks/useErrorHandler'

type TProps = {
  addNovaUsina(usina: TPlant): void
  usinasVinculadas: TPlant[]
  modalIsOpen: boolean
  closeModal(): void
}

const toastCreateSuccessMessage = 'Usina adicionada com sucesso!'

const validationSchema = yup.object().shape({
  nome: yup.string().required('O campo "Nome" é obrigatório'),
  tag: yup.string().required('O campo "Nome Pasta FTP / TAG" é obrigatório'),
  unidadeConsumidora: yup.number()
    .min(0, 'Insira um número maior ou igual a zero')
    .required('O campo "Unidade Consumidora" é obrigatório'),
  dataInicioOperacao: yup.date()
    .required('O campo "Data Início Operação" é obrigatório'),
  potencia: yup.number()
    .min(0, 'Insira um número maior ou igual a zero')
    .required('O campo "Potência" é obrigatório'),
  margemErro: yup.number()
    .min(0, 'Insira um número maior ou igual a zero')
    .max(100, 'Insira uma porcentagem menor ou igual a cem')
    .required('O campo "Margem de Erro" é obrigatório'),
  nomeImovelRural: yup.string()
    .required('O campo "Nome do Imóvel Rural" é obrigatório'),
  cidade: yup.string().required('O campo "Cidade" é obrigatório'),
  estado: yup.string().required('O campo "Estado" é obrigatório'),
  distribuidoraId: yup.string()
    .required('O campo "Nome da Distribuidora" é obrigatório'),
  diaLeitura: yup.number()
    .positive('Insira um dia do mês válido')
    .integer('Insira um dia do mês válido')
    .max(31, 'Insira um dia do mês válido')
    .required('O campo "Dia de Leitura" é obrigatório'),
  diaProducao: yup.number()
    .positive('Insira um dia do mês válido')
    .integer('Insira um dia do mês válido')
    .max(31, 'Insira um dia do mês válido')
    .required('O campo "Dia de Produção" é obrigatório')
})

type TUsinaForm = {
  nome: string
  tag: string
  isAtiva: string
  unidadeConsumidora: string
  dataInicioOperacao: string
  potencia: string
  margemErro: string
  nomeImovelRural: string
  cidade: string
  estado: string
  distribuidoraId: string
  diaLeitura: string
  diaProducao: string
}

const initialValues: TUsinaForm = {
  nome: '',
  tag: '',
  isAtiva: 'true',
  unidadeConsumidora: '',
  dataInicioOperacao: '',
  potencia: '',
  margemErro: '',
  nomeImovelRural: '',
  cidade: '',
  estado: '',
  distribuidoraId: '',
  diaLeitura: '',
  diaProducao: ''
}

const distribuidorasParams: TListaDistribuidorasParams = {
  findPowerCompanyByFilterCommand: {},
  paginate: {
    pageNumber: 0,
    numberOfRecordsByPage: 1000
  }
}

const ModalUsina = (props: TProps) => {
  const {
    modalIsOpen,
    closeModal,
    usinasVinculadas,
    addNovaUsina,
  } = props

  const [estadoOptions, setEstadoOptions] = useState<TSelectOption[]>([])
  const [cidadeOptions, setCidadeOptions] = useState<TypeaheadModel[]>([])
  const [distribuidoraOptions, setDistribuidoraOptions] = useState<TSelectOption[]>([])

  const { addToast } = useToasts()
  const createUsina = useCreateUpdateUsina()
  const { getEstados, getCidades } = useInformacoes()
  const getDistribuidoras = useGetDistribuidorasSelect()
  const cidadeRef = useRef<AsyncTypeahead<string>>(null)
  const { userData, userPermissions, hasFormPermission } = useAuth()
  const [ salvarEFechar, setSalvarEFechar ] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [ showModalUsinaComMesmoNome, setShowModalUsinaComMesmoNome] = useState(false)
  const inputTagRef = useRef<HTMLInputElement>(null)

  const permission = useMemo(() => {
    const permission = userPermissions?.usinas
    return hasFormPermission(permission!, location.pathname)
  }, [userPermissions])

  useEffect(() => {
    cleanForm()
  }, [modalIsOpen])

  useEffect(() => {
    const requestEstados = async () => {
      const estados = await getEstados()
      if (!estados) return

      const _estadoOptions = estados.map(estado => ({
        label: estado.name,
        value: estado.name,
        id: estado.id
      }))

      setEstadoOptions(_estadoOptions)
    }

    requestEstados()
    getDistribuidoras.get(distribuidorasParams)
  }, [])

  const focusInputTagAlreadyUsed = () => {
    if((createUsina.description === ApiErrorMessages.USINA_TAG)) {
      inputTagRef.current?.focus()
    }
  }

  useEffect(() => {
    if (!createUsina.data || createUsina.isError){
      focusInputTagAlreadyUsed()
      return
    }

    addToast(
      toastCreateSuccessMessage,
      { appearance: 'success', autoDismiss: true }
    )

    cleanForm()
    const { id, name } = createUsina.data.registerOrUpdatePowerPlant.powerPlantSuccess
    addNovaUsina({ id, name })
    if(salvarEFechar) closeModal()
  }, [createUsina.data])

  useEffect(() => {
    const populateDistribuidoras = () => {
      if (!getDistribuidoras.distribuidoras) return
      const _distribuidoraOptions = getDistribuidoras.distribuidoras.map(distribuidora => ({
        label: distribuidora.name,
        value: String(distribuidora.id)
      }))
      setDistribuidoraOptions(_distribuidoraOptions)
    }
    populateDistribuidoras()
  }, [getDistribuidoras.distribuidoras])

  const handleSubmit = () => {
    const { nome } = formik.values
    if(verificaSeExisteUsinaComMesmoNome(nome)){
      setShowModalUsinaComMesmoNome(true)
      return
    }

    onClickHandleSubmit()
  }

  const onClickHandleSubmit = () => {
    const usinaBody = createBodyUsina()
    createUsina.send(usinaBody)
    if(showModalUsinaComMesmoNome) setShowModalUsinaComMesmoNome(false)
  }

  const verificaSeExisteUsinaComMesmoNome = (usinaNome: string) => {
    return Boolean(usinasVinculadas.find( usina => usina.name === usinaNome))
  }

  const formik = useFormik<TUsinaForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: handleSubmit
  })

  const cleanForm = () => {
    formik.resetForm()
  }

  const createBodyUsina = () => {
    const { values } = formik
    const usinaBody: TUsinaInfoBody = {
      name: values.nome,
      isActive: JSON.parse(values.isAtiva),
      power: Number(values.potencia),
      operationStartDate: formatDateToServer(values.dataInicioOperacao),
      consumerUnit: Number(values.unidadeConsumidora),
      powerCompany: Number(values.distribuidoraId),
      powerCompanyReadingDay: Number(values.diaLeitura),
      powerPlantProductionReadingDay: Number(values.diaProducao),
      powerPlantAddress: {
        ruralName: values.nomeImovelRural,
        city: values.cidade,
        state: values.estado
      }
    }

    return usinaBody
  }

  const hasError = (name: keyof TUsinaForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TUsinaForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const onChangeEstado = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('estado', value, true)
    formik.setFieldValue('cidade', '')
    if (cidadeRef.current) cidadeRef.current.clear()
  }

  const onSearchCidade = (term: string) => {
    if (!formik.values.estado || term.length < 3) return

    const requestCidades = async () => {
      const estado = estadoOptions.find(estado => (
        estado.value === formik.values.estado
      ))

      const params = {
        search: term,
        stateId: String(estado!.id)
      }
      const cidades = await getCidades(params)
      if (!cidades) return

      const _cidadeOptions = cidades.map(cidade => ({
        label: cidade.description,
        value: cidade.description
      }))

      setCidadeOptions(_cidadeOptions)
    }

    requestCidades()
  }

  const onChangeCidade = ([cidade]: TSelectOption[]) => {
    const value = cidade?.value ?? ''
    formik.setFieldValue('cidade', value, true)
  }

  const onBlurCidade = () => {
    formik.setFieldTouched('cidade', true)
    if (!formik.values.cidade) cidadeRef.current?.clear()
  }

  if (userData && !permission) return <Redirect to='/acesso-negado' />

  const handleSalvarEFechar = () => setSalvarEFechar(true)
  const handleSalvarEContinuar = () => setSalvarEFechar(false)

  const handleShowConfirmationModal = () => setShowConfirmationModal(true)
  const handleHideConfirmationModal = () => setShowConfirmationModal(false)

  const handleHideModalUsinaEModalConfirmation = () => {
    setShowConfirmationModal(false)
    closeModal()
  }

  const usinaComMesmoNomeMessage =
    `Já foi vinculada uma usina com o nome: ${formik.values.nome}. Deseja criar e vincular outra com mesmo nome?`

  return (
    <>
      <Modal
        size='lg'
        show={modalIsOpen}
        onHide={closeModal}
        backdrop='static'
      >
        <ModalBody>
          <PageHeader title='Adicionar Usina' />
          <Form onSubmit={formik.handleSubmit}>
            <Form.Row>
              <Col md={12}>
                <TextField
                  label='Nome'
                  required
                  {...getCommonFieldProps('nome')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={6}>
                <NumberField
                  label='Unidade Consumidora'
                  required
                  {...getCommonFieldProps('unidadeConsumidora')}
                />
              </Col>

              <Col md={6}>
                <TextField
                  label='Nome Pasta FTP / TAG'
                  inputRef={inputTagRef}
                  required
                  description='Esta pasta é o vínculo para a comunicação com o SCADA'
                  {...getCommonFieldProps('tag')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={4}>
                <TextField
                  label='Início da Operação'
                  type='date'
                  required
                  {...getCommonFieldProps('dataInicioOperacao')}
                />
              </Col>

              <Col md={4}>
                <NumberField
                  required
                  min={0}
                  decimalScale={2}
                  label='Potência (KWp)'
                  {...getCommonFieldProps('potencia')}
                />
              </Col>

              <Col md={4}>
                <NumberField
                  required
                  decimalScale={2}
                  min={0}
                  max={100}
                  label='Margem de Erro (%)'
                  {...getCommonFieldProps('margemErro')}
                />
              </Col>
            </Form.Row>

            <FormDivider />

            <Row>
              <Col>
                <FormTitle>Localização</FormTitle>
              </Col>
            </Row>

            <Form.Row>
              <Col md={12}>
                <TextField
                  required
                  label='Nome do Imóvel Rural'
                  {...getCommonFieldProps('nomeImovelRural')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={6}>
                <SelectField
                  required
                  label='Estado'
                  options={estadoOptions}
                  {...getCommonFieldProps('estado')}
                  onChange={onChangeEstado}
                />
              </Col>

              <Col md={6}>
                <SearchField
                  required
                  label='Cidade'
                  elementRef={cidadeRef}
                  options={cidadeOptions}
                  {...getCommonFieldProps('cidade')}
                  onSearch={onSearchCidade}
                  onChange={onChangeCidade}
                  onBlur={onBlurCidade}
                  value={formik.values.cidade}
                />
              </Col>
            </Form.Row>

            <FormDivider />

            <Row>
              <Col>
                <FormTitle>Distribuidora</FormTitle>
              </Col>
            </Row>

            <Form.Row>
              <Col md={6}>
                <SelectField
                  required
                  label='Empresa'
                  options={distribuidoraOptions}
                  {...getCommonFieldProps('distribuidoraId')}
                />
              </Col>

              <Col md={3}>
                <NumberField
                  required
                  max={31}
                  label='Dia de Leitura'
                  {...getCommonFieldProps('diaLeitura')}
                />
              </Col>

              <Col md={3}>
                <NumberField
                  required
                  max={31}
                  label='Dia de Produção'
                  {...getCommonFieldProps('diaProducao')}
                />
              </Col>
            </Form.Row>
            <ButtonsContainer>
              <SecondaryButton
                type='button'
                onClick={handleShowConfirmationModal}
              >
                Cancelar
              </SecondaryButton>
              <PrimaryButton
                outline
                type='submit'
                isLoading={createUsina.isLoading}
                onClick={handleSalvarEContinuar}
              >
                <span>Salvar e criar novo </span>
              </PrimaryButton>
              <PrimaryButton
                type='submit'
                isLoading={createUsina.isLoading}
                onClick={handleSalvarEFechar}
              >
                <span>Salvar e fechar</span>
              </PrimaryButton>
            </ButtonsContainer>
            <Modal
              size='sm'
              show={showConfirmationModal}
              onHide={handleHideConfirmationModal}
            >
              <Modal.Body>
                {confirmationMessage}
              </Modal.Body>

              <Modal.Footer>
                <ButtonsContainer compact>
                  <SecondaryButton onClick={handleHideConfirmationModal}>
                  Não
                  </SecondaryButton>

                  <PrimaryButton
                    onClick={handleHideModalUsinaEModalConfirmation}
                    isLoading={createUsina.isLoading}
                  >
                  Sim
                  </PrimaryButton>
                </ButtonsContainer>
              </Modal.Footer>
            </Modal>

            <ModalConfirmation
              setIsModalOpen={setShowModalUsinaComMesmoNome}
              isModalOpen={showModalUsinaComMesmoNome}
              confirmationText={usinaComMesmoNomeMessage}
              confirmationFunction={onClickHandleSubmit}
            />

          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalUsina
import styled from 'styled-components'
import { Row, ModalBody as ModalBodyBS } from 'react-bootstrap'

export const Container = styled.div`
  form > div {
    margin-bottom: 10px;
  }
`

export const CustomFormContainer = styled(Row)`
  @media (min-width: 690px) {
    > div:first-child {
      border-right: 1px solid rgba(0,0,0,.1);
    }
  }
`

export const ModalBody = styled(ModalBodyBS)`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
`
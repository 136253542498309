import * as Yup from 'yup'
import {
  cpf as cpfValidation,
  cnpj as cnpjValidation
} from 'cpf-cnpj-validator'
import { regexCep } from 'utils/constants'
import { removeMaskGuides } from 'utils/helpers'
import moment from 'moment'

export const estadoCivilCasado: string = '2'
export const ocupacaoEmpresario = '4'
export const ocupacaoAssalariado = ['0', '2', '4']
export const nacionalidadeBrasileira = '1'

export const initialValues: TProjetoFormPJ = {
  tipoFinanciamento: '',
  finalidadeProjeto: '',
  nomeProjeto: '',
  potenciaProjeto: '',
  valorProjeto: '',
  estadoProjeto: '',
  cidadeProjetoId: '',
  cidadeProjeto: '',
  integrador: '',
  integradorId: '',
  razaoSocial: '',
  nomeFantasia: '',
  cnpj: '',
  ramo: '',
  dataConstituicao: '',
  valorFaturamentoAnualMedio: '',
  nomeGrupoEconomico: '',
  porte: '',
  atividadeEconomica: '',
  atividadeEconomicaId: '',
  nacionalidade: '',
  nacionalidadeId: '',
  patrimonioLiquido: '',
  cep: '',
  endereco: '',
  numero: '',
  bairro: '',
  complemento: '',
  cidade: '',
  cidadeId: '',
  estado: '',
  telefoneComercial: '',
  ramalComercial: '',
  celularComercial: '',
  socioAdmNome: '',
  socioAdmCpf: '',
  socioAdmEmail: '',
  socioAdmTelefone: '',
  socioAdmEstadoCivil: '',
  socioAdmCep: '',
  socioAdmEndereco: '',
  socioAdmNumero: '',
  socioAdmBairro: '',
  socioAdmEstado: '',
  socioAdmComplemento: '',
  socioAdmCidade: '',
  socioAdmCidadeId: '',
  socioAdmConjugeNome: '',
  socioAdmConjugeCpf: '',
  socioAdmConjugeOcupacao: '',
  socioAdmConjugeProfissao: '',
  socioAdmConjugeCargo: '',
  socioAdmConjugeComercialNome: '',
  socioAdmConjugeComercialCnpj: '',
  socioAdmConjugeRendaMensal: '',
  socioAdmConjugeComercialAdmissao: '',
  socioAdmEnderecamentoConjugePessoal: '',
  socioAdmConjugeCep: '',
  socioAdmConjugeEndereco: '',
  socioAdmConjugeNumero: '',
  socioAdmConjugeBairro: '',
  socioAdmConjugeEstado: '',
  socioAdmConjugeCidade: '',
  socioAdmConjugeCidadeId: '',
  socioAdmConjugeComplemento: '',
  socioAdmConjugeComercialTelefone: '',
  socioAdmConjugeComercialTelefoneRamal: '',
  avalistaNome: '',
  avalistaCpf: '',
  avalistaEmail: '',
  avalistaTelefone: '',
  avalistaEstadoCivil: '',
  avalistaCep: '',
  avalistaEndereco: '',
  avalistaNumero: '',
  avalistaBairro: '',
  avalistaEstado: '',
  avalistaCidade: '',
  avalistaCidadeId: '',
  avalistaComplemento: '',
  avalistaComercialTelefone: '',
  avalistaComercialTelefoneRamal: '',
  avalistaComercialCelular: '',
  avalistaRendaMensal: '',
  avalistaPatrimonio: '',
  avalistaConjugeNome: '',
  avalistaConjugeCpf: '',
  avalistaConjugeOcupacao: '',
  avalistaConjugeProfissao: '',
  avalistaConjugeCargo: '',
  avalistaConjugeComercialNome: '',
  avalistaConjugeComercialCnpj: '',
  avalistaConjugeRendaMensal: '',
  avalistaConjugeComercialAdmissao: '',
  avalistaConjugeCep: '',
  avalistaConjugeEndereco: '',
  avalistaConjugeNumero: '',
  avalistaConjugeBairro: '',
  avalistaConjugeEstado: '',
  avalistaConjugeCidade: '',
  avalistaConjugeCidadeId: '',
  avalistaConjugeComplemento: '',
  avalistaConjugeComercialTelefone: '',
  avalistaConjugeComercialTelefoneRamal: '',
}

export const schema = Yup.object().shape({
  tipoFinanciamento: Yup.string().required('Tipo de Financiamento é obrigatório'),
  finalidadeProjeto: Yup.string().required('Finalidade do projeto é obrigatório'),
  nomeProjeto: Yup.string().required('Nome do projeto é obrigatório'),
  potenciaProjeto: Yup.string()
    .test(
      '',
      'Potência estimada não pode ser menor que zero',
      value => (parseFloat(value!) > 0)
    ).required('Potência estimada é obrigatório'),
  valorProjeto: Yup.string().required('Valor estimado é obrigatório')
    .test(
      '',
      'Valor estimado não pode ser menor que zero',
      value => (parseFloat(value!) > 0)
    ),
  estadoProjeto: Yup.number().required('Estado é obrigatório'),
  cidadeProjeto: Yup.string().required('Cidade é obrigatório'),  

  razaoSocial: Yup.string().required('Razão Social é obrigatório'),
  nomeFantasia: Yup.string().required('Nome Fantasia é obrigatório'),
  cnpj: Yup.string().required('CNPJ é obrigatório')
    .test(
      '',
      'CNPJ não está válido',
      value => {
        const cleanCnpj = removeMaskGuides(String(value))
        const cnpjInputIsEmpty = cleanCnpj.length === 0
        return cnpjInputIsEmpty || cnpjValidation.isValid(cleanCnpj!)
      }
    ),
  dataConstituicao: Yup.date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .max(new Date, 'Data posterior a atual não é permitido')
    .required('Data de admissão é obrigatória'),
  valorFaturamentoAnualMedio: Yup.string().required('Valor de Faturamento Anual Médio é obrigatório')
    .test(
      '',
      'Valor não pode ser menor que zero',
      value => (parseFloat(value!) > 0)
    ),
  patrimonioLiquido: Yup.string().required('Patrimonio Líquido é obrigatório')
    .test(
      '',
      'Patrimonio Líquido não pode ser menor que zero',
      value => (parseFloat(value!) > 0)
    ),
  nomeGrupoEconomico: Yup.string().required('Nome do Grupo Econômico é obrigatório'),
  nacionalidade: Yup.string().required('Nacionalidade é obrigatório'),
  porte: Yup.string().required('Porte da Empresa é obrigatório'),
  ramo: Yup.string().required('Ramo de Atividade é obrigatório'),
  atividadeEconomica: Yup.string().required('Atividade Econômica é obrigatório'),
  celularComercial: Yup.string()
    .test(
      '',
      'Insira um celular válido',
      value => {
        const zeroedPhone = '(00) 00000-0000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = cleanPhone.length < 10

        return (!isPhoneIncomplete && !isPhoneZeroed)
      }
    ).required('Celular é obrigatório'),
  telefoneComercial: Yup
    .string()
    .test(
      '',
      'Insira um telefone válido',
      value => {
        const zeroedPhone = '(00) 0000-0000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = cleanPhone.length < 10

        return (!isPhoneIncomplete && !isPhoneZeroed)
      }
    ).required('Telefone é obrigatório'),

  cep: Yup
    .string()
    .required('CEP é obrigatório')
    .matches(regexCep, 'CEP não está com o formato válido'),
  endereco: Yup.string().required('Logradouro é obrigatório'),
  numero: Yup.string()
    .max(5, 'Não pode ser maior que 5 dígitos')
    .required('Número é obrigatório'),
  bairro: Yup.string().required('Bairro é obrigatório'),
  cidade: Yup.string().required('Cidade é obrigatório'),
  estado: Yup.number().required('Estado é obrigatório'),

  socioAdmNome: Yup.string().required('Nome completo é obrigatório'),
  socioAdmCpf: Yup.string().required('CPF é obrigatório')
    .test(
      '',
      'CPF não está válido',
      value => (cpfValidation.isValid(value!))
    ),
  socioAdmEmail: Yup.string().email('Entre com um email válido').required('E-mail é obrigatório'),
  socioAdmTelefone: Yup
    .string()
    .test(
      '',
      'Insira um telefone válido',
      value => {
        const zeroedPhone = '(00) 0000-0000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = cleanPhone.length < 10

        return (!isPhoneIncomplete && !isPhoneZeroed)
      }
    ).required('Telefone é obrigatório'),
  socioAdmEstadoCivil: Yup.string().required('Estado civil é obrigatório'),
  socioAdmCep: Yup
    .string()
    .required('CEP é obrigatório')
    .matches(regexCep, 'CEP não está com o formato válido'),
  socioAdmEndereco: Yup.string().required('Logradouro é obrigatório'),
  socioAdmNumero: Yup.string()
    .max(5, 'Não pode ser maior que 5 dígitos')
    .required('Número é obrigatório'),
  socioAdmBairro: Yup.string().required('Bairro é obrigatório'),
  socioAdmCidade: Yup.string().required('Cidade é obrigatório'),
  socioAdmEstado: Yup.number().required('Estado é obrigatório'),
  socioAdmConjugeNome: Yup.string()
    .when('socioAdmEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup
        .string()
        .notOneOf(
          [Yup.ref('socioAdmNome')],
          'Nome do conjuge não pode ser igual o do cliente'
        )
        .required('Nome do conjuge é obrigatório')
    }),
  socioAdmConjugeCpf: Yup.string()
    .when('socioAdmEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup
        .string()
        .notOneOf(
          [Yup.ref('socioAdmCpf')],
          'CPF do conjuge não pode ser igual o do cliente'
        )
        .test(
          '',
          'CPF não está válido',
          value => (cpfValidation.isValid(value!))
        ).required('CPF é obrigatório')
    }),
  socioAdmConjugeOcupacao: Yup.string()
    .when('socioAdmEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup.string().required('Ocupação / Setor é obrigatório')
    }),
    socioAdmConjugeProfissao: Yup.string()
    .when('socioAdmEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup.string().required('Profissão é obrigatório')
    }),
    socioAdmConjugeCargo: Yup.string()
    .when('socioAdmEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup.string().required('Cargo é obrigatório')
    }),
  socioAdmConjugeComercialNome: Yup.string()
    .when(['socioAdmEstadoCivil', 'socioAdmConjugeOcupacao'], {
      is: (estadoCivil: string, conjugeOcupacao: string) => (
        estadoCivil === estadoCivilCasado &&
        ocupacaoAssalariado.indexOf(String(conjugeOcupacao)) >= 0
      ),
      then: Yup.string().required('Nome da empresa é obrigatório')
    }),
  socioAdmConjugeComercialCnpj: Yup.string()
  .when(['socioAdmEstadoCivil', 'socioAdmConjugeOcupacao'], {
    is: (estadoCivil: string, conjugeOcupacao: string) => (
      estadoCivil === estadoCivilCasado &&
      ocupacaoAssalariado.indexOf(String(conjugeOcupacao)) >= 0
    ),
    then: Yup.string().required('CNPJ da empresa é obrigatório')
  }).test(
      '',
      'CNPJ não está válido',
      value => {
        const cleanCnpj = removeMaskGuides(String(value))
        const cnpjInputIsEmpty = cleanCnpj.length === 0
        return cnpjInputIsEmpty || cnpjValidation.isValid(cleanCnpj!)
      }
    ),
  socioAdmConjugeRendaMensal: Yup.string()
    .when('socioAdmEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup.string()
        .test(
          '',
          'Renda mensal não pode ser menor que zero',
          value => (parseFloat(value!) > 0)
        ).required('Renda mensal é obrigatório')
    }),
  socioAdmConjugeComercialAdmissao: Yup.date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .max(new Date, 'Data posterior a atual não é permitido')
    .when(['socioAdmEstadoCivil', 'socioAdmConjugeOcupacao'], {
      is: (estadoCivil: string, conjugeOcupacao: string) => (
        estadoCivil === estadoCivilCasado &&
        ocupacaoAssalariado.indexOf(String(conjugeOcupacao)) >= 0
      ),
      then: Yup.date().required('Data de admissão é obrigatória')
    }),
  socioAdmConjugeCep: Yup.string()
    .when(['socioAdmEstadoCivil', 'socioAdmConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('CEP é obrigatório')
    }).test(
      '',
      'CEP não está válido',
      value => {
        const cleanCep = removeMaskGuides(String(value))
        const cepInputIsEmpty = cleanCep.length === 0
        return cepInputIsEmpty || cleanCep.length === 8
      }
    ),
  socioAdmConjugeEndereco: Yup.string()
    .when(['socioAdmEstadoCivil', 'socioAdmConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Logradouro é obrigatório')
    }),
  socioAdmConjugeNumero: Yup.string()
    .when(['socioAdmEstadoCivil', 'socioAdmConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Número é obrigatório')
    }).max(5, 'Não pode ser maior que 5 dígitos'),
  socioAdmConjugeBairro: Yup.string()
    .when(['socioAdmEstadoCivil', 'socioAdmConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Bairro é obrigatório')
    }),
  socioAdmConjugeEstado: Yup.number()
    .when(['socioAdmEstadoCivil', 'socioAdmConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.number().required('Estado é obrigatório')
    }),
  socioAdmConjugeCidade: Yup.string()
    .when(['socioAdmEstadoCivil', 'socioAdmConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Cidade é obrigatório')
    }),
  socioAdmConjugeComercialTelefone: Yup.string()
    .when('socioAdmEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup.string()
        .test(
          '',
          'Insira um telefone válido',
          value => {
            const zeroedPhone = '(00) 0000-0000'
            const isPhoneZeroed = value === zeroedPhone

            const cleanPhone = removeMaskGuides(String(value))
            const isPhoneIncomplete = cleanPhone.length < 10

            return (!isPhoneIncomplete && !isPhoneZeroed)
          }
        ).required('Telefone é obrigatório'),
    }),


  avalistaNome: Yup.string().required('Nome completo é obrigatório'),
  avalistaCpf: Yup.string().required('CPF é obrigatório')
    .test(
      '',
      'CPF não está válido',
      value => (cpfValidation.isValid(value!))
    ),
  avalistaEmail: Yup.string().email('Entre com um email válido').required('E-mail é obrigatório'),
  avalistaTelefone: Yup
    .string()
    .test(
      '',
      'Insira um telefone válido',
      value => {
        const zeroedPhone = '(00) 0000-0000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = cleanPhone.length < 10

        return (!isPhoneIncomplete && !isPhoneZeroed)
      }
    ).required('Telefone é obrigatório'),
  avalistaEstadoCivil: Yup.string().required('Estado civil é obrigatório'),
  avalistaCep: Yup
    .string()
    .required('CEP é obrigatório')
    .matches(regexCep, 'CEP não está com o formato válido'),
  avalistaEndereco: Yup.string().required('Logradouro é obrigatório'),
  avalistaNumero: Yup.string()
    .max(5, 'Não pode ser maior que 5 dígitos')
    .required('Número é obrigatório'),
  avalistaBairro: Yup.string().required('Bairro é obrigatório'),
  avalistaCidade: Yup.string().required('Cidade é obrigatório'),
  avalistaEstado: Yup.number().required('Estado é obrigatório'),
  avalistaConjugeNome: Yup.string()
    .when('avalistaEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup
        .string()
        .notOneOf(
          [Yup.ref('avalistaNome')],
          'Nome do conjuge não pode ser igual o do cliente'
        )
        .required('Nome do conjuge é obrigatório')
    }),
  avalistaConjugeCpf: Yup.string()
    .when('avalistaEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup
        .string()
        .notOneOf(
          [Yup.ref('avalistaCpf')],
          'CPF do conjuge não pode ser igual o do cliente'
        )
        .test(
          '',
          'CPF não está válido',
          value => (cpfValidation.isValid(value!))
        ).required('CPF é obrigatório')
    }),
  avalistaConjugeOcupacao: Yup.string()
    .when('avalistaEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup.string().required('Ocupação / Setor é obrigatório')
    }),
  avalistaConjugeProfissao: Yup.string()
    .when('avalistaEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup.string().required('Profissão é obrigatório')
    }),
  avalistaConjugeCargo: Yup.string()
    .when('avalistaEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup.string().required('Cargo é obrigatório')
    }),
  avalistaConjugeComercialNome: Yup.string()
    .when(['avalistaEstadoCivil', 'avalistaConjugeOcupacao'], {
      is: (estadoCivil: string, conjugeOcupacao: string) => (
        estadoCivil === estadoCivilCasado &&
        ocupacaoAssalariado.indexOf(String(conjugeOcupacao)) >= 0
      ),
      then: Yup.string().required('Nome da empresa é obrigatório')
    }),
  avalistaConjugeComercialCnpj: Yup.string()
  .when(['avalistaEstadoCivil', 'avalistaConjugeOcupacao'], {
    is: (estadoCivil: string, conjugeOcupacao: string) => (
      estadoCivil === estadoCivilCasado &&
      ocupacaoAssalariado.indexOf(String(conjugeOcupacao)) >= 0
    ),
    then: Yup.string().required('CNPJ da empresa é obrigatório')
  }).test(
      '',
      'CNPJ não está válido',
      value => {
        const cleanCnpj = removeMaskGuides(String(value))
        const cnpjInputIsEmpty = cleanCnpj.length === 0
        return cnpjInputIsEmpty || cnpjValidation.isValid(cleanCnpj!)
      }
    ),
  avalistaConjugeRendaMensal: Yup.string()
    .when('avalistaEstadoCivil', {
      is: estadoCivilCasado,
      then: Yup.string()
        .test(
          '',
          'Renda mensal não pode ser menor que zero',
          value => (parseFloat(value!) > 0)
        ).required('Renda mensal é obrigatório')
    }),
  avalistaConjugeComercialAdmissao: Yup.date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .max(new Date, 'Data posterior a atual não é permitido')
    .when(['avalistaEstadoCivil', 'avalistaConjugeOcupacao'], {
      is: (estadoCivil: string, conjugeOcupacao: string) => (
        estadoCivil === estadoCivilCasado &&
        ocupacaoAssalariado.indexOf(String(conjugeOcupacao)) >= 0
      ),
      then: Yup.date().required('Data de admissão é obrigatória')
    }),
  avalistaConjugeCep: Yup.string()
    .when(['avalistaEstadoCivil', 'avalistaConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('CEP é obrigatório')
    }).test(
      '',
      'CEP não está válido',
      value => {
        const cleanCep = removeMaskGuides(String(value))
        const cepInputIsEmpty = cleanCep.length === 0
        return cepInputIsEmpty || cleanCep.length === 8
      }
    ),
  avalistaConjugeEndereco: Yup.string()
    .when(['avalistaEstadoCivil', 'avalistaConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Logradouro é obrigatório')
    }),
  avalistaConjugeNumero: Yup.string()
    .when(['avalistaEstadoCivil', 'avalistaConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Número é obrigatório')
    }).max(5, 'Não pode ser maior que 5 dígitos'),
  avalistaConjugeBairro: Yup.string()
    .when(['avalistaEstadoCivil', 'avalistaConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Bairro é obrigatório')
    }),
  avalistaConjugeEstado: Yup.number()
    .when(['avalistaEstadoCivil', 'avalistaConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.number().required('Estado é obrigatório')
    }),
  avalistaConjugeCidade: Yup.string()
    .when(['avalistaEstadoCivil', 'avalistaConjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (estadoCivil: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        estadoCivil === estadoCivilCasado &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Cidade é obrigatório')
    }),
  avalistaConjugeComercialTelefone: Yup.string()
  .when('avalistaEstadoCivil', {
    is: estadoCivilCasado,
    then: Yup.string()
      .test(
        '',
        'Insira um telefone válido',
        value => {
          const zeroedPhone = '(00) 0000-0000'
          const isPhoneZeroed = value === zeroedPhone

          const cleanPhone = removeMaskGuides(String(value))
          const isPhoneIncomplete = cleanPhone.length < 10

          return (!isPhoneIncomplete && !isPhoneZeroed)
        }
      ).required('Telefone é obrigatório'),
  }),
})

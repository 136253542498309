import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

export const storageKey = 'COPBACKOFFICE:userData'

const storageUserData = localStorage.getItem(storageKey)
if (storageUserData) {
  const { token } = JSON.parse(storageUserData)
  api.defaults.headers.Authorization = `Bearer ${token}`
}

export default api
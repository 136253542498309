import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { BiX } from 'react-icons/bi'
import { FormLabel } from 'components'
import {
  Container,
  Label,
  InvisibleFileField,
  Loader,
  FileDownloadContainer
} from './styles'
import { fileToBase64 } from 'utils/helpers'

type TProps = {
  required?: boolean
  name: string
  label?: string
  value: string
  description?: string
  errorMessage?: string
  isInvalid?: boolean
  setFile(name: string, content: string): void
  fileName?: string
  inCloud?: string
  disabled?: boolean
  downloadFile?(): void
  isLoading?: boolean
  accept?: string
}

const FileField = (props: TProps) => {
  const {
    required,
    label,
    name,
    value,
    errorMessage,
    description,
    isInvalid,
    setFile,
    fileName = '',
    inCloud = '',
    disabled = false,
    downloadFile,
    isLoading,
    ...rest
  } = props

  const [isProcessing, setIsProcessing] = useState(false)
  const [wasCleared, setWasCleared] = useState(false)

  const onChangeSetFile = async (event: any) => {
    if (!event) return

    const input = event.target as HTMLInputElement
    if (!input.files) return
    const rawFile = input.files[0]

    setIsProcessing(true)
    const fileContent = await fileToBase64(rawFile)
    setIsProcessing(false)
    setFile(rawFile.name, fileContent)
  }

  const onClickDownload = () => {
    if (downloadFile) downloadFile()
  }

  const onClickClearFile = () => {
    setWasCleared(true)
    setFile('', '')
  }

  return (
    <Form.Group>
      <FormLabel required={required}>
        {label}
      </FormLabel>
      {inCloud && !wasCleared ? (
        <FileDownloadContainer>
          <span>{fileName}</span>
          <div>
            {(inCloud === fileName && isLoading) && (
              <button type='button' onClick={onClickDownload}>
                <Loader animation='border' variant='secondary' />
              </button>
            )}
            {(inCloud === fileName && !isLoading) && (
              <button type='button' onClick={onClickDownload}>
                <AiOutlineCloudDownload />
              </button>
            )}
            {!disabled && (
              <button type='button' onClick={onClickClearFile}>
                <BiX />
              </button>
            )}
          </div>
        </FileDownloadContainer>
      ) : (
        <Container
          disabled={disabled}
          className={`form-control ${isInvalid && 'is-invalid'}`}
        >
          <Label htmlFor={name}>
            {isProcessing ? (
              <Loader animation='border' variant='secondary' />
            ) : (
              <span>Clique para selecionar</span>
            )}
          </Label>
          <span>{value}</span>
        </Container>
      )}


      <InvisibleFileField
        id={name}
        type='file'
        disabled={disabled}
        {...rest}
        onChange={onChangeSetFile}
      />

      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>

      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default FileField
import styled from 'styled-components'
import backgroundImage from 'assets/images/background.jpg'
import logo from 'assets/images/logoTexto.svg'
import logoIcon from 'assets/images/logoIcon.svg'

type TContainerProps = {
  isOpen: boolean
}

export const Container = styled.aside<TContainerProps>`
  width: 280px;
  height: 100vh;
  z-index: 2;
  transition: all .1s ease-in;
  overflow-y: overlay;
  direction: rtl;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-end;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  :hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, .2);
    }
  }

  @media screen and (
    max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN})
    and (min-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}
  ) {
    width: 130px;
    position: absolute;

    &:hover {
      width: 250px;

      a {
        span {
          display: block;
        }
      }

      li::before {
        display: none;
      }

      a.profileMenu {
        display: block;
      }

      h1 {
        background: url(${logo}) no-repeat;
        background-size: 125px 25px;
        width: 125px;
        height: 25px;
        text-indent: -9999px;
        overflow: hidden;
        margin: -15px 0 55px -15px;
        border: none;
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    position: absolute;
    left: ${p => p.isOpen ? '0' : '-250px'};
  }
`

export const Logo = styled.h1`
  background: url(${logo}) no-repeat;
  background-size: 125px 42px;
  width: 125px;
  height: 42px;
  text-indent: -9999px;
  overflow: hidden;
  margin: -15px 0 40px -15px;

  @media screen and (
    max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN})
    and (min-width: 501px
  ) {
    width: 40px;
    height: 40px;
    background: url(${logoIcon}) no-repeat;
    background-position: center;
    background-size: 40px 40px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border: 2px solid ${({ theme }) => theme.colors.WHITE};
    margin-left: 0;
    margin-bottom: 40px;
  }
`

export const InnerContainer = styled.div`
  flex: 1;
  padding: 30px 30px 0 30px;
  width: 250px;
  background: url(${backgroundImage});
  box-shadow: 3px 0px 10px 0 rgb(0 0 0 / 25%);
  direction: ltr;

  @media screen
    and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN})
    and (min-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN})
  {
    width: 100px;

    &:hover {
      width: 250px;
    }
  }
`

export const Menu = styled.ul`
  list-style: none;
  padding: 0;
`
import React, { useMemo, useEffect, useState } from 'react'
import { useAuth } from 'hooks/useAuth'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import {
  useGetPipefyErrors,
  TFindCardErrorsParams,
  TFindCardErrorsInfo
} from 'repositories/pipefy'

import { initialPaginationParams } from 'utils/constants'
import { formatDateToView } from 'utils/helpers'

import { Redirect } from 'react-router-dom'
import { AiOutlineEye } from 'react-icons/ai'
import {
  PageHeader,
  ActionButton,
  Pagination,
  Filter,
  FlexContainer,
  TableMessageRow
} from 'components'
import {
  Row,
  Col,
  Table,
  Modal
} from 'react-bootstrap'
import { ModalText } from './styles'

const initialParams: TFindCardErrorsParams = {
  findCardErrorCommand: {},
  ...initialPaginationParams
}
const modalAllFalse: boolean[] = [false, false, false, false, false, false, false, false, false, false]

const ListarErrosPipefy = () => {
  const { userPermissions } = useAuth()
  const pagination = usePagination()
  const filters = useFilters()
  const getPipefyErrors = useGetPipefyErrors()
  const [pipefyErrors, setPipefyErrors] = useState<TFindCardErrorsInfo[]>([])
  const [paginationInfo, setPaginationInfo] = useState<TPageProperties>()
  const [showDetailsModal, setShowDetailsModal] = useState<boolean[]>(modalAllFalse)

  const handleHideDetailsModal = () => setShowDetailsModal(modalAllFalse)
  const handleShowDetailsModal = (id: number) => {
    const _modal = showDetailsModal.map((modal, index) => {
      if(id === index) return true
      return modal
    })
    setShowDetailsModal(_modal)
  }

  useEffect(() => {
    getPipefyErrors.get(initialParams)
  }, [])

  useEffect(() => {
    if(!getPipefyErrors.errors) return
    setPipefyErrors(getPipefyErrors.errors)
  }, [getPipefyErrors.errors])

  useEffect(() => {
    requestPipefyErrors()
  }, [filters.items])

  useEffect(() => {
    pagination.stopLoading()
    filters.stopLoading()

    if (!getPipefyErrors.paginationInfo) return
    if(!getPipefyErrors.errors) return

    setPipefyErrors(getPipefyErrors.errors)

    const { paginationInfo } = getPipefyErrors
    setPaginationInfo({
      pageNumber: paginationInfo.pageNumber,
      totalPages: paginationInfo.totalPages
    })
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)

  }, [getPipefyErrors.paginationInfo])

  const requestPipefyErrors = (pageIndex = 0, triggerLoading = false) => {
    const _params: TFindCardErrorsParams = {
      findCardErrorCommand: {
        ...filters.getObjectifiedFilterItems(),
      },
      paginate: {
        pageNumber: pageIndex,
        numberOfRecordsByPage: 10
      }
    }
    getPipefyErrors.get(_params, triggerLoading)
  }

  const permissions = useMemo(() => userPermissions?.pipefy, [userPermissions])

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />
  return (
    <>
      <PageHeader title='Erros Pipefy' />
      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>ID</span>
                    <Filter
                      field='cardId'
                      specialType='number'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Cliente</span>
                    <Filter
                      field='clientName'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Data de Integração</span>
                    <Filter
                      label={[
                        'Data Início',
                        'Data Fim'
                      ]}
                      field='createDateStart|createDateEnd'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <span>Visualizar Erros</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {pipefyErrors.length === 0 &&
              <TableMessageRow colSpan={7}>
                      Não foram encontrados erros para serem listados
              </TableMessageRow>}
              {pipefyErrors.map((erro, index) => {
                return(
                  <tr key={index}>
                    <td>{erro.cardId}</td>
                    <td>{erro.clientName}</td>
                    <td>{formatDateToView(erro.createDate)}</td>
                    <td>
                      <ActionButton
                        title='Mostrar detalhes'
                        onClick={() => handleShowDetailsModal(index)}
                      >
                        <AiOutlineEye />
                      </ActionButton>
                      <Modal
                        size='lg'
                        show={showDetailsModal[index]}
                        onHide={handleHideDetailsModal}
                      >
                        <Modal.Header closeButton>
                          <PageHeader title='Visualizar Erros' />
                        </Modal.Header>
                        <Modal.Body>
                          <ModalText>{erro.errorDescription}</ModalText>
                        </Modal.Body>
                      </Modal>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination
            currentPageIndex={paginationInfo?.pageNumber}
            totalPages={paginationInfo?.totalPages}
            request={requestPipefyErrors}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}

export default ListarErrosPipefy
import styled from 'styled-components'
import contentArrow from 'assets/images/contentArrow.svg'

type TContainerProps = {
  isActive?: boolean
}

export const Container = styled.li<TContainerProps>`
  margin-bottom: 20px;

  ::before {
    content: "";
    position: absolute;
    margin-top: -20px;
    left: 230px;
    width: 40px; 
    height: 68px;
    background: url(${contentArrow}) no-repeat;
    background-size: 50px;
    display: ${p => p.isActive ? 'block' : 'none'};
    transition: all .1s ease-in;

    @media screen and (
      max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN})
      and (min-width: 501px
    ) {
      left: 80px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
      display: none;
    }
  }

  a {
    line-height: 22px;
    color: ${({ theme }) => theme.colors.WHITE};
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    display: flex;

    :hover {
      text-decoration: underline;
    }

    span {
      margin-right: 18px;
      white-space: nowrap;
      @media screen and (
        max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN})
        and (min-width: 501px
      ) {
        display: none;
      }
    }

    i {
      display: inline-block;
      width: 30px;
      min-width: 30px;
      margin-left: 10px;
      margin-right: 10px;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    > svg {
      width: 15px;
      height: 15px;
      margin-top: 3px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
      font-weight: ${p => p.isActive ? '700' : '500'};
    }
  }
`
import SockJS from 'sockjs-client'
const Stomp = require('stompjs')

const useSocket = () => {
  const socketUrl = process.env.REACT_APP_SOCKET_URL as string

  const socketConnect = () => {
    const options: SockJS.Options = {
      timeout: 5000
    }
    const socket = new SockJS(socketUrl, options)
    const stompClient = Stomp.over(socket)
    return stompClient
  }

  return {
    socketConnect
  }
}

export default useSocket
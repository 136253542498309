import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Form, Col } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import {
  useGetValidacaoCodigoConvite,
  useCreateClienteUser
} from 'repositories/clientes'
import { ApiErrorMessages } from 'hooks/useErrorHandler'
import {
  UnsignedContainer,
  Box,
  TextField,
  PrimaryButton,
  Logo,
  InfoText,
  CenteredContainer
} from 'components'
import { senhaRegex } from 'utils/constants'

const successMessage = 'Senha criada com sucesso! Agora utilize seu e-mail e senha recém criada para ter acesso a nosso sistema!'

const validationSchema = yup.object().shape({
  nome: yup.string().required('O campo "Nome" é obrigatório'),
  senha: yup.string().required('O campo "Senha" é obrigatório')
    .matches(
      senhaRegex,
      { excludeEmptyString: true
        , message: 'A senha precisa ter pelo menos 8 caracteres, sendo um caractere especial, letras e números.' }
    ),
  confirmacaoSenha: yup.string()
    .required('O campo "Confirmação de Senha" é obrigatório')
    .oneOf(
      [yup.ref('senha'), ''],
      'Os campos "Senha" e "Confirmação de Senha" não conferem'
    )
})

type TClienteVinculo = {
  nome: string,
  email: string,
  senha: string,
  confirmacaoSenha: string
}

const initialValues: TClienteVinculo = {
  nome: '',
  email: '',
  senha: '',
  confirmacaoSenha: ''
}

const Vinculo = () => {
  const { token } = useParams<{ token: string}>()
  const history = useHistory()
  const getValidacaoCodigoConvite = useGetValidacaoCodigoConvite()
  const createClienteUser = useCreateClienteUser()
  const { addToast } = useToasts()

  useEffect(() => {
    if (token) getValidacaoCodigoConvite.get(token)
  }, [token])

  useEffect(() => {
    if (getValidacaoCodigoConvite.mensagem === ApiErrorMessages.EXPIRED_TOKEN) {
      history.push('/entrar')
      return
    }
    if (!getValidacaoCodigoConvite.info) return

    const { name, email } = getValidacaoCodigoConvite.info
    formik.setFieldValue('nome', name)
    formik.setFieldValue('email', email)
  }, [getValidacaoCodigoConvite.info])

  useEffect(() => {
    if (!createClienteUser.data) return
    if (createClienteUser.isError) return
    addToast(
      successMessage,
      { appearance: 'success', autoDismiss: true }
    )
    history.push('/entrar')
  }, [createClienteUser.data])

  const onSubmit = () => {
    const { nome, email, senha } = formik.values
    const data = {
      name: nome,
      email,
      passwordHash: senha,
      authToken: token
    }

    createClienteUser.send(data)
  }

  const formik = useFormik<TClienteVinculo>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit
  })

  const hasError = (name: keyof TClienteVinculo) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TClienteVinculo) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  return (
    <UnsignedContainer>
      <Box width={600}>
        <CenteredContainer>
          <Logo>Copérnico Energia Renovável</Logo>
          <InfoText>Confirme seus dados e crie uma senha para ter acesso a nosso sistema</InfoText>
        </CenteredContainer>

        <Form onSubmit={formik.handleSubmit}>
          <Form.Row>
            <Col>
              <TextField
                label='Nome / Razão Social'
                required
                {...getCommonFieldProps('nome')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <TextField
                label='Email'
                disabled
                {...getCommonFieldProps('email')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <TextField
                label='Senha'
                type='password'
                required
                {...getCommonFieldProps('senha')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <TextField
                label='Confirmação de Senha'
                type='password'
                required
                {...getCommonFieldProps('confirmacaoSenha')}
              />
            </Col>
          </Form.Row>

          <CenteredContainer>
            <PrimaryButton
              type='submit'
              isLoading={createClienteUser.isLoading}
            >
              Confirmar
            </PrimaryButton>
          </CenteredContainer>
        </Form>
      </Box>
    </UnsignedContainer>
  )
}

export default Vinculo
import { Options } from 'react-toast-notifications'

export const initialPaginationParams = {
  paginate: {
    pageNumber: 0,
    numberOfRecordsByPage: 10
  }
}

export enum TiposEndereco {
  RUA = 'Rua',
  AVENIDA = 'Avenida',
  TRAVESSA = 'Travessa',
  ALAMEDA = 'Alameda',
  PRACA = 'Praça',
  BECO = 'Beco',
  RODOVIA = 'Rodovia',
  LARGO = 'Largo',
  VILA = 'Vila',
  QUADRA = 'Quadra',
  CONJUNTO = 'Conjunto',
  CONDOMINIO = 'Condomínio'
}

export const tipoEnderecoOptions = [
  {
    label: TiposEndereco.RUA,
    value: TiposEndereco.RUA
  },
  {
    label: TiposEndereco.AVENIDA,
    value: TiposEndereco.AVENIDA
  },
  {
    label: TiposEndereco.TRAVESSA,
    value: TiposEndereco.TRAVESSA
  },
  {
    label: TiposEndereco.ALAMEDA,
    value: TiposEndereco.ALAMEDA
  },
  {
    label: TiposEndereco.PRACA,
    value: TiposEndereco.PRACA
  },
  {
    label: TiposEndereco.BECO,
    value: TiposEndereco.BECO
  },
  {
    label: TiposEndereco.RODOVIA,
    value: TiposEndereco.RODOVIA
  },
  {
    label: TiposEndereco.LARGO,
    value: TiposEndereco.LARGO
  },
  {
    label: TiposEndereco.VILA,
    value: TiposEndereco.VILA
  },
  {
    label: TiposEndereco.QUADRA,
    value: TiposEndereco.QUADRA
  },
  {
    label: TiposEndereco.CONJUNTO,
    value: TiposEndereco.CONJUNTO
  },
  {
    label: TiposEndereco.CONDOMINIO,
    value: TiposEndereco.CONDOMINIO
  },
]

export enum IconSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum PessoaTipo {
  FISICA = 'F',
  JURIDICA = 'J'
}

export enum ActivationStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  WAITING = 'AWAITING_FIRST_APP_ACCESS'
}

export enum Sexo {
  MASCULINO = 'MASCULINO',
  FEMININO = 'FEMININO'
}

export enum OrgaoEmissor {
  SSP = 'SSP',
  SSPDS = 'SSPDS',
  SESP = 'SESP',
  SESED = 'SESED',
  SESDEC = 'SESDEC',
  CGPI_DIREX_DPF = 'CGPI/DIREX/DPF',
  DPF = 'DPF',
}

export enum TipoFinanciamento {
  SERVICO = 'Serviço',
  PRODUTO = 'Produto',
  SERVICO_PRODUTO = 'Serviço e Produto',
}

export enum FinalidadeProjeto {
  AUTO_CONSUMO_REMOTO = 'Auto Consumo Remoto',
  GERACAO_PROPRIA = 'Geração na própria Carga',
  CONDOMINIO = 'Condomínio',
  GERACAO_COMPARTILHADA = 'Geração Compartilhada',
}

export enum StatusDocumento {
  NAO_ENVIADO = 1,
  AGUARDANDO_APROVACAO_BANCO = 2,
  APROVADO_PELO_BANCO = 3,
  NEGADO_PELO_BANCO = 4,
  SALVO_MAS_NAO_ENVIADO = 5
}

export const tiposDocumentosApiSafraOptions = [
  {
    label: 'RG',
    value: '1'
  },
  {
    label: 'PASSAPORTE',
    value: '2'
  },
  {
    label: 'RNE',
    value: '3'
  }
]

export const finalidadeProjetoOptions = [
  {
    label: FinalidadeProjeto.AUTO_CONSUMO_REMOTO,
    value: '1'
  },
  {
    label: FinalidadeProjeto.GERACAO_PROPRIA,
    value: '2'
  },
  {
    label: FinalidadeProjeto.CONDOMINIO,
    value: '3'
  },
  {
    label: FinalidadeProjeto.GERACAO_COMPARTILHADA,
    value: '4'
  }
]

export const tipoFinanciamentoOptions = [
  {
    label: TipoFinanciamento.SERVICO,
    value: '1'
  },
  {
    label: TipoFinanciamento.PRODUTO,
    value: '2'
  },
  {
    label: TipoFinanciamento.SERVICO_PRODUTO,
    value: '3'
  }
]

export const monthOptions = [
  {
    label: 'JANEIRO',
    value: 'JANUARY'
  },
  {
    label: 'FEVEREIRO',
    value: 'FEBRUARY'
  },
  {
    label: 'MARÇO',
    value: 'MARCH'
  },
  {
    label: 'ABRIL',
    value: 'APRIL'
  },
  {
    label: 'MAIO',
    value: 'MAY'
  },
  {
    label: 'JUNHO',
    value: 'JUNE'
  },
  {
    label: 'JULHO',
    value: 'JULY'
  },
  {
    label: 'AGOSTO',
    value: 'AUGUST'
  },
  {
    label: 'SETEMBRO',
    value: 'SEPTEMBER'
  },
  {
    label: 'OUTUBRO',
    value: 'OCTOBER'
  },
  {
    label: 'NOVEMBRO',
    value: 'NOVEMBER'
  },
  {
    label: 'DEZEMBRO',
    value: 'DECEMBER'
  },
]

export const sexoOptions = [
  {
    label: 'Masculino',
    value: Sexo.MASCULINO
  },
  {
    label: 'Feminino',
    value: Sexo.FEMININO
  }
]

export const contractTypeOptions = [
  {
    label: 'COMERCIAL',
    value: 'COMMERCIAL'
  },
  {
    label: 'RESIDENCIAL',
    value: 'RESIDENTIAL'
  },
  {
    label: 'INDUSTRIAL',
    value: 'INDUSTRIAL'
  },
  {
    label: 'RURAL',
    value: 'RURAL'
  },
]

export const contractStatusOptions = [
  {
    label: 'NOVO',
    value: 'NEW'
  },
  {
    label: 'AGUARDANDO CONEXÃO',
    value: 'WAITING_CONNECTION'
  },
  {
    label: 'CONECTADO',
    value: 'CONNECTED'
  },
  {
    label: 'CANCELADO',
    value: 'CANCELED'
  }
]

export const contractSubclassOptions = [
  {
    label: 'MONOFÁSICO',
    value: 'MONOFASICO'
  },
  {
    label: 'BIFÁSICO',
    value: 'BIFASICO'
  },
  {
    label: 'TRIFÁSICO',
    value: 'TRIFASICO'
  },
  {
    label: 'MÉDIA TENSÃO',
    value: 'MEDIA_TENSAO'
  },
]

export const statusContratoOptions = [
  {
    label: 'NOVO',
    value: 'NEW'
  },
  {
    label: 'AGUARDANDO CONEXÃO',
    value: 'WAITING_CONNECTION'
  },
  {
    label: 'CONECTADO',
    value: 'CONNECTED'
  },
  {
    label: 'CANCELADO',
    value: 'CANCELED'
  }
]

export const orgaoEmissorOptions = [
  {
    label: OrgaoEmissor.SSP,
    value: '1'
  },
  {
    label: OrgaoEmissor.SSPDS,
    value: '2'
  },
  {
    label: OrgaoEmissor.SESP,
    value: '3'
  },
  {
    label: OrgaoEmissor.SESED,
    value: '4'
  },
  {
    label: OrgaoEmissor.SESDEC,
    value: '5'
  },
  {
    label: OrgaoEmissor.CGPI_DIREX_DPF,
    value: '6'
  },
  {
    label: OrgaoEmissor.DPF,
    value: '7'
  },
]

export const statusOptions = [
  {
    label: 'Ativo',
    value: ActivationStatus.ACTIVATED
  },
  {
    label: 'Inativo',
    value: ActivationStatus.DEACTIVATED
  }
]

export const bankStatusOptions: TSelectOption[] = [
  {
    value: 'true',
    label: 'Bloqueado'
  },
  {
    value: 'false',
    label: 'Desbloqueado'
  }
]

export const yesNoOptions = [
  {
    label: 'Sim',
    value: 'true'
  },
  {
    label: 'Não',
    value: 'false'
  },
]

export const tipoPessoaOptions = [
  {
    label: 'Física',
    value: PessoaTipo.FISICA
  },
  {
    label: 'Jurídica',
    value: PessoaTipo.JURIDICA
  }
]

export const ufOptions = [
  {
    label: 'AC',
    value: 'AC'
  },
  {
    label: 'AL',
    value: 'AL'
  },
  {
    label: 'AP',
    value: 'AP'
  },
  {
    label: 'AM',
    value: 'AM'
  },
  {
    label: 'BA',
    value: 'BA'
  },
  {
    label: 'CE',
    value: 'CE'
  },
  {
    label: 'DF',
    value: 'DF'
  },
  {
    label: 'ES',
    value: 'ES'
  },
  {
    label: 'GO',
    value: 'GO'
  },
  {
    label: 'MA',
    value: 'MA'
  },
  {
    label: 'MS',
    value: 'MS'
  },
  {
    label: 'MT',
    value: 'MT'
  },
  {
    label: 'MG',
    value: 'MG'
  },
  {
    label: 'PA',
    value: 'PA'
  },
  {
    label: 'PB',
    value: 'PB'
  },
  {
    label: 'PR',
    value: 'PR'
  },
  {
    label: 'PE',
    value: 'PE'
  },
  {
    label: 'PI',
    value: 'PI'
  },
  {
    label: 'RJ',
    value: 'RJ'
  },
  {
    label: 'RN',
    value: 'RN'
  },
  {
    label: 'RS',
    value: 'RS'
  },
  {
    label: 'RO',
    value: 'RO'
  },
  {
    label: 'RR',
    value: 'RR'
  },
  {
    label: 'SC',
    value: 'SC'
  },
  {
    label: 'SP',
    value: 'SP'
  },
  {
    label: 'SE',
    value: 'SE'
  },
  {
    label: 'TO',
    value: 'TO'
  }
]

export const estadosOptions = [
  {
    label: 'Acre',
    value: 'Acre'
  },
  {
    label: 'Alagoas',
    value: 'Alagoas'
  },
  {
    label: 'Amapá',
    value: 'Amapá'
  },
  {
    label: 'Amazonas',
    value: 'Amazonas'
  },
  {
    label: 'Bahia',
    value: 'Bahia'
  },
  {
    label: 'Ceará',
    value: 'Ceará'
  },
  {
    label: 'Distrito Federal',
    value: 'Distrito Federal'
  },
  {
    label: 'Espírito Santo',
    value: 'Espírito Santo'
  },
  {
    label: 'Goiás',
    value: 'Goiás'
  },
  {
    label: 'Maranhão',
    value: 'Maranhão'
  },
  {
    label: 'Mato Grosso',
    value: 'Mato Grosso'
  },
  {
    label: 'Mato Grosso do Sul',
    value: 'Mato Grosso do Sul'
  },
  {
    label: 'Minas Gerais',
    value: 'Minas Gerais'
  },
  {
    label: 'Pará',
    value: 'Pará'
  },
  {
    label: 'Paraíba',
    value: 'Paraíba'
  },
  {
    label: 'Paraná',
    value: 'Paraná'
  },
  {
    label: 'Pernambuco',
    value: 'Pernambuco'
  },
  {
    label: 'Piauí',
    value: 'Piauí'
  },
  {
    label: 'Rio de Janeiro',
    value: 'Rio de Janeiro'
  },
  {
    label: 'Rio Grande do Norte',
    value: 'Rio Grande do Norte'
  },
  {
    label: 'Rio Grande do Sul',
    value: 'Rio Grande do Sul'
  },
  {
    label: 'Rondônia',
    value: 'Rondônia'
  },
  {
    label: 'Roraima',
    value: 'Roraima'
  },
  {
    label: 'Santa Catarina',
    value: 'Santa Catarina'
  },
  {
    label: 'São Paulo',
    value: 'São Paulo'
  },
  {
    label: 'Sergipe',
    value: 'Sergipe'
  },
  {
    label: 'Tocantins',
    value: 'Tocantins'
  }
]

export const estadosApiSafraOptions = [
  {
    label: 'Acre',
    value: '1'
  },
  {
    label: 'Alagoas',
    value: '2'
  },
  {
    label: 'Amazonas',
    value: '3'
  },
  {
    label: 'Amapá',
    value: '4'
  },
  {
    label: 'Bahia',
    value: '5'
  },
  {
    label: 'Ceará',
    value: '6'
  },
  {
    label: 'Distrito Federal',
    value: '7'
  },
  {
    label: 'Espírito Santo',
    value: '8'
  },
  {
    label: 'Goiás',
    value: '9'
  },
  {
    label: 'Maranhão',
    value: '10'
  },
  {
    label: 'Minas Gerais',
    value: '11'
  },
  {
    label: 'Mato Grosso do Sul',
    value: '12'
  },
  {
    label: 'Mato Grosso',
    value: '13'
  },
  {
    label: 'Pará',
    value: '14'
  },
  {
    label: 'Paraíba',
    value: '15'
  },
  {
    label: 'Pernambuco',
    value: '16'
  },
  {
    label: 'Piauí',
    value: '17'
  },
  {
    label: 'Paraná',
    value: '18'
  },
  {
    label: 'Rio de Janeiro',
    value: '19'
  },
  {
    label: 'Rio Grande do Norte',
    value: '20'
  },
  {
    label: 'Rio Grande do Sul',
    value: '22'
  },
  {
    label: 'Rondônia',
    value: '21'
  },
  {
    label: 'Roraima',
    value: '27'
  },
  {
    label: 'Santa Catarina',
    value: '23'
  },
  {
    label: 'São Paulo',
    value: '25'
  },
  {
    label: 'Sergipe',
    value: '24'
  },
  {
    label: 'Tocantins',
    value: '26'
  }
]

export const maritalStatusOptions = [
  {
    label: 'SOLTEIRO',
    value: 'SINGLE'
  },
  {
    label: 'CASADO',
    value: 'MARRIED'
  },
  {
    label: 'VIÚVO',
    value: 'WIDOWED'
  },
  {
    label: 'SEPARADO',
    value: 'SEPARATE'
  },
  {
    label: 'DIVORCIADO',
    value: 'DIVORCED'
  }
]

export const confirmationMessage = 'Todas as alterações não salvas serão perdidas. Tem certeza que deseja continuar?'

export const regexTelefone = /\(?\d{2,}\)?[ -]?\d{4,}[-\s]?\d{4}/

export const regexCelular = /\(?\d{2,}\)?[ -]?\d{5,}[-\s]?\d{4}/

export const regexCep = /^\d{2}\d{3}-\d{3}$/

export const statusSafraProjetosOptions = [
  {
    label: 'DOC PENDENTES SOL',
    value: 'DOC PENDENTES SOL'
  },
  {
    label: 'PROPOSTA ENVIADA',
    value: 'PROPOSTA ENVIADA'
  },
  {
    label: 'ANÁLISE',
    value: 'ANÁLISE'
  },
  {
    label: 'CONTATO COM CLIENTE',
    value: 'CONTATO COM CLIENTE'
  },
  {
    label: 'RECUSADA/PENDENTE',
    value: 'RECUSADA/PENDENTE'
  },
  {
    label: 'LIBERADA CHECKLIST',
    value: 'LIBERADA CHECKLIST'
  },
  {
    label: 'IMAGEM DOCS RECEBIDAS',
    value: 'IMAGEM DOCS RECEBIDAS'
  },
  {
    label: 'PAGO',
    value: 'PAGO'
  },
  {
    label: 'EXPIRADA',
    value: 'EXPIRADA'
  },
  {
    label: 'CANCELADA',
    value: 'CANCELADA'
  },
  {
    label: 'CHEKLIST RECUSADO',
    value: 'CHEKLIST RECUSADO'
  },
  {
    label: 'PAGAMENTO DEVOLVIDO',
    value: 'PAGAMENTO DEVOLVIDO'
  },
]

export const statusProjetosOptions = [
  {
    label: 'Aguardando inserção dos dados técnicos do projeto',
    value: '1'
  },
  {
    label: 'Aguardando a escolha de uma proposta de financiamento',
    value: '2'
  },
  {
    label: 'Aguardando o envio dos documentos, notas fiscais e documentos de formalização',
    value: '3'
  },
  {
    label: 'Pendência em: Documentos, Notas fiscais e/ou Documentos de formalização',
    value: '4'
  },
  {
    label: 'Contratação de crédito aprovada',
    value: '5'
  },
  {
    label: 'Pagamento em análise',
    value: '6'
  },
  {
    label: 'Projeto concluído',
    value: '7'
  },
  {
    label: 'Proposta cancelada',
    value: '8'
  },
  {
    label: 'Aguardando Aprovação de Integrador',
    value: '9'
  },
  {
    label: 'Integrador reprovado',
    value: '10'
  }
]

export const senhaRegex = /^.*(?=.{8,})((?=.+[!@#$%^&*()\-_=+{};:,<.>"'`~´]){1,50})(?=.*\d)((?=.+[a-zA-Z]){1,50}).*$/

export const tipoClienteOptions = [
  {
    label: 'Pessoa física',
    value: 'pf'
  },
  {
    label: 'Pessoa jurídica',
    value: 'pj'
  }
]

export const configToastSuccess = {
  appearance: 'success',
  autoDismiss: true
} as Options

export const configToastError = {
  appearance: 'error',
  autoDismiss: true
} as Options

import styled from 'styled-components'
import { Button } from 'react-bootstrap'

type TSecondaryButtonProps = {
  iconPosition?: 'right' | 'left'
}

export const SecondaryButton = styled(Button)<TSecondaryButtonProps>`
  background-color: ${({ theme }) => theme.colors.RED} !important;
  color: ${({ theme }) => theme.colors.WHITE};
  border: none !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${({ theme }) => theme.colors.RED};
  }

  svg {
    margin-left: ${p => p.iconPosition === 'right' ? '10px' : '0'};
    margin-right: ${p => p.iconPosition === 'left' ? '10px' : '0'};
    width: 22px;
    height: auto;
  }
`
import React from 'react'
import { ListContainer, Item } from './styles'

type TProps = {
  options: TPageOption[]
  chooseOption(index: number): void
}

const Options = (props: TProps) => {
  const { options, chooseOption } = props

  const onClickChooseOption = (index: number) => () => chooseOption(index)

  return (
    <ListContainer>
      {options.map((option, index) => (
        <Item key={`${option.label}${index}`} isActive={option.isActive}>
          <a href='#' onClick={onClickChooseOption(index)}>
            {option.label}
          </a>
        </Item>
      ))}

    </ListContainer>
  )
}

export default Options
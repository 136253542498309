import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const ALIQUOT_MUTATION = gql`
  mutation updateAliquot ($updateAliquotCommand: UpdateAliquotCommandInput!) {
    updateAliquot (updateAliquotCommand: $updateAliquotCommand) {
      ${commonResponse}
      aliquotResult {
        id,
        typeClass,
        monthValidity,
        te,
        tusd,
        pisCofins,
        icms
      }
    }
  }
`

export const ALIQUOT_QUERY = gql`
  query getAliquot ($getAliquotCommand: GetAliquotCommand) {
    getAliquot (getAliquotCommand: $getAliquotCommand) {
      ${commonResponse}
      aliquot {
        id,
        typeClass,
        monthValidity,
        te,
        tusd,
        pisCofins,
        icms
      }
    }
  }
`

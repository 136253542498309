import { useState } from 'react'
import useRest from 'hooks/useRest'

export type TEmpresaParametros = {
  corporateName: string
  fantasyName: string
  cnpj: string
  foundationYear: string
  bankAccountInformations: TAccountInformation[]
}

const useEmpresa = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getEmpresa = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/company'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  const putEmpresa = async (data: TEmpresaParametros, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/company'
    const response = await rest.put(url, data)
    if (triggerLoading) setIsLoading(false)
    if(!response) return false
    return response
  }

  return {
    isLoading,
    getEmpresa,
    putEmpresa,
  }
}

export default useEmpresa
import styled from 'styled-components'
import { FormLabel as Label } from 'react-bootstrap'

type TLabelProps = {
  underLined?: boolean
}

export const FormLabel = styled(Label)<TLabelProps>`
  display: flex;
  align-items: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  border-bottom: ${p => p.underLined
    ? `1px solid ${p.theme.colors.LIGHT_GRAY}`
    : 'none'
};

  &::after {
    content: ${p => p.required ? '"*"' : ''};
    margin-left: 5px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    border-bottom: none;
  }
`
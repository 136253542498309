import * as Yup from 'yup'
import { cnpj as cnpjValidation } from 'cpf-cnpj-validator'
import { getCurrentDate, removeMaskGuides } from 'utils/helpers'
import moment from 'moment'

export const initialValues: TIntegradorForm = {
  id: '',
  cnpj: '',
  razaoSocial: '',
  nomeFantasia: '',
  inscricaoMunicipal: '',
  numeroProjetosExecutados: '',
  potenciaInstalacao: '',
  email: '',
  telefone: '',
  dataFundacao: '',
  dataEfetivacao: getCurrentDate(),
  dataCriacao: '',
  temUsuario: '',
  criarUsuario: 'true',
  status: '',
  statusBancario: 'false',
  situacao: '',
  validade: '90',
  vigencia: '',
  cep: '',
  endereco: '',
  numero: '',
  bairro: '',
  complemento: '',
  estado: '',
  cidadeId: '',
  cidade: '',
  responsavelNome: '',
  responsavelEmail: '',
  responsavelCelular: '',
  tecnicoNome: '',
  tecnicoEmail: '',
  tecnicoCelular: '',
  tecnicoCrea: '',
  tecnicoCargo: '',
}

export const schema = Yup.object().shape({
  cnpj: Yup.string()
    .required('CNPJ é obrigatório')
    .test(
      'is-incorrect',
      'CNPJ não está válido',
      (value?: string) => (
        Boolean(cnpjValidation.isValid(String(value)))
      )
    ),
  razaoSocial: Yup.string().required('Razão Social é obrigatório'),
  inscricaoMunicipal: Yup.string().required('Inscrição Municípal é obrigatório'),
  numeroProjetosExecutados: Yup
    .number()
    .min(0, 'Número de projetos não pode ser menor que zero')
    .required('Número de projetos é obrigatório'),
  potenciaInstalacao: Yup
    .number()
    .min(0, 'Potência não pode ser menor que zero')
    .required('Potência instalada é obrigatório'),
  email: Yup
    .string()
    .email('Entre com um email válido')
    .required('E-mail é obrigatório'),
  telefone: Yup
    .string()
    .required('Telefone é obrigatório')
    .test(
      '',
      'Telefone não é válido',
      value => {
        const zeroedTelefone = '(00) 0000-0000'
        const isTelefoneZeroed = value === zeroedTelefone

        const cleanTelefone = removeMaskGuides(String(value))
        const isTelefoneIncomplete = cleanTelefone.length < 10

        return (!isTelefoneIncomplete && !isTelefoneZeroed)
      }
    ),
  dataFundacao: Yup
    .date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .max(new Date, 'Data posterior a atual não é permitido')
    .required('Data de fundação é obrigatório'),
  dataEfetivacao: Yup
    .date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .required('Data de vigência é obrigatório'),
  status: Yup.number().required('Status do cadastro é obrigatório'),
  criarUsuario: Yup.boolean(),
  situacao: Yup.number().required('Situação é obrigatório'),
  validade: Yup
    .number()
    .min(1, 'Validade não pode ser menor que zero')
    .required('Validade do integrador é obrigatório'),
  cep: Yup
    .string()
    .required('CEP é obrigatório')
    .test(
      '',
      'CEP não é válido',
      value => {
        const zeroedCep = '00000-000'
        const isCepZeroed = value === zeroedCep

        const cleanCep = removeMaskGuides(String(value))
        const isCepIncomplete = cleanCep.length < 8

        return (!isCepIncomplete && !isCepZeroed)
      }
    ),
  endereco: Yup.string().required('Endereço é obrigatório'),
  numero: Yup.string()
    .max(5, 'Não pode ser maior que 5 dígitos')
    .required('Número é obrigatório'),
  bairro: Yup.string().required('Bairro é obrigatório'),
  cidade: Yup.string().required('Cidade é obrigatório'),
  estado: Yup.number().required('Estado é obrigatório'),
  responsavelNome: Yup.string().required('Nome é obrigatório'),
  responsavelEmail: Yup
    .string()
    .email('Email não está valido')
    .required('Email é obrigatório'),
  responsavelCelular: Yup
    .string()
    .test(
      '',
      'Celular não é válido',
      value => {
        const zeroedCelular = '(00) 00000-0000'
        const isCelularZeroed = value === zeroedCelular

        const cleanCelular = removeMaskGuides(String(value))
        const isCelularIncomplete = cleanCelular.length < 11

        return (!isCelularIncomplete && !isCelularZeroed)
      }
    ).required('Celular é obrigatório'),
  tecnicoNome: Yup.string().required('Nome é obrigatório'),
  tecnicoEmail: Yup
    .string()
    .email('Email não está valido')
    .required('Email é obrigatório'),
  tecnicoCelular: Yup
    .string()
    .test(
      '',
      'Celular não é válido',
      value => {
        const zeroedCelular = '(00) 00000-0000'
        const isCelularZeroed = value === zeroedCelular

        const cleanCelular = removeMaskGuides(String(value))
        const isCelularIncomplete = cleanCelular.length < 11

        return (!isCelularIncomplete && !isCelularZeroed)
      }
    ).required('Celular é obrigatório'),
  tecnicoCrea: Yup
    .string()
    .test(
      '',
      'Crea não está com o formato válido',
      value => {
        const zeroedCrea = '000000000-0'
        const isCreaZeroed = value === zeroedCrea

        const cleanCrea = removeMaskGuides(String(value))
        const isCreaIncomplete = cleanCrea.length < 10

        return (!isCreaIncomplete && !isCreaZeroed)
      }
    ),
  tecnicoCargo: Yup
    .string()
    .required('Cargo é obrigatório')
})
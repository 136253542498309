import { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'

import { QUERY_AUDIT_TABLES, QUERY_LOGS } from './logsGql'

type TGetAuditTablesResponse = {
  findAllAuditTables: TCommonResponse &{
    auditTablesSuccess: [{
      id: number
      tableName: string
    }]
  }}

export const useGetAuditTables = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [
    requestAuditTables,
    { loading, data }
  ] = useLazyQuery<TGetAuditTablesResponse>(
    QUERY_AUDIT_TABLES, {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error)
    }
  )

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findAllAuditTables.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestAuditTables()
  }

  return {
    isLoading: loading && isLoading,
    tabelas: data?.findAllAuditTables.auditTablesSuccess,
    get
  }
}

type TLogsResponse = {
  findAuditByFilter: TCommonResponse & TPaginationInfo &{
    auditSuccess: TLogsInfo[]
  }}

export type TLogsInfo = {
  id: number
  date: string
  userName: string
  action: string
  changeDetails: string
}

export type TLogsParams = {
  auditTableId: number
  filter: TFindLogByFilter
  paginate: TPaginationParams
}

export type TFindLogByFilter = {
  registerId ?: number
  userName?: string
  action?: string
  startDate?: string
  endDate?: string
}

export const useGetLogs = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestLogs, { loading, data }] = useLazyQuery<TLogsResponse>(QUERY_LOGS, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findAuditByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TLogsParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestLogs({
      variables: params
    })
  }

  return {
    isLoading: loading && isLoading,
    logs: data?.findAuditByFilter.auditSuccess,
    paginationInfo: data?.findAuditByFilter.paginationInfo,
    get
  }
}

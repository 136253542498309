import styled from 'styled-components'
import { HiOutlineAdjustments } from 'react-icons/hi'
import { RiArrowRightSLine } from 'react-icons/ri'
import { Row } from 'react-bootstrap'
import money from 'assets/images/money.svg'
import solarPowerPanels from 'assets/images/solarPowerPanels.jpg'

export const TwistedAdjustsIcon = styled(HiOutlineAdjustments)`
  transform: rotate(90deg);
`

export const CompactRow = styled(Row)`
  > div {
    padding: 5px;
  }
`

type TBoxTitleProps = {
  centered?: boolean
}

export const BoxTitle = styled.h3<TBoxTitleProps>`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.ORANGE};
  text-align: ${p => p.centered ? 'center' : 'left'};
  white-space: nowrap;
`

export const SecondaryBoxTitle = styled.h4<TBoxTitleProps>`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  text-align: ${p => p.centered ? 'center' : 'left'};
`

export const Price = styled.span`
  display: block;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
`

export const BoxInfoList = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 10px;

  li {
    display: flex;
    gap: 7px;
    flex-wrap: wrap;
    line-height: 1;
    color: ${({ theme }) => theme.colors.DARK_GRAY};

    dt {
      font-weight: 700;
    }

    dd {
      font-weight: 300;
    }
  }
`

export const Links = styled.ul`
  list-style: none;
  margin: 0;

  li a {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.ORANGE};
    text-decoration: underline;
  }
`

type TChartContainerProps = {
  id?: string,
  color: string,
  completion: number,
  icon?: string,
}

export const ChartContainer = styled.div<TChartContainerProps>`
  background: url(${p => p.icon}) no-repeat center;
  background-size: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  min-width: 140px;

  svg {
    width: 140px;
    height: 140px;
    transform: rotate(-90deg);

    @keyframes ${p => p.id ? p.id : 'fillup'} {
      to { stroke-dasharray: ${p => p.completion} 314; }
    }

    circle {
      fill: transparent;
      stroke-width: 40;

      &:last-child {
        stroke: ${p => p.color};
        stroke-dasharray: 0 314;
        animation: ${p => p.id ? p.id : 'fillup'} 0.5s linear both;
      }

      &:first-child {
        stroke: ${({ theme }) => theme.colors.LIGHT_GRAY};
      }
    }
  }
`

export const CarouselBulletList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  justify-content: center;
  gap: 5px;
`

type TCarouselBulletProps = {
  isActive?: boolean
}

export const CarouselBullet = styled.li<TCarouselBulletProps>`
  a {
    display: block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    text-indent: -99999px;
    overflow: hidden;
    background-color: ${p => p.isActive
    ? p.theme.colors.ORANGE
    : p.theme.colors.LIGHT_GRAY
};

    &:hover {
      background-color: ${({ theme }) => theme.colors.ORANGE};
    }
  }
`

export const CarouselContent = styled.div`
  background: url(${money}) no-repeat;
  background-position: top 15px right 15px;
  background-size: 60px auto;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  padding: 46px 20px;

  span {
    display: block;
    font-size: 20px;
  }

  strong {
    display: block;
    font-size: 30px;
    font-weight: 700;
  }
`

export const EconomiaCO2List = styled.ul`
  list-style: none;
  margin: 0;

  li {
    height: 60px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 60px;
    color: ${({ theme }) => theme.colors.DARK_GRAY};
    font-size: 14px;
    line-height: 1.3;

    svg:first-child {
      width: 50px;
      height: 50px;
      margin-right: 5px;

      path {
        stroke: transparent;
      }
    }

    &:first-child svg:first-child path {
      fill: ${({ theme }) => theme.colors.YELLOW};
    }

    &:nth-child(2) svg:first-child path {
      fill: ${({ theme }) => theme.colors.ORANGE};
    }

    &:last-child {
      border-bottom: none;

      svg:first-child path {
        fill: ${({ theme }) => theme.colors.RED};
      }
    }
  }
`

export const ArrowIcon = styled(RiArrowRightSLine)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`

export const BarsChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const BarsChartGrid = styled.ul`
  position: absolute;
  list-style: none;
  margin: 0;
  width: calc(100% - 45px);

  li {
    width: 100%;
    height: 25px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  }
`

export const BarsList = styled.ul`
  width: 100%;
  height: 150px;
  list-style: none;
  margin: 0;
  display: flex;
  gap: 5px;
  align-items: flex-end;
  z-index: 1;
`

type TBarProps = {
  index?: number,
  completion: number,
  color: string,
}

export const Bar = styled.li<TBarProps>`
  height: 0;
  flex: 1;
  background-color: ${p => p.color};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  animation: ${p => `bar${p.index}`} 1s linear both;

  @keyframes ${p => `bar${p.index}`} {
    to { height: ${p => `${p.completion * 1.5}px`}; }
  }
`

type TConsumoProps = {
  type: 'high' | 'low'
}

export const Consumo = styled.span<TConsumoProps>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${p => p.type === 'high'
    ? p.theme.colors.RED
    : p.theme.colors.ORANGE
};
`

export const CurrentWeather = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  color: ${p => p.theme.colors.DARK_GRAY};

  svg {
    width: 100px;
    height: 100px;
    fill: ${p => p.theme.colors.RED};
  }
`

export const Temperature = styled.span`
  font-size: 30px;
  font-weight: 700;
`

export const WeatherChart = styled.ul`
  display: flex;
  list-style: none;
  justify-content: center;
  flex: 1;
  gap: 10px;

  li {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${p => p.theme.colors.DARK_GRAY};

    h4 {
      font-size: 14px;
      font-weight: 300;
      margin: 0;
    }

    svg {
      width: 25px;
      height: 25px;
      fill: ${p => p.theme.colors.RED};
    }

    > span {
      font-size: 13px;
      font-weight: 300;
      white-space: nowrap;
    }
  }
`

type TTemperatureBarProps = {
  index: number
  completion: number
}

export const TemperatureBar = styled.div<TTemperatureBarProps>`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;

  span {
    display: block;
    width: 100%;
    height: 0;
    background-color: ${p => p.theme.colors.ORANGE};
    border-top-right-radius: ${({ theme }) => theme.borderRadius};
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
    animation: ${p => `weatherBar${p.index}`} 1s linear both;

    @keyframes ${p => `weatherBar${p.index}`} {
      to { height: ${p => `${p.completion}px`}; }
    }
  }
`

export const UsinaPercentage = styled.span`
  font-size: 25px;
  font-weight: 700;
  color: ${p => p.theme.colors.DARK_GRAY};
`

export const UsinaProduction = styled.div`
  background: url(${solarPowerPanels}) no-repeat;
  background-size: cover;
  border-radius: ${({ theme }) => theme.borderRadius};
  height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    display: block;
    margin-left: 80px;
    color: ${p => p.theme.colors.DARK_GRAY};

    &:last-child {
      font-size: 25px;
      font-weight: 700;
    }
  }
`
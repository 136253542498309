import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { useFormik } from 'formik'
import { useToasts } from 'react-toast-notifications'
import * as yup from 'yup'
import { Form, Col, Row, Modal } from 'react-bootstrap'
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator'

import { useGetEndereco } from 'repositories/general'
import { useAuth } from 'hooks/useAuth'
import {
  FormTitle,
  PageHeader,
  PrimaryButton,
  ButtonsContainer,
  FormDivider,
  TextField,
  MaskedTextField,
  GoBackButton,
  ConfirmButton,
  SecondaryButton
} from 'components'
import { removeMaskGuides } from 'utils/helpers'
import { confirmationMessage, Variant } from 'utils/constants'
import {
  useCreateUpdateDistribuidora,
  useGetDistribuidora
} from 'repositories/distribuidoras'

const toastCreateSucessMessage = 'Distribuidora adicionada com sucesso!'
const toastUpdateSucessMessage = 'Distribuidora editada com sucesso!'

const validationSchema = yup.object().shape({
  razaoSocial: yup.string().required('O campo "Razão Social" é obrigatório'),
  nomeFantasia: yup.string().required('O campo "Nome Fantasia" é obrigatório'),
  cnpj: yup.string().test(
    '',
    'O CNPJ informado não é válido',
    value => {
      const cleanCnpj = removeMaskGuides(String(value))
      return cleanCnpj === '' || cnpjValidator.isValid(value!)
    }
  ),
  cep: yup.string()
    .test(
      '',
      'Insira um CEP válido',
      value => {
        const zeroedCEP = '00000-000'
        const isCEPZeroed = value === zeroedCEP

        const cleanCEP = removeMaskGuides(String(value))
        const isCEPIncomplete = cleanCEP.length !== 8

        return cleanCEP === '' || (!isCEPIncomplete && !isCEPZeroed)
      }
    ),
  numero: yup.number()
    .moreThan(0, 'Insira um número válido')
    .typeError('Insira um número válido'),
  porcentagemDesconto: yup.number()
    .required('O campo "Porcentagem de Desconto" é obrigatório')
    .min(0, 'Insira um número maior ou igual a zero')
    .max(100, 'Insira uma porcentagem menor ou igual a cem')
})

type TDistribuidoraForm = {
  razaoSocial: string
  nomeFantasia: string
  cnpj: string
  porcentagemDesconto: string
  cep: string
  logradouro: string
  numero: string
  bairro: string
  complemento: string
  cidade: string
  estado: string
  isAtiva: string
}

const initialValues: TDistribuidoraForm = {
  razaoSocial: '',
  nomeFantasia: '',
  cnpj: '',
  porcentagemDesconto: '',
  cep: '',
  logradouro: '',
  numero: '',
  bairro: '',
  complemento: '',
  cidade: '',
  estado: '',
  isAtiva: 'true',
}

const DistribuidorasFormulario = () => {
  const [oldDesconto, setOldDesconto] = useState<number>()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { id } = useParams<{ id: string}>()
  const location = useLocation()
  const history = useHistory()
  const { addToast } = useToasts()
  const getEndereco = useGetEndereco()
  const createUpdateDistribuidora = useCreateUpdateDistribuidora()
  const getDistribuidora = useGetDistribuidora()
  const { userData, userPermissions, hasFormPermission } = useAuth()

  const permission = useMemo(() => {
    const permission = userPermissions?.distribuidoras
    return hasFormPermission(permission!, location.pathname)
  }, [userPermissions])

  useEffect(() => {
    if (id) getDistribuidora.get(id)
  }, [id])

  useEffect(() => {
    if (!getDistribuidora.distribuidora) return

    const { distribuidora } = getDistribuidora
    const { address } = distribuidora
    const _distribuidora: TDistribuidoraForm = {
      razaoSocial: distribuidora.name,
      nomeFantasia: distribuidora.companyName,
      cnpj: distribuidora?.cnpj,
      porcentagemDesconto: String(distribuidora.discountPercentage),
      cep: address?.zipCode,
      logradouro: address?.street,
      numero: address?.number,
      bairro: address?.neighborhood,
      complemento: address?.complement,
      cidade: address?.city,
      estado: address?.state,
      isAtiva: String(distribuidora.isActive),
    }

    setOldDesconto(distribuidora.discountPercentage)

    formik.setValues(_distribuidora, true)
  }, [getDistribuidora.distribuidora])

  useEffect(() => {
    if (createUpdateDistribuidora.data) handleHideConfirmationModal()
    if (!createUpdateDistribuidora.data || createUpdateDistribuidora.isError) return

    history.push('/distribuidoras')
    addToast(
      id ? toastUpdateSucessMessage : toastCreateSucessMessage,
      { appearance: 'success', autoDismiss: true }
    )
  }, [createUpdateDistribuidora.data])

  useEffect(() => {
    if (!getEndereco.endereco) return
    const { localidade, uf, bairro, logradouro } = getEndereco.endereco

    formik.setFieldValue('cidade', localidade, true)
    formik.setFieldValue('estado', uf, true)
    formik.setFieldValue('bairro', bairro, true)
    formik.setFieldValue('logradouro', logradouro, true)
  }, [getEndereco.endereco])

  const handleSubmit = () => {
    const { values } = formik

    const body: TDistribuidoraInfo = {
      id: Number(id) ?? null,
      name: values.razaoSocial,
      companyName: values.nomeFantasia,
      cnpj: values.cnpj,
      discountPercentage: Number(values.porcentagemDesconto),
      isActive: JSON.parse(values.isAtiva),
      address: {
        zipCode: values.cep,
        street: values.logradouro,
        number: values.numero,
        neighborhood: values.bairro,
        complement: values.complemento,
        city: values.cidade,
        state: values.estado,
      },
    }

    createUpdateDistribuidora.send(body)
  }

  const handleShowConfirmationModal = () => setShowConfirmationModal(true)
  const handleHideConfirmationModal = () => setShowConfirmationModal(false)

  const onSubmit = () => {
    const { porcentagemDesconto } = formik.values
    const isPorcentagemChanged = Number(porcentagemDesconto) !== oldDesconto

    if (!isPorcentagemChanged || !id) {
      handleSubmit()
      return
    }

    handleShowConfirmationModal()
  }

  const formik = useFormik<TDistribuidoraForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit
  })

  const hasError = (name: keyof TDistribuidoraForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TDistribuidoraForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const onClickGoToLista = () => history.push('/distribuidoras')

  const onChangeCEP = (e: TInputEvent) => {
    const { value: cep } = e.target
    formik.handleChange(e)

    const cepNumbers = cep.replace(/_/g, '').replace(/-/g, '')
    if (cepNumbers.length === 8) {
      getEndereco.get(cep)
      return
    }

    formik.setFieldValue('cidade', '')
    formik.setFieldValue('estado', '')
    formik.setFieldValue('bairro', '')
    formik.setFieldValue('logradouro', '')
  }

  const isVisualizando = useMemo(() => {
    const urlAction = location.pathname.split('/')[2]
    return urlAction === 'visualizar'
  }, [location.pathname])

  const headerTitleAction = useMemo(() => {
    if (isVisualizando) return 'Visualizar'

    return id ? 'Editar' : 'Adicionar'
  }, [isVisualizando, id])

  const descontoConfirmationMessage = useMemo(() => (`
    Você alterou a porcentagem de desconto de ${oldDesconto} para 
    ${formik.values.porcentagemDesconto}. Esta alteração afetará os próximos
    faturamentos. Você confirma a alteração?
  `), [oldDesconto, formik.values.porcentagemDesconto])

  if (userData && !permission) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={!isVisualizando}
            route='/distribuidoras'
            message={confirmationMessage}
          />
        </Col>
      </Row>

      <PageHeader title={`${headerTitleAction} Distribuidora`} />

      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Col>
            <TextField
              label='Razão Social'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('razaoSocial')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <TextField
              label='Nome Fantasia'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('nomeFantasia')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <MaskedTextField
              label='CNPJ'
              mask='99.999.999/9999-99'
              placeholder='00.000.000/0000-00'
              disabled={isVisualizando}
              {...getCommonFieldProps('cnpj')}
            />
          </Col>

          <Col md={6}>
            <TextField
              required
              type='number'
              min={0}
              label='Porcentagem de Desconto'
              disabled={isVisualizando}
              {...getCommonFieldProps('porcentagemDesconto')}
            />
          </Col>
        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormTitle>Endereço</FormTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={2}>
            <MaskedTextField
              label='CEP'
              mask='99999-999'
              placeholder='00000-000'
              disabled={isVisualizando}
              {...getCommonFieldProps('cep')}
              onChange={onChangeCEP}
            />
          </Col>

          <Col md={5}>
            <TextField
              label='Cidade'
              disabled
              {...getCommonFieldProps('cidade')}
            />
          </Col>

          <Col md={5}>
            <TextField
              label='Estado'
              disabled
              {...getCommonFieldProps('estado')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={9}>
            <TextField
              label='Logradouro'
              disabled={isVisualizando}
              {...getCommonFieldProps('logradouro')}
            />
          </Col>

          <Col md={3}>
            <TextField
              label='Número'
              type='number'
              min={0}
              disabled={isVisualizando}
              {...getCommonFieldProps('numero')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Bairro'
              disabled={isVisualizando}
              {...getCommonFieldProps('bairro')}
            />
          </Col>

          <Col md={6}>
            <TextField
              label='Complemento'
              disabled={isVisualizando}
              {...getCommonFieldProps('complemento')}
            />
          </Col>
        </Form.Row>

        <ButtonsContainer>
          <ConfirmButton
            active={!isVisualizando}
            actionFn={onClickGoToLista}
            message={confirmationMessage}
            variant={Variant.SECONDARY}
          >
            <span>{isVisualizando ? 'Voltar' : 'Cancelar' }</span>
          </ConfirmButton>
          {!isVisualizando && (
            <PrimaryButton type='submit' isLoading={createUpdateDistribuidora.isLoading}>
              <span>Salvar</span>
            </PrimaryButton>
          )}
        </ButtonsContainer>
      </Form>
      <Modal
        size='sm'
        show={showConfirmationModal}
        onHide={handleHideConfirmationModal}
      >
        <Modal.Body>
          {descontoConfirmationMessage}
        </Modal.Body>

        <Modal.Footer>
          <ButtonsContainer compact>
            <SecondaryButton onClick={handleHideConfirmationModal}>
              Não
            </SecondaryButton>

            <PrimaryButton
              onClick={handleSubmit}
              isLoading={createUpdateDistribuidora.isLoading}
            >
              Sim
            </PrimaryButton>
          </ButtonsContainer>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DistribuidorasFormulario
import styled from 'styled-components'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'

export const Container = styled.section`
  margin-bottom: 45px;

  > div {
    display: flex;
  }
`

export const Picture = styled.img`
  width: 35px;
  height: 35px;
  border: 3px solid ${({ theme }) => theme.colors.WHITE};
  border-radius: 20px;
  margin-right: 13px;
`

export const Info = styled.a`
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;

  > div {
    display: flex;
  }

  h3 {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
    white-space: nowrap;
    margin: 0 18px 4px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 115px;
  }

  span {
    display: block;
    font-size: 10px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.WHITE};
  }

  @media screen
    and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN})
    and (min-width: 501px)
  {
    display: none;
  }
`

export const ArrowIconDown = styled(FaAngleDown)`
  width: 15px;
  height: 15px;
`

export const ArrowIconUp = styled(FaAngleUp)`
  width: 15px;
  height: 15px;
`

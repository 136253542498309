import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useHistory, Redirect, useParams, useLocation } from 'react-router-dom'

import cepPromise from 'cep-promise'
import { useFormik } from 'formik'
import { Row, Col, Form, } from 'react-bootstrap'
import { AsyncTypeahead, TypeaheadModel } from 'react-bootstrap-typeahead'
import { useToasts } from 'react-toast-notifications'

import { useAuth } from 'hooks/useAuth'
import useProjetos from 'repositories/useProjetos'
import useInformacoes from 'repositories/useInformacoes'
import useIntegradores from 'repositories/useIntegradores'
import {
  PageHeader,
  InfoText,
  FormTitle,
  FormSubTitle,
  FormDivider,
  ButtonsContainer,
  PrimaryButton,
  GoBackButton,
  ConfirmButton,
  TextField,
  SelectField,
  MaskedTextField,
  MaskedCurrencyField,
  SearchField
} from 'components'

import {
  formatCpfCnpj,
  formatCurrencyToServer,
  formatDateToServer,
  removeTimeFromDateTime,
  formatResponseToOptions,
  removeMaskGuides
} from 'utils/helpers'

import {
  tipoFinanciamentoOptions,
  finalidadeProjetoOptions,
  sexoOptions,
  orgaoEmissorOptions,
  estadosApiSafraOptions,
  tiposDocumentosApiSafraOptions,
  confirmationMessage,
  Variant
} from 'utils/constants'
import {
  initialValues,
  schema,
  estadoCivilCasado,
  ocupacaoAssalariado,
  ocupacaoEmpresario,
  nacionalidadeBrasileira
} from './validation'

import { basicSpouseInfo, completeSpouseInfo } from './formdata'

enum CommonFieldTypes {
  CLIENTE = 'cliente',
  CONJUGE = 'conjuge'
}

const tipoDocumentoRg = '1'

const CreateProject = () => {
  const [escolaridadesOptions, setEscolaridadesOptions] = useState<TSelectOption[]>([])
  const [estadoCivilOptions, setEstadoCivilOptions] = useState<TSelectOption[]>([])
  const [nacionalidadesOptions, setNacionalidadesOptions] = useState<TSelectOption[]>([])
  const [tiposResidenciaOptions, setTiposResidenciaOptions] = useState<TSelectOption[]>([])
  const [ocupacoesOptions, setOcupacoesOptions] = useState<TSelectOption[]>([])
  const [profissoesOptions, setProfissoesOptions] = useState<TSelectOption[]>([])
  const [cargosOptions, setCargoOptions] = useState<TSelectOption[]>([])
  const [profissoesConjugeOptions, setProfissoesConjugeOptions] = useState<TSelectOption[]>([])
  const [cargosConjugeOptions, setCargoConjugeOptions] = useState<TSelectOption[]>([])
  const [patrimonioOptions, setPatrimonioOptions] = useState<TSelectOption[]>([])
  const [cidadeOptions, setCidadeOptions] = useState<TypeaheadModel[]>([])
  const [integradoresOptions, setIntegradoresOptions] = useState<TypeaheadModel[]>([])
  const [showFieldsDocumentos, setShowFieldsDocumentos] = useState(false)
  const [isDisabledIntegradorField, setIsDisabledIntegradorField] = useState(false)
  const [isEnderecamentoComercialDisabled, setIsDisabledEnderecamentoComercialField] = useState(false)
  const [isEnderecamentoConjugeDisabled, setIsDisabledEnderecamentoConjugeField] = useState(false)
  const [isReplicate, setIsReplicate] = useState<boolean>()
  const [isConjugeRequired, setIsConjugeRequired] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { addToast } = useToasts()
  const history = useHistory()
  const location = useLocation()
  const repositoryProjetos = useProjetos()
  const repositoryInformacoes = useInformacoes()
  const { getIntegradoresByCompanyName } = useIntegradores()
  const cidadeProjetoRef = useRef<AsyncTypeahead<string>>(null)
  const cidadeRef = useRef<AsyncTypeahead<string>>(null)
  const cidadeNaturalidadeRef = useRef<AsyncTypeahead<string>>(null)
  const integradorRef = useRef<AsyncTypeahead<string>>(null)
  const conjugeCidadeRef = useRef<AsyncTypeahead<string>>(null)
  const comercialCidadeRef = useRef<AsyncTypeahead<string>>(null)
  const nacionalidadeRef = useRef<AsyncTypeahead<string>>(null)
  const { userData, userPermissions, hasFormPermission } = useAuth()

  const permission = useMemo(() => {
    const permission = userPermissions?.projetos
    return hasFormPermission(permission!, location.pathname)
  }, [userPermissions])

  useEffect(() => {
    const nullSafeString = (value?: string | number) => (
      value ? String(value) : ''
    )

    const populateForm = (projeto: TProjetoRequest) => {
      const { client } = projeto
      const {
        address,
        personalInformation,
        identificationDocument,
        occupationInformation,
        financialInformation
      } = client

      const data: TProjetoForm = {
        tipoFinanciamento: nullSafeString(projeto.typeFinancing.id),
        finalidadeProjeto: nullSafeString(projeto.projectPurpose.id),
        nomeProjeto: nullSafeString(projeto.projectName),
        potenciaProjeto: nullSafeString(projeto.estimatedPowerOfProject),
        valorProjeto: nullSafeString(projeto.estimatedValueOfProject),
        estadoProjeto: nullSafeString(projeto.state.id),
        cidadeProjeto: nullSafeString(projeto.city.description),
        cidadeProjetoId: nullSafeString(projeto.city.id),
        integrador: projeto.integrator
          ? nullSafeString(`${projeto.integrator?.companyName} - ${projeto.integrator?.cnpj}`)
          : '',
        integradorId: nullSafeString(projeto.integrator?.id),
        nomeCompleto: nullSafeString(client.fullName),
        dataNascimento: nullSafeString(formatDateToServer(client.birthDate)),
        email: nullSafeString(client.email),
        celular: nullSafeString(client.celular),
        cep: nullSafeString(address.zipCode),
        endereco: nullSafeString(address.street),
        numero: nullSafeString(address.number),
        bairro: nullSafeString(address.neighborhood),
        complemento: nullSafeString(address.complement),
        cidade: nullSafeString(address.city.description),
        cidadeId: nullSafeString(address.city.id),
        estado: nullSafeString(address.state.id),
        tipoResidencia: nullSafeString(address.addressType.id),
        nacionalidade: nullSafeString(personalInformation.nationality.description),
        nacionalidadeId: nullSafeString(personalInformation.nationality.id),
        cidadeNaturalidade: nullSafeString(personalInformation.hometown?.description),
        cidadeNaturalidadeId: nullSafeString(personalInformation.hometown?.id),
        estadoNaturalidade: nullSafeString(personalInformation.homestate?.id),
        escolaridade: nullSafeString(personalInformation.scholarity.id),
        sexo: nullSafeString(personalInformation.sex),
        estadoCivil: nullSafeString(personalInformation.maritalStatus.id),
        cpf: nullSafeString(personalInformation.cpf),
        nomeMae: nullSafeString(personalInformation.mothersName),
        tipoDocumento: nullSafeString(identificationDocument.identificationDocumentType.id),
        documento: nullSafeString(identificationDocument.documentNumber),
        orgaoEmissor: nullSafeString(identificationDocument.documentEmmiterInstitution?.id),
        estadoOrgaoEmissor: nullSafeString(identificationDocument.uf?.id),
        dataEmissao: nullSafeString(formatDateToServer(identificationDocument.documentEmissionDate)),
        ocupacao: nullSafeString(occupationInformation.professionNature.id),
        profissao: nullSafeString(occupationInformation.profession.id),
        cargo: nullSafeString(occupationInformation.occupationRole.id),
        rendaMensal: nullSafeString(financialInformation.monthlyIncome),
        patrimonio: nullSafeString(financialInformation.patrimony.id),
        comercialCnpj: nullSafeString(client.occupationInformation.companyInformation.cnpj),
        comercialNome: nullSafeString(client.occupationInformation.companyInformation.companyName),
        comercialAdmissao: removeTimeFromDateTime(nullSafeString(client.occupationInformation.companyInformation.admissionDate)),
        comercialTelefone: nullSafeString(client.occupationInformation.companyInformation.comercialPhone),
        comercialTelefoneRamal: nullSafeString(client.occupationInformation.companyInformation.phoneExtension),
        comercialEnderecoObrigatorio: 'false',
        comercialCep: nullSafeString(client.occupationInformation.companyInformation.address?.zipCode),
        comercialEndereco: nullSafeString(client.occupationInformation.companyInformation.address?.street),
        comercialNumero: nullSafeString(client.occupationInformation.companyInformation.address?.number),
        comercialComplemento: nullSafeString(client.occupationInformation.companyInformation.address?.complement),
        comercialBairro: nullSafeString(client.occupationInformation.companyInformation.address?.neighborhood),
        comercialCidade: nullSafeString(client.occupationInformation.companyInformation.address?.city?.description),
        comercialCidadeId: nullSafeString(client.occupationInformation.companyInformation.address?.city?.id),
        comercialEstado: nullSafeString(client.occupationInformation.companyInformation.address?.state?.id),
        conjugeNome: nullSafeString(client.spouse?.fullName),
        conjugeCpf: nullSafeString(client.spouse?.cpf),
        conjugeComercialCnpj: nullSafeString(client.spouse?.occupationInformation?.companyInformation?.cnpj),
        conjugeComercialNome: nullSafeString(client.spouse?.occupationInformation?.companyInformation?.companyName),
        conjugeComercialAdmissao: removeTimeFromDateTime(nullSafeString(client.spouse?.occupationInformation?.companyInformation?.admissionDate)),
        conjugeComercialTelefone: nullSafeString(client.spouse?.comercialPhone),
        conjugeComercialTelefoneRamal: nullSafeString(client.spouse?.phoneExtension),
        conjugeEnderecoObrigatorio: 'false',
        conjugeComercialCep: nullSafeString(client.spouse?.address?.zipCode),
        conjugeComercialEndereco: nullSafeString(client.spouse?.address?.street),
        conjugeComercialNumero: nullSafeString(client.spouse?.address?.number),
        conjugeComercialComplemento: nullSafeString(client.spouse?.address?.complement),
        conjugeComercialBairro: nullSafeString(client.spouse?.address?.neighborhood),
        conjugeComercialCidade: nullSafeString(client.spouse?.address?.city?.description),
        conjugeComercialCidadeId: nullSafeString(client.spouse?.address?.city?.id),
        conjugeComercialEstado: nullSafeString(client.spouse?.address?.state?.id),
        conjugeRendaMensal: nullSafeString(client.spouse?.financialInformation?.monthlyIncome),
        conjugeOcupacao: nullSafeString(client.spouse?.occupationInformation?.professionNature?.id),
        conjugeProfissao: nullSafeString(client.spouse?.occupationInformation?.profession?.id),
        conjugeCargo: nullSafeString(client.spouse?.occupationInformation?.occupationRole?.id),
        enderecamentoPessoal: nullSafeString(client.address?.enderecamentoPessoal),
        enderecamentoConjugePessoal: nullSafeString(client.address?.enderecamentoConjugePessoal),
      }
      formik.setValues(data)

      if (data.ocupacao) populateProfissoesField(CommonFieldTypes.CLIENTE, data.ocupacao)
      if (data.conjugeOcupacao) populateProfissoesField(CommonFieldTypes.CONJUGE, data.conjugeOcupacao)

      const clienteCargoParams: TCargosParams = {
        professionId: data.profissao,
        professionNatureId: data.ocupacao
      }
      if (data.profissao) populateCargoField(CommonFieldTypes.CLIENTE, clienteCargoParams)

      const conjugeCargoParams: TCargosParams = {
        professionId: data.conjugeProfissao,
        professionNatureId: data.conjugeOcupacao
      }
      if (data.conjugeProfissao) populateCargoField(CommonFieldTypes.CONJUGE, conjugeCargoParams)
    }

    const requestProjeto = async () => {
      const triggerLoading = false
      const projeto = await repositoryProjetos.getProjeto(id, triggerLoading)
      if (projeto) populateForm(projeto)
    }

    if (id) requestProjeto()
  }, [id])

  useEffect(() => {
    setIsReplicate(location.pathname.includes('replicar'))
  }, [location])

  const onSubmit = async () => {
    const { values } = formik
    const isRequiredEnderecoComercial = values.comercialEnderecoObrigatorio === 'true'
    const isRequiredEnderecoConjuge = values.conjugeEnderecoObrigatorio === 'true'

    let dados

    if (isConjugeRequired) {
      dados = completeSpouseInfo(values, isRequiredEnderecoComercial, isRequiredEnderecoConjuge)
    } else {
      dados = basicSpouseInfo(values, isRequiredEnderecoComercial, isRequiredEnderecoConjuge)
    }

    const _dados = {
      sourceProjectId: id ? parseInt(id) : null,
      ...dados
    }

    const response = (id && !isReplicate)
      ? await repositoryProjetos.putProjeto(id, dados)
      : await repositoryProjetos.postProjeto(_dados)
    if (!response) return

    history.push('/projetos')
    addToast(
      getToastMessage(),
      { appearance: 'success', autoDismiss: true }
    )
  }

  const formik = useFormik<TProjetoForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit,
  })

  useEffect(() => {
    const getInformacoes = async () => {
      const [
        tiposResidencia,
        escolaridade,
        estadoCivil,
        ocupacoes,
        patrimonio
      ] = await Promise.all([
        repositoryInformacoes.getTiposEndereco(),
        repositoryInformacoes.getEscolaridades(),
        repositoryInformacoes.getEstadosCivis(),
        repositoryInformacoes.getNaturezasProfissao(),
        repositoryInformacoes.getPatrimonios(),
      ])
      const _tiposResidencia = formatResponseToOptions(tiposResidencia)
      setTiposResidenciaOptions(_tiposResidencia)
      const _escolaridade = formatResponseToOptions(escolaridade)
      setEscolaridadesOptions(_escolaridade)
      const _estadoCivil = formatResponseToOptions(estadoCivil)
      setEstadoCivilOptions(_estadoCivil)
      const _ocupacoes = formatResponseToOptions(ocupacoes)
      setOcupacoesOptions(_ocupacoes)
      const _patrimonio = formatResponseToOptions(patrimonio)
      setPatrimonioOptions(_patrimonio)
    }
    getInformacoes()
  }, [])

  const {
    isRequiredConjuge,
    isRequiredConjugeEmpresario,
    isRequiredConjugeAssalariado,
    isRequiredEmpresario,
    isRequiredAssalariado
  } = useMemo(() => {
    const { estadoCivil, conjugeOcupacao, ocupacao } = formik.values
    const isRequiredConjuge = estadoCivil === estadoCivilCasado
    const isRequiredConjugeEmpresario = (
      estadoCivil === estadoCivilCasado && conjugeOcupacao === ocupacaoEmpresario
    )
    const isRequiredConjugeAssalariado = (
      estadoCivil === estadoCivilCasado &&
      ocupacaoAssalariado.indexOf(conjugeOcupacao) >= 0
    )

    const isRequiredEmpresario = ocupacao === ocupacaoEmpresario

    const isRequiredAssalariado = (ocupacaoAssalariado.indexOf(ocupacao) >= 0)
    return {
      isRequiredConjuge,
      isRequiredConjugeEmpresario,
      isRequiredConjugeAssalariado,
      isRequiredEmpresario,
      isRequiredAssalariado
    }
  }, [
    formik.values.estadoCivil,
    formik.values.ocupacao,
    formik.values.conjugeOcupacao
  ])

  const getConfirmationMessage = () => {
    if(isReplicate) return 'Ao prosseguir, este projeto será replicado. Deseja continuar?'
    return 'Ao prosseguir, suas alterações serão salvas. Deseja continuar?'
  }

  const getToastMessage = () => {
    if(isReplicate) return 'Projeto replicado com sucesso!'
    if(id) return 'Projeto editado com sucesso!'
    return 'Projeto criado com sucesso!'
  }

  const isRequiredEnderecoComercial = useMemo(() => {
    const address = [
      removeMaskGuides(formik.values.comercialCep),
      formik.values.comercialEndereco,
      formik.values.comercialBairro,
      formik.values.comercialEstado,
      formik.values.comercialCidadeId
    ]
    const isRequired = address.some(address => address)
    formik.setFieldValue(
      'comercialEnderecoObrigatorio',
      String(isRequired)
    )
    return isRequired
  }, [
    formik.values.comercialCep,
    formik.values.comercialEndereco,
    formik.values.comercialBairro,
    formik.values.comercialEstado,
    formik.values.comercialCidadeId
  ])

  const isRequiredEnderecoConjuge = useMemo(() => {
    if(formik.values.estadoCivil !== estadoCivilCasado) return false

    const address = [
      removeMaskGuides(formik.values.conjugeComercialCep),
      formik.values.conjugeComercialEndereco,
      formik.values.conjugeComercialBairro,
      formik.values.conjugeComercialEstado,
      formik.values.conjugeComercialCidadeId
    ]
    const isRequired = address.some(address => address)
    formik.setFieldValue(
      'conjugeEnderecoObrigatorio',
      String(isRequired)
    )
    return isRequired
  }, [
    formik.values.conjugeComercialCep,
    formik.values.conjugeComercialEndereco,
    formik.values.conjugeComercialBairro,
    formik.values.conjugeComercialEstado,
    formik.values.conjugeComercialCidadeId
  ])

  const hasError = (name: keyof TProjetoForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TProjetoForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  })

  const onChangeTipoDocumento = (e: TInputEvent) => {
    const { value } = e.target

    formik.setFieldValue('tipoDocumento', value)
    if (value === tipoDocumentoRg) {
      setShowFieldsDocumentos(true)
      return
    }

    setShowFieldsDocumentos(false)
    formik.setFieldValue('orgaoEmissor', '')
    formik.setFieldValue('estadoOrgaoEmissor', '')
  }

  const populateProfissoesField = async (
    type: CommonFieldTypes,
    ocupacaoId?: string,
    clearFields = false
  ) => {
    const profissoes = ocupacaoId
      ? await repositoryInformacoes.getProfissoes(ocupacaoId)
      : []

    const formattedPorfissoes = formatResponseToOptions(profissoes)

    if (type === CommonFieldTypes.CLIENTE) {
      setProfissoesOptions(formattedPorfissoes)
      if (!clearFields) return

      formik.setFieldValue('profissao', '')
      formik.setFieldValue('cargo', '')
      return
    }

    setProfissoesConjugeOptions(formattedPorfissoes)
    if (!clearFields) return
    formik.setFieldValue('conjugeProfissao', '')
    formik.setFieldValue('conjugeCargo', '')
  }

  const onChangeOcupacao = (e: TInputEvent) => {
    const { value, id } = e.target
    formik.setFieldValue(id, value, true)

    const type = id === 'ocupacao'
      ? CommonFieldTypes.CLIENTE
      : CommonFieldTypes.CONJUGE

    const clearFields = true
    populateProfissoesField(type, value, clearFields)
  }

  const populateCargoField = async (
    type: CommonFieldTypes,
    params: TCargosParams,
    clearField = false
  ) => {
    const cargos = await repositoryInformacoes.getCargos(params)
    if (!cargos) return

    const formattedCargos = formatResponseToOptions(cargos)

    if (type === CommonFieldTypes.CLIENTE) {
      setCargoOptions(formattedCargos)
      if (clearField) formik.setFieldValue('cargo', '')
      return
    }

    setCargoConjugeOptions(formattedCargos)
    if (clearField) formik.setFieldValue('conjugeCargo', '')
  }

  const onChangeProfissao = (e: TInputEvent) => {
    const { value, id } = e.target
    formik.setFieldValue(id, value, true)

    const type = id === 'profissao'
      ? CommonFieldTypes.CLIENTE
      : CommonFieldTypes.CONJUGE

    const fieldOcupacao = id === 'profissao' ? 'ocupacao' : 'conjugeOcupacao'

    const params: TCargosParams = {
      professionId: value,
      professionNatureId: formik.values[fieldOcupacao]
    }

    const clearField = true
    populateCargoField(type, params, clearField)
  }

  const onChangeEstadoCivil = (e: TInputEvent) => {
    const { value } = e.target
    formik.setFieldValue('estadoCivil', value, true)

    if (value === estadoCivilCasado) return

    formik.setFieldValue('conjugeNome', '')
    formik.setFieldValue('conjugeCpf', '')
    formik.setFieldValue('conjugeComercialCnpj', '')
    formik.setFieldValue('conjugeComercialNome', '')
    formik.setFieldValue('conjugeComercialAdmissao', '')
    formik.setFieldValue('conjugeComercialTelefone', '')
    formik.setFieldValue('conjugeComercialTelefoneRamal', '')
    formik.setFieldValue('conjugeComercialCep', '')
    formik.setFieldValue('conjugeComercialEndereco', '')
    formik.setFieldValue('conjugeComercialNumero', '')
    formik.setFieldValue('conjugeComercialComplemento', '')
    formik.setFieldValue('conjugeComercialTipoResidencia', '')
    formik.setFieldValue('conjugeComercialCidade', '')
    formik.setFieldValue('conjugeComercialCidadeId', '')
    formik.setFieldValue('conjugeComercialEstado', '')
    formik.setFieldValue('conjugeRendaMensal', '')
    formik.setFieldValue('conjugeOcupacao', '')
    formik.setFieldValue('conjugeProfissao', '')
    formik.setFieldValue('conjugeCargo', '')
    if (conjugeCidadeRef.current) conjugeCidadeRef.current.clear()
  }

  const onChangeEstado = (e: TInputEvent) => {
    const { value, id } = e.target
    switch (id) {
      case 'estadoProjeto':
        formik.setFieldValue('estadoProjeto', value, true)
        formik.setFieldValue('cidadeProjeto', '')
        if (cidadeProjetoRef.current) cidadeProjetoRef.current.clear()
        break
      case 'estado':
        formik.setFieldValue('estado', value, true)
        formik.setFieldValue('cidade', '')
        if (cidadeRef.current) cidadeRef.current.clear()
        break
      case 'estadoNaturalidade':
        formik.setFieldValue('estadoNaturalidade', value, true)
        formik.setFieldValue('cidadeNaturalidade', '')
        if (cidadeNaturalidadeRef.current) {
          cidadeNaturalidadeRef.current.clear()
        }
        break
      case 'conjugeComercialEstado':
        formik.setFieldValue('conjugeComercialEstado', value, true)
        formik.setFieldValue('conjugeComercialCidade', '')
        if (conjugeCidadeRef.current) conjugeCidadeRef.current.clear()
        break
      case 'comercialEstado':
        formik.setFieldValue('comercialEstado', value, true)
        formik.setFieldValue('comercialCidade', '')
        if (comercialCidadeRef.current) comercialCidadeRef.current.clear()
        break
    }
  }

  const onSearchCidade = (name: keyof TProjetoForm) => (term: string) => {
    if (term.length < 3) return
    const requestCidades = async (estado: string) => {

      const params = {
        search: term,
        stateId: String(estado)
      }
      const cidades = await repositoryInformacoes.getCidades(params)
      if (!cidades) return

      const _cidadeOptions = cidades.map(cidade => ({
        label: cidade.description,
        value: String(cidade.id)
      }))

      setCidadeOptions(_cidadeOptions)
    }

    const { values } = formik

    switch (name) {
      case 'cidadeProjeto':
        if (values.estadoProjeto) requestCidades(values.estadoProjeto)
        break
      case 'cidade':
        if (values.estado) requestCidades(values.estado)
        break
      case 'cidadeNaturalidade':
        if (values.estadoNaturalidade) requestCidades(values.estadoNaturalidade)
        break
      case 'conjugeComercialCidade':
        if (values.conjugeComercialEstado) requestCidades(values.conjugeComercialEstado)
        break
      case 'comercialCidade':
        if (values.comercialEstado) requestCidades(values.comercialEstado)
    }
  }

  const onChangeCidade = (name: keyof TProjetoForm) => ([cidade]: TSelectOption[]) => {
    const value = cidade?.value ?? ''
    const label = cidade?.label ?? ''
    switch (name) {
      case 'cidadeProjeto':
        formik.setFieldValue('cidadeProjeto', label, true)
        formik.setFieldValue('cidadeProjetoId', value, true)
        break
      case 'cidade':
        formik.setFieldValue('cidade', label, true)
        formik.setFieldValue('cidadeId', value, true)
        break
      case 'cidadeNaturalidade':
        formik.setFieldValue('cidadeNaturalidade', label, true)
        formik.setFieldValue('cidadeNaturalidadeId', value, true)
        break
      case 'conjugeComercialCidade':
        formik.setFieldValue('conjugeComercialCidade', label, true)
        formik.setFieldValue('conjugeComercialCidadeId', value, true)
        break
      case 'comercialCidade':
        formik.setFieldValue('comercialCidade', label, true)
        formik.setFieldValue('comercialCidadeId', value, true)
    }
  }

  const onBlurCidade = (name: keyof TProjetoForm) => () => {
    const getRefByName = () => {
      switch (name) {
        case 'cidadeProjeto':
          return cidadeProjetoRef
        case 'cidade':
          return cidadeRef
        case 'cidadeNaturalidade':
          return cidadeNaturalidadeRef
        case 'conjugeComercialCidade':
          return conjugeCidadeRef
      }
    }
    const ref = getRefByName()
    formik.setFieldTouched(name, true)
    if (!formik.values[name]) ref?.current?.clear()
  }

  const onSearchIntegrador = (term: string) => {
    if (term.length < 3) return

    const requestIntegradores = async () => {
      const params = {
        companyName: term
      }
      const response = await getIntegradoresByCompanyName(params)
      if (!response) return

      const _integradoresOptions = response.integrators.map(integrador => {
        const formattedCnpj = formatCpfCnpj(integrador.cnpj)
        return {
          label: `${integrador.companyName} - CNPJ: ${formattedCnpj}`,
          value: integrador.id
        }
      })

      setIntegradoresOptions(_integradoresOptions)
    }

    requestIntegradores()
  }

  const onChangeIntegrador = ([integrador]: TSelectOption[]) => {
    const value = integrador?.value ?? ''
    const label = integrador?.label ?? ''
    formik.setFieldValue('integrador', label, true)
    formik.setFieldValue('integradorId', value, true)
  }

  const onBlurIntegrador = () => {
    formik.setFieldTouched('integradorId', true)
    if (!formik.values.integradorId) integradorRef.current?.clear()
  }

  const onSearchNacionalidade = (term: string) => {
    if (term.length < 3) return

    const requestNacionalidades = async () => {
      const params = {
        search: term,
      }
      const nacionalidades = await repositoryInformacoes.getNacionalidades(params)
      if (!nacionalidades) return

      const _nacionalidadesOptions = nacionalidades.map(nacionalidade => ({
        label: nacionalidade.description,
        value: nacionalidade.id
      }))

      setNacionalidadesOptions(_nacionalidadesOptions)
    }

    requestNacionalidades()
  }

  const onChangeNacionalidade = ([nacionalidade]: TSelectOption[]) => {
    const cleanNaturalidadeEstadoCidade = () => {
      formik.setFieldValue('estadoNaturalidade', '')
      formik.setFieldValue('cidadeNaturalidade', '')
      formik.setFieldValue('cidadeNaturalidadeId', '')
      if (cidadeNaturalidadeRef.current) {
        cidadeNaturalidadeRef.current.clear()
      }
    }

    const value = nacionalidade?.value ?? ''
    const label = nacionalidade?.label ?? ''
    formik.setFieldValue('nacionalidade', label, true)
    formik.setFieldValue('nacionalidadeId', value, true)
    if (String(value) !== nacionalidadeBrasileira) cleanNaturalidadeEstadoCidade()
  }

  const onBlurNacionalidade = () => {
    formik.setFieldTouched('nacionalidade', true)
    if (!formik.values.nacionalidadeId) nacionalidadeRef.current?.clear()
  }

  const isRequiredCidadeUfNaturalidade = useMemo(() => (
    String(formik.values.nacionalidadeId) === nacionalidadeBrasileira
  ), [formik.values.nacionalidadeId])

  const onClickBackToList = () => history.push('/projetos')

  useEffect(() => {
    const requestCurrentIntegrador = async () => {
      const response = await getIntegradoresByCompanyName({ pageSize: 1 })
      if (!response) return

      const { currentUserIntegrator } = response
      if (currentUserIntegrator.id) {
        const formattedIntegradorNome = `${currentUserIntegrator.companyName} - CNPJ: ${currentUserIntegrator.cnpj}`
        formik.setFieldValue('integrador', formattedIntegradorNome)
        formik.setFieldValue('integradorId', currentUserIntegrator.id)
        setIsDisabledIntegradorField(true)
      }
    }

    requestCurrentIntegrador()

    if(formik.values.enderecamentoPessoal === '1') {
      // usar o enderecamento pessoal
      formik.setFieldValue('comercialCep', formik.values.cep)
      formik.setFieldValue('comercialEndereco', formik.values.endereco)
      formik.setFieldValue('comercialNumero', formik.values.numero)
      formik.setFieldValue('comercialBairro', formik.values.bairro)
      formik.setFieldValue('comercialEstado', formik.values.estado)
      formik.setFieldValue('comercialCidade', formik.values.cidade)
      formik.setFieldValue('comercialCidadeId', formik.values.cidadeId)
      formik.setFieldValue('comercialComplemento', formik.values.complemento)
      setIsDisabledEnderecamentoComercialField(true)
    } else {
      // nao usar o enderecamento pessoal
      formik.setFieldValue('comercialCep', '')
      formik.setFieldValue('comercialEndereco', '')
      formik.setFieldValue('comercialNumero', '')
      formik.setFieldValue('comercialBairro', '')
      formik.setFieldValue('comercialEstado', '')
      formik.setFieldValue('comercialCidade', '')
      formik.setFieldValue('comercialComplemento', '')
      setIsDisabledEnderecamentoComercialField(false)
    }
    if(formik.values.enderecamentoConjugePessoal === '1') {
      // usar o enderecamento pessoal
      formik.setFieldValue('conjugeComercialCep', formik.values.cep)
      formik.setFieldValue('conjugeComercialEndereco', formik.values.endereco)
      formik.setFieldValue('conjugeComercialNumero', formik.values.numero)
      formik.setFieldValue('conjugeComercialBairro', formik.values.bairro)
      formik.setFieldValue('conjugeComercialEstado', formik.values.estado)
      formik.setFieldValue('conjugeComercialCidade', formik.values.cidade)
      formik.setFieldValue('conjugeComercialCidadeId', formik.values.cidadeId)
      formik.setFieldValue('conjugeComercialComplemento', formik.values.complemento)
      setIsDisabledEnderecamentoConjugeField(true)
    } else {
      // nao usar o enderecamento pessoal
      formik.setFieldValue('conjugeComercialCep', '')
      formik.setFieldValue('conjugeComercialEndereco', '')
      formik.setFieldValue('conjugeComercialNumero', '')
      formik.setFieldValue('conjugeComercialBairro', '')
      formik.setFieldValue('conjugeComercialEstado', '')
      formik.setFieldValue('conjugeComercialCidade', '')
      formik.setFieldValue('conjugeComercialComplemento', '')
      setIsDisabledEnderecamentoConjugeField(false)
    }
  }, [formik.values.enderecamentoPessoal, formik.values.enderecamentoConjugePessoal])

  useEffect(() => {
    if(formik.values.comercialCep.replaceAll('_', '').replace('-', '').length >= 8 && formik.values.enderecamentoPessoal !== '1'){
      cepPromise(formik.values.comercialCep).then((data: any) => {
        if (data) {
          formik.setFieldValue('comercialBairro', data.neighborhood)
          formik.setFieldValue('comercialEndereco', data.street)
        }
      })
    }
  }, [formik.values.comercialCep])

  useEffect(() => {
    if(formik.values.cep.replaceAll('_', '').replace('-', '').length >= 8) {
      cepPromise(formik.values.cep).then((data: any) => {
        if (data) {
          formik.setFieldValue('bairro', data.neighborhood)
          formik.setFieldValue('endereco', data.street)
        }
      })
    }
  }, [formik.values.cep])

  useEffect(() => {
    if(formik.values.conjugeComercialCep.replaceAll('_', '').replace('-', '').length >= 8 && formik.values.enderecamentoConjugePessoal !== '1'){
      cepPromise(formik.values.conjugeComercialCep).then((data: any) => {
        if (data) {
          formik.setFieldValue('conjugeComercialBairro', data.neighborhood)
          formik.setFieldValue('conjugeComercialEndereco', data.street)
        }
      })
    }
  }, [formik.values.conjugeComercialCep])

  useEffect(() => {
    if (formik.values.complemento.length > 30) {
      formik.setFieldValue('complemento', formik.values.complemento.slice(0, 30))
    }

    if (formik.values.comercialComplemento.length > 30) {
      formik.setFieldValue('comercialComplemento', formik.values.comercialComplemento.slice(0, 30))
    }

    if (formik.values.conjugeComercialComplemento.length > 30) {
      formik.setFieldValue('conjugeComercialComplemento', formik.values.conjugeComercialComplemento.slice(0, 30))
    }
  }, [formik.values.complemento, formik.values.comercialComplemento, formik.values.conjugeComercialComplemento])

  useEffect(() => {
    const validateFilled = () => {
      if (formik.values.conjugeRendaMensal) {
        if (formik.values.conjugeRendaMensal.length > 3) {
          setIsConjugeRequired(true)
        } else {
          setIsConjugeRequired(false)
        }
      } else {
        setIsConjugeRequired(false)
      }
    }
    validateFilled()
  }, [formik.values.conjugeRendaMensal, isConjugeRequired])

  const isVisualizando = useMemo(() => {
    const urlAction = location.pathname.split('/')[2]
    return urlAction === 'visualizar'
  }, [location.pathname])

  const headerTitleAction = useMemo(() => {
    if (isVisualizando) return 'Visualizar'
    if (isReplicate) return 'Replicar'

    return id ? 'Editar' : 'Adicionar'
  }, [isVisualizando, id, isReplicate])

  if (userData && !permission) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={true}
            route='/projetos'
            message={confirmationMessage}
          />
        </Col>
      </Row>

      <PageHeader title={`${headerTitleAction} Projeto`} />

      <Row>
        <Col>
          <InfoText>
            Para prosseguir com o processo de compra do sistema fotovoltaico você deve fornecer algumas informações a respeito
            do cliente ou de sua empresa.
          </InfoText>
        </Col>
      </Row>

      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <FormTitle>Dados do Projeto</FormTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={6}>
            <SelectField
              required
              label='Finalidade do Projeto'
              options={finalidadeProjetoOptions}
              {...getCommonFieldProps('finalidadeProjeto')}
            />
          </Col>

          <Col md={6}>
            <SelectField
              required
              label='Tipo de Financiamento'
              options={tipoFinanciamentoOptions}
              {...getCommonFieldProps('tipoFinanciamento')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Nome do projeto'
              required
              {...getCommonFieldProps('nomeProjeto')}
            />
          </Col>

          <Col md={6}>
            <SearchField
              label='Integrador'
              disabled={isDisabledIntegradorField}
              elementRef={integradorRef}
              options={integradoresOptions}
              {...getCommonFieldProps('integrador')}
              onSearch={onSearchIntegrador}
              onChange={onChangeIntegrador}
              onBlur={onBlurIntegrador}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={3}>
            <MaskedCurrencyField
              required
              label='Potência estimada'
              {...getCommonFieldProps('potenciaProjeto')}
            />
          </Col>

          <Col md={3}>
            <MaskedCurrencyField
              required
              label='Valor estimado'
              {...getCommonFieldProps('valorProjeto')}
            />
          </Col>

          <Col md={2}>
            <SelectField
              required
              label='Estado'
              options={estadosApiSafraOptions}
              {...getCommonFieldProps('estadoProjeto')}
              onChange={onChangeEstado}
            />
          </Col>

          <Col md={4}>
            <SearchField
              required
              label='Cidade'
              elementRef={cidadeProjetoRef}
              options={cidadeOptions}
              {...getCommonFieldProps('cidadeProjeto')}
              onSearch={onSearchCidade('cidadeProjeto')}
              onChange={onChangeCidade('cidadeProjeto')}
              onBlur={onBlurCidade('cidadeProjeto')}
              value={formik.values.cidadeProjeto}
              description='Para pesquisar uma cidade, selecione um "Estado" antes'
            />
          </Col>
        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormTitle>Pessoa Física</FormTitle>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormSubTitle>Informações básicas</FormSubTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Nome completo'
              required
              {...getCommonFieldProps('nomeCompleto')}
            />
          </Col>

          <Col md={6}>
            <TextField
              type='date'
              label='Data de Nascimento'
              required
              {...getCommonFieldProps('dataNascimento')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='E-mail'
              required
              {...getCommonFieldProps('email')}
            />
          </Col>

          <Col md={6}>
            <MaskedTextField
              required
              label='Celular'
              mask='(99) 99999-9999'
              {...getCommonFieldProps('celular')}
            />
          </Col>
        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormSubTitle>Endereço</FormSubTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={3}>
            <MaskedTextField
              label='CEP'
              mask='99999-999'
              required
              {...getCommonFieldProps('cep')}
            />
          </Col>

          <Col md={5}>
            <TextField
              label='Logradouro'
              required
              {...getCommonFieldProps('endereco')}
            />
          </Col>

          <Col md={4}>
            <TextField
              required
              type='string'
              label='Número'
              {...getCommonFieldProps('numero')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={5}>
            <TextField
              required
              label='Bairro'
              {...getCommonFieldProps('bairro')}
            />
          </Col>

          <Col md={3}>
            <SelectField
              required
              label='Estado'
              options={estadosApiSafraOptions}
              {...getCommonFieldProps('estado')}
              onChange={onChangeEstado}
            />
          </Col>

          <Col md={4}>
            <SearchField
              required
              label='Cidade'
              elementRef={cidadeRef}
              options={cidadeOptions}
              {...getCommonFieldProps('cidade')}
              onSearch={onSearchCidade('cidade')}
              onChange={onChangeCidade('cidade')}
              onBlur={onBlurCidade('cidade')}
              description='Para pesquisar uma cidade, selecione um "Estado" antes'
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={5}>
            <TextField
              label='Complemento'
              {...getCommonFieldProps('complemento')}
            />
          </Col>

          <Col md={7}>
            <SelectField
              required
              label='Tipo de residência'
              options={tiposResidenciaOptions}
              {...getCommonFieldProps('tipoResidencia')}
            />
          </Col>
        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormSubTitle>Informações pessoais</FormSubTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={4}>
            <SearchField
              required
              label='Nacionalidade'
              elementRef={nacionalidadeRef}
              options={nacionalidadesOptions}
              {...getCommonFieldProps('nacionalidade')}
              onSearch={onSearchNacionalidade}
              onChange={onChangeNacionalidade}
              onBlur={onBlurNacionalidade}
            />
          </Col>
          <Col md={2}>
            <SelectField
              required={isRequiredCidadeUfNaturalidade}
              disabled={!isRequiredCidadeUfNaturalidade}
              label='Estado'
              options={estadosApiSafraOptions}
              {...getCommonFieldProps('estadoNaturalidade')}
              onChange={onChangeEstado}
            />
          </Col>
          <Col md={6}>
            <SearchField
              required={isRequiredCidadeUfNaturalidade}
              disabled={!isRequiredCidadeUfNaturalidade}
              label='Cidade'
              elementRef={cidadeNaturalidadeRef}
              options={cidadeOptions}
              {...getCommonFieldProps('cidadeNaturalidade')}
              onSearch={onSearchCidade('cidadeNaturalidade')}
              onChange={onChangeCidade('cidadeNaturalidade')}
              onBlur={onBlurCidade('cidadeNaturalidade')}
              description='Para pesquisar uma cidade, selecione um "Estado" antes'
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <SelectField
              required
              label='Sexo'
              options={sexoOptions}
              {...getCommonFieldProps('sexo')}
            />
          </Col>
          <Col md={6}>
            <SelectField
              required
              label='Qual a sua escolaridade'
              options={escolaridadesOptions}
              {...getCommonFieldProps('escolaridade')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <SelectField
              required
              label='Estado Civil'
              options={estadoCivilOptions}
              {...getCommonFieldProps('estadoCivil')}
              onChange={onChangeEstadoCivil}
            />
          </Col>
          <Col md={6}>
            <MaskedTextField
              required
              label='CPF'
              mask='999.999.999-99'
              {...getCommonFieldProps('cpf')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <TextField
              required
              label='Nome da mãe'
              {...getCommonFieldProps('nomeMae')}
            />
          </Col>
        </Form.Row>

        {formik.values.estadoCivil === estadoCivilCasado && (
          <>
            <FormDivider />

            <Row>
              <Col>
                <FormSubTitle>Informações do Cônjuge</FormSubTitle>
              </Col>
            </Row>

            <Form.Row>
              <Col md={6}>
                <TextField
                  required={isRequiredConjuge}
                  label='Nome do Cônjuge'
                  {...getCommonFieldProps('conjugeNome')}
                />
              </Col>
              <Col md={6}>
                <MaskedTextField
                  required={isRequiredConjuge}
                  label='CPF'
                  mask='999.999.999-99'
                  {...getCommonFieldProps('conjugeCpf')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col>
                <SelectField
                  required={isConjugeRequired}
                  label='Ocupação'
                  options={ocupacoesOptions}
                  {...getCommonFieldProps('conjugeOcupacao')}
                  onChange={onChangeOcupacao}
                />
              </Col>
              <Col md={6}>
                <SelectField
                  required={isConjugeRequired}
                  label='Profissão'
                  options={profissoesConjugeOptions}
                  {...getCommonFieldProps('conjugeProfissao')}
                  onChange={onChangeProfissao}
                />
              </Col>
            </Form.Row>

            <Form.Row>

              <Col md={6}>
                <SelectField
                  required={isConjugeRequired}
                  label='Cargo / Atividade'
                  options={cargosConjugeOptions}
                  {...getCommonFieldProps('conjugeCargo')}
                />
              </Col>
              <Col md={6}>
                <TextField
                  required={isRequiredConjugeAssalariado}
                  label='Nome da Empresa'
                  {...getCommonFieldProps('conjugeComercialNome')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={6}>
                <MaskedTextField
                  required={isRequiredConjugeAssalariado}
                  label='CNPJ'
                  mask='99.999.999/9999-99'
                  {...getCommonFieldProps('conjugeComercialCnpj')}
                />
              </Col>
              <Col md={6}>
                <MaskedCurrencyField
                  required={isConjugeRequired}
                  label='Renda mensal'
                  description='Ao preencher, os demais campos do cônjuge serão obrigatórios'
                  {...getCommonFieldProps('conjugeRendaMensal')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={6}>
                <TextField
                  type='date'
                  label='Data admissão'
                  required={isRequiredConjugeAssalariado}
                  {...getCommonFieldProps('conjugeComercialAdmissao')}
                />
              </Col>
              <Col md={6}>
                <SelectField
                  label='Usar Endereçamento Pessoal'
                  options={[ { label: 'Sim', value: '1' }, { label: 'Não', value: '0' }]}
                  {...getCommonFieldProps('enderecamentoConjugePessoal')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={3}>
                <MaskedTextField
                  label='CEP'
                  mask='99999-999'
                  disabled = {isEnderecamentoConjugeDisabled}
                  required={isRequiredConjugeEmpresario || isRequiredEnderecoConjuge}
                  {...getCommonFieldProps('conjugeComercialCep')}
                />
              </Col>

              <Col md={5}>
                <TextField
                  label='Logradouro'
                  disabled = {isEnderecamentoConjugeDisabled}
                  required={isRequiredConjugeEmpresario || isRequiredEnderecoConjuge}
                  {...getCommonFieldProps('conjugeComercialEndereco')}
                />
              </Col>

              <Col md={4}>
                <TextField
                  type='string'
                  label='Número'
                  disabled = {isEnderecamentoConjugeDisabled}
                  required={isRequiredConjugeEmpresario || isRequiredEnderecoConjuge}
                  {...getCommonFieldProps('conjugeComercialNumero')}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={5}>
                <TextField
                  label='Bairro'
                  disabled = {isEnderecamentoConjugeDisabled}
                  required={isRequiredConjugeEmpresario || isRequiredEnderecoConjuge}
                  {...getCommonFieldProps('conjugeComercialBairro')}
                />
              </Col>

              <Col md={3}>
                <SelectField
                  label='Estado'
                  disabled = {isEnderecamentoConjugeDisabled}
                  options={estadosApiSafraOptions}
                  required={isRequiredConjugeEmpresario || isRequiredEnderecoConjuge}
                  {...getCommonFieldProps('conjugeComercialEstado')}
                  onChange={onChangeEstado}
                />
              </Col>

              <Col md={4}>
                <SearchField
                  label='Cidade'
                  disabled = {isEnderecamentoConjugeDisabled}
                  elementRef={conjugeCidadeRef}
                  options={cidadeOptions}
                  required={isRequiredConjugeEmpresario || isRequiredEnderecoConjuge}
                  {...getCommonFieldProps('conjugeComercialCidade')}
                  onSearch={onSearchCidade('conjugeComercialCidade')}
                  onChange={onChangeCidade('conjugeComercialCidade')}
                  onBlur={onBlurCidade('conjugeComercialCidade')}
                  description='Para pesquisar uma cidade, selecione um "Estado" antes'
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={5}>
                <TextField
                  label='Complemento'
                  disabled = {isEnderecamentoConjugeDisabled}
                  {...getCommonFieldProps('conjugeComercialComplemento')}
                />
              </Col>
              <Col md={3}>
                <MaskedTextField
                  required={isConjugeRequired}
                  label='Telefone'
                  mask='(99) 99999-9999'
                  {...getCommonFieldProps('conjugeComercialTelefone')}
                />
              </Col>
              <Col md={4}>
                <TextField
                  type='number'
                  label='Ramal'
                  {...getCommonFieldProps('conjugeComercialTelefoneRamal')}
                />
              </Col>
            </Form.Row>
          </>
        )}

        <FormDivider />

        <Row>
          <Col>
            <FormSubTitle>Documento de identificação</FormSubTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col md={12}>
            <SelectField
              required
              label='Tipo de documento'
              options={tiposDocumentosApiSafraOptions}
              {...getCommonFieldProps('tipoDocumento')}
              onChange={onChangeTipoDocumento}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Número do documento'
              required
              {...getCommonFieldProps('documento')}
            />
          </Col>

          <Col md={6}>
            <TextField
              required={showFieldsDocumentos}
              type='date'
              label='Data de emissão'
              {...getCommonFieldProps('dataEmissao')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          {showFieldsDocumentos && (
            <>
              <Col md={6}>
                <SelectField
                  required={showFieldsDocumentos}
                  label='Estado do órgão emissor'
                  options={estadosApiSafraOptions}
                  {...getCommonFieldProps('estadoOrgaoEmissor')}
                />
              </Col>

              <Col md={6}>
                <SelectField
                  required={showFieldsDocumentos}
                  label='Órgão emissor'
                  options={orgaoEmissorOptions}
                  {...getCommonFieldProps('orgaoEmissor')}
                />
              </Col>
            </>
          )}
        </Form.Row>

        <FormDivider />

        <Row>
          <Col>
            <FormSubTitle>Informações profissionais</FormSubTitle>
          </Col>
        </Row>

        <Form.Row>
          <Col>
            <SelectField
              required
              label='Ocupação'
              options={ocupacoesOptions}
              {...getCommonFieldProps('ocupacao')}
              onChange={onChangeOcupacao}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <SelectField
              required
              label='Profissão'
              options={profissoesOptions}
              {...getCommonFieldProps('profissao')}
              onChange={onChangeProfissao}
            />
          </Col>

          <Col md={6}>
            <SelectField
              required
              label='Cargo / Atividade'
              options={cargosOptions}
              {...getCommonFieldProps('cargo')}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <TextField
              required={isRequiredAssalariado}
              label='Nome da Empresa'
              {...getCommonFieldProps('comercialNome')}
            />
          </Col>
          <Col md={6}>
            <MaskedTextField
              required={isRequiredAssalariado}
              label='CNPJ'
              mask='99.999.999/9999-99'
              {...getCommonFieldProps('comercialCnpj')}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <TextField
              type='date'
              label='Data admissão'
              required={isRequiredAssalariado}
              {...getCommonFieldProps('comercialAdmissao')}
            />
          </Col>
          <Col md={6}>
            <SelectField
              label='Usar Endereçamento Pessoal'
              options={[ { label: 'Sim', value: '1' }, { label: 'Não', value: '0' }]}
              {...getCommonFieldProps('enderecamentoPessoal')}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={3}>
            <MaskedTextField
              label='CEP'
              disabled={isEnderecamentoComercialDisabled}
              mask='99999-999'
              required={isRequiredEmpresario || isRequiredEnderecoComercial}
              {...getCommonFieldProps('comercialCep')}
            />
          </Col>

          <Col md={5}>
            <TextField
              label='Logradouro'
              disabled={isEnderecamentoComercialDisabled}
              required={isRequiredEmpresario || isRequiredEnderecoComercial}
              {...getCommonFieldProps('comercialEndereco')}
            />
          </Col>

          <Col md={4}>
            <TextField
              type='string'
              label='Número'
              disabled={isEnderecamentoComercialDisabled}
              required={isRequiredEmpresario || isRequiredEnderecoComercial}
              {...getCommonFieldProps('comercialNumero')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={5}>
            <TextField
              label='Bairro'
              disabled={isEnderecamentoComercialDisabled}
              required={isRequiredEmpresario || isRequiredEnderecoComercial}
              {...getCommonFieldProps('comercialBairro')}
            />
          </Col>

          <Col md={3}>
            <SelectField
              label='Estado'
              options={estadosApiSafraOptions}
              disabled={isEnderecamentoComercialDisabled}
              required={isRequiredEmpresario || isRequiredEnderecoComercial}
              {...getCommonFieldProps('comercialEstado')}
              onChange={onChangeEstado}
            />
          </Col>

          <Col md={4}>
            <SearchField
              label='Cidade'
              elementRef={comercialCidadeRef}
              options={cidadeOptions}
              disabled={isEnderecamentoComercialDisabled}
              required={isRequiredEmpresario || isRequiredEnderecoComercial}
              {...getCommonFieldProps('comercialCidade')}
              onSearch={onSearchCidade('comercialCidade')}
              onChange={onChangeCidade('comercialCidade')}
              onBlur={onBlurCidade('comercialCidade')}
              description='Para pesquisar uma cidade, selecione um "Estado" antes'
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={5}>
            <TextField
              label='Complemento'
              disabled={isEnderecamentoComercialDisabled}
              {...getCommonFieldProps('comercialComplemento')}
            />
          </Col>
          <Col md={3}>
            <MaskedTextField
              label='Telefone'
              mask='(99) 99999-9999'
              required
              {...getCommonFieldProps('comercialTelefone')}
            />
          </Col>
          <Col md={4}>
            <TextField
              type='number'
              label='Ramal'
              {...getCommonFieldProps('comercialTelefoneRamal')}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <MaskedCurrencyField
              required
              label='Renda mensal'
              {...getCommonFieldProps('rendaMensal')}
            />
          </Col>
          <Col md={6}>
            <SelectField
              required
              label='Patrimônio'
              options={patrimonioOptions}
              {...getCommonFieldProps('patrimonio')}
            />
          </Col>
        </Form.Row>

        <ButtonsContainer>
          <ConfirmButton
            active={true}
            actionFn={onClickBackToList}
            message={confirmationMessage}
            variant={Variant.SECONDARY}
          >
            Cancelar
          </ConfirmButton>
          <ConfirmButton
            active={true}
            actionFn={formik.handleSubmit}
            message={getConfirmationMessage()}
            variant={Variant.PRIMARY}
            isLoading={repositoryProjetos.isLoading}
          >
            {isReplicate ? 'Replicar' : 'Salvar'}
          </ConfirmButton>
        </ButtonsContainer>
      </Form>
    </>
  )
}

export default CreateProject

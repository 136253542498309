import { useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'
import {
  PARAMETRIZATION_MUTATION,
  PARAMETRIZATION_QUERY
} from './parametrosAdesaoGql'

type TRegisterOrUpdateParametrizationResponse = {
  registerOrUpdateParametrization: TCommonResponse
}

export type TRegisterOrUpdateParametrizationParams = {
  parametrizations: [{
    id: number,
    name: string,
    valueChar: string | null,
    valueNumeric: number | null
  }
  ]
}


export const useRegisterOrUpdateParametrization = () => {
  const errorHandler = useErrorHandler()

  const [
    registerParametrization,
    { data, loading }
  ] = useMutation<TRegisterOrUpdateParametrizationResponse>(PARAMETRIZATION_MUTATION, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.registerOrUpdateParametrization.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (_data: TRegisterOrUpdateParametrizationParams) => {
    registerParametrization({
      variables: {
        parametrizationInput: _data
      }
    })
  }

  return {
    send,
    data,
    isError: data?.registerOrUpdateParametrization.commonResponse.isError,
    isLoading: loading
  }
}

export type TGetParametrizationsResponse = {
  findAllParametrization: TCommonResponse & {
    parametrizationSuccess: TParametroAdesao[]
  }
}

export type TParametroAdesao = {
  id: number,
  name: string,
  valueChar: string | null,
  valueNumeric: number | null
}

export const useGetParametrizations = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [
    requestParametros,
    { loading, data }
  ] = useLazyQuery<TGetParametrizationsResponse>(
    PARAMETRIZATION_QUERY, {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error)
    }
  )

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findAllParametrization.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestParametros()
  }

  return {
    isLoading: loading && isLoading,
    parametros: data?.findAllParametrization.parametrizationSuccess,
    get
  }
}


import { useEffect } from 'react'
import { useMutation, useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'
import {
  QUERY_VALIDATE_INVITATION_CODE,
  MUTATION_REGISTER_INTEGRADOR_USER
} from './integradoresGql'

type TValidacaoCodigoConviteResponse = {
  getStatusInvitationIntegratorToken: TComonResponse & {
    success: {
      name: string
      email: string
      integratorId: string
    }
  }
}

export const useGetValidacaoCodigoConvite = () => {
  const errorHandler = useErrorHandler()

  const [requestValidacao, { data, loading }] = useLazyQuery<TValidacaoCodigoConviteResponse>(
    QUERY_VALIDATE_INVITATION_CODE,
    {
      fetchPolicy: 'no-cache',
      onError: error => errorHandler.handleApolloErrorDesambiguation(error)
    }
  )

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.getStatusInvitationIntegratorToken.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (token: string) => {
    requestValidacao({
      variables: { authToken: token }
    })
  }

  return {
    loading,
    info: data?.getStatusInvitationIntegratorToken?.success,
    mensagem: data?.getStatusInvitationIntegratorToken.commonResponse.description,
    get
  }
}

type TIntegradorUsuarioBody = {
  name: string
  email: string
  passwordHash: string
  authToken: string
}

type TCreateIntegradorUsuarioResponse = {
  registerNewUserFromIntegrator: TComonResponse
}

export const useCreateIntegradorUsuario = () => {
  const errorHandler = useErrorHandler()

  const [
    createIntegradorUser,
    { data, loading }
  ] = useMutation<TCreateIntegradorUsuarioResponse>(MUTATION_REGISTER_INTEGRADOR_USER, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.registerNewUserFromIntegrator.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (_data: TIntegradorUsuarioBody) => {
    createIntegradorUser({
      variables: {
        registerNewUserFromIntegratorCommand: _data
      }
    })
  }

  return {
    send,
    data,
    isError: data?.registerNewUserFromIntegrator.commonResponse.isError,
    isLoading: loading
  }
}
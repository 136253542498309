import React, { useState, useMemo, useEffect } from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import { FaEllipsisV } from 'react-icons/fa'
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md'
import { useToasts } from 'react-toast-notifications'

import { PageHeader, IconButton, Box, FlexContainer } from 'components'
import {
  TwistedAdjustsIcon,
  CompactRow,
  BoxTitle,
  SecondaryBoxTitle,
  Price,
  BoxInfoList,
  Links,
  ChartContainer,
  EconomiaCO2List,
  ArrowIcon,
  CarouselBulletList,
  CarouselBullet,
  CarouselContent,
  BarsChartContainer,
  BarsChartGrid,
  BarsList,
  Bar,
  Consumo,
  CurrentWeather,
  Temperature,
  WeatherChart,
  TemperatureBar,
  UsinaPercentage,
  UsinaProduction
} from './styles'
import { IconSizes } from 'utils/constants'
import { theme } from 'styles'

import bateryPlugIcon from 'assets/images/icons/bateryPlug.svg'
import plugIcon from 'assets/images/icons/plug.svg'
import sunIcon from 'assets/images/icons/sun.svg'
import antenaIcon from 'assets/images/icons/antena.svg'

import { ReactComponent as Co2Icon } from 'assets/images/co2.svg'
import { ReactComponent as TreeIcon } from 'assets/images/tree.svg'
import { ReactComponent as CarIcon } from 'assets/images/car.svg'

import { ReactComponent as SunIcon } from 'assets/images/weather/1.svg'
import { ReactComponent as MoonIcon } from 'assets/images/weather/1n.svg'
import { ReactComponent as SunCloudIcon } from 'assets/images/weather/2.svg'
import { ReactComponent as MoonCloudIcon } from 'assets/images/weather/2n.svg'
import { ReactComponent as HiddenSunCloudIcon } from 'assets/images/weather/2r.svg'
import { ReactComponent as SoftRainIcon } from 'assets/images/weather/3.svg'
import { ReactComponent as HeavyRainIcon } from 'assets/images/weather/3n.svg'
import { ReactComponent as CloudIcon } from 'assets/images/weather/3tm.svg'
import { ReactComponent as ThunderStromIcon } from 'assets/images/weather/4t.svg'
import { ReactComponent as SnowIcon } from 'assets/images/weather/7.svg'

const forecastErrorMessage = 'Tivemos um problema ao carregar a previsão do tempo'

const consumoEnergia = [
  293,
  284,
  331,
  408,
  533,
  490,
  457,
  511,
  561,
  424,
  354,
  290,
  412
]

const getWheatherIconByCode = (code: string) => {
  switch (code) {
    case '1':
      return <SunIcon />
    case '1n':
      return <MoonIcon />
    case '2':
      return <SunCloudIcon />
    case '2n':
      return <MoonCloudIcon />
    case '2r':
      return <HiddenSunCloudIcon />
    case '2rn':
      return <MoonCloudIcon />
    case '3':
      return <SoftRainIcon />
    case '3n':
      return <HeavyRainIcon />
    case '3tm':
      return <CloudIcon />
    case '4':
      return <SoftRainIcon />
    case '4n':
      return <SoftRainIcon />
    case '4r':
      return <HeavyRainIcon />
    case '4rn':
      return <HeavyRainIcon />
    case '4t':
      return <ThunderStromIcon />
    case '4tn':
      return <ThunderStromIcon />
    case '5':
      return <HeavyRainIcon />
    case '5n':
      return <HeavyRainIcon />
    case '6':
      return <ThunderStromIcon />
    case '6n':
      return <ThunderStromIcon />
    case '7':
      return <SnowIcon />
    case '7n':
      return <SnowIcon />
    case '8':
      return <SnowIcon />
    case '8n':
      return <SnowIcon />
    case '9':
      return <SunCloudIcon />
    case '9n':
      return <MoonIcon />
  }
}

const getBarColorByPercentage = (percentage: number) => {
  const firstBreakPoint = 100 / 3
  const secondBreakPoint = firstBreakPoint * 2

  if (percentage < firstBreakPoint) return theme.colors.YELLOW
  if (percentage < secondBreakPoint) return theme.colors.ORANGE
  return theme.colors.RED
}

const getPercentagesFromList = (list: number[]) => {
  const maxValue = Math.max(...list)
  const percentagesList = list.map(item => (
    Math.round((item / maxValue) * 100)
  ))

  return percentagesList
}

const weatherToken = '2b1cc86e8a95bfb2bb61481350c7f0d4'
const saoCarlosId = 3680

type TForecast = {
  text_icon: {
    icon: {
      day: string
    }
  },
  temperature: {
    min: number,
    max: number
  }
}

type TCurrentWeather = {
  condition: string
  icon: string
  temperature: number
  wind_velocity: number
}

const getWeekDays = () => {
  const currentDay = new Date().getDay()

  const dayIndexes = Array.from({ length: 6 }, (_, index) => (
    currentDay + index <= 5
      ? currentDay + index + 1
      : (currentDay + index) - 6
  ))

  const stringDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
  const weekDays = dayIndexes.map(index => stringDays[index])

  return ['Hoje', ...weekDays]
}

const weekDays = getWeekDays()

const Dashboard = () => {
  const [activeCarouselItem, setActiveCarouselItem] = useState(0)
  const [forecast, setForecast] = useState<TForecast[]>([])
  const [currentWheather, setCurrentWeather] = useState<TCurrentWeather>()

  const { addToast } = useToasts()

  useEffect(() => {
    const getForecast = async () => {
      const url = `https://apiadvisor.climatempo.com.br/api/v1/forecast/locale/${saoCarlosId}/days/15?token=${weatherToken}`
      try {
        const response = await fetch(url)
        const _forecast = await response.json()
        setForecast(_forecast.data)
      } catch (_) {
        addToast(
          forecastErrorMessage,
          { appearance: 'error', autoDismiss: true }
        )
      }
    }

    const getCurrentWeather = async () => {
      const url = `https://apiadvisor.climatempo.com.br/api/v1/weather/locale/${saoCarlosId}/current?token=${weatherToken}`
      try {
        const response = await fetch(url)
        const _currentWheather = await response.json()
        setCurrentWeather(_currentWheather.data)
      } catch (_) {
        addToast(
          forecastErrorMessage,
          { appearance: 'error', autoDismiss: true }
        )
      }
    }

    getForecast()
    getCurrentWeather()
  }, [])

  const onClickShowCarouselItem = (index: number) => () => setActiveCarouselItem(index)

  const {
    consumoEnergiaPercentage,
    consumoMax,
    consumoMin
  } = useMemo(() => {
    const consumoEnergiaPercentage = getPercentagesFromList(consumoEnergia)
    const consumoMax = Math.max(...consumoEnergia)
    const consumoMin = Math.min(...consumoEnergia)

    return {
      consumoEnergiaPercentage,
      consumoMax,
      consumoMin
    }
  }, [consumoEnergia])

  const maxTemperaturePercentages = useMemo(() => {
    const maxTemperatureList = forecast.map(item => item.temperature.max)
    return getPercentagesFromList(maxTemperatureList)
  }, [forecast])

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Dashboard'>
            <IconButton>
              <TwistedAdjustsIcon />
            </IconButton>
          </PageHeader>
        </Col>
      </Row>

      <CompactRow>
        <Col lg={3}>
          <Box compact>
            <BoxTitle>Última Fatura</BoxTitle>

            <Price>R$ 212,51</Price>

            <BoxInfoList>
              <li>
                <dt>Vencimento:</dt>
                <dd>20/07/2021</dd>
              </li>
              <li>
                <dt>Forma de pagamento:</dt>
                <dd>Boleto</dd>
              </li>
              <li>
                <dt>Forma de recebimento:</dt>
                <dd>E-mail</dd>
              </li>
              <li>
                <dt>Situação:</dt>
                <dd>Em aberto</dd>
              </li>
            </BoxInfoList>

            <Links>
              <li><a href='#'>Segunda via da fatura</a></li>
              <li><a href='#'>Histórico</a></li>
            </Links>
          </Box>
        </Col>

        <Col lg={3}>
          <Box compact>
            <BoxTitle centered>Medição de Energia</BoxTitle>
            <ChartContainer
              color={theme.colors.DARK_GRAY}
              completion={300}
              id='medicao'
              icon={bateryPlugIcon}
            >
              <svg>
                <circle r='50' cx='70' cy='70' />
                <circle r='50' cx='70' cy='70' />
              </svg>
            </ChartContainer>
            <SecondaryBoxTitle centered>80% da energia carregada</SecondaryBoxTitle>
          </Box>
        </Col>

        <Col lg={6}>
          <Box compact>
            <Row>
              <Col lg={4}>
                <BoxTitle centered>Energia Consumida</BoxTitle>
                <ChartContainer
                  color={theme.colors.RED}
                  completion={250}
                  id='consumo'
                  icon={plugIcon}
                >
                  <svg>
                    <circle r='50' cx='70' cy='70' />
                    <circle r='50' cx='70' cy='70' />
                  </svg>
                </ChartContainer>
                <SecondaryBoxTitle centered>856 kW de energia</SecondaryBoxTitle>
              </Col>

              <Col lg={4}>
                <BoxTitle centered>Energia Produzida</BoxTitle>
                <ChartContainer
                  color={theme.colors.YELLOW}
                  completion={200}
                  id='producao'
                  icon={sunIcon}
                >
                  <svg>
                    <circle r='50' cx='70' cy='70' />
                    <circle r='50' cx='70' cy='70' />
                  </svg>
                </ChartContainer>
                <SecondaryBoxTitle centered>4,57 kW de energia</SecondaryBoxTitle>
              </Col>

              <Col lg={4}>
                <BoxTitle centered>Medição de Energia</BoxTitle>
                <ChartContainer
                  color={theme.colors.ORANGE}
                  completion={150}
                  id='rede'
                  icon={antenaIcon}
                >
                  <svg>
                    <circle r='50' cx='70' cy='70' />
                    <circle r='50' cx='70' cy='70' />
                  </svg>
                </ChartContainer>
                <SecondaryBoxTitle centered>3,72 kW de energia</SecondaryBoxTitle>
              </Col>
            </Row>
          </Box>
        </Col>
      </CompactRow>

      <CompactRow>
        <Col lg={6}>
          <Box compact>
            <FlexContainer>
              <BoxTitle centered>Energia Consumida</BoxTitle>
              <IconButton size={IconSizes.SMALL}>
                <FaEllipsisV />
              </IconButton>
            </FlexContainer>

            <BarsChartContainer>
              <BarsChartGrid>
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
              </BarsChartGrid>
              <BarsList>
                {consumoEnergiaPercentage.map((item, index) => {
                  const color = getBarColorByPercentage(item)
                  return (
                    <Bar
                      key={index}
                      completion={item}
                      color={color}
                      index={index}
                      title={`${consumoEnergia[index]} kWh`}
                    />
                  )
                })}
              </BarsList>
              <FlexContainer>
                <Consumo type='low'>
                  <span>{consumoMin} kWh</span>
                  <MdArrowDownward />
                </Consumo>
                <Consumo type='high'>
                  <span>{consumoMax} kWh</span>
                  <MdArrowUpward />
                </Consumo>
              </FlexContainer>
            </BarsChartContainer>
          </Box>
        </Col>

        <Col lg={3}>
          <Box compact>
            <FlexContainer>
              <BoxTitle centered>Economias</BoxTitle>
              <IconButton size={IconSizes.SMALL}>
                <FaEllipsisV />
              </IconButton>
            </FlexContainer>

            <Carousel
              activeIndex={activeCarouselItem}
              onSelect={onClickShowCarouselItem}
              controls={false}
            >
              <Carousel.Item>
                <CarouselContent>
                  <span>Junho, 2021</span>
                  <strong>R$ 10.120,00</strong>
                </CarouselContent>
              </Carousel.Item>
              <Carousel.Item>
                <CarouselContent>
                  <span>Maio, 2021</span>
                  <strong>R$ 9.120,00</strong>
                </CarouselContent>
              </Carousel.Item>
              <Carousel.Item>
                <CarouselContent>
                  <span>Abril, 2021</span>
                  <strong>R$ 8.120,00</strong>
                </CarouselContent>
              </Carousel.Item>
              <Carousel.Item>
                <CarouselContent>
                  <span>Março, 2021</span>
                  <strong>R$ 7.120,00</strong>
                </CarouselContent>
              </Carousel.Item>
            </Carousel>

            <CarouselBulletList>
              <CarouselBullet
                isActive={activeCarouselItem === 0}
                onClick={onClickShowCarouselItem(0)}
              >
                <a href='#'>Junho 2021</a>
              </CarouselBullet>

              <CarouselBullet
                isActive={activeCarouselItem === 1}
                onClick={onClickShowCarouselItem(1)}
              >
                <a href='#'>Maio 2021</a>
              </CarouselBullet>

              <CarouselBullet
                isActive={activeCarouselItem === 2}
                onClick={onClickShowCarouselItem(2)}
              >
                <a href='#'>Abril 2021</a>
              </CarouselBullet>

              <CarouselBullet
                isActive={activeCarouselItem === 3}
                onClick={onClickShowCarouselItem(3)}
              >
                <a href='#'>Março 2021</a>
              </CarouselBullet>
            </CarouselBulletList>
          </Box>
        </Col>

        <Col lg={3}>
          <Box compact>
            <FlexContainer>
              <BoxTitle centered>Economia de CO2</BoxTitle>
              <IconButton size={IconSizes.SMALL}>
                <FaEllipsisV />
              </IconButton>
            </FlexContainer>
            <EconomiaCO2List>
              <li>
                <Co2Icon />
                <ArrowIcon />
                <span>Economia de <strong>10 toneladas</strong> de CO2</span>
              </li>
              <li>
                <TreeIcon />
                <ArrowIcon />
                <span>Equivalente ao <strong>plantio de 100 árvores</strong></span>
              </li>
              <li>
                <CarIcon />
                <ArrowIcon />
                <span>Compensa <strong>10 horas de uso de veículo</strong></span>
              </li>
            </EconomiaCO2List>
          </Box>
        </Col>
      </CompactRow>
      <CompactRow>
        <Col lg={6}>
          <Box compact>
            <FlexContainer>
              <BoxTitle>Usina Itacarambi / 3 MWp</BoxTitle>

              <IconButton size={IconSizes.SMALL}>
                <FaEllipsisV />
              </IconButton>
            </FlexContainer>

            <FlexContainer>
              <SecondaryBoxTitle>Silva Mello Supermercados Ltda</SecondaryBoxTitle>
              <UsinaPercentage>7%</UsinaPercentage>
            </FlexContainer>

            <UsinaProduction>
              <span>Usina Itacarambi</span>
              <span>3 MWp</span>
            </UsinaProduction>
          </Box>
        </Col>
        <Col lg={6}>
          <Box compact>
            <FlexContainer>
              <BoxTitle>São Carlos, São Paulo</BoxTitle>
              <IconButton size={IconSizes.SMALL}>
                <FaEllipsisV />
              </IconButton>
            </FlexContainer>

            <Row>
              <Col lg={4}>
                {currentWheather && (
                  <CurrentWeather>
                    {getWheatherIconByCode(currentWheather.icon)}
                    <Temperature>{currentWheather.temperature} °C</Temperature>
                    <span>{currentWheather.condition}</span>
                    <span>{currentWheather.wind_velocity} m/s</span>
                  </CurrentWeather>
                )}
              </Col>

              <Col lg={8}>
                <WeatherChart>
                  {forecast?.map((dailyForecast, index) => {
                    return (
                      <li key={`${dailyForecast.temperature}${index}`}>
                        <h4>{weekDays[index]}</h4>
                        {getWheatherIconByCode(dailyForecast.text_icon.icon.day)}
                        <span>{dailyForecast.temperature.max} °C</span>
                        <TemperatureBar
                          completion={maxTemperaturePercentages[index]}
                          index={index}
                        >
                          <span />
                        </TemperatureBar>
                        <span>{dailyForecast.temperature.min} °C</span>
                      </li>
                    )
                  })}
                </WeatherChart>
              </Col>
            </Row>
          </Box>
        </Col>
      </CompactRow>
    </>
  )
}

export default Dashboard

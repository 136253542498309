import React from 'react'
import { Form } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import { FormLabel } from 'components'

type TProps = {
  required?: boolean
  name: string
  label?: string
  placeholder?: string
  emptyLabel?: string
  description?: string
  isInvalid?: boolean
  errorMessage?: string
  value?: string | number
  onChange(e: TInputEvent): void,
  disabled?: boolean,
  readOnly?: boolean
}

const MaskedCurrencyField = (props: TProps) => {
  const {
    required,
    label,
    name,
    isInvalid,
    errorMessage,
    description,
    disabled = false,
    ...rest
  } = props

  return (
    <Form.Group>
      <FormLabel required={required}>
        {label}
      </FormLabel>

      <NumberFormat
        id={name}
        className={`form-control ${isInvalid && 'is-invalid'}`}
        autoComplete='off'
        disabled={disabled}
        thousandSeparator='.'
        decimalSeparator=','
        decimalScale={2}
        fixedDecimalScale={true}
        {...rest}
      />

      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>

      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default MaskedCurrencyField

import { useEffect } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'
import {
  MUTATION_LOGIN,
  MUTATION_LOGOUT,
  QUERY_VERIFY_PERMISSION
} from './autenticacaoGqls'

type TLoginResponse = {
  login: TCommonResponse & {
    userResult: TUserInfo
    authToken: string
  }
}

type TUserInfo = {
  name: string
  roleName: string
  permissions: string[]
  id: number
}

export const useLogin = () => {
  const errorHandler = useErrorHandler()

  const [login, { data, loading }] = useMutation<TLoginResponse>(MUTATION_LOGIN, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.login.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (email: string, senha: string) => {
    login({
      variables: {
        loginByEmail: {
          email,
          password: senha
        }
      }
    })
  }

  return {
    send,
    userInfo: data?.login.userResult,
    token: data?.login.authToken,
    isError: data?.login.commonResponse.isError,
    isLoading: loading
  }
}

type TLogoutResponse = {
  logout: {
    isError: boolean
    statusCode: number
    description: string
  }
}

export const useLogout = () => {
  const errorHandler = useErrorHandler()

  const [logout, { data, loading }] = useMutation<TLogoutResponse>(MUTATION_LOGOUT, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.logout
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  return {
    send: logout,
    data,
    isError: data?.logout.isError,
    isLoading: loading
  }
}

type TVerifyPermissionResponse = {
  verifyUserHasPermission: TCommonResponse & {
    hasPermission: boolean
  }
}

export const useGetVerificaPermissao = () => {
  const errorHandler = useErrorHandler()

  const [
    requestPermissao,
    { loading, data }
  ] = useLazyQuery<TVerifyPermissionResponse>(QUERY_VERIFY_PERMISSION, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if(!data) return
    const { isError, description } = data.verifyUserHasPermission.commonResponse
    if(isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (permission: String) => {
    requestPermissao({
      variables: permission
    })
  }

  return {
    loading,
    get,
    isPermissao: data?.verifyUserHasPermission.hasPermission
  }
}
import styled from 'styled-components'
import { Col } from 'react-bootstrap'

export const Column = styled(Col)`
  border-right: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:last-child {
    border-right: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    border-right: none;
  }
`

export const Item = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  h4 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }

  span {
    font-weight: 300;
  }
`

export const Divider = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0;

  div:first-child {
    flex: 1;
  }
`
import React, { useEffect, useMemo } from 'react'
import { useHistory, Redirect } from 'react-router-dom'

import { Row, Col, Table } from 'react-bootstrap'
import { AiFillPlusSquare, AiOutlineEye } from 'react-icons/ai'
import { BiPencil, BiX, BiCheck } from 'react-icons/bi'

import {
  Loader,
  PageHeader,
  PrimaryButton,
  TableMessageRow,
  ActionButton,
  Pagination,
  FlexContainer,
  Filter
} from 'components'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import {
  useGetUsuarios,
  useGetProfiles,
  useUpdateUsuario
} from 'repositories/usuarios'
import { ActivationStatus } from 'utils/constants'
import { getStatusLabel } from 'utils/helpers'
import { useAuth } from 'hooks/useAuth'

const initialParamsUsuarios = {
  paginate: {
    numberOfRecordsByPage: 10,
    pageNumber: 0,
  }
}

const initialParamsPerfis = {
  status: ActivationStatus.ACTIVATED,
  paginate: {
    numberOfRecordsByPage: 1000,
    pageNumber: 0,
  }
}

const statusOptions = [{
  label: 'Ativo',
  value: ActivationStatus.ACTIVATED
},
{
  label: 'Inativo',
  value: ActivationStatus.DEACTIVATED
},
{
  label: 'Aguardando Ativação',
  value: ActivationStatus.WAITING
},
]

const UsuariosLista = () => {
  const history = useHistory()
  const pagination = usePagination()
  const filters = useFilters()
  const getUsuarios = useGetUsuarios()
  const getPerfis = useGetProfiles(initialParamsPerfis)
  const updateUsuario = useUpdateUsuario()
  const { userPermissions } = useAuth()

  const permissions = useMemo(() => userPermissions?.usuarios, [userPermissions])

  useEffect(() => {
    getUsuarios.get(initialParamsUsuarios)
  }, [])

  useEffect(() => {
    requestUsuarios()
  }, [filters.items])

  useEffect(() => {
    if (!getUsuarios.paginationInfo) return
    const { paginationInfo } = getUsuarios
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)
    pagination.stopLoading()
    filters.stopLoading()
  }, [getUsuarios.paginationInfo])

  useEffect(() => {
    if (updateUsuario.response) {
      requestUsuarios(getUsuarios.paginationInfo?.pageNumber)
    }
  }, [updateUsuario.response])

  const requestUsuarios = (pageIndex = 0) => {
    const _filters = filters.items.reduce((acc, curr) => ({
      ...acc, [curr.field]: curr.term
    }), {})

    const params = {
      ..._filters,
      paginate: {
        numberOfRecordsByPage: pagination.itemsPerPage,
        pageNumber: pageIndex,
      }
    }
    getUsuarios.get(params)
  }

  const onClickGoToForm = () => {
    history.push('/usuarios/novo')
  }

  const onClickVisualize = (id: number) => () => {
    history.push(`/usuarios/visualizar/${id}`)
  }

  const onClickEdit = (id: number) => () => {
    history.push(`/usuarios/editar/${id}`)
  }

  const onClickToogleUsuarioStatus = (
    usuario: Omit<TUsuarioRequest, 'role'>
  ) => () => {
    const _usuario = {
      id: usuario.id!,
      status: usuario.status === ActivationStatus.ACTIVATED
        ? ActivationStatus.DEACTIVATED
        : ActivationStatus.ACTIVATED
    }

    updateUsuario.update(_usuario)
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Usuários'>
            {permissions?.add && (
              <PrimaryButton onClick={onClickGoToForm} iconPosition='right'>
                <span>Adicionar Usuário</span>
                <AiFillPlusSquare />
              </PrimaryButton>
            )}
          </PageHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>Nome</span>
                    <Filter
                      field='name'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Email</span>
                    <Filter
                      field='email'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Perfil</span>
                    <Filter
                      field='role'
                      filters={filters}
                      options={getPerfis.perfisOptions}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Status</span>
                    <Filter
                      field='status'
                      filters={filters}
                      options={statusOptions}
                    />
                  </FlexContainer>
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {getUsuarios.loading ? (
                <TableMessageRow colSpan={5}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!getUsuarios.usuarios?.length && (
                    <TableMessageRow colSpan={7}>
                      Não foram encontrados perfis para serem listados
                    </TableMessageRow>
                  )}
                  {getUsuarios.usuarios?.map((usuario, index) => {
                    const formattedStatus = getStatusLabel(usuario.status)
                    const isStatus = usuario.status === ActivationStatus.ACTIVATED
                    const isWaiting = usuario.status === ActivationStatus.WAITING
                    const statusButtonTitle = isStatus
                      ? 'Inativar'
                      : 'Ativar'
                    return (
                      <tr key={index}>
                        <td>{usuario.name}</td>
                        <td>{usuario.email}</td>
                        <td>{usuario.role.name}</td>
                        <td>{formattedStatus}</td>
                        <td>
                          <ActionButton
                            onClick={onClickVisualize(usuario.id)}
                          >
                            <AiOutlineEye />
                          </ActionButton>
                          {permissions?.edit && (
                            <ActionButton
                              onClick={onClickEdit(usuario.id)}
                            >
                              <BiPencil />
                            </ActionButton>
                          )}
                          {permissions?.inactivate && !isWaiting && (
                            <ActionButton
                              title={statusButtonTitle}
                              onClick={onClickToogleUsuarioStatus(usuario)}
                            >
                              {isStatus ? <BiX /> : <BiCheck />}
                            </ActionButton>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination
            currentPageIndex={pagination.currentPageIndex!}
            totalPages={pagination.totalPages!}
            request={requestUsuarios}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}

export default UsuariosLista

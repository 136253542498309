import React from 'react'
import { Form } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import { FormLabel } from 'components'

const MaskedInput = props => {
  const {
    field,
    label,
    type = 'text',
    mask,
    placeholder = '',
    value,
    handleChange,
    error = {},
    description = '',
    ...rest
  } = props
  return (
    <Form.Group controlId={field}>
      <FormLabel required={rest.required}>{label}</FormLabel>
      <InputMask
        type={type}
        name={field}
        mask={mask}
        placeholder={placeholder}
        className={error.isError ? 'form-control is-invalid' : 'form-control'}
        value={value}
        onChange={handleChange}
        {...rest}
      />
      <Form.Control.Feedback type='invalid'>
        {error.message}
      </Form.Control.Feedback>
      {description && <Form.Text className='text-muted'>{description}</Form.Text>}
    </Form.Group>
  )
}

export default MaskedInput
import * as Yup from 'yup'
import { ActivationStatus } from 'utils/constants'

export const initialValues: TPerfisRequest = {
  id: '',
  name: '',
  status: ActivationStatus.ACTIVATED,
  functionalities: []
}

export const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  status: Yup.string().required('Status é obrigatório')
})
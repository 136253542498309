import styled from 'styled-components'
import { FlexContainer as FC, PrimaryButton } from 'components'
import{ Spinner } from 'react-bootstrap'

export const FlexContainer = styled(FC)`
    flex-wrap: nowrap;
    min-width: 120px;
`
export const CustomSpinner = styled(Spinner)`
  width: 17px;
  height: 17px;
`

export const CustomCollapseButton = styled(PrimaryButton)`
width: 100%;
`
export const CustomLink = styled.a`
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  font-weight: 700;
  display: block;
  margin-bottom: 27px;
  cursor: pointer;
  display: flex;

  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GRAY};
    text-decoration: none;
  }
`
import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import Entrar from 'pages/Autenticacao/Entrar'
import RecuperacaoSenha from 'pages/Autenticacao/RecuperacaoSenha'
import RedefinicaoSenha from 'pages/Autenticacao/RedefinicaoSenha'

import Home from 'pages/Home'

import Dashboard from 'pages/Dashboard'

import ListProject from 'pages/Projetos/Lista'
import CreateProject from 'pages/Projetos/Formulario'
import FormPJ from 'pages/Projetos/Formulario/PJ'
import FormPF from 'pages/Projetos/Formulario/PF'
import ProjectDetalhes from 'pages/Projetos/Detalhes'
import ProjectProposals from 'pages/Projetos/Propostas'
import ProjectDocumentos from 'pages/Projetos/Documentos'
import ProjectDocumentosFormalizacao from 'pages/Projetos/DocumentosFormalizacao'
import ProjectNotaFiscal from 'pages/Projetos/NotaFiscal'
import ProjectDadosTecnicos from 'pages/Projetos/DadosTecnicos'
import ProjectCredito from 'pages/Projetos/Credito'
import ProjectRelatorio from 'pages/Projetos/Relatorios'
import ViewProject from 'pages/Projetos/DetalhesFormulario'

import ClientesLista from 'pages/Clientes/Lista'
import ClientesFormulario from 'pages/Clientes/Formulario'
import ClientesVinculo from 'pages/Clientes/Vinculo'
import ClientesMeusDados from 'pages/Clientes/MeusDados'

import UsuariosLista from 'pages/Usuarios/Lista'
import UsuariosFormulario from 'pages/Usuarios/Formulario'

import PerfisLista from 'pages/Perfis/Lista'
import PerfisFormulario from 'pages/Perfis/Formulario'

import IntegradoresLista from 'pages/Integradores/Lista'
import IntegradoresFormulario from 'pages/Integradores/Formulario'
import IntegradoresVinculo from 'pages/Integradores/Vinculo'

import UsinasLista from 'pages/Usinas/Lista'
import UsinasFormulario from 'pages/Usinas/Formulario'

import CondominioLista from 'pages/Condominios/Lista'
import CondominioFormulario from 'pages/Condominios/Formulario'

import DistribuidorasLista from 'pages/Distribuidoras/Lista'
import DistribuidorasFormulario from 'pages/Distribuidoras/Formulario'

import Parametros from 'pages/Parametros'

import SemPermissao from 'pages/SemPermissao'
import PaginaNaoEncontrada from 'pages/PaginaNaoEncontrada'
import Aliquotas from 'pages/Aliquotas'

import ContratosLista from 'pages/Contratos/Lista'
import FormContrato from 'pages/Contratos/Form'

import VisualizarLogs from 'pages/Logs'
import VisualizaFaturas from 'pages/Faturas/Listar'
import ListarErrosPipefy from 'pages/Pipefy/Listar Erros'

import EditEmpresa from 'pages/Empresa'
import ErrosCemigLista from 'pages/ErrosCemig'
import ErrosFaturamentoLista from 'pages/ErrosFaturamento'

const Routes = () => {
  return (
    <Switch>
      <Route path='/' exact component={Home} isPrivate />

      <Route path='/entrar' component={Entrar} />
      <Route path='/recuperacao-senha' component={RecuperacaoSenha} />
      <Route path='/redefine-password/:token' component={RedefinicaoSenha} />

      <Route path='/' exact component={Home} isPrivate />
      <Route path='/dashboard' exact component={Dashboard} isPrivate />

      <Route path='/projetos' exact component={ListProject} isPrivate />
      <Route path='/projetos/novo/pf'exact component={FormPF} isPrivate />
      <Route path='/projetos/novo/pj' exact component={FormPJ} isPrivate />
      <Route path='/projetos/editar/:id' component={CreateProject} isPrivate />
      <Route path='/projetos/replicar/:id' component={CreateProject} isPrivate />
      <Route path='/projetos/detalhes/:id' component={ProjectDetalhes} isPrivate />
      <Route path='/projetos/propostas/:id' component={ProjectProposals} isPrivate />
      <Route path='/projetos/documentos/:id' component={ProjectDocumentos} isPrivate />
      <Route path='/projetos/credito/:id' component={ProjectCredito} isPrivate />
      <Route path='/projetos/documentos-formalizacao/:id' component={ProjectDocumentosFormalizacao} isPrivate />
      <Route path='/projetos/dados-tecnicos/:id' component={ProjectDadosTecnicos} isPrivate />
      <Route path='/projetos/notas-fiscais/:id' component={ProjectNotaFiscal} isPrivate />
      <Route path='/relatorios-projetos' component={ProjectRelatorio} isPrivate />

      <Route path='/projetos/detalhes-formulario/:id' component={ViewProject} isPrivate />

      <Route path='/parametros' component={Parametros} isPrivate />

      <Route path='/faturamento' component={VisualizaFaturas} isPrivate />

      <Route path='/clientes' exact component={ClientesLista} isPrivate />
      <Route path='/clientes/editar/:id' component={ClientesFormulario} isPrivate />
      <Route path='/clientes/visualizar/:id' component={ClientesFormulario} isPrivate />
      <Route path='/clientes/vinculo/:token' component={ClientesVinculo} />
      <Route path='/clientes/meus-dados' component={ClientesMeusDados} isPrivate />

      <Route path='/usuarios' exact component={UsuariosLista} isPrivate />
      <Route path='/usuarios/novo' component={UsuariosFormulario} isPrivate />
      <Route path='/usuarios/visualizar/:id' component={UsuariosFormulario} isPrivate />
      <Route path='/usuarios/editar/:id' component={UsuariosFormulario} isPrivate />

      <Route path='/usinas' exact component={UsinasLista} isPrivate />
      <Route path='/usinas/novo' component={UsinasFormulario} isPrivate />
      <Route path='/usinas/editar/:id' component={UsinasFormulario} isPrivate />
      <Route path='/usinas/visualizar/:id' component={UsinasFormulario} isPrivate />

      <Route path='/integradores' exact component={IntegradoresLista} isPrivate />
      <Route path='/integradores/novo' component={IntegradoresFormulario} isPrivate />
      <Route path='/integradores/visualizar/:id' component={IntegradoresFormulario} isPrivate />
      <Route path='/integradores/editar/:id' component={IntegradoresFormulario} isPrivate />
      <Route path='/integradores/vinculo/:token' component={IntegradoresVinculo} />

      <Route path='/perfis' exact component={PerfisLista} isPrivate />
      <Route path='/perfis/novo' component={PerfisFormulario} isPrivate />
      <Route path='/perfis/visualizar/:id' component={PerfisFormulario} isPrivate />
      <Route path='/perfis/editar/:id' component={PerfisFormulario} isPrivate />

      <Route path='/condominios' exact component={CondominioLista} isPrivate />
      <Route path='/condominios/novo' component={CondominioFormulario} isPrivate />
      <Route path='/condominios/visualizar/:id' component={CondominioFormulario} isPrivate />
      <Route path='/condominios/editar/:id' component={CondominioFormulario} isPrivate />

      <Route path='/distribuidoras' exact component={DistribuidorasLista} isPrivate />
      <Route path='/distribuidoras/novo' component={DistribuidorasFormulario} isPrivate />
      <Route path='/distribuidoras/visualizar/:id' component={DistribuidorasFormulario} isPrivate />
      <Route path='/distribuidoras/editar/:id' component={DistribuidorasFormulario} isPrivate />

      <Route path='/contratos' exact component={ContratosLista} isPrivate />
      <Route path='/contratos/visualizar/:id' component={FormContrato} isPrivate />
      <Route path='/contratos/editar/:id' component={FormContrato} isPrivate />

      <Route path='/pipefy/listar-erros' component={ListarErrosPipefy} isPrivate />

      <Route path='/aliquotas' component={Aliquotas} isPrivate />

      <Route path='/empresa' component={EditEmpresa} isPrivate />

      <Route path='/auditoria' component={VisualizarLogs} isPrivate />

      <Route path='/erros-cemig' component={ErrosCemigLista} isPrivate />
      <Route path='/erros-faturamento' component={ErrosFaturamentoLista} isPrivate />

      <Route path='/acesso-negado' component={SemPermissao} isPrivate />

      <Route component={PaginaNaoEncontrada} isPrivate />
    </Switch>
  )
}

export default Routes

import React from 'react'
import { Container, CustomPagination, CustomSpinner } from './styles'
import { TResquestFn, GoToWhere, TLoader } from 'hooks/usePagination'

const getPages = (current = 1, total = 0) => {
  const pagesAhead = current < 3
    ? 5 - current
    : 2

  const pagesBehind = total - current >= 2
    ? 2
    : 5 - ((total - current) + 1)

  const currentMinusPagesBehind = current - pagesBehind
  const currentPlusPagesAhead = current + pagesAhead

  const firsPageInRange = currentMinusPagesBehind <= 0
    ? 1
    : currentMinusPagesBehind

  const lastPageInRange = total >= currentPlusPagesAhead
    ? currentPlusPagesAhead
    : total

  const length = (lastPageInRange - firsPageInRange) + 1

  const _pages = Array.from(
    { length },
    (_, index) => index + firsPageInRange
  )
  return _pages
}

type TProps = {
  currentPageIndex?: number
  totalPages?: number
  request(pageIndex: number): void
  goTo(request: TResquestFn, wehre: GoToWhere): void
  goToPage(request: TResquestFn, page: number): void
  loader: TLoader
}

const Pagination = (props: TProps) => {
  const {
    currentPageIndex = 0,
    totalPages = 1,
    request,
    goTo,
    goToPage,
    loader
  } = props

  const onClickGoTo = (where: GoToWhere) => () => {
    goTo(request, where)
  }

  const onClickGoToPage = (page: number) => () => {
    goToPage(request, page)
  }

  const currentPage = currentPageIndex + 1

  const pages = getPages(currentPage, totalPages)

  const showFirstAndPrevButtons = currentPage > 1
  const showNextAndLastButtons = currentPage !== totalPages

  if (Number(totalPages) <= 1) return null

  return (
    <Container>
      <CustomPagination className='float-left'>
        {showFirstAndPrevButtons && (
          <>
            <CustomPagination.Item onClick={onClickGoTo(GoToWhere.FIRST)}>
              {loader.where === GoToWhere.FIRST ? <CustomSpinner animation='border' /> : '«'}
            </CustomPagination.Item>
            <CustomPagination.Item onClick={onClickGoTo(GoToWhere.PREV)}>
              {loader.where === GoToWhere.PREV ? <CustomSpinner animation='border' /> : '‹'}
            </CustomPagination.Item>
          </>
        )}
        {pages.map(page => {
          const isCurrent = page === currentPage
          return (
            <CustomPagination.Item
              key={page}
              active={isCurrent}
              onClick={onClickGoToPage(page)}
            >
              {loader.where === page ? <CustomSpinner animation='border' /> : page}
            </CustomPagination.Item>
          )
        })}
        {showNextAndLastButtons && (
          <>
            <CustomPagination.Item onClick={onClickGoTo(GoToWhere.NEXT)}>
              {loader.where === GoToWhere.NEXT ? <CustomSpinner animation='border' /> : '›'}
            </CustomPagination.Item>
            <CustomPagination.Item onClick={onClickGoTo(GoToWhere.LAST)}>
              {loader.where === GoToWhere.LAST ? <CustomSpinner animation='border' /> : '»'}
            </CustomPagination.Item>
          </>
        )}
      </CustomPagination>
    </Container>

  )
}

export default Pagination
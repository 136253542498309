import styled from 'styled-components'
import { AiOutlineExclamationCircle } from 'react-icons/ai'

export const ActionMenu = styled.ul`
  display: table;
  list-style: none;
  border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  border-radius: ${({ theme }) => theme.borderRadius};
  text-align: center;

  li {
    display: inline-flex;
    height: 40px;
    align-items: center;
  }

  li.disabled {
    a {
      cursor: default;
      color: ${({ theme }) => theme.colors.LIGHT_GRAY};
    }

    a:hover {
      color: ${({ theme }) => theme.colors.LIGHT_GRAY};
      text-decoration: none;
    }
  }

  li:last-child a {
    border-right: 0;
  }
`

type TActionLinkProps = {
  hasPendencia?: boolean
}

export const ActionLink = styled.a<TActionLinkProps>`
  font-size: 16px;
  white-space: nowrap;
  padding: 0 30px;
  border-right: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  color: ${p => !p.hasPendencia ? p.theme.colors.DARK_GRAY : p.theme.colors.RED};
  cursor: pointer; 
  display: flex;
  align-items: center;
  gap: 5px;

  :hover {
    color: ${p => !p.hasPendencia ? p.theme.colors.DARK_GRAY : p.theme.colors.RED};
    text-decoration: none;
  }

`

export const ExclamationIcon = styled(AiOutlineExclamationCircle)`
  color: ${({ theme }) => theme.colors.RED};
`

type TStatusProgressBarProps = {
  completion: number
}

export const StatusProgressBar = styled.div<TStatusProgressBarProps>`
  height: 29px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.LIGHT_GRAY};
  margin-bottom: 30px;

  span {
    display: block;
    height: 29px;
    width: ${p => `${p.completion ?? 0}%`};
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ theme }) => theme.colors.ORANGE};
  }
`

type TBoxProps = {
  height?: number
}

export const Box = styled.div<TBoxProps>`
  border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 20px;
  margin-bottom: 20px;
  overflow-y: auto;
  height: ${p => p.height ? `${p.height}px` : 'unset'};
`

type TBoxTitleProps = {
  spaced?: boolean
}

export const BoxTitle = styled.div<TBoxTitleProps>`
  margin-bottom: ${p => p.spaced ? '20px' : 0};
  color: ${({ theme }) => theme.colors.DARK_GRAY};

  svg {
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 10px;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    
  }
`

export const ClienteInfoContainer = styled.dl`
  color: ${({ theme }) => theme.colors.DARK_GRAY};

  dt {
    width: 80px;
    float: left;
  }
`

export const ProjetoInfoContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: wrap;

  li {
    box-sizing: border-box;
    border-right: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
    text-align: center;
    flex: 1;
    padding: 0 10px;
    min-width: 110px;

    svg {
      margin-bottom: 10px;
      color: ${({ theme }) => theme.colors.DARK_GRAY};
    }

    h4 {
      font-weight: 700;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.DARK_GRAY};
    }

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.DARK_GRAY};
      margin-left: 5px;
    }

    span:first-child {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 464px) {
    li {
      border-top: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
      padding: 20px 0;
    }

    li:first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
    }

    li:nth-child(2) {
      border-right: 0;
    }

    li:last-child {
      border-bottom: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
      padding: 20px 0;
    }
  }

  @media screen and (max-width: 354px) {
    li {
      border-right: 0;
      border-top: 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
      padding: 20px 0;
    }

    li:first-child {
      border-top: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
      border-right: 0;
    }

    li:last-child {
      border-top: 0;
      margin-top: 0;
    }
  }

  li:last-child {
    border-right: 0;
  }
`

export const ProjetoExtraInfoContainer = styled.div`
  strong {
    color: ${({ theme }) => theme.colors.DARK_GRAY};
    margin-right: 10px;
  }

  span {
    color: ${({ theme }) => theme.colors.DARK_GRAY};
  }
`
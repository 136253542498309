import styled from 'styled-components'

type TFlexContainerProps = {
  spaced?: boolean
}

export const FlexContainer = styled.div<TFlexContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${p => p.spaced ? '20px' : '0'};
`
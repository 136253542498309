import React, { useState, useEffect, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { Row, Col, Table } from 'react-bootstrap'

import { ImFilePdf, ImFileExcel } from 'react-icons/im'

import {
  PageHeader,
  PrimaryButton,
  FlexContainer,
  InfoText,
  Loader,
  TableMessageRow,
  Pagination,
  Filter
} from 'components'

import {
  CustomTable,
  CustomFlexContainer,
  MaxContentRow
} from './styles'

import useProjetos from 'repositories/useProjetos'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import { downloadBase64File, formatDateTimeToView } from 'utils/helpers'
import { statusProjetosOptions, statusSafraProjetosOptions } from 'utils/constants'
import { useAuth } from 'hooks/useAuth'

type TProjetoResponse = {
  createDate: string
  updateDate: string
  integrator: string
  projectName: string
  projectPower: string
  projectValue: string
  installationValue: string
  totalEquipmentValue: string
  proposalSafraId	: string
  statusSafraDescription: string
  createdDateStatusSafra: string
  responsibleForFinancing: string
  status: string
}

const fileFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const RelatorioProjeto = () => {
  const [projetos, setProjetos] = useState<TProjetoResponse[]>([])
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false)
  const [isDownloadingXlsx, setIsDownloadingXlsx] = useState(false)

  const repository = useProjetos()
  const pagination = usePagination()
  const filters = useFilters()
  const { userPermissions } = useAuth()

  const permissions = useMemo(() => userPermissions?.relatorios, [userPermissions])

  useEffect(() => {
    if (filters.items) requestProjetos()
  }, [filters.items])

  const requestProjetos = async (pageIndex?: number) => {
    const formatProjetos = (listaProjetos: TProjetoResponse[]) => {
      return listaProjetos.map(projeto => {
        const formattedCreateDate = formatDateTimeToView(projeto.createDate)
        const formattedUpdateDate = formatDateTimeToView(projeto.updateDate)
        return {
          ...projeto,
          createDate: formattedCreateDate,
          updateDate: formattedUpdateDate
        }
      })
    }
    const _filters = filters.getObjectifiedFilterItems()
    const params = {
      ..._filters,
      pageSize: 12,
      page: pageIndex ?? 0
    }
    const retornoProjetos = await repository.getProjetosRelatorios(params)
    pagination.stopLoading()
    filters.stopLoading()
    if (!retornoProjetos) return

    const _projetos = formatProjetos(retornoProjetos.projects)
    setProjetos(_projetos)
    pagination.setOptions(retornoProjetos.number, retornoProjetos.totalPages)
  }

  const requestFilePdfOrXlsxForDownload = async (url: string) => {
    const params = filters.getObjectifiedFilterItems()
    return await repository.getProjetosRelatoriosDownload(url, params, false)
  }

  const onClickDownloadPdf = async () => {
    setIsDownloadingPdf(true)
    const url = '/report/projects/download-pdf'
    const response = await requestFilePdfOrXlsxForDownload(url)
    if (response) await downloadBase64File('relatório', response.relatorio)
    setIsDownloadingPdf(false)
  }

  const onClickDownloadXlsx = async () => {
    setIsDownloadingXlsx(true)
    const url = '/report/projects/download-xlsx'
    const response = await requestFilePdfOrXlsxForDownload(url)
    if (response) {
      await downloadBase64File(
        'relatório',
        response.relatorio,
        fileFormat
      )
    }
    setIsDownloadingXlsx(false)
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Relatórios'>
            <PrimaryButton
              onClick={onClickDownloadPdf}
              iconPosition='right'
              disabled={!projetos.length}
              isLoading={isDownloadingPdf}
            >
              <span>Download PDF</span>
              <ImFilePdf />
            </PrimaryButton>
            <PrimaryButton
              onClick={onClickDownloadXlsx}
              iconPosition='right'
              disabled={!projetos.length}
              isLoading={isDownloadingXlsx}
            >
              <span>Download XLSX</span>
              <ImFileExcel />
            </PrimaryButton>
          </PageHeader>
        </Col>
      </Row>

      <Row>
        <Col>
          <InfoText>
            Você poderá visualizar e fazer download dos seus relatórios.
          </InfoText>
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomTable striped bordered>
            <thead>
              <tr>
                <th>
                  <CustomFlexContainer>
                    <span>Nome do Projeto</span>
                    <Filter
                      field='projectName'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Proposta SAFRA</span>
                    <Filter
                      field='proposalSafraId'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Valor</span>
                    <Filter
                      specialType='currency'
                      field='projectValue'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Valor Serviço</span>
                    <Filter
                      label={['Mínimo', 'Máximo']}
                      specialType='currency'
                      field='installationValueStart|installationValueEnd'
                      filters={filters}
                      isDate={false}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Valor Equipamento</span>
                    <Filter
                      label={['Mínimo', 'Máximo']}
                      specialType='currency'
                      field='totalEquipmentValueStart|totalEquipmentValueEnd'
                      filters={filters}
                      isDate={false}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Qtd Kwh</span>
                    <Filter
                      specialType='number'
                      field='projectPower'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Status</span>
                    <Filter
                      field='status'
                      filters={filters}
                      options={statusProjetosOptions}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Status Safra</span>
                    <Filter
                      field='statusSafra'
                      filters={filters}
                      options={statusSafraProjetosOptions}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Atualização Safra</span>
                    <Filter
                      label={[
                        'Data Inicial',
                        'Data Final'
                      ]}
                      field='statusSafraDateStart|statusSafraDateEnd'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Integrador</span>
                    <Filter
                      field='integrator'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Responsável Financeiro</span>
                    <Filter
                      field='responsibleForFinancing'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Cadastrado em</span>
                    <Filter
                      label={[
                        'Data Inicial',
                        'Data Final'
                      ]}
                      field='createDateStart|createDateEnd'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
                <th>
                  <CustomFlexContainer>
                    <span>Última atualização</span>
                    <Filter
                      label={[
                        'Data Inicial',
                        'Data Final'
                      ]}
                      field='updateDateStart|updateDateEnd'
                      filters={filters}
                    />
                  </CustomFlexContainer>
                </th>
              </tr>
            </thead>
            <tbody>
              {repository.isLoading ? (
                <TableMessageRow colSpan={13}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!projetos?.length && (
                    <TableMessageRow colSpan={13}>
                      Nenhum projeto encontrado
                    </TableMessageRow>
                  )}
                  {projetos?.map((projeto, index) => (
                    <tr key={index}>
                      <td><MaxContentRow>{projeto.projectName}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.proposalSafraId}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.projectValue}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.installationValue}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.totalEquipmentValue}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.projectPower}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.status}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.statusSafraDescription}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.createdDateStatusSafra}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.integrator}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.responsibleForFinancing}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.createDate}</MaxContentRow></td>
                      <td><MaxContentRow>{projeto.updateDate}</MaxContentRow></td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </CustomTable>
        </Col>
      </Row>

      <Row>
        <Col>
          <Pagination
            currentPageIndex={pagination.currentPageIndex!}
            totalPages={pagination.totalPages!}
            request={requestProjetos}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}

export default RelatorioProjeto
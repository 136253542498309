import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Row, Col, Table } from 'react-bootstrap'
import { BiPencil, BiCheck, BiX } from 'react-icons/bi'
import { AiOutlineEye } from 'react-icons/ai'

import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import { useGetClientes, useCreateUpdateCliente } from 'repositories/clientes'
import {
  PageHeader,
  Pagination,
  ActionButton,
  Loader,
  TableMessageRow,
  Filter,
  FlexContainer
} from 'components'
import {
  initialPaginationParams,
  tipoPessoaOptions,
  ufOptions,
  yesNoOptions,
  ActivationStatus
} from 'utils/constants'
import {
  getTipoPessoaByFirstLetter,
  getYesNoFromBoolean,
  formatCpfCnpj
} from 'utils/helpers'
import { useAuth } from 'hooks/useAuth'

const tableMessageRowColSpan = 9

const initialParams: TListaClientesParams = {
  findClientCommand: {},
  ...initialPaginationParams
}

const ClientesLista = () => {
  const [clientes, setClientes] = useState<TClienteInfo[]>([])
  const [paginationInfo, setPaginationInfo] = useState<TPageProperties>()

  const history = useHistory()
  const pagination = usePagination()
  const getClientes = useGetClientes()
  const updateCliente = useCreateUpdateCliente()
  const filters = useFilters()
  const { userPermissions } = useAuth()

  const permissions = useMemo(() => userPermissions?.clientes, [userPermissions])

  useEffect(() => {
    getClientes.get(initialParams)
  }, [])

  useEffect(() => {
    requestClientes()
  }, [filters.items])

  useEffect(() => {
    filters.stopLoading()
    pagination.stopLoading()

    if (!getClientes.paginationInfo) return

    setClientes(getClientes.clientes!)

    const { paginationInfo } = getClientes
    setPaginationInfo({
      pageNumber: paginationInfo.pageNumber,
      totalPages: paginationInfo.totalPages
    })
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)

  }, [getClientes.paginationInfo])

  useEffect(() => {
    if (updateCliente.data) {
      requestClientes(getClientes.paginationInfo?.pageNumber)
    }
  }, [updateCliente.data])

  const requestClientes = (pageIndex = 0, triggerLoading = false) => {
    const _params = {
      findClientCommand: {
        ...filters.getObjectifiedFilterItems()
      },
      paginate: {
        pageNumber: pageIndex,
        numberOfRecordsByPage: pagination.itemsPerPage
      }
    }
    getClientes.get(_params, triggerLoading)
  }

  const isEditable = (status: string) => {
    if(status === 'ACTIVATED') return true
    return false
  }

  const onClickVisualize = (id: number) => () => {
    history.push(`/clientes/visualizar/${id}`)
  }

  const onClickEdit = (id: number) => () => {
    history.push(`/clientes/editar/${id}`)
  }

  const onClickToggleClienteStatus = (cliente: TClienteInfo) => () => {
    const status = cliente.roleStatus === ActivationStatus.ACTIVATED
      ? ActivationStatus.DEACTIVATED
      : ActivationStatus.ACTIVATED

    const _representative = cliente.representative === null ? null : {
      cpf: cliente.representative.cpf,
      email: cliente.representative.email,
      id: cliente.representative.id,
      name: cliente.representative.name,
      representativeAddress: {
        city: cliente.representative.representativeAddress.city,
        complement: cliente.representative.representativeAddress.complement,
        neighborhood: cliente.representative.representativeAddress.neighborhood,
        number: cliente.representative.representativeAddress.number,
        street: cliente.representative.representativeAddress.street,
        uf: cliente.representative.representativeAddress.uf,
        zipCode: cliente.representative.representativeAddress.zipCode,
        id: cliente.representative.representativeAddress.id,
        state: cliente.representative.representativeAddress.state
      },
      telephone: cliente.representative.telephone
    }

    const _cliente: TClienteInfo = {
      clientAddress: {
        city: cliente.clientAddress.city,
        complement: cliente.clientAddress.complement,
        neighborhood: cliente.clientAddress.neighborhood,
        number: cliente.clientAddress.number,
        street: cliente.clientAddress.street,
        uf: cliente.clientAddress.uf,
        zipCode: cliente.clientAddress.zipCode,
        id: cliente.clientAddress.id,
        state: cliente.clientAddress.state
      },
      cnpj: cliente.cnpj,
      companyName: cliente.companyName,
      contracts: [],
      cpf: cliente.cpf,
      fullName: cliente.fullName,
      id: cliente.id,
      inPipefy: cliente.inPipefy,
      maritalStatus: cliente.maritalStatus,
      occupation: cliente.occupation,
      representative: _representative,
      roleStatus: status,
      telephone: cliente.telephone,
      typePerson: cliente.typePerson,
      email: cliente.email
    }

    updateCliente.send(_cliente)
  }

  const hasActiveContracts = (contratos: TClienteContrato[]) => {
    if(contratos.filter(e => e.status === 'CONNECTED').length > 0)
      return true
    if(contratos.filter(e => e.status === 'WAITING_CONNECTION').length > 0)
      return true
    if(contratos.filter(e => e.status === 'NEW').length > 0)
      return true
    return false
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Clientes' />
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>Tipo de Pessoa</span>
                    <Filter
                      field='typePerson'
                      options={tipoPessoaOptions}
                      filters={filters}
                    />
                  </FlexContainer>
                </th>

                <th>
                  <FlexContainer>
                    <span>Nome / Razão Social</span>
                    <Filter
                      field='fullNameCompanyName'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>

                <th>
                  <FlexContainer>
                    <span>CPF / CNPJ</span>
                    <Filter
                      field='cpfCnpj'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>

                <th>
                  <FlexContainer>
                    <span>Cidade</span>
                    <Filter
                      field='clientAddress.city'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>

                <th>
                  <FlexContainer>
                    <span>UF</span>
                    <Filter
                      field='clientAddress.uf'
                      options={ufOptions}
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Repres. Comercial</span>
                    <Filter
                      field='representative.name'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Pipefy</span>
                    <Filter
                      field='inPipefy'
                      options={yesNoOptions}
                      filters={filters}
                    />
                  </FlexContainer>
                </th>

                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {getClientes.isLoading ? (
                <TableMessageRow colSpan={tableMessageRowColSpan}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!clientes?.length && (
                    <TableMessageRow colSpan={tableMessageRowColSpan}>
                      Não foram encontrados clientes para serem listados
                    </TableMessageRow>
                  )}
                </>
              )}
              {clientes?.map(cliente => {
                const nomeRazaoSocial = cliente.fullName ?? cliente.companyName
                const cpfCnpj = cliente.cpf ?? cliente.cnpj
                const formattedCpfCnpj = formatCpfCnpj(cpfCnpj)
                const tipoPessoa = getTipoPessoaByFirstLetter(cliente.typePerson)
                const inPipefy = getYesNoFromBoolean(cliente.inPipefy!)
                const isUserActivated = cliente.roleStatus === ActivationStatus.ACTIVATED
                const statusButtonTitle = isUserActivated ? 'Inativar' : 'Ativar'

                return (
                  <tr key={cliente.id}>
                    <td>{tipoPessoa}</td>
                    <td>{nomeRazaoSocial}</td>
                    <td>{formattedCpfCnpj}</td>
                    <td>{cliente.clientAddress.city}</td>
                    <td>{cliente.clientAddress.uf}</td>
                    <td>{cliente.representative?.name}</td>
                    <td>{inPipefy}</td>
                    <td>
                      <ActionButton
                        title='Visualizar'
                        onClick={onClickVisualize(cliente.id!)}
                      >
                        <AiOutlineEye />
                      </ActionButton>
                      {permissions?.edit && isEditable(cliente.roleStatus) && (
                        <ActionButton
                          title='Editar'
                          onClick={onClickEdit(cliente.id!)}
                        >
                          <BiPencil />
                        </ActionButton>
                      )}
                      {permissions?.inactivate && (!hasActiveContracts(cliente.contracts)) && (
                        <ActionButton
                          title={statusButtonTitle}
                          onClick={onClickToggleClienteStatus(cliente)}
                        >
                          {isUserActivated ? <BiX /> : <BiCheck />}
                        </ActionButton>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          <Pagination
            currentPageIndex={paginationInfo?.pageNumber}
            totalPages={paginationInfo?.totalPages}
            request={requestClientes}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}

export default ClientesLista

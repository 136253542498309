import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const ENDERECO = gql`
  query findDataZipCode ($zipCode: String!) {
    findDataZipCode (zipCode: $zipCode) {
      logradouro
      bairro
      localidade
      uf
      ${commonResponse}
    }
  }
`
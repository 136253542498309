import { gql } from 'apollo-boost'
import { commonResponse } from './constants'

export const MUTATION_LOGIN = gql`
  mutation login ($loginByEmail: LoginByEmailInput) {
    login(loginByEmail: $loginByEmail) {
      ${commonResponse}
      userResult {
        name,
        roleName,
        permissions,
        id
      }
      authToken
    }
  }
`

export const MUTATION_LOGOUT = gql`
  mutation logout {
    logout {
      isError,
      statusCode,
      description
    }
  }
`

export const QUERY_VERIFY_PERMISSION = gql`
  query verifyUserHasPermission ($permission: String) {
    verifyUserHasPermission(permission: $permission) {
      ${commonResponse}
      hasPermission
    }
  }
`
import React, { useState, useEffect, useMemo } from 'react'

import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import { useHistory, Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'

import { Row, Col, Table, Modal } from 'react-bootstrap'
import { AiOutlineEye } from 'react-icons/ai'
import { BsLightning } from 'react-icons/bs'
import { BiX, BiPencil } from 'react-icons/bi'
import {
  PageHeader,
  Pagination,
  ActionButton,
  Filter,
  FlexContainer,
  ButtonsContainer,
  PrimaryButton,
  SecondaryButton,
  TableMessageRow,
  Loader
} from 'components'
import { initialPaginationParams, statusContratoOptions } from 'utils/constants'
import { formatContractStatus, formatCpfCnpj } from 'utils/helpers'
import { useToasts } from 'react-toast-notifications'
import {
  useGetContracts,
  TContractsParams,
  useUpdateContractStatus,
  TUpdateContractStatusParams
} from 'repositories/contratos'

const initialParams: TContractsParams = {
  findContractByFilterCommand: {},
  ...initialPaginationParams
}

const modalAllFalse: boolean[] = [false, false, false, false, false, false, false, false, false, false]

const ContratosLista = () => {
  const [contratos, setContratos] = useState<TClienteContrato[]>([])
  const [paginationInfo, setPaginationInfo] = useState<TPageProperties>()

  const [showConfirmationModalAdvance, setShowConfirmationModalAdvance] = useState<boolean[]>(modalAllFalse)
  const [showConfirmationModalCancel, setShowConfirmationModalCancel] = useState<boolean[]>(modalAllFalse)

  const handleHideConfirmationModalAdvance = () => setShowConfirmationModalAdvance(modalAllFalse)
  const handleShowConfirmationModalAdvance = (id: number) => {
    const _modal = showConfirmationModalAdvance.map((modal, index) => {
      if(id === index) return true
      return modal
    })
    setShowConfirmationModalAdvance(_modal)
  }
  const handleHideConfirmationModalCancel = () => setShowConfirmationModalCancel(modalAllFalse)
  const handleShowConfirmationModalCancel = (id: number) => {
    const _modal = showConfirmationModalCancel.map((modal, index) => {
      if(id === index) return true
      return modal
    })
    setShowConfirmationModalCancel(_modal)
  }
  const history = useHistory()
  const pagination = usePagination()
  const filters = useFilters()
  const { userPermissions } = useAuth()
  const getContratos = useGetContracts()
  const updateContrato = useUpdateContractStatus()
  const { addToast } = useToasts()


  const permissions = useMemo(() => userPermissions?.contrato, [userPermissions])

  useEffect(() => {
    getContratos.get(initialParams)
  }, [])

  useEffect(() => {
    requestContracts()
  }, [filters.items])

  useEffect(() => {
    if(!getContratos.contratos) return
    setContratos(getContratos.contratos)
  }, [getContratos.contratos])

  useEffect(() => {
    filters.stopLoading()
    pagination.stopLoading()

    if (!getContratos.paginationInfo) return

    setContratos(getContratos.contratos!)

    const { paginationInfo } = getContratos
    setPaginationInfo({
      pageNumber: paginationInfo.pageNumber,
      totalPages: paginationInfo.totalPages
    })
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)

  }, [getContratos.paginationInfo])

  useEffect(() => {
    handleHideConfirmationModalCancel()
    handleHideConfirmationModalAdvance()
    if(!updateContrato.response?.statusCode) return
    if(updateContrato.response?.statusCode < 0) return
    addToast(
      'Status modificado com sucesso!',
      { appearance: 'success', autoDismiss: true }
    )
    requestContracts()
  }, [updateContrato.response?.statusCode])


  const requestContracts = (pageIndex = 0, triggerLoading = true) => {
    const _params = {
      findContractByFilterCommand: {
        ...filters.getObjectifiedFilterItems(),
      },
      paginate: {
        pageNumber: pageIndex,
        numberOfRecordsByPage: 10
      }
    }
    getContratos.get(_params, triggerLoading)
  }

  const onClickVisualize = (id: number) => () => {
    history.push(`/contratos/visualizar/${id}`)
  }

  const onClickEdit = (id: number) => () => {
    history.push(`/contratos/editar/${id}`)
  }

  const cancelContract = (id: number) => {
    const params: TUpdateContractStatusParams = {
      id,
      newContractStatus: 'CANCELED'
    }
    updateContrato.send(params)
  }

  const advanceContract = (id: number) => {
    const params: TUpdateContractStatusParams = {
      id
    }
    updateContrato.send(params)
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Contratos' />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>Contrato</span>
                    <Filter
                      field='contractHash'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Cliente</span>
                    <Filter
                      field='fullNameCompanyName'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>CPF/CNPJ</span>
                    <Filter
                      field='cpfCnpj'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>UC</span>
                    <Filter
                      field='unitConsumption'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Status </span>
                    <Filter
                      field='status'
                      options={statusContratoOptions}
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Usina</span>
                    <Filter
                      field='powerPlantName'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Ações</span>
                  </FlexContainer>
                </th>
              </tr>
            </thead>
            <tbody>
              {getContratos.isLoading ? (
                <TableMessageRow colSpan={7}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {contratos.length === 0 && (
                    <TableMessageRow colSpan={7}>
                      Não foram encontrados contratos para serem listados
                    </TableMessageRow>)}
                  {contratos?.map((contrato, index) => {
                    const nomeRazaoSocial = contrato.client.fullName ?? contrato.client.companyName
                    const cpfCnpj = contrato.client.cpf ?? contrato.client.cnpj
                    const formattedCpfCnpj = formatCpfCnpj(cpfCnpj)
                    return (
                      <tr key={contrato.id}>
                        <td>{contrato.contractHash}</td>
                        <td>{nomeRazaoSocial}</td>
                        <td>{formattedCpfCnpj}</td>
                        <td>{contrato.unitConsumption}</td>
                        <td>{formatContractStatus(contrato.status)}</td>
                        <td>{contrato.powerPlant?.name}</td>
                        <td>
                          <ActionButton
                            title='Visualizar'
                            onClick={onClickVisualize(contrato.id!)}
                          >
                            <AiOutlineEye />
                          </ActionButton>
                          <ActionButton
                            title='Editar'
                            onClick={onClickEdit(contrato.id!)}
                          >
                            <BiPencil />
                          </ActionButton>
                          {permissions?.status &&
                      !(contrato.status === 'CANCELED') &&
                      !(contrato.status === 'CONNECTED') &&
                      (contrato.powerPlant) &&
                      (
                        <>
                          <ActionButton
                            title='Avançar Status'
                            onClick={() => handleShowConfirmationModalAdvance(index)}
                          >
                            <BsLightning />
                          </ActionButton>
                          <Modal
                            size='sm'
                            show={showConfirmationModalAdvance[index]}
                            onHide={handleHideConfirmationModalAdvance}
                          >
                            <Modal.Body>
                            O avanço de status do contrato é irreversível, deseja realmente avançar o Contrato {contrato.contractHash}?
                            </Modal.Body>

                            <Modal.Footer>
                              <ButtonsContainer compact>
                                <SecondaryButton onClick={handleHideConfirmationModalAdvance}>
                                Não
                                </SecondaryButton>

                                <PrimaryButton
                                  onClick={() => advanceContract(contrato.id!)}
                                  isLoading={updateContrato.isLoading}
                                >
                                Sim
                                </PrimaryButton>
                              </ButtonsContainer>
                            </Modal.Footer>
                          </Modal>
                        </>)
                          }
                          {permissions?.status && !(contrato.status === 'CANCELED') && (
                            <>
                              <ActionButton
                                title='Cancelar'
                                onClick={() => handleShowConfirmationModalCancel(index)}
                              >
                                <BiX />
                              </ActionButton>
                              <Modal
                                size='sm'
                                show={showConfirmationModalCancel[index]}
                                onHide={handleHideConfirmationModalCancel}
                              >
                                <Modal.Body>
                            O cancelamento de um contrato é irreversível, deseja realmente cancelar o Contrato {contrato.contractHash}?
                                </Modal.Body>

                                <Modal.Footer>
                                  <ButtonsContainer compact>
                                    <SecondaryButton onClick={handleHideConfirmationModalCancel}>
                                  Não
                                    </SecondaryButton>

                                    <PrimaryButton
                                      onClick={() => cancelContract(contrato.id!)}
                                      isLoading={updateContrato.isLoading}
                                    >
                                  Sim
                                    </PrimaryButton>
                                  </ButtonsContainer>
                                </Modal.Footer>
                              </Modal>
                            </>)}
                        </td>
                      </tr>
                    )})}
                </>)}

            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination
            currentPageIndex={paginationInfo?.pageNumber}
            totalPages={paginationInfo?.totalPages}
            request={requestContracts}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}

export default ContratosLista
import * as Yup from 'yup'
import {
  cpf as cpfValidation,
  cnpj as cnpjValidation
} from 'cpf-cnpj-validator'
import { regexCep } from 'utils/constants'
import { removeMaskGuides } from 'utils/helpers'
import moment from 'moment'

export const estadoCivilCasado = '2'
export const ocupacaoEmpresario = '4'
export const ocupacaoAssalariado = ['2', '5']
export const nacionalidadeBrasileira = '1'

export const initialValues: TProjetoForm = {
  enderecamentoPessoal: '',
  enderecamentoConjugePessoal: '',
  tipoFinanciamento: '1',
  finalidadeProjeto: '',
  nomeProjeto: '',
  potenciaProjeto: '',
  valorProjeto: '',
  estadoProjeto: '',
  cidadeProjeto: '',
  cidadeProjetoId: '',
  integrador: '',
  integradorId: '',
  nomeCompleto: '',
  dataNascimento: '',
  email: '',
  celular: '',
  cep: '',
  endereco: '',
  numero: '',
  bairro: '',
  complemento: '',
  cidade: '',
  cidadeId: '',
  estado: '',
  tipoResidencia: '',
  nacionalidade: '',
  nacionalidadeId: '',
  cidadeNaturalidade: '',
  cidadeNaturalidadeId: '',
  estadoNaturalidade: '',
  escolaridade: '',
  sexo: '',
  estadoCivil: '',
  cpf: '',
  nomeMae: '',
  tipoDocumento: '',
  documento: '',
  orgaoEmissor: '',
  estadoOrgaoEmissor: '',
  dataEmissao: '',
  ocupacao: '',
  profissao: '',
  cargo: '',
  rendaMensal: '',
  patrimonio: '',
  comercialCnpj: '',
  comercialNome: '',
  comercialAdmissao: '',
  comercialTelefone: '',
  comercialTelefoneRamal: '',
  comercialEnderecoObrigatorio: 'false',
  comercialCep: '',
  comercialEndereco: '',
  comercialNumero: '',
  comercialComplemento: '',
  comercialBairro: '',
  comercialCidade: '',
  comercialCidadeId: '',
  comercialEstado: '',
  conjugeNome: '',
  conjugeCpf: '',
  conjugeComercialCnpj: '',
  conjugeComercialNome: '',
  conjugeComercialAdmissao: '',
  conjugeComercialTelefone: '',
  conjugeComercialTelefoneRamal: '',
  conjugeEnderecoObrigatorio: 'false',
  conjugeComercialCep: '',
  conjugeComercialEndereco: '',
  conjugeComercialNumero: '',
  conjugeComercialComplemento: '',
  conjugeComercialBairro: '',
  conjugeComercialCidade: '',
  conjugeComercialCidadeId: '',
  conjugeComercialEstado: '',
  conjugeRendaMensal: '',
  conjugeOcupacao: '',
  conjugeProfissao: '',
  conjugeCargo: '',
}

export const schema = Yup.object().shape({
  tipoFinanciamento: Yup.string().required('Tipo de Financiamento é obrigatório'),
  finalidadeProjeto: Yup.string().required('Finalidade do projeto é obrigatório'),
  nomeProjeto: Yup.string().required('Nome do projeto é obrigatório'),
  potenciaProjeto: Yup.string()
    .test(
      '',
      'Potência estimada não pode ser menor que zero',
      value => (parseFloat(value!) > 0)
    ).required('Potência estimada é obrigatório'),
  valorProjeto: Yup.string().required('Valor estimado é obrigatório')
    .test(
      '',
      'Valor estimado não pode ser menor que zero',
      value => (parseFloat(value!) > 0)
    ),
  estadoProjeto: Yup.number().required('Estado é obrigatório'),
  cidadeProjeto: Yup.string().required('Cidade é obrigatório'),
  nomeCompleto: Yup.string().required('Nome completo é obrigatório'),
  dataNascimento: Yup
    .date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .max(new Date, 'Data posterior a atual não é permitido')
    .required('Data de nascimento é obrigatório'),
  email: Yup.string().email('Entre com um email válido').required('E-mail é obrigatório'),
  celular: Yup
    .string()
    .test(
      '',
      'Insira um celular válido',
      value => {
        const zeroedPhone = '(00) 00000-0000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = cleanPhone.length < 10

        return (!isPhoneIncomplete && !isPhoneZeroed)
      }
    ).required('Celular é obrigatório'),
  cep: Yup
    .string()
    .required('CEP é obrigatório')
    .matches(regexCep, 'CEP não está com o formato válido'),
  endereco: Yup.string().required('Logradouro é obrigatório'),
  numero: Yup.string()
    .max(5, 'Não pode ser maior que 5 dígitos')
    .required('Número é obrigatório'),
  bairro: Yup.string().required('Bairro é obrigatório'),
  cidade: Yup.string().required('Cidade é obrigatório'),
  estado: Yup.number().required('Estado é obrigatório'),
  tipoResidencia: Yup.number().required('Tipo de Residência é obrigatório'),
  nacionalidade: Yup.string().required('Nacionalidade é obrigatório'),
  cidadeNaturalidade: Yup
    .string()
    .when('nacionalidadeId', {
      is: Number(nacionalidadeBrasileira),
      then: Yup.string().required('Cidade naturalidade é obrigatório')
    }),
  estadoNaturalidade: Yup
    .string()
    .when('nacionalidadeId', {
      is: Number(nacionalidadeBrasileira),
      then: Yup.string().required('Estado de naturalidade é obrigatório')
    }),
  escolaridade: Yup.number().required('Escolaridade é obrigatório'),
  sexo: Yup.string().required('Sexo é obrigatório'),
  estadoCivil: Yup.string().required('Estado civil é obrigatório'),
  cpf: Yup.string().required('CPF é obrigatório')
    .test(
      '',
      'CPF não está válido',
      value => (cpfValidation.isValid(value!))
    ),
  nomeMae: Yup.string().required('Nome da mãe é obrigatório'),
  tipoDocumento: Yup.string().required('Tipo de documento é obrigatório'),
  documento: Yup.string().required('Número do documento é obrigatório'),
  orgaoEmissor: Yup.string().when('tipoDocumento', {
    is: '1',
    then: Yup.string().required('Órgão emissor é obrigatório')
  }),
  estadoOrgaoEmissor: Yup.string().when('tipoDocumento', {
    is: '1',
    then: Yup.string().required('UF do órgão emissor é obrigatório')
  }),
  dataEmissao: Yup.date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .max(new Date, 'Data posterior a atual não é permitido')
    .when('tipoDocumento', {
      is: '1',
      then: Yup
        .date()
        .required('Data de emissão é obrigatório'),
      otherwise: Yup.date()
    }),
  ocupacao: Yup.string().required('Ocupação / Setor é obrigatório'),
  profissao: Yup.string().required('Profissão é obrigatório'),
  cargo: Yup.string().required('Cargo / Atividade é obrigatório'),
  rendaMensal: Yup
    .string()
    .required('Renda mensal é obrigatório')
    .test(
      '',
      'Renda mensal não pode ser menor que zero',
      value => (parseFloat(value!) > 0)
    ),
  patrimonio: Yup.string().required('Patrimônio é obrigatório'),
  comercialNome: Yup
    .string()
    .when(['ocupacao'], {
      is: (ocupacao: string) => (
        ocupacaoAssalariado.indexOf(String(ocupacao)) >= 0
      ),
      then: Yup.string().required('Nome da empresa é obrigatório')
    }),
  comercialCnpj: Yup
    .string()
    .when(['ocupacao'], {
      is: (conjugeOcupacao: string) => (
        String(conjugeOcupacao) === ocupacaoEmpresario
      ),
      then: Yup
        .string()
        .required('CNPJ é obrigatório')
    }).test(
      '',
      'CNPJ não está válido',
      value => {
        const cleanCnpj = removeMaskGuides(String(value))
        const cnpjInputIsEmpty = cleanCnpj.length === 0
        return cnpjInputIsEmpty || cnpjValidation.isValid(cleanCnpj!)
      }
    ),
  comercialAdmissao: Yup
    .date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .max(new Date, 'Data posterior a atual não é permitido')
    .when(['ocupacao'], {
      is: (ocupacao: string) => (
        ocupacaoAssalariado.indexOf(String(ocupacao)) >= 0
      ),
      then: Yup.date().required('Data de admissão é obrigatória')
    }),
  comercialEnderecoObrigatorio: Yup.boolean(),
  comercialCep: Yup
    .string()
    .when(['ocupacao', 'comercialEnderecoObrigatorio'], {
      is: (ocupacao: string, comercialEnderecoObrigatorio: boolean) => (
        String(ocupacao) === ocupacaoEmpresario ||
        comercialEnderecoObrigatorio
      ),
      then: Yup
        .string()
        .required('CEP é obrigatório')
    }).test(
      '',
      'CEP não está válido',
      value => {
        const cleanCep = removeMaskGuides(String(value))
        const cepInputIsEmpty = cleanCep.length === 0
        return cepInputIsEmpty || cleanCep.length === 8
      }
    ),
  comercialEndereco: Yup
    .string()
    .when(['ocupacao', 'comercialEnderecoObrigatorio'], {
      is: (ocupacao: string, comercialEnderecoObrigatorio: boolean) => (
        String(ocupacao) === ocupacaoEmpresario ||
        comercialEnderecoObrigatorio
      ),
      then: Yup.string().required('Logradouro é obrigatório')
    }),
  comercialNumero: Yup
    .string()
    .when(['ocupacao', 'comercialEnderecoObrigatorio'], {
      is: (ocupacao: string, comercialEnderecoObrigatorio: boolean) => (
        String(ocupacao) === ocupacaoEmpresario ||
        comercialEnderecoObrigatorio
      ),
      then: Yup
        .string()
        .required('Número é obrigatório')
    }).max(5, 'Não pode ser maior que 5 dígitos'),
  comercialBairro: Yup
    .string()
    .when(['ocupacao', 'comercialEnderecoObrigatorio'], {
      is: (ocupacao: string, comercialEnderecoObrigatorio: boolean) => (
        String(ocupacao) === ocupacaoEmpresario ||
        comercialEnderecoObrigatorio
      ),
      then: Yup.string().required('Bairro é obrigatório')
    }),
  comercialEstado: Yup
    .number()
    .when(['ocupacao', 'comercialEnderecoObrigatorio'], {
      is: (ocupacao: string, comercialEnderecoObrigatorio: boolean) => (
        String(ocupacao) === ocupacaoEmpresario ||
        comercialEnderecoObrigatorio
      ),
      then: Yup.number().required('Estado é obrigatório')
    }),
  comercialCidade: Yup
    .string()
    .when(['ocupacao', 'comercialEnderecoObrigatorio'], {
      is: (ocupacao: string, comercialEnderecoObrigatorio: boolean) => (
        String(ocupacao) === ocupacaoEmpresario ||
        comercialEnderecoObrigatorio
      ),
      then: Yup.string().required('Cidade é obrigatório')
    }),
  comercialTelefone: Yup
    .string()
    .test(
      '',
      'Insira um telefone válido',
      value => {
        const zeroedPhone = '(00) 0000-0000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = cleanPhone.length < 10

        return (!isPhoneIncomplete && !isPhoneZeroed)
      }
    ).required('Telefone é obrigatório'),
  comercialTelefoneRamal: Yup.number(),
  conjugeNome: Yup
    .string()
    .when('estadoCivil', {
      is: estadoCivilCasado,
      then: Yup
        .string()
        .notOneOf(
          [Yup.ref('nomeCompleto')],
          'Nome do conjuge não pode ser igual o do cliente'
        )
        .required('Nome do conjuge é obrigatório')
    }),
  conjugeCpf: Yup
    .string()
    .when('estadoCivil', {
      is: estadoCivilCasado,
      then: Yup
        .string()
        .notOneOf(
          [Yup.ref('cpf')],
          'CPF do conjuge não pode ser igual o do cliente'
        )
        .test(
          '',
          'CPF não está válido',
          value => (cpfValidation.isValid(value!))
        ).required('CPF é obrigatório')
    }),
  conjugeOcupacao: Yup
    .string()
    .when('conjugeRendaMensal', {
      is: (conjugeRendaMensal: string) => conjugeRendaMensal?.length > 3,
      then: Yup.string().required('Ocupação / Setor é obrigatório')
    }),
  conjugeProfissao: Yup
    .string()
    .when('conjugeRendaMensal', {
      is: (conjugeRendaMensal: string) => conjugeRendaMensal?.length > 3,
      then: Yup.string().required('Profissão é obrigatório')
    }),
  conjugeCargo: Yup
    .string()
    .when('conjugeRendaMensal', {
      is: (conjugeRendaMensal: string) => conjugeRendaMensal?.length > 3,
      then: Yup.string().required('Cargo é obrigatório')
    }),
  conjugeComercialNome: Yup
    .string()
    .when(['conjugeRendaMensal', 'conjugeOcupacao'], {
      is: (conjugeRendaMensal: string, conjugeOcupacao: string) => (
        conjugeRendaMensal?.length > 3 &&
        ocupacaoAssalariado.indexOf(String(conjugeOcupacao)) >= 0
      ),
      then: Yup.string().required('Nome da empresa é obrigatório')
    }),
  conjugeComercialCnpj: Yup
    .string()
    .when(['conjugeRendaMensal', 'conjugeOcupacao'], {
      is: (conjugeRendaMensal: string, conjugeOcupacao: string) => (
        conjugeRendaMensal?.length > 3 &&
        String(conjugeOcupacao) === ocupacaoEmpresario
      ),
      then: Yup
        .string()
        .required('CNPJ é obrigatório')
    }).test(
      '',
      'CNPJ não está válido',
      value => {
        const cleanCnpj = removeMaskGuides(String(value))
        const cnpjInputIsEmpty = cleanCnpj.length === 0
        return cnpjInputIsEmpty || cnpjValidation.isValid(cleanCnpj!)
      }
    ),
  conjugeComercialAdmissao: Yup
    .date()
    .transform((value, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
      return correctDate
    })
    .min(moment().subtract(110, 'years'), 'Insira uma data válida')
    .max(new Date, 'Data posterior a atual não é permitido')
    .when(['conjugeRendaMensal', 'conjugeOcupacao'], {
      is: (conjugeRendaMensal: string, conjugeOcupacao: string) => (
        conjugeRendaMensal?.length > 3 &&
        ocupacaoAssalariado.indexOf(String(conjugeOcupacao)) >= 0
      ),
      then: Yup.date().required('Data de admissão é obrigatória')
    }),
  conjugeRendaMensal: Yup.string(),
  conjugeEnderecoObrigatorio: Yup.boolean(),
  conjugeComercialCep: Yup
    .string()
    .when(['conjugeRendaMensal', 'conjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (conjugeRendaMensal: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        conjugeRendaMensal?.length > 3 &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup
        .string()
        .required('CEP é obrigatório')
    }).test(
      '',
      'CEP não está válido',
      value => {
        const cleanCep = removeMaskGuides(String(value))
        const cepInputIsEmpty = cleanCep.length === 0
        return cepInputIsEmpty || cleanCep.length === 8
      }
    ),
  conjugeComercialEndereco: Yup
    .string()
    .when(['conjugeRendaMensal', 'conjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (conjugeRendaMensal: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        conjugeRendaMensal?.length > 3 &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Logradouro é obrigatório')
    }),
  conjugeComercialNumero: Yup
    .string()
    .when(['conjugeRendaMensal', 'conjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (conjugeRendaMensal: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        conjugeRendaMensal?.length > 3 &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup
        .string()
        .required('Número é obrigatório')
    }).max(5, 'Não pode ser maior que 5 dígitos'),
  conjugeComercialBairro: Yup
    .string()
    .when(['conjugeRendaMensal', 'conjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (conjugeRendaMensal: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        conjugeRendaMensal?.length > 3 &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Bairro é obrigatório')
    }),
  conjugeComercialEstado: Yup
    .number()
    .when(['conjugeRendaMensal', 'conjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (conjugeRendaMensal: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        conjugeRendaMensal?.length > 3 &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.number().required('Estado é obrigatório')
    }),
  conjugeComercialCidade: Yup
    .string()
    .when(['conjugeRendaMensal', 'conjugeOcupacao', 'conjugeEnderecoObrigatorio'], {
      is: (conjugeRendaMensal: string, conjugeOcupacao: string, conjugeEnderecoObrigatorio: boolean) => (
        conjugeRendaMensal?.length > 3 &&
        (String(conjugeOcupacao) === ocupacaoEmpresario || conjugeEnderecoObrigatorio)
      ),
      then: Yup.string().required('Cidade é obrigatório')
    }),
  conjugeComercialTelefone: Yup
    .string()
    .when('conjugeRendaMensal', {
      is: (conjugeRendaMensal: string) => conjugeRendaMensal?.length > 3,
      then: Yup
        .string()
        .test(
          '',
          'Insira um telefone válido',
          value => {
            const zeroedPhone = '(00) 0000-0000'
            const isPhoneZeroed = value === zeroedPhone

            const cleanPhone = removeMaskGuides(String(value))
            const isPhoneIncomplete = cleanPhone.length < 10

            return (!isPhoneIncomplete && !isPhoneZeroed)
          }
        ).required('Telefone é obrigatório'),
    }),
  conjugeComercialTelefoneRamal: Yup.number(),
})

import React, { useEffect, useMemo, useState } from 'react'
import {
  Filter,
  FlexContainer,
  PageHeader,
  Pagination,
  PrimaryButton,
  TableMessageRow,
} from 'components'
import { useAuth } from 'hooks/useAuth'
import useFilters from 'hooks/useFilters'
import usePagination from 'hooks/usePagination'
import { Col, Row, Table } from 'react-bootstrap'
import {
  TFindGenerateInvoiceLogInfo,
  TFindGenerateInvoiceLogParams,
  useGetInvoicingLogs,
} from 'repositories/errosFaturamento'
import {
  configToastError,
  configToastSuccess,
  initialPaginationParams,
} from 'utils/constants'
import { formatDateToView } from 'utils/helpers'
import { Redirect } from 'react-router-dom'
import { AiOutlineInteraction } from 'react-icons/ai'
import useSocket from 'hooks/useSocket'
import { useToasts } from 'react-toast-notifications'
import useInvoiceCalculate from 'repositories/useCalculateInvoice'

const initialParams: TFindGenerateInvoiceLogParams = {
  findGenerateInvoiceLogCommand: {},
  ...initialPaginationParams,
}

const ErrosFaturamentoLista = () => {
  const pagination = usePagination()
  const { userPermissions } = useAuth()
  const getInvoicingLogs = useGetInvoicingLogs()
  const filters = useFilters()
  const socket = useSocket()
  const calculateInvoice = useInvoiceCalculate()
  const { addToast } = useToasts()
  const [paginationInfo, setPaginationInfo] = useState<TPageProperties>()
  const [invoincingLogs, setInvoicingLogs] = useState<
    TFindGenerateInvoiceLogInfo[]
  >([])

  useEffect(() => {
    getInvoicingLogs.get(initialParams)
  }, [])

  useEffect(() => {
    if (!getInvoicingLogs.errors) return
    setInvoicingLogs(getInvoicingLogs.errors)
  }, [getInvoicingLogs.errors])

  useEffect(() => {
    requestInvoincingErrors()
  }, [filters.items])

  useEffect(() => {
    pagination.stopLoading()
    filters.stopLoading()

    if (!getInvoicingLogs.paginationInfo) return
    if (!getInvoicingLogs.errors) return

    setInvoicingLogs(getInvoicingLogs.errors)

    const { paginationInfo } = getInvoicingLogs
    setPaginationInfo({
      pageNumber: paginationInfo.pageNumber,
      totalPages: paginationInfo.totalPages,
    })
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)
  }, [getInvoicingLogs.paginationInfo])

  const requestInvoincingErrors = (pageIndex = 0, triggerLoading = false) => {
    const _params: TFindGenerateInvoiceLogParams = {
      findGenerateInvoiceLogCommand: {
        ...filters.getObjectifiedFilterItems(),
      },
      paginate: {
        pageNumber: pageIndex,
        numberOfRecordsByPage: 10,
      },
    }
    getInvoicingLogs.get(_params, triggerLoading)
  }

  const handleCalculate = () => {
    try {
      const stompClient = socket.socketConnect()
      stompClient.connect({}, async () => {
        const response = await calculateInvoice.postCalculateInvoice()
        if (response) {
          addToast(
            'O processamento do cálculo da fatura foi iniciado, assim que estiver concluído, você receberá uma notificação',
            configToastSuccess
          )
        }
        const subscription = stompClient.subscribe(
          `/topic/messages/${response}`,
          (message: { body: string }) => {
            const jsonParse = JSON.parse(message.body)
            addToast(
              jsonParse.commonResponse.finishedWithError
                ? 'O processamento do cálculo da fatura foi concluido, porém contém erros'
                : 'O processamento do cálculo da fatura foi concluído com sucesso!',
              configToastSuccess
            )
            subscription.unsubscribe()
          }
        )
      })
    } catch {
      addToast('Erro na conexão com o socket', configToastError)
      socket.socketConnect().unsubscribe()
    }
  }

  const permissions = useMemo(
    () => userPermissions?.errosFaturamento,
    [userPermissions]
  )

  if (userPermissions && !permissions?.view)
    return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Erros Faturamento'>
            <PrimaryButton onClick={handleCalculate} iconPosition='right'>
              <span>Executar recálculo</span>
              <AiOutlineInteraction />
            </PrimaryButton>
          </PageHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>Data</span>
                    <Filter
                      label={['Data Início', 'Data Fim']}
                      field='updatedDateStart|updatedDateEnd'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>CPF/CNPJ</span>
                    <Filter field='cpfOrCnpj' filters={filters} />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Unidade consumidora</span>
                    <Filter field='unitConsumption' filters={filters} />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Status</span>
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Descrição</span>
                    <Filter field='description' filters={filters} />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Mês de referência</span>
                    <Filter field='referenceMonth' filters={filters} />
                  </FlexContainer>
                </th>
              </tr>
            </thead>
            <tbody>
              {invoincingLogs.length === 0 && (
                <TableMessageRow colSpan={7}>
                  Não foram encontrados erros para serem listados
                </TableMessageRow>
              )}
              {invoincingLogs &&
                invoincingLogs.map(log => (
                  <tr key={log.id}>
                    <td>{formatDateToView(log.updatedIn)}</td>
                    <td>{log.cpfOrCnpj}</td>
                    <td>{log.unitConsumption}</td>
                    <td>{log.status}</td>
                    <td>{log.description}</td>
                    <td>{log.referenceMonth}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination
            currentPageIndex={paginationInfo?.pageNumber}
            totalPages={paginationInfo?.totalPages}
            request={requestInvoincingErrors}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}

export default ErrosFaturamentoLista

import { useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'
import {
  CONTRACTS_QUERY,
  CONTRACT_BY_ID_QUERY,
  ATTACHMENT_DOWNLOAD_LINK,
  SAVE_CONTRACT,
  UPDATE_CONTRACT_STATUS,
  UPDATE_CONTRACT
} from './contratosGqls'

type TContractsResponse = {
  findContractByFilter: TCommonResponse & TPaginationInfo &{
    contractSuccess: TClienteContrato[]
  }}

export type TContractsParams = {
  findContractByFilterCommand: TFindContractByFilterCommand
  paginate: TPaginationParams
}

export type TFindContractByFilterCommand = {
  id?: number
  contractHash?: string
  contractPipefyID?: number
  sellerName?: number
  unitConsumption?: string
  category?: string
  type?: string
  subclass?: string
  status?: string
  powerPlantName?: string
  cpfCnpj?: string
}

export const useGetContracts = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestContracts, { loading, data }] = useLazyQuery<TContractsResponse>(CONTRACTS_QUERY, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findContractByFilter.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (params: TContractsParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestContracts({
      variables: params
    })
  }

  return {
    isLoading: loading && isLoading,
    contratos: data?.findContractByFilter.contractSuccess,
    paginationInfo: data?.findContractByFilter.paginationInfo,
    get
  }
}


type TContractByIdResponse = {
  findContractById: TCommonResponse &{
    contractSuccess: TClienteContrato
  }}


export const useGetContractById = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestContract, { loading, data }] = useLazyQuery<TContractByIdResponse>(CONTRACT_BY_ID_QUERY, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.findContractById.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: number, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestContract({
      variables: { id }
    })
  }

  return {
    isLoading: loading && isLoading,
    contrato: data?.findContractById.contractSuccess,
    get
  }
}

type TAttachmentLinkResponse = {
  getAttachmentDownloadLink: TCommonResponse &{
    publicLinkEnergyBill: string
  }}


export const useGetAttachmentLink = () => {
  const [isLoading, setIsLoading] = useState(false)

  const errorHandler = useErrorHandler()

  const [requestAttachmentLink, { loading, data }] = useLazyQuery<TAttachmentLinkResponse>(ATTACHMENT_DOWNLOAD_LINK, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    setIsLoading(false)
    const { isError, description } = data.getAttachmentDownloadLink.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (id: number, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    requestAttachmentLink({
      variables: { id }
    })
  }

  return {
    isLoading: loading && isLoading,
    link: data?.getAttachmentDownloadLink.publicLinkEnergyBill,
    get
  }
}

type TSaveContractsResponse = {
  saveContracts: TCommonResponse
}

export type TSaveContractParams = {
  contractId: number
  powerPlantId: number
}


export const useSaveContract = () => {

  const errorHandler = useErrorHandler()

  const [
    saveContracts,
    { data, loading }
  ] = useMutation<TSaveContractsResponse>(SAVE_CONTRACT, {
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.saveContracts.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (params: TSaveContractParams) => {
    saveContracts({
      variables: {
        registerContractCommand: params
      }
    })
  }

  return {
    send,
    isLoading: loading,
    response: data?.saveContracts.commonResponse
  }
}


export type TUpdateContractStatusParams = {
  id: number
  newContractStatus?: string
}

type TUpdateContractsResponse = {
  updateContractStatus: TCommonResponse
}

export const useUpdateContractStatus = () => {

  const errorHandler = useErrorHandler()

  const [
    updateContract,
    { data, loading }
  ] = useMutation<TUpdateContractsResponse>(UPDATE_CONTRACT_STATUS, {
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.updateContractStatus.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (params: TUpdateContractStatusParams) => {
    updateContract({
      variables: params
    })
  }

  return {
    send,
    isLoading: loading,
    response: data?.updateContractStatus.commonResponse
  }
}

export type TUpdateContractParams = {
  updateContractCommand: TUpdateContractCommand
}

type TUpdateContractCommand = {
  id: number
  sellerName: string
  contractHash: string
  type: string
  subclass: string
  averageConsumption: string
  unitConsumption: string
  discount: number
  averageCost: number
  finalCost: number
  readingDate: string
  street: string
  number: string
  complement: string
  city: string
  uf?: string
  state: string
  neighborhood: string
  zipCode: string
}

type TSaveORUpdateContractsResponse = {
  saveOrUpdateContracts: TCommonResponse
}

export const useUpdateContract = () => {

  const errorHandler = useErrorHandler()

  const [
    updateContract,
    { data, loading }
  ] = useMutation<TSaveORUpdateContractsResponse>(UPDATE_CONTRACT, {
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return
    const { isError, description } = data.saveOrUpdateContracts.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const send = (params: TUpdateContractParams) => {
    updateContract({
      variables: params
    })
  }

  return {
    send,
    isLoading: loading,
    response: data?.saveOrUpdateContracts.commonResponse
  }
}
import { empty } from 'apollo-boost'
import client from 'services/apollo'
import { formatCurrencyToServer, formatDateToServer, removeSpecialCharacters } from 'utils/helpers'

export const noGuarantors = (values: any, conjugeFullRequired: boolean) => {
  const dados: TProjetoBodyPJ = {
      estimatedPowerOfProject: formatCurrencyToServer(values.potenciaProjeto),
      estimatedValueOfProject: formatCurrencyToServer(values.valorProjeto),
      projectName: values.nomeProjeto,
      projectPurpose: {
        id: Number(values.finalidadeProjeto)
      },
      typeFinancing: {
        id: Number(values.tipoFinanciamento)
      },
      state: {
        id: Number(values.estadoProjeto)
      },
      city: {
        id: Number(values.cidadeProjetoId)
      },
      integrator: {
        id: Number(values.integradorId)
      },
      clientPJ: {
        corporateName: values.razaoSocial,
        fantasyName: values.nomeFantasia,
        cnpj: Number(removeSpecialCharacters(values.cnpj)),
        constitutionDate: values.dataConstituicao,
        annualBillingAverageValue: Number(formatCurrencyToServer(values.valorFaturamentoAnualMedio)),
        nameEconomicGroup: values.nomeGrupoEconomico,
        nationality: {
          id: Number(values.nacionalidadeId),
        },
        netWorth: Number(formatCurrencyToServer(values.patrimonioLiquido)),
        commercialPhone: values.telefoneComercial,
        cellPhone: values.celularComercial,
        companySize: {
          idPorteEmpresa: Number(values.porte)
        },
        economicActivity: {
          id: Number(values.atividadeEconomicaId)
        },
        activityBranch: {
          idRamoAtividade: Number(values.ramo)
        },
        managingPartners: [{
          cpf: Number(removeSpecialCharacters(values.socioAdmCpf)),
          name: values.socioAdmNome,
          email: values.socioAdmEmail,
          address: {
            zipCode: values.socioAdmCep,
            street: values.socioAdmEndereco,
            number: values.socioAdmNumero,
            complement: values.socioAdmComplemento,
            neighborhood: values.socioAdmBairro, 
            addressType: {
              id: 0
            },           
            city: {
              id: Number(values.socioAdmCidadeId)
            },
            state: {
              id: Number(values.socioAdmEstado)
            }
          },
          maritalStatus: {
            id: Number(values.socioAdmEstadoCivil)
          },
          spouse: {
            fullName: values.socioAdmConjugeNome,
            cpf: values.socioAdmConjugeCpf,
            ...(conjugeFullRequired ? {comercialPhone: values.socioAdmConjugeComercialTelefone} : { }),
            ...(conjugeFullRequired ? {phoneExtension: Number(values.socioAdmConjugeComercialTelefoneRamal)} : { }),
            ...(conjugeFullRequired ? {address: {
              zipCode: values.socioAdmConjugeCep? values.socioAdmConjugeCep : empty,
              street: values.socioAdmConjugeEndereco? values.socioAdmConjugeEndereco : empty,
              number: values.socioAdmConjugeNumero? values.socioAdmConjugeNumero : empty,
              complement: values.socioAdmConjugeComplemento? values.socioAdmConjugeComplemento : empty,
              neighborhood: values.socioAdmConjugeBairro? values.socioAdmConjugeBairro : empty,
              addressType: {
                id: 0
              },
              city: {
                id: Number(values.socioAdmConjugeCidadeId),
              },
              state: {
                id: Number(values.socioAdmConjugeEstado),
              }
            }} : { }),
            ...(conjugeFullRequired ? {financialInformation: {
              monthlyIncome: Number(formatCurrencyToServer(values.socioAdmConjugeRendaMensal)),
            }} : { }),
            ...(conjugeFullRequired ? {occupationInformation: {
              professionNature: {
                id: Number(values.socioAdmConjugeOcupacao)
              },
              profession: {
                id: Number(values.socioAdmConjugeProfissao)
              },
              occupationRole: {
                id: Number(values.socioAdmConjugeCargo)
              },
              companyInformation: {
                cnpj: values.socioAdmConjugeComercialCnpj,
                companyName: values.socioAdmConjugeComercialNome,
                admissionDate: values.socioAdmConjugeComercialAdmissao
              }
            }} : { }),
          },
          homePhone: values.socioAdmTelefone
        }],
        guarantors: [],
        address: {
          zipCode: values.cep,
          street: values.endereco,
          number: values.numero,
          complement: values.complemento,
          neighborhood: values.bairro,
          addressType: {
            id: 0
          },
          city: {
            id: Number(values.cidadeId)
          },
          state: {
            id: Number(values.estado)
          }
        },
      }      
    }

    return dados
}

export const dataOneGuarantor = (values: any, conjuge1FullRequired: boolean, conjuge2FullRequired: boolean) => {
    const dados: TProjetoBodyPJ = {
        estimatedPowerOfProject: formatCurrencyToServer(values.potenciaProjeto),
        estimatedValueOfProject: formatCurrencyToServer(values.valorProjeto),
        projectName: values.nomeProjeto,
        projectPurpose: {
          id: Number(values.finalidadeProjeto)
        },
        typeFinancing: {
          id: Number(values.tipoFinanciamento)
        },
        state: {
          id: Number(values.estadoProjeto)
        },
        city: {
          id: Number(values.cidadeProjetoId)
        },
        integrator: {
          id: Number(values.integradorId)
        },
        clientPJ: {
          corporateName: values.razaoSocial,
          fantasyName: values.nomeFantasia,
          cnpj: Number(removeSpecialCharacters(values.cnpj)),
          constitutionDate: values.dataConstituicao,
          annualBillingAverageValue: Number(formatCurrencyToServer(values.valorFaturamentoAnualMedio)),
          nameEconomicGroup: values.nomeGrupoEconomico,
          nationality: {
            id: Number(values.nacionalidadeId),
          },
          netWorth: Number(formatCurrencyToServer(values.patrimonioLiquido)),
          commercialPhone: values.telefoneComercial,
          cellPhone: values.celularComercial,
          companySize: {
            idPorteEmpresa: Number(values.porte)
          },
          economicActivity: {
            id: Number(values.atividadeEconomicaId)
          },
          activityBranch: {
            idRamoAtividade: Number(values.ramo)
          },
          managingPartners: [{
            cpf: Number(removeSpecialCharacters(values.socioAdmCpf)),
            name: values.socioAdmNome,
            email: values.socioAdmEmail,
            address: {
              zipCode: values.socioAdmCep,
              street: values.socioAdmEndereco,
              number: values.socioAdmNumero,
              complement: values.socioAdmComplemento,
              neighborhood: values.socioAdmBairro, 
              addressType: {
                id: 0
              },           
              city: {
                id: Number(values.socioAdmCidadeId)
              },
              state: {
                id: Number(values.socioAdmEstado)
              }
            },
            maritalStatus: {
              id: Number(values.socioAdmEstadoCivil)
            },
            spouse: {
              fullName: values.socioAdmConjugeNome,
              cpf: values.socioAdmConjugeCpf,
              ...(conjuge1FullRequired ? {comercialPhone: values.socioAdmConjugeComercialTelefone} : { }),
              ...(conjuge1FullRequired ? {phoneExtension: Number(values.socioAdmConjugeComercialTelefoneRamal)} : { }),
              ...(conjuge1FullRequired ? {address: {
                zipCode: values.socioAdmConjugeCep,
                street: values.socioAdmConjugeEndereco,
                number: values.socioAdmConjugeNumero,
                complement: values.socioAdmConjugeComplemento,
                neighborhood: values.socioAdmConjugeBairro,
                addressType: {
                  id: 0
                },
                city: {
                  id: Number(values.socioAdmConjugeCidadeId),
                },
                state: {
                  id: Number(values.socioAdmConjugeEstado),
                }
              }} : { }),
              ...(conjuge1FullRequired ? {financialInformation: {
                monthlyIncome: Number(formatCurrencyToServer(values.socioAdmConjugeRendaMensal))
              }} : { }),
              ...(conjuge1FullRequired ? {occupationInformation: {
                professionNature: {
                  id: Number(values.socioAdmConjugeOcupacao)
                },
                profession: {
                  id: Number(values.socioAdmConjugeProfissao)
                },
                occupationRole: {
                  id: Number(values.socioAdmConjugeCargo)
                },
                companyInformation: {
                  cnpj: values.socioAdmConjugeComercialCnpj,
                  companyName: values.socioAdmConjugeComercialNome,
                  admissionDate: values.socioAdmConjugeComercialAdmissao
                }
              }} : { }),
            },
            homePhone: values.socioAdmTelefone
          }],
          guarantors: [
            {
              cpf: Number(removeSpecialCharacters(values.avalistaCpf)),
              name: values.avalistaNome,
              email: values.avalistaEmail,
              address: {
                zipCode: values.avalistaCep,
                street: values.avalistaEndereco,
                number: values.avalistaNumero,
                complement: values.avalistaComplemento,
                neighborhood: values.avalistaBairro,
                addressType: {
                  id: 0
                },
                city: {
                  id: Number(values.avalistaCidadeId)
                },
                state: {
                  id: Number(values.avalistaEstado)
                }
              },
              maritalStatus: {
                id: Number(values.avalistaEstadoCivil)
              },
              spouse: {
                fullName: values.avalistaConjugeNome,
                cpf: values.avalistaConjugeCpf,
                ...(conjuge2FullRequired ? {comercialPhone: values.avalistaConjugeComercialTelefone} : { }),
                ...(conjuge2FullRequired ? {phoneExtension: Number(values.avalistaConjugeComercialTelefoneRamal)} : { }),
                ...(conjuge2FullRequired ? {address: {
                  zipCode: values.avalistaConjugeCep,
                  street: values.avalistaConjugeEndereco,
                  number: values.avalistaConjugeNumero,
                  complement: values.avalistaConjugeComplemento,
                  neighborhood: values.avalistaConjugeBairro,
                  addressType: {
                    id: 0
                  },
                  city: {
                    id: Number(values.avalistaConjugeCidadeId),
                  },
                  state: {
                    id: Number(values.avalistaConjugeEstado),
                  }
                }} : { }),
                ...(conjuge2FullRequired ? {financialInformation: {
                  monthlyIncome: Number(formatCurrencyToServer(values.avalistaConjugeRendaMensal))
                }} : { }),
                ...(conjuge2FullRequired ? {occupationInformation: {
                  professionNature: {
                    id: Number(values.avalistaConjugeOcupacao)
                  },
                  profession: {
                    id: Number(values.avalistaConjugeProfissao)
                  },
                  occupationRole: {
                    id: Number(values.avalistaConjugeCargo)
                  },
                  companyInformation: {
                    cnpj: values.avalistaConjugeComercialCnpj,
                    companyName: values.avalistaConjugeComercialNome,
                    admissionDate: values.avalistaConjugeComercialAdmissao
                  }
                }} : { }),
              },
              homePhone: values.avalistaTelefone
          }
        ],
          address: {
            zipCode: values.cep,
            street: values.endereco,
            number: values.numero,
            complement: values.complemento,
            neighborhood: values.bairro,
            addressType: {
              id: 0
            },
            city: {
              id: Number(values.cidadeId)
            },
            state: {
              id: Number(values.estado)
            }
          },
        }      
      }

      return dados
}

export const dataTwoGuarantor = (values: any, conjuge1FullRequired: boolean, conjuge2FullRequired: boolean, conjuge3FullRequired: boolean) => {
    const dados: TProjetoBodyPJ = {
        estimatedPowerOfProject: formatCurrencyToServer(values.potenciaProjeto),
        estimatedValueOfProject: formatCurrencyToServer(values.valorProjeto),
        projectName: values.nomeProjeto,
        projectPurpose: {
          id: Number(values.finalidadeProjeto)
        },
        typeFinancing: {
          id: Number(values.tipoFinanciamento)
        },
        state: {
          id: Number(values.estadoProjeto)
        },
        city: {
          id: Number(values.cidadeProjetoId)
        },
        integrator: {
          id: Number(values.integradorId)
        },
        clientPJ: {
          corporateName: values.razaoSocial,
          fantasyName: values.nomeFantasia,
          cnpj: Number(removeSpecialCharacters(values.cnpj)),
          constitutionDate: values.dataConstituicao,
          annualBillingAverageValue: Number(formatCurrencyToServer(values.valorFaturamentoAnualMedio)),
          nameEconomicGroup: values.nomeGrupoEconomico,
          nationality: {
            id: Number(values.nacionalidadeId),
          },
          netWorth: Number(formatCurrencyToServer(values.patrimonioLiquido)),
          commercialPhone: values.telefoneComercial,
          cellPhone: values.celularComercial,
          companySize: {
            idPorteEmpresa: Number(values.porte)
          },
          economicActivity: {
            id: Number(values.atividadeEconomicaId)
          },
          activityBranch: {
            idRamoAtividade: Number(values.ramo)
          },
          managingPartners: [{
            cpf: Number(removeSpecialCharacters(values.socioAdmCpf)),
            name: values.socioAdmNome,
            email: values.socioAdmEmail,
            address: {
              zipCode: values.socioAdmCep,
              street: values.socioAdmEndereco,
              number: values.socioAdmNumero,
              complement: values.socioAdmComplemento,
              neighborhood: values.socioAdmBairro, 
              addressType: {
                id: 0
              },           
              city: {
                id: Number(values.socioAdmCidadeId)
              },
              state: {
                id: Number(values.socioAdmEstado)
              }
            },
            maritalStatus: {
              id: Number(values.socioAdmEstadoCivil)
            },
            spouse: {
              fullName: values.socioAdmConjugeNome,
              cpf: values.socioAdmConjugeCpf,
              ...(conjuge1FullRequired ? {comercialPhone: values.socioAdmConjugeComercialTelefone} : { }),
              ...(conjuge1FullRequired ? {phoneExtension: Number(values.socioAdmConjugeComercialTelefoneRamal)} : { }),
              ...(conjuge1FullRequired ? {address: {
                zipCode: values.socioAdmConjugeCep,
                street: values.socioAdmConjugeEndereco,
                number: values.socioAdmConjugeNumero,
                complement: values.socioAdmConjugeComplemento,
                neighborhood: values.socioAdmConjugeBairro,
                addressType: {
                  id: 0
                },
                city: {
                  id: Number(values.socioAdmConjugeCidadeId),
                },
                state: {
                  id: Number(values.socioAdmConjugeEstado),
                }
              }} : { }),
              ...(conjuge1FullRequired ? {financialInformation: {
                monthlyIncome: Number(formatCurrencyToServer(values.socioAdmConjugeRendaMensal))
              }} : { }),
              ...(conjuge1FullRequired ? {occupationInformation: {
                professionNature: {
                  id: Number(values.socioAdmConjugeOcupacao)
                },
                profession: {
                  id: Number(values.socioAdmConjugeProfissao)
                },
                occupationRole: {
                  id: Number(values.socioAdmConjugeCargo)
                },
                companyInformation: {
                  cnpj: values.socioAdmConjugeComercialCnpj,
                  companyName: values.socioAdmConjugeComercialNome,
                  admissionDate: values.socioAdmConjugeComercialAdmissao
                }
              }} : { }),
            },
            homePhone: values.socioAdmTelefone
          }],
          guarantors: [
            {
              cpf: Number(removeSpecialCharacters(values.avalistaCpf)),
              name: values.avalistaNome,
              email: values.avalistaEmail,
              address: {
                zipCode: values.avalistaCep,
                street: values.avalistaEndereco,
                number: values.avalistaNumero,
                complement: values.avalistaComplemento,
                neighborhood: values.avalistaBairro,
                addressType: {
                  id: 0
                },
                city: {
                  id: Number(values.avalistaCidadeId)
                },
                state: {
                  id: Number(values.avalistaEstado)
                }
              },
              maritalStatus: {
                id: Number(values.avalistaEstadoCivil)
              },
              spouse: {
                fullName: values.avalistaConjugeNome,
                cpf: values.avalistaConjugeCpf,
                ...(conjuge2FullRequired ? {comercialPhone: values.avalistaConjugeComercialTelefone} : { }),
                ...(conjuge2FullRequired ? {phoneExtension: Number(values.avalistaConjugeComercialTelefoneRamal)} : { }),
                ...(conjuge2FullRequired ? {address: {
                  zipCode: values.avalistaConjugeCep,
                  street: values.avalistaConjugeEndereco,
                  number: values.avalistaConjugeNumero,
                  complement: values.avalistaConjugeComplemento,
                  neighborhood: values.avalistaConjugeBairro,
                  addressType: {
                    id: 0
                  },
                  city: {
                    id: Number(values.avalistaConjugeCidadeId),
                  },
                  state: {
                    id: Number(values.avalistaConjugeEstado),
                  }
                }} : { }),
                ...(conjuge2FullRequired ? {financialInformation: {
                  monthlyIncome: Number(formatCurrencyToServer(values.avalistaConjugeRendaMensal))
                }} : { }),
                ...(conjuge2FullRequired ? {occupationInformation: {
                  professionNature: {
                    id: Number(values.avalistaConjugeOcupacao)
                  },
                  profession: {
                    id: Number(values.avalistaConjugeProfissao)
                  },
                  occupationRole: {
                    id: Number(values.avalistaConjugeCargo)
                  },
                  companyInformation: {
                    cnpj: values.avalistaConjugeComercialCnpj,
                    companyName: values.avalistaConjugeComercialNome,
                    admissionDate: values.avalistaConjugeComercialAdmissao
                  }
                }} : { }),
              },
              homePhone: values.avalistaTelefone
          },
          {
            cpf: Number(removeSpecialCharacters(values.avalista2Cpf)),
            name: values.avalista2Nome,
            email: values.avalista2Email,
            address: {
            zipCode: values.avalista2Cep,
            street: values.avalista2Endereco,
            number: values.avalista2Numero,
            complement: values.avalista2Complemento,
            neighborhood: values.avalista2Bairro,
            addressType: {
                id: 0
            },
            city: {
                id: Number(values.avalista2CidadeId)
            },
            state: {
                id: Number(values.avalista2Estado)
            }
            },
            maritalStatus: {
            id: Number(values.avalista2EstadoCivil)
            },
            spouse: {
              fullName: values.avalista2ConjugeNome,
              cpf: values.avalista2ConjugeCpf,
              ...(conjuge3FullRequired ? {comercialPhone: values.avalista2ConjugeComercialTelefone} : { }),
              ...(conjuge3FullRequired ? {phoneExtension: Number(values.avalista2ConjugeComercialTelefoneRamal)} : { }),
              ...(conjuge3FullRequired ? {address: {
                  zipCode: values.avalista2ConjugeCep,
                  street: values.avalista2ConjugeEndereco,
                  number: values.avalista2ConjugeNumero,
                  complement: values.avalista2ConjugeComplemento,
                  neighborhood: values.avalista2ConjugeBairro,
                  addressType: {
                  id: 0
                  },
                  city: {
                  id: Number(values.avalista2ConjugeCidadeId),
                  },
                  state: {
                  id: Number(values.avalista2ConjugeEstado),
                  }
              }} : { }),
              ...(conjuge3FullRequired ? {financialInformation: {
                  monthlyIncome: Number(formatCurrencyToServer(values.avalista2ConjugeRendaMensal))
              }} : { }),
              ...(conjuge3FullRequired ? {occupationInformation: {
                  professionNature: {
                  id: Number(values.avalista2ConjugeOcupacao)
                  },
                  profession: {
                  id: Number(values.avalista2ConjugeProfissao)
                  },
                  occupationRole: {
                  id: Number(values.avalista2ConjugeCargo)
                  },
                  companyInformation: {
                  cnpj: values.avalista2ConjugeComercialCnpj,
                  companyName: values.avalista2ConjugeComercialNome,
                  admissionDate: values.avalista2ConjugeComercialAdmissao
                  }
              }} : { }),
            },
            homePhone: values.avalista2Telefone
        }
        ],
          address: {
            zipCode: values.cep,
            street: values.endereco,
            number: values.numero,
            complement: values.complemento,
            neighborhood: values.bairro,
            addressType: {
              id: 0
            },
            city: {
              id: Number(values.cidadeId)
            },
            state: {
              id: Number(values.estado)
            }
          },
        }      
      }

      return dados
}

export const populateForms = (projeto: any) => {
    const nullSafeString = (value?: string | number) => (value ? String(value) : '')

    const { clientpj } = projeto
    const {
        address,
        nationality,
        managingPartners,
        guarantors,
    } = clientpj

    let data: TProjetoFormPJ

    if (guarantors.length > 1){
        data = {
            tipoFinanciamento: nullSafeString(projeto.typeFinancing.id),
            finalidadeProjeto: nullSafeString(projeto.projectPurpose.id),
            nomeProjeto: nullSafeString(projeto.projectName),
            potenciaProjeto: nullSafeString(projeto.estimatedPowerOfProject),
            valorProjeto: nullSafeString(projeto.estimatedValueOfProject),
            estadoProjeto: nullSafeString(projeto.state.id),
            cidadeProjeto: nullSafeString(projeto.city.description),
            cidadeProjetoId: nullSafeString(projeto.city.id),
            integrador: projeto.integrator
                ? nullSafeString(`${projeto.integrator?.companyName} - ${projeto.integrator?.cnpj}`)
                : '',
            integradorId: nullSafeString(projeto.integrator?.id),
    
            razaoSocial: nullSafeString(clientpj.corporateName),
            nomeFantasia: nullSafeString(clientpj.fantasyName),
            cnpj: nullSafeString(clientpj.cnpj),
            ramo: nullSafeString(clientpj.activityBranch.idRamoAtividade),
            dataConstituicao: nullSafeString(clientpj.constitutionDate),
            valorFaturamentoAnualMedio: nullSafeString(clientpj.annualBillingAverageValue),
            nomeGrupoEconomico: nullSafeString(clientpj.nameEconomicGroup),
            porte: nullSafeString(clientpj.companySize.idPorteEmpresa),
            atividadeEconomica: nullSafeString(clientpj.economicActivity.descricao),
            atividadeEconomicaId: nullSafeString(clientpj.economicActivity.id),
            nacionalidade: nullSafeString(nationality.description),
            nacionalidadeId: nullSafeString(nationality.id),
            patrimonioLiquido: nullSafeString(clientpj.netWorth),
            cep: nullSafeString(address.zipCode),
            endereco: nullSafeString(address.street),
            numero: nullSafeString(address.number),
            bairro: nullSafeString(address.neighborhood),
            complemento: nullSafeString(address.complement),
            cidade: nullSafeString(address.city.description),
            cidadeId: nullSafeString(address.city.id),
            estado: nullSafeString(address.state.id),
    
            telefoneComercial: nullSafeString(clientpj.commercialPhone),
            ramalComercial: nullSafeString(clientpj.phoneExtension),
            celularComercial: nullSafeString(clientpj.cellPhone),
    
            socioAdmNome: nullSafeString(managingPartners[0].name),
            socioAdmCpf: nullSafeString(managingPartners[0].cpf),
            socioAdmEmail: nullSafeString(managingPartners[0].email),
            socioAdmTelefone: nullSafeString(managingPartners[0].homePhone),
            socioAdmEstadoCivil: nullSafeString(managingPartners[0].maritalStatus.id),
            socioAdmCep: nullSafeString(managingPartners[0].address.zipCode),
            socioAdmEndereco: nullSafeString(managingPartners[0].address.street),
            socioAdmNumero: nullSafeString(managingPartners[0].address.number),
            socioAdmBairro: nullSafeString(managingPartners[0].address.neighborhood),
            socioAdmEstado: nullSafeString(managingPartners[0].address.state.id),
            socioAdmCidade: nullSafeString(managingPartners[0].address.city.description),
            socioAdmCidadeId: nullSafeString(managingPartners[0].address.city.id),
            socioAdmComplemento: nullSafeString(managingPartners[0].address.complement),
    
            socioAdmConjugeNome: nullSafeString(managingPartners[0].spouse?.fullName),
            socioAdmConjugeCpf: nullSafeString(managingPartners[0].spouse?.cpf),
            socioAdmConjugeOcupacao: nullSafeString(managingPartners[0].spouse?.occupationInformation?.professionNature?.id),
            socioAdmConjugeProfissao: nullSafeString(managingPartners[0].spouse?.occupationInformation?.profession?.id),
            socioAdmConjugeCargo: nullSafeString(managingPartners[0].spouse?.occupationInformation?.occupationRole?.id),
            socioAdmConjugeComercialNome: nullSafeString(managingPartners[0].spouse?.occupationInformation?.companyInformation?.companyName),
            socioAdmConjugeComercialCnpj: nullSafeString(managingPartners[0].spouse?.occupationInformation?.companyInformation?.cnpj),
            socioAdmConjugeRendaMensal: nullSafeString(managingPartners[0].spouse?.financialInformation?.monthlyIncome),
            socioAdmConjugeComercialAdmissao: nullSafeString(managingPartners[0].spouse?.occupationInformation?.companyInformation?.admissionDate.substring(0, managingPartners[0].spouse?.occupationInformation?.companyInformation?.admissionDate.indexOf('T'))),
            socioAdmEnderecamentoConjugePessoal: nullSafeString(),
            socioAdmConjugeCep: nullSafeString(managingPartners[0].spouse?.address?.zipCode),
            socioAdmConjugeEndereco: nullSafeString(managingPartners[0].spouse?.address?.street),
            socioAdmConjugeNumero: nullSafeString(managingPartners[0].spouse?.address?.number),
            socioAdmConjugeBairro: nullSafeString(managingPartners[0].spouse?.address?.neighborhood),
            socioAdmConjugeEstado: nullSafeString(managingPartners[0].spouse?.address?.state?.id),
            socioAdmConjugeCidade: nullSafeString(managingPartners[0].spouse?.address?.city?.description),
            socioAdmConjugeCidadeId: nullSafeString(managingPartners[0].spouse?.address?.city?.id),
            socioAdmConjugeComplemento: nullSafeString(managingPartners[0].spouse?.address?.complement),
            socioAdmConjugeComercialTelefone: nullSafeString(managingPartners[0].spouse?.comercialPhone),
            socioAdmConjugeComercialTelefoneRamal: nullSafeString(managingPartners[0].spouse?.phoneExtension),
    
            avalistaNome: nullSafeString(guarantors[1].name),
            avalistaCpf: nullSafeString(guarantors[1].cpf),
            avalistaEmail: nullSafeString(guarantors[1].email),
            avalistaTelefone: nullSafeString(guarantors[1].homePhone),
            avalistaEstadoCivil: nullSafeString(guarantors[1].maritalStatus.id),
            avalistaCep: nullSafeString(guarantors[1].address.zipCode),
            avalistaEndereco: nullSafeString(guarantors[1].address.street),
            avalistaNumero: nullSafeString(guarantors[1].address.number),
            avalistaBairro: nullSafeString(guarantors[1].address.neighborhood),
            avalistaEstado: nullSafeString(guarantors[1].address.state.id),
            avalistaCidade: nullSafeString(guarantors[1].address.city.description),
            avalistaCidadeId: nullSafeString(guarantors[1].address.city.id),
            avalistaComplemento: nullSafeString(guarantors[1].address.complement),
            avalistaComercialTelefone: nullSafeString(),
            avalistaComercialTelefoneRamal: nullSafeString(),
            avalistaComercialCelular: nullSafeString(),
            avalistaRendaMensal: nullSafeString(),
            avalistaPatrimonio: nullSafeString(),
    
            avalistaConjugeNome: nullSafeString(guarantors[1].spouse?.fullName),
            avalistaConjugeCpf: nullSafeString(guarantors[1].spouse?.cpf),
            avalistaConjugeOcupacao: nullSafeString(guarantors[1].spouse?.occupationInformation?.professionNature?.id),
            avalistaConjugeProfissao: nullSafeString(guarantors[1].spouse?.occupationInformation?.profession?.id),
            avalistaConjugeCargo: nullSafeString(guarantors[1].spouse?.occupationInformation?.occupationRole?.id),
            avalistaConjugeComercialNome: nullSafeString(guarantors[1].spouse?.occupationInformation?.companyInformation?.companyName),
            avalistaConjugeComercialCnpj: nullSafeString(guarantors[1].spouse?.occupationInformation?.companyInformation?.cnpj),
            avalistaConjugeRendaMensal: nullSafeString(guarantors[1].spouse?.financialInformation?.monthlyIncome),
            avalistaConjugeComercialAdmissao: nullSafeString(guarantors[1].spouse?.occupationInformation?.companyInformation?.admissionDate.substring(0, guarantors[1].spouse?.occupationInformation?.companyInformation?.admissionDate.indexOf('T'))),
            avalistaConjugeCep: nullSafeString(guarantors[1].spouse?.address?.zipCode),
            avalistaConjugeEndereco: nullSafeString(guarantors[1].spouse?.address?.street),
            avalistaConjugeNumero: nullSafeString(guarantors[1].spouse?.address?.number),
            avalistaConjugeBairro: nullSafeString(guarantors[1].spouse?.address?.neighborhood),
            avalistaConjugeEstado: nullSafeString(guarantors[1].spouse?.address?.state.id),
            avalistaConjugeCidade: nullSafeString(guarantors[1].spouse?.address?.city?.description),
            avalistaConjugeCidadeId: nullSafeString(guarantors[1].spouse?.address?.city?.id),
            avalistaConjugeComplemento: nullSafeString(guarantors[1].spouse?.address?.complement),
            avalistaConjugeComercialTelefone: nullSafeString(guarantors[1].spouse?.comercialPhone),
            avalistaConjugeComercialTelefoneRamal: nullSafeString(guarantors[1].spouse?.phoneExtension),
    
            avalista2Nome: nullSafeString(guarantors[0].name),
            avalista2Cpf: nullSafeString(guarantors[0].cpf),
            avalista2Email: nullSafeString(guarantors[0].email),
            avalista2Telefone: nullSafeString(guarantors[0].homePhone),
            avalista2EstadoCivil: nullSafeString(guarantors[0].maritalStatus?.id),
            avalista2Cep: nullSafeString(guarantors[0].address?.zipCode),
            avalista2Endereco: nullSafeString(guarantors[0].address?.street),
            avalista2Numero: nullSafeString(guarantors[0].address?.number),
            avalista2Bairro: nullSafeString(guarantors[0].address?.neighborhood),
            avalista2Estado: nullSafeString(guarantors[0].address?.state.id),
            avalista2Cidade: nullSafeString(guarantors[0].address?.city.description),
            avalista2CidadeId: nullSafeString(guarantors[0].address?.city.id),
            avalista2Complemento: nullSafeString(guarantors[0].address?.complement),
    
            avalista2ConjugeNome: nullSafeString(guarantors[0].spouse?.fullName),
            avalista2ConjugeCpf: nullSafeString(guarantors[0].spouse?.cpf),
            avalista2ConjugeOcupacao: nullSafeString(guarantors[0].spouse?.occupationInformation?.professionNature?.id),
            avalista2ConjugeProfissao: nullSafeString(guarantors[0].spouse?.occupationInformation?.profession?.id),
            avalista2ConjugeCargo: nullSafeString(guarantors[0].spouse?.occupationInformation?.occupationRole?.id),
            avalista2ConjugeComercialNome: nullSafeString(guarantors[0].spouse?.occupationInformation?.companyInformation?.companyName),
            avalista2ConjugeComercialCnpj: nullSafeString(guarantors[0].spouse?.occupationInformation?.companyInformation?.cnpj),
            avalista2ConjugeRendaMensal: nullSafeString(guarantors[0].spouse?.financialInformation?.monthlyIncome),
            avalista2ConjugeComercialAdmissao: nullSafeString(guarantors[0].spouse?.occupationInformation?.companyInformation?.admissionDate.substring(0, guarantors[0].spouse?.occupationInformation?.companyInformation?.admissionDate.indexOf('T'))),
            avalista2ConjugeCep: nullSafeString(guarantors[0].spouse?.address?.zipCode),
            avalista2ConjugeEndereco: nullSafeString(guarantors[0].spouse?.address?.street),
            avalista2ConjugeNumero: nullSafeString(guarantors[0].spouse?.address?.number),
            avalista2ConjugeBairro: nullSafeString(guarantors[0].spouse?.address?.neighborhood),
            avalista2ConjugeEstado: nullSafeString(guarantors[0].spouse?.address?.state?.id),
            avalista2ConjugeCidade: nullSafeString(guarantors[0].spouse?.address?.city?.description),
            avalista2ConjugeCidadeId: nullSafeString(guarantors[0].spouse?.address?.city?.id),
            avalista2ConjugeComplemento: nullSafeString(guarantors[0].spouse?.address?.complement),
            avalista2ConjugeComercialTelefone: nullSafeString(guarantors[0].spouse?.comercialPhone),
            avalista2ConjugeComercialTelefoneRamal: nullSafeString(guarantors[0].spouse?.phoneExtension),
        }
    } else if (guarantors.length > 0) {
        data = {
            tipoFinanciamento: nullSafeString(projeto.typeFinancing.id),
            finalidadeProjeto: nullSafeString(projeto.projectPurpose.id),
            nomeProjeto: nullSafeString(projeto.projectName),
            potenciaProjeto: nullSafeString(projeto.estimatedPowerOfProject),
            valorProjeto: nullSafeString(projeto.estimatedValueOfProject),
            estadoProjeto: nullSafeString(projeto.state.id),
            cidadeProjeto: nullSafeString(projeto.city.description),
            cidadeProjetoId: nullSafeString(projeto.city.id),
            integrador: projeto.integrator
                ? nullSafeString(`${projeto.integrator?.companyName} - ${projeto.integrator?.cnpj}`)
                : '',
            integradorId: nullSafeString(projeto.integrator?.id),
    
            razaoSocial: nullSafeString(clientpj.corporateName),
            nomeFantasia: nullSafeString(clientpj.fantasyName),
            cnpj: nullSafeString(clientpj.cnpj),
            ramo: nullSafeString(clientpj.activityBranch.idRamoAtividade),
            dataConstituicao: nullSafeString(clientpj.constitutionDate),
            valorFaturamentoAnualMedio: nullSafeString(clientpj.annualBillingAverageValue),
            nomeGrupoEconomico: nullSafeString(clientpj.nameEconomicGroup),
            porte: nullSafeString(clientpj.companySize.idPorteEmpresa),
            atividadeEconomica: nullSafeString(clientpj.economicActivity.descricao),
            atividadeEconomicaId: nullSafeString(clientpj.economicActivity.id),
            nacionalidade: nullSafeString(nationality.description),
            nacionalidadeId: nullSafeString(nationality.id),
            patrimonioLiquido: nullSafeString(clientpj.netWorth),
            cep: nullSafeString(address.zipCode),
            endereco: nullSafeString(address.street),
            numero: nullSafeString(address.number),
            bairro: nullSafeString(address.neighborhood),
            complemento: nullSafeString(address.complement),
            cidade: nullSafeString(address.city.description),
            cidadeId: nullSafeString(address.city.id),
            estado: nullSafeString(address.state.id),
    
            telefoneComercial: nullSafeString(clientpj.commercialPhone),
            ramalComercial: nullSafeString(clientpj.phoneExtension),
            celularComercial: nullSafeString(clientpj.cellPhone),
    
            socioAdmNome: nullSafeString(managingPartners[0].name),
            socioAdmCpf: nullSafeString(managingPartners[0].cpf),
            socioAdmEmail: nullSafeString(managingPartners[0].email),
            socioAdmTelefone: nullSafeString(managingPartners[0].homePhone),
            socioAdmEstadoCivil: nullSafeString(managingPartners[0].maritalStatus.id),
            socioAdmCep: nullSafeString(managingPartners[0].address.zipCode),
            socioAdmEndereco: nullSafeString(managingPartners[0].address.street),
            socioAdmNumero: nullSafeString(managingPartners[0].address.number),
            socioAdmBairro: nullSafeString(managingPartners[0].address.neighborhood),
            socioAdmEstado: nullSafeString(managingPartners[0].address.state.id),
            socioAdmCidade: nullSafeString(managingPartners[0].address.city.description),
            socioAdmCidadeId: nullSafeString(managingPartners[0].address.city.id),
            socioAdmComplemento: nullSafeString(managingPartners[0].address.complement),
    
            socioAdmConjugeNome: nullSafeString(managingPartners[0].spouse?.fullName),
            socioAdmConjugeCpf: nullSafeString(managingPartners[0].spouse?.cpf),
            socioAdmConjugeOcupacao: nullSafeString(managingPartners[0].spouse?.occupationInformation?.professionNature?.id),
            socioAdmConjugeProfissao: nullSafeString(managingPartners[0].spouse?.occupationInformation?.profession?.id),
            socioAdmConjugeCargo: nullSafeString(managingPartners[0].spouse?.occupationInformation?.occupationRole?.id),
            socioAdmConjugeComercialNome: nullSafeString(managingPartners[0].spouse?.occupationInformation?.companyInformation?.companyName),
            socioAdmConjugeComercialCnpj: nullSafeString(managingPartners[0].spouse?.occupationInformation?.companyInformation?.cnpj),
            socioAdmConjugeRendaMensal: nullSafeString(managingPartners[0].spouse?.financialInformation?.monthlyIncome),
            socioAdmConjugeComercialAdmissao: nullSafeString(managingPartners[0].spouse?.occupationInformation?.companyInformation?.admissionDate.substring(0, managingPartners[0].spouse?.occupationInformation?.companyInformation?.admissionDate.indexOf('T'))),
            socioAdmEnderecamentoConjugePessoal: nullSafeString(),
            socioAdmConjugeCep: nullSafeString(managingPartners[0].spouse?.address?.zipCode),
            socioAdmConjugeEndereco: nullSafeString(managingPartners[0].spouse?.address?.street),
            socioAdmConjugeNumero: nullSafeString(managingPartners[0].spouse?.address?.number),
            socioAdmConjugeBairro: nullSafeString(managingPartners[0].spouse?.address?.neighborhood),
            socioAdmConjugeEstado: nullSafeString(managingPartners[0].spouse?.address?.state?.id),
            socioAdmConjugeCidade: nullSafeString(managingPartners[0].spouse?.address?.city?.description),
            socioAdmConjugeCidadeId: nullSafeString(managingPartners[0].spouse?.address?.city?.id),
            socioAdmConjugeComplemento: nullSafeString(managingPartners[0].spouse?.address?.complement),
            socioAdmConjugeComercialTelefone: nullSafeString(managingPartners[0].spouse?.comercialPhone),
            socioAdmConjugeComercialTelefoneRamal: nullSafeString(managingPartners[0].spouse?.phoneExtension),
    
            avalistaNome: nullSafeString(guarantors[0].name),
            avalistaCpf: nullSafeString(guarantors[0].cpf),
            avalistaEmail: nullSafeString(guarantors[0].email),
            avalistaTelefone: nullSafeString(guarantors[0].homePhone),
            avalistaEstadoCivil: nullSafeString(guarantors[0].maritalStatus.id),
            avalistaCep: nullSafeString(guarantors[0].address.zipCode),
            avalistaEndereco: nullSafeString(guarantors[0].address.street),
            avalistaNumero: nullSafeString(guarantors[0].address.number),
            avalistaBairro: nullSafeString(guarantors[0].address.neighborhood),
            avalistaEstado: nullSafeString(guarantors[0].address.state.id),
            avalistaCidade: nullSafeString(guarantors[0].address.city.description),
            avalistaCidadeId: nullSafeString(guarantors[0].address.city.id),
            avalistaComplemento: nullSafeString(guarantors[0].address.complement),
            avalistaComercialTelefone: nullSafeString(),
            avalistaComercialTelefoneRamal: nullSafeString(),
            avalistaComercialCelular: nullSafeString(),
            avalistaRendaMensal: nullSafeString(),
            avalistaPatrimonio: nullSafeString(),
    
            avalistaConjugeNome: nullSafeString(guarantors[0].spouse?.fullName),
            avalistaConjugeCpf: nullSafeString(guarantors[0].spouse?.cpf),
            avalistaConjugeOcupacao: nullSafeString(guarantors[0].spouse?.occupationInformation?.professionNature?.id),
            avalistaConjugeProfissao: nullSafeString(guarantors[0].spouse?.occupationInformation?.profession?.id),
            avalistaConjugeCargo: nullSafeString(guarantors[0].spouse?.occupationInformation?.occupationRole?.id),
            avalistaConjugeComercialNome: nullSafeString(guarantors[0].spouse?.occupationInformation?.companyInformation?.companyName),
            avalistaConjugeComercialCnpj: nullSafeString(guarantors[0].spouse?.occupationInformation?.companyInformation?.cnpj),
            avalistaConjugeRendaMensal: nullSafeString(guarantors[0].spouse?.financialInformation?.monthlyIncome),
            avalistaConjugeComercialAdmissao: nullSafeString(guarantors[0].spouse?.occupationInformation?.companyInformation?.admissionDate.substring(0, guarantors[0].spouse?.occupationInformation?.companyInformation?.admissionDate.indexOf('T'))),
            avalistaConjugeCep: nullSafeString(guarantors[0].spouse?.address?.zipCode),
            avalistaConjugeEndereco: nullSafeString(guarantors[0].spouse?.address?.street),
            avalistaConjugeNumero: nullSafeString(guarantors[0].spouse?.address?.number),
            avalistaConjugeBairro: nullSafeString(guarantors[0].spouse?.address?.neighborhood),
            avalistaConjugeEstado: nullSafeString(guarantors[0].spouse?.address?.state?.id),
            avalistaConjugeCidade: nullSafeString(guarantors[0].spouse?.address?.city?.description),
            avalistaConjugeCidadeId: nullSafeString(guarantors[0].spouse?.address?.city?.id),
            avalistaConjugeComplemento: nullSafeString(guarantors[0].spouse?.address?.complement),
            avalistaConjugeComercialTelefone: nullSafeString(guarantors[0].spouse?.comercialPhone),
            avalistaConjugeComercialTelefoneRamal: nullSafeString(guarantors[0].spouse?.phoneExtension),
        }
    } else {
      data = {
        tipoFinanciamento: nullSafeString(projeto.typeFinancing.id),
        finalidadeProjeto: nullSafeString(projeto.projectPurpose.id),
        nomeProjeto: nullSafeString(projeto.projectName),
        potenciaProjeto: nullSafeString(projeto.estimatedPowerOfProject),
        valorProjeto: nullSafeString(projeto.estimatedValueOfProject),
        estadoProjeto: nullSafeString(projeto.state.id),
        cidadeProjeto: nullSafeString(projeto.city.description),
        cidadeProjetoId: nullSafeString(projeto.city.id),
        integrador: projeto.integrator
            ? nullSafeString(`${projeto.integrator?.companyName} - ${projeto.integrator?.cnpj}`)
            : '',
        integradorId: nullSafeString(projeto.integrator?.id),

        razaoSocial: nullSafeString(clientpj.corporateName),
        nomeFantasia: nullSafeString(clientpj.fantasyName),
        cnpj: nullSafeString(clientpj.cnpj),
        ramo: nullSafeString(clientpj.activityBranch.idRamoAtividade),
        dataConstituicao: nullSafeString(clientpj.constitutionDate),
        valorFaturamentoAnualMedio: nullSafeString(clientpj.annualBillingAverageValue),
        nomeGrupoEconomico: nullSafeString(clientpj.nameEconomicGroup),
        porte: nullSafeString(clientpj.companySize.idPorteEmpresa),
        atividadeEconomica: nullSafeString(clientpj.economicActivity.descricao),
        atividadeEconomicaId: nullSafeString(clientpj.economicActivity.id),
        nacionalidade: nullSafeString(nationality.description),
        nacionalidadeId: nullSafeString(nationality.id),
        patrimonioLiquido: nullSafeString(clientpj.netWorth),
        cep: nullSafeString(address.zipCode),
        endereco: nullSafeString(address.street),
        numero: nullSafeString(address.number),
        bairro: nullSafeString(address.neighborhood),
        complemento: nullSafeString(address.complement),
        cidade: nullSafeString(address.city.description),
        cidadeId: nullSafeString(address.city.id),
        estado: nullSafeString(address.state.id),

        telefoneComercial: nullSafeString(clientpj.commercialPhone),
        ramalComercial: nullSafeString(clientpj.phoneExtension),
        celularComercial: nullSafeString(clientpj.cellPhone),

        socioAdmNome: nullSafeString(managingPartners[0].name),
        socioAdmCpf: nullSafeString(managingPartners[0].cpf),
        socioAdmEmail: nullSafeString(managingPartners[0].email),
        socioAdmTelefone: nullSafeString(managingPartners[0].homePhone),
        socioAdmEstadoCivil: nullSafeString(managingPartners[0].maritalStatus.id),
        socioAdmCep: nullSafeString(managingPartners[0].address.zipCode),
        socioAdmEndereco: nullSafeString(managingPartners[0].address.street),
        socioAdmNumero: nullSafeString(managingPartners[0].address.number),
        socioAdmBairro: nullSafeString(managingPartners[0].address.neighborhood),
        socioAdmEstado: nullSafeString(managingPartners[0].address.state.id),
        socioAdmCidade: nullSafeString(managingPartners[0].address.city.description),
        socioAdmCidadeId: nullSafeString(managingPartners[0].address.city.id),
        socioAdmComplemento: nullSafeString(managingPartners[0].address.complement),

        socioAdmConjugeNome: nullSafeString(managingPartners[0].spouse?.fullName),
        socioAdmConjugeCpf: nullSafeString(managingPartners[0].spouse?.cpf),
        socioAdmConjugeOcupacao: nullSafeString(managingPartners[0].spouse?.occupationInformation?.professionNature?.id),
        socioAdmConjugeProfissao: nullSafeString(managingPartners[0].spouse?.occupationInformation?.profession?.id),
        socioAdmConjugeCargo: nullSafeString(managingPartners[0].spouse?.occupationInformation?.occupationRole?.id),
        socioAdmConjugeComercialNome: nullSafeString(managingPartners[0].spouse?.occupationInformation?.companyInformation?.companyName),
        socioAdmConjugeComercialCnpj: nullSafeString(managingPartners[0].spouse?.occupationInformation?.companyInformation?.cnpj),
        socioAdmConjugeRendaMensal: nullSafeString(managingPartners[0].spouse?.financialInformation?.monthlyIncome),
        socioAdmConjugeComercialAdmissao: nullSafeString(managingPartners[0].spouse?.occupationInformation?.companyInformation?.admissionDate.substring(0, managingPartners[0].spouse?.occupationInformation?.companyInformation?.admissionDate.indexOf('T'))),
        socioAdmEnderecamentoConjugePessoal: nullSafeString(),
        socioAdmConjugeCep: nullSafeString(managingPartners[0].spouse?.address?.zipCode),
        socioAdmConjugeEndereco: nullSafeString(managingPartners[0].spouse?.address?.street),
        socioAdmConjugeNumero: nullSafeString(managingPartners[0].spouse?.address?.number),
        socioAdmConjugeBairro: nullSafeString(managingPartners[0].spouse?.address?.neighborhood),
        socioAdmConjugeEstado: nullSafeString(managingPartners[0].spouse?.address?.state?.id),
        socioAdmConjugeCidade: nullSafeString(managingPartners[0].spouse?.address?.city?.description),
        socioAdmConjugeCidadeId: nullSafeString(managingPartners[0].spouse?.address?.city?.id),
        socioAdmConjugeComplemento: nullSafeString(managingPartners[0].spouse?.address?.complement),
        socioAdmConjugeComercialTelefone: nullSafeString(managingPartners[0].spouse?.comercialPhone),
        socioAdmConjugeComercialTelefoneRamal: nullSafeString(managingPartners[0].spouse?.phoneExtension),

        avalistaNome: nullSafeString(),
        avalistaCpf: nullSafeString(),
        avalistaEmail: nullSafeString(),
        avalistaTelefone: nullSafeString(),
        avalistaEstadoCivil: nullSafeString(),
        avalistaCep: nullSafeString(),
        avalistaEndereco: nullSafeString(),
        avalistaNumero: nullSafeString(),
        avalistaBairro: nullSafeString(),
        avalistaEstado: nullSafeString(),
        avalistaCidade: nullSafeString(),
        avalistaCidadeId: nullSafeString(),
        avalistaComplemento: nullSafeString(),
        avalistaComercialTelefone: nullSafeString(),
        avalistaComercialTelefoneRamal: nullSafeString(),
        avalistaComercialCelular: nullSafeString(),
        avalistaRendaMensal: nullSafeString(),
        avalistaPatrimonio: nullSafeString(),

        avalistaConjugeNome: nullSafeString(),
        avalistaConjugeCpf: nullSafeString(),
        avalistaConjugeOcupacao: nullSafeString(),
        avalistaConjugeProfissao: nullSafeString(),
        avalistaConjugeCargo: nullSafeString(),
        avalistaConjugeComercialNome: nullSafeString(),
        avalistaConjugeComercialCnpj: nullSafeString(),
        avalistaConjugeRendaMensal: nullSafeString(),
        avalistaConjugeComercialAdmissao: nullSafeString(),
        avalistaConjugeCep: nullSafeString(),
        avalistaConjugeEndereco: nullSafeString(),
        avalistaConjugeNumero: nullSafeString(),
        avalistaConjugeBairro: nullSafeString(),
        avalistaConjugeEstado: nullSafeString(),
        avalistaConjugeCidade: nullSafeString(),
        avalistaConjugeCidadeId: nullSafeString(),
        avalistaConjugeComplemento: nullSafeString(),
        avalistaConjugeComercialTelefone: nullSafeString(),
        avalistaConjugeComercialTelefoneRamal: nullSafeString(),
      }
  }
    return data
}

import React from 'react'
import { FaBars } from 'react-icons/fa'

import { Container, MenuButton } from './styles'


type TProps = {
  openSidebar(): void
}

const Header = ({ openSidebar }: TProps) => {

  const onClickOpenSidebar = (e: any) => {
    openSidebar()
    return false
  }

  return (
    <Container>
      <div>
        <MenuButton onClick={onClickOpenSidebar}>
          <FaBars />
        </MenuButton>
      </div>
    </Container>
  )
}

export default Header

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'hooks/useAuth'
import { ApolloProvider } from 'react-apollo'
import apolloClient from 'services/apollo'
import { ToastProvider } from 'react-toast-notifications'
import { ThemeProvider } from 'styled-components'
import { theme } from 'styles'

type TProps = {
  children: React.ReactNode
}

const Providers = ({ children }: TProps) => (
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            {children}
          </ToastProvider>
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  </BrowserRouter>
)

export default Providers

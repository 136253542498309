import React, { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Col, Form } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import useParametros from 'repositories/useParametros'
import {
  FormLabel,
  TextField,
  ButtonsContainer,
  PrimaryButton
} from 'components'
import { useAuth } from 'hooks/useAuth'
import { Redirect } from 'react-router-dom'
import { Container } from '../styles'

const parametrosUpdatedSucessMessage = 'Parâmetros atualizados com sucesso'

type TParametrosForm = {
  id: string
  validadeIntegrador: string
  tentativasRoboCemig: string
  areaCoefficient: string
  performanceRate: string
  standardElectricityBillRate: string
}

const validationSchema = yup.object().shape({
  validadeIntegrador: yup.number()
    .required('O campo "Validade do integrador" é obrigatório')
    .moreThan(0, 'Insira uma quantidade de dias maior do que zero'),
  tentativasRoboCemig: yup.number()
    .required('O campo "Quantidade de tentativas do robô CEMIG" é obrigatório')
    .moreThan(0, 'Insira uma quantidade de tentativas maior do que zero')
    .max(50, 'Insira uma quantidade de tentativas menor do que 50'),
  areaCoefficient: yup.number().required('O campo "Coeficiente de Área" é obrigatório'),
  performanceRate: yup.number().required('O campo "Taxa de desempenho" é obrigatório'),
  standardElectricityBillRate: yup.number().required('O campo "Taxa padrão da conta de energia" é obrigatório')
})

const initialValues: TParametrosForm = {
  id: '',
  validadeIntegrador: '',
  tentativasRoboCemig: '',
  areaCoefficient: '',
  performanceRate: '',
  standardElectricityBillRate: ''
}

const ParametrosFinanciamento = () => {
  const repository = useParametros()
  const { userPermissions } = useAuth()
  const permissions = useMemo(() => userPermissions?.parametros, [userPermissions])
  const { addToast } = useToasts()

  useEffect(() => {
    const requestParametros = async () => {
      const triggerLoading = false
      const _parametros = await repository.getParametros(triggerLoading)
      if (!_parametros) return

      const formattedParams: TParametrosForm = {
        id: String(_parametros.id),
        validadeIntegrador: String(_parametros.validityOfIntegrators),
        tentativasRoboCemig: String(_parametros.robotAttemptsNumber),
        areaCoefficient: String(_parametros.areaCoefficient),
        performanceRate: String(_parametros.performanceRate),
        standardElectricityBillRate: String(_parametros.standardElectricityBillRate)
      }

      formik.setValues(formattedParams)
    }

    requestParametros()
  }, [])

  const onSubmit = async () => {
    const {
      id,
      validadeIntegrador,
      tentativasRoboCemig,
      areaCoefficient,
      performanceRate,
      standardElectricityBillRate
    } = formik.values

    const body: TParametrosBody = {
      id: Number(id),
      validityOfIntegrators: Number(validadeIntegrador),
      robotAttemptsNumber: Number(tentativasRoboCemig),
      areaCoefficient: Number(areaCoefficient),
      performanceRate: Number(performanceRate),
      standardElectricityBillRate: Number(standardElectricityBillRate)
    }

    const response = await repository.putParametros(body)
    if (!response) return

    addToast(
      parametrosUpdatedSucessMessage,
      { appearance: 'success', autoDismiss: true, }
    )
  }

  const formik = useFormik<TParametrosForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit
  })

  const hasError = (name: keyof TParametrosForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TParametrosForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <FormLabel
            underLined
            column
            required
            md={9}
          >
            Validade do Integrador
          </FormLabel>

          <Col md={3}>
            <TextField
              type='number'
              appendLabel='dias'
              disabled={!permissions?.edit}
              min={1}
              max={100}
              {...getCommonFieldProps('validadeIntegrador')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <FormLabel
            underLined
            column
            required
            md={9}
          >
            Quantidade de tentativas do robô CEMIG
          </FormLabel>

          <Col md={3}>
            <TextField
              type='number'
              appendLabel='tentativas'
              disabled={!permissions?.edit}
              min={1}
              max={100}
              {...getCommonFieldProps('tentativasRoboCemig')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <FormLabel
            underLined
            column
            required
            md={9}
          >
            Coeficiente da área
          </FormLabel>

          <Col md={3}>
            <TextField
              type='number'
              disabled={!permissions?.edit}
              min={0}
              {...getCommonFieldProps('areaCoefficient')}
              step={0.01}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <FormLabel
            underLined
            column
            required
            md={9}
          >
            Taxa de desempenho
          </FormLabel>

          <Col md={3}>
            <TextField
              type='number'
              disabled={!permissions?.edit}
              min={0}
              {...getCommonFieldProps('performanceRate')}
              step={0.01}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <FormLabel
            underLined
            column
            required
            md={9}
          >
            Taxa padrão da conta de energia
          </FormLabel>

          <Col md={3}>
            <TextField
              type='number'
              disabled={!permissions?.edit}
              min={0}
              {...getCommonFieldProps('standardElectricityBillRate')}
              step={0.01}
            />
          </Col>
        </Form.Row>

        {permissions?.edit && (
          <ButtonsContainer>
            <PrimaryButton type='submit' isLoading={repository.isLoading}>
              Salvar Alterações
            </PrimaryButton>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
}

export default ParametrosFinanciamento
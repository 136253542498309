import React from 'react'
import { Form } from 'react-bootstrap'
import { FormLabel } from 'components'

const SelectInput = props => {
  const {
    field,
    label,
    items,
    properties = {},
    emptyValue = 'Selecione uma opção',
    value,
    handleChange,
    error = {},
    description = '',
    ...rest
  } = props
  return (
    <Form.Group controlId={field}>
      <FormLabel required={rest.required}>{label}</FormLabel>
      <Form.Control
        value={value}
        onChange={handleChange}
        isInvalid={Boolean(error.isError)}
        as='select'
        custom
        {...rest}
      >
        <option value=''>{emptyValue}</option>
        {items?.map(item => (
          <option
            key={item[properties.labelId]}
            value={item[properties.labelId]}
          >
            {item[properties.labelName]}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type='invalid'>
        {error.message}
      </Form.Control.Feedback>
      {description && <Form.Text className='text-muted'>{description}</Form.Text>}
    </Form.Group>
  )
}

export default SelectInput
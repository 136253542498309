import { gql } from 'apollo-boost'
import { commonResponse, paginationInfo } from './constants'

export const QUERY_CLIENTES = gql`
  query findClientByFilter ($findClientCommand: FindClientByFilterCommand, $paginate: Paginate) {
    findClientsByFilter (
      findClientCommand: $findClientCommand
      paginate: $paginate
    ) {
      ${commonResponse}
      ${paginationInfo}
      clientSuccess {
        id
        typePerson
        fullName
        cpf
        cnpj
        companyName
        maritalStatus
        occupation
        telephone
        email
        clientAddress {
          id
          zipCode
          street
          number
          complement
          neighborhood
          addressType
          city
          state
          uf
        }
        representative {
          id
          name
          cpf
          telephone
          email
          representativeAddress{
            id
            zipCode
            street
            number
            complement
            neighborhood
            addressType
            city
            state
            uf
          }
        }
        contracts {
          id
          contractHash
          contractPipefyId
          sellerName
          unitConsumption
          averageConsumption
          averageCost
          finalCost
          discount
          readingDate
          category
          type
          subclass
          status
          attachments {
            id
            fileName
            createdDate
          }
        }
        roleStatus
        inPipefy
      }
    }
  }
`

export const MUTATION_CLIENTE = gql`
  mutation registerOrUpdateClient ($registerClientCommand: RegisterClientCommand) {
    registerOrUpdateClient (registerClientCommand: $registerClientCommand) {
      ${commonResponse}
    }
  }
`

export const QUERY_CLIENTE = gql`
  query findClientById ($id: Long!) {
    findClientById (id : $id) {
      commonResponse {isError, statusCode, description}
      clientSuccess {
        id
        typePerson
        fullName
        maritalStatus
        occupation
        cpf
        cnpj
        companyName
        telephone
        email
        clientAddress {
          id
          zipCode
          street
          number
          complement
          neighborhood
          addressType
          city
          state
          uf
        }
        representative {
          id
          name
          cpf
          telephone
          email
          representativeAddress{
            id
            zipCode
            street
            number
            complement
            neighborhood
            addressType
            city
            state
            uf
          }
        }
        contracts {
          id
          contractHash
          contractPipefyId
          sellerName
          unitConsumption
          averageConsumption
          averageCost
          finalCost
          discount
          readingDate
          category
          type
          subclass
          status
          attachments {
            id
            fileName
            createdDate
          }
        }
        roleStatus
        inPipefy
      }
    }
  }
`

export const QUERY_ENERGY_BILL = gql`
  query getEnergyBillLinkById ($id: Long!) {
    getEnergyBillLinkById(id: $id) {
      ${commonResponse}
      publicLinkEnergyBill
    }
  }
`

export const QUERY_VALIDATE_INVITATION_CODE = gql`
  query getStatusInvitationToken ($authToken: String!) {
    getStatusInvitationToken (authToken: $authToken) {
      ${commonResponse}
      success {
        name
        email
      }
    }
  }
`

export const MUTATION_REGISTER_CLIENTE_USER = gql`
  mutation registerNewUserFromClient (
    $registerUserFromClientCommand: RegisterUserFromClientCommandInput!
  ) {
    registerNewUserFromClient (
      registerUserFromClientCommand: $registerUserFromClientCommand
    ) {
      ${commonResponse}
    }
  }
`

export const QUERY_MEUS_DADOS = gql`
  query findMyDataRegister ($id: Long!) {
    findMyDataRegisterById (id : $id) {
      ${commonResponse}
      myDataRegister {
        id
        typePerson
        fullName
        cpf
        companyName
        cnpj
        telephone
        email
        kwh
        contactName
        tradeRepresentative
        commercialRepresentationCompany
        kwh
        clientAddress {
          zipCode
          street
          number
          complement
          neighborhood
          addressType
          city
          state
          uf
        }
      }
    }
  }
`

export const QUERY_MEUS_DADOS_USINAS_CONDOMINIOS = gql`
  query findMyDataRegister ($id: Long!) {
    findMyDataRegisterById (id : $id) {
      ${commonResponse}
      myDataRegister {
        clientPowerPlants {
          id
          contractedPercentage
          createdDate
          powerPlant {
            name
            condominium {
              name
            }
          }
        }
      }
    }
  }
`

export const MUTATION_DELETE_ANEXOS = gql`
  mutation deleteEnergyBillByListId ($listId: [Long!]) {
    deleteEnergyBillByListId (listId: $listId) {
      ${commonResponse}
    }
  }
`
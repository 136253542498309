import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom'
import { useFormik } from 'formik'
import { Form, Col, Row } from 'react-bootstrap'
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator'
import * as yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import {
  useCreateUpdateCliente,
  useGetCliente,
  useGetContaEnergia,
  useDeleteAnexos
} from 'repositories/clientes'
import { useGetEndereco } from 'repositories/general'
import {
  FormTitle,
  PageHeader,
  PrimaryButton,
  ButtonsContainer,
  FormDivider,
  RadioField,
  TextField,
  MaskedTextField,
  SelectField,
  GoBackButton,
  ConfirmButton
} from 'components'
import {
  tipoPessoaOptions,
  PessoaTipo,
  ActivationStatus,
  Variant,
  confirmationMessage,
  maritalStatusOptions
} from 'utils/constants'
import { removeMaskGuides, downloadUrl, formatContractStatus } from 'utils/helpers'
import { useAuth } from 'hooks/useAuth'


const toastCreateSucessMessage = 'Cliente adicionado com sucesso!'
const toastUpdateSucessMessage = 'Cliente editado com sucesso!'


const validationSchema = yup.object().shape({
  tipoPessoa: yup.string().required('O campo "Tipo de Pessoa" é obrigatório'),
  nome: yup.string().when('tipoPessoa', {
    is: 'F',
    then: yup.string().required('O campo "Nome" é obrigatório')
      .max(100, 'Insira um nome com menos de 100 dígitos'),
    otherwise: yup.string()
  }),
  ocupacao: yup.string().required('O campo "Ocupação" é obrigatório')
    .max(100, 'Insira uma ocupação com menos de 100 dígitos'),
  cpf: yup.string().when('tipoPessoa', {
    is: 'F',
    then: yup.string()
      .required('O campo "CPF" é obrigatório')
      .test(
        '',
        'O CPF informado não é válido',
        value => cpfValidator.isValid(value!)
      ),
    otherwise: yup.string()
  }),
  razaoSocial: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string().required('O campo "Razão Social" é obrigatório')
      .max(100, 'Insira uma razão social com menos de 200 dígitos'),
    otherwise: yup.string()
  }),
  estadoCivil: yup.string().required('O campo "Estado Civil" é obrigatório'),
  cnpj: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string()
      .required('O campo "CNPJ" é obrigatório')
      .test(
        '',
        'O CNPJ informado não é válido',
        value => cnpjValidator.isValid(value!)
      ),
    otherwise: yup.string()
  }),
  cep: yup.string()
    .required('O campo "CEP" é obrigatório')
    .test(
      '',
      'Insira um CEP válido',
      value => {
        const zeroedCEP = '00000-000'
        const isCEPZeroed = value === zeroedCEP

        const cleanCEP = removeMaskGuides(String(value))
        const isCEPIncomplete = cleanCEP.length !== 8

        return (!isCEPIncomplete && !isCEPZeroed)
      }
    ),
  cidade: yup.string().required('O campo "Cidade" é obrigatório'),
  estado: yup.string().required('O campo "Estado" é obrigatório'),
  bairro: yup.string().max(100, 'Insira um bairro com menos de 100 dígitos'),
  logradouro: yup.string().required('O campo "Logradouro" é obrigatório')
    .max(100, 'Insira um logradouro com menos de 100 dígitos'),
  complemento: yup.string().max(100, 'Insira um complemento com menos de 100 dígitos'),
  numero: yup.number()
    .required('O campo "Número" é obrigatório')
    .moreThan(0, 'Insira um número válido')
    .typeError('Insira um número válido')
    .max(999999, 'Insira um número com menos de 6 dígitos'),
  telefone: yup.string()
    .required('O campo "Telefone" é obrigatório')
    .test(
      '',
      'Insira um telefone válido',
      value => {
        const zeroedPhone = '(00) 00000-0000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = cleanPhone.length < 10

        return (!isPhoneIncomplete && !isPhoneZeroed)
      }
    )
    .max(15, 'Insira um telefone válido'),
  email: yup.string()
    .required('O campo "E-mail" é obrigatório')
    .email('Insira um e-mail válido')
    .max(100, 'Insira um email com menos de 100 dígitos'),
  representanteComercial: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string().required('O campo "Repesentante Comercial" é obrigatório')
      .max(100, 'Insira um nome com menos de 100 dígitos'),
    otherwise: yup.string().max(100, 'Insira um nome com menos de 100 dígitos')
  }),
  cpfRepresentante: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string()
      .required('O campo "CPF" é obrigatório')
      .test(
        '',
        'O CPF informado não é válido',
        value => cpfValidator.isValid(value!)
      ),
    otherwise: yup.string() }),
  cepRepresentante: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string()
      .required('O campo "CEP" é obrigatório')
      .test(
        '',
        'Insira um CEP válido',
        value => {
          const zeroedCEP = '00000-000'
          const isCEPZeroed = value === zeroedCEP

          const cleanCEP = removeMaskGuides(String(value))
          const isCEPIncomplete = cleanCEP.length !== 8

          return (!isCEPIncomplete && !isCEPZeroed)
        }
      ),
    otherwise: yup.string() }),
  cidadeRepresentante: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string().required('O campo "Cidade" é obrigatório'),
    otherwise: yup.string() }),
  estadoRepresentante: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string().required('O campo "Estado" é obrigatório'),
    otherwise: yup.string() }),
  bairroRepresentante: yup.string().max(100, 'Insira um bairro com menos de 100 dígitos'),
  logradouroRepresentante: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string().required('O campo "Logradouro" é obrigatório')
      .max(100, 'Insira um logradouro com menos de 100 dígitos'),
    otherwise: yup.string().max(100, 'Insira um logradouro com menos de 100 dígitos') }),
  numeroRepresentante: yup.number().when('tipoPessoa', {
    is: 'J',
    then: yup.number().required('O campo "Número" é obrigatório')
      .moreThan(0, 'Insira um número válido')
      .typeError('Insira um número válido')
      .max(999999, 'Insira um número com menos de 6 dígitos'),
    otherwise: yup.number()
      .moreThan(0, 'Insira um número válido')
      .typeError('Insira um número válido')
      .max(999999, 'Insira um número com menos de 6 dígitos') }),
  complementoRepresentante: yup.string().max(100, 'Insira um complemento com menos de 100 dígitos'),
  telefoneRepresentante: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string()
      .required('O campo "Telefone" é obrigatório')
      .test(
        '',
        'Insira um telefone válido',
        value => {
          const zeroedPhone = '(00) 00000-0000'
          const isPhoneZeroed = value === zeroedPhone

          const cleanPhone = removeMaskGuides(String(value))
          const isPhoneIncomplete = cleanPhone.length < 10

          return (!isPhoneIncomplete && !isPhoneZeroed)
        }
      )
      .max(15, 'Insira um telefone válido'),
    otherwise: yup.string() }),
  emailRepresentante: yup.string().when('tipoPessoa', {
    is: 'J',
    then: yup.string()
      .required('O campo "E-mail" é obrigatório')
      .email('Insira um e-mail válido')
      .max(100, 'Insira um email com menos de 100 dígitos'),
    otherwise: yup.string()
      .email('Insira um e-mail válido')
      .max(100, 'Insira um email com menos de 100 dígitos'), })
})

type TClienteForm = {
  tipoPessoa: PessoaTipo
  estadoCivil: string
  ocupacao: string
  nome: string
  cpf: string
  razaoSocial: string
  cnpj: string
  cep: string
  idEndereco: string
  cidade: string
  estado: string
  bairro: string
  logradouro: string
  numero: string
  complemento: string
  telefone: string
  email: string
  cpfRepresentante: string
  cepRepresentante: string
  idRepresentante: string
  idEnderecoRepresentante: string
  cidadeRepresentante: string
  estadoRepresentante: string
  bairroRepresentante: string
  logradouroRepresentante: string
  numeroRepresentante: string
  complementoRepresentante: string
  telefoneRepresentante: string
  emailRepresentante: string
  representanteComercial: string
  status: ActivationStatus
}

const initialValues: TClienteForm = {
  tipoPessoa: PessoaTipo.FISICA,
  estadoCivil: 'SINGLE',
  ocupacao: '',
  idEndereco: '0',
  idEnderecoRepresentante: '0',
  idRepresentante: '0',
  nome: '',
  cpf: '',
  razaoSocial: '',
  cnpj: '',
  cep: '',
  cidade: '',
  estado: '',
  bairro: '',
  logradouro: '',
  numero: '',
  complemento: '',
  telefone: '',
  email: '',
  cpfRepresentante: '',
  cepRepresentante: '',
  cidadeRepresentante: '',
  estadoRepresentante: '',
  bairroRepresentante: '',
  logradouroRepresentante: '',
  numeroRepresentante: '',
  complementoRepresentante: '',
  telefoneRepresentante: '',
  emailRepresentante: '',
  representanteComercial: '',
  status: ActivationStatus.ACTIVATED,
}



const ClientesFormulario = () => {
  const { id } = useParams<{ id: string}>()
  const location = useLocation()
  const history = useHistory()
  const { addToast } = useToasts()
  const createUpdateCliente = useCreateUpdateCliente()
  const getCliente = useGetCliente()
  const getEndereco = useGetEndereco()
  const getEnderecoRepresentante = useGetEndereco()
  const getConta = useGetContaEnergia()
  const deleteAnexos = useDeleteAnexos()
  const { userData, userPermissions, hasFormPermission } = useAuth()
  const [contratos, setContratos] = useState<TClienteContrato[]>([])

  const permission = useMemo(() => {
    const permission = userPermissions?.clientes
    return hasFormPermission(permission!, location.pathname)
  }, [userPermissions])

  useEffect(() => {
    if (!getEndereco.endereco) return
    const { localidade, uf, bairro, logradouro } = getEndereco.endereco

    formik.setFieldValue('cidade', localidade, true)
    formik.setFieldValue('estado', uf, true)
    formik.setFieldValue('bairro', bairro, true)
    formik.setFieldValue('logradouro', logradouro, true)
  }, [getEndereco.endereco])

  useEffect(() => {
    if (!getEnderecoRepresentante.endereco) return
    const { localidade, uf, bairro, logradouro } = getEnderecoRepresentante.endereco

    formik.setFieldValue('cidadeRepresentante', localidade, true)
    formik.setFieldValue('estadoRepresentante', uf, true)
    formik.setFieldValue('bairroRepresentante', bairro, true)
    formik.setFieldValue('logradouroRepresentante', logradouro, true)
  }, [getEnderecoRepresentante.endereco])

  useEffect(() => {
    if (id) getCliente.get(id)
  }, [id])



  useEffect(() => {
    if (!getCliente.cliente) return

    const { cliente } = getCliente
    const { clientAddress, representative, contracts } = cliente
    if(cliente.roleStatus !== 'ACTIVATED') history.push(`/clientes/visualizar/${id}`)

    const _representative = fillRepresentative(representative)

    const populateForm = () => {
      const _cliente: TClienteForm = {
        tipoPessoa: cliente.typePerson,
        nome: cliente.fullName ?? '',
        cpf: cliente.cpf ?? '',
        razaoSocial: cliente.companyName ?? '',
        cnpj: cliente.cnpj ?? '',
        estadoCivil: String(cliente.maritalStatus),
        ocupacao: cliente.occupation,
        cep: clientAddress.zipCode,
        idEndereco: String(clientAddress.id),
        cidade: clientAddress.city,
        estado: String(clientAddress?.uf),
        bairro: clientAddress.neighborhood,
        logradouro: clientAddress.street,
        numero: clientAddress.number,
        complemento: String(clientAddress.complement),
        telefone: cliente.telephone,
        email: cliente.email,
        idRepresentante: String(_representative.id),
        cpfRepresentante: String(_representative?.cpf),
        cepRepresentante: String(_representative?.representativeAddress.zipCode),
        idEnderecoRepresentante: String(_representative?.representativeAddress.id),
        cidadeRepresentante: String(_representative?.representativeAddress.city),
        estadoRepresentante: String(_representative?.representativeAddress.uf),
        bairroRepresentante: String(_representative?.representativeAddress.neighborhood),
        logradouroRepresentante: String(_representative?.representativeAddress.street),
        numeroRepresentante: String(_representative?.representativeAddress.number),
        complementoRepresentante: String(_representative?.representativeAddress.complement),
        telefoneRepresentante: String(_representative.telephone),
        emailRepresentante: String(_representative.email),
        representanteComercial: String(_representative.name),
        status: cliente.roleStatus
      }
      formik.setValues(_cliente, true)
    }
    const _contratos: TClienteContrato[] = contracts.map(contrato => {
      return({
        id: contrato.id,
        unitConsumption: contrato.unitConsumption,
        discount: contrato.discount,
        status: contrato.status,
        contractHash:	contrato.contractHash,
        contractPipefyId: contrato.contractPipefyId,
        sellerName: contrato.sellerName,
        averageConsumption: contrato.averageConsumption,
        averageCost: contrato.averageCost,
        finalCost: contrato.finalCost,
        readingDate: contrato.readingDate,
        category: contrato.category,
        type: contrato.type,
        subclass: contrato.subclass,
        client: contrato.client,
        attachments: contrato.attachments
      })
    })
    setContratos(_contratos)
    populateForm()
  }, [getCliente.cliente])

  useEffect(() => {
    if (!createUpdateCliente.data || createUpdateCliente.isError) return

    history.push('/clientes')
    addToast(
      id ? toastUpdateSucessMessage : toastCreateSucessMessage,
      { appearance: 'success', autoDismiss: true }
    )
  }, [createUpdateCliente.data])

  useEffect(() => {
    if (!deleteAnexos.data) return

    if (!deleteAnexos.data.isError) criarOuAtualizarCliente()
  }, [deleteAnexos.data])

  useEffect(() => {
    if (getConta.conta) downloadUrl(getConta.conta, 'conta.pdf')
  }, [getConta.conta])

  const onSubmit = () => {
    criarOuAtualizarCliente()
  }

  const fillRepresentative = (representative: TRepresentative | null) => {
    if(!representative) return {
      id: '',
      name: '',
      cpf: '',
      telephone: '',
      email: '',
      representativeAddress: {
        id: null,
        zipCode: '',
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        uf: ''
      }
    }
    return representative
  }

  const formik = useFormik<TClienteForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit
  })

  const criarOuAtualizarCliente = () => {
    const { values } = formik

    const _representative = formik.values.tipoPessoa === 'F' ? null : {
      id: parseInt(values.idRepresentante),
      name: values.representanteComercial,
      cpf: values.cpfRepresentante,
      telephone: values.telefoneRepresentante,
      email: values.emailRepresentante,
      representativeAddress: {
        id: parseInt(values.idEnderecoRepresentante),
        zipCode: values.cepRepresentante,
        street: values.logradouroRepresentante,
        number: String(values.numeroRepresentante),
        complement: values.complementoRepresentante,
        neighborhood: values.bairroRepresentante,
        city: values.cidadeRepresentante,
        uf: values.estadoRepresentante
      }
    }

    const clienteBody: TClienteInfoBody = {
      id: Number(id) ?? null,
      typePerson: values.tipoPessoa as PessoaTipo,
      fullName: values.nome ?? null,
      cpf: values.cpf ?? null,
      companyName: values.razaoSocial ?? null,
      cnpj: values.cnpj ?? null,
      telephone: values.telefone,
      email: values.email,
      roleStatus: values.status,
      maritalStatus: values.estadoCivil,
      occupation: values.ocupacao,
      clientAddress: {
        id: parseInt(values.idEndereco),
        zipCode: values.cep,
        street: values.logradouro,
        number: String(values.numero),
        complement: values.complemento,
        neighborhood: values.bairro,
        city: values.cidade,
        uf: values.estado
      },
      representative: _representative,
      contracts: []
    }
    createUpdateCliente.send(clienteBody)
  }

  const customRadioOnChange = (field: keyof TClienteForm, value: string) => {
    formik.setFieldValue(field, value, true)
  }

  const hasError = (name: keyof TClienteForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TClienteForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange
  })

  const onClickGoToLista = () => history.push('/clientes')

  const onChangeCEP = (e: TInputEvent) => {
    const { value: cep } = e.target
    formik.handleChange(e)

    const cepNumbers = cep.replace(/_/g, '').replace(/-/g, '')
    if (cepNumbers.length === 8) {
      getEndereco.get(cep)
      return
    }

    formik.setFieldValue('cidade', '')
    formik.setFieldValue('estado', '')
    formik.setFieldValue('bairro', '')
    formik.setFieldValue('logradouro', '')
  }

  const onChangeCEPRepresentante = (e: TInputEvent) => {
    const { value: cep } = e.target
    formik.handleChange(e)

    const cepNumbers = cep.replace(/_/g, '').replace(/-/g, '')
    if (cepNumbers.length === 8) {
      getEnderecoRepresentante.get(cep)
      return
    }

    formik.setFieldValue('cidadeRepresentante', '')
    formik.setFieldValue('estadoRepresentante', '')
    formik.setFieldValue('bairroRepresentante', '')
    formik.setFieldValue('logradouroRepresentante', '')
  }


  const isVisualizando = useMemo(() => {
    const urlAction = location.pathname.split('/')[2]
    return urlAction === 'visualizar'
  }, [location.pathname])

  const headerTitleAction = useMemo(() => {
    if (isVisualizando) return 'Visualizar'

    return id ? 'Editar' : 'Adicionar'
  }, [isVisualizando, id])


  if (userData && !permission) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <GoBackButton
            showConfirmation={!isVisualizando}
            route='/clientes'
            message={confirmationMessage}
          />
        </Col>
      </Row>

      <PageHeader title={`${headerTitleAction} Cliente`} />

      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Col>
            <RadioField
              label='Tipo Pessoa'
              options={tipoPessoaOptions}
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('tipoPessoa')}
              onChange={customRadioOnChange}
            />
          </Col>
        </Form.Row>

        {formik.values.tipoPessoa === 'F' ? (
          <Form.Row>
            <Col md={8}>
              <TextField
                label='Nome'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('nome')}
              />
            </Col>

            <Col md={4}>
              <MaskedTextField
                label='CPF'
                mask='999.999.999-99'
                placeholder='000.000.000-00'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('cpf')}
              />
            </Col>
          </Form.Row>
        ) : (
          <Form.Row>
            <Col md={8}>
              <TextField
                label='Razão Social'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('razaoSocial')}
              />
            </Col>

            <Col md={4}>
              <MaskedTextField
                label='CNPJ'
                mask='99.999.999/9999-99'
                placeholder='00.000.000/0000-00'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('cnpj')}
              />
            </Col>
          </Form.Row>
        )}
        <Form.Row>
          <Col md={8}>
            <TextField
              label='Ocupação'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('ocupacao')}
            />
          </Col>
          <Col md={4}>
            <SelectField
              required
              label='Estado Civil'
              disabled={isVisualizando}
              options={maritalStatusOptions}
              {...getCommonFieldProps('estadoCivil')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={2}>
            <MaskedTextField
              label='CEP'
              mask='99999-999'
              placeholder='00000-000'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('cep')}
              onChange={onChangeCEP}
            />
          </Col>

          <Col md={5}>
            <TextField
              label='Cidade'
              required
              disabled
              {...getCommonFieldProps('cidade')}
            />
          </Col>

          <Col md={5}>
            <TextField
              label='Estado'
              required
              disabled
              {...getCommonFieldProps('estado')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={10}>
            <TextField
              label='Logradouro'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('logradouro')}
            />
          </Col>

          <Col md={2}>
            <TextField
              label='Número'
              type='number'
              min={0}
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('numero')}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <TextField
              label='Bairro'
              disabled={isVisualizando}
              {...getCommonFieldProps('bairro')}
            />
          </Col>

          <Col md={6}>
            <TextField
              label='Complemento'
              disabled={isVisualizando}
              {...getCommonFieldProps('complemento')}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={4}>
            <MaskedTextField
              label='Telefone'
              mask='(99) 99999-9999'
              placeholder='(00) 00000-0000'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('telefone')}
            />
          </Col>

          <Col md={8}>
            <TextField
              label='E-mail'
              type='email'
              required
              disabled={isVisualizando}
              {...getCommonFieldProps('email')}
            />
          </Col>
        </Form.Row>

        <FormDivider />

        {formik.values.tipoPessoa === 'J' && (<>
          <Row>
            <Col>
              <FormTitle>Representação Comercial</FormTitle>
            </Col>
          </Row>

          <Form.Row>
            <Col md={8}>
              <TextField
                label='Representante Comercial'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('representanteComercial')}
              />
            </Col>

            <Col md={4}>
              <MaskedTextField
                label='CPF'
                mask='999.999.999-99'
                placeholder='000.000.000-00'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('cpfRepresentante')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={2}>
              <MaskedTextField
                label='CEP'
                mask='99999-999'
                placeholder='00000-000'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('cepRepresentante')}
                onChange={onChangeCEPRepresentante}
              />
            </Col>

            <Col md={5}>
              <TextField
                label='Cidade'
                required
                disabled
                {...getCommonFieldProps('cidadeRepresentante')}
              />
            </Col>

            <Col md={5}>
              <TextField
                label='Estado'
                required
                disabled
                {...getCommonFieldProps('estadoRepresentante')}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col md={10}>
              <TextField
                label='Logradouro'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('logradouroRepresentante')}
              />
            </Col>

            <Col md={2}>
              <TextField
                label='Número'
                type='number'
                min={0}
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('numeroRepresentante')}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col md={6}>
              <TextField
                label='Bairro'
                disabled={isVisualizando}
                {...getCommonFieldProps('bairroRepresentante')}
              />
            </Col>

            <Col md={6}>
              <TextField
                label='Complemento'
                disabled={isVisualizando}
                {...getCommonFieldProps('complementoRepresentante')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={4}>
              <MaskedTextField
                label='Telefone'
                mask='(99) 99999-9999'
                placeholder='(00) 00000-0000'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('telefoneRepresentante')}
              />
            </Col>

            <Col md={8}>
              <TextField
                label='E-mail'
                type='email'
                required
                disabled={isVisualizando}
                {...getCommonFieldProps('emailRepresentante')}
              />
            </Col>
          </Form.Row>

          <FormDivider /> </>)}

        <Row>
          <Col>
            <FormTitle>Contratos</FormTitle>
          </Col>
        </Row>
        {contratos?.map(contrato =>
          <>
            <Row key={contrato.id}>
              <Col md={4}><b>ID:</b> {contrato.contractHash}</Col>
              <Col md={4}><b>Unidade Consumidora:</b> {contrato.unitConsumption}</Col>
              <Col md={2}><b>Desconto:</b> {contrato.discount}%</Col>
              <Col md={2}><b>Status:</b> {formatContractStatus(contrato.status)}</Col>
            </Row>
            <FormDivider />
          </>
        )}

        <ButtonsContainer>
          <ConfirmButton
            active={!isVisualizando}
            actionFn={onClickGoToLista}
            message={confirmationMessage}
            variant={Variant.SECONDARY}
          >
            <span>{isVisualizando ? 'Voltar' : 'Cancelar' }</span>
          </ConfirmButton>
          {!isVisualizando && (
            <PrimaryButton type='submit' isLoading={createUpdateCliente.isLoading}>
              <span>Salvar</span>
            </PrimaryButton>
          )}
        </ButtonsContainer>
      </Form>
    </>
  )
}

export default ClientesFormulario

import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {
  PageHeader,
  InfoText
} from 'components'

const PaginaNaoEncontrada = () => {
  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Página não encontrada' />
        </Col>
      </Row>
      <Row>
        <Col>
          <InfoText>
            Para maiores informações contate o administrador do sistema
          </InfoText>
        </Col>
      </Row>
    </>
  )
}

export default PaginaNaoEncontrada
import styled from 'styled-components'

export const CustomLink = styled.a`
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  font-weight: 700;
  display: block;
  margin-bottom: 27px;
  cursor: pointer;
  display: flex;

  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GRAY};
    text-decoration: none;
  }

  svg {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
`
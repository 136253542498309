import React, { useState, useEffect, useMemo } from 'react'
import {
  useParams,
  useHistory,
  useLocation,
  Redirect
} from 'react-router-dom'
import { Row, Col, Container, Spinner, ListGroup } from 'react-bootstrap'
import { useToasts, Options } from 'react-toast-notifications'
import { BiDetail } from 'react-icons/bi'
import { BsInfoCircle, BsClipboardCheck } from 'react-icons/bs'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'

import useProjetos from 'repositories/useProjetos'
import {
  Loader,
  InfoText,
  GoBackButton,
  PageHeader,
  ButtonsContainer,
  SecondaryButton,
  PrimaryButton
} from 'components'
import {
  formatCurrency,
  formatDateToView,
  getDiffDaysBetweenDates,
  formatDecimalNumber
} from 'utils/helpers'
import ModalConfirmation from 'components/ModalConfirmation'

import {
  Box,
  BoxTitle,
  InfoContainer,
  ActionButton
} from './styles'
import { useAuth } from 'hooks/useAuth'


const list100 = ['Conta de Energia']

const list300 = [
  ...list100,
  'Autorização para consulta SCR',
  'Comprovação de Renda - Conforme vínculos empregatícios',
  'Projeto detalhado'
]

const listCompletePF = [
  ...list300,
  'Referências sobre o integrador'
]

const list500PJ = [
  ...list100,
  'Autorização para consulta SCR',
  'Relação de Faturamento (últimos 12 meses)',
  'Balanço patrimonial',
  'RE (consolidado último ano) + Últimos Balancetes',
  'Projeto detalhado',
  'Referências do integrador'
]

const listCompletePJ = [
  ...list500PJ,
  'Abertura de endividamento Bancário (consolidado por instituição financeira)'
]

const toastErrorConfig = {
  appearance: 'error',
  autoDismiss: false,
} as Options


const Credito = () => {
  const { id } = useParams<{ id: string }>()
  const location = useLocation<{ fromDetalhes: boolean }>()
  const repository = useProjetos()
  const { userPermissions } = useAuth()
  const history = useHistory()
  const permissions = useMemo(() => userPermissions?.projetos, [userPermissions])
  const { addToast } = useToasts()

  const [credito, setCredito] = useState<TProposta>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAcceptedByUser, setIsAcceptedByUser] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [messageModal, setMessageModal] = useState<string>('')
  const [documentList, setDocumentList] = useState<string[]>([])

  const goBackRoute = `/projetos/detalhes/${id}`
  const documentsRoute = `/projetos/documentos/${id}`

  useEffect(() => {
    const requestCredito = async () => {
      const formatCredito = (credit: TProposta): TProposta => {
        const formatedAmountReleased = formatCurrency(credit.amountReleased)
        const formatedDataSimulacao = formatDateToView(credit.dataSimulacao)
        const formatedEvaluationDate = formatDateToView(credit.evaluationDate)
        const formatedTaxaJuros = formatDecimalNumber(credit.installment.taxaJuros)
        const formatedTaxaCet = formatDecimalNumber(credit.installment.taxaCet)
        const carencia = getDiffDaysBetweenDates(
          credit.dataSimulacao,
          credit.installment.primeiroVencimento
        )
        const listaIdStatusValidos = [3, 4, 5]

        const isAllowedChoose = listaIdStatusValidos.includes(credit.projectStatus.id)

        return {
          ...credit,
          formatedAmountReleased,
          formatedDataSimulacao,
          formatedEvaluationDate,
          carencia,
          formatedTaxaJuros,
          formatedTaxaCet,
          isAllowedChoose
        }
      }

      const _credito = await repository.getCredito(id)
      if (!_credito) return
      const formattedCredito = formatCredito(_credito)
      const formattedDocumentList = formatDocuments(formattedCredito.isClientPj, parseInt(formattedCredito.amountReleased))
      setCredito(formattedCredito)
      setDocumentList(formattedDocumentList)
    }

    if (id) requestCredito()
  }, [id])


  const getDocumentMessagePF = (projectValue: number) => {
    if(!projectValue) return ['']
    if(projectValue < 100000) return list100
    if(projectValue >= 100000 && projectValue < 300000) return list300
    if(projectValue >= 300000) return listCompletePF
    return ['']
  }

  const getDocumentMessagePJ = (projectValue: number) => {
    if(!projectValue) return ['']
    if(projectValue < 100000) return list100
    if(projectValue >= 100000 && projectValue < 300000) return list300
    if(projectValue >= 300000 && projectValue < 500000) return list500PJ
    if(projectValue >= 500000) return listCompletePJ
    return ['']
  }

  const formatDocuments = (isClientPj: boolean, projectValue: number) => {
    if(isClientPj) return getDocumentMessagePJ(projectValue)
    return getDocumentMessagePF(projectValue)
  }

  const onClickHandleSubmit = async () => {
    if (!credito?.id) return
    setIsModalOpen(false)
    setIsSaving(true)
    const data = {
      isAccepted: isAcceptedByUser
    }
    const response = await repository.postCredito(credito.id, data)
    setIsSaving(false)
    if(!response) return
    if(response.code === 18003){
      addToast('Documentos requisitados pelo SAFRA ainda não foram enviados.', toastErrorConfig)
      return
    }
    history.push(goBackRoute)
  }

  const showModal = (isAccepted: boolean) => () => {
    setIsModalOpen(true)
    setIsAcceptedByUser(isAccepted)
    setMessageModal(
      `Deseja mesmo ${isAccepted ? 'ACEITAR' : 'RECUSAR'} o crédito ?`
    )
  }

  const goToDocuments = () => {
    history.push(documentsRoute, { fromDetalhes: true })
  }

  const areButtonsDisabled = credito?.isAcceptedByUser || !credito?.isAllowedChoose || !permissions?.edit

  if (!location.state) return <Redirect to={goBackRoute} />

  return (
    <Container className='pl-3' fluid>
      <Row>
        <Col>
          <GoBackButton route={goBackRoute} />
        </Col>
      </Row>

      <Row>
        <Col>
          <PageHeader title='Crédito' />
        </Col>
        <Col>
          {isSaving ? (
            <></>
          ) : (
            <ButtonsContainer>
              <SecondaryButton
                disabled={areButtonsDisabled}
                onClick={showModal(false)}
              >
                Recusar
              </SecondaryButton>
              <PrimaryButton
                disabled={areButtonsDisabled}
                onClick={showModal(true)}
              >
                Aceitar
              </PrimaryButton>
            </ButtonsContainer>
          )}
        </Col>
      </Row>

      <Loader isLoading={repository.isLoading} />

      {!repository.isLoading && (
        <>
          <Row>
            <Col>
              <InfoText>
                Ao aprovar a proposta, você deverá continuar o processo junto ao banco
              </InfoText>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Box>
                <BoxTitle>
                  <RiMoneyDollarCircleLine />
                  <h3>Produto</h3>
                </BoxTitle>

                <InfoContainer>
                  <div>
                    <dt>Produto</dt>
                    <dd>{credito?.productDescription}</dd>
                  </div>

                  <div>
                    <dt>Valor Ofertado</dt>
                    <dd>{credito?.formatedAmountReleased}</dd>
                  </div>
                </InfoContainer>

                <BoxTitle>
                  <BiDetail />
                  <h3>Detalhes da Oferta</h3>
                </BoxTitle>

                <InfoContainer>
                  <div>
                    <dt>Quantidade de Parcelas</dt>
                    <dd>{credito?.installment.prazo}</dd>
                  </div>

                  <div>
                    <dt>Taxa de Juros</dt>
                    <dd>{credito?.formatedTaxaJuros}% a.m.</dd>
                  </div>

                  <div>
                    <dt>CET</dt>
                    <dd>{credito?.formatedTaxaCet}% a.m.</dd>
                  </div>

                  <div>
                    <dt>Carência</dt>
                    <dd>{credito?.carencia} dias</dd>
                  </div>
                </InfoContainer>

                <BoxTitle>
                  <BsInfoCircle />
                  <h3>Informações</h3>
                </BoxTitle>

                <InfoContainer>
                  <div>
                    <dt>Data de Emissão</dt>
                    <dd>{credito?.formatedDataSimulacao}</dd>
                  </div>

                  <div>
                    <dt>Validade da Proposta</dt>
                    <dd>{credito?.formatedEvaluationDate}</dd>
                  </div>

                  <div>
                    <dt>Status</dt>
                    <dd>{credito?.statusSafra}</dd>
                  </div>
                </InfoContainer>

              </Box>
            </Col>

            <Col lg={6}>
              <Box>
                <BoxTitle>
                  <BsClipboardCheck />
                  <h3>Documentos Obrigatórios</h3>
                </BoxTitle>

                <p><ActionButton onClick={goToDocuments}>Clique aqui</ActionButton> para enviar os documentos obrigatórios através do sistema.</p>

                <BoxTitle>
                  <BsClipboardCheck />
                  <h3>Documentos Complementares</h3>
                </BoxTitle>

                <p>Antes de aceitar a proposta, verifique se a documentação mínima para análise também foi enviada:</p>
                <ListGroup>
                  {documentList.map((doc, key) => {
                    return(
                      <ListGroup.Item key={key}>
                        {doc}
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>

              </Box>
            </Col>
          </Row>
        </>
      )}
      <ModalConfirmation
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        confirmationText={messageModal}
        confirmationFunction={onClickHandleSubmit}
      />
    </Container>
  )
}

export default Credito
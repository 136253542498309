import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, Redirect } from 'react-router-dom'

import { Row, Col, Table } from 'react-bootstrap'
import { AiFillPlusSquare, AiOutlineEye } from 'react-icons/ai'
import { BiPencil, BiCheck, BiX } from 'react-icons/bi'

import {
  Loader,
  PageHeader,
  PrimaryButton,
  TableMessageRow,
  ActionButton,
  Pagination,
  FlexContainer,
  Filter,
  TableLink
} from 'components'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import { useGetCondominios, useSaveCondominio } from 'repositories/condominios'
import { useAuth } from 'hooks/useAuth'
import ClientesModal from './Components/ClientesModal'

const tableMessageRowColSpan = 4

const initialParams = {
  paginate: {
    numberOfRecordsByPage: 10,
    pageNumber: 0,
  }
}

const CondominioLista = () => {
  const [showClientesModal, setShowClientesModal] = useState(false)
  const [
    selectedCondominio,
    setSelectedCondominio
  ] = useState<TCondominioResponse>()

  const history = useHistory()
  const pagination = usePagination()
  const filters = useFilters()
  const getCondominios = useGetCondominios()
  const updateConominio = useSaveCondominio()
  const { userPermissions } = useAuth()

  const permissions = useMemo(() => userPermissions?.condominios, [userPermissions])

  useEffect(() => {
    getCondominios.get(initialParams)
  }, [])

  useEffect(() => {
    requestCondominios()
  }, [filters.items])

  useEffect(() => {
    if (!getCondominios.paginationInfo) return
    const { paginationInfo } = getCondominios
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)
    pagination.stopLoading()
    filters.stopLoading()
  }, [getCondominios.paginationInfo])

  useEffect(() => {
    if (updateConominio.data) {
      requestCondominios(getCondominios.paginationInfo?.pageNumber)
    }
  }, [updateConominio.data])

  const requestCondominios = (pageIndex = 0) => {
    const _filters = filters.items.reduce((acc, curr) => ({
      ...acc, [curr.field]: curr.term
    }), {})

    const params = {
      findCondominiumByFilterCommand: {
        ..._filters,
      },
      paginate: {
        numberOfRecordsByPage: pagination.itemsPerPage,
        pageNumber: pageIndex,
      }
    }
    getCondominios.get(params)
  }

  const onClickGoToPage = (page: string) => () => (
    history.push(page)
  )

  const onClickToogleCondominioStatus = (condominio: TCondominioResponse) => () => {
    const usinaIds = condominio.powerPlants.map(usina => usina.id)
    const contas = condominio.accounts.map(conta => ({
      ...conta,
    }))
    const _condominio = {
      ...condominio,
      powerPlants: usinaIds,
      accounts: contas,
      isActive: !condominio.isActive,
      allocatedClients: undefined,
    }

    updateConominio.save(_condominio)
  }

  const handleOpenClienteModal = (index: number) => () => {
    setSelectedCondominio(getCondominios.condominios?.[index])
    setShowClientesModal(true)
  }
  const handleCloseClienteModal = () => setShowClientesModal(false)

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Condomínios'>
            {permissions?.add && (
              <PrimaryButton
                iconPosition='right'
                onClick={onClickGoToPage('/condominios/novo')}
              >
                <span>Adicionar Condomínio</span>
                <AiFillPlusSquare />
              </PrimaryButton>
            )}
          </PageHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>Nome</span>
                    <Filter
                      field='name'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>CNPJ</span>
                    <Filter
                      field='cnpj'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>Clientes Alocados</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {getCondominios.loading ? (
                <TableMessageRow colSpan={tableMessageRowColSpan}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!getCondominios.condominios?.length && (
                    <TableMessageRow colSpan={7}>
                      Não foram encontrados condomínios para serem listados
                    </TableMessageRow>
                  )}
                  {getCondominios.condominios?.map((condominio, index) => {
                    const statusButtonTitle = condominio.isActive ? 'Inativar' : 'Ativar'
                    return (
                      <tr key={index}>
                        <td>{condominio.name}</td>
                        <td>{condominio.cnpj}</td>
                        <td>
                          {condominio.allocatedClients.length ? (
                            <TableLink href='#' onClick={handleOpenClienteModal(index)}>
                              {condominio.allocatedClients.length}
                              {` cliente${condominio.allocatedClients.length > 1 ? 's' : ''}`}
                            </TableLink>
                          ) : (
                            <>Sem clientes alocados</>
                          )}
                        </td>
                        <td>
                          <ActionButton
                            onClick={onClickGoToPage(`/condominios/visualizar/${condominio.id}`)}
                          >
                            <AiOutlineEye />
                          </ActionButton>
                          {permissions?.edit && (
                            <ActionButton
                              onClick={onClickGoToPage(`/condominios/editar/${condominio.id}`)}
                            >
                              <BiPencil />
                            </ActionButton>
                          )}
                          {permissions?.inactivate && (
                            <ActionButton
                              title={statusButtonTitle}
                              onClick={onClickToogleCondominioStatus(condominio)}
                            >
                              {condominio.isActive ? <BiX /> : <BiCheck />}
                            </ActionButton>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          <Pagination
            currentPageIndex={pagination.currentPageIndex!}
            totalPages={pagination.totalPages!}
            request={requestCondominios}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>

      <ClientesModal
        selectedCondominio={selectedCondominio}
        showModal={showClientesModal}
        handleCloseModal={handleCloseClienteModal}
      />
    </>
  )
}

export default CondominioLista

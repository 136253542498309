import React, { useMemo, useEffect, useState } from 'react'
import { useAuth } from 'hooks/useAuth'
import {
  useGetBillings,
  TBillingParams,
  TBillingInfo,
  useGetBillingsReport,
  useGetBillingsReportsList,
  TBillingReportsListParams,
  TBillingReportsListInfo,
} from 'repositories/faturas'
import { Redirect } from 'react-router-dom'
import { configToastError, configToastSuccess, initialPaginationParams } from 'utils/constants'
import usePagination from 'hooks/usePagination'
import { FiDownload } from 'react-icons/fi'
import useFilters from 'hooks/useFilters'
import {
  formatDateToView,
  formatCurrency,
  formatBooleanToSimNao,
  formatDateTimeToView,
} from 'utils/helpers'
import {
  Pagination,
  PageHeader,
  Filter,
  TableMessageRow,
  PrimaryButton,
  Loader
} from 'components'
import { Row, Col, Table, Collapse } from 'react-bootstrap'
import { CustomCollapseButton, CustomLink, FlexContainer } from './styles'
import useSocket from 'hooks/useSocket'
import { useToasts } from 'react-toast-notifications'

const initialParams: TBillingParams = {
  findBillingCommand: {},
  ...initialPaginationParams,
}
const billingsReportsListInitialParams: TBillingReportsListParams = {
  findBillingReportCommand: {},
  paginate: {
    pageNumber: 0,
    numberOfRecordsByPage: 5
  },
}

const billingReportStatus: Record<string, string> = {
  SUCCESS: 'SUCESSO',
  DELETED: 'DELETADO',
  ERROR: 'ERRO',
  PROCESSING: 'PROCESSANDO'
}

const VisualizaFaturas = () => {
  const { userPermissions } = useAuth()
  const getBillings = useGetBillings()
  const getBillingsReport = useGetBillingsReport()
  const getBillingsReportList = useGetBillingsReportsList()
  const socket = useSocket()
  const { addToast } = useToasts()
  const pagination = usePagination()
  const filters = useFilters()
  const [faturas, setFaturas] = useState<TBillingInfo[]>([])
  const [billingReports, setBillingReports] = useState<TBillingReportsListInfo[]>([])
  const [paginationInfo, setPaginationInfo] = useState<TPageProperties>()
  const [reportsCollapseIsOpen, setReportsCollapseIsOpen] =
    useState<boolean>(false)

  useEffect(() => {
    getBillings.get(initialParams)
    getBillingsReportList.get(billingsReportsListInitialParams)
  }, [])

  useEffect(() => {
    if (!getBillings.billings) return
    setFaturas(getBillings.billings)
  }, [getBillings.billings, getBillingsReportList.billingReportList])

  useEffect(() => {
    if (!getBillingsReportList.billingReportList) return
    setBillingReports(getBillingsReportList.billingReportList)
  }, [getBillingsReportList.billingReportList])

  useEffect(() => {
    if (!getBillingsReport.requestIdentification) return
    try {
      const stompClient = socket.socketConnect()
      addToast('Exportando relatório, aguarde', configToastSuccess)
      getBillingsReportList.get(billingsReportsListInitialParams)
      stompClient.connect({}, () => {
        const subscription = stompClient.subscribe(
          `/topic/messages/${getBillingsReport.requestIdentification}`,
          (message: { body: string }) => {
            const jsonParse = JSON.parse(message.body)
            if(jsonParse && !jsonParse.commonResponse.isError){
              window.open(`${jsonParse.billingReportSuccess.link}`)
              addToast('Relatório exportado com sucesso', configToastSuccess)
              getBillingsReportList.get(billingsReportsListInitialParams)
            }
            jsonParse.commonResponse.isError && addToast('Relatório não exportado', configToastError)
            subscription.unsubscribe()
          }
        )
      })
    } catch {
      addToast('Falha na conexão com o socket', configToastError)
      socket.socketConnect().unsubscribe()
    }
  }, [getBillingsReport.requestIdentification])

  useEffect(() => {
    requestBillings()
  }, [filters.items])

  useEffect(() => {
    pagination.stopLoading()
    filters.stopLoading()

    if (!getBillings.paginationInfo) return

    setFaturas(getBillings.billings!)

    const { paginationInfo } = getBillings
    setPaginationInfo({
      pageNumber: paginationInfo.pageNumber,
      totalPages: paginationInfo.totalPages,
    })
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)
  }, [getBillings.paginationInfo])

  const requestBillings = (pageIndex = 0, triggerLoading = false) => {
    const _params = {
      findBillingCommand: {
        ...filters.getObjectifiedFilterItems(),
      },
      paginate: {
        pageNumber: pageIndex,
        numberOfRecordsByPage: 10,
      },
    }
    getBillings.get(_params, triggerLoading)
  }

  const requestBillingsReport = (triggerLoading = false) => {
    const _params = {
      findBillingCommand: {
        ...filters.getObjectifiedFilterItems(),
      },
    }
    getBillingsReport.get(_params, triggerLoading)
  }

  const permissions = useMemo(() => userPermissions?.faturas, [userPermissions])

  if (userPermissions && !permissions?.view)
    return <Redirect to='/acesso-negado' />
  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Faturamento'>
            <PrimaryButton
              onClick={requestBillingsReport}
              iconPosition='right'
              isLoading={getBillingsReport.isLoading}
              disabled={getBillingsReport.isLoading}
            >
              <span>Exportar Relatório</span>
              <FiDownload color='#FFF' />
            </PrimaryButton>
          </PageHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>UC</span>
                    <Filter field='unitConsumption' filters={filters} />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Usina</span>
                    <Filter field='powerPlantName' filters={filters} />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Cliente</span>
                    <Filter field='clientName' filters={filters} />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Referência</span>
                    <Filter
                      label={['Data Início', 'Data Fim']}
                      field='monthStart|monthEnd'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Vencimento</span>
                    <Filter
                      label={['Data Início', 'Data Fim']}
                      field='dueDateStart|dueDateEnd'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Valor SOL</span>
                    <Filter
                      label={['Mínimo', 'Máximo']}
                      specialType='currency'
                      field='copernicoTotalStart|copernicoTotalEnd'
                      filters={filters}
                      isDate={false}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Valor CEMIG</span>
                    <Filter
                      label={['Mínimo', 'Máximo']}
                      specialType='currency'
                      field='cemigTotalStart|cemigTotalEnd'
                      filters={filters}
                      isDate={false}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <span>Pago</span>
                </th>
                <th>
                  <span>Pagamento</span>
                </th>
                <th>
                  <span>Remessa</span>
                </th>
                <th>
                  <span>Retorno</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {getBillings.isLoading ? (
                <TableMessageRow colSpan={11}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {faturas.length === 0 && (
                    <TableMessageRow colSpan={11}>
                  Não foram encontradas faturas para serem listadas
                    </TableMessageRow>
                  )}
                  {faturas.map((fatura, index) => {
                    return (
                      <tr key={index}>
                        <td>{fatura.unitConsumption}</td>
                        <td>{fatura.powerPlantName}</td>
                        <td>{fatura.clientName}</td>
                        <td>{fatura.month}</td>
                        <td>{formatDateToView(fatura.dueDate)}</td>
                        <td>{formatCurrency(fatura.copernicoBillValue)}</td>
                        <td>{formatCurrency(fatura.cemigBillValue)}</td>
                        <td>{formatBooleanToSimNao(fatura.paid)}</td>
                        <td>{fatura.payday}</td>
                        <td>{formatBooleanToSimNao(fatura.fileSent)}</td>
                        <td>{formatBooleanToSimNao(fatura.fileReturned)}</td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination
            currentPageIndex={paginationInfo?.pageNumber}
            totalPages={paginationInfo?.totalPages}
            request={requestBillings}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomCollapseButton
            onClick={() => setReportsCollapseIsOpen(!reportsCollapseIsOpen)}
          >
            Exibir Relatórios
          </CustomCollapseButton>
          <Collapse in={reportsCollapseIsOpen}>
            <Table responsive striped bordered>
              <thead>
                <tr>
                  <th>
                    <FlexContainer>
                      <span>Usuário</span>
                    </FlexContainer>
                  </th>
                  <th>
                    <FlexContainer>
                      <span>Data de Geração</span>
                    </FlexContainer>
                  </th>
                  <th>
                    <FlexContainer>
                      <span>Status</span>
                    </FlexContainer>
                  </th>
                  <th>
                    <FlexContainer>
                      <span>Download</span>
                    </FlexContainer>
                  </th>
                  <th>
                    <FlexContainer>
                      <span>Data de Última Atualização</span>
                    </FlexContainer>
                  </th>
                </tr>
              </thead>
              <tbody>
                {billingReports.length === 0 && (
                  <TableMessageRow colSpan={10}>
                    Não foram encontradas faturas para serem listadas
                  </TableMessageRow>
                )}
                {billingReports && billingReports.map((report: TBillingReportsListInfo) => (
                  <tr key={report.id}>
                    <td>{report.userName}</td>
                    <td>{formatDateToView(report.createdDate)}</td>
                    <td>{billingReportStatus[report.status]}</td>
                    <td>{report.status === 'SUCCESS' ? <CustomLink onClick={() => window.open(`${report.link}`)}>Link para download</CustomLink> : ''}</td>
                    <td>{formatDateTimeToView(report.updatedDate)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Collapse>
        </Col>
      </Row>
    </>
  )
}

export default VisualizaFaturas

import { useEffect } from 'react'
import { useLazyQuery } from 'react-apollo'
import useErrorHandler from 'hooks/useErrorHandler'
import { ENDERECO } from './generalGqls'

type TEnderecoResponse = {
  findDataZipCode: TComonResponse & {
    localidade: string
    logradouro: string
    bairro: string
    uf: string
  }
}

export const useGetEndereco = () => {
  const errorHandler = useErrorHandler()

  const [requestEndereco, { data }] = useLazyQuery<TEnderecoResponse>(ENDERECO, {
    fetchPolicy: 'no-cache',
    onError: error => errorHandler.handleApolloErrorDesambiguation(error)
  })

  useEffect(() => {
    if (!data) return

    const { isError, description } = data.findDataZipCode.commonResponse
    if (isError) errorHandler.handleBusinessLogicError(description)
  }, [data])

  const get = (cep: string) => {
    requestEndereco({
      variables: {
        zipCode: cep
      }
    })
  }

  return {
    endereco: data?.findDataZipCode,
    get
  }
}
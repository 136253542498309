import React, { useEffect, useMemo } from 'react'
import { useHistory, Redirect } from 'react-router-dom'

import { Row, Col, Table } from 'react-bootstrap'
import { AiFillPlusSquare, AiOutlineEye } from 'react-icons/ai'
import { BiPencil, BiCheck, BiX } from 'react-icons/bi'

import {
  Loader,
  PageHeader,
  PrimaryButton,
  TableMessageRow,
  ActionButton,
  Pagination,
  FlexContainer,
  Filter
} from 'components'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import { useGetProfiles, useUpdateProfile } from 'repositories/perfis'
import { ActivationStatus, statusOptions } from 'utils/constants'
import { getStatusLabel } from 'utils/helpers'
import { useAuth } from 'hooks/useAuth'

const initialParams = {
  paginate: {
    numberOfRecordsByPage: 10,
    pageNumber: 0,
  }
}

const PerfisLista = () => {
  const history = useHistory()
  const pagination = usePagination()
  const filters = useFilters()
  const getProfiles = useGetProfiles()
  const updateProfile = useUpdateProfile()
  const { userPermissions } = useAuth()

  const permissions = useMemo(() => userPermissions?.perfis, [userPermissions])

  useEffect(() => {
    getProfiles.get(initialParams)
  }, [])

  useEffect(() => {
    requestPerfis()
  }, [filters.items])

  useEffect(() => {
    if (!getProfiles.paginationInfo) return
    const { paginationInfo } = getProfiles
    pagination.setOptions(paginationInfo.pageNumber, paginationInfo.totalPages)
    pagination.stopLoading()
    filters.stopLoading()
  }, [getProfiles.paginationInfo])

  useEffect(() => {
    if (updateProfile.response) {
      requestPerfis(getProfiles.paginationInfo?.pageNumber)
    }
  }, [updateProfile.response])

  const requestPerfis = (pageIndex = 0) => {
    const _filters = filters.items.reduce((acc, curr) => ({
      ...acc, [curr.field]: curr.term
    }), {})

    const params = {
      ..._filters,
      paginate: {
        numberOfRecordsByPage: pagination.itemsPerPage,
        pageNumber: pageIndex,
      }
    }
    getProfiles.get(params)
  }

  const onClickGoToForm = () => {
    history.push('/perfis/novo')
  }

  const onClickVisualize = (id: number) => () => {
    history.push(`/perfis/visualizar/${id}`)
  }

  const onClickEdit = (id: number) => () => {
    history.push(`/perfis/editar/${id}`)
  }

  const onClickToogleProfileStatus = (profile: TPerfisPostOrUpdate) => () => {
    const _profile = {
      id: profile.id!,
      status: profile.status === ActivationStatus.ACTIVATED
        ? ActivationStatus.DEACTIVATED
        : ActivationStatus.ACTIVATED
    }

    updateProfile.update(_profile)
  }

  if (userPermissions && !permissions?.view) return <Redirect to='/acesso-negado' />

  return (
    <>
      <Row>
        <Col>
          <PageHeader title='Perfis de Acesso'>
            {permissions?.add && (
              <PrimaryButton onClick={onClickGoToForm} iconPosition='right'>
                <span>Adicionar Perfil</span>
                <AiFillPlusSquare />
              </PrimaryButton>
            )}
          </PageHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <FlexContainer>
                    <span>Nome</span>
                    <Filter
                      field='name'
                      filters={filters}
                    />
                  </FlexContainer>
                </th>
                <th>
                  <FlexContainer>
                    <span>Status</span>
                    <Filter
                      field='status'
                      filters={filters}
                      options={statusOptions}
                    />
                  </FlexContainer>
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {getProfiles.loading ? (
                <TableMessageRow colSpan={3}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!getProfiles.perfis?.length && (
                    <TableMessageRow colSpan={7}>
                      Não foram encontrados perfis para serem listados
                    </TableMessageRow>
                  )}
                  {getProfiles.perfis?.map((perfil, index) => {
                    const formattedStatus = getStatusLabel(perfil.status)
                    const isStatus = perfil.status === ActivationStatus.ACTIVATED
                    const statusButtonTitle = isStatus
                      ? 'Inativar'
                      : 'Ativar'
                    return (
                      <tr key={index}>
                        <td>{perfil.name}</td>
                        <td>{formattedStatus}</td>
                        <td>
                          <ActionButton
                            onClick={onClickVisualize(perfil.id)}
                          >
                            <AiOutlineEye />
                          </ActionButton>
                          {permissions?.edit && (
                            <ActionButton
                              onClick={onClickEdit(perfil.id)}
                            >
                              <BiPencil />
                            </ActionButton>
                          )}
                          {permissions?.inactivate && (
                            <ActionButton
                              title={statusButtonTitle}
                              onClick={onClickToogleProfileStatus(perfil)}
                            >
                              {isStatus ? <BiX /> : <BiCheck />}
                            </ActionButton>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination
            currentPageIndex={pagination.currentPageIndex!}
            totalPages={pagination.totalPages!}
            request={requestPerfis}
            goTo={pagination.goTo}
            goToPage={pagination.goToPage}
            loader={pagination.loader}
          />
        </Col>
      </Row>
    </>
  )
}

export default PerfisLista
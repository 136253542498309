import React from 'react'
import { useFormik, FormikErrors } from 'formik'

import {
  Form,
  Col
} from 'react-bootstrap'
import { BiX } from 'react-icons/bi'

import {
  initialValues,
  schema
} from './validation'

import {
  PrimaryButton,
  ActionButton,
  TextField,
  MaskedCurrencyField
} from 'components'
import {
  formatCurrencyToServer,
  formatDecimalNumber
} from 'utils/helpers'

import {
  CustomTable,
  EmptyRows,
  ContainerInvertersAndModules
} from '../styles'

type TProps = {
  values: TDadosTecnicosRequest,
  setFieldValue(
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ): Promise<void> | Promise<FormikErrors<TDadosTecnicosRequest>>,
  isDisableItems: boolean
}

const Modules = (props: TProps) => {
  const {
    values,
    setFieldValue,
    isDisableItems
  } = props

  const addModule = () => {
    if (!values.modulos) return

    const data = [
      ...values.modulos, { ...formik.values }
    ]
    const sumOfEquipament = formatDecimalNumber(
      Number(formatCurrencyToServer(values.totalEquipamento)) +
      Number(formatCurrencyToServer(String(formik.values.equipmentValue)))
    )
    setFieldValue('modulos', data)
    setFieldValue('totalEquipamento', sumOfEquipament)
    formik.resetForm()
  }

  const formik = useFormik<TModule>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: addModule,
  })

  const onClickHandleRemoveItem = (indexArray: number) => () => {
    if (!values.modulos) return

    const filteredModules = values.modulos.filter((_, index) => (
      index !== indexArray
    ))

    const [filteredIndex]: TModule[] = values.modulos.filter((_, index) => (
      index === indexArray
    ))
    const formattedTotalEquipmentValue = formatCurrencyToServer(values.totalEquipamento)
    const formattedEquipmentValue = formatCurrencyToServer(String(filteredIndex.equipmentValue))
    const sumOfEquipament = formatDecimalNumber(
      Number(formattedTotalEquipmentValue) -
      Number(formattedEquipmentValue)
    )
    setFieldValue('totalEquipamento', sumOfEquipament)
    setFieldValue('modulos', filteredModules)
  }

  const hasError = (name: keyof TModule) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof TModule) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  })

  return (
    <>
      {!isDisableItems && (
        <ContainerInvertersAndModules>
          <Form>
            <Form.Row>
              <Col md={6}>
                <TextField
                  label='Marca'
                  required
                  {...getCommonFieldProps('brand')}
                />
              </Col>
              <Col md={6}>
                <TextField
                  label='Modelo'
                  required
                  {...getCommonFieldProps('model')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={3}>
                <TextField
                  type='number'
                  label='Quantidade'
                  required
                  {...getCommonFieldProps('amount')}
                />
              </Col>
              <Col md={3}>
                <TextField
                  type='number'
                  label='Potência'
                  required
                  {...getCommonFieldProps('power')}
                />
              </Col>
              <Col md={3}>
                <TextField
                  type='number'
                  label='Garantia'
                  required
                  {...getCommonFieldProps('warrantyTerm')}
                />
              </Col>
              <Col md={3}>
                <MaskedCurrencyField
                  required
                  label='Valor do equipamento'
                  {...getCommonFieldProps('equipmentValue')}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={12}>
                <PrimaryButton
                  type='button'
                  onClick={formik.handleSubmit}
                >
                  <span>Adicionar Módulo</span>
                </PrimaryButton>
              </Col>
            </Form.Row>
          </Form>
        </ContainerInvertersAndModules>
      )}
      <CustomTable responsive striped hover>
        <thead>
          <tr>
            <th>Marca</th>
            <th>Modelo</th>
            <th>Quantidade</th>
            <th>Potência</th>
            <th>Garantia</th>
            <th>Valor</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {!values.modulos?.length && (
            <tr>
              <EmptyRows colSpan={7}>
                Nenhum módulo encontrado
              </EmptyRows>
            </tr>
          )}
          {values.modulos?.map((modulo, index) => (
            <tr key={index}>
              <td>{modulo.brand}</td>
              <td>{modulo.model}</td>
              <td>{modulo.amount}</td>
              <td>{modulo.power}</td>
              <td>{modulo.warrantyTerm}</td>
              <td>{modulo.equipmentValue}</td>
              <td>
                <ActionButton
                  type='button'
                  onClick={onClickHandleRemoveItem(index)}
                  disabled={isDisableItems}
                >
                  <BiX />
                </ActionButton>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTable>
    </>
  )
}

export default Modules
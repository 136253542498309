import { useState } from 'react'
import useRest from 'hooks/useRest'

type TResetSenhaData = {
  newPassword: string
  token: string
}

type TLoginData = {
  token: string,
  user: {
    name: string
    role: string
  }
}

const useAutenticacao = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const postLogin = async (email: string, senha: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/auth/signin'
    const dados = { email, password: senha }
    const response = await rest.post(url, dados)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response as TLoginData
  }

  const postLogout = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = 'auth/logout'
    const response = await rest.post(url)
    if (triggerLoading) setIsLoading(false)
    return Boolean(response)
  }

  const postEsqueceuSenha = async (email: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = 'auth/forgot-password'
    const dados = { email }
    const response = await rest.post(url, dados)
    if (triggerLoading) setIsLoading(false)
    if (!response) return
    return response
  }

  const getValidateToken = async (token: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `auth/token-validation/${token}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {}
    return response
  }

  const putResetSenha = async (dados: TResetSenhaData, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = 'auth/password-reset'
    const response = await rest.put(url, dados)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {}
    return response
  }

  return {
    isLoading,
    postLogin,
    postLogout,
    postEsqueceuSenha,
    getValidateToken,
    putResetSenha
  }
}

export default useAutenticacao
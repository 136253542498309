import { useState } from 'react'
import useRest from 'hooks/useRest'

type TListaProjetosParams = TPaginateRest & {
  search?: string
}

type TDocumentoDownload = {
  arquivo: string,
  nomeArquivo: string
}

type TDocumentoData = {
  documentList: {
    id: number
  }
  file: string
  fileContent: string
}

type TAceitaCredito = {
  isAccepted: boolean
}

type TEstimatedValue = {
  estimatedValueOfProject: string,
  estimatedPowerOfProject: string
}

type TListaRelatorioProjetosParams = TPaginateRest & {
  responseType?: string
  createDate?: string
  integrator?: string
  projectName?: string
  projectPower?: string
  projectValue?: string
  responsibleForFinancing?: string
  status?: string
  updateDate?: string
}

type TRelatorioDownloadResponse = {
  relatorio: string
}

type TNotaFiscalResponse = {
  notaFiscalUnificada: TNotaFiscalUnificadaResponse
  result: TNotaFiscal[]
  empresa: TNotaFiscalEmpresa
  integrador: TNotaFiscalIntegrador
}

export type TNotaFiscalIntegrador = {
  id: number
  companyName: string
  fantasyName: string
  cnpj: string
  foundationYear: number
  accountNumber: number
  agencyNumber: number
  masterAccount: boolean
  bankName: string
  bankCode: string
  bankId: number
}

export type TNotaFiscalEmpresa = {
  id: number
  corporateName: string
  fantasyName: string
  cnpj: string
  foundationYear: number
  accountNumber: number
  agencyNumber: number
  masterAccount: boolean
  bankName: string
  bankCode: string
  bankId: number
}

const useProjetos = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getProjetos = async (params: TListaProjetosParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/project'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response
  }

  const getProjeto = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/project/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return
    return response.data
  }

  const postProjeto = async (data: TProjetoBody | TProjetoBodyPJ | TProjetoBodyReduced, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/project'
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response
  }

  const putProjeto = async (id: string, data: TProjetoBody | TProjetoBodyPJ | TProjetoBodyReduced, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/project/${id}`
    const response = await rest.put(url, data)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response
  }

  const postCancelamentoProjeto = async (
    data: any,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/project/${data.id}/cancelation`
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  const getDetalhes = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/project/${id}/details`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TProjetosDetalhesResponse
    return response.data as TProjetosDetalhesResponse
  }

  const getTimeline = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/credit/proposal-history/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as TProjetoTimelineRequest[]
    return response.history as TProjetoTimelineRequest[]
  }

  const getDigitalFormalizationTimeline = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/credit/proposal-history/formalization/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as TProjetoDigitalFormalizationTimelineRequest[]
    return response.digitalFormalizationhistory as TProjetoDigitalFormalizationTimelineRequest[]
  }

  const getDigitalFormalizationLink = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/credit/proposal-history/formalization/link/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TProjetoDigitalFormalizationLinkRequest
    return response.digitalFormalizationLink as TProjetoDigitalFormalizationLinkRequest
  }
  const getEstimatedValuesByProjectId = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/project/${id}/estimated-values`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TEstimatedValue
    return response.data as TEstimatedValue
  }

  const getDadosTecnicos = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/project/${id}/technical-data`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TDadosTecnicosBothResponses
    return response as TDadosTecnicosBothResponses
  }

  const postDadosTecnicos = async (
    id: string,
    dados: TDadosTecnicosResponse,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/project/${id}/technical-data`
    await rest.post(url, dados)
    if (triggerLoading) setIsLoading(false)
  }

  const getPropostasAndIntegrador = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/credit/proposal?projectId=${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return []
    return response
  }

  const postProposta = async (dados: any, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = 'credit/proposal'
    const response = await rest.post(url, dados)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  const getDocumentos = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/proposal/${id}/documents`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response as TDocumentosRequest
  }

  const postDocumento = async (id: string, data: TDocumentoData, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/proposal/${id}/documents`
    await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
  }

  const getDocumentoById = async (proposalId: string, documentId: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/proposal/${proposalId}/document/${documentId}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response as TDocumentoRequest
  }

  const getNotasFiscais = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `project/${id}/invoice`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TNotaFiscalResponse
    return response as TNotaFiscalResponse
  }

  const postNotasFiscais = async (id: string, data: TNotaFiscalRequest[], triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `project/${id}/invoice`
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  const putNotasFiscais = async (id: string, data: TNotaFiscalRequest[], triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/project/${id}/invoice-update`
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  const getDocumentosFomalizacao = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/proposal/${id}/formalization_documents`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TDocumentosFormalizacaoRequest
    return response as TDocumentosFormalizacaoRequest
  }

  const getDocumentoFormalizacao = async (projetoId: string, tipoId: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/proposal/${projetoId}/formalization_documents/${tipoId}/download`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TDocumentoDownload
    return response.result as TDocumentoDownload
  }

  const postDocumentoFormalizacao = async (
    id: string,
    data: TDocumentoData,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/proposal/${id}/formalization_documents`
    await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
  }

  const getCredito = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/credit/proposal-follow-up/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TProposta
    return response.data as TProposta
  }

  const postCredito = async (
    id: string,
    data: TAceitaCredito,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/credit/proposal/${id}/evaluation`
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    if(!response) return {}
    return response
  }

  const getProjetosRelatorios = async (
    params: TListaProjetosParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/report/projects'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response
  }

  const getProjetosRelatoriosDownload = async (
    url: string,
    params: TListaRelatorioProjetosParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response as TRelatorioDownloadResponse
  }

  const getDocumentosPendentes = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/project/${id}/pending-documents`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if(!response) return {} as TDocumentosPendentesResponse
    return response.pendingDocument as TDocumentosPendentesResponse
  }

  return {
    isLoading,
    getProjetos,
    getProjeto,
    postProjeto,
    putProjeto,
    postCancelamentoProjeto,
    getDetalhes,
    getTimeline,
    getDigitalFormalizationTimeline,
    getEstimatedValuesByProjectId,
    getDadosTecnicos,
    postDadosTecnicos,
    postProposta,
    getPropostasAndIntegrador,
    getDocumentos,
    postDocumento,
    getDocumentoById,
    getNotasFiscais,
    postNotasFiscais,
    putNotasFiscais,
    getDocumentosFomalizacao,
    getDocumentoFormalizacao,
    postDocumentoFormalizacao,
    getCredito,
    postCredito,
    getProjetosRelatorios,
    getProjetosRelatoriosDownload,
    getDigitalFormalizationLink,
    getDocumentosPendentes
  }
}

export default useProjetos
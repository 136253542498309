import * as Yup from 'yup'

export type TAliquot = {
  id: number
  typeClass: string
  monthValidity: string
  te: number
  tusd: number
  pisCofins: number
  icms: number
}

export const initialValues: TAliquot = {
  id: 1,
  typeClass: 'RESIDENTIAL',
  monthValidity: '',
  te: 0,
  tusd: 0,
  pisCofins: 0,
  icms: 0
}


export const validationSchema = Yup.object().shape({
  typeClass: Yup.string().required('Tipo da Classe é obrigatório'),
  monthValidity: Yup.string().required('Mês é obrigatório'),
  te: Yup.number().required('Tarifa de Energia é obrigatória'),
  tusd: Yup.number().required('Tarifa de Uso do Sistema de Distribuição é obrigatória'),
  icms: Yup.number().required('Imposto sobre Circulação de Mercadorias e Serviços é obrigatório'),
  pisCofins: Yup.number().required('PIS/CONFINS é obrigatório')
})
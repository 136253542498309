import { gql } from 'apollo-boost'
import { commonResponse, paginationInfo } from './constants'

export const QUERY_USUARIOS = gql`
  query listUsers ($listUsersCommand: ListUsersCommand) {
    listUsers (listUsersCommand: $listUsersCommand) {
      ${commonResponse}
      ${paginationInfo}
      usersList {
        id
        name
        email
        role {
          name
        }
        status
      }
    }
  }
`

export const QUERY_PERFIS = gql`
  query listProfiles ($listProfilesCommand: ListProfilesCommand) {
    listProfiles (listProfilesCommand: $listProfilesCommand) {
      ${commonResponse}
      ${paginationInfo}
      profileList {
        id,
        name
      }
    }
  }
`

export const QUERY_USUARIO = gql`
  query getUser ($getUserCommand: GetUserCommand) {
    getUser (getUserCommand: $getUserCommand) {
      ${commonResponse}
      user {
        id
        name
        email
        role {
          id
        }
        status
        telephone
      }
    }
  }
`

export const MUTATION_SAVE_USUARIO = gql`
    mutation registerNewUser ($registerUserCommand: RegisterUserCommandInput!) {
      registerNewUser (registerUserCommand: $registerUserCommand) {
        ${commonResponse}
      }
    }
`

export const MUTATION_UPDATE_USUARIO = gql`
    mutation updateUser ($updateUserCommand: UpdateUserCommandInput!) {
      updateUser (updateUserCommand: $updateUserCommand) {
        ${commonResponse}
      }
    }
`

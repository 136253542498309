import styled from 'styled-components'
import logoImage from 'assets/images/logoHorizontal.svg'

export const Logo = styled.h1`
  width: 240px;
  height: 62px;
  background: url(${logoImage}) no-repeat;
  background-size: 240px 62px;
  text-indent: -9999px;
  overflow: hidden;
`